import { useMediaQuery } from 'react-responsive'

export interface MediaQueries {
  isExtraSmall: boolean;
  isSmall: boolean;
  isMedium: boolean;
  isOneK: boolean;
  isLarge: boolean;
  isExtraLarge: boolean;
  isPrint: boolean;
}

export const useMediaQueries = (): MediaQueries => {
  const isExtraSmall = useMediaQuery({ maxWidth: 599 });
  const isSmall = useMediaQuery({ maxWidth: 799 });
  const isMedium = useMediaQuery({ maxWidth: 999 });
  const isOneK = useMediaQuery({ maxWidth: 1024 });
  const isLarge = useMediaQuery({ maxWidth: 1199 });
  const isExtraLarge = useMediaQuery({ maxWidth: 1399 });
  const isPrint = useMediaQuery({ print: true });

  return { isExtraSmall, isSmall, isMedium, isOneK, isLarge, isExtraLarge, isPrint };
}
