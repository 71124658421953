/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithId, WithNameAndId } from "../utils/api-types/WithNameAndId";
import { Proper } from "../utils/api-types/ordo/Proper";
import { GQLDocument, GQLQuery } from './gql-generics';

// This type should match LITURGICAL_RITE_FRAGMENT
export type LiturgicalRite = {
  isGendered: boolean;
  lectionaryCommonRubric: string | null;
  hasOwnReadings: boolean;
  showDeceasedName: boolean;
  showCommonsAsTextOptions: boolean;
  showReligiousAnniversary: boolean;
  showYearsMarried: boolean;
  seedId: string;
  year: number | null;
  councilId: string | null;
  pathing: string | null;
  liturgicalCategory: WithNameAndId;
  liturgy: { orders: WithNameAndId[] } & WithId;
  propers: Proper[];
  lectionaryPropers: WithNameAndId[];
} & WithNameAndId;

// This query should match the LiturgicalRite interface
export const LITURGICAL_RITE_FRAGMENT = gql`
  fragment LiturgicalRite on LiturgicalRite {
    id
    name
    isGendered
    lectionaryCommonRubric
    hasOwnReadings
    showDeceasedName
    showCommonsAsTextOptions
    showReligiousAnniversary
    showYearsMarried
    seedId
    year
    councilId
    pathing
    liturgicalCategory {
      id
      name
    }
    liturgy {
      id
      orders {
        id
        name
      }
    }
    propers {
      id
      name
      season
      liturgicalCategory {
        id
        name
        isOption
      }
    }
    lectionaryPropers {
      id
      name
    }
  }
`;

export interface GetLiturgicalRitesByCategory extends GQLQuery {
  Variables: {
    categorySeedId?: string;
  }
  Data: {
    getLiturgicalRitesByCategory: LiturgicalRite[];
  }
}
export const GetLiturgicalRitesByCategory: GQLDocument<GetLiturgicalRitesByCategory> = {
  query: gql`
    query getLiturgicalRitesByCategory($categorySeedId: String) {
      getLiturgicalRitesByCategory(categorySeedId: $categorySeedId) {
        ...LiturgicalRite
      }
    }
    ${LITURGICAL_RITE_FRAGMENT}
  `
}
