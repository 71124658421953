import React from 'react';
import { CanonicalSource } from 'components/shared/CanonicalSource';
import { css, cx } from '@emotion/css';
import { colorDictionary, fontSerifCss } from 'utils/styles';

interface TextSourceProps {
  scriptureReference?: string;
  showCanonicalSource?: boolean;
  sourceNames?: string[];
}

export const TextSource: React.FC<TextSourceProps> = ({
  scriptureReference,
  showCanonicalSource,
  sourceNames,
}) => <div className={cx('_EXPORT text-reference no-page-break-after', style)}>
  <span>
    {scriptureReference && <span>
      {scriptureReference}
      {showCanonicalSource && !!sourceNames?.length && ' · '}
    </span>}
    {showCanonicalSource && <CanonicalSource sourceNames={sourceNames} />}
  </span>
</div>;

const style = css`
  ${fontSerifCss}
  font-style: italic;
  font-size: 0.8em;
  color: ${colorDictionary['Secondary']};
  font-weight: 400;
  line-height: 1.2;
  padding-bottom: 10px;
`;
