import { OrdoDisplayMode } from 'utils/ordo/OrdoDisplayMode';

export const useCardClass = (displayMode: OrdoDisplayMode) => {
  switch (displayMode) {
    case OrdoDisplayMode.WRITE:
      return 'card-planning-card';
    case OrdoDisplayMode.READ:
      return 'card-view-card';
    case OrdoDisplayMode.EXPORT:
      return 'card-view-card export';
    default:
      return '';
  }
}
