import { LayoutContext } from 'context/LayoutContext';
import { OrdoContext } from 'context/OrdoContext';
import { OrdoCard } from 'queries/card-fragment';
import { OrdoRole } from 'queries/ordo-role';
import { TemplateCard } from 'queries/template';
import { useCallback, useContext, useMemo } from 'react';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';

export const useConfigOnCard = (card?: OrdoCard): OrdoCardConfig => {
  const { selectedRole } = useContext(OrdoContext);
  // also check if we're in a LayoutContext, and if so, use those override configs
  const { overrideConfigMap, activeFile: { config: { spacingHorizontal, spacingVertical }} } = useContext(LayoutContext);
  const layoutOverrideConfig = overrideConfigMap.get(card?.id ?? -1);

  return useMemo(
    () => getConfigOnCard(card ? {config: card.config, roleConfigs: card.roleConfigs} : undefined, selectedRole, layoutOverrideConfig, { spacingHorizontal, spacingVertical }),
    [card, layoutOverrideConfig, selectedRole, spacingHorizontal, spacingVertical]
  );
};

export const useGetConfigOnCard = () => {
  const { selectedRole } = useContext(OrdoContext);
  // also check if we're in a LayoutContext, and if so, use those override configs
  const { overrideConfigMap, activeFile: { config: { spacingHorizontal, spacingVertical }} } = useContext(LayoutContext);

  return useCallback<(card?: OrdoCard) => OrdoCardConfig>(
    (card) =>
      getConfigOnCard(
        card
          ? { config: card.config, roleConfigs: card.roleConfigs }
          : undefined,
        selectedRole,
        overrideConfigMap.get(card?.id ?? -1),
        { spacingHorizontal, spacingVertical },
      ),
    [overrideConfigMap, selectedRole, spacingHorizontal, spacingVertical],
  );
};

export const getConfigOnCard = (
  card?: OrdoCard | Pick<OrdoCard, 'config' | 'roleConfigs'> | undefined,
  selectedRole?: OrdoRole | null,
  layoutOverride?: OrdoCardConfig,
  defaultConfig?: OrdoCardConfig,
) => {
  const config = { ...card?.config };
  if (defaultConfig) {
    Object.entries(defaultConfig).forEach(([key, val]) => {
      config[key as keyof OrdoCardConfig] ??= val as any;
    })
  }
  if (layoutOverride) {
    // don't override null values:
    layoutOverride = Object.fromEntries(Object.entries(layoutOverride).filter(([_, val]) => val !== null))
  }
  if (selectedRole) {
    const roleConfig = card?.roleConfigs.find(
      (rc) => rc.ordoRole.id === selectedRole.id,
    )?.config;
    const configOverrides = filterToOverrides(
      roleConfig ?? selectedRole.defaultRoleConfig,
    );
    return { ...config, ...configOverrides, ...layoutOverride };
  } else {
    return { ...config, ...layoutOverride};
  }
};

/// Note this is similar to getConfigOnCard, any updates to one should probably be reflected in the other
export const getConfigOnTemplateCard = (card: TemplateCard|null, selectedRole: OrdoRole|null): OrdoCardConfig|{} => {
  if (selectedRole) {
    const roleConfig = card?.config?.roleConfigs.find(rc => rc.ordoRole.id === selectedRole.id)?.properties;
    const configOverrides = filterToOverrides(roleConfig ?? selectedRole.defaultRoleConfig);
    return { ...card?.config?.properties, ...configOverrides }
  } else {
    return card?.config?.properties ?? {};
  }
}

export const filterToOverrides = (config: OrdoCardConfig) => {
  return Object.fromEntries(Object.entries(config).filter(([, val]) => val !== null));
}
