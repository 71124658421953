import { useApolloClient } from '@apollo/client';
import { CallToAction } from 'components/shared/buttons/CallToAction';
import { AuthContext } from 'context/AuthContext';
import { useLogException } from 'hooks/error/useLogError';
import { useMutation } from 'hooks/queries/useMutation';
import { useQuery } from 'hooks/queries/useQuery';
import { useStoredReferral } from 'hooks/referrals/useProcessReferral';
import { useRefreshJwt } from 'hooks/registration';
import { useToast } from 'hooks/useToast';
import { Group } from 'queries/group';
import { ChargebeeEstimates, GetEstimateForPlan, GetHostedPageForPlan, GrantAccessForHostedPage, HostedPage } from 'queries/hosted-page';
import { Addon, Plan } from 'queries/plan';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getChargebee } from 'utils/chargebee/getChargebee';
import { getPlanPrice } from 'utils/chargebee/getPlanPrice';
import { setLocalStorageHostedPageId } from 'utils/localStorageHostedPageId';

export type ChargebeeButtonProps = {
  style?: React.CSSProperties;
  className?: string;
  group?: Group;
  isYearly: boolean;
  isUpgrade?: boolean;
  plan?: Plan;
  quantity?: number;
  addon?: Addon;
  addonQuantity?: number;
  couponCode?: string;
  onSuccess?: () => void;
  getRedirectUrl?: (hostedPage: HostedPage) => [string, Record<string, string>] | null;
  startDate?: Date;
  isRenewal?: boolean;
  disabled?: boolean;
  updateEstimates?: (estimate: ChargebeeEstimates) => void;
  setInvalidCoupon?: (invalidCouponCode?: string) => void;
};

export const ChargebeeButton: React.FC<PropsWithChildren<ChargebeeButtonProps>> = ({
  style,
  className,
  group,
  plan,
  quantity,
  addon,
  addonQuantity,
  couponCode,
  isUpgrade = false,
  isYearly,
  onSuccess,
  getRedirectUrl,
  startDate,
  isRenewal,
  disabled,
  updateEstimates,
  setInvalidCoupon,
  children,
}) => {
  const onSubscribeError = useToast('error', 'Something went wrong with your purchase. Please contact customer support.');
  const chargebee = getChargebee();
  const [grantAccessForHostedPage] = useMutation(GrantAccessForHostedPage);
  const { userManager } = useContext(AuthContext);
  const refreshJwt = useRefreshJwt(userManager);
  const history = useHistory();
  const referral = useStoredReferral();
  const referrerId = !group ? referral?.referrerId : undefined;
  const hostedPageVariables: GetHostedPageForPlan['Variables'] = {
    planId: plan?.id,
    quantity,
    addonId: addon?.id,
    addonQuantity,
    couponCode,
    startDate,
    isRenewal,
    // only send a referrer if user is not logged in
    referrerId,
  };
  const { data: { estimate: estimates } = { estimate: undefined }, error: estimateError } =
    useQuery(GetEstimateForPlan, {
      variables: hostedPageVariables,
      fetchPolicy: 'cache-and-network',
      skip: disabled,
    });
  const { estimate, estimateWithoutCustomer } = estimates || {};
  useEffect(() => {
    updateEstimates?.({ estimate, estimateWithoutCustomer });
  }, [updateEstimates, estimate, estimateWithoutCustomer]);
  // if useCoupon is set to false, we will not use the coupon when retrieving the hosted page, to avoid an error
  const [useCoupon, setUseCoupon] = useState(true);
  useEffect(() => {
    const { message } = estimateError ?? {};
    const badCouponCode = /\bThe (.*?) referenced in parameter coupon_ids\[/.exec(message ?? '')?.[1] ??
      /\bThe passed coupon (.*?) has already been redeemed.\b/.exec(message ?? '')?.[1];
    setInvalidCoupon?.(badCouponCode);
    setUseCoupon(!badCouponCode);
  }, [estimateError, setInvalidCoupon]);
  // The GetHostedPageForPlan query needs to be done directly with the client, because the useQuery hook is not compatible with this use case.
  // cf. https://github.com/apollographql/react-apollo/issues/3499
  const apollo = useApolloClient();
  const planPrice = getPlanPrice({ plan, parishSize: group?.keycloakGroup.parishSize, isYearly });
  
  const logException = useLogException();
  const trackFbq = () => fbq('track', 'AddToCart', { value: planPrice ?? undefined, currency: 'USD', content_name: (plan || addon)?.name });
  const checkout = () => {
    trackFbq();
    let redirectUrl = window.location.pathname;
    let redirectState: null | Record<string, string> = null;
    chargebee?.openCheckout({
      layout: 'in_app',
      hostedPage: () =>
        apollo.query<GetHostedPageForPlan['Data'], GetHostedPageForPlan['Variables']>({
          query: GetHostedPageForPlan.query,
          variables: useCoupon ? hostedPageVariables : { ...hostedPageVariables, couponCode: undefined },
          fetchPolicy: 'no-cache',
        }).then((result) => {
          if (result?.data) {
            ([redirectUrl, redirectState] = getRedirectUrl?.(result.data.hostedPage) ?? [redirectUrl, null]);
            return result.data.hostedPage;
          }

          throw new Error('Unable to load plan');
        }),
      error: (err: any) => {
        // this error is actually harmless
        // and can be ignored.
        if (typeof err === 'string' && err.match(/in progress/)) {
          return;
        }

        console.error(err);
        onSubscribeError('Unable to begin checkout process');
        logException({ error: err, errorInfo: { function: 'chargebee.openCheckout', planId: plan?.id, addonId: addon?.id }})
      },
      success: async (hostedPageId: string) => {
        const user = await userManager.getUser();
        const result = await grantAccessForHostedPage({
          variables: {
            hostedPageId,
            referrerId, 
          }
        });
        if (result.data) {
          onSuccess?.();
          // grant access through keycloak, but only if there is a user
          if (user) {
            if (redirectState) {
              redirectUrl += '?' + new URLSearchParams(redirectState).toString();
            }    
            refreshJwt(redirectUrl);
          } else {
            setLocalStorageHostedPageId(hostedPageId);
            history.push(redirectUrl, redirectState);
          }
        }
        chargebee?.closeAll();
      },
    });
  };

  return (
    <CallToAction
      className={className}
      behavior={{ onClick: checkout }}
      disabled={disabled || (!group && !plan?.id) || !chargebee}
      data-ga-code='chargebee'
      style={style}
    >
      {children || (isUpgrade ? 'Upgrade' : 'Begin Plan')}
    </CallToAction>
  );
}
