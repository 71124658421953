import { css, cx } from '@emotion/css';
import Exsurge from 'components/shared/LazyExsurge';
import { NotatedVerses } from 'components/shared/NotatedVerses';
import { PlainTextVerses } from 'components/shared/PlainTextVerses';
import { PointedVerses } from 'components/shared/PointedVerses';
import { OrdoContext } from 'context/OrdoContext';
import { GabcSyllabified, Language, splitGabcByTitle } from 'gabc-utils';
import { useLogException } from 'hooks/error/useLogError';
import { useDefaultExsurgeConfig } from 'hooks/exsurge/useDefaultExsurgeConfig';
import { useGabcsHaveRendered } from 'hooks/exsurge/useGabcsHaveRendered';
import { useGloryBeText } from 'hooks/text';
import { MelodicComposition } from 'queries/composition';
import { Text } from 'queries/text';
import { TextOption } from 'queries/text-option';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Season } from 'utils/api-types/ordo/Season';
import { VerseStyle } from 'utils/api-types/ordo/card/MusicalProperties';
import { processComposition } from 'utils/functions/composition/processComposition';
import { getExsurgeAlignment } from 'utils/functions/exsurge/getExsurgeAlignment';
import { getGabcClefMatch } from 'utils/functions/exsurge/getGabcClefMatch';
import { getModeNumeral } from 'utils/functions/exsurge/getModeNumeral';
import { getContentWidthForExsurge } from 'utils/getContentWidth';
import { replaceLORDWithSmallCaps } from 'utils/markup/replaceLORDWithSmallCaps';
import { VerseSplitType, splitVersesByType } from 'utils/markup/splitVerses';
import { MusicalErrorAlert } from './MusicalErrorAlert';
import { ScriptureReference } from './ScriptureReference';
import { Spacing } from './editor/settings/SpacingToggler';

interface ExsurgeWrapperProps {
  removeSolesmesMarkings?: boolean;
  includeGloryBe?: boolean;
  language?: Language;
  defaultVerseLanguage?: Language;
  isAlleluia?: boolean;
  textOption?: TextOption|null;
  antiphon: MelodicComposition;
  antiphonText?: Text|null;
  additionalAntiphonText?: string|null;
  verseText?: Text|null;
  verse?: MelodicComposition|null;
  verseStyle?: VerseStyle|null;
  verseCount?: number;
  verseSplitType?: VerseSplitType;
  verseClassName?: string;
  verseColumns?: number|null;
  useVersicleSymbolForVerses?: boolean;
  capitalizeInitial?: boolean;
  isHymn?: boolean;
  spacingHorizontal?: Spacing | null;
  spacingVertical?: Spacing | number | null;
  width: number;
  onLoaded?: () => void;
}

export const ExsurgeWrapper: React.FC<ExsurgeWrapperProps> = ({
  removeSolesmesMarkings = false,
  includeGloryBe = false,
  defaultVerseLanguage,
  isAlleluia = false,
  textOption,
  antiphon,
  antiphonText,
  additionalAntiphonText,
  language = antiphon?.translation?.language,
  verseText,
  verse,
  verseStyle,
  verseCount = 0,
  verseSplitType,
  verseColumns,
  useVersicleSymbolForVerses = false,
  verseClassName = useVersicleSymbolForVerses ? 'versicle-list' : undefined,
  capitalizeInitial = true,
  spacingHorizontal,
  spacingVertical,
  width,
  onLoaded,
}) => {

  width = getContentWidthForExsurge(width);
  
  const isEasterTime = useContext(OrdoContext).ordo.season === Season.EASTER;

  verseStyle = verseStyle || VerseStyle.POINT;

  // prevent rendering with width=0, which was screwing up exports when hiding and re-displaying cards
  const lastNonZeroWidth = useRef(width);
  if (width) lastNonZeroWidth.current = width;
  width = lastNonZeroWidth.current;

  const defaults = useDefaultExsurgeConfig({ spacingHorizontal, spacingVertical });
  const modeNumeral = getModeNumeral(antiphon.melody.mode);
  const gloryBe = useGloryBeText();

  const antiphonArguments = useMemo(
    () => ({ text: antiphonText, composition: antiphon, language, processGabc: true, isEasterTime, removeSolesmesMarkings }),
    [antiphon, antiphonText, isEasterTime, language, removeSolesmesMarkings]
  );
  const {
    translation: antiphonTranslation,
    language: antiphonLanguage,
    music: antiphonMusic,
    gabc: antiphonGabc,
    error: antiphonError,
  } = useMemo(() => processComposition(antiphonArguments), [antiphonArguments]);
  const antiphonClef = getGabcClefMatch(antiphonMusic, true);
  const {
    translation: verseTranslation,
    language: verseLanguage,
    music: verseMusic,
    psalmTone: versePsalmTone,
  } = useMemo(() => processComposition({ text: verseText, composition: verse, language: defaultVerseLanguage || language, clef: antiphonClef, processGabc: false, removeSolesmesMarkings }), [antiphonClef, defaultVerseLanguage, language, removeSolesmesMarkings, verse, verseText]);
  const verses = useMemo(
    () => splitVersesByType(verseTranslation?.content, verseSplitType).slice(0, verseCount),
    [verseSplitType, verseTranslation?.content, verseCount],
  );

  const {
    translation: gbTranslation,
    music: gbMusic,
    psalmTone: gbPsalmTone,
  } = useMemo(() =>
    processComposition({ text: gloryBe, composition: verse, language: defaultVerseLanguage || language, clef: antiphonClef, processGabc: false, removeSolesmesMarkings }),
    [antiphonClef, defaultVerseLanguage, gloryBe, language, removeSolesmesMarkings, verse]
  );
  const antiphonSources = textOption?.sources?.map(s => s.nameShort) || [];
  let textRight = isAlleluia ? '' : antiphonText?.originalSource;
  let canonicalSources = `${textRight && antiphonSources.length > 0 ? ' · ' : ''}${antiphonSources.length > 0 ? antiphonSources.join(', ') : ''}`;

  const verseGabc = useMemo(() => {
    let verseText = verseTranslation?.content;
    // in case the verse already starts with a number or versicle or response, we don't need to add in the versicle symbol:
    if (verseText && !/^\s*(?:[\d℣℟]|\w\.)/.test(verseText)) {
      verseText = '℣. ' + verseText;
    }
    return (
      !versePsalmTone &&
      verse &&
      verseText &&
      verseMusic &&
      replaceLORDWithSmallCaps(
        GabcSyllabified.merge(
          verseText,
          verseMusic,
          {
            isEaster: isEasterTime,
            useLargeInitial: capitalizeInitial,
          }
        )
      )
    );
  }, [
    capitalizeInitial,
    isEasterTime,
    verse,
    verseMusic,
    versePsalmTone,
    verseTranslation,
  ]);

  const [firstAntiphonGabc, ...otherAntiphonGabcs] = useMemo(
    () => {
      const combinedGabc =
        !antiphonGabc &&
        antiphonTranslation &&
        antiphon &&
        antiphonMusic &&
        replaceLORDWithSmallCaps(
          GabcSyllabified.merge(
            antiphonTranslation.content,
            antiphonMusic,
            {
              isEaster: isEasterTime,
              useLargeInitial: capitalizeInitial,
              followedByGabc: typeof verseGabc === 'string' ? verseGabc : undefined,
            },
          )
        );
      return combinedGabc ? splitGabcByTitle(combinedGabc) : [];
    },
    [antiphon, antiphonGabc, antiphonMusic, antiphonTranslation, capitalizeInitial, isEasterTime, verseGabc],
  );

  const logException = useLogException();
  useEffect(() => {
    if (antiphonError) {
      logException({
        error: antiphonError,
        errorInfo: antiphonArguments,
        message: 'Musical Exception',
      });
      onLoaded?.();
    }
  }, [antiphonError, onLoaded, logException, antiphonArguments]);

  const [antiphonGabcHasRendered, setAntiphonGabcHasRendered] = useState(false);
  const setAntiphonGabcRendered = useCallback(() => setAntiphonGabcHasRendered(true), []);
  const [firstAntiphonGabcHasRendered, setFirstAntiphonGabcHasRendered] = useState(false);
  const setFirstAntiphonGabcRendered = useCallback(() => setFirstAntiphonGabcHasRendered(true), []);
  const [otherAntiphonGabcsHaveRendered, setOtherAntiphonGabcsRendered] = useGabcsHaveRendered(otherAntiphonGabcs.length);
  const [formulaicVersesHaveRendered, setFormulaicVersesHaveRendered] = useState(false);
  const [notatedVersesHaveRendered, setNotatedVersesHaveRendered] = useState(false);
  const onLoadedFormulaicVerses = useCallback(() => {
    setFormulaicVersesHaveRendered(true);
  }, []);
  const onRenderNotatedVerses = useCallback(() => {
    setNotatedVersesHaveRendered(true);
  }, []);
  const hasFormulaicVerses = !!(versePsalmTone && verseStyle !== VerseStyle.TEXT && (includeGloryBe || (verseText && verseCount > 0)));
  const hasMultipleAntiphonGabcs = !antiphonGabc && !!antiphonTranslation && !!antiphon;
  const hasOtherAntiphonGabcs = otherAntiphonGabcs.length > 0;
  const hasNotatedVerses = !versePsalmTone && verseStyle !== VerseStyle.TEXT && !!verseGabc;
  const hasLoaded =
    (!antiphonGabc || antiphonGabcHasRendered) &&
    (!hasMultipleAntiphonGabcs ||
      (firstAntiphonGabcHasRendered &&
        (!hasOtherAntiphonGabcs || otherAntiphonGabcsHaveRendered))) &&
    (!hasFormulaicVerses || formulaicVersesHaveRendered) &&
    (!hasNotatedVerses || notatedVersesHaveRendered);
  useEffect(() => {
    if (hasLoaded) {
      onLoaded?.();
    }
  }, [hasLoaded, onLoaded]);

  return (
    antiphonError
  ? <MusicalErrorAlert />
  : <div className={cx('exsurge-wrapper MIDI-antiphon', style)}>
      <div className='MIDI-music'>
      {antiphonGabc && <Exsurge
        gabc={antiphonGabc}
        useDropCap={true}
        width={width}
        textRight={`<i>${textRight}</i>${canonicalSources}`}
        alignment={getExsurgeAlignment(antiphonLanguage)}
        annotation={modeNumeral}
        className='_EXPORT'
        onRender={setAntiphonGabcRendered}
        {...defaults}
      />}


      {!antiphonGabc && antiphonTranslation && antiphon &&
        <>
        <Exsurge
          gabc={firstAntiphonGabc.gabc}
          subtitle={firstAntiphonGabc.subtitle}
          width={width}
          useDropCap={capitalizeInitial}
          textRight={isAlleluia ? '' : `<i>${textRight}</i>${canonicalSources}`}
          alignment={getExsurgeAlignment(antiphonLanguage)}
          annotation={modeNumeral}
          className='_EXPORT'
          onRender={setFirstAntiphonGabcRendered}
          {...defaults}
        />
        {otherAntiphonGabcs.map((gabc, i) => <Exsurge
            key={i}
            gabc={gabc.gabc}
            subtitle={gabc.subtitle}
            width={width}
            useDropCap={capitalizeInitial}
            alignment={getExsurgeAlignment(antiphonLanguage)}
            annotation={modeNumeral}
            className='_EXPORT'
            onRender={setOtherAntiphonGabcsRendered[i]}
            {...defaults}
          />)}
        </>
      }
      </div>

      <div className='MIDI-verses'>{versePsalmTone ? (
        <>
          {(includeGloryBe || (verseText && verseCount > 0)) &&
            verseStyle === VerseStyle.POINT && (
              <PointedVerses
                verseMusic={verseMusic}
                versePsalmTone={versePsalmTone}
                verseLanguage={verseLanguage}
                defaults={defaults}
                additionalAntiphonText={additionalAntiphonText}
                verseText={verseText}
                verseTranslation={verseTranslation}
                verses={verses}
                verseClassName={verseClassName}
                verseColumns={verseColumns}
                includeGloryBe={includeGloryBe}
                gbTranslation={gbTranslation}
                gbMusic={gbMusic}
                onLoaded={onLoadedFormulaicVerses}
              />
            )}

          {(verseCount > 0 || includeGloryBe) &&
            verseStyle === VerseStyle.NOTATE && (
              <NotatedVerses
                additionalAntiphonText={additionalAntiphonText}
                versePsalmTone={versePsalmTone}
                verseText={verseText}
                verseTranslation={verseTranslation}
                verses={verses}
                useVersicleSymbol={useVersicleSymbolForVerses}
                includeGloryBe={includeGloryBe}
                gbTranslation={gbTranslation}
                gbPsalmTone={gbPsalmTone}
                width={width}
                verseCount={verseCount}
                spacingHorizontal={spacingHorizontal}
                spacingVertical={spacingVertical}
                onLoad={onLoadedFormulaicVerses}
              />
            )}
        </>
      ) : verseStyle !== VerseStyle.TEXT && (
        <>
        {verseText && <ScriptureReference text={verseText.originalSource}/>}
        {verseGabc && (
          <Exsurge
            gabc={verseGabc}
            width={width}
            useDropCap={false}
            alignment={getExsurgeAlignment(verseLanguage)}
            onRender={onRenderNotatedVerses}
            svgClass='_EXPORT'
            {...defaults}
          />
        )}
      </>)}</div>

      {(verseCount > 0 || includeGloryBe) && verseStyle === VerseStyle.TEXT &&
        <PlainTextVerses
          additionalAntiphonText={additionalAntiphonText}
          verses={verses}
          verseColumns={verseColumns}
          verseText={verseText}
          verseTranslation={verseTranslation}
          includeGloryBe={includeGloryBe}
          gbTranslation={gbTranslation}
        />
      }
    </div>
  )
}

const style = css`
  .Exsurge + .Exsurge {
    padding-top: calc(4px + var(--card-vertical-spacing, 0px));
  }
  .MIDI-music>.Exsurge:last-child {
    padding-bottom: 24px;
  }
  
  .plain-text-translation + .plain-text-translation > .translation > .liturgical-text,
  .plain-text-translation + .liturgical-text
  {
    padding-top: 15px;
  }
`;
