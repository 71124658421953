import React from 'react';
import { Ordo } from 'queries/ordo';
import { OrdoRole } from 'queries/ordo-role';
import { UserRoles } from 'hooks/permission-set/useHasRole';

export interface IOrdoContext {
  type?: 'ordo' | 'share' | 'template';
  ordo: Ordo;
  ownerRoles?: UserRoles[];
  exportCardId?: number;
  setExportCardId?: (exportCardId?: number) => void;
  selectedRole: OrdoRole | null;
  setSelectedRole: (ordoRole: OrdoRole | null) => void;
  createdCardIds: number[];
  setCreatedCardIds: React.Dispatch<React.SetStateAction<number[]>>;
  doAutomaticExport?: boolean;
}

export const OrdoContext = React.createContext<IOrdoContext>({
  ordo: {
    // this empty ordo should never occur, but if it does, it may cause problems.
    // is there an alternative?
    id: 0,
    updatedAt: new Date(),
    name: '',
    userId: '',
    groupId: '',
    eventId: null,
    eventDate: '',
    riteId: 0,
    season: null,
    liturgicalYear: 2024,
    defaultLanguage: 'en',
    liturgyDisplayName: '',
    suggestedCompositions: [],
    liturgicalCategory: null,
    liturgicalRite: { shortName: '', name: '', id: 0, showDeceasedName: false },
    liturgicalEvent: null,
    cards: [],
    layoutFiles: [],
    celebrations: [],
    config: { },
    shareId: '',
    accountId: 0,
    canDisplayAntiphonVersesOtherThanCommunion: false,
    treatAsBasic: false,
    permissionSetSeedIds: [],
  },
  exportCardId: undefined,
  selectedRole: null,
  setSelectedRole: () => {},
  setExportCardId: undefined,
  createdCardIds: [],
  setCreatedCardIds: () => {},
});
