import { css, cx } from '@emotion/css';
import { AnimatePresence, motion } from 'framer-motion';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { OptionListProps } from 'utils/functions/card';
import { colorDictionary } from 'utils/styles';

type SegmentedControlProps<V extends string | number | boolean = string> = OptionListProps<V, ReactNode> & {
  className?: string;
  style?: CSSProperties;
};

export function SegmentedControl<T extends string | number | boolean>({
  options,
  value,
  onChange,
  className,
  style: cssStyle,
}: React.PropsWithChildren<SegmentedControlProps<T>>) {
  const [cachedValue,setCachedValue] = useState<T>();
  useEffect(() => {
    if (value) {
      setCachedValue(undefined);
    }
  }, [value, setCachedValue]);
  const activeValue = cachedValue ?? value;
  const selectedIndex = options.findIndex(o => o.value === activeValue);
  const hasValidSelection = selectedIndex >= 0 && selectedIndex < options.length;
  return <div className={cx('segmented-control', className, style)} style={cssStyle}>
    {options.map(option => <div
      className='option'
      onClick={() => {
        setCachedValue(option.value);
        onChange(option.value);
      }}
      key={option.value.toString()}
    >
      <span>{option.label}</span>
    </div>)}
    <div
      className={cx('highlight', { invalid: !hasValidSelection })}
      style={{
        width: `calc((100% - 8px) / ${options.length})`,
        transform: hasValidSelection ? `translateX(calc(${selectedIndex * 100}% + ${selectedIndex}px))` : `translateX(0)`,
      }}
      key='0'
    >
      <AnimatePresence>
        <motion.span className='highlight-label' {...highlightLabelMotionProps} key={selectedIndex}>
          {hasValidSelection && options[selectedIndex].label}
        </motion.span>
      </AnimatePresence>
    </div>
  </div>;
};

const style = css`
  cursor: pointer;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-gap: 1px;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  color: ${colorDictionary['Secondary']};
  background: ${colorDictionary['Input']};
  user-select: none;
  position: relative;
  padding: 3px;
  .option {
    margin: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      min-width: 0;
      text-align: center;
      line-height: 34px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .option + .option {
    &::before {
      position: absolute;
      top: 9px;
      left: -1px;
      height: 1rem;
      background-color: #E8E8E8;
      content: '';
      width: 1px;
    }
  }
  .highlight {
    transition: transform 500ms cubic-bezier(0.5,0,0,1), opacity 0.5s;
    box-sizing: border-box;
    position: absolute;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px, rgba(0, 0, 0, 0.1) 0px 4px 16px;
    color: ${colorDictionary['Primary']};
    background-color: ${colorDictionary['White']};
    height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    text-align: center;
    line-height: 34px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .highlight-label {
      position: absolute;
      transform: translateX(-50%);
    }
    &.invalid {
      opacity: 0;
    }
  }
`;

const highlightLabelMotionProps = {
  transition: {
    delay: 0.125,
    duration: 0,
  },
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}
