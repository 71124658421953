import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef, useState } from 'react';
import type { OnPageLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import { Page } from './Page';

interface PageBrowserProps {
  width: number;
  numPages: number;
  url: string;
}

export const PageBrowser: React.FC<PageBrowserProps> = ({
  width,
  numPages,
  url,
}) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  /**
   * This will store the page height divided by its width so that the height of a given page
   * can be calculated by multiplying this number times the width
   */
  const pageRatios = useRef<number[]>([]);
  const [pageRatio, setPageRatio] = useState<number>();

  const onLoadSuccess: OnPageLoadSuccess = useCallback(page => {
    const { width, height } = page.getViewport();
    const ratio = height / width;
    pageRatios.current[page._pageIndex] = ratio;
    setPageRatio(current => current || ratio);
  }, [])

  const nextPage = useCallback((e?: React.MouseEvent) => {
    e?.stopPropagation?.();
    if (numPages) {
      setPageIndex(idx => Math.min(idx + 1, numPages - 1))
    }
  }, [numPages]);

  const prevPage = useCallback((e?: React.MouseEvent) => {
    e?.stopPropagation?.();
    setPageIndex(idx => Math.max(0, idx - 1));
  }, [setPageIndex]);


  // pre-render 3 pages, or as many as there are if there are fewer than 3:
  const pageCount = Math.min(3, numPages);
  // try to render half of these pages before pageIndex, but we cannot render more than pageIndex pages because that is as many as there are:
  const pagesBefore = Math.min(pageIndex, pageCount >> 1); // integer division by two, using bit shift.
  // find the first page to render, taking the earlier of `pagesBefore` pages before `pageIndex` or `pageCount` before the total number of pages in the PDF
  const firstPageToRender = Math.min(pageIndex - pagesBefore, numPages - pageCount);

  const pagesToRender = Array.from(
    new Array(pageCount)
  ).map((_, i) => firstPageToRender + i);

  const height = (pageRatios.current[pageIndex] ?? pageRatio) * width;

  return <>
    {
      width !== undefined &&
        pagesToRender.map((index) => <div key={index} className={pageIndex === index ? '' : 'd-none'}>
          <Page
            pageIndex={index}
            width={width}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onLoadSuccess={onLoadSuccess}
          />
      </div>)
    }
    <div className={`d-flex align-items-center justify-content-between py-15 px-30px border-light-grey bg-white w-100 ${(isNaN(height) || height > 60)? 'absolute-bottom-lg parent-hover-show-lg opacity-0-lg transition-opacity transition-duration-200ms' : ''}`}>
      <div>
        <a href={url} className='no-special-link-styling' target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon
            icon={faArrowToBottom}
            className='text-24 clickable'
          />
        </a>
      </div>
      <div className='d-flex align-items-center'>
        <FontAwesomeIcon
          icon={faChevronLeft}
          onClick={prevPage}
          className='text-18 clickable'
        />
        <p className='font-family-sans-serif px-15 text-16 m-0'>{pageIndex + 1} of {numPages}</p>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={nextPage}
          className='text-18 clickable'
        />
      </div>
    </div>
  </>;
};
