export const saveAs = (blob: Blob, fileName: string) => {
  const href = window.URL.createObjectURL(blob);
  const url = [
    'aplication/octet-stream',
    fileName,
    href
  ].join(':');

  const a = document.createElement('a');
  a.download = fileName;
  a.href = href;
 
  a.dataset.downloadurl =  url;
  const e = new MouseEvent('click', { bubbles: true, cancelable: false });
  a.dispatchEvent(e);
};
