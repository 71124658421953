import { getLiturgyDisplayNameIfFuneral } from 'components/ordo/utils/getLiturgyDisplayNameIfFuneral';
import { OrdoCardWithMeiOrSvg } from 'queries/card-fragment';
import { Ordo, OrdoShare } from 'queries/ordo';
import dayjs from 'utils/dayjs';

const getCardTitle = (ordo: Ordo | OrdoShare, cardId: number, addGrandStaff: boolean) => {
  let cardTitle = '';
  const card = (ordo.cards as OrdoCardWithMeiOrSvg[]).filter((card) => card.id === cardId)[0];
  if (card) {
    if (addGrandStaff) {
      // when adding a grand staff, the filename comes from the melody id:
      return ((card.mei ?? card.svg)?.exportName || 'grand-staff');
    }
    const cardName = card.name;
    cardTitle = '_' + cardName.toLowerCase().replace(/\s+/g, '-');
  }
  return cardTitle;
};

export const getExportFilename = ( 
  ordo: Ordo | OrdoShare,
  crop: boolean,
  addGrandStaff: boolean,
  cardId?: number | string,
  idx?: string,
) => {
  const date = dayjs.utc(ordo.eventDate).format('YYYYMMDD');
  if (idx) {
    idx = '_' + idx;
  } else {
    idx = '';
  }
  const cropped = crop ? '_cropped' : '';
  let cardTitle = '';
  if (typeof cardId === 'string') {
    cardTitle = '_' + cardId;
  } else if (cardId) {
    cardTitle = getCardTitle(ordo, cardId, addGrandStaff);
    if (addGrandStaff) {
      return cardTitle + cropped;
    }
  }
  const { liturgicalEvent, liturgicalRite } = ordo;
  let eventShortName = (
    getLiturgyDisplayNameIfFuneral(ordo.config, liturgicalRite) ??
    liturgicalEvent?.externalId ??
    liturgicalRite?.shortName ??
    ordo.config.liturgyDisplayName ??
    'export'
  )
    .toLowerCase()
    .replace(/_/g, '-');
  if (liturgicalEvent?.hasMultipleRites) {
    const liturgicalRiteParts = liturgicalRite.name.split(/:\s*/);
    let riteName =
      liturgicalRiteParts[liturgicalRiteParts.length - 1].toLowerCase();
    let match = /(\w+)\smass\b/.exec(riteName);
    if (match) {
      if ('the' === match[1]) {
        riteName = riteName.match(/\w+$/)![0];
      } else {
        riteName = match[1];
      }
    } else if (/\bvigil\b/i.test(riteName)) {
      riteName = 'vigil';
    }
    eventShortName += '_' + riteName;
  }
  if (ordo.config.hideDate) {
    eventShortName = ordo.id.toString();
  }
  const accompanimentPrefix = ordo.config.accompanimentExport
    ? ordo.config.accompanimentLectionary
      ? 'RP-AL_'
      : 'EN-OF-CO_'
    : '';
  let result = `${accompanimentPrefix}${date}${idx}${cardTitle}_${eventShortName}${cropped}`;
  result = encodeURIComponent(result)
    .replace(/-?(%[0-9a-fA-F]{2})+-?/g, '-')
    .replace(/-?_-?/g, '_');
  return result;
};
