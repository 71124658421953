/**
 * This file is copied from the api
 */
import { Melody } from 'queries/composition';
type Voice = Melody['voice'];

export const doesHymnTuneHaveAmen = (voice?: Voice) =>
  !!voice &&
  (voice.encoding === 'gabc'
    ? /::\s*(\S+\s+){2}::s*$/.test(voice.data)
    : /\n\[[^\]]*]$/.test(voice.data));

export const removeGabcHymnTuneAmen = (gabc: string) =>
  gabc.replace(/::\s*(\S+\s+){2}::s*$/, '::');

export const removeLilypondHymnTuneAmen = (lilypond: string) =>
  lilypond.replace(
    /(\\bar\s*"[^"]+")?\n\[[^\]]*?(\\bar\s*"[^"]+")?]$/,
    (_, barBeforeAmen, finalBar) => finalBar || barBeforeAmen || '',
  );

export const removeHymnTextAmen = (lyrics: string) =>
  lyrics.replace(/\s*[AÁ]\s+--\s+m[eé]n\.?\s*$/, '');
