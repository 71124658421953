/* eslint-disable @typescript-eslint/no-redeclare */
import { gql, OperationVariables } from '@apollo/client';
import { Season } from 'utils/api-types/ordo/Season';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { CheckForErrorFunction, GQLDocument, GQLQuery } from './gql-generics';
import { Source, SOURCE_NAME_SHORT_FRAGMENT } from './source';
import { TEXT_FRAGMENT, Text } from "./text";

export type TextOption = {
  textCategory: WithNameAndId;
  texts: Text[];
  sources: Source[];
} & WithNameAndId;

export type TextOptionWithMeta = TextOption & {
  textsMeta: {
    name: string | null;
    hideBelowRubric: string | null;
    selectionOnly: boolean | null;
  }[] | null;
  availableData?: {
    [k in 'en'|'la'|'es']?: AvailableData;
  }
};

export type AvailableData = {
  music: boolean;
  recordedAudio: boolean;
  inSsm: boolean;
}

export const TEXT_OPTION_FRAGMENT = gql`
  fragment TextOption on TextOption {
    id
    name
    textCategory {
      id
      name
    }
    texts {
      ...Text
    }
    sources {
      ...SourceNameShort
    }
  }
  ${TEXT_FRAGMENT}
  ${SOURCE_NAME_SHORT_FRAGMENT}
`;

export const TEXT_OPTION_WITH_META_FRAGMENT = gql`
  fragment TextOptionWithTextMeta on TextOption {
    ...TextOption
    textsMeta {
      name
      hideBelowRubric
      selectionOnly
    }
  }
  ${TEXT_OPTION_FRAGMENT}
`;

export const AVAILABLE_DATA_FRAGMENT = gql`
  fragment AvailableData on AvailableData {
    music
    recordedAudio
    inSsm
  }
`

export const TEXT_OPTION_WITH_META_AND_AVAILABLE_DATA_FRAGMENT = gql`
  fragment TextOptionWithTextMetaAndAvailableData on TextOption {
    ...TextOptionWithTextMeta
    availableData {
      en {
        ...AvailableData
      }
      es {
        ...AvailableData
      }
      la {
        ...AvailableData
      }
    }
  }
  ${TEXT_OPTION_WITH_META_FRAGMENT}
  ${AVAILABLE_DATA_FRAGMENT}
`

export const createTextOptionListErrorCheck: <Vars extends OperationVariables = OperationVariables>(queryName: string) => CheckForErrorFunction<{
  Data: {
    textOptions: TextOption[];
  }
  Variables: Vars
}> = (queryName) => (logError, data, variables) => {
  if (!data?.textOptions.length) {
    logError(`No TextOptions on query: ${queryName}`, {
      data,
      variables,
    });
  }
  else {
    data.textOptions.forEach((textOption, index) => {
      if (textOption.texts.length === 0) {
        logError(`No Texts on TextOption in query: ${queryName}`, {
          textOption,
          index,
          variables,
        });
      }
      else {
        textOption.texts.forEach((text, subindex) => {
          if (textOption.texts.length === 0) {
            logError(`No Translations on Text on TextOption in query: ${queryName}`, {
              text,
              textIndex: index,
              translationIndex: subindex,
              variables,
            });
          }
        });
      }
      // should there be a check for textsMeta as well?
    });
  }
}

export interface GetCardOptions extends GQLQuery<GetCardOptions> {
  Variables: WithId;
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetCardOptions: GQLDocument<GetCardOptions> = {
  query: gql`
    query getCardOptions($id: Float!) {
      textOptions: textOptionsForCard(cardId: $id) {
        ...TextOptionWithTextMetaAndAvailableData
      }
    }
    ${TEXT_OPTION_WITH_META_AND_AVAILABLE_DATA_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('GetCardOptions')
}

export interface GetInvariableTextCategoryOptions extends GQLQuery<GetInvariableTextCategoryOptions> {
  Variables: WithId;
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetInvariableTextCategoryOptions: GQLDocument<GetInvariableTextCategoryOptions> = {
  query: gql`
    query getInvariableTextCategoryOptions($id: Float!) {
      textOptions: textOptionsForInvariableTextCategory(id: $id) {
        ...TextOptionWithTextMeta
      }
    }
    ${TEXT_OPTION_WITH_META_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('getInvariableTextCategoryOptions')
}

export interface GetAlleluiaOptions extends GQLQuery<GetAlleluiaOptions> {
  Variables: {
    cardId: number;
    season?: Season;
  }
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetAlleluiaOptions: GQLDocument<GetAlleluiaOptions> = {
  query: gql`
    query getAlleluiaOptions($cardId: Float!, $season: Season) {
      textOptions: alleluiaOptionsForCard(cardId: $cardId, season: $season) {
        ...TextOptionWithTextMeta
      }
    }
    ${TEXT_OPTION_WITH_META_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('GetAlleluiaOptions')
}

export interface GetSeasonalCardOptions extends GQLQuery<GetSeasonalCardOptions> {
  Variables: {
    season: Season;
    categories: number[];
    language?: string;
    query?: string;
  }
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetSeasonalCardOptions: GQLDocument<GetSeasonalCardOptions> = {
  query: gql`
    query getSeasonalCardOptions($season: Season!, $categories: [Float!]!, $query: String, $language: String) {
      textOptions: seasonalTextOptionsForCard(season: $season, categories: $categories, query: $query, language: $language) {
        ...TextOptionWithTextMetaAndAvailableData
      }
    }
    ${TEXT_OPTION_WITH_META_AND_AVAILABLE_DATA_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('GetSeasonalCardOptions')
}

export interface GetSeasonalAlleluiaOptions extends GQLQuery<GetSeasonalAlleluiaOptions> {
  Variables: { season: Season };
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetSeasonalAlleluiaOptions: GQLDocument<GetSeasonalAlleluiaOptions> = {
  query: gql`
    query getSeasonalAlleluiaOptions($season: Season!) {
      textOptions: seasonalAlleluiaOptionsForCard(season: $season) {
        ...TextOptionWithTextMeta
      }
    }
    ${TEXT_OPTION_WITH_META_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('GetSeasonalAlleluiaOptions')
}

export interface GetEucharisticAntiphons extends GQLQuery<GetEucharisticAntiphons> {
  Variables: never;
  Data: { textOptions: TextOptionWithMeta[] };
}
export const GetEucharisticAntiphons: GQLDocument<GetEucharisticAntiphons> = {
  query: gql`
    query eucharisticAntiphons {
      textOptions: eucharisticAntiphons {
        ...TextOptionWithTextMetaAndAvailableData
      }
    }
    ${TEXT_OPTION_WITH_META_AND_AVAILABLE_DATA_FRAGMENT}
  `,
  checkForError: createTextOptionListErrorCheck('GetEucharisticAntiphons')
}

export interface GetTextOption extends GQLQuery {
  Variables: WithId;
  Data: { textOption: TextOptionWithMeta };
}
export const GetTextOption: GQLDocument<GetTextOption> = {
  query: gql`
    query getTextOption($id: Float!) {
      textOption(id: $id) {
        ...TextOptionWithTextMeta
      }
    }
    ${TEXT_OPTION_WITH_META_FRAGMENT}
  `
}
