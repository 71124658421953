import { getContentWidth } from "utils/getContentWidth";

// 72 points to an inch
const PT = 72;

export const toPt = (inches: number) => Math.round(inches * PT);

// always in inches!
export type ExportDimensionSettings = {
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  size: {
    width: number;
    height: number;
  };
};

// these are in inches
const contentWidth = getContentWidth();
const defaultHorizontalMargin = (8.5 - (contentWidth || 5.5)) / 2;
export const defaultDimensionSettings: ExportDimensionSettings = {
  size: {
    width: 8.5,
    height: 11,
  },
  margins: {
    top: 0.75,
    bottom: 0.75,
    left: defaultHorizontalMargin,
    right: defaultHorizontalMargin,
  },
};
export const defaultAccompanimentDimensionSettings: ExportDimensionSettings = {
  ...defaultDimensionSettings,
  margins: {
    ...defaultDimensionSettings.margins,
    top: 0.5,
    bottom: 0.5,
  }
};

// these are in points
export type PDFKitDimensionSettings = {
  size: [number, number];
  margins: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  }
}

export const defaultDimensionsForPDFKit = {
  size: [
    toPt(defaultDimensionSettings.size.width),
    toPt(defaultDimensionSettings.size.height),
  ],
  margins: {
    top: toPt(defaultDimensionSettings.margins.top),
    bottom: toPt(defaultDimensionSettings.margins.bottom),
    left: toPt(defaultDimensionSettings.margins.left),
    right: toPt(defaultDimensionSettings.margins.right),
  }
} as const satisfies PDFKitDimensionSettings;
