import { useBackendLoggedQuery } from 'hooks/queries/useQuery';
import { GetSeasonalAlleluiaOptions } from 'queries/text-option';
import { Season } from 'utils/api-types/ordo/Season';

export const useSeasonalAlleluiaOptionsQuery = (season?: Season) => {
  return useBackendLoggedQuery(
    GetSeasonalAlleluiaOptions,
    {
      variables: season ? { season } : undefined,
      skip: !season
    },
  );
}
