import { css, cx } from '@emotion/css';
import { border } from './border';
import { colorDictionary } from './colorDictionary';

export const largeInput = css`
  padding: 7px 10px;
  border: ${border('Grey')};
  border-radius: 8px;
  min-width: 0;
  width: 100%;

  &:active,
  &:focus {
    border: ${border('Primary')};
  }
`;

export const label = (hasError?: boolean) => cx([css`
  color: ${colorDictionary['Secondary']};
  font-size: 14px;
`, {
  [css`color: ${colorDictionary['Error']}`]: hasError ?? false,
}]);
