import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ParishSize } from 'utils/forms/options/parishSizes';
import { useLogError } from './error/useLogError';

export type SessionStorage = {
  parishSize: ParishSize;
  billYearly: boolean;
  missalQuantity: number;
  addOn: 'Missal Support'|'Complete Digital Add-On';
  missalQuantitySetByUser: boolean;
  groupId: string|undefined;
  debug: string[];
  trace: boolean;
  layoutFilesLastViewed: number[];
  hasSeenMarketingShareToast: boolean;
}

type Booleanable<K extends keyof SessionStorage> = boolean|((initialState: SessionStorage[K]|undefined, storedState: SessionStorage[K]) => boolean);

const makeGetStorageValue = (storageKey: 'sessionStorage' | 'localStorage') => <K extends keyof SessionStorage>(key: K): SessionStorage[K] | null => {
  const item = window[storageKey].getItem(key);
  return item ? JSON.parse(item) : null;
}
export const getSessionStorageValue = makeGetStorageValue('sessionStorage');
export const getLocalStorageValue = makeGetStorageValue('localStorage');
export const getSessionStorageValueWithLocalStorageBackup = <K extends keyof SessionStorage>(key: K) => {
  return getSessionStorageValue(key) ?? getLocalStorageValue(key);
}

export interface UseSessionStorage {
  <K extends keyof SessionStorage>(key: K, initialState: SessionStorage[K]|undefined, override?: Booleanable<K>): [SessionStorage[K], Dispatch<SetStateAction<SessionStorage[K]>>];
  <K extends keyof SessionStorage>(key: K, initialState?: never, override?: never): [SessionStorage[K], Dispatch<SetStateAction<SessionStorage[K]>>];
}

// cf. https://usehooks.com/useLocalStorage/
const makeUseStorage = (storageKey: 'sessionStorage' | 'localStorage'): UseSessionStorage => {
  const getStorageValue = makeGetStorageValue(storageKey);
  return  (key, initialState, override = false) => {
    type K = typeof key;
    type V = SessionStorage[K];
    const logError = useLogError();
    const getInitialStateValue = useCallback(() => {
      try {
        const storedState = getStorageValue(key);
        if (storedState === null || storedState === undefined ||
          override === true ||
          (override && override(initialState, storedState)
        )) {
          if (typeof initialState !== 'undefined') {
            window[storageKey].setItem(key, JSON.stringify(initialState));
          }
          return initialState as V;
        } else {
          return storedState;
        }
      } catch (error) {
        logError(`Failed while getting value ${key} in window.${storageKey}`, { error });
        return initialState as V;
      }
    }, [initialState, key, logError, override]);

    const [storedValue, setStoredValue] = useState<V>(getInitialStateValue);

    const setValue = useCallback((value: SetStateAction<V>) => {
      setStoredValue(storedValue => {
        try {
          const valueToStore = value instanceof Function ? value(storedValue) : value;
          if (typeof valueToStore === 'undefined') {
            window[storageKey].removeItem(key);
          } else {
            window[storageKey].setItem(key, JSON.stringify(valueToStore));
          }
          return valueToStore;
        } catch (error) {
          logError(`Failed while setting value ${key} in window.${storageKey}`, { error });
          return storedValue;
        }
      });
    }, [key, logError]);

    return [storedValue, setValue];
  };
}

export const useSessionStorage = makeUseStorage('sessionStorage');
export const useLocalStorage = makeUseStorage('localStorage');

export const useSessionStorageWithLocalStorageBacking = <K extends keyof SessionStorage>(
  key: K,
  initialState?: SessionStorage[K],
): [SessionStorage[K], Dispatch<SetStateAction<SessionStorage[K]>>] => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(key, initialState);
  const [value, setSessionValue] = useSessionStorage(key, localStorageValue, false);
  const setValue = useCallback((value: SetStateAction<SessionStorage[typeof key]>) => {
    setSessionValue(value);
    setLocalStorageValue(value);
  }, [setLocalStorageValue, setSessionValue]);

  return [value, setValue];
}
