/* eslint-disable @typescript-eslint/no-redeclare */
import { Language } from 'gabc-utils';
import { gql } from '@apollo/client';
import { CollectionTypes } from 'utils/api-types/composition/CollectionTypes';
import { CompositionTypes } from 'utils/api-types/composition/CompositionTypes';
import { MetaDisplayData } from 'utils/api-types/composition/MetaDisplayData';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { Nullable } from 'utils/typescript/Nullable';
import { COMMON_COMPOSITION_FIELDS, ComplexComposition, MelodicComposition, MELODIC_COMPOSITION_FRAGMENT } from './composition';
import { GQLDocument, GQLQuery } from './gql-generics';
import { PermissionSet, PERMISSION_SET_FRAGMENT } from './permission-set';

export type Collection = {
  type: CollectionTypes;
  isFeatured: boolean;
  language: null | Language;
  permissionSet: PermissionSet | null;
} & WithNameAndId;

const COLLECTION_FRAGMENT = gql`
  fragment Collection on Collection {
    id
    name
    type
    isFeatured
    language
    permissionSet {
      ...PermissionSet
    }
  }
  ${PERMISSION_SET_FRAGMENT}
`;

export type CollectionComposition = {
  referenceNumber: string;
  collection: {
    defaultModeName: null | string;
  } & Collection;
  collectionCompositionDisplayData: MetaDisplayData[];
  isSolesmes?: boolean | null;
  composerSource: string | null;
  complexComposition: ComplexComposition | null;
  melodyGroup: ({
    compositions: MelodicComposition[];
  } & WithId) | null;
  defaultHarmonizations: ({
    hasChordSymbols: boolean;
    melody: WithId;
  } & WithId)[];
  collectionName?: string;
  hasRecordedAudio: boolean;
} & WithNameAndId;

export const COLLECTION_COMPOSITION_FRAGMENT = gql`
  fragment CollectionComposition on CollectionComposition {
    id
    name
    referenceNumber
    collectionName @client
    hasRecordedAudio
    collection {
      id
      name
      defaultModeName
      language
      type
      isFeatured
      permissionSet {
        ...PermissionSet
      }
    }
    collectionCompositionDisplayData {
      value
      key
      id
    }
    isSolesmes
    composerSource
    complexComposition {
      ${COMMON_COMPOSITION_FIELDS}
    }
    melodyGroup {
      id
      compositions {
        ...MelodicComposition
      }
    }
    defaultHarmonizations {
      id
      hasChordSymbols
      melody {
        id
      }
    }
  }
  ${MELODIC_COMPOSITION_FRAGMENT}
  ${PERMISSION_SET_FRAGMENT}
`;

export interface GetCollectionComposition extends GQLQuery {
  Variables: WithId;
  Data: { collectionComposition: CollectionComposition };
}
export const GetCollectionComposition: GQLDocument<GetCollectionComposition> = {
  query: gql`
    query getCollectionComposition($id: Float!) {
      collectionComposition(id: $id) {
        ...CollectionComposition
      }
    }
    ${COLLECTION_COMPOSITION_FRAGMENT}
  `
}

export interface GetCollection extends GQLQuery {
  Variables: WithId;
  Data: { collection: Collection };
}
export const GetCollection: GQLDocument<GetCollection> = {
  query: gql`
    query collection($id: Float!) {
      collection(id: $id) {
        ...Collection
      }
    }
    ${COLLECTION_FRAGMENT}
  `
}

export interface FindAntiphonCollections extends GQLQuery {
  Variables: never;
  Data: { collections: Pick<Collection, 'id'|'name'|'language'>[] };
}
export const FindAntiphonCollections: GQLDocument<FindAntiphonCollections> = {
  query: gql`
    query findAntiphonCollections {
      collections: findAntiphonCollections {
        id
        name
        language
      }
    }
  `
}

export interface GetOtherHymns extends GQLQuery {
  Variables: never;
  Data: { otherHymns: Collection };
}
export const GetOtherHymns: GQLDocument<GetOtherHymns> = {
  query: gql`
    query otherHymns {
      otherHymns {
        ...Collection
      }
    }
    ${COLLECTION_FRAGMENT}
  `
}

export interface GetStandardCompositions extends GQLQuery {
  Variables: {
    cardId: number;
    language?: string | null;
  }
  Data: {
    collectionCompositions: CollectionComposition[];
  }
}
export const GetStandardCompositions: GQLDocument<GetStandardCompositions> = {
  query: gql`
    query getStandardCompositions($cardId: Float!, $language: String) {
      collectionCompositions: standardCompositionsForCard(cardId: $cardId, language: $language) {
        ...CollectionComposition
      }
    }
    ${COLLECTION_COMPOSITION_FRAGMENT}
  `
}

export interface GetResourceCompositions extends GQLQuery {
  Variables: {
    cardId: number;
    collectionId?: number;
  }
  Data: {
    collectionCompositions: CollectionComposition[];
  }
}
export const GetResourceCompositions: GQLDocument<GetResourceCompositions> = {
  query: gql`
    query getResourceCompositions($cardId: Float!, $collectionId: Float!) {
      collectionCompositions: resourceCompositionsForCard(cardId: $cardId, collectionId: $collectionId) {
        ...CollectionComposition
      }
    }
    ${COLLECTION_COMPOSITION_FRAGMENT}
  `
}

export interface GetCompositionsForType extends GQLQuery {
  Variables: {
    textId?: number | null;
    type: string;
    offset?: number;
    limit?: number;
    language: Language | null;
    liturgicalYear?: number | null;
  }
  Data: {
    compositions: CollectionComposition[];
  }
}
export const GetCompositionsForType: GQLDocument<GetCompositionsForType> = {
  query: gql`
    query getCompositionsForCollectionType($textId: Float, $type: String!, $offset: Float, $limit: Float, $language: String, $liturgicalYear: Float) {
      compositions: pagedCompositionsForCollectionType(textId: $textId, type: $type, offset: $offset, limit: $limit, language: $language, liturgicalYear: $liturgicalYear) {
        ...CollectionComposition
      }
    }
    ${COLLECTION_COMPOSITION_FRAGMENT}
  `
}

export interface GetCompositionsForCollection extends GQLQuery {
  Variables: {
    collectionId: number;
    mode?: string;
  }
  Data: {
    collectionCompositions: CollectionComposition[];
  }
}
export const GetCompositionsForCollection: GQLDocument<GetCompositionsForCollection> = {
  query: gql`
    query getCompositionsForCollection($collectionId: Float!, $mode: String) {
      collectionCompositions: compositionsForCollection(collectionId: $collectionId, mode: $mode) {
        ...CollectionComposition
      }
    }
    ${COLLECTION_COMPOSITION_FRAGMENT}
  `
}

export interface GetFeaturedCollections extends GQLQuery {
  Variables: Partial<Nullable<{
    primaryText: number;
    secondaryText: number;
    language?: string;
  }>>
  Data: {
    featuredCollections: Collection[];
  }
}
export const GetFeaturedCollections: GQLDocument<GetFeaturedCollections> = {
  query: gql`
    query getFeaturedCollections($primaryText: Float, $secondaryText: Float, $language: String) {
      featuredCollections: collectionsForText(primaryText: $primaryText, secondaryText: $secondaryText, language: $language, isFeatured: true) {
        ...Collection
      }
    }
    ${COLLECTION_FRAGMENT}
  `
}

export interface GetResourceCollections extends GQLQuery {
  Variables: Partial<{
    liturgicalYear: number;
    compositionType: CompositionTypes;
    collectionType: CollectionTypes;
    isFeatured: boolean;
    category: number;
    minLines: number;
    maxLines: number;
  }>
  Data: {
    collections: Collection[];
  }
}
export const GetResourceCollections: GQLDocument<GetResourceCollections> = {
  query: gql`
    query getResourceCollections (
      $liturgicalYear: Float,
      $compositionType: CompositionType,
      $collectionType: CollectionType,
      $isFeatured: Boolean,
      $category: Float,
      $minLines: Float,
      $maxLines: Float
    ) {
      collections(
        liturgicalYear: $liturgicalYear,
        compositionType: $compositionType,
        collectionType: $collectionType,
        isFeatured: $isFeatured,
        category: $category,
        minLines: $minLines,
        maxLines: $maxLines
      ) {
        ...Collection
      }
    }
    ${COLLECTION_FRAGMENT}
  `
}

export interface GetPsalmToneCollections extends GQLQuery {
  Variables: {
    cardId: number;
  }
  Data: {
    collections: Collection[];
  }
}
export const GetPsalmToneCollections: GQLDocument<GetPsalmToneCollections> = {
  query: gql`
    query psalmToneCollections($cardId: Float!) {
      collections: psalmToneCollectionsForCard(cardId: $cardId) {
        ...Collection
      }
    }
    ${COLLECTION_FRAGMENT}
  `
}
