import { UpsellModal } from 'components/ordo/modals/UpsellModal';
import { useCallback, useState } from 'react';
import { UserRoles, doesGroupHaveRoles } from './permission-set/useHasRole';
import { JwtUser, getSelectedGroup } from './useUser';

export const useUpsellModal = (user: JwtUser|undefined|null) => {
  const group = getSelectedGroup(user);
  const [upsellModalInterest, setUpsellModalInterest] = useState('');
  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const [toEssentials, setToEssentials] = useState(false);
  const openUpsellModal = useCallback((expressedInterest: string, toEssentials: boolean = false) => {
    setUpsellModalInterest(expressedInterest);
    setToEssentials(toEssentials);
    setShowUpsellModal(true);
  }, []);
  const hideUpsellModal = useCallback(() => setShowUpsellModal(false), []);
  const allowUpsellModal = !!group && doesGroupHaveRoles(group, { any: [UserRoles.BasicUser, UserRoles.MissalSupport, UserRoles.EssentialsUser, UserRoles.MissalCompanion]});

  return {
    openUpsellModal,
    upsellModalMarkup: allowUpsellModal && <UpsellModal
      toEssentials={toEssentials}
      show={showUpsellModal}
      onHide={hideUpsellModal}
      expressedInterest={upsellModalInterest}
    />
  };
}
