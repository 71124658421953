import { Addon, Plan, Tier } from 'queries/plan';
import { PlanTerm } from 'utils/api-types/plans';
import { ParishSize, parishSizeQuantities } from 'utils/forms/options/parishSizes';

export interface PlanPriceArguments {
  plan?: Plan|Addon|null;
  addOn?: Addon|null;
  parishSize?: ParishSize;
  isYearly?: boolean;
  quantity?: number;
}

export const getTierPrice = (tiers: Tier[], quantity: number): number | null => {
  const tier = [...tiers]
    .sort((a, b) => a.startingUnit - b.startingUnit)
    .find(t => !t.endingUnit || t.endingUnit >= quantity);
  const tierPrice = tier?.price ?? null;
  return tierPrice;
}

export const getPlanPrice = ({
  plan,
  addOn,
  parishSize,
  isYearly = plan?.periodTerm !== PlanTerm.MONTHLY,
  quantity,
}: PlanPriceArguments): number | null => {
  if (!plan || !(parishSize || quantity) || (addOn && (!parishSize || !quantity))) {
    return null;
  }

  if (addOn) {
    // use quantity for plan if provided
    const planRate = getPlanPrice({plan, isYearly, ...(quantity ? {quantity} : {parishSize})});
    // addOn always uses parish size
    const addOnRate = getPlanPrice({plan: addOn, parishSize, isYearly});
    return planRate ? (planRate + (addOnRate ?? 0)) : 0;
  }

  const { tiers, pricingModel } = plan;

  if (!tiers.length) {
    return isYearly ? plan.annualRate : plan.monthlyRate;
  }

  const parishQuantity = parishSize && parishSizeQuantities[parishSize];
  const effectiveQuantity = quantity || parishQuantity;
  if (!effectiveQuantity) {
    return null;
  }

  const tierPrice = getTierPrice(tiers, effectiveQuantity)
  if (tierPrice !== null) {
    switch(pricingModel) {
      case 'stairstep':
        return tierPrice;
      case 'volume':
        return tierPrice * effectiveQuantity;
    }
  }
  return null;
};
