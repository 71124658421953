import { MutationHookOptions, MutationTuple, useMutation as useApolloMutation } from '@apollo/client';
import { useApolloOnError } from 'hooks/error/useLogError';
import { GQLDocument, GQLMutation, GQLQuery, UpdateQueryContext } from 'queries/gql-generics';
import { useMemo } from 'react';

export function useMutation<Q extends GQLMutation<Q, U>, U extends GQLQuery<U>>(query: GQLDocument<GQLMutation<Q, U>>, options?: MutationHookOptions<Q['Data'], Q['Variables'], UpdateQueryContext<U>>): MutationTuple<Q['Data'], Q['Variables'], UpdateQueryContext<U>> {
  const onError = useApolloOnError('Mutation', query.mutation, options);
  const optionsToUse = useMemo(() => ({
    ...options,
    onError,
    update: options?.update || query.updater,
    optimisticResponse: options?.optimisticResponse || query.optimisticResponse,
  }), [onError, options, query.updater, query.optimisticResponse]);

  return useApolloMutation(query.mutation, optionsToUse);
}
