import { css, cx } from '@emotion/css';
import { BriefOrdoCardSummary } from 'queries/card-fragment';
import React, { forwardRef, memo } from 'react';
import { PageBreakClass } from 'utils/export/convertToPdfBlocks';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import {
  fontSerifCss,
} from 'utils/styles';
import { CardSummary } from './CardSummary';

export type OrdoSummaryExportProps = {
  cards: BriefOrdoCardSummary[];
};
// This is the ordo outline that is optionally included at the beginning of the export
const _OrdoSummaryExport = forwardRef<
  HTMLDivElement,
  OrdoSummaryExportProps
>(({ cards }, ref?: React.Ref<HTMLDivElement>) => (
  <div
    ref={ref}
    data-export-pad-top='24'
    className={cx(
      'summary-section _EXPORT',
      style,
      PageBreakClass.PageBreakAfter,
    )}
  >
    {cards.filter(card => card.type !== CardTypes.RUBRIC).map((card) => (
      <CardSummary
      key={card.id}
      className={'card-summary _export-as-table'}
      {...{ card }}
      showAudioButton={false}
      includeSecondary={
        ![CardTypes.READING, CardTypes.AD_LIBITUM_READING].includes(
          card.type,
        )
      }
      groupReferenceAndSecondary={true}
      cardReferenceFallback="—"
      makeLinkInPdf={true}
    />
    ))}
  </div>
));

export const OrdoSummaryExport = memo(_OrdoSummaryExport)

const style = css`
  & {
    ${fontSerifCss}
    font-weight: 400;
    width: 100%;
    .card-summary {
      margin-bottom: 12px;
      display: flex;

      .card-name {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
        margin-right: 10px;
        font-size: 1.125rem;
      }
      .info-group {
        flex: 1 0 0;
        padding-top: 0.125em;
        .card-reference {
          display: inline-block;
          margin-right: 5px;
        }
        .card-secondary-info {
          display: inline-block;
          padding-top: 0.25em;
          font-size: 0.75rem;
          font-style: italic;
        }
      }
      .card-tags {
        & > div {
          margin: 0.5em 0 -1.75em 5px;
          padding: 0.5em;
          border: 1px solid;
          border-radius: 5px;
          float: right;
          font-size: 0.75rem;
        }
        &.grey-tags > div {
          border: 0 solid;
          background-color: rgba(0, 0, 0, 8.5%);
        }
      }
    }
  }
`;
