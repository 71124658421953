import React from 'react';

interface WarnProps  {
  message: string;
}

export const Warn: React.FC<WarnProps> = ({message}) => {
  return (
    <>{message}</>
  );
}
