/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { AttributeKey } from 'utils/api-types/resource/AttributeKey';
import { Music, Resource } from 'utils/api-types/resource/Resource';
import { ResourceGroupTypesSeedId } from '../utils/api-types/resource/ResourceGroupTypesSeedId';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';
import { createMutationCacheUpdater } from './updaters/createMutationCacheUpdater';

// type defined in utils/api-types/resource/Resource.ts
export const FILE_FRAGMENT = gql`
  fragment File on File {
    id
    name
    url
    awsKey
  }
`;

// type defined in utils/api-types/resource/Resource.ts
export const MUSIC_RESOURCE_FRAGMENT = gql`
  fragment MusicResource on MusicResource {
    id
    name
    combinedGabc
    mode
    dropCap
    alignment
  }
`;

// type defined in utils/api-types/resource/Resource.ts
const RESOURCE_FRAGMENT = gql`
  fragment Resource on Resource {
    id
    note {
      id
      content
      title
      language
      alignment
    }
    file {
      ...File
    }
    music: musicResource {
      ...MusicResource
    }
    resourceType {
      id
      name
    }
  }
  ${FILE_FRAGMENT}
  ${MUSIC_RESOURCE_FRAGMENT}
`;

export type ChildResource = {
  id: string;
  isDefault?: true|null;
  resource: Resource;
};

const CHILD_RESOURCE_FRAGMENT = gql`
  fragment ChildResource on ChildResource {
    id
    isDefault
    resource {
      ...Resource
    }
  }
  ${RESOURCE_FRAGMENT}
`;

export type Attribute = {
  name: AttributeKey;
  value: string;
} & WithId;

export type ResourceGroup = {
  userId: string;
  groupId: string;
  title: string;
  collection: string | null;
  defaultResourceId: string | null;
  isDeleted: boolean;
  effectiveType: ({
    name: ResourceGroupTypesSeedId;
  } & WithId)|null;
  resources: ChildResource[];
  resourceReference: ({
    reference: string;
    collection: {
      edition: string|null;
    } & WithNameAndId;
    attributes: Attribute[];
  } & WithNameAndId)|null;
  attributes: Attribute[];
} & WithId;

const RESOURCE_GROUP_FRAGMENT = gql`
  fragment ResourceGroup on ResourceGroup {
    id
    userId
    groupId
    title
    collection
    defaultResourceId
    isDeleted
    attributes {
      id
      name
      value
    }
    effectiveType {
      id
      name
    }
    resourceReference {
      id
      name
      reference
      collection {
        id
        name
        edition
      }
      attributes {
        id
        name
        value
      }
    }
    resources {
      ...ChildResource
    }
  }
  ${CHILD_RESOURCE_FRAGMENT}
`;

export type ResourceType = WithNameAndId;

export interface ResourceTypes extends GQLQuery {
  Variables: { resourceGroupTypeId?: number | null };
  Data: { resourceTypes: ResourceType[] };
}
export const ResourceTypes: GQLDocument<ResourceTypes> = {
  query: gql`
    query resourceTypes($resourceGroupTypeId: Float) {
      resourceTypes(resourceGroupTypeId: $resourceGroupTypeId) {
        name
        id
      }
    }
  `
}

export interface GetResourceGroup extends GQLQuery {
  Variables: WithId;
  Data: { resourceGroup: ResourceGroup };
}
export const GetResourceGroup: GQLDocument<GetResourceGroup> = {
  query: gql`
    query getResourceGroup($id: Float!) {
      resourceGroup(id: $id) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
}

export interface GetResourceGroups extends GQLQuery {
  Variables: {
    resourceGroupTypeName: string;
    groupId: string;
  }
  Data: { resourceGroups: ResourceGroup[] };
}
export const GetResourceGroups: GQLDocument<GetResourceGroups> = {
  query: gql`
    query getResourceGroups($resourceGroupTypeName: String!, $groupId: String!) {
      resourceGroups: resourceGroupsByType(resourceGroupTypeName: $resourceGroupTypeName, groupId: $groupId) {
        ...ResourceGroup
      }
    }

    ${RESOURCE_GROUP_FRAGMENT}
  `
}

export interface DeleteResourceGroup extends GQLMutation<DeleteResourceGroup, GetResourceGroups> {
  Variables: {
    resourceGroupId: number;
    hardDelete?: boolean;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const DeleteResourceGroup: GQLDocument<DeleteResourceGroup> = {
  mutation: gql`
    mutation deleteResourceGroup($resourceGroupId: Float!, $hardDelete: Boolean) {
      resourceGroup: deleteResourceGroup(resourceGroupId: $resourceGroupId, hardDelete: $hardDelete) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetResourceGroups,
    (data, { context }) => context?.updateQueryVariables?.groupId && data.resourceGroup.effectiveType
    ? {
      resourceGroupTypeName: data.resourceGroup.effectiveType.name,
      groupId: context.updateQueryVariables.groupId,
    }
    : false,
    (draft, mutationData) => {
      const deletedIdx = draft.resourceGroups.findIndex(resourceGroup => resourceGroup.id === mutationData.resourceGroup.id);

      if (deletedIdx === undefined || deletedIdx < 0) {
        return false;
      }

      draft.resourceGroups.splice(deletedIdx, 1)
      return true;
    }
  )
}

export interface CreateResourceGroup extends GQLMutation<CreateResourceGroup, GetResourceGroups> {
  Variables: {
    groupId: string;
    typeId: number;
    referenceId?: number;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const CreateResourceGroup: GQLDocument<CreateResourceGroup> = {
  mutation: gql`
    mutation createResourceGroup(
      $groupId: String!,
      $typeId: Float!,
      $referenceId: Float,
    ) {
      resourceGroup: createResourceGroup(
        groupId: $groupId,
        typeId: $typeId,
        referenceId: $referenceId,
      ) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetResourceGroups,
    (data, { context }) => context?.updateQueryVariables?.groupId && data.resourceGroup.effectiveType
    ? {
      resourceGroupTypeName: data.resourceGroup.effectiveType.name,
      groupId: context.updateQueryVariables.groupId,
    }
    : false,
    (draft, mutationData) => {
      draft.resourceGroups.push(mutationData.resourceGroup);
      return true;
    }
  )
};

export interface RenameResourceGroup extends GQLMutation<RenameResourceGroup> {
  Variables: {
    resourceGroupId: number;
    title: string;
  };
  Data: { resourceGroup: Pick<ResourceGroup,'id'|'title'|'isDeleted'> };
}
export const RenameResourceGroup: GQLDocument<RenameResourceGroup> = {
  mutation: gql`
    mutation renameResourceGroup(
      $resourceGroupId: Float!,
      $title: String!
    ) {
      resourceGroup: renameResourceGroup(
        resourceGroupId: $resourceGroupId,
        title: $title
      ) {
        id
        title
        isDeleted
      }
    }
  `,
  optimisticResponse: ({ resourceGroupId, title }) => ({
    resourceGroup: {
      __typename: 'ResourceGroup',
      id: resourceGroupId,
      title,
      isDeleted: false,
    } as RenameResourceGroup['Data']['resourceGroup']
  }),
};

export interface SetResourceGroupCollection extends GQLMutation<SetResourceGroupCollection> {
  Variables: {
    resourceGroupId: number;
    collection: string;
  };
  Data: { resourceGroup: Pick<ResourceGroup,'id'|'collection'|'effectiveType'|'isDeleted'> };
}
export const SetResourceGroupCollection: GQLDocument<SetResourceGroupCollection> = {
  mutation: gql`
    mutation setResourceGroupCollection(
      $resourceGroupId: Float!,
      $collection: String!
    ) {
      resourceGroup: setResourceGroupCollection(
        resourceGroupId: $resourceGroupId,
        collection: $collection
      ) {
        id
        collection
        isDeleted
        effectiveType {
          id
          name
        }
      }
    }
  `
};

export interface SetResourceGroupAttribute extends GQLMutation<SetResourceGroupAttribute> {
  Variables: {
    resourceGroupId: number;
    name: string;
    value: string | null;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const SetResourceGroupAttribute: GQLDocument<SetResourceGroupAttribute> = {
  mutation: gql`
    mutation setResourceGroupAttribute(
      $resourceGroupId: Float!,
      $name: String!,
      $value: String
    ) {
      resourceGroup: setResourceGroupAttribute(
        resourceGroupId: $resourceGroupId,
        name: $name,
        value: $value
      ) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
};

export interface AddFileToResourceGroup extends GQLMutation<AddFileToResourceGroup> {
  Variables: {
    resourceGroupId: number;
    awsKey: string;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const AddFileToResourceGroup: GQLDocument<AddFileToResourceGroup> = {
  mutation: gql`
    mutation addFileToResourceGroup(
      $resourceGroupId: Float!,
      $awsKey: String!
    ) {
      resourceGroup: addFileToResourceGroup(
        resourceGroupId: $resourceGroupId,
        awsKey: $awsKey
      ) {
        ...ResourceGroup
      }
    }

    ${RESOURCE_GROUP_FRAGMENT}
  `
};

export interface CreateNoteOnResourceGroup extends GQLMutation<CreateNoteOnResourceGroup> {
  Variables: {
    resourceGroupId: number;
    content?: string | null;
    title?: string | null;
  };
  Data: {
    createNoteOnResourceGroup: {
      resourceGroup: ResourceGroup;
      noteId: number;
    }
  };
}
export const CreateNoteOnResourceGroup: GQLDocument<CreateNoteOnResourceGroup> = {
  mutation: gql`
    mutation createNoteOnResourceGroup(
      $resourceGroupId: Float!
      $content: String
      $title: String
    ) {
      createNoteOnResourceGroup(
        resourceGroupId: $resourceGroupId
        content: $content
        title: $title
      ) {
        noteId
        resourceGroup {
          ...ResourceGroup
        }
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
};

export interface CreateMusicResourceOnResourceGroup extends GQLMutation<CreateMusicResourceOnResourceGroup> {
  Variables: {
    resourceGroupId: number;
    combinedGabc: string;
    name?: string | null;
  };
  Data: {
    createMusicResourceOnResourceGroup: {
      resourceGroup: ResourceGroup;
      musicResourceId: number;
    }
  };
}
export const CreateMusicResourceOnResourceGroup: GQLDocument<CreateMusicResourceOnResourceGroup> = {
  mutation: gql`
    mutation createMusicResourceOnResourceGroup(
      $resourceGroupId: Float!
      $combinedGabc: String!
      $name: String
    ) {
      createMusicResourceOnResourceGroup(
        resourceGroupId: $resourceGroupId
        combinedGabc: $combinedGabc
        name: $name
      ) {
        musicResourceId
        resourceGroup {
          ...ResourceGroup
        }
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
};

export interface UpdateMusicResource extends GQLMutation<UpdateMusicResource> {
  Variables: {
    id: number;
    name?: string;
    combinedGabc?: string;
    mode?: string;
    alignment?: Music['alignment'];
    dropCap?: boolean;
  }
  Data: { musicResource: Music };
}
export const UpdateMusicResource: GQLDocument<UpdateMusicResource> = {
  mutation: gql`
    mutation updateMusicResource($id: Float!, $name: String, $combinedGabc: String, $mode: String, $alignment: String, $dropCap: Boolean) {
      note: updateMusicResource(id: $id, name: $name, combinedGabc: $combinedGabc, mode: $mode, alignment: $alignment, dropCap: $dropCap) {
        ...MusicResource
      }
    }
    ${MUSIC_RESOURCE_FRAGMENT}
  `,
}


export interface UpdateResourceType extends GQLMutation<UpdateResourceType> {
  Variables: {
    resourceId: number;
    resourceTypeId: number;
  };
  Data: { resource: Resource };
}
export const UpdateResourceType: GQLDocument<UpdateResourceType> = {
  mutation: gql`
    mutation updateResourceType(
      $resourceId: Float!,
      $resourceTypeId: Float!,
    ) {
      resourceType: updateResourceType(
        resourceId: $resourceId,
        resourceTypeId: $resourceTypeId
      ) {
        ...Resource
      }
    }
    ${RESOURCE_FRAGMENT}
  `
};

export interface SetResourceGroupReference extends GQLMutation<SetResourceGroupReference, GetResourceGroups> {
  Variables: {
    resourceReferenceId: number;
    resourceGroupId: number;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const SetResourceGroupReference: GQLDocument<SetResourceGroupReference> = {
  mutation: gql`
    mutation setReferenceOrMerge(
      $resourceReferenceId: Float!,
      $resourceGroupId: Float!
    ) {
      resourceGroup: setReferenceOrMerge(
        resourceReferenceId: $resourceReferenceId,
        resourceGroupId: $resourceGroupId
      ) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetResourceGroups,
    (data, { variables }, cache) => {
      if (!variables?.resourceGroupId || variables.resourceGroupId === data.resourceGroup.id) {
        return false;
      }

      const oldResourceGroup = cache.readFragment<ResourceGroup>({
        id: `ResourceGroup-${variables.resourceGroupId}`,
        fragment: RESOURCE_GROUP_FRAGMENT,
        fragmentName: 'ResourceGroup',
      });

      return oldResourceGroup?.effectiveType?.name
      ? {
        resourceGroupTypeName: oldResourceGroup.effectiveType.name,
        groupId: oldResourceGroup.groupId,
      }
      : false;
    },
    (draft, _, { variables }) => {
      if (!variables) {
        return false;
      }
      const index = draft.resourceGroups.findIndex(({id}) => id === variables.resourceGroupId);
      if (index < 0) {
        return false;
      }
      // remove it
      draft.resourceGroups.splice(index, 1);
      return true;
    }
  ),
};

export interface SetDefaultResource extends GQLMutation<SetDefaultResource> {
  Variables: {
    resourceGroupId: number;
    defaultResourceId: number;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const SetDefaultResource: GQLDocument<SetDefaultResource> = {
  mutation: gql`
    mutation setDefaultResource(
      $resourceGroupId: Float!,
      $defaultResourceId: Float!
    ) {
      resourceGroup: setDefaultResource(
        resourceGroupId: $resourceGroupId,
        defaultResourceId: $defaultResourceId
      ) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
};

export interface DeleteResource extends GQLMutation<DeleteResource> {
  Variables: {
    resourceId: number;
  };
  Data: { resourceGroup: ResourceGroup };
}
export const DeleteResource: GQLDocument<DeleteResource> = {
  mutation: gql`
    mutation deleteResource($resourceId: Float!) {
      resourceGroup: deleteResource(resourceId: $resourceId) {
        ...ResourceGroup
      }
    }
    ${RESOURCE_GROUP_FRAGMENT}
  `
}
