import { OrdoContext } from 'context/OrdoContext';
import { CardContext } from 'context/CardContext';
import { Text, TranslationOnText } from 'queries/text';
import { useContext } from 'react';
import { Language } from 'utils/api-types/Language';

export const getTranslationForLanguage = (
  language: Language,
  text?: Text | null,
  fallbackLanguage: Language | null = 'la',
): TranslationOnText | undefined => {
  const translation = text?.officialTranslations.find(
    (t) => t.language === language,
  );
  if (!translation && fallbackLanguage) {
    return getTranslationForLanguage(fallbackLanguage, text, null);
  }
  return translation;
};

export const useDefaultLanguageTranslation = (
  text?: Text | null,
  languageOverride?: Language | null,
) => {
  const cardLanguage = useContext(CardContext).config.language;
  const { defaultLanguage } = useContext(OrdoContext).ordo;
  const language = languageOverride || cardLanguage || defaultLanguage;
  // use Latin as a fallback language of English, and English as a fallback of Spanish:
  const fallbackLanguage = (['la','en','es'] as Language[]).find((_, i, langs) => langs[i + 1] === language);
  return getTranslationForLanguage(language, text, fallbackLanguage);
};
