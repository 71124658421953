import { VerseText, GabcPsalmTones, Language } from 'gabc-utils';
import replaceTildeWithUndertie from 'utils/markup/replaceTildeWithUndertie';
import { replaceLORDWithSmallCaps } from './replaceLORDWithSmallCaps';

export const processFormulaicGabc = (
  texts: string[],
  language: Language,
  gpt?: GabcPsalmTones,
  isDoxology?: boolean,
  useVersicleSymbol?: boolean,
  removeSolesmesMarkings = false,
  startingVerseNumber = 1,
): string[] | null => {
  if (!texts || texts.length === 0 || !gpt) {
    return [];
  }

  const formulaicVerses = texts.map((verse, verseIndex) => {
    if (language === 'es') {
      verse = replaceTildeWithUndertie(verse);
    }
    const vt = new VerseText({text: verse, language});
    return replaceLORDWithSmallCaps(
      vt.withGabc(gpt, {
        startVersesOnNewLine: false,
        stripFlexMediantSymbols: false,
        addInitialVerseNumber: isDoxology ? 'D' : useVersicleSymbol ? '℣' : verseIndex + startingVerseNumber,
        removeSolesmesMarkings,
      })
    );
  });
  return formulaicVerses;
};
