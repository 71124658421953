export const VEROVIO_NOTE_NAMES = [
  'c',
  'df',
  'd',
  'ef',
  'e',
  'f',
  'gf',
  'g',
  'af',
  'a',
  'bf',
  'b',
];
