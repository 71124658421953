import { useEffect, useState, useMemo } from 'react';

/**
 *
 * @param gabcCount number of GABCs to track rendering of
 * @returns
 */
export const useGabcsHaveRendered = (
  gabcCount: number,
): [boolean, (() => void)[]] => {
  const [gabcsHaveRendered, setGabcsHaveRendered] = useState([false]);
  const [allGabcsHaveRendered, setAllGabcsHaveRendered] = useState(false);

  useEffect(() => {
    setGabcsHaveRendered(new Array(gabcCount).fill(false));
  }, [gabcCount]);

  const setOtherAntiphonGabcsRendered = useMemo(() => {
    return new Array(gabcCount)
      .fill(false)
      .map(
        (_, i) => () =>
          setGabcsHaveRendered((prev) => [
            ...prev.slice(0, i),
            true,
            ...prev.slice(i + 1),
          ]),
      );
  }, [gabcCount]);

  useEffect(() => {
    setAllGabcsHaveRendered(
      gabcsHaveRendered.every((hasRendered) => hasRendered),
    );
  }, [gabcsHaveRendered, gabcCount]);
  return [allGabcsHaveRendered, setOtherAntiphonGabcsRendered];
};
