import { OrdoContext } from 'context/OrdoContext';
import React, { useContext } from 'react';
import { TextDisplayType } from 'utils/api-types/ordo/card/ReadingSettingsTypes';
import { Season } from 'utils/api-types/ordo/Season';
import { processPlainText } from 'utils/markup';
import { Translation } from './Translation';

interface PlainTextTranslationProps {
  text?: string;
  englishTranslation?: string;
  textInterjected?: string;
  textInterjectedBefore?: string;
  scriptureReference?: string;
  sourceNames?: string[];
  showAll?: boolean;
  showSource?: boolean;
  showCanonicalSource?: boolean;
  className?: string;
  textDisplay?: TextDisplayType;
  exportPadBottom?: number;
  exportPadTop?: number;
  allowFullText?: boolean;
  columns?: number | null;
  includeGloryBe?: boolean | null;
  addExportClass?: boolean;
  paragraphClassName?: string;
}

export const PlainTextTranslation: React.FC<PlainTextTranslationProps> = ({
  text,
  englishTranslation,
  textInterjected,
  textInterjectedBefore,
  scriptureReference,
  sourceNames,
  showAll = true,
  showSource,
  showCanonicalSource,
  className = '',
  textDisplay,
  exportPadBottom,
  exportPadTop,
  allowFullText = true,
  columns,
  includeGloryBe,
  addExportClass = true,
  paragraphClassName,
}) => {

  const isEasterTime = useContext(OrdoContext).ordo.season === Season.EASTER;
  const processedText = processPlainText(text, isEasterTime);
  const processedEnglishTranslation = englishTranslation && processPlainText(englishTranslation, isEasterTime);
  const translation = <Translation
    exportPadBottom={exportPadBottom}
    exportPadTop={exportPadTop}
    text={processedText}
    englishTranslation={processedEnglishTranslation}
    textInterjected={textInterjected}
    textInterjectedBefore={textInterjectedBefore}
    scriptureReference={scriptureReference}
    sourceNames={sourceNames}
    showAll={showAll}
    showSource={showSource}
    showCanonicalSource={showCanonicalSource}
    className={className}
    textDisplay={textDisplay}
    allowFullText={allowFullText}
    columns={columns}
    includeGloryBe={includeGloryBe}
    addExportClass={addExportClass}
    paragraphClassName={paragraphClassName}
  />;
  return <div className='plain-text-translation'>{translation}</div>;
}
