import { css } from '@emotion/css';
import { LiturgicalColor } from 'utils/api-types/ordo/LiturgicalColor';

export const colorDictionary = {
  'Primary': 'var(--primary, #0C79BF)',
  'Primary Light': 'var(--primary-light, #E6F1F8)',
  'Primary Medium': 'var(--primary-medium, #D6EFFF)',
  'Primary Dark': 'var(--primary-dark, #0A6CA9)',
  'Secondary': '#6E6E6E',
  'Inactive Text': '#6E6E6E',
  'Input': '#F4F4F6',
  'Grey 1': '#F4F4F6',
  'Primary Text': '#2D2D2D',
  'Error': '#DD2228',
  'Rubric': '#DD2228',
  'Red': '#DD2228',
  'Error Light': '#FADEDF',
  'White': '#FFFFFF',
  'Grey': '#A9A9A9',
  'Grey 3': '#FBFBFB',
  'Background': '#FBFBFB',
  'Green': '#029789',
  'Light Green': '#71C5BD',
  'Dark Green': '#0B2524',
  'Gold': '#F5AC1C',
  'Grey 2': '#A9A9A9',
  'Grey 4': '#D7D7D7',
  'Grey 5': '#DEE2E6',
  'Grey 6': '#EBEBEB',
  'Grey 7': '#999999',
  'Cream': '#FEF4E3',
  'Dark': '#0B252D',
  'G1': '#E6E8ED',
  'G2': '#F0F1F2',
  'G3': '#F4F4F6',
  // these are equivalent to the primary color set usually, except that they are always blue, even in role support
  'Primary Blue': '#0C79BF',
  'Primary Light Blue': '#E6F1F8',
  'Primary Dark Blue': '#0A6CA9',
  'Transparent White': 'rgba(255, 255, 255, 0.15)',
  'Blue Gradient Highlight': '#59B1E8',
  'Glow Outline': '#0099FF',
} as const;

export const goalColors = {
  'Persian Blue': '#0867A4',
  'Royal Blue': '#3355DB',
  'Dark Orchid': '#9440C4',
  'Lavender Indigo': '#9C66FE',
  'Rose Pink': '#EA66CB',
  'Amaranth': '#ED3156',
  'Gold': '#F5AC1C',
  'Dark Cyan': '#029789',
  'Caribbean Green': '#0ECD91',
};

export const liturgicalColors: {[key in LiturgicalColor]: string} = {
  'green': '#1F7415',
  'violet': '#7F38C2',
  'white': '#E8E0C7',
  'red': '#D41118',
  'rose': '#F28295',
}

export const themeDefinitions = {
  blue: css`
  &:root {
    --primary: #0C79BF;
    --primary-light: #E6F1F8;
    --primary-medium: #D6EFFF;
    --primary-dark: #0A6CA9;
  }
`,
  purple: css`
  &:root {
    --primary: #8555FF;
    --primary-light: #E7DDFF;
    --primary-medium: #D6C9F2;
    --primary-dark: #7444EE;
  }
`,
}

export const colorStyles: {[key in keyof typeof colorDictionary]: string} = Object.keys(colorDictionary)
  .reduce((obj, key: string) => ({
    ...obj,
    [key]: css`color: ${colorDictionary[key as keyof typeof colorDictionary]};`
  }), {} as typeof colorDictionary);
