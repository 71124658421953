import { useQuery } from 'hooks/queries/useQuery';
import { GetGloryBe } from 'queries/text';
import { Language } from 'utils/api-types/Language';
import { useDefaultLanguageTranslation } from './useDefaultLanguageTranslation';

export const useGloryBeText = () => {
  const { data } = useQuery(GetGloryBe, { fetchPolicy: 'cache-first' });
  return data?.gloryBe;
}

export const useGloryBeTranslation = (languageOverride?: Language | null) => {
  return useDefaultLanguageTranslation(useGloryBeText(), languageOverride);
}
