import React from 'react';
import { LoadingLogo } from 'components/shared/LoadingLogo';


export const LoadingScreen: React.FC = () => {
  return (
    <div id='loading-screen' className='container-fluid px-15'>
      <div className='image-container d-flex justify-content-center align-items-center'>
        <LoadingLogo />
      </div>
    </div>
  );
};
