/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { TextAlignment } from 'components/ordo/cards/notes/TextAlignment';
import { WithId } from 'utils/api-types/WithNameAndId';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';

export type Note = {
  content: string | null;
  title: string | null;
  alignment: TextAlignment | null;
} & WithId;

export const NOTE_FRAGMENT = gql`
  fragment Note on Note {
    id
    title
    content
    alignment
  }
`;

export interface GetNote extends GQLQuery {
  Variables: WithId;
  Data: { note: Note };
}
export const GetNote: GQLDocument<GetNote> = {
  query: gql`
    query getNote($id: Float!) {
      note(id: $id) {
        ...Note
      }
    }
    ${NOTE_FRAGMENT}
  `
}

export interface UpdateNote extends GQLMutation<UpdateNote> {
  Variables: {
    id?: number;
    title?: string;
    content?: string;
    alignment?: TextAlignment;
  }
  Data: { note: Note };
}
export const UpdateNote: GQLDocument<UpdateNote> = {
  mutation: gql`
    mutation updateNote($id: Float, $title: String, $content: String, $alignment: String) {
      note: updateNote(id: $id, title: $title, content: $content, alignment: $alignment) {
        ...Note
      }
    }
    ${NOTE_FRAGMENT}
  `,
}
