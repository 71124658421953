import { Font } from "./PdfBlockTypes";

export function setFontWithRestore(
  pdf: PDFKit.PDFDocument,
  fontSetting: Font,
  block?: Font,
) {
  const defaultFont = pdf.options.font || block?.fontFamily || 'Times';
  const fontFamily = fontSetting.fontFamily || defaultFont;
  const fontOptions =
    fontSetting.fontOptions || block?.fontOptions || 'Regular';
  const font =
    fontFamily +
    (fontOptions === 'Regular' || fontFamily === 'VerovioText'
      ? ''
      : '-' + fontOptions);
  pdf.font(font);
  return () => pdf.font(defaultFont);
}

export function setFontSizeWithRestore(pdf: PDFKit.PDFDocument, fontSize: number) {
  const oldFontSize = pdf._fontSize;
  pdf.fontSize(fontSize).lineGap(fontSize * -0.1);
  return () => pdf.fontSize(oldFontSize).lineGap(oldFontSize * -0.1);
}
