import { ICardContext, TextOptionType } from "context/CardContext";
import { OrdoContext } from "context/OrdoContext";
import { useAlleluiaOptionsQuery, useSeasonalAlleluiaOptionsQuery } from "hooks/text";
import { OrdoCard } from "queries/card-fragment";
import { useCallback, useContext, useState } from "react";
import { useCardOptionsQuery, useEucharisticOptionsQuery, useSeasonalOptionsQuery } from ".";

export const useTextOptionsForCard = (card: OrdoCard, skip = false): Pick<ICardContext, 'optionType'> & {
  textOptions: ICardContext['textOptions'];
} => {
  const season = useContext(OrdoContext).ordo.liturgicalEvent?.season ?? undefined;

  const {
    isAlleluia,
    isAntiphon,
    isNonTextOptionsCard,
    result: cardOptionsQuery,
  } = useCardOptionsQuery(card, skip);

  // skip if NOT alleluia
  const alleluiaOptionsQuery = useAlleluiaOptionsQuery(isAlleluia ? card.id : null, season);
  const alleluiaSeasonalOptionsQuery = useSeasonalAlleluiaOptionsQuery(isAlleluia ? season : undefined);

  // seasonal and eucharistic options are needed specifically for antiphon cards
  const seasonalOptionsQuery = useSeasonalOptionsQuery({
    categories: card.textCategory ? [card.textCategory.id] : [],
    season,
  }, !isAntiphon); // this skips query if not an antiphon
  const eucharisticOptionsQuery = useEucharisticOptionsQuery(skip || !isAntiphon);

  // state for the active text options (only applies to antiphon cards)
  const [selectedOptionType, setOptionType] = useState<TextOptionType>('proper');
  const selectProper = useCallback(() => setOptionType('proper'), []);
  const selectSeasonal = useCallback(() => setOptionType('seasonal'), []);
  const selectEucharistic = useCallback(() => setOptionType('eucharistic'), []);

  // create object with relevant updaters for selecting different active text options
  let optionType: ICardContext['optionType'] = { selected: selectedOptionType };
  // this only applies to antiphons
  if (isAntiphon) {
    // for each type, only add the callback to select it if the options are non-empty and that type's not already selected
    if (cardOptionsQuery.data?.textOptions.length && selectedOptionType !== 'proper') {
      optionType.proper = selectProper;
    }
    if (seasonalOptionsQuery.data?.textOptions.length && selectedOptionType !== 'seasonal') {
      optionType.seasonal = selectSeasonal;
    }
    if (eucharisticOptionsQuery.data?.textOptions.length && selectedOptionType !== 'eucharistic') {
      optionType.eucharistic = selectEucharistic;
    }
  } else if (isAlleluia) {
    // same as antiphon case, only add the callback to select it if the options are non-empty and that type's not already selected
    if (alleluiaOptionsQuery.data?.textOptions.length && selectedOptionType !== 'proper') {
      optionType.proper = selectProper;
    }
    if (alleluiaSeasonalOptionsQuery.data?.textOptions.length && selectedOptionType !== 'seasonal') {
      optionType.seasonal = selectSeasonal;
    }
    // no eucharistic option for alleluias
  }

  const textOptions = isNonTextOptionsCard ? null : (
    isAlleluia
      // alleluias always show the alleluia text options
      ? { proper: alleluiaOptionsQuery,
          seasonal: alleluiaSeasonalOptionsQuery,
          eucharistic: alleluiaOptionsQuery, // this state shouldn't be happening on alleluias, so just default to "proper" case
        }[selectedOptionType]
      // antiphons show the active text options based on `selectedOptionType`
      : isAntiphon
        ? {
          proper: cardOptionsQuery,
          seasonal: seasonalOptionsQuery,
          eucharistic: eucharisticOptionsQuery,
        }[selectedOptionType]
        // all other cards show normal text options
        : cardOptionsQuery
  ).data?.textOptions ?? null;

  return {
    textOptions,
    optionType: optionType,
  };
}
