import { TextOptionWithMeta } from "queries/text-option";

interface SectionHeaders {
  title: string;
  subTitle: string;
};

export const useParseSectionHeaderTexts = (textOptions: TextOptionWithMeta[] | null): SectionHeaders => {
  const sectionHeaders = {
    title: '',
    subTitle: '',
  };

  if (!textOptions?.length) {
    return sectionHeaders;
  }

  const { texts } = textOptions[0];

  texts.forEach((text: any) => {
    const type = text.contentType?.name;
    const content = text.officialTranslations?.[0]?.content;
   
    if (type === 'Title') {
      sectionHeaders.title = content;
    }

    else if (type === 'Subtitle') {
      sectionHeaders.subTitle = content;
    }
  });

  return sectionHeaders;
};
