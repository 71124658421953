import { css, cx } from '@emotion/css';
import React from 'react';
import { contactUrl } from 'utils/links';
import { colorDictionary, fontSansCss } from 'utils/styles';

export const MusicalErrorAlert: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => (
  <div className={cx('musical-error-alert d-print-none', style)}>
    There is an error in the musical setting you have selected. Please <a href={contactUrl} rel='noreferrer' target="_blank">contact us</a> noting the liturgy name, date, and section, and we will get this fixed ASAP.
  </div>
);

const style = css`
  ${fontSansCss}
  padding: 10px;
  font-size: 15px;
  color: ${colorDictionary['Error']};
  background-color: ${colorDictionary['Error Light']}99;
  border: 1px solid ${colorDictionary['Error']};
  border-radius: 4px;
  font-weight: 400;
  a {
    font-weight: 600;
  }
`;
