export const extractLyricsFromGabc = (gabc: string, syllableSeparator = '') => {
  const result: string[] = [];
  const words = gabc
    .replace(/\n/g, ' ')
    .replace(/\)\(/g, '')
    .replace(/\)\s+(?=[^)]*(?:\(|$))/g, ')\n')
    .split('\n');
  let isFirstSyllable = true;
  for (const word of words) {
    let syllables = word
      .split(')')
      .map((syl) => (syl ? syl + (/\(/.test(syl) ? ')' : '()') : syl));
    let lyricSyllables = [];
    for (let sylI = 0; sylI < syllables.length; ++sylI) {
      let syl = syllables[sylI];
      let notesMatch = /\(([^)]*)\)?$/.exec(syl);
      let lyrics: string | null;
      if (notesMatch) {
        lyrics = syl.slice(0, notesMatch.index);
      } else {
        lyrics = syl;
      }
      let translationMatch = /\[([^\]]+)\]/.exec(lyrics);
      if (translationMatch) {
        lyrics =
          lyrics.slice(0, translationMatch.index) +
          lyrics.slice(translationMatch.index + translationMatch[0].length);
      }
      if (lyrics) {
        lyrics = lyrics.replace(/(^|\s)"/, '$1“').replace(/"($|\s)/, '”$1');
        if (/\s/.test(lyrics)) {
          lyrics = lyrics.trim();
          if (!/\s/.test(lyrics)) {
            // the only whitespace is at the end...consider this the end of the word: see "Dicit Dominus Implete hydrias aqua et ferte"
            result.push([...lyricSyllables, lyrics].join(syllableSeparator));
            lyricSyllables = [];
            lyrics = null;
          }
        }
        if (isFirstSyllable && lyrics) {
          lyrics = lyrics
            .replace(/[{}]/g, '')
            .replace(
              /([A-ZŒÆÁÉÍÓÚÝǼ])([A-ZŒÆÁÉÍÓÚÝǼ]+)/,
              (_, first, second) => first + second.toLowerCase(),
            );
          isFirstSyllable = false;
        }
        if (lyrics) lyricSyllables.push(lyrics);
      }
    }
    result.push(lyricSyllables.join(syllableSeparator));
  }
  return result
    .join(' ')
    .replace(/ \n\s*/g, '\n')
    .replace(
      /<sp>([ARV])\/<\/sp>\.?/gi,
      (_, arv) => `<span class='versiculum-text'>${arv.toLowerCase()}</span>`,
    )
    .trim();
};
