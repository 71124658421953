import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';
import { JwtUser } from 'utils/jwt/parseJwt';
import { getSessionStorageValueWithLocalStorageBackup } from './useSessionStorage';

// re-export the type here so that calling files can import it with reference to the hook
export type { JwtUser } from 'utils/jwt/parseJwt';

/**
 * 
 * @returns JwtUser if authenticated, null if not authenticated, and undefined if still checking
 */
export const useUser = (): JwtUser | null | undefined => {
  return useContext(AuthContext).user;
}

export const getSelectedGroup = (user: Partial<JwtUser> | null | undefined) => {
  const groupId = getSessionStorageValueWithLocalStorageBackup('groupId');
  return groupId ? user?.groups?.find(({ id }) => id === groupId) : undefined;
}
