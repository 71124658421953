import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';

export type PdfBlockPageContainer = {
  contentWidth?: number;
  key?: string;
  blocks: (PdfBlock | PdfColumnBlock)[];
  topMargin?: number;
  leftMargin?: number;
  rightMargin?: number;
};
export type PdfColumnBlock = {
  blocks: PdfBlock[][];
  columnInfo: PdfColumnInfo;
  isCenter?: boolean;
}
export type PdfBlockPage = {
  containers: PdfBlockPageContainer[];
  content?: Blob;
  verticalAlign?: 'bottom';
  originalPageNumber?: number;
};

export interface Font {
  fontFamily?: string;
  fontOptions?: FontOption;
  fontSize?: number;
  features?: PDFKit.Mixins.OpenTypeFeatures[];
}
export type FontOption =
  | 'Regular'
  | 'Medium'
  | 'Bold'
  | 'Italic'
  | 'BoldItalic';
export const isBold = (option?: FontOption) => option && /Bold/.test(option);
export const isItalic = (option?: FontOption) => option && /Italic/.test(option);
export interface PdfTextSpan extends Font {
  spans?: undefined;
  element?: undefined;
  columnBlocks?: undefined;
  text: string;
  marginRight?: number;
  yOffset?: number;
  newline?: boolean;
  preserveWhitespace?: boolean;
  options?: Pick<
    PDFKit.Mixins.TextOptions,
    | 'link'
    | 'goTo'
    | 'wordSpacing'
    | 'indent'
    | 'characterSpacing'
    | 'baseline'
    | 'features'
    | 'underline'
    | 'strike'
    | 'ellipsis'
    | 'oblique'
    | 'continued'
  >;
}

export interface PdfTextSpanWithDimensions extends PdfTextSpan {
  width: number;
  height: number;
  spaceWidth: number;
}

export interface PdfTextLine {
  width: number;
  height: number;
  spans: PdfTextSpan[];
  dx?: number;
}
export interface PdfColumnInfo {
  count: number;
  gap: number;
  center?: boolean;
};
export type PdfTextBlock = Font & {
  /**
   * Additional margins for this block
   */
  margins?: { top?: number; left?: number; bottom?: number; right?: number };
  options?: Pick<
    PDFKit.Mixins.TextOptions,
    | 'columns'
    | 'columnGap'
    | 'lineGap'
    | 'indent'
    | 'paragraphGap'
    | 'characterSpacing'
    | 'wordSpacing'
    | 'align'
    | 'baseline'
    | 'features'
    | 'oblique'
    | 'strike'
    | 'underline'
    | 'ellipsis'
    | 'continued'
    | 'destination'
  >;
  contentWidth?: number;
  height?: number;
  width?: number;
  backgroundColor?: PDFKit.Mixins.ColorValue;
  borderWidth?: number;
  borderPadding?: number;
  borderRadius?: number;
  spans: PdfTextSpan[];
  lines?: PdfTextLine[];
  multipartId?: number;
  continuedFrom?: PdfTextBlock;
  columns?: PdfColumnInfo;
  float?: 'right';
  x?: number;
};

export type PdfTextColumnsBlock = Font & Pick<PdfTextBlock, 'margins'|'options'|'height'|'width'|'contentWidth'> & {
  columnBlocks: (PdfTextBlock & { autoWidth?: true; rowParent: PdfTextColumnsBlock })[];
  columns?: undefined;
  continuedFrom?: undefined;
};

export type PdfImageBlock = {
  margins?: { top?: number; left?: number; bottom?: number; right?: number };
  height?: number;
  width?: number;
  element: SVGSVGElement | HTMLImageElement;
  getImageBuffer?: () => Promise<ArrayBuffer>;
  _imageBuffer?: ArrayBuffer;
  src?: string;
  options?: PdfTextBlock['options'];
};

export type PdfBlock = ExclusiveUnion<PdfTextBlock | PdfImageBlock | PdfTextColumnsBlock> & {
  pageBreakBefore?: boolean;
  pageBreakAfter?: boolean;
  columnBreakBefore?: boolean;
  columnBreakAfter?: boolean;
  isCardTitle?: boolean;
};

export type PdfCardBlocks = {
  blocks: PdfBlock[];
  cardConfig?: OrdoCardConfig;
}

export const blankPdfBlockPage = {
  containers: [{ blocks: [] }],
} as const satisfies PdfBlockPage;
