import { TranslationOnText } from "queries/text";
import { TextOptionWithMeta } from "queries/text-option";
import { Dictionary } from "utils/typescript/Dictionary";

interface ContextualRubrics {
  rubricHeader: string;
  rubricFooter: string;
};

export const useContextualRubricTextOptions = (textOptions: TextOptionWithMeta[] | null | undefined, isConfiguredState = false): ContextualRubrics => {
  const contextualRubrics = {
    rubricHeader: '',
    rubricFooter: '',
  };

  if (!textOptions?.length) {
    return contextualRubrics;
  }

  const texts = textOptions.flatMap((textOption) => textOption.texts);
  const selectionOnlyRubric = textOptions.some((textOption) =>
      textOption.textsMeta?.some((tm) => tm.selectionOnly)
  );
  if (selectionOnlyRubric && isConfiguredState) {
    return contextualRubrics;
  }

  const translations = texts
    .filter((text) => ['Rubric Header', 'Rubric Footer'].includes(text.contentType?.name))
    .reduce((obj: Dictionary<TranslationOnText>, text) => {
      const key = text.contentType.name;
      return {
        ...obj,
        [key]: text.officialTranslations[0],
      };
    }, {});

  if (translations['Rubric Header']) {
    contextualRubrics.rubricHeader = translations['Rubric Header'].content;
  }

  if (translations['Rubric Footer']) {
    contextualRubrics.rubricFooter = translations['Rubric Footer'].content;
  }

  return contextualRubrics;
};
