import { cx } from '@emotion/css';
import { Translation } from 'components/ordo/shared/text/Translation';
import { CardContext } from 'context/CardContext';
import { OrdoContext } from 'context/OrdoContext';
import { useErrorToastEffect } from 'hooks/error/useErrorToastEffect';
import { useIdQuery } from 'hooks/queries/useQuery';
import { GetText } from 'queries/text';
import React, { useContext, useEffect } from 'react';
import { ResourceGroupDisplay } from '../resource/ResourceGroupDisplay';

export const PrayerCard : React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const { defaultLanguage } = useContext(OrdoContext).ordo;
  const { config, onLoaded } = useContext(CardContext);

  const { data: { text } = { text: undefined }, error, loading } = useIdQuery(GetText, config.textId);
  useErrorToastEffect(error && 'An error occurred while loading the prayer text for this card.')

  useEffect(() => {
    if (!loading) {
      onLoaded();
    }
  }, [loading, onLoaded]);

  const translation = text?.officialTranslations.find((t) => t.language === (config.language ?? defaultLanguage));
  const prayerText = translation?.content;

  return <div className={cx('prayer-card')}>
    {config.resourceGroupId !== null && <ResourceGroupDisplay isActive={isActive} />}
    {prayerText && <Translation
      text={prayerText}
      showAll={true}
      textDisplay={prayerText.includes('\n') ? 'In Verse' : 'Paragraph'}
    />}
  </div>;
};
