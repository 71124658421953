/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { AddOnId, PlanId } from 'utils/api-types/plans';
import { TeamRole } from 'utils/api-types/TeamRole';
import { WithName } from 'utils/api-types/WithNameAndId';
import { ParishSize } from 'utils/forms/options/parishSizes';
import { CHOIR_EMAIL_FRAGMENT, ChoirEmail, GetGroupChoirEmails } from './choir-email';
import { GooglePlace } from './google-place';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';
import { createMutationCacheUpdater } from './updaters/createMutationCacheUpdater';
import { addChoirEmailsToGroupCache } from 'utils/apollo/addChoirEmailsToGroupCache';
import { File } from 'utils/api-types/resource';

//////////////
/// FRAGMENTS

export interface Address {
  line1: string | null;
  line2: string | null;
  locality: string | null;
  region: string | null;
  postalCode: string | null;
}

export type TeamMember = {
  id: string;
  name: string;
  email: string;
  role: TeamRole;
};

export type KeycloakGroup = {
  // should be the same as the group ID
  id: string;
  parishSize: ParishSize;
  address: Address | null;
  phone: string | null;
  isMissingData: boolean;
  owner: TeamMember|null;
  admins: TeamMember[];
  editors: TeamMember[];
  pendingInvites: TeamInvitation[];
  maxUsers: number;
  userTeamRole: TeamRole;
  userParishRole: string|null;
  verifiedGroupOwner: string|null;
  type: 'verified'|'solo'|null;
  hasMultiGroupAccess: boolean;
  placeId: string|null;
  ignoreMissingPlaceId: boolean;
} & WithName;

export type Group = {
  id: string;
  keycloakGroup: KeycloakGroup;
  portalConfig: {};
  subscription: {
    id: string;
    planId: keyof typeof PlanId;
    status: string;
    isActive: boolean;
    isAnnual: boolean;
    isMissal: boolean;
    addOns: {
      id: keyof typeof AddOnId;
    }[];
    quantity: number | null;
    currentTermStart: string | null;
    currentTermEnd: string | null;
    dollarsRealized: number | null;
  } | null;
  layoutImages: (File & { uploader: string|null })[];
};

const TEAM_MEMBER_FRAGMENT = gql`
  fragment TeamMember on TeamMember {
    id
    name
    email
    role
  }
`;

const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    line1
    line2
    locality
    region
    postalCode
  }
`

const KEYCLOAK_GROUP_FRAGMENT = gql`
  fragment KeycloakGroup on KeycloakGroup {
    id
    name
    parishSize
    address {
      ...Address
    }
    phone
    isMissingData
    owner {
      ...TeamMember
    }
    admins {
      ...TeamMember
    }
    editors {
      ...TeamMember
    }
    pendingInvites {
      email
      role
    }
    maxUsers
    userTeamRole
    userParishRole
    verifiedGroupOwner
    type
    hasMultiGroupAccess
    placeId
    ignoreMissingPlaceId
  }
  ${TEAM_MEMBER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const GROUP_FRAGMENT = gql`
  fragment Group on Group {
    id
    keycloakGroup {
      ...KeycloakGroup
    }
    portalConfig
    subscription {
      id
      planId
      status
      isActive
      isAnnual
      isMissal
      addOns {
        id
      }
      quantity
      currentTermStart
      currentTermEnd
      dollarsRealized
    }
    layoutImages {
      id
      name
      url
      awsKey
      uploader
    }
  }
  ${KEYCLOAK_GROUP_FRAGMENT}
`;

////////////
/// QUERIES

export interface GetGroup extends GQLQuery {
  Variables: { id: string };
  Data: { group: Group };
}
export const GetGroup: GQLDocument<GetGroup> = {
  query: gql`
    query getGroup($id: String!) {
      group(id: $id) {
        ...Group
      }
    }
    ${GROUP_FRAGMENT}
  `
}

export interface ValidatePurchase extends GQLQuery {
  Variables: { id: string };
  Data: { valid: boolean };
}
export const ValidatePurchase: GQLDocument<ValidatePurchase> = {
  query: gql`
    query validatePurchase($id: String!) {
      valid(groupId: $id)
    }
  `
}

export type TeamInvitation = {
  email: string;
  role: TeamRole;
}

export interface InviteToTeam extends GQLMutation<InviteToTeam> {
  Variables: {
    groupId: string;
    email: string;
    role: TeamRole;
  };
  Data: { invitation: TeamInvitation };
}
export const InviteToTeam: GQLDocument<InviteToTeam> = {
  mutation: gql`
    mutation inviteToTeam($groupId: String!, $email: String!, $role: TeamRole!) {
      invitation: inviteToTeam(groupId: $groupId, email: $email, role: $role) {
        email
        role
      }
    }
  `,
}

export interface RemoveInvitation extends GQLMutation<RemoveInvitation> {
  Variables: {
    groupId: string;
    email: string;
  };
  Data: { invitation: TeamInvitation };
}
export const RemoveInvitation: GQLDocument<RemoveInvitation> = {
  mutation: gql`
    mutation removeInvitation($groupId: String!, $email: String!) {
      invitation: removeInvitation(groupId: $groupId, email: $email) {
        email
        role
      }
    }
  `,
}

export interface InviteFromChoirEmail extends GQLMutation<InviteFromChoirEmail> {
  Variables: {
    choirEmailId: number;
    role: TeamRole;
  }
  Data: {
    inviteFromChoirEmail: {
      invitation: TeamInvitation;
      choirEmailId: number;
    }
  }
}
export const InviteFromChoirEmail: GQLDocument<InviteFromChoirEmail> = {
  mutation: gql`
    mutation inviteFromChoirEmail($choirEmailId: Float!, $role: TeamRole!) {
      inviteFromChoirEmail(choirEmailId: $choirEmailId, role: $role) {
        invitation {
          email
          role
        }
        choirEmailId
      }
    }
  `,
  updater: createMutationCacheUpdater(
    GetGroupChoirEmails,
    (_, { context }) =>
      context?.updateQueryVariables?.id
      ? { id: context.updateQueryVariables.id }
      : false,
    (data: GetGroupChoirEmails['Data'], { inviteFromChoirEmail: { choirEmailId } }: InviteFromChoirEmail['Data']) => {
      const emailIndex = data.group.choirEmails.findIndex(({ id }) => id === choirEmailId);
      if (emailIndex === -1) {
        return false;
      }
      data.group.choirEmails.splice(emailIndex, 1);
      return true;
    }
  ),
}

export type ListGroup = {
  id: string;
  keycloakGroup: {
    id: string;
    address: Address | null;
  } & WithName;
  googlePlace: { placeId: string } | null;
  subscription: { status: string } | null;
};

const LIST_GROUP_FRAGMENT = gql`
  fragment ListGroup on Group {
    id
    keycloakGroup {
      id
      name
      address {
        ...Address
      }
    }
    googlePlace {
      placeId
    }
    subscription {
      status
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export interface GetAllGroups extends GQLQuery {
  Variables: {
    offset?: number;
    limit?: number;
    groupId?: string;
  };
  Data: { allGroups: ListGroup[] };
}
export const GetAllGroups: GQLDocument<GetAllGroups> = {
  query: gql`
    query allGroups($offset: Float, $limit: Float, $groupId: String) {
      allGroups(offset: $offset, limit: $limit, groupId: $groupId) {
        ...ListGroup
      }
    }
    ${LIST_GROUP_FRAGMENT}
  `,
}

export interface SetPlaceForGroup extends GQLMutation<SetPlaceForGroup, GetAllGroups> {
  Variables: {
    groupId: string;
    place: GooglePlace;
  };
  Data: {
    setPlaceForGroup: {
      groupName: string;
      groupId: string;
      placeId: string;
    };
  };
}
export const SetPlaceForGroup: GQLDocument<SetPlaceForGroup> = {
  mutation: gql`
    mutation setPlaceForGroup($groupId: String!, $place: JSONObject!) {
      setPlaceForGroup(groupId: $groupId, place: $place) {
        groupName
        groupId
        placeId
      }
    }
  `,
  updater: createMutationCacheUpdater(
    GetAllGroups,
    (_, options) => options.context?.updateQueryVariables ?? false,
    (draft, mutationData) => {
      const groupIdx = draft.allGroups.findIndex((group) => group.id === mutationData.setPlaceForGroup.groupId);
      if (groupIdx < 0) {
        return false;
      }
      draft.allGroups[groupIdx].googlePlace = mutationData.setPlaceForGroup;
      return true;
    }
  )
}

export interface AcceptInvitation extends GQLMutation<AcceptInvitation> {
  Variables: {
    inviteToken: string;
  }
  Data: {
    acceptInvitation: {
      groupId: string;
      groupName: string;
      userRole: string;
      alreadyMember: boolean|null;
    }
  }
}
export const AcceptInvitation: GQLDocument<AcceptInvitation> = {
  mutation: gql`
    mutation acceptInvitation($inviteToken: String!) {
      acceptInvitation(inviteToken: $inviteToken) {
        groupId
        groupName
        userRole
        alreadyMember
      }
    }
  `,
}

export interface RemoveUserFromTeam extends GQLMutation<RemoveUserFromTeam> {
  Variables: {
    userId: string;
    groupId: string;
  }
  Data: { removedUserName: string; }
}
export const RemoveUserFromTeam: GQLDocument<RemoveUserFromTeam> = {
  mutation: gql`
    mutation removeUserFromGroup($userId: String!, $groupId: String!) {
      removedUserName: removeUserFromGroup(userId: $userId, groupId: $groupId)
    }
  `,
}

export interface UpdateMemberRole extends GQLMutation<UpdateMemberRole> {
  Variables: {
    userId: string;
    groupId: string;
    role: TeamRole;
  }
  Data: {
    updatedUser: {
      name: string;
      role: TeamRole;
    }
  }
}
export const UpdateMemberRole: GQLDocument<UpdateMemberRole> = {
  mutation: gql`
    mutation updateMemberRole($userId: String!, $groupId: String!, $role: String!) {
      updatedUser: updateUserRoleInGroup(userId: $userId, groupId: $groupId, role: $role) {
        name
        role
      }
    }
  `,
}

export interface UpdateGroup extends GQLMutation<UpdateGroup> {
  Variables: {
    groupId: string;
    name: string;
    phone: string;
  }
  Data: {
    updatedGroup: Pick<KeycloakGroup, 'id'|'name'|'phone'>;
  };
}
export const UpdateGroup: GQLDocument<UpdateGroup> = {
  mutation: gql`
    mutation updateGroup($groupId: String!, $name: String!, $phone: String!) {
      updatedGroup: updateGroup(groupId: $groupId, name: $name, phone: $phone) {
        id
        name
        phone
      }
    }
  `
}

export interface UpdateGroupAddress extends GQLMutation<UpdateGroupAddress> {
  Variables: {
    groupId: string;
    address: Address;
  }
  Data: {
    updatedGroup: Pick<KeycloakGroup, 'id'|'address'>;
  };
}
export const UpdateGroupAddress: GQLDocument<UpdateGroupAddress> = {
  mutation: gql`
    mutation updateGroupAddress($groupId: String!, $address: AddressInput!) {
      updatedGroup: updateGroupAddress(groupId: $groupId, address: $address) {
        id
        address {
          ...Address
        }
      }
    }
    ${ADDRESS_FRAGMENT}
  `
}

export interface TransferGroupOwnership extends GQLMutation<TransferGroupOwnership> {
  Variables: {
    userId: string;
    groupId: string;
  }
  Data: {
    newOwner: string
  }
}
export const TransferGroupOwnership: GQLDocument<TransferGroupOwnership> = {
  mutation: gql`
    mutation transferGroupOwnership($userId: String!, $groupId: String!) {
      newOwner: transferGroupOwnership(userId: $userId, groupId: $groupId)
    }
  `,
}

export interface SendMissingPlaceIdEmail extends GQLMutation<SendMissingPlaceIdEmail> {
  Variables: {
    groupId: string;
  }
  Data: {
    sendMissingPlaceIdEmail: boolean;
  }
}
export const SendMissingPlaceIdEmail: GQLDocument<SendMissingPlaceIdEmail> = {
  mutation: gql`
    mutation sendMissingPlaceIdEmail($groupId: String!) {
      sendMissingPlaceIdEmail(groupId: $groupId)
    }
  `,
}

export interface MoveUserToChoirEmail extends GQLMutation<MoveUserToChoirEmail> {
  Variables: {
    userId: string;
    groupId: string;
  }
  Data: {
    moveUserToChoirEmail: ChoirEmail;
  }
}
export const MoveUserToChoirEmail: GQLDocument<MoveUserToChoirEmail> = {
  mutation: gql`
    mutation moveUserToChoirEmail($userId: String!, $groupId: String!) {
      moveUserToChoirEmail(userId: $userId, groupId: $groupId) {
        ...ChoirEmail
      }
    }
    ${CHOIR_EMAIL_FRAGMENT}
  `,
  updater: (cache, { data }, { variables }) => {
    const choirEmail = data?.moveUserToChoirEmail;
    const groupId = variables?.groupId;
    if (!choirEmail || !groupId) return;
    addChoirEmailsToGroupCache(cache, [choirEmail], groupId);
  }
}

export interface GetAllInvites extends GQLQuery {
  Variables: {
    offset?: number;
    limit?: number;
  };
  Data: {
    allInvites: {
      senderEmail: string|null;
      invitationUrl: string;
      createdAt: Date;
    }[];
  };
}
export const GetAllInvites: GQLDocument<GetAllInvites> = {
  query: gql`
    query allInvites($offset: Float, $limit: Float) {
      allInvites(offset: $offset, limit: $limit) {
        senderEmail
        invitationUrl
        createdAt
      }
    }
  `,
}
