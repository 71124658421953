import { css, cx } from '@emotion/css';
import { Text, TranslationOnText } from 'queries/text';
import React, { useMemo } from 'react';
import { PlainTextVerse } from './PlainTextVerse';
import { ScriptureReference } from './ScriptureReference';

interface PlainTextVersesProps {
  additionalAntiphonText?: string | null;
  verses: string[];
  verseText?: Text | null;
  verseTranslation?: TranslationOnText | null;
  includeGloryBe: boolean;
  gbTranslation?: TranslationOnText | null;
  verseColumns?: number | null;
}

export const PlainTextVerses: React.FC<PlainTextVersesProps> = ({
  additionalAntiphonText,
  verses,
  verseColumns,
  verseText,
  verseTranslation,
  includeGloryBe,
  gbTranslation,
}) => {
  const additionalAntiphonVerses = useMemo(
    () => (additionalAntiphonText ? [additionalAntiphonText] : null),
    [additionalAntiphonText],
  );
  return <div className={cx('plain-text-verses', style)}>
    {additionalAntiphonVerses && <>
      <div className='_EXPORT' style={{ fontSize: '80%', marginBottom: 8, fontStyle: 'italic' }}>Additional Antiphon Text</div>
      <PlainTextVerse
        verses={additionalAntiphonVerses}
        verseClassName='liturgical-text-verse mb-2'
        columns={verseColumns}
      />
      <div className='_EXPORT' style={{marginTop: 24}}/>
    </>}
    {verseText && !!verses.length && <ScriptureReference text={verseText.originalSource}/>}

    {verseTranslation && !!verses.length && <PlainTextVerse
      verses={verses}
      verseClassName='liturgical-text-verse'
      columns={verseColumns}
      includeGloryBe={gbTranslation && includeGloryBe}
    />}
  </div>;
}

const style = css`
  .scripture-reference {
    padding-bottom: 15px;
  }
`;
