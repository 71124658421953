import { getVerovioOptionsForHorizontal } from 'components/shared/editor/settings/SpacingToggler';
import { MidiVerovioOrPassthru } from 'components/verovio/MidiVerovioOrPassthru';
import { RenderMidiCallback, Verovio } from 'components/verovio/Verovio';
import { CardContext } from 'context/CardContext';
import { removeSolesmesMarkingsInMixedGabc } from 'gabc-utils';
import { useDefaultExsurgeConfig } from 'hooks/exsurge/useDefaultExsurgeConfig';
import { useMidiCallbacks } from 'hooks/useMidiCallbacks';
import useResizeObserver from 'hooks/util/useResizeObserver';
import React, { FC, RefObject, useCallback, useContext, useEffect, useRef } from 'react';
import Exsurge from 'react-exsurge';
import { VerovioOptions } from 'react-verovio';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { File, Resource, isFile, isMusic, isNote } from 'utils/api-types/resource';
import { getExsurgeAlignment } from 'utils/functions/exsurge/getExsurgeAlignment';
import { getModeNumeral } from 'utils/functions/exsurge/getModeNumeral';
import { DEFAULT_POST_PROCESS_ORDINARY } from 'utils/verovio/DEFAULT_POST_PROCESS_ANTIPHON';
import { getVerovioTranspose } from 'utils/verovio/getVerovioTranspose';
import { DefaultVerovioOptionsArgument, useDefaultVerovioOptionsAntiphon } from 'utils/verovio/useDefaultVerovioOptionsAntiphon';
import { NotesCardViewer } from '../notes/NotesCardViewer';
import { PdfDisplay } from './PdfDisplay';
import { extractLyricsFromGabc } from 'utils/extractLyricsFromGabc';

interface ResourceDisplayProps {
  resource: Resource;
  parentRef: RefObject<HTMLDivElement>;
  isActive: boolean;
}

const isPdf = ({ awsKey }: File) => !!awsKey.match(/\.pdf$/i);

export const ResourceDisplay: React.FC<ResourceDisplayProps> = ({
  resource,
  parentRef,
  isActive,
}: ResourceDisplayProps) => {
  const { onLoaded, config, card } = useContext(CardContext);
  const defaultExsurgeConfig = useDefaultExsurgeConfig(config);
  const { width: observedWidth = 0 } = useResizeObserver({ ref: parentRef });
  const lastNonZeroWidth = useRef(observedWidth);
  if (observedWidth) lastNonZeroWidth.current = observedWidth;
  const width = lastNonZeroWidth.current;

  useEffect(() => {
    if (isNote(resource) || isFile(resource)) {
      onLoaded();
    }
  }, [resource, onLoaded]);

  const meiOrSvg = card.mei ?? card.svg;
  const { ref, setMidiData } = useMidiCallbacks(
    isActive,
    card.id,
    meiOrSvg?.name,
    card.name,
    { isMidiAvailable: !!meiOrSvg },
  );
  const onRenderMidi: RenderMidiCallback = useCallback((...args) => {
    setMidiData(...args, meiOrSvg?.audioUrls, card.config.keyOffset);
    onLoaded();
  }, [setMidiData, meiOrSvg?.audioUrls, card.config.keyOffset, onLoaded]);

  if (isFile(resource)) {
    const file: File|undefined = resource.file;

    if (!file) {
      return <>No file to display</>;
    }


    if (isPdf(file)) {
      return <PdfDisplay
        file={file}
      />;
    }

    return <img
      crossOrigin='anonymous'
      className='w-100 _EXPORT'
      src={file.url}
      alt='User generated content'
    />;
  }


  if (isNote(resource)) {
    const text = resource.note;

    if (!text) {
      return <></>;
    }

    return <div className='mt-3'>
      <div className='_EXPORT'>
        <NotesCardViewer
          onClick={() => {}}
          content={text.content ?? ''}
          editable={false}
        />
      </div>
    </div>;
  }

  if (isMusic(resource)) {
    const { combinedGabc, alignment, dropCap, mode } = resource.music;
    const { notation, removeSolesmesMarkings } = config;
    const gabc = removeSolesmesMarkings ? removeSolesmesMarkingsInMixedGabc(combinedGabc!) : combinedGabc;
    return ( !!gabc &&
      <div ref={ref} className='exsurge-wrapper'>
        {notation === MusicalNotation.SQUARE
          ? <Exsurge
              className='_EXPORT'
              gabc={gabc}
              alignment={getExsurgeAlignment(alignment)}
              width={width}
              annotation={getModeNumeral(mode ?? '')}
              useDropCap={dropCap ?? undefined}
              onRender={onLoaded}
              {...defaultExsurgeConfig}
            />
          : notation === MusicalNotation.MODERN ? <VerovioResource /> : <div dangerouslySetInnerHTML={{ __html: extractLyricsFromGabc(gabc)}} />
        }
        {meiOrSvg && (
          <MidiVerovioOrPassthru
            svg={card.svg ?? undefined}
            mei={card.mei ?? undefined}
            {...{ onRenderMidi }}
            keyOffset={config.keyOffset}
          />
        )}
      </div>
    );
  }

  return <>can't display {isNote(resource) ? 'note' : isFile(resource) ? 'file' : isMusic(resource) ? 'music' : 'unknown resource'} for {(resource as Resource).id}</>;
};

const VerovioResource: FC = () => {
  const { config, card } = useContext(CardContext);
  const {
    displayHarmonizations,
    spacingHorizontal,
    spacingLinear,
    spacingNonLinear,
    spacingVertical,
  } = config;

  const meiData = card.mei ?? card.svg;
  const meiKey = meiData?.keyIndex ?? 0;
  let defaultTranspose = meiData?.defaultTranspose ?? 0;

  const transposeOffset = (defaultTranspose || 0) + (config.keyOffset || 0);
  const verovioTranspose: VerovioOptions = {
    transpose: getVerovioTranspose(meiKey, transposeOffset),
  };

  const verovioOptionsArguments: DefaultVerovioOptionsArgument = {
    extraConfig: {
      ...verovioTranspose,
      ...(spacingHorizontal
        ? getVerovioOptionsForHorizontal(0.29, spacingHorizontal)
        : {
            ...(spacingLinear ? { spacingLinear } : {}),
            ...(spacingNonLinear ? { spacingNonLinear } : {}),
          }),
    },
    isChant: true,
    isFullScreen: false,
    showHarmonizations: displayHarmonizations,
    cardHarmonizations: displayHarmonizations,
    spacingVertical,
  };
  const verovioConfig = useDefaultVerovioOptionsAntiphon(
    verovioOptionsArguments,
  );
  return card.mei?.antiphon?.map((meiWithTitles, i) => (
    <Verovio
      key={i}
      mei={meiWithTitles.mei}
      className="_EXPORT"
      postProcessConfig={DEFAULT_POST_PROCESS_ORDINARY}
      config={verovioConfig}
    />
  ));
};
