import { getSelectedGroup, JwtUser, useUser } from 'hooks/useUser';
import { PermissionSet } from 'queries/permission-set';
import { doesUserHaveRoles, RoleArgument } from './useHasRole';
import { OrdoContext } from 'context/OrdoContext';
import { useContext } from 'react';

export const permissionSetToRoleArgument = (permissionSet: PermissionSet): RoleArgument => {
  // permission set's "NOT" argument is equivalent to useHasRole's "none" argument, hence the replace
  // also, this doesn't type correctly unfortunately, so we have to force it
  return { [permissionSet.type.toLowerCase().replace('not', 'none')]: permissionSet.roles.map(r => r.name) } as unknown as RoleArgument;
}

export const useHasPermissionSet = <DefaultValue = true>(
  permissionSet?: PermissionSet | null,
  defaultValue: boolean | DefaultValue = true,
  user?: Partial<JwtUser> | null,
) => {
  const contextUser = useUser();
  user ||= contextUser;
  const { ownerRoles } = useContext(OrdoContext);
  const group = getSelectedGroup(user) || { roles: ownerRoles };
  user ||= group;

  return !user
    // false if user not found
    ? false
    : !permissionSet
      // if permission set is null/undefined, use default value
      ? defaultValue
      // otherwise, we have both a user and a permission set, so run the check
      : doesUserHaveRoles(
        user,
        permissionSetToRoleArgument(permissionSet),
        group,
      );
};
