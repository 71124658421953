import { cx } from '@emotion/css';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContext } from 'context/CardContext';
import { useCardContext } from 'hooks/ordo/cards/useCardContext';
import { OrdoCard, OrdoCardShare } from 'queries/card-fragment';
import React, { useCallback, useContext } from 'react';
import { ShareCardBody } from './ShareCardBody';
import { TextSize } from './SharePageSettings';
import { useCardWithSvgFromCache } from 'hooks/share/useCardWithSvgFromCache';
import { useConfigOnCard } from 'hooks/ordo/cards/useCardConfig';
import { MidiContext } from 'context/MidiContext';
import { getContentWidth } from 'utils/getContentWidth';
import { getStyleForCardConfig } from 'utils/ordo/card/getStyleForCardConfig';

type ShareCardProps = {
  className?: string;
  card: OrdoCardShare | OrdoCard;
  onToggleFullscreen?: () => void;
  displayButtons?: boolean;
  displayDownloadButton?: boolean;
  displayFullScreenButton?: boolean;
  displayPlayMidiButton?: boolean;
  forcePlayMidiButton?: boolean;
  displayTop?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  ordoRoleId?: number;
  dynamicHymnScaling?: boolean;
  textSize?: TextSize;
  onLoaded?: (id: number) => void;
  forceFullWidth?: boolean;
  onClickDownload?: (cardId: number) => void;
}

const createConfigUpdater = () => async () => {};

export const ShareCard: React.FC<ShareCardProps> = ({
  className = 'share-card',
  card: cardWithTextOptions,
  onToggleFullscreen,
  displayButtons = false,
  displayDownloadButton = false,
  displayFullScreenButton = false,
  displayPlayMidiButton = false,
  forcePlayMidiButton = false,
  displayTop = true,
  isActive: active = false,
  onClick,
  onLoaded,
  ordoRoleId,
  dynamicHymnScaling = false,
  textSize = 1,
  forceFullWidth = false,
  onClickDownload,
}) => {
  const card = useCardWithSvgFromCache({
    cardWithTextOptions,
    textSize,
    dynamicHymnScaling,
    ordoRoleId,
    forceFullWidth,
  });
  const config = useConfigOnCard(card);
  const hasTextOptions = 'selectedTextOption' in card;
  const cardContext = useCardContext({
    card,
    config,
    createConfigUpdater,
    isLastCard: false,
    skipTextOptions: hasTextOptions,
    textOptionsOverride: hasTextOptions && card.selectedTextOption ? [card.selectedTextOption] : undefined,
    note: hasTextOptions ? card.note : undefined,
    onLoaded,
    textSize,
  });
  const {
    playMidi,
    midiCardId,
    pauseMidi,
    isPlaying: midiIsPlaying,
  } = useContext(MidiContext);
  const hasMidi = forcePlayMidiButton || ('svg' in card && !!card.svg);
  const isPlaying = midiIsPlaying && (card.id === midiCardId);

  const handleDownload = useCallback(
    () => onClickDownload?.(card.id),
    [card.id, onClickDownload],
  );

  return <CardContext.Provider value={cardContext}>
    <div
      className={cx(className, { 'extra-content-width': !getContentWidth() && config?.displayHarmonizations, active })}
      style={getStyleForCardConfig(config)}
      data-card-id={card.id}
      onClick={onClick}
    >
      {displayTop && <div
        className='card-top _EXPORT no-page-break-after no-page-break-inside' 
        data-export-pad-top='16'
        data-export-pad-bottom='16'
      >
        <span className='card-title'>{card.name}</span>
        {(displayButtons || displayDownloadButton) && <button className='share-button download-button' onClick={handleDownload}><FontAwesomeIcon icon={faDownload}/></button>}
        {(displayButtons || displayFullScreenButton) && <button className='share-button fullscreen-button' onClick={onToggleFullscreen}><FontAwesomeIcon icon={faExpand}/></button>}
        {(displayButtons || displayPlayMidiButton || forcePlayMidiButton) && hasMidi && <button className='share-button play-button' onClick={isPlaying ? pauseMidi : () => playMidi(card.id)}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
          </button>}
      </div>}
      <div className='card-body'>
        <ShareCardBody card={card} isActive={active} />
      </div>
    </div>
  </CardContext.Provider>;
};
