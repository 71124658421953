import { useContext } from "react";
import { OrdoContext } from "context/OrdoContext";
import { useQuery } from "hooks/queries/useQuery";
import { GetOfficialTranslation } from "queries/translation";

export const useOfficialTranslationQuery = (
  textId?: number | null,
  language?: string,
) => {
  const { defaultLanguage } = useContext(OrdoContext).ordo;
  if (!language) {
    language = defaultLanguage;
  }
  return useQuery(GetOfficialTranslation, {
    variables: {
      textId: textId ?? 0,
      language,
    },
    skip: !textId,
  });
}
