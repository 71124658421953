import { useEffect, useMemo, useState } from "react";
import { VerseStyle } from "utils/api-types/ordo/card/MusicalProperties";
import { getVerovioTranspose } from 'utils/verovio';
import type { TimeMapEntry, VerovioOptions, toolkit } from "verovio";
import type { MidiPassthruProps, MidiVerovioProps } from "./MidiVerovioProps";
import { RenderMidiCallback } from "./Verovio";

type Props =
| (MidiVerovioProps & { verovio: toolkit, svg?: never })
| (MidiPassthruProps & { verovio?: never, mei?: never });
export const useMidiVerovioOrPassthru = ({
  mei, svg, keyOffset, onRenderMidi, verseStyle, setMidiPsalmToneData, verovio
}: Props) => {
  const [meinradToneIndex, setMeinradToneIndex] = useState<number>(-1);
  const data = mei ?? svg;
  const meiContainer = (mei?.antiphon ?? mei?.hymn)?.[0];
  const container = (data.antiphon ?? data.hymn)?.[0];
  const midiOverride = container?.midi;
  const mainMei = meiContainer?.mei;
  const meiKey = data.keyIndex;
  const defaultTranspose = data.defaultTranspose;
  const transposeOffset = (defaultTranspose || 0) + (keyOffset || 0);
  const verovioOptions: VerovioOptions = useMemo(
    () => ({ transpose: getVerovioTranspose(meiKey ?? 0, transposeOffset) }),
    [meiKey, transposeOffset]
  );
  // todo: render additional hymn parts here as well?
  const verseMidi = data.antiphonVerseMidi;
  const isMeinradTone = verseStyle === VerseStyle.POINT && data.verses?.length === 5;
  const toneIndex = isMeinradTone ? meinradToneIndex : 0;
  const versePsalmToneMei = verseStyle === VerseStyle.POINT ? mei?.verses?.[toneIndex] : undefined;
  const versePsalmToneMidi = verseStyle === VerseStyle.POINT ? svg?.versesMidi?.[toneIndex] : undefined;

  useEffect(() => {
    let midiData: Parameters<RenderMidiCallback>
    if (verovio && mainMei && !midiOverride) {
      verovio.setOptions(verovioOptions);
      verovio.loadData(mainMei);
      midiData = [[verovio.renderToMIDI()], [verovio.renderToTimemap()]]
    } else if (midiOverride) {
      midiData = [[midiOverride.midi], [midiOverride.timeMap]];
    } else {
      // no data
      return;
    }
    if (verseMidi) {
      const [midi, timestamp] = midiData;
      // add verse data to midiData variable:
      midi.push(verseMidi.midi);
      timestamp.push(verseMidi.timeMap);
      if (isMeinradTone) {
        // check which meinrad tone it is:
        // it is equivalent to the number of times that every note stops in the tone, minus 2.
        // the number of note stops is equivalent to the number of measures, but array element 0 indicates
        // the version of the tone with two measures, through 4 indicating the version with 6.
        const noteStops = verseMidi.timeMap.filter((entry: TimeMapEntry) => entry.off?.length && (entry.on?.length ?? 0) === 0).length;
        setMeinradToneIndex(noteStops - 2)
      }
    }
    onRenderMidi?.(...midiData);
  }, [onRenderMidi, mainMei, verovio, verovioOptions, verseMidi, verseStyle, isMeinradTone, midiOverride]);

  useEffect(() => {
    if (!setMidiPsalmToneData) return;
    if (versePsalmToneMei && verovio) {
      verovio.loadData(versePsalmToneMei);
      setMidiPsalmToneData([verovio.renderToMIDI(), verovio.renderToTimemap()]);
    } else if (versePsalmToneMidi) {
      setMidiPsalmToneData([versePsalmToneMidi.midi, versePsalmToneMidi.timeMap]);
    } else {
      setMidiPsalmToneData(null);
    }
  }, [setMidiPsalmToneData, verovio, versePsalmToneMei, versePsalmToneMidi]);
}
