/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithNameAndId } from 'utils/api-types/WithNameAndId';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';
import { createMutationCacheUpdater } from './updaters/createMutationCacheUpdater';

export type ChoirEmail = {
  email: string;
} & WithNameAndId;

export const CHOIR_EMAIL_FRAGMENT = gql`
  fragment ChoirEmail on ChoirEmail {
    id
    name
    email
  }
`;

export type ChoirEmailWithGroups = ChoirEmail & {
  choirGroups: WithNameAndId[];
};

export const CHOIR_EMAIL_WITH_GROUPS_FRAGMENT = gql`
  fragment ChoirEmailWithGroups on ChoirEmail {
    ...ChoirEmail
    choirGroups {
      id
      name
    }
  }
  ${CHOIR_EMAIL_FRAGMENT}
`;

export type ChoirEmailOnGroup = ChoirEmailWithGroups & { isTeamMember: boolean; }

export type GroupChoirEmails = {
  id: string;
  choirEmails: ChoirEmailOnGroup[];
}

export const GROUP_CHOIR_EMAILS_FRAGMENT = gql`
  fragment GroupChoirEmails on Group {
    id
    choirEmails {
      ...ChoirEmailWithGroups
      isTeamMember
    }
  }
  ${CHOIR_EMAIL_WITH_GROUPS_FRAGMENT}
`;

/**
 * This fragment and type are only used for updating cache
 */
export type GroupChoirEmailsWithoutGroups = {
  id: string;
  choirEmails: ChoirEmail[];
}

export const GROUP_CHOIR_EMAILS_WITHOUT_GROUPS_FRAGMENT = gql`
  fragment GroupChoirEmailsWithoutGroups on Group {
    id
    choirEmails {
      ...ChoirEmail
    }
  }
  ${CHOIR_EMAIL_FRAGMENT}
`;

export interface GetGroupChoirEmails extends GQLQuery {
  Variables: { id: string };
  Data: { group: GroupChoirEmails };
}
export const GetGroupChoirEmails: GQLDocument<GetGroupChoirEmails> = {
  query: gql`
    query getGroupChoirEmails($id: String!) {
      group(id: $id) {
        ...GroupChoirEmails
      }
    }
    ${GROUP_CHOIR_EMAILS_FRAGMENT}
  `
}

export interface AddChoirEmail extends GQLMutation<AddChoirEmail> {
  Variables: {
    groupId: string;
    email: string;
    name: string;
  }
  Data: {
    choirEmail: ChoirEmail;
  };
}
export const AddChoirEmail: GQLDocument<AddChoirEmail> = {
  mutation: gql`
    mutation addChoirEmail($groupId: String!, $email: String!, $name: String!) {
      choirEmail: addChoirEmail(groupId: $groupId, email: $email, name: $name) {
        ...ChoirEmail
      }
    }
    ${CHOIR_EMAIL_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetGroupChoirEmails,
    (_, { variables }) =>
      variables
      ? { id: variables.groupId }
      : false,
    addOrUpdateChoirEmailInCache
  )
}

export interface UpdateChoirEmail extends GQLMutation<UpdateChoirEmail> {
  Variables: {
    id: number;
    email: string;
    name: string;
  }
  Data: {
    choirEmail: ChoirEmail;
  };
}
export const UpdateChoirEmail: GQLDocument<UpdateChoirEmail> = {
  mutation: gql`
    mutation updateChoirEmail($id: Float!, $email: String!, $name: String!) {
      choirEmail: updateChoirEmail(id: $id, email: $email, name: $name) {
        ...ChoirEmail
      }
    }
    ${CHOIR_EMAIL_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetGroupChoirEmails,
    (_, { context }) =>
      context?.updateQueryVariables?.id
      ? { id: context.updateQueryVariables.id }
      : false,
    addOrUpdateChoirEmailInCache
  )
}

export interface SetChoirEmailName extends GQLMutation<SetChoirEmailName> {
  Variables: {
    id: number;
    name: string;
  }
  Data: {
    choirEmail: ChoirEmail;
  };
}
export const SetChoirEmailName: GQLDocument<SetChoirEmailName> = {
  mutation: gql`
    mutation setChoirEmailName($id: Float!, $name: String!) {
      choirEmail: setChoirEmailName(id: $id, name: $name) {
        ...ChoirEmail
      }
    }
    ${CHOIR_EMAIL_FRAGMENT}
  `,
}

export interface RemoveChoirEmail extends GQLMutation<RemoveChoirEmail, GetGroupChoirEmails> {
  Variables: {
    id: number;
  }
  Data: {
    choirEmailId: number;
  };
}
export const RemoveChoirEmail: GQLDocument<RemoveChoirEmail> = {
  mutation: gql`
    mutation removeChoirEmail($id: Float!) {
      choirEmailId: removeChoirEmail(id: $id)
    }
  `,
  updater: createMutationCacheUpdater(
    GetGroupChoirEmails,
    (_, { context }) =>
      context?.updateQueryVariables?.id
      ? { id: context.updateQueryVariables.id }
      : false,
    (data: GetGroupChoirEmails['Data'], { choirEmailId }: RemoveChoirEmail['Data']) => {
      const emailIndex = data.group.choirEmails.findIndex(({ id }) => id === choirEmailId);
      if (emailIndex === -1) {
        return false;
      }
      data.group.choirEmails.splice(emailIndex, 1);
      return true;
    }
  ),
}

function addOrUpdateChoirEmailInCache (data: GetGroupChoirEmails['Data'], { choirEmail }: { choirEmail: ChoirEmail }) {
  const emailIndex = data.group.choirEmails.findIndex(({ id }) => id === choirEmail.id);
  const choirEmailWithGroups = { ...choirEmail, choirGroups: [], isTeamMember: false };
  if (emailIndex !== -1) {
    data.group.choirEmails.splice(emailIndex, 1, choirEmailWithGroups);
  } else {
    data.group.choirEmails.push(choirEmailWithGroups);
  }
  return true;
}
