/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { GQLDocument, GQLQuery } from './gql-generics';
import { UserRoles } from 'hooks/permission-set/useHasRole';
import { GatedFeature, PermissionSetId } from 'utils/api-types/PermissionSetId';
import { WithId } from 'utils/api-types/WithNameAndId';

export type PermissionSet = {
  seedId: PermissionSetId;
  type: 'ANY' | 'ALL' | 'NOT';
  cached?: true;
  roles: ({
    name: UserRoles;
  } & WithId)[];
} & WithId;

export const PERMISSION_SET_FRAGMENT = gql`
  fragment PermissionSet on PermissionSet {
    id
    seedId
    type
    cached
    roles {
      id
      name
    }
  }
`;

export interface GetPermissionSet extends GQLQuery {
  Variables: { seedId: GatedFeature };
  Data: { permissionSet: PermissionSet };
}
export const GetPermissionSet: GQLDocument<GetPermissionSet> = {
  query: gql`
    query getPermissionSet($seedId: String!) {
      permissionSet(seedId: $seedId) {
        ...PermissionSet
      }
    }
    ${PERMISSION_SET_FRAGMENT}
  `
}

export interface GatedFeatures extends GQLQuery {
  Variables: { seedIds: GatedFeature[] };
  Data: { permissionSets: PermissionSet[] };
}
export const GatedFeatures: GQLDocument<GatedFeatures> = {
  query: gql`
    query permissionSets($seedIds: [String!]!) {
      permissionSets(seedIds: $seedIds) {
        ...PermissionSet
      }
    }
    ${PERMISSION_SET_FRAGMENT}
  `
}
