import React, { useEffect, useContext } from 'react';
import { useParseSectionHeaderTexts } from 'hooks/ordo/cards';
import { CardContext } from 'context/CardContext';

export const SectionHeaderCard: React.FC = () => {
  const { onLoaded, textOptions } = useContext(CardContext);
  const {title, subTitle} = useParseSectionHeaderTexts(textOptions);

  useEffect(() => {
    onLoaded();
  }, [onLoaded]);

  return <>
    {title !== '' && <p className='_EXPORT section-title text-center font-family-sans-serif m-0'>{title}</p>}
    {subTitle !== '' && <p className='_EXPORT section-subtitle text-center fst-italic font-14 m-0 mt-1 color-light-text'>{subTitle}</p>}
  </>;
};
