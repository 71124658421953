import { ErrorMessage } from 'components/gql/ErrorMessage';
import { LoadingMessage } from 'components/gql/LoadingMessage';
import { ExsurgeWrapper } from 'components/shared/ExsurgeWrapper';
import { VerovioAntiphonVerseWrapper } from 'components/shared/VerovioAntiphonVerseWrapper';
import { RenderMidiCallback } from 'components/verovio/Verovio';
import { useIdQuery } from 'hooks/queries/useQuery';
import { useDefaultLanguageTranslation } from 'hooks/text';
import { useGloryBeTranslation } from 'hooks/text/useGloryBe';
import useResizeObserver from 'hooks/util/useResizeObserver';
import { CardMei, CardSvg } from 'queries/card-fragment';
import { GetCollectionComposition } from 'queries/collection';
import { Text } from 'queries/text';
import { TextOption } from 'queries/text-option';
import React, { RefObject, useCallback, useEffect } from 'react';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { findAntiphon } from 'utils/functions/composition/findAntiphon';
import { findVerse } from 'utils/functions/composition/findVerse';
import { isAlleluiaTranslation } from 'utils/functions/isAlleluiaTranslation';
import { splitVersesByType, verseSplitTypeForCard } from 'utils/markup/splitVerses';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { AntiphonDisplayMode, OfficialAntiphonTranslation } from './OfficialAntiphonTranslation';

interface CollectionAntiphonTranslationProps {
  cardType: CardTypes;
  antiphonText: Text;
  textOption?: TextOption;
  cardConfig: OrdoCardConfig;
  cardMei?: CardMei | null;
  cardSvg?: CardSvg | null;
  verseText?: Text;
  parentRef: RefObject<HTMLDivElement>;
  isFullScreen?: boolean;
  addGrandStaff?: boolean;
  isEasterVigilTripleAntiphon?: boolean;
  useVersicleSymbolForVerses?: boolean;
  displayMode?: AntiphonDisplayMode;
  onRenderMidi?: RenderMidiCallback;
  onLoaded?: () => void;
}

export const CollectionAntiphonTranslation: React.FC<CollectionAntiphonTranslationProps> = ({
  cardType,
  antiphonText,
  textOption,
  cardConfig,
  cardMei,
  cardSvg,
  verseText,
  parentRef,
  isFullScreen = false,
  addGrandStaff = false,
  isEasterVigilTripleAntiphon,
  useVersicleSymbolForVerses = false,
  displayMode,
  onRenderMidi,
  onLoaded,
}) => {
  const { width = 0 } = useResizeObserver({ref: parentRef});
  // if we have a cardSvg, we don't need to query the composition etc.
  const compositionId = cardSvg ? null : cardConfig.compositionId;
  const verseCompositionId = cardSvg ? null : cardConfig.verseCompositionId;
  const {
    loading: loadingComposition,
    error: compositionError,
    data: { collectionComposition: composition } = { collectionComposition: undefined, },
  } = useIdQuery(GetCollectionComposition, compositionId);
  const {
    loading: loadingVerseComposition,
    error: verseCompositionError,
    data: { collectionComposition: verseComposition } = { collectionComposition: null, },
  } = useIdQuery(GetCollectionComposition, verseCompositionId);

  const antiphon = findAntiphon(cardConfig.antiphonId, composition);
  const verse = findVerse(cardConfig.verseId, verseComposition || composition);
  const antiphonTranslation = antiphon?.translation;
  const antiphonAltContent =
    cardSvg?.additionalAntiphonText ??
    (isAlleluiaTranslation(antiphonTranslation)
      ? null
      : antiphonTranslation?.altContent);
  const additionalAntiphonText = cardConfig.additionalAntiphonText ? antiphonAltContent : null;
  const defaultLanguage = cardConfig.language || composition?.collection.language || 'en';
  const verseLanguage = verseComposition?.collection.language || defaultLanguage;
  const defaultVerseTranslation = useDefaultLanguageTranslation(verseText, verseLanguage);
  /*  Use the composition's translation if it exists, otherwise, use the official translation for the provided language */
  const verseTranslation = verse?.translation || (defaultVerseTranslation?.language === verseLanguage ? defaultVerseTranslation : null);
  const gloryBeTranslation = useGloryBeTranslation(verseLanguage);

  const verseSplitType = verseSplitTypeForCard(cardType, cardConfig);
  const defaultVerseCount = splitVersesByType(verseTranslation?.content, verseSplitType).length;
  const verseCount = cardConfig.verseCount ?? defaultVerseCount;
  useEffect(() => {
    if (antiphon && cardConfig.notation === MusicalNotation.TEXT) {
      onLoaded?.();
    }
  }, [antiphon, cardConfig.notation, onLoaded])

  const onRenderAntiphonMidi: RenderMidiCallback = useCallback((...[base64String, timeMaps]: Parameters<RenderMidiCallback>) => {
    onLoaded?.();
    onRenderMidi?.(base64String, timeMaps);
  }, [onLoaded, onRenderMidi]);

  return (
    <>
      {(loadingComposition || loadingVerseComposition) && <LoadingMessage />}

      {cardConfig.compositionId && compositionError && <ErrorMessage
          headline='Error loading composition data'
          error={compositionError}
      />}
      {cardConfig.verseCompositionId && verseCompositionError && <ErrorMessage
          headline='Error loading verse composition data'
          error={verseCompositionError}
      />}

      {!cardSvg?.antiphon &&
        antiphon &&
        (!cardConfig.notation ||
          cardConfig.notation === MusicalNotation.SQUARE) && (
          <ExsurgeWrapper
            removeSolesmesMarkings={cardConfig.removeSolesmesMarkings ?? false}
            textOption={textOption}
            language={defaultLanguage}
            defaultVerseLanguage={verseLanguage}
            includeGloryBe={cardConfig?.includeGloryBe ?? false}
            antiphon={antiphon}
            antiphonText={antiphonText}
            additionalAntiphonText={additionalAntiphonText}
            verseText={verseText}
            verse={verse}
            verseStyle={cardConfig.verseStyle}
            verseCount={verseCount}
            verseColumns={cardConfig.verseColumns}
            verseSplitType={verseSplitType}
            useVersicleSymbolForVerses={useVersicleSymbolForVerses}
            capitalizeInitial={true}
            spacingHorizontal={cardConfig.spacingHorizontal}
            spacingVertical={cardConfig.spacingVertical}
            width={width}
            onLoaded={onLoaded}
          />
        )}

      {(cardSvg?.antiphon ||
        (antiphon && cardConfig.notation === MusicalNotation.MODERN)) && (
        <VerovioAntiphonVerseWrapper
          displayMode={cardConfig.verseStyle}
          cardMei={cardMei}
          cardSvg={cardSvg}
          antiphonScriptureReference={antiphonText?.originalSource}
          antiphonSources={textOption?.sources}
          antiphonMode={cardSvg?.antiphonMode ?? antiphon?.melody.mode}
          additionalAntiphonText={additionalAntiphonText}
          isEasterVigilTripleAntiphon={isEasterVigilTripleAntiphon}
          verseText={verseTranslation?.content}
          verseLanguage={verseLanguage}
          verseMusic={cardSvg?.verseGabc ?? verse?.melody.voice.data}
          useVersicleSymbolForVerses={useVersicleSymbolForVerses}
          verseScriptureReference={verseText?.originalSource}
          keyOffset={cardConfig.keyOffset}
          verseCount={verseCount}
          verseColumns={cardConfig.verseColumns}
          verseSplitType={verseSplitType}
          gloryBeText={
            cardConfig.includeGloryBe && gloryBeTranslation
              ? gloryBeTranslation.content
              : ''
          }
          isFullScreen={isFullScreen}
          showCanonicalSource={cardType !== CardTypes.RESPONSORIAL_PSALM}
          addGrandStaff={addGrandStaff}
          displayHarmonizations={cardConfig.displayHarmonizations}
          onRenderAntiphonMidi={onRenderAntiphonMidi}
          spacingLinear={cardConfig.spacingLinear}
          spacingNonLinear={cardConfig.spacingNonLinear}
          spacingHorizontal={cardConfig.spacingHorizontal}
          spacingVertical={cardConfig.spacingVertical}
        />
      )}

      {antiphon && cardConfig.notation === MusicalNotation.TEXT && <OfficialAntiphonTranslation
        antiphonTranslation={antiphon.translation}
        antiphon={antiphonText}
        textOption={textOption}
        verse={verseText}
        includeGloryBe={cardConfig.includeGloryBe ?? undefined}
        additionalAntiphonText={additionalAntiphonText}
        displayMode={displayMode}
        verseCount={cardConfig.verseCount}
        verseColumns={cardConfig.verseColumns}
        verseSplitType={verseSplitType}
        isEasterVigilTripleAntiphon={isEasterVigilTripleAntiphon}
      />}
    </>
  );
}
