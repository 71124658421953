import React, { PropsWithChildren, useState } from 'react';

export enum SaveState {
  SAVED = 'All changes saved',
  SAVING = 'Saving changes...',
  SAVE_ERR = 'Error',
}

export interface SaveStateContextInterface {
  saveState: SaveState;
  setSaveState: (saveState: SaveState) => void;
}

export const SaveStateContext = React.createContext({
  saveState: SaveState.SAVED,
  setSaveState: (_: SaveState) => {},
});

export const SaveStateContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [saveState, setSaveState] = useState(SaveState.SAVED);
  return <SaveStateContext.Provider value={{saveState, setSaveState}}>
    {children}
  </SaveStateContext.Provider>;
}
