import { ReactComponent as PrintLogo } from 'assets/print-logo.svg';
import { UnderscoreItalic } from 'components/shared/UnderscoreItalic';
import { LayoutContext } from 'context/LayoutContext';
import { OrdoContext } from 'context/OrdoContext';
import { getConfigOnCard } from 'hooks/ordo/cards/useCardConfig';
import { OrdoCard } from 'queries/card-fragment';
import React, { ReactElement, useContext } from 'react';
import { getCardAttribution, getTermsOfUseAttribution } from 'utils/functions/card';

interface CombinedOrdoCardAttributionsProps {
  cards: OrdoCard[];
  accountId: number;
}

export const CombinedOrdoCardAttributions = React.forwardRef<HTMLDivElement, CombinedOrdoCardAttributionsProps>(({
  cards,
  accountId,
}, ref) => {
  const attributions = new Set<string>();
  const { selectedRole } = useContext(OrdoContext);
  const { activeFile: { columns: layoutColumns, config: { attributions: layoutAttributions, attributionFontSize } } } = useContext(LayoutContext);
  const displayLogo = !(layoutColumns && layoutColumns > 1);
  for (const card of cards) {
    const config = getConfigOnCard(card, selectedRole);
    const cardAttributions = getCardAttribution(card, config, true);
    cardAttributions.split('\n').forEach((a) => a && attributions.add(a));
  }
  attributions.add(getTermsOfUseAttribution(accountId, true));
  const attributionFragments: ReactElement[] = [];
  for (const a of Array.from(attributions.values())) {
    // replace nonbreaking space with standard space, since non-breaking is only important for the centered attributions
    attributionFragments.push(<UnderscoreItalic text={a.replace(/ /g, ' ')}/>);
  }
  return (
    <div
      ref={ref}
      style={{ width: 590, fontSize: `${attributionFontSize ?? 8}pt` }}
      className={'combined-ordo-card-attributions copyright-page copyright-text no-page-break-inside d-flex flex-column justify-content-end align-items-center'}
    >
      <div>
        {attributionFragments.map((a, i) => (
          <p key={i}>{a}</p>
        ))}
        {layoutAttributions && <p dangerouslySetInnerHTML={{ __html: layoutAttributions }} />}
      </div>
      {displayLogo && <PrintLogo className='copyright-logo w-50 mt-4' data-export-pad-top="24" style={{ visibility: 'visible' /* hack to force the svg to display when exporting */ }}/>}
    </div>
  );
});
