import { Attribute } from 'queries/resource';
import React from 'react';
import { ResourceGroup } from 'utils/api-types/resource';

interface ResourceAttributesDisplayProps {
  resourceGroup: ResourceGroup;
  displayDataItems?: string[];
}

export const ResourceAttributesDisplay: React.FC<ResourceAttributesDisplayProps> = ({
  resourceGroup,
  displayDataItems,
}: ResourceAttributesDisplayProps) => {
  const attributes: Omit<Attribute | { name: 'title', value: string }, 'id'>[] = resourceGroup.resourceReference
  ? [
    ...resourceGroup.resourceReference.attributes,
    { name: 'collection', value: resourceGroup.resourceReference.collection.name + (resourceGroup.resourceReference.collection.edition ? ` ${resourceGroup.resourceReference.collection.edition}` : '') },
    { name: 'title', value: resourceGroup.resourceReference.name },
    { name: 'pageNumber', value: resourceGroup.resourceReference.reference },
  ]
  : [
    ...resourceGroup.attributes,
    { name: 'collection', value: resourceGroup?.collection ?? '' },
    { name: 'title', value: resourceGroup?.title ?? '' },
  ];
  const attributeMap = Object.fromEntries(attributes.map((a) => [a.name, a]));
  const attributesDisplay = (
    displayDataItems?.map((key) =>
      key
        .split('|')
        .reduce(
          (result: null | (typeof attributeMap)[string], key) =>
            result ||
            (attributeMap[key]?.value?.trim() ? attributeMap[key] : null),
          null,
        ),
    ) ?? attributes
  ).filter((a) => a?.value?.trim()) as typeof attributes;
  return (
    <>
      {attributesDisplay.map(({ name, value }, index) => (
        <React.Fragment key={name}>
          {index > 0 ? (name === 'pageNumber' ? ' ' : ', ') : ''}
          <span title={name} className={/^(?:title|setting)$/.test(name) ? '' : 'fst-italic'}>
            {name === 'pageNumber' && '#'}{value}
          </span>
        </React.Fragment>
      ))}
    </>
  );
};
