const italicRegex = /_([^_]+)_/g;
const rubricRegex = /<rubric>([^<]+)<\/rubric>/g;
const syllabificationRegex = /[|+](?!})/g;
const emptyLineNormalizerRegex = /\n\s*\n/g;
const newLineNormalizerRegex = /\r\n/g;
const versicleRegex = /\(?℣\.?\)?[  ]/g;
const responseRegex = /\(?℟\.?\)?[  ]/g;
const responseToLineEndRegex = /(\(?℟\.?\)?[^\n\r]*)(?=[\r\n])/g;
const verseMarkerRegex = /^(\d+\.|\*)[  ]/gm;

export const processText = (text?: string): string => {

  if (!text) return '';
  return text
    .replace(rubricRegex, `<div class='rubric no-page-break-after'>$1</div>`)
    .replace(italicRegex, `<i>$1</i>`)
    .replace(responseToLineEndRegex, `$1<br/>`)
    .replace(versicleRegex, `<span class="verse-marker versiculum-text">v</span> `)
    .replace(responseRegex, `<span class="verse-marker versiculum-text">r</span> `)
    .replace(verseMarkerRegex, `<span class="verse-marker">$1 </span>`)
    .replace(syllabificationRegex, '')
    .replace(emptyLineNormalizerRegex, '\n\n')
    .replace(newLineNormalizerRegex, '\n')
    // I'm not sure why we have to double up the whitespace that comes before
    // the word LORD, when making it small caps, but otherwise, React is removing the whitespace at some level.
    .replace(/(\s?)\b(LORD)\b/g, '$1$1<span class="all-small-caps">$2</span>')
  ;
}
