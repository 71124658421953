/// Based on https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49

import { FieldPolicy, Reference } from "@apollo/client/cache";
import { KeyArgsFunction } from "@apollo/client/cache/inmemory/policies";
declare type KeyArgs = FieldPolicy<any>["keyArgs"];

const defaultKeyArgsFunction: KeyArgsFunction = (args, context) => {
  if (!args) return false;
  return Object.keys(args).filter((key) => key !== 'offset' && key !== 'limit');
};
// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export function offsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = defaultKeyArgsFunction,
): FieldPolicy<(Reference|T)[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing ? existing.slice(0) : [];
      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { offset = 0, limit } = args;
        if (incoming.length) {
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
          // filter out any duplicate references, since the same item cannot be in the list twice:
          const refs = incoming.map(ref => (ref as Reference).__ref).filter(ref => ref);
          const existingAtEnd = merged.splice(offset + incoming.length);
          const filtered = existingAtEnd.filter(ref => !refs.includes((ref as Reference).__ref));
          merged.splice(offset + incoming.length, 0, ...filtered);
        }
        if (limit && incoming.length < limit) {
          const endOffset = incoming.length + offset;
          // if incoming has fewer than the limit, that means the end of the list has been reached, and if we have anything beyond that, it's old and an error:
          merged.splice(endOffset);
        }
      } else {
        throw new Error("no args given to offset limit pagination query");
      }
      return merged;
    },
  };
}
