// the string values are the seed IDs used in the google sheet for permission sets
// from api/LiturgicalSeedIds.ts:
export enum PermissionSetId {
  Admin = 'Admin',
  Alpha = 'Alpha',
  Essentials = 'Essentials',
  Standard = 'Standard',
  Basic = 'Basic',
  CompleteDigital = 'Complete Digital',
  MissalSupport = 'Missal Support',
  SSGradual = 'Source & Summit Gradual',
  SSMissal = 'Source & Summit Missal',
  NotSSMissal = 'NOT Source & Summit Missal',
  StandardorCompleteDigital = 'Standard or Complete Digital',
  NotMissalSupport = 'NOT Missal Support',
  NeitherMissalSupportNorEssentials = 'NEITHER Missal Support NOR Essentials',
  NotEssentials = 'NOT Essentials',
  TrialMarketingHeading = 'Trial Marketing Heading',
  MissalReferenceNumber = 'Missal Reference Number',
  RitualMasses = 'Ritual Masses',
  MassesForTheDead = 'Masses for the Dead',
  OtherLiturgies = 'Other Liturgies',
  Hymns = 'Hymns',
  SeasonalAntiphons = 'Seasonal Antiphons',
  EucharisticAntiphons = 'Eucharistic Antiphons',
  CustomText = 'Custom Text',
  RoleSwitcher = 'Role Switcher',
  Languages = 'Languages',
  SundaysAndSolemnities = 'Sundays And Solemnities',
  SSMHymnToggle = 'SSM Hymn Toggle',
  LentenGospelAcclamationMusicalSettings = 'Lenten Gospel Acclamation Musical Settings',
  Readings = 'Readings',
  MultiUserGroups = 'Multi-user groups',
  AllUsers = 'All Users',
  TrialUser = 'Trial User',
  ActiveUser = 'Active User',
  Templating = 'Templating',
  Referrals = 'Referrals',
  FullTextReadings = 'Full-text Readings',
  TextOnlyEnglishAntiphons = 'Text-only English Antiphons',
  MissalHymnsOnly = 'Missal Hymns Only',
  AdLibitumAntiphons = 'Ad Libitum Antiphons',
  NotBasic = 'NOT Basic',
  AntiphonVersesOtherThanCommunion = 'Antiphon Verses Other Than Communion',
}

export enum GatedFeature {
  TrialMarketingHeading = PermissionSetId.TrialMarketingHeading,
  MissalReferenceNumber = PermissionSetId.MissalReferenceNumber,
  RitualMasses = PermissionSetId.RitualMasses,
  MassesForTheDead = PermissionSetId.MassesForTheDead,
  OtherLiturgies = PermissionSetId.OtherLiturgies,
  Hymns = PermissionSetId.Hymns,
  SeasonalAntiphons = PermissionSetId.SeasonalAntiphons,
  EucharisticAntiphons = PermissionSetId.EucharisticAntiphons,
  CustomText = PermissionSetId.CustomText,
  RoleSwitcher = PermissionSetId.RoleSwitcher,
  Languages = PermissionSetId.Languages,
  SundaysAndSolemnities = PermissionSetId.SundaysAndSolemnities,
  SSMHymnToggle = PermissionSetId.SSMHymnToggle,
  LentenGospelAcclamationMusicalSettings = PermissionSetId.LentenGospelAcclamationMusicalSettings,
  Readings = PermissionSetId.Readings,
  Templating = PermissionSetId.Templating,
  GradualeTexts = PermissionSetId.NotMissalSupport,
  // This can be updated to be its own permission set at some point if necessary
  TemplateSSMToggle = PermissionSetId.MissalSupport,
  Referrals = PermissionSetId.Referrals,
  MultiUserGroups = PermissionSetId.MultiUserGroups,
  FullTextReadings = PermissionSetId.FullTextReadings,
  AntiphonVersesOtherThanCommunion = PermissionSetId.AntiphonVersesOtherThanCommunion,
  AdLibitumAntiphons = PermissionSetId.AdLibitumAntiphons,
  Basic = PermissionSetId.Basic,
  PrayerCard = PermissionSetId.NotBasic,
  NotEssentials = PermissionSetId.NotEssentials,
  NotBasic = PermissionSetId.NotBasic,
}
