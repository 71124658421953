import { CardMei, CardSvg } from 'queries/card-fragment';
import { MidiPassthru } from './MidiPassthru';
import { MidiVerovio } from './MidiVerovio';
import { MidiVerovioProps } from './MidiVerovioProps';

export type MidiVerovioOrPassthruProps = Omit<MidiVerovioProps, 'mei'> & {
  mei?: CardMei;
  svg?: CardSvg;
};
/**
 * This way, verovio will only be loaded if necessary.
 * @returns 
 */
export const MidiVerovioOrPassthru: React.FC<MidiVerovioOrPassthruProps> = ({
  mei,
  svg,
  ...props
}) => {
  return mei ? (
    <MidiVerovio mei={mei} {...props} />
  ) : svg ? (
    <MidiPassthru svg={svg} {...props} />
  ) : null;
};
