import {
  combinedAttributionsKey,
  ordoTitleKey,
  outlineKey,
} from 'components/ordo/modals/export/ExportRenderer';
import { LayoutContext, defaultLayoutContext, getHarmonizedMargin } from 'context/LayoutContext';
import { useGetConfigOnCard } from 'hooks/ordo/cards/useCardConfig';
import { LayoutCard, isImageDimensions } from 'queries/layout-file';
import { Ordo, OrdoShare } from 'queries/ordo';
import { useContext, useMemo } from 'react';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { PdfCardBlocks } from 'utils/export/PdfBlockTypes';
import { toPt } from 'utils/export/exportDimensionSettings';
import { PdfPage } from 'utils/export/pdfTools';
import { getDisplayHarmonization } from 'utils/functions/card/getDisplayHarmonization';
import { Dictionary } from 'utils/typescript/Dictionary';

export const useSortedPdfCards = (
  blockDictionary: Dictionary<PdfCardBlocks>,
  { cards: ordoCards, config: ordoConfig }: Ordo | OrdoShare,
  {
    crop,
    isPngExport,
    isFullOrdoExport,
    includeOutline,
    contentWidth = 5.5,
    harmonizedContentWidth = 7,
    disabledCardIds,
    layoutCardBlocks,
    layoutCardMap,
    overrideConfigMap,
    pdfCardIds,
  }: {
    crop: boolean;
    isPngExport: boolean;
    isFullOrdoExport: boolean;
    includeOutline: boolean;
    /**
     * in inches
     */
    contentWidth?: number;
    /**
     * in inches
     */
    harmonizedContentWidth?: number;
    disabledCardIds?: Set<number>;
    layoutCardBlocks?: Dictionary<PdfCardBlocks>;
    layoutCardMap?: Map<string, LayoutCard>;
    overrideConfigMap?: Map<number, OrdoCardConfig>;
    pdfCardIds?: Set<string>;
  },
): PdfPage[] | undefined => {
  const { activeFile } = useContext(LayoutContext);
  const { marginLeft, marginRight, config } = activeFile;
  const [marginLeftHarmonized, marginRightHarmonized] = (
    ['Left', 'Right'] as const
  ).map((side) => getHarmonizedMargin(side, activeFile));
  const getConfigOnCard = useGetConfigOnCard();
  const ordoCardMap = useMemo(() => new Map(ordoCards.map(card => [card.id, card])), [ordoCards]);

  const cardSortPositions = useMemo(
    () =>
      Object.fromEntries([
        ...ordoCards.map(({ id, sortPosition }) => [id, sortPosition]),
        ...(Array.from(layoutCardMap ?? []).map(([id, { sortPosition }]) => [id, sortPosition]))
      ]),
    [layoutCardMap, ordoCards],
  );

  return useMemo(() => {
    let entries = Object.entries(blockDictionary);
    if (!entries.length) {
      return;
    }
    if (layoutCardBlocks) entries.push(...Object.entries(layoutCardBlocks));
    if (disabledCardIds?.size) {
      entries = entries.filter(([key]) => !disabledCardIds.has(Number(key)));
    }
    const sortedEntries = entries
      .filter(([key]) =>
        (crop || isPngExport) && isFullOrdoExport
          ? key !== ordoTitleKey && key !== outlineKey
          : key.match(/^\d/) || layoutCardMap?.has(key),
      )
      .sort(([a], [b]) => cardSortPositions[a] - cardSortPositions[b]);

    // update the page break handling by copying any information about page breaks
    // at the beginning of a block into the previous block, since it is pageBreakAfter
    // that is actually used within the PDF generator.
    const pdfBlocks = sortedEntries.flatMap(([, entry]) => entry.blocks);
    if (pdfBlocks.length > 1) {
      pdfBlocks.reduce((prev, curr) => {
        if (
          curr.pageBreakBefore !== undefined &&
          prev.pageBreakAfter === undefined
        ) {
          prev.pageBreakAfter = curr.pageBreakBefore;
        }
        return curr;
      });
    }
    if (includeOutline) {
      sortedEntries.unshift([outlineKey, blockDictionary[outlineKey]]);
    }
    if (isFullOrdoExport && !(crop || isPngExport)) {
      sortedEntries.unshift([ordoTitleKey, blockDictionary[ordoTitleKey]]);
      if (!ordoConfig.accompanimentExport) {
        // don't put in the combined attribution page on accompaniment exports:
        sortedEntries.push([
          combinedAttributionsKey,
          blockDictionary[combinedAttributionsKey],
        ]);
      }
    }
    const isLayout = activeFile !== defaultLayoutContext.activeFile;
    const isLayoutImages = isImageDimensions(activeFile);
    return sortedEntries.map(([key, { blocks, cardConfig }], index, array) => {
      const nextCardId = array[index + 1]?.[0];
      const nextCardIfOrdo = ordoCardMap.get(Number(nextCardId));
      const nextCardIfLayout = layoutCardMap?.get(nextCardId);
      const nextCardConfig =
        nextCardIfLayout?.config ?? getConfigOnCard(nextCardIfOrdo);
      const isPdfCard = pdfCardIds?.has(key);
      const isNextCardPdf = pdfCardIds?.has(nextCardId);
      if (overrideConfigMap && cardConfig) {
        // update card configs with layout overrides, if in a layout
        const override = overrideConfigMap.get(Number(key));
        if (override) {
          cardConfig = { ...cardConfig, ...override };
        }
      }
      const useHarmonizedContentWidth =
        key === outlineKey || !!getDisplayHarmonization(cardConfig);
      const [marginLeftIn, marginRightIn, contentWidthIn] =
        useHarmonizedContentWidth
          ? [
              marginLeftHarmonized,
              marginRightHarmonized,
              harmonizedContentWidth,
            ]
          : [marginLeft, marginRight, contentWidth];
      const isOrdoOutline = key === outlineKey;
      const isOrdoTitle = key === ordoTitleKey;
      const isFollowedByAttributions = nextCardId === combinedAttributionsKey;
      const isFollowedByOrdoOutline = nextCardId === outlineKey;
      const pageBreakAfter =
        isPdfCard ||
        isNextCardPdf ||
        (isOrdoOutline
          ? true
          : isFollowedByOrdoOutline
          ? false
          : isLayoutImages
          ? true
          : isFollowedByAttributions
          ? config.breakBeforeAttributions ?? config.breakEveryCard ?? undefined
          : nextCardConfig.pageBreakBefore ??
            (isOrdoTitle ? false : config.breakEveryCard ?? undefined));
      const lastBlock = blocks[blocks.length - 1];
      if (lastBlock) lastBlock.pageBreakAfter = pageBreakAfter;
      return {
        key,
        pageBreakAfter,
        blocks,
        cardConfig,
        options: {
          verticalAlign:
            key === combinedAttributionsKey
              ? ('bottom' as 'bottom')
              : undefined,
          ...(cardConfig || key === outlineKey
            ? {
                contentWidth: toPt(contentWidthIn),
                ...(isLayout
                  ? {
                      leftMargin: toPt(marginLeftIn ?? 0),
                      rightMargin: toPt(marginRightIn ?? 0),
                    }
                  : {}),
              }
            : {}),
        },
      } satisfies PdfPage;
    });
  }, [
    blockDictionary,
    layoutCardBlocks,
    disabledCardIds,
    includeOutline,
    isFullOrdoExport,
    crop,
    isPngExport,
    activeFile,
    layoutCardMap,
    cardSortPositions,
    ordoConfig.accompanimentExport,
    ordoCardMap,
    getConfigOnCard,
    pdfCardIds,
    overrideConfigMap,
    marginLeftHarmonized,
    marginRightHarmonized,
    harmonizedContentWidth,
    marginLeft,
    marginRight,
    contentWidth,
    config.breakBeforeAttributions,
    config.breakEveryCard,
  ]);
};
