import { ErrorMessage } from 'components/gql/ErrorMessage';
import { LoadingMessage } from 'components/gql/LoadingMessage';
import { ExsurgeWrapper } from 'components/shared/ExsurgeWrapper';
import { VerovioAntiphonVerseWrapper } from 'components/shared/VerovioAntiphonVerseWrapper';
import { RenderMidiCallback } from 'components/verovio/Verovio';
import { useBackendLoggedIdQuery, useIdQuery } from 'hooks/queries/useQuery';
import { useDefaultLanguageTranslation } from 'hooks/text';
import useResizeObserver from 'hooks/util/useResizeObserver';
import { CardMei, CardSvg } from 'queries/card-fragment';
import { GetCollectionComposition } from 'queries/collection';
import { GetText, Text } from 'queries/text';
import { TextOptionWithMeta } from 'queries/text-option';
import React, { RefObject, useCallback, useEffect } from 'react';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { findAntiphon } from 'utils/functions/composition/findAntiphon';
import { findVerse } from 'utils/functions/composition/findVerse';
import { splitVersesByType, verseSplitTypeForCard } from 'utils/markup/splitVerses';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { AlleluiaDisplayMode, OfficialAlleluiaTranslation } from './OfficialAlleluiaTranslation';

interface AlleluiaMusicProps {
  config: OrdoCardConfig;
  verseText?: Text;
  textOption?: TextOptionWithMeta;
  displayMode: AlleluiaDisplayMode;
  cardMei?: CardMei | null; 
  cardSvg?: CardSvg | null; 
  parentRef: RefObject<HTMLDivElement>;
  isFullScreen?: boolean;
  addGrandStaff?: boolean;
  isLentenGospelAcclamation?: boolean;
  onRenderMidi?: RenderMidiCallback;
  onLoaded?: () => void;
}

export const AlleluiaMusic: React.FC<AlleluiaMusicProps> = ({
  config,
  verseText,
  textOption,
  displayMode = AlleluiaDisplayMode.DEFAULT,
  cardMei,
  cardSvg,
  parentRef,
  isFullScreen = false,
  addGrandStaff = false,
  isLentenGospelAcclamation,
  onRenderMidi,
  onLoaded,
}) => {
  const { width = 0 } = useResizeObserver({ref: parentRef});
  const {
    loading: loadingComposition,
    error: compositionError,
    data: { collectionComposition: composition } = {
      collectionComposition: undefined,
    },
  } = useIdQuery(GetCollectionComposition, config.compositionId);
  const {
    loading: loadingVerseComposition,
    error: verseCompositionError,
    data: { collectionComposition: verseComposition } = {
      collectionComposition: undefined,
    },
  } = useIdQuery(GetCollectionComposition, config.verseCompositionId);

  let verse = findVerse(config.verseId, verseComposition || composition);

  const { loading: loadingAlleluia, error: alleluiaError, data: alleluiaData } = useBackendLoggedIdQuery(GetText, config.antiphonId);
  let antiphonText = alleluiaData ? alleluiaData.text : undefined;
  let antiphon = findAntiphon(config.antiphonId, composition);

  const swapAntiphonVerse = displayMode === AlleluiaDisplayMode.VERSE_ONLY && config.notation !== MusicalNotation.TEXT;
  if (swapAntiphonVerse) {
    [antiphonText, verseText] = [verseText, antiphonText];
  }

  const defaultLanguage = (verseComposition || composition)?.collection.language ?? undefined
  const defaultVerseTranslation = useDefaultLanguageTranslation(verseText, defaultLanguage);
  const verseTranslation = verse?.translation || defaultVerseTranslation;
  const language = (!verseText && textOption?.textCategory?.name === 'Tract')
    ? (config.language ?? 'la')
    : verseTranslation?.language ?? config.language ?? 'en';

  const verseSplitType = verseSplitTypeForCard(CardTypes.ALLELUIA);
  const verseCount = splitVersesByType(verseTranslation?.content, verseSplitType).length;

  const onRenderAntiphonMidi: RenderMidiCallback = useCallback((...[base64String, timeMaps]: Parameters<RenderMidiCallback>) => {
    onLoaded?.();
    onRenderMidi?.(base64String, timeMaps);
  }, [onLoaded, onRenderMidi]);

  const textAntiphonHasLoaded = !!antiphon?.translation && config.notation === MusicalNotation.TEXT;
  useEffect(() => {
    if (textAntiphonHasLoaded) {
      onLoaded?.();
    }
  }, [textAntiphonHasLoaded, onLoaded])

  return (
    <>
      {(loadingComposition || loadingVerseComposition || loadingAlleluia) && <LoadingMessage />}

      {compositionError && <ErrorMessage
          headline='Error loading composition data'
          error={compositionError}
      />}
      
      {verseCompositionError && <ErrorMessage
          headline='Error loading verse composition data'
          error={verseCompositionError}
      />}

      {alleluiaError && <ErrorMessage
          headline='Error loading alleluia composition data'
          error={alleluiaError}
      />}

      {antiphon && (!config.notation || config.notation === MusicalNotation.SQUARE) && <div className={swapAntiphonVerse ? '' : 'mt-4'}>
        <ExsurgeWrapper
          removeSolesmesMarkings={config.removeSolesmesMarkings ?? false}
          language={language}
          textOption={textOption}
          isAlleluia={!swapAntiphonVerse}
          antiphonText={antiphonText}
          antiphon={antiphon}
          verseText={verseText}
          verse={verse}
          verseStyle={config.verseStyle}
          verseCount={verseCount}
          verseColumns={config.verseColumns}
          useVersicleSymbolForVerses={true}
          capitalizeInitial={true}
          verseSplitType={verseSplitType}
          spacingHorizontal={config.spacingHorizontal}
          spacingVertical={config.spacingVertical}
          width={width}
          onLoaded={onLoaded}
        />
      </div>}

      {antiphon && config.notation === MusicalNotation.MODERN && <VerovioAntiphonVerseWrapper
        displayMode={config.verseStyle}
        cardMei={cardMei}
        cardSvg={cardSvg}
        antiphonScriptureReference={antiphonText?.originalSource}
        antiphonMode={antiphon.melody.mode}
        useVersicleSymbolForVerses={true}
        verseText={verseTranslation?.content}
        verseLanguage={verseTranslation?.language}
        verseMusic={verse?.melody.voice.data}
        verseScriptureReference={verseText?.originalSource}
        keyOffset={config.keyOffset}
        verseCount={verseCount}
        verseColumns={config.verseColumns}
        verseSplitType={verseSplitType}
        isFullScreen={isFullScreen}
        addGrandStaff={addGrandStaff}
        displayHarmonizations={config.displayHarmonizations}
        onRenderAntiphonMidi={onRenderAntiphonMidi}
        spacingLinear={config.spacingLinear}
        spacingNonLinear={config.spacingNonLinear}
        spacingHorizontal={config.spacingHorizontal}
        spacingVertical={config.spacingVertical}
      />}

      {antiphon && config.notation === MusicalNotation.TEXT && <OfficialAlleluiaTranslation
        verse={verseText}
        textOption={textOption}
        displayMode={displayMode}
        antiphon={antiphonText}
        antiphonTranslation={antiphon?.translation}
        isLentenGospelAcclamation={isLentenGospelAcclamation}
      />}
    </>
  );
}
