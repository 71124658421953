import { ICardContext } from "context/CardContext";
import { useCallback } from "react";
import { UpdateCardConfigCallback } from "./UpdateCardConfigCallback";
import { useSharedCardContextProps } from "./useSharedCardContextProps";
import { useTextOptionsForCard } from "./useTextOptionsForCard";
import { TextOptionWithMeta } from "queries/text-option";
import { Note } from 'queries/note';

export type UseCardContextProps = Pick<
  ICardContext,
  | 'card'
  | 'config'
  | 'addGrandStaff'
  | 'showRubrics'
  | 'openExportModal'
  | 'isLastCard'
  | 'textSize'
> & {
  createConfigUpdater: (id: number) => UpdateCardConfigCallback;
  onLoaded?: (id: number) => void;
  skipTextOptions?: boolean;
  textOptionsOverride?: TextOptionWithMeta[];
  note?: Note | null;
}

export const useCardContext = ({
  createConfigUpdater,
  onLoaded: baseOnLoaded,
  card,
  skipTextOptions,
  textOptionsOverride,
  note,
  textSize = 1,
  ...otherProps
}: UseCardContextProps): ICardContext => {
  const sharedProps = useSharedCardContextProps(card, createConfigUpdater);

  const id = card?.id;
  const onLoaded = useCallback(() => {
    baseOnLoaded?.(id);
  }, [id, baseOnLoaded]);

  const { textOptions, optionType } = useTextOptionsForCard(card, !!textOptionsOverride || skipTextOptions);

  return {
    card,
    textOptions: textOptionsOverride || textOptions,
    optionType,
    note: note ?? undefined,
    ...sharedProps,
    onLoaded,
    textSize,
    ...otherProps
  };
}
