import { useCallback, useContext } from 'react';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { useCreateUserInteraction } from 'hooks/interaction/useCreateUserInteraction';
import { InteractionIdentifier } from 'utils/ordo/InteractionIdentifier';
import { CreateCard } from 'queries/card';
import { useSavingChangesMutation } from 'hooks/useSavingChangesMutation';
import { OrdoContext } from 'context/OrdoContext';

type CreateCardHandlerArgs = Omit<CreateCard['Variables'],'ordoId'|'afterTextCategory'> & {
  afterTextCategory?: RegExp;
};
type CreateCardHandler = (args: CreateCardHandlerArgs) => void;

export const useCreateCardHandler = (ordoId: number): CreateCardHandler => {
  const { setCreatedCardIds } = useContext(OrdoContext);
  const [createCard] = useSavingChangesMutation(CreateCard);
  const createUI = useCreateUserInteraction();
  const createCardHandler: CreateCardHandler = useCallback(
    ({ name, type, textCategoryName, afterTextCategory, atEnd }) => {
      const variables: CreateCard['Variables'] = {
        ordoId,
        name,
        type,
        textCategoryName,
        // take a regex and extract just the regex pattern (the part between the slashes)
        afterTextCategory: afterTextCategory?.toString().match(/^\/(.+)\/([gmixsu]*)$/)?.[1],
        atEnd,
      };
      const interactionId =
        type === CardTypes.HYMN
          ? InteractionIdentifier.ADDED_A_HYMN
          : type === CardTypes.NOTES
          ? InteractionIdentifier.ADDED_A_CUSTOM_TEXT
          : null;
      if (interactionId) createUI(interactionId, { ordoId, cardName: name });
      createCard({ variables, onCompleted: (result) => {
        setCreatedCardIds(ids => [result.card.id, ...ids]);
      } });
    },
    [ordoId, createUI, createCard, setCreatedCardIds],
  );

  return createCardHandler;
};
