import React, { Suspense } from 'react';
import type { ExsurgeProps, SyllabifiedExsurgeProps } from 'react-exsurge';
import { verovioTextUndertie } from './FormattedVerseSegment';
import { Spinner } from './Spinner';

export const loadExsurge = async () => {
  const reactExsurge = await import('react-exsurge');
  // override english regexLetter to treat the veroviotext undertie character as a letter:
  reactExsurge.language.english.regexLetter = /[a-z\u00c0-\u02af\u0300-\u036f\u1e00-\u1eff‿]+/i;
  return reactExsurge;
}

const spinner = <Spinner size='30px' />;
const Exsurge = React.lazy(loadExsurge);
export const gabcVerovioTextUndertie = `<font family="VerovioText">${verovioTextUndertie}</font>`;
const replaceGabcUndertie = (gabc: string) =>
  gabc.replace(/‿/g, gabcVerovioTextUndertie)
const LazyExsurge: React.FC<ExsurgeProps> = ({ gabc, ...props }) => (
  <Suspense fallback={spinner}>
    <Exsurge {...props} gabc={replaceGabcUndertie(gabc)} />
  </Suspense>
);
const SyllabifiedExsurge = React.lazy(async () => ({
  default: (await loadExsurge()).SyllabifiedExsurge,
}));
export const LazySyllabifiedExsurge: React.FC<SyllabifiedExsurgeProps> = (
  props,
) => (
  <Suspense fallback={spinner}>
    <SyllabifiedExsurge {...props} />
  </Suspense>
);

export default LazyExsurge;
