import { css, cx } from '@emotion/css';
import { OrdoSummaryExport } from 'components/ordo-summary/OrdoSummaryExport';
import { CombinedOrdoCardAttributions } from 'components/ordo/cards/utils/CombinedOrdoCardAttributions';
import { OrdoContext } from 'context/OrdoContext';
import { useDownloadResources } from 'hooks/export/useDownloadResources';
import { getConfigOnCard } from 'hooks/ordo/cards/useCardConfig';
import { OrdoCard, OrdoCardShare } from 'queries/card-fragment';
import { Ordo, OrdoShare } from 'queries/ordo';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ExportFile, ExportRenderState } from 'utils/export';
import { PdfBlock, PdfCardBlocks } from 'utils/export/PdfBlockTypes';
import { PageBreakClass, convertToPdfBlocks } from 'utils/export/convertToPdfBlocks';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { Dictionary } from 'utils/typescript/Dictionary';

export const ordoTitleKey = 'ordo-title';
export const combinedAttributionsKey = 'combined-attributions';
export const outlineKey = 'ordo-outline';
export interface ExportRendererProps {
  onRenderComplete: (cardBlocks: Dictionary<PdfCardBlocks>) => void;
  ordo: Ordo | OrdoShare,
  cards: OrdoCard[] | OrdoCardShare[],
  accountId: number,
  setAttachments: (attachments: ExportFile[]) => void;
  state?: ExportRenderState;
  displayName?: string;
  displaySubTitle?: string;
  displayThirdLine?: string;
  cardId?: number;
  showRubrics: boolean;
  cardAncestor?: HTMLElement;
  fileNames: Dictionary<string>;
}

export const ExportRenderer: React.FC<ExportRendererProps> = ({
  onRenderComplete,
  ordo,
  cards,
  accountId,
  setAttachments,
  state,
  displayName,
  displaySubTitle,
  displayThirdLine,
  cardId,
  showRubrics,
  cardAncestor = document,
  fileNames,
}) => {
  const { selectedRole } = useContext(OrdoContext);
  // get the external items
  const cardIds = useMemo(() => cards.map(card => card.id), [cards]);
  const [resourceBuffers, loadingResourceBuffers] = useDownloadResources(cardIds);
  // NOTE: I did not change this one over to access the role-based config, since it doesn't use one of the overridden fields
  // and it would be a lot of extra complication to change it over.
  const exportableCards = useMemo(
    () =>
      cards.filter(
        (card) =>
          (showRubrics || CardTypes.RUBRIC !== card.type) &&
          (!card.config?.primaryResourceId ||
            resourceBuffers?.get(card.id)?.fileExt?.toLowerCase() !== 'pdf'),
      ),
    [cards, resourceBuffers, showRubrics],
  );

  const [cardBlocks, setCardBlocks] = useState<Dictionary<PdfBlock[]>>({});

  const updateCardBlocks = useCallback(
    (key: string, blocks: PdfBlock[]) => {
      setCardBlocks((cardElements) => ({
        ...cardElements,
        [key]: blocks,
      }));
    },
    [setCardBlocks],
  );
  const [combinedAttributionsDiv, setCombinedAttributionsDiv] = useState<HTMLDivElement>();
  const updateCombinedCardAttributionsBlocks = useCallback(
    (node: HTMLDivElement) => {
      setCombinedAttributionsDiv(node);
      if (node) updateCardBlocks(combinedAttributionsKey, convertToPdfBlocks(node));
    },
    [updateCardBlocks],
  );
  const [ordoTitleDiv, setOrdoTitleDiv] = useState<HTMLDivElement>();
  const updateOrdoTitleBlocks = useCallback(
    (node: HTMLDivElement) => {
      setOrdoTitleDiv(node);
      if (node) updateCardBlocks(ordoTitleKey, convertToPdfBlocks(node));
    },
    [updateCardBlocks],
    );
  const [ordoOutlineDiv, setOrdoOutlineDiv] = useState<HTMLDivElement>();
  const updateOrdoOutlineBlocks = useCallback(
    (node: HTMLDivElement) => {
      setOrdoOutlineDiv(node);
      if (node) updateCardBlocks(outlineKey, cardId ? [] : convertToPdfBlocks(node));
    },
    [updateCardBlocks, cardId],
  );

  const doUseExportState = !!state;

  const getBlockDictionaryForCardElements = useCallback((cards: HTMLDivElement[]) => {
    const exportableCardsById = new Map(exportableCards.map(card => [`${card.id}`, card]));
    return Object.fromEntries(
      cards
        .filter(({ dataset: { cardId } }) => cardId && exportableCardsById.has(cardId))
        .map((cardDiv, cardIdx, cards): [string, PdfBlock[]] => {
          let nextCardId = cards[cardIdx + 1]?.dataset.cardId;
          let nextCard = nextCardId ? exportableCardsById.get(nextCardId) : undefined;
          if (nextCard?.type === 'SECTION_HEADER') {
            // section headers don't have configs, so we use the config of the following card:
            nextCardId = cards[cardIdx + 2]?.dataset.cardId;
            nextCard = nextCardId ? exportableCardsById.get(nextCardId) : undefined;
          }
          const nextConfig = nextCard?.config;
          const { cardId } = cardDiv.dataset;
          const exportNodes = Array.from(
            cardDiv.querySelectorAll('._EXPORT'),
          )
            .filter((node) => node.querySelectorAll('._EXPORT').length === 0)
          const blocks = exportNodes.flatMap(node => convertToPdfBlocks(node));
          // card-top is used for share page cards, and card-header for regular cards
          const cardHasHeader = ['card-header', 'card-top'].some((className) =>
            exportNodes[0].classList.contains(className),
          );
          if (cardHasHeader) {
            blocks[0].isCardTitle = true;
          }
          if (blocks[0].pageBreakBefore === false && !blocks[0].margins?.top) {
            // we do this for some rubrics cards that are do not allow a page break before
            // since in this case they need a top margin
            blocks[0].margins = { ...blocks[0].margins, top: 16 };
          }
          // because the card div itself contains these page break classes, we need to handle them here
          if (cardDiv.classList.contains(PageBreakClass.PageBreakAfter)) {
            // we have to also check whether this is overridden in the next card's config:
            const pageBreakBeforeNext = nextConfig?.pageBreakBefore;
            blocks[blocks.length - 1].pageBreakAfter = pageBreakBeforeNext || undefined;
          } else if (cardDiv.classList.contains(PageBreakClass.NoPageBreakAfter)) {
            blocks[blocks.length - 1].pageBreakAfter = false;
          }
          // we are guaranteed to have a cardId because of the filter above
          return [cardId!, blocks];
        }),
      )
  }, [exportableCards]);

  // For layout, we don't use ExportRenderState, and we observe the DOM to watch for changes
  // and keep our pdf blocks up to date:
  useEffect(() => {
    const cardIdSelector = cardId ? '[data-card-id="' + cardId + '"]' : '';
    const selector = `.card-ordo-card${cardIdSelector}, .share-card${cardIdSelector}, .layout-card${cardIdSelector}`;
    const cards = Array.from(
      cardAncestor.querySelectorAll<HTMLDivElement>(selector) || [],
    );
    setCardBlocks(blocks => ({ ...blocks, ...getBlockDictionaryForCardElements(cards) }));
    
    if (!doUseExportState) {
      const observers = cards.map(card => {
        const observer = new MutationObserver(() => {
          setCardBlocks(blocks => ({ ...blocks, ...getBlockDictionaryForCardElements([card])}));
        });
        observer.observe(card, { subtree: true, childList: true, characterData: true, attributes: true });
        return observer;
      });
      if (combinedAttributionsDiv) {
        const attributionsObserver = new MutationObserver(() => updateCombinedCardAttributionsBlocks(combinedAttributionsDiv));
        attributionsObserver.observe(combinedAttributionsDiv, { subtree: true, childList: true, characterData: true, attributes: true })
        observers.push(attributionsObserver);
      }
      if (ordoTitleDiv) {
        const titleObserver = new MutationObserver(() => updateOrdoTitleBlocks(ordoTitleDiv));
        titleObserver.observe(ordoTitleDiv, { subtree: true, childList: true, characterData: true, attributes: true })
        observers.push(titleObserver);
      }
      if (ordoOutlineDiv) {
        const outlineObserver = new MutationObserver(() => updateOrdoOutlineBlocks(ordoOutlineDiv));
        outlineObserver.observe(ordoOutlineDiv, { subtree: true, childList: true, characterData: true, attributes: true })
        observers.push(outlineObserver);
      }
      return () => {
        observers.forEach(o => o.disconnect());
      }
    }
  }, [cardAncestor, cardId, setCardBlocks, doUseExportState, getBlockDictionaryForCardElements, ordoTitleDiv, updateOrdoTitleBlocks, ordoOutlineDiv, updateOrdoOutlineBlocks, combinedAttributionsDiv, updateCombinedCardAttributionsBlocks]);

  useEffect(() => {
    if (!loadingResourceBuffers && resourceBuffers) {
      const attachments = Array.from(resourceBuffers.entries())
        .map(([cardId, downloadResource]) => {
          const cardName = fileNames[cardId];
          const {fileExt} = downloadResource;
          const name = fileExt ? `${cardName}.${fileExt}` : cardName;

          return {
            name,
            fileExt: fileExt?.toLowerCase(),
            content: new Blob([downloadResource.buffer]),
            key: `${cardId}`,
          };
        });

      setAttachments(attachments);
    }
  }, [loadingResourceBuffers, setAttachments, resourceBuffers, fileNames]);

  useEffect(() => {
    if (loadingResourceBuffers) {
      return;
    }

    const allCardsLoaded = exportableCards.every((card) => cardBlocks?.[card.id]);
    const allElseLoaded = (!!cardId || (cardBlocks[outlineKey] && cardBlocks[ordoTitleKey])) && cardBlocks[combinedAttributionsKey];
    const allLoaded = allCardsLoaded && allElseLoaded;
    if (!state || (state === ExportRenderState.IDLE && allLoaded)) {
      const cardMap = new Map(exportableCards.map(card => [card.id, card]));
      const cardBlocksAndConfig = Object.fromEntries(
        Object.entries(cardBlocks).map(([id, blocks]) => {
          // add a named destination for the card, based on its ID
          if (blocks[0]) {
            blocks[0].options = Object.assign(blocks[0].options || {}, { destination: `card-${id}` });
          }
          return [
            id,
            { blocks, cardConfig: getConfigOnCard(cardMap.get(Number(id)), selectedRole) },
          ];
        }),
      );
      onRenderComplete(cardBlocksAndConfig);
    }
  }, [cardBlocks, loadingResourceBuffers, onRenderComplete, exportableCards, state, selectedRole, cardId]);

  const { superTitle, hideDate, hideTitle, accompanimentExport } = ordo.config;
  return <div className={cx('ordo-cards-preview export-preview', style)}>
    <div
      className={PageBreakClass.NoPageBreakAfter}
      style={{
        width: 590,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'black',
        fontFamily: '"EB Garamond Full", "EB Garamond", serif',
        textAlign: 'center',
    }} ref={updateOrdoTitleBlocks}>
      {superTitle && <h1 style={{
        fontSize: 30,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
      }}>{superTitle}</h1>}
      {!hideTitle && <h1 style={{
        fontSize: 26,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
      }}>{displayName}</h1>}
      {!hideDate && <p style={{
        fontSize: accompanimentExport ? 20 : 14,
        fontWeight: 400,
        fontStyle: 'italic',
        margin: 0,
      }}>{displaySubTitle}</p>}
      {displayThirdLine && <p style={{
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: 2
      }}>{displayThirdLine}</p>}
    </div>
    <OrdoSummaryExport
      cards={cardId ? [] : cards}
      ref={updateOrdoOutlineBlocks}
    />
    <CombinedOrdoCardAttributions
      cards={cards}
      accountId={accountId}
      ref={updateCombinedCardAttributionsBlocks}
    />
  </div>;
};

const style = css({
  height: 0,
  width: 0,
  overflow: 'hidden',
});
