// TODO: move to api-types

// This needs to match the equivalent API type
export enum MusicalNotation {
  SQUARE = 'Square',
  MODERN = 'Modern',
  TEXT   = 'Text',
}

// This needs to match the equivalent API type
export enum VerseStyle {
  POINT = 'Point',
  NOTATE = 'Notate',
  TEXT = 'Text',
}
