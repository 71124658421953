import { css, cx } from '@emotion/css';
import { CanonicalSource } from 'components/shared/CanonicalSource';
import { PointedVerse } from 'components/shared/PointedVerse';
import { RenderMidiCallback, Verovio } from 'components/verovio/Verovio';
import { VerovioSvgs } from 'components/verovio/VerovioSvgs';
import { OrdoContext } from 'context/OrdoContext';
import { Language } from 'gabc-utils';
import { CardMei, CardSvg, MeiWithTitles, SvgWithTitles, isMeiWithTitles } from 'queries/card-fragment';
import { Source } from 'queries/source';
import React, { Fragment, useContext, useMemo } from 'react';
import { VerovioOptions } from 'react-verovio';
import { MusicalNotation, VerseStyle } from 'utils/api-types/ordo/card/MusicalProperties';
import {
  VerseSplitType,
  splitVersesByType,
} from 'utils/markup/splitVerses';
import { colorDictionary } from 'utils/styles';
import {
  DEFAULT_POST_PROCESS_ANTIPHON,
  useDefaultVerovioOptionsAntiphon,
} from 'utils/verovio';
import { getVerovioTranspose } from 'utils/verovio/getVerovioTranspose';
import { VerovioPostProcessConfig } from 'utils/verovio/postProcessVerovioSvg';
import { DefaultVerovioOptionsArgument } from 'utils/verovio/useDefaultVerovioOptionsAntiphon';
import { MusicalErrorAlert } from './MusicalErrorAlert';
import { PlainTextVerse } from './PlainTextVerse';
import { ScriptureReference } from './ScriptureReference';
import { Spacing, getVerovioOptionsForHorizontal } from './editor/settings/SpacingToggler';

interface VerovioAntiphonVerseWrapperProps {
  displayMode?: VerseStyle | null;
  cardMei?: CardMei | null;
  cardSvg?: CardSvg | null;
  antiphonScriptureReference?: string;
  antiphonSources?: Source[];
  antiphonMode?: string;
  additionalAntiphonText?: string | null;
  isEasterVigilTripleAntiphon?: boolean;
  useVersicleSymbolForVerses?: boolean;
  verseText?: string;
  verseLanguage?: Language;
  verseMusic?: string;
  verseScriptureReference?: string;
  verseCount?: number;
  verseColumns?: number|null;
  verseSplitType?: VerseSplitType;
  verseClassName?: string;
  keyOffset?: number | null;
  gloryBeText?: string;
  isFullScreen?: boolean;
  displayHarmonizations?: boolean | null;
  showCanonicalSource?: boolean;
  addGrandStaff: boolean;
  onRenderAntiphonMidi?: RenderMidiCallback;
  referenceNumber?: string;
  postProcessConfig?: VerovioPostProcessConfig;
  spacingLinear?: number | null;
  spacingNonLinear?: number | null;
  spacingHorizontal?: Spacing | null;
  spacingVertical?: number | null;
}

export const VerovioAntiphonVerseWrapper: React.FC<VerovioAntiphonVerseWrapperProps> = ({
  displayMode = VerseStyle.POINT,
  cardMei,
  cardSvg,
  antiphonScriptureReference,
  antiphonSources = [],
  antiphonMode = null,
  additionalAntiphonText,
  isEasterVigilTripleAntiphon = false,
  useVersicleSymbolForVerses = false,
  verseText,
  verseLanguage = 'en',
  verseMusic,
  verseScriptureReference,
  verseCount = 0,
  verseColumns = 1,
  verseSplitType,
  verseClassName = useVersicleSymbolForVerses ? 'versicle-list' : undefined,
  keyOffset = 0,
  gloryBeText = '',
  isFullScreen = false,
  displayHarmonizations = false,
  showCanonicalSource = false,
  addGrandStaff = false,
  onRenderAntiphonMidi,
  referenceNumber,
  postProcessConfig = DEFAULT_POST_PROCESS_ANTIPHON,
  spacingLinear,
  spacingNonLinear,
  spacingHorizontal,
  spacingVertical,
}) => {
  const meiData = cardMei ?? cardSvg;
  const meiKey = meiData?.keyIndex ?? 0;
  let defaultTranspose = meiData?.defaultTranspose ?? 0;
  const verseError = meiData?.verseError ?? false;

  const antiphonMei = cardMei?.antiphon ?? undefined;
  let verseMei = cardMei?.verses ?? undefined;
  const antiphonSvg = cardSvg?.antiphon ?? undefined;
  let verseSvg = cardSvg?.verses ?? undefined;

  const { selectedRole, ordo: { config: ordoConfig = {} } } = useContext(OrdoContext);
  addGrandStaff = selectedRole?.defaultRoleConfig?.addGrandStaff ?? addGrandStaff;
  displayMode = displayMode || VerseStyle.POINT;
  const additionalAntiphonVerses = useMemo(
    () => (additionalAntiphonText ? [additionalAntiphonText] : []),
    [additionalAntiphonText],
  );
  const verses = useMemo(
    () => splitVersesByType(verseText, verseSplitType).slice(0, verseCount),
    [verseSplitType, verseText, verseCount],
  );

  const gbVerses = useMemo(() => [gloryBeText], [gloryBeText]);

  if (verseCount && verseCount > (verses?.length ?? 0)) {
    verseCount = verses?.length ?? 0;
  }
  if (!gloryBeText && verseCount === 1) {
    // force verse columns to 1 when there is only one verse
    verseColumns = 1;
  }
  const effectiveVerseClassName = cx({ 'two-digit-verses': verseCount >= 10 }, verseClassName);

  if (isEasterVigilTripleAntiphon) defaultTranspose += 2;

  const transposeOffset = (defaultTranspose || 0) + (keyOffset || 0);
  const verovioTranspose: VerovioOptions = useMemo(
    () => ({
      transpose: getVerovioTranspose(meiKey, transposeOffset),
    }),
    [meiKey, transposeOffset]
  );
  const extraVerovioOptions: VerovioOptions = useMemo(
    () => ({
      ...verovioTranspose,
      ...(spacingHorizontal
        ? getVerovioOptionsForHorizontal(0.29, spacingHorizontal)
        : {
            ...(spacingLinear ? { spacingLinear } : {}),
            ...(spacingNonLinear ? { spacingNonLinear } : {}),
          }),
    }),
    [spacingHorizontal, spacingLinear, spacingNonLinear, verovioTranspose]
  );

  const {
    antiphonHasChordSymbols,
    antiphonHasHarmonization,
    verseHasHarmonization,
    hymnHasSuppressedStems,
  } = meiData ?? {};

  const hasHarmonization = antiphonHasHarmonization || verseHasHarmonization;
  const isChant = hymnHasSuppressedStems ?? undefined;
  const verovioOptionsArguments: DefaultVerovioOptionsArgument = {
    extraConfig: extraVerovioOptions,
    isFullScreen,
    showHarmonizations: verseHasHarmonization,
    cardHarmonizations: hasHarmonization,
    addGrandStaff,
    spacingVertical,
  };
  const antiphonArguments = { ...verovioOptionsArguments, showHarmonizations: antiphonHasHarmonization, isChant }
  const verovioConfig = useDefaultVerovioOptionsAntiphon(antiphonArguments);
  const verovioVerseConfig = useDefaultVerovioOptionsAntiphon(verovioOptionsArguments);
  const psalmToneBaseConfig = useDefaultVerovioOptionsAntiphon({ ...verovioOptionsArguments, extraConfig: verovioTranspose });
  const verovioPsalmToneConfig = useMemo(
    () => ({
      ...psalmToneBaseConfig,
      adjustPageWidth: true,
      ...(verseHasHarmonization
        ? {
            spacingStaff: 12,
            spacingSystem: 0,
          }
        : {}),
    }),
    [psalmToneBaseConfig, verseHasHarmonization],
  );

  const verovioAntiphonConfigs = useMemo(() => {
    if (isEasterVigilTripleAntiphon) {
      return [-4, -2, 0].map(offset => ({ ...verovioConfig, transpose: getVerovioTranspose(meiKey, transposeOffset + offset) }));
    } else {
      return [verovioConfig];
    }
  }, [verovioConfig, isEasterVigilTripleAntiphon, meiKey, transposeOffset])

  let verseMeiOrSvg = (verseMei ?? verseSvg)?.slice();
  const isMeinradTone = displayMode === VerseStyle.POINT && (verseMeiOrSvg)?.length === 5;
  const isSquare = cardSvg?.musicalNotation === MusicalNotation.SQUARE;

  let [aboveAdditionalAntiphonVerses, aboveVerses, aboveGloryBe, firstVerseMeiOrSvg] = useMemo(() => {
    if (!isMeinradTone) return [];
    const verseLengths = [...additionalAntiphonVerses, ...verses].map((verse) => verse.split(/\n/).length);
    if (gloryBeText) {
      verseLengths.push(gloryBeText.split(/\n/).length);
    }
    const versesMeiOrSvg = verseLengths.map((verseLen, i) =>
      verseLen === verseLengths[i - 1] ? null : verseMeiOrSvg?.[verseLen - 2],
    );
    const aboveVerses = versesMeiOrSvg.map(
      (verseMeiOrSvg, i) =>
        (verseMeiOrSvg && (
          <div
            className={cx({
              'mt-2': i > 0 && ((verseHasHarmonization && !antiphonHasHarmonization) || isSquare),
              'my-2': i > 0 && !verseHasHarmonization && !isSquare,
              'mb-2': i === 0 && !verseHasHarmonization && !isSquare,
            }, 'text-center no-page-break-after verse-tone')}
          >
            {typeof verseMeiOrSvg === 'string' ? <Verovio
              mei={verseMeiOrSvg}
              className={`my-1 ${isFullScreen ? 'tone-container' : ''}`}
              postProcessConfig={postProcessConfig}
              config={verovioPsalmToneConfig}
            /> : <VerovioSvgs
              svgs={verseMeiOrSvg}
              className={`my-1 ${isFullScreen ? 'tone-container' : ''}`}
            />}
          </div>
        )) ||
        null,
    );
    const aboveAdditionalAntiphonVerses = aboveVerses.splice(0, additionalAntiphonVerses.length);
    let aboveGloryBe: typeof aboveVerses = [];
    if (gloryBeText && verseCount > 0) {
      aboveGloryBe.push(aboveVerses.pop() ?? null);
    }
    return [aboveAdditionalAntiphonVerses, aboveVerses, aboveGloryBe, versesMeiOrSvg[0]];
  }, [
    antiphonHasHarmonization,
    gloryBeText,
    isFullScreen,
    isMeinradTone,
    verovioPsalmToneConfig,
    verseHasHarmonization,
    verseMeiOrSvg,
    additionalAntiphonVerses,
    verses,
    verseCount,
    isSquare,
    postProcessConfig,
  ]);

  if (
    aboveVerses &&
    firstVerseMeiOrSvg &&
    aboveVerses
      .slice(1)
      .concat(aboveGloryBe ?? [])
      .every((aboveVerse) => aboveVerse === null)
  ) {
    // if there is only one aboveVerse, we display it as regular, instead of above the first verse:
    if (typeof firstVerseMeiOrSvg === 'string') {
      verseMei = [firstVerseMeiOrSvg];
    } else {
      verseSvg = [firstVerseMeiOrSvg];
    }
    verseMeiOrSvg = verseMei ?? verseSvg;
    aboveVerses = undefined;
  }

  const antiphonMeiOrSvg = antiphonMei ?? antiphonSvg;
  if (!antiphonMeiOrSvg && !verseCount) {
    return null;
  }

  const hasVerses = !addGrandStaff && (verseCount > 0 || gloryBeText || additionalAntiphonText);
  if (verseError) displayMode = null;
  const hasPointedVerses = hasVerses && displayMode === VerseStyle.POINT && verseMeiOrSvg;

  const verovioForMeiOrSvg = (meiOrSvg: MeiWithTitles | SvgWithTitles, config: VerovioOptions = verovioConfig, onRenderMidi?: RenderMidiCallback) => (
    isMeiWithTitles(meiOrSvg)
      ? <Verovio
          mei={meiOrSvg.mei}
          midiPassthru={meiOrSvg.midi ?? undefined}
          config={config}
          onRenderMidi={onRenderMidi}
          className={'_EXPORT'}
          postProcessConfig={postProcessConfig}
        />
      : <VerovioSvgs svgs={meiOrSvg.svgs} midi={meiOrSvg.midi} className={'_EXPORT'} onRenderMidi={onRenderMidi} />
  );
  const getVerovioAntiphon = (verovioConfig: VerovioOptions, index: number) => {
    if (!antiphonMeiOrSvg) return null;
    // for the triple easter vigil alleluia, we have already processed it three times
    // if we are dealing with SVGs:
    const meiOrSvgs = isEasterVigilTripleAntiphon && antiphonMeiOrSvg.length === 3
      ? antiphonMeiOrSvg[index]
      : antiphonMeiOrSvg;
    if (meiOrSvgs instanceof Array) {
      return meiOrSvgs.map((meiOrSvg, i) => (
          <React.Fragment key={i}>
            {meiOrSvg.subtitle && <h2>{meiOrSvg.subtitle}</h2>}
            {verovioForMeiOrSvg(meiOrSvg, verovioConfig, i === 0 ? onRenderAntiphonMidi : undefined)}
          </React.Fragment>
      ));
    } else {
      return verovioForMeiOrSvg(meiOrSvgs, undefined, onRenderAntiphonMidi);
    }
  };

  const antiphonRepetitions = antiphonMeiOrSvg
    ? verovioAntiphonConfigs.map(getVerovioAntiphon)
    : [];
  
  const antiphonHeader = isEasterVigilTripleAntiphon
    ? ['First time:', 'Second time:', 'Third time:']
    : [];

  const additionalAntiphonVersesMei = verseMeiOrSvg?.splice(0, additionalAntiphonVerses.length);

  const renderNotatedVerse = (startingIndex = 1) => (verse: string | string[], index: number) => {
    let firstStanzaNumber = useVersicleSymbolForVerses ? '℣' : `${index + startingIndex}`;
    if (gloryBeText && index === (verseMei?.length ?? 0) - startingIndex) {
      firstStanzaNumber = 'D';
    }
    return (
      <div className='_EXPORT verovio-verse' key={index}>
        {typeof verse === 'string'
          ? <Verovio
            mei={verse}
            postProcessConfig={{
              ...postProcessConfig,
              firstStanzaNumber
            }}
            config={verovioVerseConfig}
            className='_EXPORT'
          />
          : <VerovioSvgs svgs={verse} className='_EXPORT' />}
      </div>
    );
  };

  return <div
    className={cx(
      'verovio-antiphon-verse-wrapper',
      style, {
        'has-grand-staff': addGrandStaff || antiphonHasHarmonization,
        'has-chord-symbols': antiphonHasChordSymbols,
        'accompaniment-export': ordoConfig.accompanimentExport,
      }
    )}
  >
    {antiphonRepetitions.map((antiphon, antiphonIndex) => <Fragment key={antiphonIndex}>
      {antiphonHeader[antiphonIndex] && <div className='rubric'>{antiphonHeader[antiphonIndex]}</div>}
      <div
        className='_EXPORT verovio-right-left no-page-break-after'
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {antiphonMode && (cardSvg?.musicalNotation !== MusicalNotation.SQUARE) && <div className='text-start' style={{ fontSize: '80%'}}>
          <i>Mode {antiphonMode}</i>
        </div>}
        {(antiphonScriptureReference || !!antiphonSources?.length || referenceNumber) && <div className='text-end flex-grow-1' style={{ fontSize: '0.75em', color: colorDictionary['Secondary'],}}>
          <i>
            {antiphonScriptureReference}
            {antiphonScriptureReference && antiphonSources.length > 0 && <span> · </span>}
            <CanonicalSource sourceNames={antiphonSources.map(s => s.nameShort)} />
            {(antiphonScriptureReference || antiphonSources.length > 0) && referenceNumber && <span> · </span>}
            {referenceNumber}
          </i>
        </div>}
      </div>

      <div className={cx('MIDI-music', { 'with-harmonization': antiphonHasHarmonization, 'MIDI-antiphon': antiphonIndex === 0 })}>{antiphon}</div>
    </Fragment>)}

    {hasVerses && <div className={cx('antiphon-verses MIDI-verses', { 'with-harmonization': verseHasHarmonization })}>
      <div>
        {displayMode === VerseStyle.POINT && verseMeiOrSvg && !aboveVerses?.length && <div
          className={'text-center no-page-break-after _EXPORT psalm-tone'}
        >
          {verseMei
            ? <Verovio
              mei={verseMei[0]}
              className={cx({ 'tone-container': isFullScreen })}
              postProcessConfig={postProcessConfig}
              config={verovioPsalmToneConfig}
              spinnerClassName={hasPointedVerses && verseHasHarmonization ? 'mb-4' : ''}
            />
            : <VerovioSvgs svgs={verseSvg![0]} className={cx({ 'tone-container': isFullScreen })} />}
        </div>}

        {additionalAntiphonText && <>
          <div className='_EXPORT' style={{ fontSize: '80%', marginBottom: 8, fontStyle: 'italic' }}>Additional Antiphon Text</div>

          {displayMode === VerseStyle.NOTATE && additionalAntiphonVersesMei?.map(renderNotatedVerse())}

          {displayMode === VerseStyle.TEXT && <PlainTextVerse verses={additionalAntiphonVerses} verseClassName={'liturgical-text-verse'} columns={verseColumns} />}

          {displayMode === VerseStyle.POINT && verseMusic && <div className='_EXPORT'>
            <PointedVerse
              aboveVerses={aboveAdditionalAntiphonVerses}
              verses={additionalAntiphonVerses}
              language={verseLanguage}
              psalmTone={verseMusic}
              verseClassName={effectiveVerseClassName}
              verseColumns={verseColumns}
            />
          </div>}
          <div className='_EXPORT' style={{marginBottom: 24}} />
        </>}

        {!!verses.length && <ScriptureReference text={verseScriptureReference}/>}
      </div>

      {displayMode === null && verseError && <MusicalErrorAlert />}
      
      {displayMode === VerseStyle.NOTATE && verseMeiOrSvg?.map(renderNotatedVerse(additionalAntiphonVerses.length + 1))}

      {displayMode === VerseStyle.POINT && <div className={cx('card-width _EXPORT', { 'columns-2': verseColumns === 2 })}>
        {verseText && verseMusic && <PointedVerse
          aboveVerses={aboveVerses}
          verses={verses}
          language={verseLanguage}
          psalmTone={verseMusic}
          verseClassName={effectiveVerseClassName}
          verseColumns={verseColumns}
          firstVerseNum={additionalAntiphonVerses.length + 1}
        />}

        {gloryBeText && verseMusic && <PointedVerse
          aboveVerses={aboveGloryBe}
          verses={gbVerses}
          language={verseLanguage}
          psalmTone={verseMusic}
          verseClassName={effectiveVerseClassName}
          verseColumns={verseColumns}
          isGloryBe={true}
          individualSyllables={verses.length === 0}
        />}
      </div>}

      {displayMode === VerseStyle.TEXT && <>
        {verseText && <PlainTextVerse
          verses={verses}
          verseClassName={'liturgical-text-verse'}
          includeGloryBe={!!gloryBeText}
          columns={verseColumns}
        />}
      </>}
    </div>}
  </div>;
}

const style = css`
  .MIDI-music .verovio-container:last-child {
    padding-bottom: 15px;

    > div.Exsurge:last-child {
      padding-bottom: 9px
    }
  }

  .verovio-container + .verovio-container {
    padding-top: calc(4px + var(--card-vertical-spacing, 0px));
  }

  .verovio-verse + .verovio-verse .verovio-container:first-child {
    padding-top: calc(24px + var(--card-vertical-spacing, 0px))
  }
  
  .plain-text-translation + .plain-text-translation > .translation > .liturgical-text,
  .plain-text-translation + .liturgical-text {
    padding-top: calc(15px + var(--card-vertical-spacing, 0px))
  }

  .psalm-tone {
    padding-bottom: calc(8px + var(--card-vertical-spacing, 0px))
  }

  .scripture-reference {
    padding-bottom: calc(8px + var(--card-vertical-spacing, 0px))
  }
  &.accompaniment-export .scripture-reference {
    padding-top: 8px;
    padding-bottom: 16px;
  }

  &.has-chord-symbols {
    .MIDI-music .verovio-container:first-child {
      padding-top: 4px!important;
    }
  }
  &.has-chord-symbols, &.has-grand-staff {
    .psalm-tone {
      padding-bottom: 0;
    }
  }
  &.has-grand-staff {
    .verovio-container + .verovio-container {
      padding-top: calc(12px + var(--card-vertical-spacing, 0px));
    }
  }
`;
