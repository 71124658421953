import { useSavingChangesMutation } from 'hooks/useSavingChangesMutation';
import { RemoveCard } from 'queries/card';
import { OrdoCard } from 'queries/card-fragment';
import { useCallback } from 'react';

export const useRemoveCardHandler = () => {
  const [removeCardMutation] = useSavingChangesMutation(RemoveCard);

  const removeCard = useCallback(
    (card: OrdoCard) => removeCardMutation({ variables: { id: card.id } }),
    [removeCardMutation],
  );

  return removeCard;
}
