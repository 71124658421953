import { CardMei, CardSvg } from "queries/card-fragment";

export enum VerseTypes {
  REFRAIN = 'REFRAIN',
  VERSE = 'VERSE',
};

export interface VerseSpan {
  className: string;
  content: string;
}

export interface VerseLine {
  className: string;
  content: VerseSpan[];
};

export interface ParsedVerse {
  type: VerseTypes,
  lines: VerseLine[]
  label: string,
  sort?: number,
};

const parseVerse = ( verse: string ) => {
  let forceItalic = false;
  const localItalicsRegex = /\s*\\(?:force|no)Italic\s*/;
  const globalItalicsRegex = new RegExp(localItalicsRegex, 'g');

  return verse 
    // replace force-hyphen characters with something else
    .replace(/\\forceHyphen\s+([^\s]+)\s+--\s+/g, '$1%hyphen%')
    // remove note carries
    .replace(/(\s+)--(\s+)/g, '')
    .replace(/(\s+)(_+)((\s+)_+)*([^\S\r\n])*/g, ' ') // removes underscores from stuff like "oh come __ _ all ye faithful" and "oh come let us adore ___ him"
    .replace(/([^\s])(_+)(\s+)/g, '$1')
    .replace(/~/g, ' ')
    // remove rest notes
    .replace(/"([^"]*)"/g, '$1')
    // replace stand-in force-hypen character with hyphen
    .replace(/%hyphen%/g, '-')
    // split each line
    .split(/\n/g)
    .map((line) => {

      // we need to split our line to find any possible
      // \forceItalic or \noItalic directives
      // so if the example string were like this:
      // 'here is text \forceItalic that's italic \noItalic and that's not'
      // ! if the string starts with a command, there will still be
      // ! an empty string as the first element of separateSpans
      const separateSpans = line.split(localItalicsRegex); // ['here is text', 'that\'s italic', 'and that\'s not']
      const commands = (line.match(globalItalicsRegex) || []).map(command => command.trim()); // ['\forceItalic', '\noItalic']

      // loop through each span and decide whether or
      // not to give that span the class name italic
      const spans = separateSpans.map((span, i) => {
        // the command separate our line into
        // spans, so there will always be one
        // fewer command than spans.
        if (i !== 0) {
          const command = commands[i - 1];
          forceItalic = (command === '\\forceItalic');
        }

        return {
          content: span,
          className: forceItalic ? 'fst-italic' : '',
        };
      });

      // we've already accounted for our set
      // stanza directive, the rest can simply be
      // removed
      const setDirectiveRegex = /(\\set\s+(\w+)\s*=\s*"([^"]*|\S+)")/;
      const flag = /\\([^\s]+)(\s|$)/g;

      return {
        className: '',
        content: spans.map(span =>({
          className: span.className,
          content: span.content
            .replace(setDirectiveRegex, '')
            .replace(flag, ''),
        })),
      };
    }, []);
};

export const parseVerses = (mei: CardMei | CardSvg | null | undefined, {
  firstVerseNum = 1,
  removeRefrain = false,
}: {
  firstVerseNum?: number;
  removeRefrain?: boolean;
} = {}): ParsedVerse[] => {
  if (!mei) {
    return [];
  }

  const {
    textVerses: verses,
    refrain
  } = mei;

  // in certain circumstances, it is necessary to
  // manually split stanzas in the middle of a verse
  // see, for example, "Through the Sinful Women Shriven"
  const stanzaSplitRegex = /\\set\s+stanza\s*=\s*"([^"]*|\S+)"([^\\]+)/;
  const globalStanzaSplitRegex = new RegExp(stanzaSplitRegex, 'g');
  const parsedVerses = (verses || []).reduce((arr: ParsedVerse[], verse, i) => {
    const verseSplits = verse.match(globalStanzaSplitRegex);
    if ( verseSplits && verseSplits.length ) {
      // because this string contains manual stanza breaks
      // we will need to first, add the section of the verse
      // that comes before the first stanza break
      const firstStanzaRegex = /^([^\\]+)\\set\s+stanza/;
      const firstStanzaPieces = verse.match(firstStanzaRegex);

      if (firstStanzaPieces && firstStanzaPieces.length) {
        const firstStanza = firstStanzaPieces[1];
        arr.push({
          type: VerseTypes.VERSE,
          lines: parseVerse(firstStanza.trim()),
          label: `${i + 1}.`,
          // important because numericValues
          // below are 1-indexed
          sort: i + 1,
        });
      }

      // next, we need to loop through all of the portions
      // of this string which have been manually labeled
      // as other stanzas
      verseSplits.forEach((split) => {
        const stanzaPieces = split.match(stanzaSplitRegex);

        if (!stanzaPieces) {
          // this case isn't possible because we
          // are matching based off of matches to
          // the global expression, but we'll leave
          // this here to ease TypeScript's nerves
          return;
        }

        const [value, stanza] = stanzaPieces.slice(1);
        const numericValue = parseInt(value, 10) || i;
        arr.push({
          type: VerseTypes.VERSE,
          lines: parseVerse(stanza.trim()),
          label: value,
          sort: numericValue,
        });
      })
    }

    else {
      // if we don't have any manually split stanzas
      // we can keep this process simple
      const sort = i + firstVerseNum;
      arr.push({
        type: VerseTypes.VERSE,
        lines: parseVerse(verse),
        label: `${sort}.`,
        sort,
      });
    }

    return arr;
  }, []);

  // sort the parsed verse and
  // remove the sort property from
  // them
  const sortedVerses = parsedVerses
    .sort((a, b) => {
      if (a.sort !== undefined && b.sort !== undefined) {
        return a.sort - b.sort
      }

      return 0;
    })
    .map(({ sort, ...verse}) => ({
      ...verse,
    }));

  // place a refrain if necessary
  if (refrain && !removeRefrain) {
    const refrainVerse = {
      type: VerseTypes.REFRAIN,
      lines: parseVerse(refrain),
      label: 'Refrain:'
    };

    if (!sortedVerses.length) {
      return [refrainVerse];
    }

    const insertIndex = mei.refrainAtBeginning ? 0 : 1;

    sortedVerses.splice(insertIndex, 0, refrainVerse);
  }

  return sortedVerses;
};
