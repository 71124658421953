import { sum } from 'utils/sum';
import { PdfBlock, PdfColumnInfo } from './PdfBlockTypes';
import {
  MeasuredPdfBlockGroup,
  PDFDocumentWithColumns,
  PdfUnbreakableBlockGroup,
  getPdfWidthAndMargins,
  heightOfFormattedText,
  isBlockBreakableAtPage,
  isPdfTextStyleBlock,
  splitAtBreakableColumns,
} from './PdfFormattedText';
import { getPageDimensions } from './pdfPageTools';

export interface PageNumberInfo {
  fontSize?: number;
  margin?: number;
  pageNumber?: number;
  extraPageCount?: { [cardId: string]: number };
}

/**
 * This function should be called after having advanced pdf.column.index,
 * when pdf.y is set at the bottom of the last drawn column
 * @param pdf pdf document, which must  have column property set
 */
export const resetColumn = (pdf: PDFDocumentWithColumns) => {
  pdf.column!.endY = Math.max(pdf.column!.endY, pdf.y);
  pdf.y = pdf.column!.y;
};

/**
 * Advance to the next column
 * note that pdf.column MUST be set
 * @param pdf pdf document
 */
export const advanceColumn = (pdf: PDFDocumentWithColumns) => {
  if (++pdf.column!.index < pdf.column!.count) {
    resetColumn(pdf);
  }
};

/**
 * end a column section
 * @param pdf pdf document
 */
export const endColumn = (pdf: PDFDocumentWithColumns) => {
  resetColumn(pdf);
  pdf.y = pdf.column!.endY;
  delete pdf.column;
}

/**
 * Add a page to a pdf document that may have columns, and reset the column state for the new page
 * @param pdf pdf documentwith columns
 */
export const addPage = (pdf: PDFDocumentWithColumns, pageNumberInfo?: PageNumberInfo, cardPages?: string[][]) => {
  pdf.addPage();
  delete pdf.column;
  if (cardPages) cardPages.push([]);
  if (pageNumberInfo) {
    let { fontSize = 12, margin = 12, pageNumber = 0 } = pageNumberInfo;
    const numberString = `${pageNumberInfo.pageNumber = ++pageNumber}`;
    const { width, height, margins: { left, top } } = pdf.page;
    pdf.fontSize(fontSize);
    pdf.text(numberString, 0, height - margin, { align: 'center', width, baseline: 'bottom', height: fontSize * 0.8 });
    pdf.x = left;
    pdf.y = top;
  }
};

/**
 * Reset x and y in a pdf document that may have columns, and reset the column state for the new page
 * @param pdf pdf documentwith columns
 */
export const resetPage = (pdf: PDFDocumentWithColumns) => {
  const { margins: { top, left} } = getPageDimensions(pdf);
  pdf.y = top;
  pdf.x = left;
  delete pdf.column;
};

type MeasureBlockHeight = {
  (
    svg: PdfBlock,
    pdf: PDFKit.PDFDocument,
    forcePadTop?: number,
    forcePadBottom?: never,
  ): number;
  (
    svg: PdfBlock,
    pdf: PDFKit.PDFDocument,
    forcePadTop: number | boolean,
    forcePadBottom: number | boolean,
  ): { height: number; padTop: number; padBottom: number };
};
export const measureBlockHeight: MeasureBlockHeight = (
  block: PdfBlock,
  pdf: PDFKit.PDFDocument,
  forcePadTop?: number | boolean,
  forcePadBottom?: number | boolean,
): any => {
  let h: number,
    padTop = 0,
    padBottom = 0;
  const returnObject = forcePadBottom !== undefined;
  if (typeof forcePadTop !== 'number') forcePadTop = undefined;
  if (typeof forcePadBottom !== 'number') forcePadBottom = undefined;
  const marginTop = block.margins?.top ?? 0;
  const marginBottom = block.margins?.bottom ?? 0;
  padTop = forcePadTop ?? marginTop;
  padBottom = forcePadBottom ?? marginBottom;
  if (isPdfTextStyleBlock(block)) {
    h = heightOfFormattedText(pdf, block);
    const height = h - marginBottom - marginTop + padTop + padBottom;
    return returnObject ? { height, padTop, padBottom } : height;
  } else {
    const { width, left, right } = getPdfWidthAndMargins(pdf);
    const contentWidth = width - left - right;
    h = (block.element.clientHeight * contentWidth) / 590;
    const height = h + padTop + padBottom;
    return returnObject ? { height, padTop, padBottom } : height;
  }
};

const measureBlockGroupHeight = (
  group: PdfUnbreakableBlockGroup,
  pdf: PDFDocumentWithColumns,
) => {
  const blockGroup = group as MeasuredPdfBlockGroup;
  const { blocks } = blockGroup;
  const lastBlock = blocks[blocks.length - 1];
  blockGroup.columnTop = blockGroup.top = blocks[0]?.margins?.top ?? 0;
  blockGroup.columnBottom = blockGroup.bottom = lastBlock?.margins?.bottom ?? 0;
  const blockHeights = blocks?.map((block) => measureBlockHeight(block, pdf));
  blockGroup.columnHeight = blockGroup.height = sum(blockHeights);
  const firstBlockColumns = blocks[0]?.columns;
  const lastBlockColumns = lastBlock?.columns;
  const indexOfFirstDifferentColumn = blocks.findIndex(
    ({ columns }) => columns !== firstBlockColumns,
  );
  blockGroup.columnBlocks = blocks;
  if (indexOfFirstDifferentColumn >= 0) {
    if (!firstBlockColumns) {
      // if our group ends with columns, we need to know the height above them.
      // we find the first block that is in a different column, and measure the height of the blocks that come before it
      // and add in the top margin from that block
      blockGroup.heightAboveColumns =
        sum(blockHeights.slice(0, indexOfFirstDifferentColumn)) +
        (blocks[indexOfFirstDifferentColumn]?.margins?.top ?? 0);
      blockGroup.columnHeight -= blockGroup.heightAboveColumns;

      // set up column blocks
      blockGroup.columnBlocks = blockGroup.columnBlocks.slice(
        indexOfFirstDifferentColumn,
      );
      blockGroup.columnTop = blockGroup.columnBlocks[0]?.margins?.top ?? 0;
    }
    if (!lastBlockColumns) {
      // if our group starts with columns, we need to know the height below them.
      // we find the index of the first block with the same same column as in the last block
      // and measure all of those blocks, without the bottom margin of the final block
      const indexOfFirstLastColumn =
        blocks.length -
        blocks
          .slice()
          .reverse()
          .findIndex(({ columns }) => columns !== lastBlockColumns);
      const heightBelowColumns = sum(blockHeights.slice(indexOfFirstLastColumn));
      blockGroup.heightBelowColumns =
        heightBelowColumns - (lastBlock?.margins?.bottom ?? 0);
      blockGroup.columnHeight -= heightBelowColumns;

      // update column blocks
      const columnBlocks = (blockGroup.columnBlocks =
        blockGroup.columnBlocks.slice(
          0,
          blockGroup.columnBlocks.indexOf(blocks[indexOfFirstLastColumn]),
        ));
      blockGroup.columnBottom =
        columnBlocks[columnBlocks.length - 1].margins?.bottom ?? 0;
    }
  }
  return blockGroup;
};

const countBlockGroupsForHeight = ({
  columnCount,
  groups,
  availableHeight,
}: {
  columnCount: number;
  groups: MeasuredPdfBlockGroup[];
  availableHeight: number;
}) => {
  const columnGroupCounts: number[] = [];
  let groupI = 0;
  let columnStartGroupI = 0;
  for (let columnI = 0; columnI < columnCount; ++columnI) {
    let height = 0;
    for (; groupI < groups.length; ++groupI) {
      const group = groups[groupI];
      height += group.columnHeight;
      if (groupI === columnStartGroupI) {
        // for the first element in the column, we get rid of the top margin
        height -= group.columnTop;
      }
      if (height - group.columnBottom > availableHeight) {
        break;
      }
    }
    columnGroupCounts.push(groupI - columnStartGroupI);
    columnStartGroupI = groupI;
  }
  return columnGroupCounts;
}

const countBlockGroupsForColumns = (
  groups: MeasuredPdfBlockGroup[],
  pdf: PDFDocumentWithColumns,
  top: number,
) => {
  const firstBlocks = groups[0].blocks;
  const columnInfo: PdfColumnInfo =
    firstBlocks[firstBlocks.length - 1].columns ||
    firstBlocks.find(({ columns: c }) => c)?.columns ||
    { count: 1, gap: 0 }; // use a single column for blocks that have no columns
  if (!columnInfo) throw new Error('invalid column block group');
  const lastGroup = groups[groups.length - 1];
  const afterColumnHeight = lastGroup.heightBelowColumns ?? 0;

  // calculate height available for column
  let columnTop = pdf.y + top;
  const heightAboveColumns = groups[0].heightAboveColumns;
  if (heightAboveColumns) {
    columnTop += heightAboveColumns - groups[0].top;
  }
  const pdfPage = getPageDimensions(pdf);
  const maxColumnBottom = Math.ceil(pdfPage.height - pdfPage.margins.bottom - afterColumnHeight) + 0.5;
  let availableHeight = maxColumnBottom - columnTop;

  let columnBlocks = countBlockGroupsForHeight({ columnCount: columnInfo.count, groups, availableHeight });
  if (afterColumnHeight && sum(columnBlocks) < groups.length - 1) {
    // if all the block groups didn't fit, and there was an after column height, we should re-run it without that after column height
    // to know how many will fit (since the after column height part will be on the next page)

    // if all but one fit, we don't do this, because we can't fit more than that on the page anyway.
    availableHeight += afterColumnHeight;
    columnBlocks = countBlockGroupsForHeight({ columnCount: columnInfo.count, groups, availableHeight });
    if (sum(columnBlocks) === groups.length) {
      // if they now all fit, it's no good, because of the after column height required in the last group
      // so we have to git rid of the last block group:
      --columnBlocks[columnBlocks.length - 1];
    }
  }

  return columnBlocks;
};

const columnGroupsHeight = (groups: MeasuredPdfBlockGroup[]) =>
  groups.reduce(
    (total, group, i, groups) =>
      total +
      group.columnHeight -
      (i === 0 ? group.columnTop : 0) -
      (i === groups.length - 1 ? group.columnBottom : 0),
    0,
  );

/**
 * balance the measured groups into two columns, by adding the first block of the second column
 * to columnBreaks
 * 
 * centerColumns is for if we want to have a centered column with the last group, in cases when
 * all the groups before the last group can be evenly balanced and just leave the last one on its own.
 */
const balanceColumns = ({
  columnBreaks,
  centerColumns,
  measuredGroups,
}: {
  columnBreaks: Set<PdfBlock>;
  centerColumns?: Set<PdfBlock>;
  measuredGroups: MeasuredPdfBlockGroup[];
}) => {
  if (!measuredGroups.length) return;
  if (centerColumns && measuredGroups.length % 2 === 1) {
    // if there are an odd number of groups, first check for a two column + centered column layout:
    // bit shift to ignore remainder
    const halfCount = measuredGroups.length >> 1;
    const heightA = columnGroupsHeight(measuredGroups.slice(0, halfCount));
    const heightB = columnGroupsHeight(measuredGroups.slice(halfCount, halfCount * 2));
    if (Math.abs(heightA - heightB) < 0.5) {
      columnBreaks.add(measuredGroups[halfCount].blocks[0]);
      centerColumns.add(measuredGroups[halfCount * 2].blocks[0]);
      return;
    }
  }

  let maxHeight = Infinity;
  let difference = Infinity;
  let columnI = measuredGroups.length - 1;
  for (let i = 1; i < measuredGroups.length; ++i) {
    const heightA = columnGroupsHeight(measuredGroups.slice(0, i));
    const heightB = columnGroupsHeight(measuredGroups.slice(i));
    const newMax = Math.max(heightA, heightB);
    const newDifference = Math.abs(heightA - heightB);
    if (newMax + 0.5 > maxHeight) {
      if (Math.abs(newMax - maxHeight) < 0.5) {
        // Use the one with the bigger difference
        // because that will be putting the column break over the bigger gap:
        if (newDifference > difference) {
          ++i;
        }
      }
      // we have our best value with the split at the previous i:
      columnI = i - 1;
      break;
    }
    maxHeight = newMax;
    difference = newDifference;
  }
  columnBreaks.add(measuredGroups[columnI].blocks[0]);
}

/**
 * 
 * @returns number of blocks measured, including nonBreakingBlocks;
 * it will be 0 if not even any of the nonBreakingBlocks will fit
 */
export const measureColumnBlocks = ({
  nonBreakingBlocks,
  nextBlocks,
  pdf,
  top,
  columnBreaks,
  pageBreaks,
  centerColumns,
}: {
  nonBreakingBlocks: PdfBlock[];
  nextBlocks: PdfBlock[];
  pdf: PDFDocumentWithColumns;
  top: number;
  columnBreaks: Set<PdfBlock>;
  pageBreaks: Set<PdfBlock>;
  centerColumns?: Set<PdfBlock>;
}): number => {
  // check if columns are continued past the break;
  // if they are, we need to measure out all the column elements
  // to check where the column should break.
  const firstBreakableBlockColumns =
    nonBreakingBlocks[nonBreakingBlocks.length - 1].columns;
  let blocksToHandle = nonBreakingBlocks;
  if (
    nextBlocks.length &&
    firstBreakableBlockColumns &&
    nextBlocks[0].columns === firstBreakableBlockColumns
  ) {
    // find the first block that is in a different column
    let indexOfFirstDifferentColumn = nextBlocks.findIndex(
      ({ columns }) => columns !== firstBreakableBlockColumns,
    );
    if (indexOfFirstDifferentColumn < 0) {
      indexOfFirstDifferentColumn = nextBlocks.length;
    }
    // collect blocks after the column into `afterColumnBlocks` and keep blocks within this column in `nextBlocks`
    nextBlocks = nextBlocks.slice();
    const afterColumnBlocks = nextBlocks.splice(
      indexOfFirstDifferentColumn + 1,
    );
    // find the first breakable block after the column
    const firstBreakableIndex = afterColumnBlocks.findIndex(isBlockBreakableAtPage);
    // throw out any blocks after the first breakable block after the current column
    afterColumnBlocks.splice(Math.max(0, firstBreakableIndex));
    // collect all blocks from the current through the column
    blocksToHandle = [
      ...nonBreakingBlocks,
      ...nextBlocks,
      ...afterColumnBlocks,
    ];
  }
  // group into nonbreakable groups
  const blockGroups = splitAtBreakableColumns(blocksToHandle);
  const measuredGroups = blockGroups.map((group) =>
    measureBlockGroupHeight(group, pdf),
  );

  let columnCounts = countBlockGroupsForColumns(measuredGroups, pdf, top);
  let totalCounts = sum(columnCounts);
  if (!measuredGroups[0].columnBlocks[0].columns?.center) {
    centerColumns = undefined;
  }
  if (totalCounts === 1 && centerColumns) {
    // center the blocks that are in a column since there are not any that can be placed in the second column:
    centerColumns.add(measuredGroups[0].columnBlocks[0]);
  }
  let blocksMeasured = 0;
  if (totalCounts === measuredGroups.length) {
    if (columnCounts.length > 1) {
      // all fit, but we have columns to balance:
      balanceColumns({ columnBreaks, centerColumns, measuredGroups });
    }
    blocksMeasured = blocksToHandle.length;
  } else if (
    totalCounts > 0 &&
    (centerColumns || columnCounts.length === 1 || Math.min(...columnCounts) > 0) // if we are not centering columns (but columnCounts > 1, i.e., we actually have columns), we do not allow the printing of
    // a single column when there will not be anything in the other column
  ) {
    // find last acceptable page break
    // since we may have ended on a break that is only acceptable for a column break
    while (totalCounts >= 1) {
      const { blocks } = measuredGroups[totalCounts - 1];
      if (blocks[blocks.length - 1].pageBreakAfter === false) {
        --totalCounts;
      } else {
        break;
      }
    }
    const unusedGroups = measuredGroups.splice(totalCounts);
    balanceColumns({ columnBreaks, measuredGroups });
    pageBreaks.add(unusedGroups[0].blocks[0]);
    blocksMeasured = sum(measuredGroups.map(({ blocks }) => blocks.length));
  }
  return blocksMeasured;
};
