import { Paragraphs } from 'components/ordo/shared/text/renderer/Paragraphs';
import { processText } from 'utils/markup';
import React from 'react';
import { cx } from '@emotion/css';

interface RubricTextProps {
  text: string;
  className?: string;
}

export const RubricText: React.FC<RubricTextProps> = ({
  text,
  className,
}: RubricTextProps) => (
  <Paragraphs text={processText(text)} className={cx('rubric', className)} />
);
