import React, { useState, useRef, useCallback } from 'react';
import { Document } from 'utils/react-pdf';
import { PageBrowser } from './partials/PageBrowser';
import deepEqual from 'fast-deep-equal';
import { File } from 'utils/api-types/resource';

interface PdfDisplayProps {
  file: File;
}

export const PdfDisplay: React.FC<PdfDisplayProps> = React.memo(({
  file,
}: PdfDisplayProps) => {
  const [numPages, setNumPages] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);
  const { url } = file;
  const onLoadSuccess = useCallback(({numPages}: {numPages: number}) => setNumPages(numPages), []);

  return <>
    <div
      className='d-none-export position-relative hover-parent overflow-hidden'
      ref={ref}
    >
      <Document
        file={file}
        onLoadSuccess={onLoadSuccess}
        options={defaultOptions}
      >
        {ref.current?.clientWidth !== undefined && <PageBrowser
          width={ref.current?.clientWidth}
          numPages={numPages}
          url={url}
        />}
      </Document>
    </div>
    <a
      className='w-100 color-primary clickable d-block-export _EXPORT'
      href={file.url}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span className=''>Your PDF will download separately. This is a placeholder.</span>
    </a>
  </>;
}, (oldProps, newProps) => deepEqual(oldProps.file, newProps.file));

const defaultOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
} as const;
