import { AdLibitumProps } from 'components/ordo/modals/ad-libitum/AdLibitumProps';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { OrdoDisplayMode } from 'utils/ordo/OrdoDisplayMode';

export interface ITopLevelOrdoContext {
  hasRubrics: boolean;
  setHasRubrics: (hasRubrics: boolean) => void;
  openSettings?: () => void;
  setOpenSettings: (openSettings: () => void) => void;
  openChangeTune?: () => void;
  setOpenChangeTune: (openChangeTune: () => void) => void;
  displayMode: OrdoDisplayMode;
  setShowAdLibitumModal?: React.Dispatch<React.SetStateAction<AdLibitumProps | null>>;
  unsetActiveCardId: () => void;
}

export const TopLevelOrdoContext = React.createContext<ITopLevelOrdoContext>({
  hasRubrics: true,
  setHasRubrics: () => {},
  setOpenSettings: () => {},
  setOpenChangeTune: () => {},
  displayMode: OrdoDisplayMode.WRITE,
  unsetActiveCardId: () => {},
} as ITopLevelOrdoContext);

type TopLevelOrdoContextProviderProps = {
  ordoHasRubrics: boolean;
  displayMode: OrdoDisplayMode;
  setShowAdLibitumModal?: React.Dispatch<React.SetStateAction<AdLibitumProps | null>>;
  unsetActiveCardId: () => void;
}

export const TopLevelOrdoContextProvider: React.FC<PropsWithChildren<TopLevelOrdoContextProviderProps>> = ({
  ordoHasRubrics,
  displayMode,
  setShowAdLibitumModal,
  unsetActiveCardId,
  children,
}) => {
  const [hasRubrics, setHasRubrics] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<() => void>();
  const [openChangeTune, setOpenChangeTune] = useState<() => void>();

  useEffect(() => {
    if (ordoHasRubrics) {
      setHasRubrics(true);
    }
  }, [ordoHasRubrics, setHasRubrics]);

  return <TopLevelOrdoContext.Provider
    value={{
      hasRubrics,
      setHasRubrics,
      openSettings,
      setOpenSettings,
      openChangeTune,
      setOpenChangeTune,
      setShowAdLibitumModal,
      displayMode,
      unsetActiveCardId,
    }}
  >
    {children}
  </TopLevelOrdoContext.Provider>
}
