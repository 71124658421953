import { Error, Info, Success, Warn } from 'components/toasts';
import { useCallback } from 'react';
import { Id, Zoom, toast } from 'react-toastify';

const typeComponentMap = {
  'success': Success,
  'error': Error,
  'warn': Warn,
  'info': Info,
}

/**
 * Creates an error handler to be passed on Apollo query/mutation options.
 *
 * By default, the error message from the apollo client will be used, but an
 * optional override can be provided to display a more useful message to the user
 *
 * NOTE: The original message from the apollo client will be printed as a console
 * error to assist with debugging.
 *
 * @param  messageOverride An optional override to display more user-friendly error messages
 */

export const useToast = (type: 'success'|'error'|'warn'|'info', messageOverride?: string | null)  => {
  return useCallback((message: string): Id => {
    if (type !== 'success') {
      console[type](message || messageOverride);
    }
    return toast[type](typeComponentMap[type]({ message: messageOverride || message }), { transition: Zoom });
  }, [messageOverride, type]);
}
