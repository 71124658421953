import { ExsurgeWrapper } from 'components/shared/ExsurgeWrapper';
import { VerovioAntiphonVerseWrapper } from 'components/shared/VerovioAntiphonVerseWrapper';
import { RenderMidiCallback } from 'components/verovio/Verovio';
import useResizeObserver from 'hooks/util/useResizeObserver';
import { CardMei, CardSvg } from 'queries/card-fragment';
import { MelodicComposition } from 'queries/composition';
import { Text } from 'queries/text';
import { TextOption } from 'queries/text-option';
import React, { RefObject, useCallback } from 'react';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { OrdinaryConfigInput } from 'utils/ordo/card/CardConfigInputTypes';
import { colorDictionary } from 'utils/styles';
import { DEFAULT_POST_PROCESS_ORDINARY } from 'utils/verovio/DEFAULT_POST_PROCESS_ANTIPHON';

interface OrdinaryMusicProps {
  config: OrdinaryConfigInput,
  textOption?: TextOption,
  text?: Text,
  composition?: MelodicComposition | null,
  cardMei?: CardMei | null;
  cardSvg?: CardSvg | null;
  referenceNumber?: string;
  parentRef: RefObject<HTMLDivElement>;
  isFullScreen?: boolean;
  addGrandStaff?: boolean;
  onRenderMidi?: RenderMidiCallback;
  onLoaded?: () => void;
}

export const OrdinaryMusic: React.FC<OrdinaryMusicProps> = ({
  config,
  textOption,
  text,
  composition,
  cardMei,
  cardSvg,
  referenceNumber,
  parentRef,
  isFullScreen = false,
  addGrandStaff = false,
  onRenderMidi,
  onLoaded,
}) => {
  const { width = 0 } = useResizeObserver({ref: parentRef});

  const onRenderAntiphonMidi: RenderMidiCallback = useCallback((...[base64String, timeMaps]: Parameters<RenderMidiCallback>) => {
    onLoaded?.();
    onRenderMidi?.(base64String, timeMaps);
  }, [onLoaded, onRenderMidi]);


  return (
    <>
      {composition && (config.notation === MusicalNotation.SQUARE || !config.notation) && <>
        {!!referenceNumber && <div className='text-end _EXPORT' style={{
          fontSize: 15,
          fontStyle: 'italic',
          color: colorDictionary['Secondary'],
          lineHeight: 1,
        }}>{referenceNumber}</div>}
        <ExsurgeWrapper
          removeSolesmesMarkings={config.removeSolesmesMarkings ?? false}
          textOption={textOption}
          antiphonText={text}
          antiphon={composition}
          capitalizeInitial={true}
          spacingHorizontal={config.spacingHorizontal}
          spacingVertical={config.spacingVertical}
          width={width}
          onLoaded={onLoaded}
        />
      </>}

      {composition && config.notation === MusicalNotation.MODERN && <VerovioAntiphonVerseWrapper
        displayMode={config.verseStyle}
        cardMei={cardMei}
        cardSvg={cardSvg}
        antiphonMode={composition.melody.mode}
        keyOffset={config.keyOffset}
        isFullScreen={isFullScreen}
        addGrandStaff={addGrandStaff}
        displayHarmonizations={config.displayHarmonizations}
        onRenderAntiphonMidi={onRenderAntiphonMidi}
        referenceNumber={referenceNumber}
        postProcessConfig={DEFAULT_POST_PROCESS_ORDINARY}
        spacingLinear={config.spacingLinear}
        spacingNonLinear={config.spacingNonLinear}
        spacingHorizontal={config.spacingHorizontal}
        spacingVertical={config.spacingVertical}
      />}
    </>
  );
}
