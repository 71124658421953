import { useMemo, useEffect, useState } from 'react';
import { useQuery } from 'hooks/queries/useQuery';
import { GetResourcesForCards } from 'queries/card';

export type DownloadResource = {buffer: ArrayBuffer; fileExt: string|undefined};
export type DownloadResources = Map<number, DownloadResource>;

export const useDownloadResources = (cardIds: number[]): [DownloadResources|undefined, boolean] => {
  const [resourceData, setResourceData] = useState<DownloadResources>();
  const updateResourceData = (cardId: number, resource: DownloadResource) =>
    setResourceData((data) => new Map(data).set(cardId, resource));

  const [loading, setLoading] = useState<boolean>(true);
  const { data, loading: loadingResources } = useQuery(GetResourcesForCards, {
    variables: {
      ids: cardIds,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loadingResources && !data?.getResourcesForCards.length) {
      setLoading(false);
    }
  }, [loadingResources, data]);

  const externalPdfs = useMemo(
    () =>
      new Map(
        data?.getResourcesForCards?.map((item) => [
          item.cardId,
          {
            url: item.resource.file.url,
            fileExt: item.resource.file.awsKey.match(/\.([^.]+)$/)?.[1],
          },
        ]),
      ),
    [data],
  );

  useEffect(() => {
    if (!externalPdfs?.size) {
      return;
    }

    const entries = Array.from(externalPdfs.entries());
    Promise.all(
      entries.map(([cardId, {url, fileExt}]) => fetch(url, { cache: 'force-cache' })
        .then((res) => res.arrayBuffer())
        .then((buffer) => updateResourceData(cardId, {buffer, fileExt})))
    ).then(() => setLoading(false));
  }, [externalPdfs]);

  return [resourceData, loading];
};
