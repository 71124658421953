import { FetchResult } from '@apollo/client';
import { getLayoutCardId } from 'components/layout/useLayoutRenderer';
import { useDebugLogger, useLoggedQuery } from 'hooks/debug';
import { useSavingChangesMutation } from 'hooks/useSavingChangesMutation';
import { getSessionStorageValueWithLocalStorageBackup } from 'hooks/useSessionStorage';
import { useTabs } from 'hooks/useTabs';
import type { OrdoCardWithMei } from 'queries/card-fragment';
import type { PartialLayoutDimensions } from 'queries/layout';
import { GetLayoutFile, InsertImageInLayoutFile, LayoutCard, LayoutFile, MarginDimensions, RemoveLayoutCard, SetDisabledCardIds, UpdateCardConfigLayoutOverride, UpdateImagePositionInLayoutFile, UpdateLayoutCardConfig, UpdateLayoutFileConfig, UpdateLayoutFileDimensions, isImageDimensions } from 'queries/layout-file';
import { GetOrdo, Ordo } from 'queries/ordo';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LayoutCardConfigInput, LayoutFileConfigInput } from 'utils/api-types/layout/LayoutFileConfig';
import { OrdoCardConfig, OrdoCardConfigInput } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { ExportFileFormat } from 'utils/export';
import { Tab, tabMap } from 'utils/layout/LayoutTabs';
import { paths } from 'utils/paths';

export type CardIdWithPosition = readonly [
  cardId: number,
  cardSortPosition: number,
  type: 'layoutCard' | 'ordoCard',
];
export interface ILayoutContext {
  ordo: Ordo;
  enabledCards: OrdoCardWithMei[];
  activeFile: LayoutFile;
  effectivePageWidth: number;
  effectiveBottomMargin: number;
  cardMap: Map<number, LayoutCard>;
  overrideConfigMap: Map<number, OrdoCardConfig>;
  setActiveFile: (id: number) => void;
  activeTab: Tab;
  setTab: (tab: Tab) => void;
  contentWidth: number;
  harmonizedContentWidth: number;
  disabledCardIds: Set<number>;
  setDisabledCardIds: React.Dispatch<React.SetStateAction<Set<number>>>;
  selectedCardId?: string | undefined;
  setSelectedCardId: React.Dispatch<React.SetStateAction<string | undefined>>;
  hoveringCardId?: string | undefined;
  setHoveringCardId: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateLayoutCardConfig: (cardId: number, configDiff: LayoutCardConfigInput) => Promise<FetchResult<UpdateLayoutCardConfig['Data']>>;
  removeLayoutCard: (cardId: number) => Promise<FetchResult<RemoveLayoutCard['Data']>>;
  updateImagePosition: (cardId: number, sortPosition: number) => Promise<FetchResult<UpdateImagePositionInLayoutFile['Data']>>;
  updateCardConfigLayoutOverride: (cardId: number, configDiff: OrdoCardConfigInput) => Promise<FetchResult<UpdateCardConfigLayoutOverride['Data']>>;
  insertImageFile: (imageFileId: number, sortPosition: number) => Promise<FetchResult<InsertImageInLayoutFile['Data']>>;
  cardPositionMap: Map<string, number>;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  fileFormat: ExportFileFormat;
  setFileFormat: React.Dispatch<React.SetStateAction<ExportFileFormat>>;
  updateConfig: (configDiff: LayoutFileConfigInput) => Promise<
    FetchResult<UpdateLayoutFileConfig['Data']>
  >;
  updateDimensions: (dimensions: PartialLayoutDimensions) => Promise<FetchResult<UpdateLayoutFileDimensions['Data']>>;
  updateMargin: <T extends keyof MarginDimensions>(margin: MarginDimensions[T], marginKey: T) => Promise<FetchResult<UpdateLayoutFileDimensions['Data']>>;
  pageNumberFontSize: number;
  pageNumberMargin: number;
  orderedCardIds?: CardIdWithPosition[];
  pdfCardIds: Set<string>;
  setPdfCardIds: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const defaultLayoutContext = {
  ordo: {} as const as Ordo,
  enabledCards: [],
  activeFile: { config: {} } as LayoutFile,
  cardMap: new Map(),
  overrideConfigMap: new Map(),
  setActiveFile: () => {},
  activeTab: 'Document',
  setTab: () => {},
  contentWidth: 5.5,
  harmonizedContentWidth: 7,
  disabledCardIds: new Set<number>(),
  setDisabledCardIds: () => {},
  setSelectedCardId: () => {},
  setHoveringCardId: () => {},
  updateLayoutCardConfig: () => Promise.resolve({}),
  removeLayoutCard: () => Promise.resolve({}),
  updateImagePosition: () => Promise.resolve({}),
  updateCardConfigLayoutOverride: () => Promise.resolve({}),
  insertImageFile: () => Promise.resolve({}),
  cardPositionMap: new Map(),
  isDragging: false,
  setIsDragging: () => {},
  fileFormat: ExportFileFormat.PDF,
  setFileFormat: () => {},
  updateConfig: () => Promise.resolve({}),
  updateDimensions: () => Promise.resolve({}),
  updateMargin: () => Promise.resolve({}),
  pageNumberFontSize: 12,
  pageNumberMargin: 12,
  effectivePageWidth: 0,
  effectiveBottomMargin: 0,
  pdfCardIds: new Set(),
  setPdfCardIds: () => {},
} as const satisfies ILayoutContext;

export const LayoutContext = React.createContext<ILayoutContext>(defaultLayoutContext);

type LayoutContextProviderProps = {
}

export const getHarmonizedMargin = (
  side: 'Left' | 'Right',
  layoutFile: LayoutFile,
) =>
  isImageDimensions(layoutFile)
    ? 0
    : layoutFile?.[`margin${side}Harmonized`] ??
      Math.max((layoutFile?.[`margin${side}`] ?? 0) / 2, 0.25);

export const LayoutContextProvider: React.FC<PropsWithChildren<LayoutContextProviderProps>> = ({
  children
}) => {
  const { id = '' } = useParams<{ id: string }>();
  const [activeTab, setTab] = useTabs(paths.layout[0].replace(':id', id ?? '') + '/', tabMap, 'Document');
  const [disabledCardIds, _setDisabledCardIds] = useState(new Set<number>());
  const [enabledCards, setEnabledCards] = useState<OrdoCardWithMei[]>([]);
  const [pdfCardIds, setPdfCardIds] = useState<Set<string>>(new Set());
  const debugLogger = useDebugLogger('layout');
  const history = useHistory();

  const layoutFileId = parseInt(id);
  const [activeLayoutFileId, setActiveLayoutFileId] = useState(layoutFileId);
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [hoveringCardId, setHoveringCardId] = useState<string>();
  const [isDragging, setIsDragging] = useState(false);
  const [fileFormat, setFileFormat] = useState(ExportFileFormat.PDF);

  const [_updateConfig] = useSavingChangesMutation(UpdateLayoutFileConfig);
  const updateConfig = useCallback(
    (configDiff: LayoutFileConfigInput) =>
      _updateConfig({ variables: { layoutFileId, configDiff } }),
    [_updateConfig, layoutFileId],
  );
  const [_updateDimensions] = useSavingChangesMutation(UpdateLayoutFileDimensions);
  const updateDimensions = useCallback(
    (dimensions: PartialLayoutDimensions) =>
      _updateDimensions({ variables: { layoutFileId, dimensions } }),
    [_updateDimensions, layoutFileId],
  );
  const updateMargin: ILayoutContext['updateMargin'] = useCallback(
    (val, key) => updateDimensions({ [key]: val }),
    [updateDimensions]
  );
  const [_updateLayoutCardConfig] = useSavingChangesMutation(UpdateLayoutCardConfig);
  const updateLayoutCardConfig = useCallback(
    (cardId: number, configDiff: LayoutCardConfigInput) =>
      _updateLayoutCardConfig({ variables: { cardId, configDiff } }),
    [_updateLayoutCardConfig],
  );
  const [_removeLayoutCard] = useSavingChangesMutation(RemoveLayoutCard);
  const removeLayoutCard = useCallback(
    (cardId: number) => _removeLayoutCard({ variables: { cardId } }),
    [_removeLayoutCard],
  );
  const [_updateImagePositionInLayoutFile] = useSavingChangesMutation(UpdateImagePositionInLayoutFile);
  const updateImagePosition = useCallback(
    (cardId: number, sortPosition: number) =>
      _updateImagePositionInLayoutFile({ variables: { cardId, sortPosition } }),
    [_updateImagePositionInLayoutFile],
  );
  const [_updateCardConfig] = useSavingChangesMutation(UpdateCardConfigLayoutOverride);
  const updateCardConfigLayoutOverride = useCallback(
    (cardId: number, configDiff: OrdoCardConfigInput) =>
      _updateCardConfig({ variables: { layoutFileId, cardId, configDiff } }),
    [_updateCardConfig, layoutFileId],
  );
  const [_insertImage] = useSavingChangesMutation(InsertImageInLayoutFile);
  const insertImageFile = useCallback(
    (imageFileId: number, sortPosition: number) =>
      _insertImage({ variables: { imageFileId, sortPosition, layoutFileId: activeLayoutFileId } }),
    [_insertImage, activeLayoutFileId],
  );

  useEffect(() => {
    if (Number.isNaN(layoutFileId)) {
      history.push(paths.dashboard);
    }
  }, [layoutFileId, history]);

  const { data: { layoutFile } = { layoutFile: undefined } } = useLoggedQuery(
    debugLogger,
    'GET_LAYOUT_FILE',
    GetLayoutFile,
    {
      variables: { id: activeLayoutFileId }
    }
  );
  const cardMap = useMemo(
    () => new Map(layoutFile?.cards.map((card) => [card.id, card])),
    [layoutFile?.cards],
  );
  const cardPositionMap = useMemo(
    () => new Map([
      ...(layoutFile?.cards.map((card) => [getLayoutCardId(card), card.sortPosition] as const) ?? []),
      ...(layoutFile?.ordo.cards.map((card) => [`${card.id}`, card.sortPosition] as const) ?? []),
    ]),
    [layoutFile?.cards, layoutFile?.ordo.cards]
  )
  const overrideConfigMap = useMemo(
    () =>
      new Map(
        layoutFile?.cardConfigOverrides.map((card) => [
          card.cardId,
          card.properties,
        ]),
      ),
    [layoutFile?.cardConfigOverrides],
  );

  useEffect(() => {
    // when layoutFile is first loaded, we need to update
    // the disabledCardIds state:
    if (layoutFile?.disabledCardIds?.length) {
      _setDisabledCardIds(disabledCardIds =>
        disabledCardIds.size ? disabledCardIds : new Set(layoutFile?.disabledCardIds)
      );
    }
  }, [layoutFile?.disabledCardIds]);

  const { data: { ordo } = { ordo: undefined } } = useLoggedQuery(
    debugLogger,
    'GET_ORDO',
    GetOrdo,
    {
      variables: layoutFile?.ordoId ? { id: layoutFile.ordoId, layoutFileId: layoutFile.id } : undefined,
      skip: !layoutFile?.ordoId,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [setRemoteDisabledCardIds] = useSavingChangesMutation(SetDisabledCardIds);
  const setDisabledCardIds: React.Dispatch<React.SetStateAction<Set<number>>> = useCallback(disabledCardIds => {
    if (typeof disabledCardIds === 'function') {
      _setDisabledCardIds(prev => {
        const newValue = disabledCardIds(prev);
        if (newValue !== prev) {
          setRemoteDisabledCardIds({ variables: { id: activeLayoutFileId, disabledCardIds: Array.from(newValue) } });
        }
        return newValue;
      })
    } else {
      setRemoteDisabledCardIds({ variables: { id: activeLayoutFileId, disabledCardIds: Array.from(disabledCardIds) } });
      _setDisabledCardIds(disabledCardIds);
    }
  }, [activeLayoutFileId, setRemoteDisabledCardIds]);


  const setActiveFile = useCallback((id: number) => {
    const layoutFilePath = paths.layout[0].replace(':id', id.toString());
    const tab = tabMap.find(([,label]) => label === activeTab)?.[0] ?? '';
    history.replace(`${layoutFilePath}/${tab}`);
    setActiveLayoutFileId(id);
  }, [activeTab, history]);

  useEffect(() => {
    if (ordo) {
      if (ordo.layoutFiles.length === 0) {
        history.push(paths.ordo + '/' + ordo.id.toString());
      } else {
        const layoutFileIds = ordo.layoutFiles.map(({id}) => id);
        if (!layoutFileIds.includes(activeLayoutFileId)) {
          const layoutFilesLastViewed = getSessionStorageValueWithLocalStorageBackup('layoutFilesLastViewed');
          const lastViewed = layoutFilesLastViewed?.find(id => layoutFileIds.includes(id));
          const id = typeof lastViewed === 'number' ? lastViewed : ordo.layoutFiles[0].id;
          setActiveFile(id);
        }
      }
    }
  }, [activeLayoutFileId, history, ordo, ordo?.layoutFiles.length, setActiveFile]);

  const cards = ordo?.cards;
  useEffect(() => {
    setEnabledCards((cards ?? []).filter(card => card.enabled && !disabledCardIds.has(card.id)))
  }, [cards, disabledCardIds])

  const [effectivePageWidth, contentWidth, harmonizedContentWidth] = useMemo(() => {
    if (!layoutFile) return [0, 0, 0];
    const { pageWidth, columns, marginLeft, marginRight } = layoutFile;
    const effectivePageWidth = pageWidth / (columns ?? 1);
    const [marginLeftHarmonized, marginRightHarmonized] = (
      ['Left', 'Right'] as const
    ).map((side) => getHarmonizedMargin(side, layoutFile));
    const contentWidth = effectivePageWidth - (marginLeft ?? 0) - (marginRight ?? 0);
    const harmonizedContentWidth = effectivePageWidth - marginLeftHarmonized - marginRightHarmonized;
    return [effectivePageWidth, contentWidth, harmonizedContentWidth];
  }, [layoutFile]);

  const pageNumberFontSize = 12;
  const pageNumberMargin = 12;
  const effectiveBottomMargin =
    (layoutFile &&
      (layoutFile.config.pageNumbers
        ? (pageNumberFontSize * 0.8 + pageNumberMargin) / 72
        : 0) + (layoutFile.marginBottom ?? 0)) ??
    0;
  
  const orderedCardIds: CardIdWithPosition[] | undefined = useMemo(
    () =>
      !ordo || !layoutFile
        ? undefined
        : [
            ...ordo.cards
              .filter((card) => card.enabled && !disabledCardIds.has(card.id))
              .map((card) => [card.id, card.sortPosition, 'ordoCard'] as const),
            ...layoutFile.cards.map(
              (card) => [card.id, card.sortPosition, 'layoutCard'] as const,
            ),
          ].sort(([, a], [, b]) => a - b),
    [disabledCardIds, layoutFile, ordo],
  );

  return !ordo || !layoutFile ? null : (
    <LayoutContext.Provider
      value={{
        ordo,
        enabledCards,
        activeFile: layoutFile,
        cardMap,
        overrideConfigMap,
        setActiveFile,
        activeTab,
        setTab,
        contentWidth,
        harmonizedContentWidth,
        disabledCardIds,
        setDisabledCardIds,
        selectedCardId,
        setSelectedCardId,
        hoveringCardId,
        setHoveringCardId,
        updateLayoutCardConfig,
        removeLayoutCard,
        updateImagePosition,
        updateCardConfigLayoutOverride,
        insertImageFile,
        cardPositionMap,
        isDragging,
        setIsDragging,
        fileFormat,
        setFileFormat,
        updateConfig,
        updateDimensions,
        updateMargin,
        effectivePageWidth,
        effectiveBottomMargin,
        pageNumberFontSize,
        pageNumberMargin,
        orderedCardIds,
        pdfCardIds,
        setPdfCardIds,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}
