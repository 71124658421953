import { FeatureGateArgs } from "hooks/permission-set/useFeatureGate";
import { GatedFeature } from "utils/api-types/PermissionSetId";

/**
 * Must be kept up to date with backend enum `OrdoCardType` in ordo-card.ts
 */
export enum CardTypes {
  ANTIPHON_VERSE = 'ANTIPHON_VERSE',
  ANTIPHON_VERSE_GLORIA_PATRI = 'ANTIPHON_VERSE_GLORIA_PATRI',
  ENTRANCE_CHANT = 'ENTRANCE_CHANT',
  COMMUNION_CHANT = 'COMMUNION_CHANT',
  RESPONSORIAL_PSALM = 'RESPONSORIAL_PSALM',
  ALLELUIA = 'ALLELUIA',
  VERSE_BEFORE_GOSPEL = 'VERSE_BEFORE_GOSPEL',
  READING = 'READING',
  SEQUENCE = 'SEQUENCE',
  ORDINARY = 'ORDINARY',
  MYSTERY_OF_FAITH = 'MYSTERY_OF_FAITH',
  HYMN = 'HYMN',
  REFERENCE = 'REFERENCE',
  NOTES = 'NOTES',
  SECTION_HEADER = 'SECTION_HEADER',
  RUBRIC = 'RUBRIC',
  SELECT = 'SELECT',
  CHORAL = 'CHORAL',
  INSTRUMENTAL = 'INSTRUMENTAL',
  PRAYER = 'PRAYER',
  AD_LIBITUM_ANTIPHON = 'AD_LIBITUM_ANTIPHON',
  AD_LIBITUM_READING = 'AD_LIBITUM_READING',
}

export const restrictedCardTypes: {
  [key: string]: FeatureGateArgs | undefined;
  HYMN: FeatureGateArgs;
  NOTES: FeatureGateArgs;
  READING: FeatureGateArgs;
  PRAYER: FeatureGateArgs;
} = {
  [CardTypes.HYMN]: { feature: GatedFeature.Hymns, expressedInterest: 'include hymns in your prepared liturgies' },
  [CardTypes.NOTES]: { feature: GatedFeature.CustomText, expressedInterest: 'include custom text sections in your prepared liturgies' },
  [CardTypes.READING]: { feature: GatedFeature.Readings, expressedInterest: 'include readings in your prepared liturgies' },
  [CardTypes.PRAYER]: { feature: GatedFeature.PrayerCard, expressedInterest: 'include prayer cards in your prepared liturgies' },
};

export const MUSICAL_TYPES = [
  CardTypes.ANTIPHON_VERSE,
  CardTypes.ANTIPHON_VERSE_GLORIA_PATRI,
  CardTypes.ENTRANCE_CHANT,
  CardTypes.COMMUNION_CHANT,
  CardTypes.RESPONSORIAL_PSALM,
  CardTypes.ALLELUIA,
  CardTypes.VERSE_BEFORE_GOSPEL,
  CardTypes.SEQUENCE,
  CardTypes.ORDINARY,
  CardTypes.MYSTERY_OF_FAITH,
  CardTypes.HYMN,
  CardTypes.CHORAL,
  CardTypes.INSTRUMENTAL,
  CardTypes.REFERENCE,
  CardTypes.SELECT,
  CardTypes.PRAYER,
] as string[];
