import React from 'react';

interface SuccessProps  {
  message: string;
}

export const Success: React.FC<SuccessProps> = ({message}) => {
  return (
    <>{message}</>
  );
}
