import Axios from "axios";
import { apiOrigin } from "utils/env";

const uploadUrl = `${apiOrigin}/error/upload`
export const uploadErrorFile = async (file: File, errorIds: number[]) => {
  const formData = new FormData();
  formData.append('file', file);
  return Axios.post(`${uploadUrl}/${errorIds.join(',')}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}
