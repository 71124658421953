import { css, cx } from '@emotion/css';
import React from 'react';
import { processPoeticParagraph, getTextReaderNames } from 'utils/markup';

interface ParagraphsProps {
  text: string;
  showAll?: boolean;
  className?: string;
  language?: string;
  numberOfReaders?: number;
}

export const Paragraphs: React.FC<ParagraphsProps> = ({
  text,
  showAll = true,
  className = '',
  language = '',
  numberOfReaders = 1,
}) => {
  // split content into paragraphs
  const paragraphBreak = '</p><p>';
  const processed = processPoeticParagraph(text, paragraphBreak);
  const withReaderNames = getTextReaderNames(processed, language, numberOfReaders);
  const paragraphs = withReaderNames
    .replace(
      /(\s+)(<span )/g,
      (_, whitespace, span) =>
        `${whitespace === ' ' ? '' : whitespace.length > 1 ? whitespace : '<br/>'}${span}`,
    )
    .split(/(?:<br\/>)?\r?\n\r?\n/);
  const content = showAll ? paragraphs.join(paragraphBreak) : paragraphs[0];
  const __html = `<p>${content}</p>`;
  return (
    <div
      className={cx(style, className, 'content _EXPORT')}
      dangerouslySetInnerHTML={{ __html }}
    ></div>
  );
}
const style = css`
  p + p {
    padding-top: 15px;
  }
`;
