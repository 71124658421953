import { gql } from '@apollo/client';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { WithId, WithName, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { OrdoRole, OrdoRoleConfig, ORDO_ROLE_FRAGMENT } from './ordo-role';
import { OrdoCardConfig, ORDO_CARD_CONFIG_FRAGMENT } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { PermissionSet, PERMISSION_SET_FRAGMENT } from './permission-set';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { TEXT_OPTION_WITH_META_FRAGMENT, TextOptionWithMeta } from './text-option';
import type { TimeMapEntry } from 'verovio';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { Note, NOTE_FRAGMENT } from './note';
import { TextCategoriesWithCardTypeSeedId } from 'utils/api-types/TextCategoriesSeedIdWithCardType';
import { ResourceGroupTypesSeedId } from 'utils/api-types/resource/ResourceGroupTypesSeedId';

//////////
/// TYPES
export type ResourceGroupType = {
  singularDisplayName: string;
  pluralDisplayName: string;
  definiteSingularDisplayName: string;
  name: ResourceGroupTypesSeedId;
} & WithId;

export type OrdoCard = {
  updatedAt: Date;
  sortPosition: number;
  enabled: boolean;
  type: CardTypes;
  originalType: CardTypes | null;
  permissionSet: PermissionSet | null,
  textCategory: ({
    name: TextCategoriesWithCardTypeSeedId;
    isInvariableOrdinary: boolean;
    resourceGroupType: ResourceGroupType | null;
  } & WithId) | null;
  parent: WithId | null;
  stepId: number | null;
  step: ({
    textCategory: WithNameAndId;
  } & WithId) | null;
  config: OrdoCardConfig;
  roleConfigs: RoleConfigs;
  isConfigured: boolean;
  ordoId: number;
  attributes: {
    title: string;
    attributes: Attribute[];
  }[];
} & OrdoCardSummary & WithNameAndId;

export type OrdoCardWithMei = OrdoCard & {
  mei?: CardMei | null;
};

export type OrdoCardShare = OrdoCard & {
  selectedTextOption: TextOptionWithMeta | null;
  note: Note | null;
};
export type OrdoCardSvg = {
  svg: CardSvg | null;
} & WithId;

export type OrdoCardWithMeiOrSvg = OrdoCard &
  ExclusiveUnion<
    | { mei?: CardMei | null; }
    | { svg: CardSvg | null; }
  >;

type OrdoCardSummary = {
  summary: {
    reference: string | null;
    secondary: string | null;
    tags: string[] | null;
    greyTags: boolean | null;
  }
};

export type BriefOrdoCardSummary = Pick<OrdoCard,
  | 'id'
  | 'updatedAt'
  | 'name'
  | 'type'
  | 'parent'
  | 'summary'
> & {
  textCategory: WithNameAndId | null;
};

export type RoleConfigs = {
  ordoRole: OrdoRole;
  config: OrdoRoleConfig;
}[];

type CardData = {
  ordoRoleId: number | null;
  name: string | null;
  exportName: string | null;
  keyIndex: number | null;
  defaultTranspose: number | null;
  antiphonVerseMidi: MeiMidi | null;
  textVerses: string[] | null;
  verseCount: number | null;
  versesContainMultipleStanzas: boolean | null;
  error: boolean | null;
  verseError: boolean | null;
  refrain: string | null;
  refrainAtBeginning: boolean | null;
  referenceNumber: string | null;
  antiphonMode: string | null;
  antiphonHasChordSymbols: boolean | null;
  antiphonHasHarmonization: boolean | null;
  verseHasHarmonization: boolean | null;
  hymnHasSuppressedStems: boolean | null;
  audioUrls: string[] | null;
};
export type MeiMidi = {
  midi: string;
  timeMap: TimeMapEntry[];
}
export type CardMei = CardData & {
  hymn: MeiWithTitles[] | null;
  antiphon: MeiWithTitles[] | null;
  verses: string[] | null;
};
export type CardSvg = CardData & {
  hymn: SvgWithTitles[] | null;
  antiphon: SvgWithTitles[] | null;
  verses: string[][] | null;
  versesMidi: MeiMidi[] | null;
  verseGabc: string | null;
  musicalNotation: MusicalNotation;
  additionalAntiphonText?: string | null;
}

type WithTitlesAndMidi = {
  title: string | null;
  subtitle: string | null;
  supertitle: string | null;
  midi: MeiMidi | null;
}
export type MeiWithTitles = {
  mei: string;
} & WithTitlesAndMidi;
export type SvgWithTitles = {
  svgs: string[];
} & WithTitlesAndMidi;

export const isMeiWithTitles = (
  meiOrSvg: MeiWithTitles | SvgWithTitles,
): meiOrSvg is MeiWithTitles => (meiOrSvg as MeiWithTitles).mei !== undefined;

export type Attribute = {
  value: string;
  isSystem: boolean;
} & WithName;

/////////////
/// FRAGMENT
export const MEI_MIDI_FRAGMENT = gql`
  fragment MeiMidi on MeiMidi {
    midi
    timeMap {
      tstamp
      qstamp
      on
      off
      tempo
    }
  }
`;

export const RESOURCE_GROUP_TYPE_FRAGMENT = gql`
  fragment ResourceGroupType on ResourceGroupType {
    id
    name
    singularDisplayName
    pluralDisplayName
    definiteSingularDisplayName
  }
`;

export const CARD_SUMMARY_FRAGMENT = gql`
  fragment OrdoCardSummary on OrdoCard {
    summary {
      reference
      secondary
      tags
      greyTags
    }
  }
`;

export const CARD_MEI_FRAGMENT = gql`
  fragment CardMei on CardMei {
    ordoRoleId
    name
    exportName
    keyIndex
    hymn {
      mei
      midi { ...MeiMidi }
    }
    defaultTranspose
    antiphon {
      mei
      midi { ...MeiMidi }
      title
      subtitle
      supertitle
    }
    antiphonVerseMidi { ...MeiMidi }
    verses
    verseCount
    textVerses
    versesContainMultipleStanzas
    error
    verseError
    refrain
    refrainAtBeginning
    referenceNumber
    antiphonHasChordSymbols
    antiphonHasHarmonization
    verseHasHarmonization
    hymnHasSuppressedStems
    audioUrls
  }
  ${MEI_MIDI_FRAGMENT}
`
export const CARD_SVG_FRAGMENT = gql`
  fragment CardSvg on CardSvg {
    ordoRoleId
    name
    exportName
    keyIndex
    hymn {
      svgs
      midi { ...MeiMidi }
    }
    defaultTranspose
    antiphon {
      svgs
      midi { ...MeiMidi }
      title
      subtitle
      supertitle
    }
    antiphonVerseMidi { ...MeiMidi }
    verses
    versesMidi { ...MeiMidi }
    verseGabc
    musicalNotation
    verseCount
    versesContainMultipleStanzas
    textVerses
    error
    verseError
    refrain
    refrainAtBeginning
    referenceNumber
    antiphonMode
    antiphonHasChordSymbols
    antiphonHasHarmonization
    verseHasHarmonization
    additionalAntiphonText
    hymnHasSuppressedStems
    audioUrls
  }
  ${MEI_MIDI_FRAGMENT}
`

export const CARD_FRAGMENT = gql`
  fragment OrdoCard on OrdoCard {
    id
    updatedAt
    name
    sortPosition
    enabled
    type
    originalType
    permissionSet {
      ...PermissionSet
    }
    textCategory {
      id
      name
      isInvariableOrdinary
      resourceGroupType {
        ...ResourceGroupType
      }
    }
    parent {
      id
    }
    stepId
    step {
      id
      textCategory {
        id
        name
      }
    }
    config {
      ...OrdoCardConfig
    }
    roleConfigs {
      ordoRole {
        ...OrdoRole
      }
      config {
        notation
        displayHarmonizations
        displayChordSymbols
        verseStyle
        verseCount
        includeGloryBe
      }
    }
    isConfigured
    ordoId
    attributes {
      title
      attributes {
        name
        value
        isSystem
      }
    }
    ...OrdoCardSummary
  }
  ${RESOURCE_GROUP_TYPE_FRAGMENT}
  ${ORDO_CARD_CONFIG_FRAGMENT}
  ${ORDO_ROLE_FRAGMENT}
  ${PERMISSION_SET_FRAGMENT}
  ${CARD_SUMMARY_FRAGMENT}
`;
export const CARD_FRAGMENT_WITH_MEI = gql`
  fragment OrdoCardWithMei on OrdoCard {
    ...OrdoCard
    mei {
      ...CardMei
    }
  }
  ${CARD_FRAGMENT}
  ${CARD_MEI_FRAGMENT}
`;
export const CARD_FRAGMENT_SVG = gql`
  fragment OrdoCardSvg on OrdoCard {
    svg {
      ...CardSvg
    }
  }
  ${CARD_SVG_FRAGMENT}
`;
export const CARD_SHARE_FRAGMENT = gql`
  fragment OrdoCardShare on OrdoCard {
    ...OrdoCard
    selectedTextOption {
      ...TextOptionWithTextMeta
    }
    note {
      ...Note
    }
  }
  ${CARD_FRAGMENT}
  ${TEXT_OPTION_WITH_META_FRAGMENT}
  ${NOTE_FRAGMENT}
`;
export const CARD_WITH_SUMMARY_FRAGMENT = gql`
  fragment OrdoCardWithSummary on OrdoCard {
    id
    updatedAt
    name
    type
    textCategory {
      id
      name
    }
    parent {
      id
    }
    ...OrdoCardSummary
  }
  ${CARD_SUMMARY_FRAGMENT}
`;
