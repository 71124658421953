import { gql } from '@apollo/client';
import { Nullable } from 'utils/typescript/Nullable';

export type LayoutFileConfig = Partial<Nullable<LayoutFileConfigBase>>;

export type LayoutFileConfigInput = LayoutFileConfig;

type LayoutFileConfigBase = {
  id: string;
  includeOutline: boolean;
  pageNumbers: boolean;
  breakEveryCard: boolean;
  contentSize: -2 | -1 | 0 | 1 | 2;
  spacingVertical: -2 | -1 | 0 | 1 | 2;
  spacingHorizontal:  -2 | -1 | 0 | 1 | 2;
  liturgy: string;
  parish: string;
  subtitle: string;
  displayDate: boolean;
  attributions: string;
  attributionFontSize: number;
  breakBeforeAttributions: boolean;
  triFoldAccordion: boolean;
};

export const LAYOUT_FILE_CONFIG_FRAGMENT = gql`
  fragment LayoutFileConfig on LayoutFileConfig {
    id
    includeOutline
    pageNumbers
    breakEveryCard
    contentSize
    spacingVertical
    spacingHorizontal
    liturgy
    parish
    subtitle
    displayDate
    attributions
    attributionFontSize
    breakBeforeAttributions
    triFoldAccordion
  }
`;

export type LayoutCardConfig = Partial<Nullable<LayoutCardConfigBase>>;
export type LayoutCardConfigInput = LayoutCardConfig;
export type AlignmentString = 'left' | 'right' | 'center';

type LayoutCardConfigBase = {
  id: string;
  /**
   * width of image as fraction of contentWidth (between 0 and 1)
   */
  width: number;
  alignment: AlignmentString;
  pageBreakBefore: boolean;
};

export const LAYOUT_CARD_CONFIG_FRAGMENT = gql`
  fragment LayoutCardConfig on LayoutCardConfig {
    id
    width
    alignment
    pageBreakBefore
  }
`;
