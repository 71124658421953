import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useToast } from 'hooks/useToast';
import { PerkItem } from 'components/registration/PerkItem';
import { PricingCardCopy } from 'utils/select-plan/page-defs/SelectPlanPageDef';
import { useIdQuery } from 'hooks/queries/useQuery';
import { GetAddon, GetPlan } from 'queries/plan';
import { Group } from 'queries/group';
import { parishSizeQuantities } from 'utils/forms/options/parishSizes';
import { createDataGtagHandler, EndOfTrialConversion, getGACode } from 'utils/gtag';
import { PlanId, AddOnId } from 'utils/api-types/plans';
import { Spinner } from 'components/shared/Spinner';

type PerkPricingCardProps = {
  cardClass?: string;
  group?: Group;
  isYearly?: boolean;
  selectPlanId: (isYearly?: boolean) => PlanId | undefined;
  Copy: PricingCardCopy;
  addonId?: AddOnId;
  addonQuantity?: number;
  isUpgrade?: boolean;
} & ({
  isEndOfTrial?: false;
  pageDefinitionName?: undefined;
} | {
  isEndOfTrial: true;
  pageDefinitionName: string;
});

export const PricingCard: React.FC<PerkPricingCardProps> = ({
  cardClass = '',
  group,
  isYearly,
  selectPlanId,
  addonId,
  addonQuantity,
  Copy,
  isEndOfTrial = false,
  isUpgrade = false,
  pageDefinitionName,
}) => {
  const keycloakGroup = group?.keycloakGroup;
  const parishSize = keycloakGroup?.parishSize;
  const quantity = parishSize ? parishSizeQuantities[parishSize] : undefined;
  const isKnownQuantity = !!quantity;

  const onError = useToast('error');
  const [planId, setPlanId] = useState<PlanId | undefined>(selectPlanId(isYearly));
  const { loading: loadingPlan, error: planError, data: planData, refetch: refetchPlan } = useIdQuery(GetPlan, planId);
  const { loading: loadingAddon, error: addonError, data: addonData } = useIdQuery(GetAddon, addonId);

  const plan = planData?.plan ?? undefined;
  const addon = addonData?.addon ?? undefined;
  const hasComingSoon = Copy.PerkItems.some(item => item.comingSoon);

  useEffect(() => {
    if (planError || addonError) {
      onError('Unable to load plan details at this time. Please contact support or try again later');
      console.error('Unable to load plan details', {
        group,
        planId,
        addonId,
        planError,
        addonError,
      })
    }
  }, [planError, addonError, group, planId, addonId, onError]);

  useEffect(() => {
    setPlanId(selectPlanId(isYearly));
  }, [isYearly, selectPlanId]);

  useEffect(() => {
    refetchPlan({ id: planId });
  }, [planId, refetchPlan]);

  return (
    <Card className={cardClass}>
      <Card.Body className='pt-20px-imp d-flex flex-column justify-content-between'>
        <div>
          {Copy.SpecialOfferText && <div className='position-absolute background-icon-green fw-semibold px-2 py-2px rounded-5 color-white text-18' style={{
            top: 0,
            right: 10,
            transform: 'translateY(-50%)'
          }}>
            <Copy.SpecialOfferText />
          </div>}
          {!(group || loadingPlan || loadingAddon) && <div className='text-primary text-center mb-4'>
            <Spinner size='30px' />
            <span className="sr-only"><Copy.Loading /></span>
          </div>}

          {group && Copy.PlanName && <Copy.PlanName />}

          {group && plan && Copy.PlanPrice && <div className='mb-15'>
            <Copy.PlanPrice
              plan={plan}
              isYearly={isYearly}
              parishSize={parishSize}
            />
          </div>}

          {plan && Copy.PriceSubText && <div className='discount-block mb-20px'>
            <Copy.PriceSubText
              plan={plan}
              isYearly={isYearly}
              parishSize={parishSize}
            />
          </div>}

          {Copy.PerkItems.map((perk, i) => <PerkItem
            icon={perk.icon} 
            key={i}
            copy={perk.copy}
            className='mb-10'
          />)}

          {hasComingSoon && <p style={{textAlign: 'left', margin: 0}}>* Coming soon</p>}

          {Copy.PostPerkItemsText && <Copy.PostPerkItemsText />}
        </div>

        <div className='mt-30px' onClick={isEndOfTrial && planId
        ? createDataGtagHandler(EndOfTrialConversion, e => {
            return {
              // pageDefinition is only defined if isEndOfTrial is true
              page_definition: pageDefinitionName!,
              plan_id: planId,
              // code tells us the button type in this case
              method: getGACode(e) ?? '',
            };
          })
        : undefined
        }>
          {((plan || addon) && isKnownQuantity && Copy.ChargebeeButton) ? <Copy.ChargebeeButton
            group={group}
            plan={plan}
            addon={addon}
            addonQuantity={addon ? addonQuantity || quantity : undefined}
            isYearly={isYearly ?? false}
            isUpgrade={isUpgrade}
          /> : <Copy.ContactSalesButton
            group={group}
          />}
        </div>
      </Card.Body>
    </Card>
  )
};
