import React from 'react';
import { TextSource } from './TextSource';
import { Regular } from './renderer/Regular';
import { processPlainText, processText } from 'utils/markup';
import { css, cx } from '@emotion/css';

interface AlleluiaTranslationProps {
  text?: string;
  scriptureReference?: string;
  sourceNames?: string[];
  antiphon?: string;
  antiphonBefore?: string;
  isLentenGospelAcclamation?: boolean;
  className?: string;
}

export const AlleluiaTranslation: React.FC<AlleluiaTranslationProps> = ({
  text,
  scriptureReference,
  sourceNames,
  antiphon,
  antiphonBefore,
  isLentenGospelAcclamation = false,
  className = '',
}) => {
  const plainAntiphon = processText(processPlainText(antiphon));
  const plainAntiphonBefore = processText(processPlainText(antiphonBefore)) || plainAntiphon;
  const plainVerse = processPlainText(text);
  const processedVerse = processText(plainVerse);
  const verse = (antiphon || isLentenGospelAcclamation)
    ? <Regular
    text={plainVerse}
    textInterjected={plainAntiphon}
    textInterjectedBefore={plainAntiphonBefore}
    showAll={true}
    className={className}
  />
    : <span>{processedVerse}</span>

  return (
    <div className={cx('alleluia-translation', style)}>
      <TextSource
        scriptureReference={scriptureReference}
        showCanonicalSource={!!sourceNames?.length}
        sourceNames={sourceNames}
      />

      {verse}

    </div>
  );
}

const style = css`
  p, ul, ol, li {
    margin: 0;
  }

  // this rule sets spacing around the verse
  .liturgical-text > span + span > ul {
    padding-top: 15px;
  }
`;
