import { css, cx } from '@emotion/css';
import parse from 'html-react-parser';
import React from 'react';
import { colorDictionary } from 'utils/styles';
import { fontSans, fontSerif } from 'utils/styles/font';

interface NotesCardViewerProps {
  onClick?: () => void;
  content?: string;
  editable: boolean;
  maxCharacters?: number;
};

export const NotesCardViewer: React.FC<NotesCardViewerProps> = ({
  onClick,
  content,
  editable
}: NotesCardViewerProps) => {
  if (!content) {
    return <div className='notes-card-viewer'></div>
  }

  return <div className={cx('notes-card-viewer', style)}>
    <div
      onClick={onClick}
      className={cx(style, editable && 'clickable-text-block')}
    >{parse(content)}</div>
  </div>;
};

const style = css({
  fontFamily: fontSerif,
  fontSize: 18,
  whiteSpace: 'pre-wrap',
  '.notes-card-viewer-show-more': {
    cursor: 'pointer',
    marginTop: '0.25rem',
    color: colorDictionary['Primary'],
    fontFamily: fontSans,
    fontSize: 14,
  }
});
