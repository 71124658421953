import { ScreenSize } from 'hooks/useScreenSize'

const breakpointMap: { [key: number]: number } = {
  [ScreenSize.XXL]: 1400,
  [ScreenSize.XL]: 1200,
  [ScreenSize.LG]: 1025,
  [ScreenSize.ONEK]: 1000,
  [ScreenSize.MD]: 800,
  [ScreenSize.SM]: 600,
  [ScreenSize.XS]: 0,
};

export const mediaQueryString = (screenSize: ScreenSize | number, breakDirection: 'min' | 'max' = 'min') => {
  // This needs to happen so if you use both break directions, you don't have an awkward width where both styles activate
  return `@media (${breakDirection}-width: ${(breakpointMap[screenSize] ?? screenSize) - (breakDirection === 'max' ? 1 : 0)}px)`;
};
