import React, { StrictMode } from 'react';
import shimArrayAt from 'utils/shim-array-at';
import { createRoot } from 'react-dom/client';
import App from './App';
import { sentryDSN } from 'utils/env';
import { loadReactPdf } from 'utils/react-pdf';

// style imports
import './scss/theme.scss';
import 'react-calendar/dist/Calendar.css';
import 'swiper/css';

import * as serviceWorker from './serviceWorker';

// just so that it actually imports the shim:
console.info(shimArrayAt);

loadReactPdf().then(({ pdfjs }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;
});

if (sentryDSN && !window.location.pathname.startsWith('/export/')) {
  import('@sentry/react').then(Sentry => Sentry.init({ dsn: sentryDSN }));
}

createRoot(document.getElementById('root')!).render(<StrictMode><App/></StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
