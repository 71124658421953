import { Text } from "queries/text";

export const splitRubricsAndTexts = (texts: Text[]): [Text[], Text[]] =>
  texts.reduce(
    ([rubrics, readings]: [Text[], Text[]], text) =>
      text.contentType.name === 'Rubric'
        ? [[...rubrics, text], readings]
        : [rubrics, [...readings, text]],
    [[], []],
  );
