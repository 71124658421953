import { getQueryParams } from './useQueryParams';

export const getQueryParam = <T extends string | string[] | null>(
  paramName: string,
): T | undefined => {
  const params = getQueryParams();

  try {
    const param = params[paramName];
    const convertedParam = param as T;
    return convertedParam;
  } catch {
    return undefined;
  }
};

export const isAutoExportQueryParam = () =>
  getQueryParam('export') !== undefined;
