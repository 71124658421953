import { useMemo } from 'react';
import type { VerovioOptions } from 'react-verovio';
import { useDefaultVerovioOptions } from './useDefaultVerovioOptions';

const antiphonConfig: VerovioOptions = {
  lyricTopMinMargin: 3.5,
};
const chantConfig: VerovioOptions = {
  spacingLinear: 0.29,
}
const antiphonConfigWithHarmonization: VerovioOptions = {
  ...antiphonConfig,
  defaultBottomMargin: 5,
}
export type DefaultVerovioOptionsArgument = {
  extraConfig?: VerovioOptions;
  isFullScreen?: boolean;
  showHarmonizations?: boolean | null;
  cardHarmonizations?: boolean | null;
  addGrandStaff?: boolean;
  isChant?: boolean;
  spacingVertical?: number | null;
};
export const useDefaultVerovioOptionsAntiphon = ({
  extraConfig,
  isFullScreen = false,
  showHarmonizations = false,
  cardHarmonizations = false,
  addGrandStaff = false,
  isChant = true,
  spacingVertical,
}: DefaultVerovioOptionsArgument) => {
  const hasGrandStaff = showHarmonizations || addGrandStaff;
  const addonConfig = useMemo(() => ({
    ...(hasGrandStaff ? antiphonConfigWithHarmonization : antiphonConfig),
    ...(isChant ? chantConfig : {}),
    ...extraConfig,
  }), [extraConfig, hasGrandStaff, isChant]);
  return useDefaultVerovioOptions(addonConfig, isFullScreen, showHarmonizations, cardHarmonizations, addGrandStaff, spacingVertical);
};
