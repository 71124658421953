import { addLinesForPdfBlock } from './addLinesForPdfBlock';
import { GospelCross } from './GospelCross';
import { initSpan } from './initSpan';
import { PdfBlock, PdfTextBlock, PdfTextColumnsBlock } from './PdfBlockTypes';
import { setFontSizeWithRestore, setFontWithRestore } from './setFontWithRestore';

export interface PDFDocumentWithColumns extends PDFKit.PDFDocument {
  column?: {
    y: number;
    endY: number;
    index: number;
    count: number;
    width: number;
    gap: number;
  }
  pageDimensions?: Pick<PDFKit.PDFPage, 'width' | 'height' | 'margins'>;
}
const textReplacement = { '☩': GospelCross } as const;

export interface PdfUnbreakableBlockGroup {
  blocks: PdfBlock[];
}
export interface MeasuredPdfBlockGroup extends PdfUnbreakableBlockGroup {
  /**
   * These are the subset of blocks that are in the column we're dealing with,
   * since sometimes there may be blocks at the beginning or end outside of that column
   */
  columnBlocks: PdfBlock[];

  /**
   * total height
   */
  height: number;

  /**
   * top margin of first block
   */
  top: number;

  /**
   * bottom margin of last block
   */
  bottom: number;

  /**
   * height of just the column we're dealing with
   */
  columnHeight: number;

  /**
   * height above the column we're dealing with
   */
  heightAboveColumns?: number;

  /**
   * height below the column we're dealing with
   */
  heightBelowColumns?: number;

  /**
   * top margin of first block in the column we're dealing with
   */
  columnTop: number;

  /**
   * bottom margin of last block in the column we're dealing with
   */
  columnBottom: number;
}

export const isPdfTextStyleBlock = (block: PdfBlock): block is (PdfTextBlock|PdfTextColumnsBlock) =>
  !!(block.spans || block.columnBlocks);

export const isBlockBreakableAtColumn = (block: PdfBlock, nextBlock?: PdfBlock) => {
  if (block.height === 0) return false;
  const isBreak = block.columnBreakAfter;
  const result = isBreak !== false;
  if (isBreak === undefined && (!block.columns || !nextBlock?.columns)) {
    return block.pageBreakAfter !== false;
  }
  return result;
}
export const isBlockBreakableAtPage = (block: PdfBlock) =>
  block.pageBreakAfter !== false && block.height !== 0;

export const splitAtBreakableColumns = (blocks: PdfBlock[]) =>
  blocks.reduceRight<PdfUnbreakableBlockGroup[]>((result, block) => {
    const lastBlockGroup = result[0];
    if (!lastBlockGroup || isBlockBreakableAtColumn(block, lastBlockGroup?.blocks[0])) {
      result.unshift({ blocks: [block] })
    } else {
      lastBlockGroup.blocks.unshift(block);
    }
    return result;
  }, [])

/**
 * To get the page dimensions when a page has not yet been created, we need to
 * use some special tricks.
 * @param pdf pdf document
 * @returns the width of the page and left and right margins
 */
export const getPdfWidthAndMargins = (pdf: PDFKit.PDFDocument) => {
  if (pdf.page) {
    return {
      width: pdf.page.width,
      left: pdf.page.margins.left,
      right: pdf.page.margins.right,
    };
  } else {
    const dimensions =
      pdf.options.size instanceof Array ? pdf.options.size : [612, 792];
    const [left, right] =
      typeof pdf.options.margin === 'number'
        ? [pdf.options.margin, pdf.options.margin]
        : [pdf.options?.margins?.left ?? 72, pdf.options?.margins?.right ?? 72];
    return {
      width: dimensions[pdf.options.layout === 'landscape' ? 1 : 0],
      left,
      right,
    };
  }
}

/**
 * set font settings and document coordinates for a new block,
 * and run layout on the spans within the block if necessary
 * @param pdf pdf document
 * @param block block that is beginning
 * @param margins block margins
 * @param initializeColumns whether to initialize the column state object in the pdf document.  if set to 'center' columns will be initalized such that there will be one centered column instead of two
 * @returns { x: starting document X, width: contentWidth, endBlock: function to close the block }
 */
function initializeBlock(
  pdf: PDFDocumentWithColumns,
  block: PdfTextBlock | PdfTextColumnsBlock,
  margins = block.margins,
  initializeColumns: boolean | 'center' = false,
) {
  let restoreFontSize: (() => void) | undefined;
  let restoreFontFamily: (() => void) | undefined;
  let restorePdfY: (() => void) | undefined;
  let pdfPage = getPdfWidthAndMargins(pdf);

  let column = pdf.column;
  if (!column && block.columns) {
    const { count, gap } = block.columns;
    const { width, left, right } = pdfPage;
    column = {
      y: pdf.y + (margins?.top ?? 0),
      endY: pdf.y,
      count,
      gap,
      index: initializeColumns === 'center' ? 0.5 : 0,
      width: (width - left - right - (gap * (count - 1))) / count,
    };
    if (initializeColumns) {
      pdf.column = column;
    }
  } else if (column && !block.columns) {
    delete pdf.column;
    column = undefined;
  }
  if (column) {
    const { index, count, width, gap } = column;
    const countLeft = index;
    const countRight = count - index - 1;
    pdfPage.left = pdfPage.left + (countLeft * (width + gap));
    pdfPage.right = pdfPage.right + (countRight * (width + gap));
  }

  pdf.x = pdfPage.left + (margins?.left || 0);
  const contentWidth = pdfPage.width - pdf.x - pdfPage.right - (margins?.right || 0);
  if ('x' in block && block.x) {
    pdf.x += block.x;
    if (block.x < 0) {
      pdf.x += contentWidth;
    }
  }
  const { x, y } = pdf;
  if (block.continuedFrom) {
    pdf.y -=
      (block.continuedFrom.height ?? 0) +
      (block.continuedFrom.margins?.bottom ?? 0);
  }
  pdf.y += margins?.top ?? 0;
  const width =
    block.width ??
    contentWidth;

  if (block.fontSize) {
    restoreFontSize = setFontSizeWithRestore(pdf, block.fontSize);
  }
  if (block.fontFamily || block.fontOptions) {
    restoreFontFamily = setFontWithRestore(pdf, block);
  }
  if ('rowParent' in block) {
    restorePdfY = () => pdf.y = y;
  }
    
  if ('columnBlocks' in block) {
    // make sure that any float right columns come first
    // so that they will get measured first
    const { columnBlocks } = block;
    const floatRightIndex = columnBlocks.findIndex(block => block.float === 'right');
    if (floatRightIndex < 0) {
      // check if the last block has no spans / text and remove it if so.
      // this is a hack to get rows in the outline without tags working quickly
      const lastBlock = columnBlocks.pop();
      if (lastBlock?.spans.some(({ text }) => text)) {
        columnBlocks.push(lastBlock);
      }
    } else if (floatRightIndex > 0) {
      const floatRights = columnBlocks.splice(floatRightIndex).reverse();
      columnBlocks.unshift(...floatRights);
      const widths = floatRights.map(floatRight => floatRight.spans.map(span => {
        // TODO: This should be done a better way; for now this is just a hack to vertically position the tags in the ordo outline correctly:
        if (floatRight.borderPadding) span.yOffset = -floatRight.borderPadding * 2/3;
        const restoreFontSize = span.fontSize ? setFontSizeWithRestore(pdf, span.fontSize) : null;
        const result = pdf.widthOfString(span.text, {
          ...block.options,
          ...floatRight.options,
          ...span.options,
          width: width * 0.25,
        });
        restoreFontSize?.();
        return result;
      }));
      let floatRightOffset = 0;
      floatRights.forEach((floatRight, i) => {
        floatRight.width = widths[i].reduce((a, b) => a + b, 0);
        if (floatRight.width) {
          floatRight.width += 2 * ((floatRight.borderPadding ?? 0) + (floatRight.borderWidth ?? 0));
        }
        floatRightOffset -= floatRight.width
        floatRight.x = floatRightOffset;
        floatRightOffset -= floatRight.margins?.left ?? 0;
      });
    }
    let availableWidth = width - columnBlocks.reduce((sum, { margins: { left = 0, right = 0 } = {} }) => sum + left + right, 0);
    const widths = columnBlocks.filter(block => !block.autoWidth && typeof block.width === 'number').map(block => block.width!);
    const remainingWidth = widths.reduce((remainingWidth, width) => remainingWidth - width, availableWidth);

    const blocksWithoutWidth = columnBlocks.filter(block => block.autoWidth || typeof block.width !== 'number');
    const blockCount = blocksWithoutWidth.length;
    const remainingWidthPerBlock = remainingWidth / blockCount;
    blocksWithoutWidth.forEach(block => {
      block.autoWidth = true;
      block.width = remainingWidthPerBlock;
    });
    // set x offset of each column
    let x = 0;
    columnBlocks.forEach(colBlock => {
      if (colBlock.float === 'right') return;
      colBlock.x = x;
      x += colBlock.width! + (colBlock.margins?.right ?? 0);
    });
  }
  
  const endBlock = () => {
    restoreFontSize?.();
    restoreFontFamily?.();
    if (block.continuedFrom) {
      // check if the block height needs to be adjusted:
      const prevBlockHeight = block.continuedFrom.height ?? 0;
      const blockHeight = block.height ?? 0;
      if (blockHeight < prevBlockHeight) {
        const difference = prevBlockHeight - blockHeight;
        if (difference > 0) pdf.y += difference;
      }
    }
    pdf.y += margins?.bottom ?? 0;
    pdf.x = pdfPage.left;
    restorePdfY?.();
    if ('columnBlocks' in block && block.height) {
      pdf.y += block.height;
    }
  };
  if ('spans' in block) {
    addLinesForPdfBlock(pdf, block, width);
  }
  return { x, y, width, endBlock };
}

export function heightOfFormattedText(
  pdf: PDFKit.PDFDocument,
  block: PdfTextBlock | PdfTextColumnsBlock,
  includeMargins = true,
) {
  const { x, y } = pdf;
  const { width, endBlock } = initializeBlock(pdf, block);
  let result = 0;
  if (block.height === 0) {
    result = 0;
  } else if (block.height && block.contentWidth === width) {
    result = block.height;
  } else if ('columnBlocks' in block) {
    for (const colBlock of block.columnBlocks) {
      result = Math.max(result, heightOfFormattedText(pdf, colBlock));
    }
    block.height = result;
    block.contentWidth = width;
  } else {
    for (const span of block.spans) {
      const { cleanup } = initSpan(pdf, span, block);
      if (span.text) {
        result += pdf.heightOfString(span.text, {
          ...block.options,
          ...span.options,
          width,
        });
      }
      cleanup();
    }
    block.height = result;
    block.contentWidth = width;
  }
  endBlock();
  pdf.x = x;
  pdf.y = y;
  if (includeMargins) {
    result += (block.margins?.top ?? 0) + (block.margins?.bottom ?? 0);
  }
  if (block.continuedFrom) {
    // handle the oddities of continued blocks:
    // the height of the continued block must be only any additional height incurred from it
    // rather than its actual height, since the heights are all just added together
    const prevHeight =
      (block.continuedFrom.height ?? 0) +
      (includeMargins
        ? (block.continuedFrom.margins?.top ?? 0) +
          (block.continuedFrom.margins?.bottom ?? 0)
        : 0);
    result = Math.max(result, prevHeight) - prevHeight;
  }
  return result;
}

/**
 * This function is designed to be just like drawFormattedText, but it only advances the pdf.y variable
 * as much as would be advances when drawing formatted text
 * @param pdf 
 * @param block 
 * @param destinations 
 * @param overrideMargins 
 * @param centerColumn 
 */
export function advancePdfYForFormattedText(
  pdf: PDFKit.PDFDocument,
  block: PdfTextBlock | PdfTextColumnsBlock,
  destinations?: Set<string>,
  overrideMargins?: PdfTextBlock['margins'],
  centerColumn?: boolean,
) {
  const { width, endBlock } = initializeBlock(pdf, block, {
    ...block.margins,
    ...overrideMargins,
  }, centerColumn ? 'center' : true);
  if ('columnBlocks' in block) {
    for (const colBlock of block.columnBlocks) {
      advancePdfYForFormattedText(pdf, colBlock, destinations);
    }
  } else if (block.lines) {
    pdf.y += block.height || block.lines.reduce((total, { height }) => total + height , 0);
  } else {
    for (const span of block.spans) {
      if (span.text) {
        const lineBreak = block.height !== 0;
        pdf.y += pdf.heightOfString(span.text, {
          ...block.options,
          ...span.options,
          width: lineBreak ? width : undefined,
          lineBreak,
          underline: false,
        });
      }
    }
  }
  endBlock();
}

/**
 * draw formatted text into the document
 * @param pdf document
 * @param block block to draw
 * @param overrideMargins if you need to override the margins
 * @param centerColumn true if you want the column initialized to be centered horizontally
 */
export function drawFormattedText(
  pdf: PDFKit.PDFDocument,
  block: PdfTextBlock | PdfTextColumnsBlock,
  destinations?: Set<string>,
  overrideMargins?: PdfTextBlock['margins'],
  centerColumn?: boolean,
) {
  const { x, y, width, endBlock } = initializeBlock(pdf, block, {
    ...block.margins,
    ...overrideMargins,
  }, centerColumn ? 'center' : true);
  if ('columnBlocks' in block) {
    for (const colBlock of block.columnBlocks) {
      drawFormattedText(pdf, colBlock, destinations);
    }
  } else if (block.lines) {
    const height = block.height || block.lines.reduce((total, { height }) => total + height , 0);
    const totalHeight = pdf.y - y + height;
    if (height && width && (block.borderWidth || block.backgroundColor)) {
      const borderWidth = block.borderWidth ?? 1;
      pdf.roundedRect(x, y + borderWidth, width - borderWidth, totalHeight - borderWidth, block.borderRadius);
      if (borderWidth) {
        pdf.lineWidth(borderWidth).stroke();
      } else if (block.backgroundColor) {
        pdf.fill(block.backgroundColor).fillColor([0, 0, 0, 100]);
      }
    }
    const align = block.options?.align ?? 'left';
    for (const line of block.lines) {
      const rightMargin = block.margins?.right ?? 0;
      const offset =
        align === 'right'
          ? width - line.width - rightMargin
          : align === 'center'
          ? (width - line.width - rightMargin) / 2
          : (block.borderPadding ?? 0) + (block.borderWidth ?? 0);
      pdf.x = x + offset + (line.dx || 0);
      for (const span of line.spans) {
        const { cleanup, drawDecorations } = initSpan(pdf, span, block, destinations);
        if (span.text?.length === 1 && span.text in textReplacement) {
          const symbol = textReplacement[span.text as keyof typeof textReplacement];
          const h = 0.6 * pdf._fontSize;
          const y = pdf.y - h + (pdf._font.ascender * pdf._fontSize / 1000);
          pdf.path(symbol.pathFor({ x: pdf.x, y, h })).fill();
          pdf.x += symbol.width * h / symbol.height;
        } else if (span.text) {
          const options = {
            ...block.options,
            ...span.options,
            align: 'left',
            lineBreak: false,
            // pdfkit underline and link are not working, so we're handling these below with drawDecorations (see https://github.com/foliojs/pdfkit/issues/719)
            underline: false,
            link: undefined,
            goTo: undefined,
          } as const;
          // This is necessary, because of a bug in pdfkit in which it ignores the options passed to pdf.text() when measuring the width
          const newX = span.options?.features
            ? pdf.x + pdf.widthOfString(span.text, options)
            : null;
          pdf.text(span.text, options);
          if (newX) {
            pdf.x = newX;
          }
        }
        drawDecorations();
        cleanup();
      }
      pdf.y += line.height;
    }
  } else {
    for (const span of block.spans) {
      const { cleanup, drawDecorations } = initSpan(pdf, span, block);
      if (span.text) {
        const lineBreak = block.height !== 0;
        pdf.text(span.text, {
          ...block.options,
          ...span.options,
          width: lineBreak ? width : undefined,
          lineBreak,
          underline: false,
        });
      }
      drawDecorations();
      cleanup();
    }
  }
  endBlock();
}
