/* eslint-disable @typescript-eslint/no-redeclare */
import { gql, makeReference } from '@apollo/client';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { LAYOUT_CARD_CONFIG_FRAGMENT, LAYOUT_FILE_CONFIG_FRAGMENT, LayoutCardConfig, LayoutCardConfigInput, LayoutFileConfig, LayoutFileConfigInput } from 'utils/api-types/layout/LayoutFileConfig';
import type { OrdoCardConfig, OrdoCardConfigInput } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { File } from 'utils/api-types/resource';
import { CARD_FRAGMENT_WITH_MEI, OrdoCardWithMei } from './card-fragment';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';
import { LayoutDimensions, PartialLayoutDimensions } from './layout';
import { GetOrdo } from './ordo';
import { FILE_FRAGMENT } from './resource';

export type LayoutOrdoCardConfig = {
  id: number;
  cardId: number;
  properties: Pick<
    OrdoCardConfig,
    | 'notation'
    | 'displayChordSymbols'
    | 'displayHarmonizations'
    | 'verseColumns'
    | 'notateFirstVerses'
    | 'spacingHorizontal'
    | 'spacingVertical'
    | 'pageBreakBefore'
    | 'includeGloryBe'
    | 'verseId'
    | 'verseStyle'
    | 'readingDisplay'
    | 'textDisplay'
    | 'showAcclamations'
  >;
};
export type LayoutCard = {
  id: number;
  sortPosition: number;
  name: string;
  file: File;
  config: LayoutCardConfig;
}
export type LayoutFileSummary = {
  ordoId: number;
  thumbnail: File;
  format: string;
} & WithNameAndId & PageDimensions;

export type LayoutFile = {
  ordo: { id: number; cards: { id: number; sortPosition: number }[] };
  disabledCardIds?: number[] | null;
  config: LayoutFileConfig;
  cardConfigOverrides: LayoutOrdoCardConfig[];
  cards: LayoutCard[];
} & LayoutFileSummary;

export type PageDimensionsPage = {
  pageWidth: number;
  pageHeight: number;
  columns: 1 | 2 | 3;
} & MarginDimensions;
export type PageDimensions = PageDimensionsPage | PageDimensionsImage;
export type MarginDimensions = {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  marginLeftHarmonized?: number | null;
  marginRightHarmonized?: number | null
};
export type PageDimensionsImage = {
  pageWidth: number;
  pageHeight?: null;
  columns?: 1;
  marginTop?: null;
  marginBottom?: null;
  marginLeft?: null;
  marginRight?: null;
  marginLeftHarmonized?: null;
  marginRightHarmonized?: null
}
export const isImageDimensions = (
  dimensions?: Pick<PageDimensions, 'pageHeight'|'pageWidth'>,
): dimensions is PageDimensionsImage =>
  !!dimensions?.pageWidth && (dimensions.pageHeight ?? null) === null;


export const LAYOUT_FILE_SUMMARY_FRAGMENT = gql`
  fragment LayoutFileSummary on LayoutFile {
    id
    name
    ordoId
    format
    pageWidth
    pageHeight
    columns
    marginTop
    marginBottom
    marginLeft
    marginRight
    marginLeftHarmonized
    marginRightHarmonized
    thumbnail {
      id
      name
      url
      awsKey
    }
  }
`;
export const LAYOUT_FILE_ORDO_CARDS = gql`
  fragment LayoutFileOrdoCards on LayoutFile {
    ordo {
      id
      cards: ordoCards {
        id
        sortPosition
      }
    }
  }
`;
export const LAYOUT_ORDO_CARD_CONFIG_FRAGMENT = gql`
  fragment LayoutOrdoCardConfig on LayoutOrdoCardConfig{
    id
    cardId
    properties {
      notation
      displayChordSymbols
      displayHarmonizations
      verseColumns
      notateFirstVerses
      spacingHorizontal
      spacingVertical
      pageBreakBefore
      includeGloryBe
      verseId
      verseStyle
      readingDisplay
      textDisplay
      showAcclamations
    }
  }
`;
export const LAYOUT_CARD_FRAGMENT = gql`
  fragment LayoutCard on LayoutCard {
    id
    sortPosition
    name
    file {
      ...File
    }
    config {
      ...LayoutCardConfig
    }
  }
  ${FILE_FRAGMENT}
  ${LAYOUT_CARD_CONFIG_FRAGMENT}
`;
export const LAYOUT_FILE_FRAGMENT = gql`
  fragment LayoutFile on LayoutFile {
    ...LayoutFileSummary
    ...LayoutFileOrdoCards
    disabledCardIds
    cardConfigOverrides {
      ...LayoutOrdoCardConfig
    }
    config {
      ...LayoutFileConfig
    }
    cards {
      ...LayoutCard
    }
  }
  ${LAYOUT_FILE_SUMMARY_FRAGMENT}
  ${LAYOUT_FILE_ORDO_CARDS}
  ${LAYOUT_ORDO_CARD_CONFIG_FRAGMENT}
  ${LAYOUT_FILE_CONFIG_FRAGMENT}
  ${LAYOUT_CARD_FRAGMENT}
`;

export interface GetLayoutFile extends GQLQuery {
  Variables: WithId;
  Data: {
    layoutFile: LayoutFile;
  };
}
export const GetLayoutFile: GQLDocument<GetLayoutFile> = {
  query: gql`
    query getLayoutFile($id: Float!) {
      layoutFile(id: $id) {
        ...LayoutFile
      }
    }
    ${LAYOUT_FILE_FRAGMENT}
  `,
};

export interface CreateLayoutFile extends GQLMutation<CreateLayoutFile, GetOrdo> {
  Variables: {
    dimensions: LayoutDimensions;
    ordoId: number;
  };
  Data: {
    createLayoutFile: {
      ordo: {
        layoutFiles: LayoutFileSummary[];
      } & WithId;
      layoutFileId: number;
    };
  };
}
export const CreateLayoutFile: GQLDocument<CreateLayoutFile> = {
  mutation: gql`
    mutation createLayoutFile($dimensions: LayoutDimensions!, $ordoId: Float!) {
      createLayoutFile(dimensions: $dimensions, ordoId: $ordoId) {
        ordo {
          id
          layoutFiles {
            ...LayoutFileSummary
          }
        }
        layoutFileId
      }
    }
    ${LAYOUT_FILE_SUMMARY_FRAGMENT}
  `,
};

export interface DeleteLayoutFile extends GQLMutation<DeleteLayoutFile> {
  Variables: { id: number };
  Data: {
    deleteLayoutFile: {
      layoutFiles: WithId[];
    } & WithId;
  };
}
export const DeleteLayoutFile: GQLDocument<DeleteLayoutFile> = {
  mutation: gql`
    mutation deleteLayoutFile($id: Float!) {
      deleteLayoutFile(id: $id) {
        id
        layoutFiles {
          id
        }
      }
    }
  `,
};

export interface RenameLayoutFile extends GQLMutation<RenameLayoutFile> {
  Variables: {
    id: number;
    name: string;
  };
  Data: {
    layoutFile: LayoutFileSummary;
  };
}
export const RenameLayoutFile: GQLDocument<RenameLayoutFile> = {
  mutation: gql`
    mutation renameLayoutFile($id: Float!, $name: String!) {
      layoutFile: renameLayoutFile(id: $id, name: $name) {
        ...LayoutFileSummary
      }
    }
    ${LAYOUT_FILE_SUMMARY_FRAGMENT}
  `,
};

export interface SetDisabledCardIds extends GQLMutation<SetDisabledCardIds> {
  Variables: {
    id: number;
    disabledCardIds: number[];
  };
  Data: {
    layoutFile: Pick<LayoutFile, 'id' | 'disabledCardIds'>;
  };
}
export const SetDisabledCardIds: GQLDocument<SetDisabledCardIds> = {
  mutation: gql`
    mutation setDisabledCardIds($id: Float!, $disabledCardIds: [Float!]!) {
      layoutFile: setDisabledCardIds(id: $id, disabledCardIds: $disabledCardIds) {
        id
        disabledCardIds
      }
    }
  `,
};

export interface UpdateLayoutFileDimensions extends GQLMutation<UpdateLayoutFileDimensions> {
  Variables: {
    layoutFileId: number;
    dimensions: PartialLayoutDimensions;
  };
  Data: {
    layoutFile: LayoutFile;
  }
}
export const UpdateLayoutFileDimensions: GQLDocument<UpdateLayoutFileDimensions> = {
  mutation: gql`
    mutation updateLayoutFileDimensions($layoutFileId: Float!, $dimensions: PartialLayoutDimensions!) {
      layoutFile: updateLayoutFileDimensions(id: $layoutFileId, dimensions: $dimensions) {
        ...LayoutFileSummary
        config {
          ...LayoutFileConfig
        }
      }
    }
    ${LAYOUT_FILE_SUMMARY_FRAGMENT}
    ${LAYOUT_FILE_CONFIG_FRAGMENT}
  `
};

export interface UpdateLayoutFileConfig extends GQLMutation<UpdateLayoutFileConfig> {
  Variables: {
    layoutFileId: number;
    configDiff: LayoutFileConfigInput;
  };
  Data: {
    layoutFileConfig: LayoutFileConfig;
  }
}
export const UpdateLayoutFileConfig: GQLDocument<UpdateLayoutFileConfig> = {
  mutation: gql`
    mutation updateLayoutFileConfig($layoutFileId: Float!, $configDiff: LayoutFileConfigInput!) {
      layoutFileConfig: updateLayoutFileConfig(layoutFileId: $layoutFileId, configDiff: $configDiff) {
        ...LayoutFileConfig
      }
    }
    ${LAYOUT_FILE_CONFIG_FRAGMENT}
  `
};

export interface UpdateCardConfigLayoutOverride extends GQLMutation<UpdateCardConfigLayoutOverride> {
  Variables: {
    layoutFileId: number;
    cardId: number;
    configDiff: OrdoCardConfigInput;
  };
  Data: {
    result: {
      layoutOrdoCardConfig: LayoutOrdoCardConfig;
      ordoCard: OrdoCardWithMei;
    }
  };
}
export const UpdateCardConfigLayoutOverride: GQLDocument<UpdateCardConfigLayoutOverride> = {
  mutation: gql`
    mutation updateCardConfigLayoutOverride($layoutFileId: Float!, $cardId: Float!, $configDiff: OrdoCardConfigInput!) {
      result: updateCardConfigLayoutOverride(layoutFileId: $layoutFileId, cardId: $cardId, configDiff: $configDiff) {
        layoutOrdoCardConfig {
          ...LayoutOrdoCardConfig
        }
        ordoCard {
          ...OrdoCardWithMei
        }
      }
    }
    ${LAYOUT_ORDO_CARD_CONFIG_FRAGMENT}
    ${CARD_FRAGMENT_WITH_MEI}
  `,
  updater: (cache, result, options) => {
    // Update the cache by adding the resulting card config override to the array of card config overrides on the layout file
    // in case it wasn't there
    const { data } = result;
    const { variables } = options;
    const newCardConfig = data?.result.layoutOrdoCardConfig;
    const newCardConfigId = newCardConfig && cache.identify(newCardConfig);
    if (newCardConfigId && variables) {
      cache.modify<LayoutFile>({
        id: cache.identify({ id: variables.layoutFileId, __typename: 'LayoutFile' }),
        fields: {
          cardConfigOverrides: (value, { readField }) => {
            const cardConfigIds = new Set((value ?? []).map(o => cache.identify(o)!));
            cardConfigIds.add(newCardConfigId);
            return Array.from(cardConfigIds).map(makeReference);
          }
        }
      });
    }
  }
};

export interface UpdateLayoutCardConfig extends GQLMutation<UpdateLayoutCardConfig> {
  Variables: {
    cardId: number;
    configDiff: LayoutCardConfigInput;
  };
  Data: {
    layoutCardConfig: LayoutCardConfig;
  };
}
export const UpdateLayoutCardConfig: GQLDocument<UpdateLayoutCardConfig> = {
  mutation: gql`
    mutation updateLayoutCardConfig($cardId: Float!, $configDiff: LayoutCardConfigInput!) {
      layoutCardConfig: updateLayoutCardConfig(cardId: $cardId, configDiff: $configDiff) {
        ...LayoutCardConfig
      }
    }
    ${LAYOUT_CARD_CONFIG_FRAGMENT}
  `
};
export interface RemoveLayoutCard extends GQLMutation<RemoveLayoutCard> {
  Variables: {
    cardId: number;
  };
  Data: {
    layoutFile: { id: number, cards: Pick<LayoutFile['cards'][number], 'id'>[] };
  };
}
export const RemoveLayoutCard: GQLDocument<RemoveLayoutCard> = {
  mutation: gql`
    mutation removeLayoutCard($cardId: Float!) {
      layoutFile: removeLayoutCard(cardId: $cardId) {
        id
        cards {
          id
        }
      }
    }
  `
};

export interface InsertImageInLayoutFile extends GQLMutation<InsertImageInLayoutFile> {
  Variables: {
    layoutFileId: number;
    imageFileId: number;
    sortPosition: number;
    config?: LayoutCardConfigInput;
  };
  Data: {
    layoutCard: LayoutCard;
  };
}
export const InsertImageInLayoutFile: GQLDocument<InsertImageInLayoutFile> = {
  mutation: gql`
    mutation insertImageInLayoutFile($layoutFileId: Float!, $imageFileId: Float!, $sortPosition: Float!, $config: LayoutCardConfigInput) {
      layoutCard: insertImageInLayoutFile(layoutFileId: $layoutFileId, imageFileId: $imageFileId, sortPosition: $sortPosition, config: $config) {
        ...LayoutCard
      }
    }
    ${LAYOUT_CARD_FRAGMENT}
  `,
  updater: (cache, result, options) => {
    // Update the cache by adding the resulting layout card to the array of cards on the layout file
    const { data } = result;
    const { variables } = options;
    const newCard = data?.layoutCard;
    const newCardId = newCard && cache.identify(newCard);
    if (newCardId && variables) {
      cache.modify<LayoutFile>({
        id: cache.identify({ id: variables.layoutFileId, __typename: 'LayoutFile' }),
        fields: {
          cards: (value) => {
            const newValue = (value ?? []).concat(newCard).sort((a,b) => {
              if (!('sortPosition' in a) || !('sortPosition' in b)) {
                return 0;
              }
              return a.sortPosition - b.sortPosition;
            });
            const cardIds = new Set(newValue.map(o => cache.identify(o)!));
            return Array.from(cardIds).map(makeReference);
          }
        }
      });
    }
  }
}

export interface UpdateImagePositionInLayoutFile extends GQLMutation<UpdateImagePositionInLayoutFile> {
  Variables: {
    cardId: number;
    sortPosition: number;
  };
  Data: {
    layoutCard: {
      id: number;
      sortPosition: number;
    };
  };
}
export const UpdateImagePositionInLayoutFile: GQLDocument<UpdateImagePositionInLayoutFile> = {
  mutation: gql`
    mutation updateImagePositionInLayoutFile($cardId: Float!, $sortPosition: Float!) {
      layoutCard: updateImagePositionInLayoutFile(cardId: $cardId, sortPosition: $sortPosition) {
        id
        sortPosition
      }
    }
  `,
  optimisticResponse: ({ cardId, sortPosition }) => {
    return {
      layoutCard: {
        __typename: 'LayoutCard',
        id: cardId,
        sortPosition,
      }
    }
  }
}
