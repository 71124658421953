/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithNameAndId } from 'utils/api-types/WithNameAndId';
import { ResourceGroupTypesSeedId } from 'utils/api-types/resource/ResourceGroupTypesSeedId';
import { GQLDocument, GQLQuery } from './gql-generics';
import { Attribute } from './resource';

export type ResourceReference = {
  reference: string;
  collection: {
    edition: string|null;
  } & WithNameAndId;
  attributes: Attribute[];
} & WithNameAndId;
export type ResourceReferenceWithAvailableData = {
  availableData: {
    uploadedContent: boolean;
  };
} & ResourceReference;

export const RESOURCE_REFERENCE_FRAGMENT = gql`
  fragment ResourceReference on ResourceReference {
    name
    id
    reference
    collection {
      name
      id
      edition
    }
    attributes {
      name
      id
      value
    }
  }
`;
// NOTE that using this fragment requires you to provide a groupId argument to any query using it
// e.g., see the `GetResourceReference` query defined below
export const RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID = gql`
  fragment ResourceReferenceWithGroupId on ResourceReference {
    ...ResourceReference
    availableData(groupId: $groupId) {
      uploadedContent
    }
  }
  ${RESOURCE_REFERENCE_FRAGMENT}
`;

export interface GetResourceReference extends GQLQuery {
  Variables: {
    id: number;
  }
  Data: { resourceReference: ResourceReference };
}
export const GetResourceReference: GQLDocument<GetResourceReference> = {
  query: gql`
    query resourceReference($id: Float!) {
      resourceReference(id: $id) {
        ...ResourceReference
      }
    }
    ${RESOURCE_REFERENCE_FRAGMENT}
  `
}

export interface GetResourceReferenceWithAvailableData extends GQLQuery {
  Variables: {
    id: number;
    groupId: string;
  }
  Data: { resourceReference: ResourceReferenceWithAvailableData };
}
export const GetResourceReferenceWithAvailableData: GQLDocument<GetResourceReferenceWithAvailableData> = {
  query: gql`
    query resourceReferenceWithAvailableData($id: Float!, $groupId: String!) {
      resourceReference(id: $id) {
        ...ResourceReferenceWithGroupId
      }
    }
    ${RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID}
  `
}

export interface SearchResourceReferences extends GQLQuery {
  Variables: {
    query: string;
    groupId: string;
    type?: ResourceGroupTypesSeedId;
    collectionId?: number;
    offset?: number;
    limit?: number;
  };
  Data: { resourceReferences: ResourceReference[] };
}
export const SearchResourceReferences: GQLDocument<SearchResourceReferences> = {
  query: gql`
    query searchReferences($query: String!, $groupId: String!, $type: String, $collectionId: Float, $offset: Float, $limit: Float) {
      resourceReferences: searchReferences(query: $query, type: $type, collectionId: $collectionId, offset: $offset, limit: $limit) {
        ...ResourceReferenceWithGroupId
      }
    }
    ${RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID}
  `
}
