import React from 'react';

interface CustomGQLErrorMessage {
  error: Error|undefined,
  headline: string;
}

export const ErrorMessage: React.FC<CustomGQLErrorMessage> = ({error, headline}) => {
  return (
    <div className='gql-error text-center alert alert-error my-15'>
      {headline ? (<h6>{headline}</h6>) : ''}
      <p>{error && error.message}</p>
    </div>
  );
};
