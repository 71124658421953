import { SaveCardConfigCallback } from 'hooks/ordo/cards/UpdateCardConfigCallback';
import { OrdoCardWithMeiOrSvg } from 'queries/card-fragment';
import { Note } from 'queries/note';
import { TextOptionWithMeta } from 'queries/text-option';
import React from 'react';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { CardState } from 'utils/ordo/card/CardState';

type CardViewState = string;

export type TextOptionType = 'proper' | 'seasonal' | 'eucharistic';

// TODO: Refactor this context and how it is provided. Move relevant hooks and methods etc off into a class or other module
export interface ICardContext {
  card: OrdoCardWithMeiOrSvg;
  config: OrdoCardConfig;
  isLastCard: boolean;
  textOptions: TextOptionWithMeta[] | null;
  // the presence or absence of a callback corresponding to a specific text option type indicates whether that option is available
  // calling the corresponding callback makes that type active
  optionType?: {
    [key in TextOptionType]?: () => void;
  } & {
    selected: TextOptionType;
  };
  cardState: CardState;
  currentView: () => CardViewState;
  nextView: (name: CardViewState) => CardViewState[];
  prevView: () => CardViewState[];
  updateView: (viewStack: CardViewState[]) => void;
  saveConfig: SaveCardConfigCallback;
  addGrandStaff?: boolean;
  showRubrics?: boolean;
  onLoaded: () => void;
  openExportModal?: (cardId: number) => void;
  note?: Note;
  textSize?: number;
}

export const CardContext = React.createContext<ICardContext>({
  card: {} as OrdoCardWithMeiOrSvg,
  config: {} as OrdoCardConfig,
  isLastCard: false,
  textOptions: [],
  cardState: {
    viewStack: [] as string[],
  },
  currentView: (): string => '',
  nextView: (name: string) => [] as string[],
  prevView: () => [] as string[],
  updateView: (viewStack: string[]) => {},
  saveConfig: async () => {},
  addGrandStaff: false,
  onLoaded: () => {},
  openExportModal: () => {},
  note: {} as Note,
} as ICardContext);
