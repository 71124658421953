import React from 'react';

import { PlainTextTranslation } from 'components/ordo/shared/text/PlainTextTranslation';

interface PlainTextVerseProps {
  verses: string[];
  verseClassName?: string;
  includeGloryBe?: boolean | null;
  columns?: number | null;
}

export const PlainTextVerse: React.FC<PlainTextVerseProps> = ({
  verses,
  verseClassName,
  includeGloryBe,
  columns,
}) => {
  const verseText = verses.join('\n\n');
  return (
    verseText.trim() && (
      <PlainTextTranslation
        paragraphClassName={verseClassName}
        text={verseText}
        className="liturgical-text-verse"
        columns={columns}
        includeGloryBe={includeGloryBe}
      />
    )
  );
};
