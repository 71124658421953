/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { Language } from 'utils/api-types/Language';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { CompositionTypes } from 'utils/api-types/composition/CompositionTypes';
import { MetaDisplayData } from 'utils/api-types/composition/MetaDisplayData';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { Nullable } from 'utils/typescript/Nullable';
import { GQLDocument, GQLQuery } from './gql-generics';
import { RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID, ResourceReferenceWithAvailableData } from './resource-reference';
import { TRANSLATION_FRAGMENT, Translation } from './translation';

export type VoiceEncoding = 'gabc' | 'lilypond';
export type Composition = {
  format: 'melodic' | 'complex';
  type: CompositionTypes;
  preview: string;
  composerSource: string | null;
  translation: (Translation & { isOfficeHymn: boolean }) | null;
} & WithNameAndId;

// Normally this should be its own fragment, but that is not possible right now since
// Composition is implemented as a GQL union on the backend (should eventually be a GQL interface)
export const COMMON_COMPOSITION_FIELDS = `
  id
  name
  format
  type
  preview
  composerSource
  translation {
    isOfficeHymn
    ...Translation
  }
`;

export type ComplexComposition = Composition & {
  format: 'complex';
  score: string;
}

export const COMPLEX_COMPOSITION_FRAGMENT = gql`
  fragment ComplexComposition on ComplexComposition {
    ${COMMON_COMPOSITION_FIELDS}
    score
  }
  ${TRANSLATION_FRAGMENT}
`;

export type Melody = {
  mode: string;
  meterTextual: string;
  isRegularMeter: boolean;
  voice: {
    encoding: VoiceEncoding;
    data: string;
  } & WithId;
  harmonizations: WithNameAndId;
} & WithNameAndId;

export const MELODY_FRAGMENT = gql`
  fragment Melody on Melody {
    id
    name
    mode
    meterTextual
    isRegularMeter
    voice {
      id
      encoding
      data
    }
    harmonizations {
      id
      name
    }
  }
`;

export type MelodicComposition = Composition & {
  format: 'melodic';
  form: 'long' | 'short';
  isFormulaic: boolean;
  hasReferenceNumber: boolean;
  parentheticQualifier: string | null;
  compositionDisplayData: MetaDisplayData[];
  melody: Melody;
  audioUrl: string|null;
  defaultHarmonizations: ({
    voiceTypes: ('alto'|'tenor'|'bass'|'chords')[];
  } & WithNameAndId)[];
}

export const MELODIC_COMPOSITION_FRAGMENT = gql`
  fragment MelodicComposition on MelodicComposition {
    ${COMMON_COMPOSITION_FIELDS}
    form
    isFormulaic
    hasReferenceNumber
    parentheticQualifier
    audioUrl
    compositionDisplayData {
      value
      key
      id
    }
    melody {
      ...Melody
    }
    defaultHarmonizations {
      id
      name
      voiceTypes
    }
  }
  ${TRANSLATION_FRAGMENT}
  ${MELODY_FRAGMENT}
`;

export type EitherComposition = ExclusiveUnion<MelodicComposition | ComplexComposition>;

export interface GetSuggestedCompositions extends GQLQuery {
  Variables: {
    ordoId: number;
    groupId: string;
    type: string;
    language?: Language;
    showOnlySSMHymns?: boolean;
    liturgicalYear?: number;
  }
  Data: {
    suggestedCompositions: ({
      isOfficeHymn: boolean;
      composition: EitherComposition | null;
      referenceTitle: {
        references: ResourceReferenceWithAvailableData[];
      } | null;
    } & WithId)[];
  }
}
export const GetSuggestedCompositions: GQLDocument<GetSuggestedCompositions> = {
  query: gql`
    query suggestedCompositions($ordoId: Float!, $groupId: String!, $type: String!, $language: String, $showOnlySSMHymns: Boolean, $liturgicalYear: Float) {
      suggestedCompositions(ordoId: $ordoId, type: $type, language: $language, showOnlySSMHymns: $showOnlySSMHymns, liturgicalYear: $liturgicalYear) {
        id
        isOfficeHymn
        composition {
          ... on MelodicComposition {
            ...MelodicComposition
          }

          ... on ComplexComposition {
            ...ComplexComposition
          }
        }
        referenceTitle {
          references {
            ...ResourceReferenceWithGroupId
          }
        }
      }
    }
    ${COMPLEX_COMPOSITION_FRAGMENT}
    ${MELODIC_COMPOSITION_FRAGMENT}
    ${RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID}
  `
}

export interface SearchHymns extends GQLQuery {
  Variables: Partial<Nullable<{
    name: string;
    collection: number;
    attributeName: string;
    attributeValue: string;
    offset: number;
    limit: number;
    language: Language;
    showOnlySSMHymns?: boolean;
    liturgicalYear?: number;
  }>> & {
    groupId: string;
  };
  Data: {
    hymns: ({
      composition: EitherComposition;
      reference: null;
    }|{
      composition: null;
      reference: ResourceReferenceWithAvailableData;
    })[];
  }
}
export const SearchHymns: GQLDocument<SearchHymns> = {
  query: gql`
    query searchHymns(
      $name: String,
      $collection: Float,
      $attributeName: String,
      $attributeValue: String,
      $offset: Float,
      $limit: Float,
      $language: String,
      $showOnlySSMHymns: Boolean,
      $liturgicalYear: Float,
      $groupId: String!,
    ) {
      hymns: searchCompositions(
        name: $name,
        type: "${CompositionTypes.HYMN}",
        collection: $collection,
        attributeName: $attributeName,
        attributeValue: $attributeValue,
        offset: $offset,
        limit: $limit,
        language: $language,
        showOnlySSMHymns: $showOnlySSMHymns,
        liturgicalYear: $liturgicalYear,
      ) {
        composition {
          ... on MelodicComposition {
            ...MelodicComposition
          }
          ... on ComplexComposition {
            ...ComplexComposition
          }
        }
        reference {
          ...ResourceReferenceWithGroupId
        }
      }
    }
    ${COMPLEX_COMPOSITION_FRAGMENT}
    ${MELODIC_COMPOSITION_FRAGMENT}
    ${RESOURCE_REFERENCE_FRAGMENT_WITH_GROUP_ID}
  `
}

export interface GetComposition extends GQLQuery {
  Variables: WithId;
  Data: {
    composition: EitherComposition;
  }
}
export const GetComposition: GQLDocument<GetComposition> = {
  query: gql`
    query getComposition($id: Float!) {
      composition(id: $id) {
        ... on MelodicComposition {
          ...MelodicComposition
        }
  
        ... on ComplexComposition {
          ...ComplexComposition
        }
      }
    }
    ${COMPLEX_COMPOSITION_FRAGMENT}
    ${MELODIC_COMPOSITION_FRAGMENT}
  `
}

export type MelodyBasic = {
  harmonizations: ({
    voiceTypes: ('alto'|'tenor'|'bass'|'chords')[];
  } & WithId)[];
  voice: {
    encoding: VoiceEncoding;
    data: string;
  } & WithId;
} & WithNameAndId;

export interface GetMelodyBasic extends GQLQuery {
  Variables: WithId;
  Data: { melody: MelodyBasic };
}
export const GetMelodyBasic: GQLDocument<GetMelodyBasic> = {
  query: gql`
    query getMelody($id: Float!) {
      melody(id: $id) {
        id
        name
        harmonizations {
          id
          voiceTypes
        }
        voice {
          id
          type
          encoding
          data
        }
      }
    }
  `
}
export interface GetMelody extends GQLQuery {
  Variables: WithId;
  Data: { melody: Melody };
}
export const GetMelody: GQLDocument<GetMelody> = {
  query: gql`
    query getMelody($id: Float!) {
      melody(id: $id) {
        ...Melody
      }
    }
    ${MELODY_FRAGMENT}
  `
}

export interface SearchCompositionAttributeValues extends GQLQuery {
  Variables: {
    name: string;
    value?: string;
    meter?: string;
    language?: Language;
  }
  Data: { searchResults: string[] };
}
export const SearchCompositionAttributeValues: GQLDocument<SearchCompositionAttributeValues> = {
  query: gql`
    query searchCompositionAttributeValues($name: String!, $value: String, $meter: String, $language: String) {
      searchResults: searchHymnAttributeValues(name: $name, value: $value, meter: $meter, language: $language)
    }
  `
}

export interface SearchHymnTunes extends GQLQuery {
  Variables: {
    tuneQuery?: string;
    meter?: string | null;
    translationId?: number | null;
  }
  Data: { searchResults: WithNameAndId[] };
}
export const SearchHymnTunes: GQLDocument<SearchHymnTunes> = {
  query: gql`
    query searchHymnTunes($tuneQuery: String, $meter: String, $translationId: Float) {
      searchResults: searchHymnTunes(tuneQuery: $tuneQuery, meter: $meter, translationId: $translationId) {
        id
        name
      }
    }
  `
}

export interface CountHymnTunes extends GQLQuery {
  Variables: {
    tuneQuery?: string;
    meter?: string | null;
    translationId?: number | null;
  }
  Data: { count: number };
}
export const CountHymnTunes: GQLDocument<CountHymnTunes> = {
  query: gql`
    query countHymnTunes($tuneQuery: String, $meter: String, $translationId: Float) {
      count: countHymnTunes(tuneQuery: $tuneQuery, meter: $meter, translationId: $translationId)
    }
  `
}
