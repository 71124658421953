import { collapseDuplicateAttributions } from 'utils/collapseDuplicateAttributions';
import { Attribute, OrdoCard } from 'queries/card-fragment';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import withNonBreaking from 'utils/markup/replaceSpacesWithNonBreaking';

const getComposerCopyright = ({
  Composer,
  Copyright,
  "Composer / Source": composerSource
}: {
  Composer?: string;
  Copyright?: string;
  "Composer / Source"?: string;
}) => {
  let result: string[] = [];
  const composer = Composer || composerSource;
  if (composer) result.push(withNonBreaking(composer));
  if (Copyright) {
    result.push(Copyright);
  }
  return result;
};
const getTranslatorCopyright = ({
  Translator,
  Copyright,
}: {
  Translator?: string;
  Copyright?: string;
}) => {
  const result: string[] = [];
  if (Translator) result.push(withNonBreaking('Tr. ' + Translator));
  if (Copyright) result.push(Copyright);
  return result;
};
const withPrefix = <T>(prefix: string, getStrings: (obj: T) => string[]) => {
  return (obj: T) => {
    let result = getStrings(obj);
    if (result?.length) {
      result[0] = prefix + result[0];
    }
    return result;
  };
};

const getAttributeConfigForCard = (
  cardType: CardTypes,
  verseCount?: number|null,
  useGeneralAttribution = false,
) => {
  switch (cardType) {
    case CardTypes.ANTIPHON_VERSE:
    case CardTypes.ANTIPHON_VERSE_GLORIA_PATRI:
    case CardTypes.ENTRANCE_CHANT:
    case CardTypes.COMMUNION_CHANT:
    case CardTypes.RESPONSORIAL_PSALM:
    case CardTypes.ALLELUIA:
    case CardTypes.VERSE_BEFORE_GOSPEL:
      return {
        'Antiphon Text': withPrefix('Ant. Text: ', getTranslatorCopyright),
        'Antiphon Melody': withPrefix('Ant. Melody: ', getComposerCopyright),
        'Antiphon Harmonization': withPrefix('Ant. Harm.: ', getComposerCopyright),
        ...(CardTypes.RESPONSORIAL_PSALM === cardType || (verseCount && verseCount > 0)
          ? {
              'Verse Text': withPrefix('Verse Text: ', getTranslatorCopyright),
              'Verse Melody': withPrefix('Verse Melody: ', getComposerCopyright),
              'Verse Harmonization': withPrefix('Verse Harm.: ', getComposerCopyright),
            }
          : {}),
      };
    case CardTypes.SEQUENCE:
      return {
        Text: withPrefix('Text: ', getTranslatorCopyright),
        Melody: withPrefix('Melody: ', getComposerCopyright),
        Harmonization: withPrefix('Harm.: ', getComposerCopyright),
      };
    case CardTypes.ORDINARY:
    case CardTypes.MYSTERY_OF_FAITH:
      return {
        Text: true,
        Melody: withPrefix('Melody: ', getComposerCopyright),
        Harmonization: withPrefix('Harm.: ', getComposerCopyright),
      };

    case CardTypes.HYMN:
      return useGeneralAttribution ? {} : {
        Text: ({
          Author,
          Source,
          Translator,
          Copyright
        }: {
          Author?: string;
          Source?: string;
          Translator?: string;
          Copyright?: string;
        }) => {
          if (Author || Source || Translator || Copyright) {
            const prefix = 'Text: ';
            const result: string[] = [];
            if (Author) {
              let firstResult = '';
              if (Source) {
                if (Source.indexOf('_') < 0) Source = `_${Source}_`;
                firstResult += `${withNonBreaking(Source)}, `;
              }
              result.push(firstResult + Author);
            } else if (Source) {
              result.push(withNonBreaking(Source))
            }
            const translatorCopyright = getTranslatorCopyright({ Translator, Copyright });
            result.push(...translatorCopyright);
            if (result[0]) result[0] = prefix + result[0];
            return result;
          } else {
            return [];
          }
        },
        Melody: ({
          Tune,
          Meter,
          Mode,
          Composer,
          Copyright,
        }: {
          Tune?: string;
          Meter?: string;
          Mode?: string;
          Composer?: string;
          Copyright?: string;
        }) => {
          if (Tune || Meter || Mode || Composer || Copyright) {
            const prefix = 'Melody: ';
            let firstResult = '';
            if (Tune) {
              firstResult += withNonBreaking(Tune);
              if (Meter || Mode) firstResult += ', ';
            }
            if (Meter) {
              firstResult += withNonBreaking(Meter);
              if (Mode) firstResult += ', ';
            }
            if (Mode) {
              firstResult += `Mode ${withNonBreaking(Mode)}`;
            }
            const result = getComposerCopyright({ Composer, Copyright });
            if (firstResult) result.unshift(firstResult);
            if (result.length) result[0] = prefix + result[0];
            return result;
          } else {
            return [];
          }
        },
        Harmonization: withPrefix('Harm.: ', getComposerCopyright),
      };

    case CardTypes.READING:
      return {
        Text: true,
        Melody: true,
      };
    default:
      return {};
  }
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const makeAttributeMap = (attributes: Attribute[]) => {
  return Object.fromEntries(attributes.map(a => [capitalize(a.name), a.value]));
};

export const getCardAttribution = (
  card: OrdoCard,
  cardConfig: OrdoCardConfig,
  useGeneralAttribution = false
): string => {
  const config = getAttributeConfigForCard(card.type, cardConfig.verseCount, useGeneralAttribution);
  const attributes = card.attributes;
  const copyrightKey = useGeneralAttribution ? 'copyrightGeneral' : 'Copyright';

  const attributions = attributes
    .map((a) => {
      const conf = config[a.title as keyof typeof config];
      if (!useGeneralAttribution && typeof conf === 'function') {
        const attributes = makeAttributeMap(a.attributes);
        return conf(attributes);
      } else if (conf) {
        const name =
          useGeneralAttribution || conf === true ? a.title : conf;

        const copyright = a.attributes.find((a) => a.name === copyrightKey)?.value;
        if (name && copyright) {
          if (useGeneralAttribution) {
            return [copyright];
          } else {
            return [`${name}: ${copyright}`];
          }
        }
      }
      return [];
    })
    .filter((a) => a.length);
  return collapseDuplicateAttributions(attributions).map(a => a.join('; ')).join('\n');
};
