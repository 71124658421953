import { ApolloCache, NormalizedCacheObject } from '@apollo/client';
import { ChoirEmail, GROUP_CHOIR_EMAILS_WITHOUT_GROUPS_FRAGMENT, GroupChoirEmailsWithoutGroups } from 'queries/choir-email';

export const addChoirEmailsToGroupCache = (cache: ApolloCache<NormalizedCacheObject>, choirEmails: ChoirEmail[], groupId: string) =>
cache.updateFragment({
  id: `Group-${groupId}`,
  fragment: GROUP_CHOIR_EMAILS_WITHOUT_GROUPS_FRAGMENT,
  fragmentName: 'GroupChoirEmailsWithoutGroups',
}, (data: null | GroupChoirEmailsWithoutGroups) => {
  if (!data) return data;
  const byEmail = new Set(data.choirEmails.map(({ email }) => email));
  const emailsToAdd = choirEmails.filter(({ email }) => !byEmail.has(email));
  if (emailsToAdd.length) {
    const result =  { ...data, choirEmails: [...data.choirEmails, ...emailsToAdd] };
    return result;
  }
  return data;
});
