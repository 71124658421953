import React, { useContext, useRef, RefObject, useCallback, useState, useEffect } from 'react';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { CardContext } from 'context/CardContext';
import { OfficialAntiphonTranslation, AntiphonDisplayMode } from './partials/OfficialAntiphonTranslation';
import { CollectionAntiphonTranslation } from './partials/CollectionAntiphonTranslation';
import { MusicalErrorAlert } from 'components/shared/MusicalErrorAlert';
import { useMidiCallbacks } from 'hooks/useMidiCallbacks';
import { RenderMidiCallback } from 'components/verovio/Verovio';
import { useIdQuery } from 'hooks/queries/useQuery';
import { GetTextOption } from 'queries/text-option';
import hasEasterVigilAlleluiaRubric from 'utils/hasEasterVigilAlleluiaRubric';
import { AntiphonStates } from './AntiphonStates';
import { verseSplitTypeForCard } from 'utils/markup/splitVerses';
import { MidiVerovioOrPassthru } from 'components/verovio/MidiVerovioOrPassthru';
import { GetText } from 'queries/text';
import { OrdoContext } from 'context/OrdoContext';

type ConfiguredStateProps = {
  isFullScreen?: boolean;
  isActive?: boolean;
}

export const ConfiguredState: React.FC<ConfiguredStateProps> = ({
  isFullScreen,
  isActive = false,
}) => {
  const cardContext = useContext(CardContext);
  const { ordo: { canDisplayAntiphonVersesOtherThanCommunion } } = useContext(OrdoContext);
  const { card, config, onLoaded } = cardContext;
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const [translationLoaded, setTranslationLoaded] = useState<boolean>(false);
  const onLoadedTranslation = useCallback(() => setTranslationLoaded(true), []);
  const [midiLoaded, setMidiLoaded] = useState<boolean>(false);

  // use the text option data from the card context, if available:
  const textOptionFromContext = cardContext.textOptions?.find(to => to.id === config.optionId);
  const { data, loading: loadingTextOption } = useIdQuery(GetTextOption, textOptionFromContext ? null : config.optionId);
  let textOption = textOptionFromContext ?? data?.textOption;
  if (textOption && config.optionId !== textOption.id) {
    // If the id doesn't match, we have stale data and don't want to use it
    textOption = undefined;
  }

  useEffect(() => {
    if ((midiLoaded && translationLoaded) || (!config.collectionId && !loadingTextOption)) {
      onLoaded();
    }
  }, [midiLoaded, translationLoaded, config, onLoaded, loadingTextOption]);
  const svgOrMei = card.svg ?? card.mei;
  const { setMidiData, setMidiPsalmToneData } = useMidiCallbacks(
    isActive,
    card.id,
    (svgOrMei)?.name,
    card.name,
    { ref, isMidiAvailable: !!(svgOrMei) }
  );

  const onRenderMidi: RenderMidiCallback = useCallback((...args) => {
    setMidiLoaded(true);
    setMidiData(...args, svgOrMei?.audioUrls, card.config.keyOffset);
  },[setMidiData, svgOrMei?.audioUrls, card.config.keyOffset]);

  const antiphonFromTextOption = textOption?.texts.find((t) => t.id === config.antiphonId);
  const { data: antiphonData, loading: antiphonLoading } = useIdQuery(GetText, antiphonFromTextOption ? null : config.antiphonId);
  
  const verseFromTextOption = config.language === 'es'
    ? undefined
    : textOption?.texts.find((t) => t.id === config.verseId);
  const { data: verseData } = useIdQuery(GetText, verseFromTextOption ? null : config.verseId);
  
  const canDisplayResponsorialPsalmVerseText = canDisplayAntiphonVersesOtherThanCommunion;

  if ((svgOrMei)?.error) {
    return <MusicalErrorAlert />;
  }
  const antiphon = antiphonData?.text ?? antiphonFromTextOption;
  const verse = verseData?.text ?? verseFromTextOption;

  if (!antiphon) {
    if (!antiphonLoading) {
      cardContext.updateView([AntiphonStates.ANTIPHON]);
    }
    return null;
  }
  const isEasterVigilTripleAntiphon = hasEasterVigilAlleluiaRubric(textOption);

  const isResponsorialPsalm = card.type === CardTypes.RESPONSORIAL_PSALM &&
    antiphon.contentType.name === 'Responsorial Psalm Antiphon';
  const cardDisplayMode = isResponsorialPsalm
      ? canDisplayResponsorialPsalmVerseText
        ? AntiphonDisplayMode.REPEATED_RESPONSE
        : AntiphonDisplayMode.ANTIPHON_WITH_VERSE_REFERENCE
      : AntiphonDisplayMode.DEFAULT;

  const useVersicleSymbolForVerses =
    card.type === CardTypes.RESPONSORIAL_PSALM &&
    antiphon.contentType.name === 'Gradual';

  return (
    <div ref={ref} className='card-configured antiphon _EXPORT'>
    {config.collectionId ? (<>
      {(svgOrMei) && <MidiVerovioOrPassthru svg={card.svg ?? undefined} mei={card.mei ?? undefined} verseStyle={config.verseStyle} {...{ onRenderMidi, setMidiPsalmToneData }} keyOffset={config.keyOffset} />}
      <CollectionAntiphonTranslation
        cardType={card.type}
        cardConfig={config}
        parentRef={ref}
        antiphonText={antiphon}
        textOption={textOption}
        cardMei={card.mei}
        cardSvg={card.svg}
        verseText={verse}
        useVersicleSymbolForVerses={useVersicleSymbolForVerses}
        isFullScreen={isFullScreen}
        addGrandStaff={cardContext.addGrandStaff}
        isEasterVigilTripleAntiphon={isEasterVigilTripleAntiphon}
        onLoaded={onLoadedTranslation}
        displayMode={cardDisplayMode}
      />
    </>) : (
      <OfficialAntiphonTranslation
        antiphon={antiphon}
        textOption={textOption}
        verse={verse}
        isEasterVigilTripleAntiphon={isEasterVigilTripleAntiphon}
        includeGloryBe={config.includeGloryBe ?? undefined}
        displayMode={cardDisplayMode}
        verseCount={config.verseCount}
        verseColumns={config.verseColumns}
        verseSplitType={verseSplitTypeForCard(card.type, config)}
      />
    )}
    </div>
  );
};
