import { Spinner } from 'components/shared/Spinner';
import { AlternativeCardOption } from 'components/shared/buttons/AlternativeCardOption';
import { CardContext } from 'context/CardContext';
import { TopLevelOrdoContext } from 'context/TopLevelOrdoContext';
import { useMutation } from 'hooks/queries/useMutation';
import { useIdQuery } from 'hooks/queries/useQuery';
import { useToast } from 'hooks/useToast';
import { produce } from 'immer';
import { GetNote, UpdateNote } from 'queries/note';
import React, { useCallback, useContext, useEffect } from 'react';
import { OrdoDisplayMode } from 'utils/ordo/OrdoDisplayMode';
import { NotesCardEditor } from './NotesCardEditor';
import { NotesCardViewer } from './NotesCardViewer';

export type NotesCardViewState = 'VIEW' | 'EDIT';

interface NotesCardProps {
  viewState: NotesCardViewState;
  setViewState?: (state: NotesCardViewState) => void;
};

export const NotesCard: React.FC<NotesCardProps> = ({
  viewState,
  setViewState,
}: NotesCardProps) => {
  const { displayMode } = useContext(TopLevelOrdoContext);
  const canEdit = (setViewState && displayMode === OrdoDisplayMode.WRITE) ?? false;
  const { config, saveConfig, onLoaded, note: cardNote } = useContext(CardContext);
  const showError = useToast('error');

  const setEditState = useCallback(() => {
    if (canEdit) {
      setViewState!('EDIT');
    }
  }, [canEdit, setViewState]);

  // query for the note, using noteId on the card config
  let {
    error: noteError,
    data: {
      note: {
        content = undefined,
        alignment = undefined,
        id = undefined,
      } = {}
    } = {},
    loading,
    // if note exists on CardContext already, skip query (empty ID skips query)
  } = useIdQuery(GetNote, cardNote ? null : config.noteId);

  if (cardNote) {
    ({
      content,
      alignment,
      id,
    } = cardNote);
  }

  useEffect(() => {
    if (!loading) {
      onLoaded();
    }
  }, [loading, onLoaded]);

  const [updateCardNote] = useMutation(UpdateNote, {
    onCompleted: (data) => {
      // pull id off the new note
      const { id } = data.note;
      // save it to the card's config to update card.configuredAt or add new note ID to card.
      saveConfig(produce(config, draftConfig => {
        draftConfig.noteId = id;
      }), []);
      // card leaves editing state after successful save
      setViewState?.('VIEW');
    },
    onError: (error) => {
      console.error(error);
      showError(`An error occurred while attempting to save this note`);
    },
  });

  // callback to save note
  const saveNote = (content: string) => {
    // use mutation defined above to update the card
    updateCardNote({
      variables: {
        id,
        content,
      },
      // replace update with a no-op function if we are creating a new note
      // (this prevents an error where we try to update the cache for a query it doesn't have yet)
      update: id ? undefined : () => {},
    });
  }

  // notes card should always be in VIEW mode during ordo READ mode
  useEffect(() => {
    if (!canEdit && viewState !== 'VIEW') {
      setViewState?.('VIEW');
    }
  }, [canEdit, viewState, setViewState]);

  // TODO: this error state could be rethought/designed
  return noteError ?
      <div className='card-option border-subtle text-center py-15'>
          <span>Error loading note</span>
      </div>
    // Show editor in edit state
    : viewState === 'EDIT' ?
      <NotesCardEditor
        save={saveNote}
        initialContent={content ?? undefined}
        alignment={alignment ?? undefined}
      />

    : content ? <div className='_EXPORT'>
      <NotesCardViewer
        onClick={setEditState}
        content={content}
        editable={canEdit}
      />
    </div>

    : !loading ? // this means viewState === 'VIEW' and we do NOT have content
      <AlternativeCardOption onClick={setEditState}>
        Add a Custom Text
      </AlternativeCardOption>

    // show loading
    : <Spinner size='30px' />
};
