import { PdfTextSpan, PdfTextBlock } from "./PdfBlockTypes";
import { setFontSizeWithRestore, setFontWithRestore } from "./setFontWithRestore";

/**
 * set pdf document font with a span's settings
 * @param pdf pdf document
 * @param span text span that is starting
 * @param block parent of text span
 * @returns function to de-initialize the span's settings
 */
 export function initSpan(
  pdf: PDFKit.PDFDocument,
  span: PdfTextSpan,
  block: PdfTextBlock,
  destinations?: Set<string>,
) {
  let restoreFontSize: () => void;
  let restoreFontFamily: () => void;
  let drawUnderline: () => void;
  let annotateLink: () => void;
  if ('fontSize' in span) {
    restoreFontSize = setFontSizeWithRestore(pdf, span.fontSize!);
  }
  const outerFont = pdf._font;
  if (span.fontFamily || span.fontOptions) {
    restoreFontFamily = setFontWithRestore(pdf, span, block);
  }
  if (block.fontSize) {
    // This is to handle matching font baselines to the block font's baseline when Versiculum is used, or different font sizes are used
    const baseline = (-outerFont.ascender / 1000) * block.fontSize;
    span.options = Object.assign(span.options || {}, { baseline });
  }
  const { x, y } = pdf;
  const height = ((pdf._font.ascender - pdf._font.descender / 2) * pdf._fontSize) / 1000;
  if (span.options?.underline) {
    drawUnderline = () => {
      const x2 = pdf.x;
      pdf.save();
      const lineWidth = Math.max(0.5, Math.floor(pdf._fontSize / 10));
      pdf.lineWidth(lineWidth);
      let lineY = y + height - (lineWidth / 2);
      pdf.moveTo(x, lineY);
      pdf.lineTo(x2, lineY);
      pdf.stroke();
      pdf.restore();
    };
  }
  const goToLink =
    span.options?.goTo &&
    // if set of destinations was passed, make sure the link is to an existing destination,
    // otherwise assume it is.
    (destinations?.has(span.options?.goTo) ?? true) &&
    span.options.goTo;
  const link = span.options?.link || goToLink;
  if (link) {
    const type = span.options?.link ? 'link' : 'goTo';
    annotateLink = () => {
      const w = pdf.x - x;
      pdf[type](x, y, w, height, link);
    }
  }
  pdf.y += span.yOffset ?? 0;
  return {
    cleanup: () => {
      restoreFontSize?.();
      restoreFontFamily?.();
      pdf.x += span.marginRight ?? 0;
      pdf.y -= span.yOffset ?? 0;
    },
    drawDecorations: () => {
      drawUnderline?.();
      annotateLink?.();
    },
  };
}
