import { css } from '@emotion/css';
import { colorDictionary as color } from './colorDictionary';

interface HeaderOptions {
  fontSize?: number;
  color?: keyof typeof color;
}

export const header = (options?: HeaderOptions) => css`
  font-size: ${options?.fontSize ?? 24}px;
  color: ${color[options?.color ?? 'Secondary']};
  margin: 0;
`;

type TextAlign = 'center'
  | 'left'
  | 'right';

export const align: {[key in TextAlign]: string} = ['center', 'left', 'right']
  .reduce((obj, key) => ({
    ...obj,
    [key]: css`text-align: ${key}`,
  }), {} as {[key in TextAlign]: string});
