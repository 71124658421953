import { PdfBlock, PdfBlockPage, PdfBlockPageContainer } from './PdfBlockTypes';
import {
  advanceColumn,
  endColumn,
  measureBlockHeight,
  measureColumnBlocks,
  resetPage,
} from './pdfColumnTools';
import {
  advancePdfYForFormattedText,
  isBlockBreakableAtPage,
  isPdfTextStyleBlock,
  PDFDocumentWithColumns,
} from './PdfFormattedText';
import { getPageDimensions } from './pdfPageTools';
import type { PdfPage } from './pdfTools';

export const paginateFromEnd = ({
  page,
  pdf,
  pdfPage = getPageDimensions(pdf),
  contentHeight = pdfPage.height - pdfPage.margins.top - pdfPage.margins.bottom,
}: {
  page: PdfPage;
  pdf: PDFDocumentWithColumns;
  pdfPage?: PDFDocumentWithColumns['pageDimensions'];
  contentHeight: number;
}) =>
  page.blocks.reduceRight(
    (
      state: {
        blocks: PdfBlock[][];
        pageHeights: number[];
      },
      block,
    ) => {
      const { height, padTop, padBottom } = measureBlockHeight(
        block,
        pdf,
        false,
        false,
      );
      if (height - padTop + state.pageHeights[0] <= contentHeight) {
        // add to this page
        state.blocks[0].unshift(block);
        state.pageHeights[0] += height;
      } else {
        // start a new page
        state.blocks.unshift([block]);
        state.pageHeights.unshift(height - padBottom);
      }
      return state;
    },
    { blocks: [[]], pageHeights: [0] },
  );

// TODO: currently this function has copied code from `getPdfBlob` in pdfTools.ts
// We should refactor so that both can use the same code
export const paginate = ({
  pages,
  pdf,
  breakEveryCard = true,
  contentWidthPixels = 590,
}: {
  pages: PdfPage[];
  pdf: PDFDocumentWithColumns;
  breakEveryCard?: boolean;
  contentWidthPixels?: number;
}): PdfBlockPage[] => {
  const result: PdfBlockPage[] = [];
  let pageBreak: boolean | undefined = undefined;
  let addToNextTop = 0;
  const pdfPage = getPageDimensions(pdf);
  const contentHeight =
    pdfPage.height - pdfPage.margins.top - pdfPage.margins.bottom;
  resetPage(pdf);
  for (const page of pages) {
    const { width, margins } = getPageDimensions(pdf);
    if (page.options?.contentWidth) {
      let { contentWidth, leftMargin, rightMargin } = page.options;
      const doubleMargin = (width - contentWidth);
      const useLeftRight =
        typeof leftMargin === 'number' &&
        typeof rightMargin === 'number' &&
        leftMargin + rightMargin === doubleMargin;
      const marginLeft = useLeftRight ? leftMargin : doubleMargin / 2;
      const marginRight = useLeftRight ? rightMargin : doubleMargin / 2;
      margins.left = marginLeft!;
      margins.right = marginRight!;
    }
    const contentWidth = width - margins.left - margins.right;

    // We use these sets to define properties without touching the original pdf block objects
    // This way we can store which blocks have column breaks, page breaks, etc. for just this render:
    const columnBreaks = new Set<PdfBlock>();
    const pageBreaks = new Set<PdfBlock>();
    const centerColumns = new Set<PdfBlock>();
    let { blocks } = page;

    // if the last page block array ended with a pageBreak, add it in now, provided that this page has been used (pdf.y > top margin)
    if (
      pageBreak &&
      pdf.y > pdfPage.margins.top
    ) {
      resetPage(pdf);
      pageBreaks.add(blocks[0]);
      addToNextTop = 0;
    }
    if (page.options?.verticalAlign === 'bottom') {
      // paginate from end so that the first page of this section
      // starts as far down as possible, and any additional pages
      // are filled up with content.
      // measure content from end of section, filling up pages as we go
      const { pageHeights, blocks } = paginateFromEnd({ page, pdf, contentHeight });
      const newY = pdfPage.margins.top + contentHeight - Math.ceil(pageHeights[0]);

      if (!pageBreak && result.length && newY >= pdf.y) {
        // first set of blocks will fit on this page
        // pull it out and put it on the current last page
        const topMargin = newY - pdf.y;
        const firstPageBlocks = blocks.shift()!;
        result.at(-1)!.containers.push({ blocks: firstPageBlocks, contentWidth, leftMargin: margins.left, rightMargin: margins.right, topMargin, key: page.key })
      }
      // set y of first page:
      pdf.y = newY;
      if (blocks.length) {
        result.push(...blocks.map((blocks) => ({
          containers: [{ blocks, contentWidth, leftMargin: margins.left, rightMargin: margins.right, key: page.key }],
          verticalAlign: 'bottom' as const,
        })));
      }
      // unset pageBreak if it was set to avoid an extra page break:
      pageBreak = false;
      continue;
    }
    let measuredThroughBlock = -1;
    let lastBlock: PdfBlock | undefined;
    let containerTop = 0;
    for (let i = 0; i < blocks.length; ++i) {
      let block = blocks[i];
      if (!block) continue;
      // don't add any top margin if we're starting at the top of the page:
      let top = pdf.y <= pdfPage.margins.top ? 0 : block.margins?.top ?? 0;
      if (
        pdf.column &&
        (centerColumns.has(block) || block.columns !== lastBlock?.columns)
      ) {
        endColumn(pdf);
      }
      if (pdf.column && columnBreaks.has(block)) {
        advanceColumn(pdf);
        top = 0;
      }
      if (pageBreaks.has(block)) {
        resetPage(pdf);
        top = addToNextTop = 0;
      }

      if (measuredThroughBlock < i) {
        // find any group of blocks that avoid page breaks:
        const nonBreakingBlocks = blocks.slice(i);
        const indexOfFirstAllowablePageBreak = nonBreakingBlocks.findIndex(
          isBlockBreakableAtPage,
        );
        // keep track of blocks beyond the first breakable block, if any
        let nextBlocks: PdfBlock[] = [];
        if (indexOfFirstAllowablePageBreak >= 0) {
          nextBlocks = nonBreakingBlocks.splice(
            indexOfFirstAllowablePageBreak + 1,
          );
        }
        // we have to check using the column measurer whether these blocks can fit on this page
        let measuredColumnBlocks = pageBreak
          ? -1
          : measureColumnBlocks({
              nonBreakingBlocks,
              nextBlocks,
              pdf,
              top,
              centerColumns,
              columnBreaks,
              pageBreaks,
            });
        if (pdf.y > pdfPage.margins.top && measuredColumnBlocks <= 0) {
          // if something has already been put on the page (i.e., it's not a fresh new page with pdf.y === page.margins.top)
          // or none would fit
          // or there is a page break,
          // then we need to start a new page
          pageBreaks.add(block);
          resetPage(pdf);
          top = addToNextTop = 0;
          // and re-measure with the new top set
          measuredColumnBlocks = measureColumnBlocks({
            nonBreakingBlocks,
            nextBlocks,
            pdf,
            top,
            centerColumns,
            columnBreaks,
            pageBreaks,
          });
        }
        if (addToNextTop && measuredColumnBlocks > 0) {
          containerTop = addToNextTop;
        }
        // TODO: for small page heights, where not all non-breaking blocks can fit, we may eventually want to handle it in some way
        // but for now, we just force all non-breaking blocks to try to be together on the page, which means the page may
        // overrun its margins in these cases
        measuredThroughBlock =
          i - 1 + Math.max(nonBreakingBlocks.length, measuredColumnBlocks);
      }
      const bottom = block.margins?.bottom ?? 0;
      if (isPdfTextStyleBlock(block)) {
        advancePdfYForFormattedText(
          pdf,
          block,
          undefined,
          { top, bottom },
          centerColumns.has(block),
        );
      } else {
        const { clientWidth, clientHeight } = block.element;
        const pixelMultiplier =
          contentWidth / Math.max(contentWidthPixels, clientWidth); // for conversion from pixels to points
        const height = Math.round(clientHeight * pixelMultiplier);
        pdf.y += top + height + bottom;
      }
      pageBreak = block.pageBreakAfter;
      lastBlock = block;
    }
    pageBreak = pageBreak || page.pageBreakAfter;
    if (page.pageBreakAfter !== false) {
      // if breakEveryCard is ON, set pageBreak to TRUE; otherwise set to the card settings:
      pageBreak = breakEveryCard || pageBreak;
    }
    if (pageBreak !== true) {
      // if using natural flow, add some extra vertical margin:
      pdf.y += (addToNextTop = 16);
    }
    let startI = 0;
    let activeResult = result.at(-1);
    const addResult = (i?: number) => {
      const container: PdfBlockPageContainer = { blocks: blocks.slice(startI, i), contentWidth, leftMargin: margins.left, rightMargin: margins.right, key: page.key };
      if (containerTop && startI === 0) {
        container.topMargin = containerTop;
        containerTop = 0;
      }
      if (activeResult) {
        if (container.blocks.length) {
          const activeContainer = activeResult?.containers.at(-1);
          const isNewSection = activeContainer?.key !== page.key;
          if (isNewSection) {
            activeResult.containers.push(container);
          } else {
            activeContainer?.blocks.push(...container.blocks);
          }
        }
      } else {
        result.push(activeResult = { containers: [container] });
      }
      startI = i ?? blocks.length;
    };
    const addResultColumn = (i?: number) => {
      let myBlocks = blocks.slice(startI, i);
      const firstColumnBlockIndex = myBlocks.findIndex(block => block.columns);
      if (firstColumnBlockIndex < 0) {
        // if there are no columns, just use the regular add result
        return addResult(i);
      }
      let container = activeResult?.containers.at(-1);
      if (firstColumnBlockIndex > 0) {
        // some blocks come before the first column block, so add them using the standard addResult
        myBlocks = myBlocks.slice(firstColumnBlockIndex)
        addResult(startI + firstColumnBlockIndex);
        container = activeResult?.containers.at(-1);
      }
      // Do we need to check whether the first block and the last block have the same columns property?
      if (!container) {
        container = { blocks: [], contentWidth, leftMargin: margins.left, rightMargin: margins.right, key: page.key };
        if (containerTop && startI === 0) {
          container.topMargin = containerTop;
          containerTop = 0;
        }
        if (activeResult) {
          if (myBlocks.length) {
            activeResult.containers.push(container);
          }
        } else {
          result.push(activeResult = { containers: [container] });
        }
      }
      const lastBlock = container.blocks.at(-1);
      const isCenter = centerColumns.has(myBlocks[0]);
      if (lastBlock && 'blocks' in lastBlock && lastBlock.blocks.length < lastBlock.columnInfo.count) {
        lastBlock.blocks.push(myBlocks);
      } else {
        container.blocks.push({ blocks: [myBlocks], columnInfo: myBlocks[0].columns!, isCenter });
      }
      startI = i ?? blocks.length;
    }
    for (let i = startI; i < blocks.length; ++i) {
      let block = blocks[i];
      const pageBreak = pageBreaks.has(block);
      const columnBreak = columnBreaks.has(block);
      const centerColumn = centerColumns.has(block);
      if (columnBreak || pageBreak) {
        // update top margin to 0 for any that have it set, but then occur at a page or column break:
        if (block.margins?.top) {
          if (centerColumn) centerColumns.delete(block);
          block = { ...block };
          block.margins!.top = 0;
          blocks = blocks.with(i, block);
          if (centerColumn) centerColumns.add(block);
        }
      }
      if (columnBreak || pageBreak || centerColumn) {
        addResultColumn(i);
      }
      if (pageBreak) {
        activeResult = undefined;
      }
    }
    if (blocks.length > startI) {
      addResultColumn();
    }
  }
  return result;
};
