import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PerkItemProps {
  icon: IconProp;
  copy: React.ReactNode;
  centerText?: boolean;
  className?: string;
}

export const PerkItem: React.FC<PerkItemProps> = ({
  icon,
  copy,
  centerText = false,
  className
}) => {

  return (
    <div className={`perk-item ${className} ${centerText ? 'text-center' : 'd-flex'}`}>
      <span className={`icon me-10 ${!centerText ? 'd-block' : ''}`}>
        <FontAwesomeIcon className='text-18' icon={icon} />
      </span>
      <span className={`copy ${!centerText ? 'd-block' : ''}`}>{copy}</span>
    </div>
  )
}
