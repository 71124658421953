import { isProduction } from 'utils/env';
import { useBlockPurchaseConditions } from './useGetOverridePurchaseCta';
import { useDefaultParishSize } from './useDefaultParishSize';
import { useSessionStorage } from './useSessionStorage';
export const pricingUrl = `https://www.sourceandsummit.com/pricing`;

export const usePricingUrl = (isRenewal: boolean = false) => {
  const { blockMissal, blockNonMissal } = useBlockPurchaseConditions(isRenewal);
  const url = new URL(pricingUrl);
  url.searchParams.set('app', '1');
  if (!isProduction) {
    url.searchParams.set('origin', window.location.origin);
  }
  const [defaultParishSize, parishSizeUnknown] = useDefaultParishSize();
  const [storedParishSize] = useSessionStorage('parishSize');
  const parishSize = storedParishSize || defaultParishSize;
  if (parishSize) {
    url.searchParams.set('parishSize', parishSize);
    if (!parishSizeUnknown) {
      url.searchParams.set('hideParishSizeSelector', '1');
    }
  }
  if (blockMissal) {
    url.searchParams.set('blockMissal', '1');
  }
  if (blockNonMissal) {
    url.searchParams.set('blockNonMissal', '1');
  }
  if (isRenewal) {
    url.searchParams.set('renewal', '1');
  }
  return url.href;
}
