
// this should match the API enum
export enum AddOnId {
  COMPLETE_DIGITAL_ADD_ON = 'complete-digital-add-on',
  COMPLETE_DIGITAL_ADD_ON_2023 = 'CDAO',
  PRORATED_COMPLETE_DIGITAL_ADD_ON = 'prorated_complete_digital_add_on',
  COMPLETE_DIGITAL_ADD_ON_DEPRECATED = 'complete_digital_add_on',
  ONBOARDING_COMPLETE_DIGITAL_ADD_ON = 'onboarding_complete_digital_add_on',
  TEMP_COMPLETE_DIGITAL_ADD_ON = 'temp-complete-digital-add-on',
}

// this should match the API enum
export enum PlanId {
  // Standard plans
  //    Yearly
  YEARLY_STANDARD = 'standard_digital_subscription_2023_-annual-',
  YEARLY_STANDARD_archive = 'standard_digital_subscription_2023_(annual)',
  CAMPUS_MINISTRY = 'campus-ministry-plan',
  TEMPORARY_MULTIUSER_ACCOUNT = 'temporary-multi-user-account',
  TESTING_PLAN = 'testing-plan',
  //    Monthly
  CHARTER_TRIAL = 'charter-trial',
  MONTH_TO_MONTH_STANDARD = 'month-to-month-2023',
  CAMPUS_MINISTRY_MONTHLY = 'campus-ministry-monthly',
  // Essentials plans
  YEARLY_ESSENTIALS = 'essentials-digital-subscription-2023-annual',
  MONTH_TO_MONTH_ESSENTIALS = 'month-to-month-2023_essentials',
  
  // Missal support plans
  MISSAL_SUBSCRIPTION = 'missal-subscription-2024',
  TEMPORARY_MULTIUSER_MISSAL = 'temporary-multi-user-account-missal',
  ONBOARDING = 'onboarding',
  PRORATED_MISSAL_SUBSCRIPTION = 'pro-rated-missal-subscription',
  
  // Missal companion plans
  MISSAL_COMPANION = 'missal-subscription--companion-edition-2024',
  MISSAL_COMPANION_archive = 'missal-subscription,-companion-edition-2024',
  PRORATED_MISSAL_COMPANION = 'pro-rated-missal-companion',

  // Basic plans
  BASIC = 'basic',
  BASIC_MONTHLY = 'basic-monthly',
  
  // Sunsetting plans
  YEARLY_STANDARD_ARCHIVE = 'standard_digital_subscription_-annual-',
  YEARLY_STANDARD_ARCHIVE_archive = 'standard_digital_subscription_(annual)',
  MONTH_TO_MONTH_STANDARD_ARCHIVE = 'month-to-month',
  YEARLY_ESSENTIALS_ARCHIVE = 'essentials_digital_subscription_-annual-',
  YEARLY_ESSENTIALS_ARCHIVE_archive = 'essentials_digital_subscription_(annual)',
  MONTH_TO_MONTH_ESSENTIALS_ARCHIVE = 'month-to-month_essentials',
  ESSENTIALS_CHARTER_PARISH_NO_TRIAL = 'copy_of_essentials-charter-subscription',
  ESSENTIALS_CHARTER_PARISH = 'essentials-charter-subscription',
  CHARTER_PARISH_NO_TRIAL = 'copy_of_charter-parish-subscription',
  CHARTER_PARISH = 'charter-parish-subscription',
  OLD_MISSAL_SUBSCRIPTION = 'missal-subscription',
  MISSAL_SUBSCRIPTION_2023 = 'missal-subscription-2023',
  MISSAL_COMPANION_2023 = 'missal-companion',
}

export enum PlanCategory {
  Standard = 'Standard',
  Essentials = 'Essentials',
  Missal = 'Missal',
  Educational = 'Educational',
  Companion = 'Companion',
  Basic = 'Basic',
}

// match to planRole on backend
export const planCategories: {
  [key in PlanId|AddOnId]: PlanCategory
} = {
  // Standard
  [PlanId.YEARLY_STANDARD]: PlanCategory.Standard,
  [PlanId.TEMPORARY_MULTIUSER_ACCOUNT]: PlanCategory.Standard,
  [PlanId.TESTING_PLAN]: PlanCategory.Standard,
  [PlanId.CHARTER_TRIAL]: PlanCategory.Standard,
  [PlanId.MONTH_TO_MONTH_STANDARD]: PlanCategory.Standard,
  // Campus Ministry
  [PlanId.CAMPUS_MINISTRY]: PlanCategory.Educational,
  [PlanId.CAMPUS_MINISTRY_MONTHLY]: PlanCategory.Educational,
  // Essentials
  [PlanId.YEARLY_ESSENTIALS]: PlanCategory.Essentials,
  [PlanId.MONTH_TO_MONTH_ESSENTIALS]: PlanCategory.Essentials,
  // Missal Support
  [PlanId.MISSAL_SUBSCRIPTION]: PlanCategory.Missal,
  [PlanId.PRORATED_MISSAL_SUBSCRIPTION]: PlanCategory.Missal,
  [PlanId.TEMPORARY_MULTIUSER_MISSAL]: PlanCategory.Missal,
  [PlanId.ONBOARDING]: PlanCategory.Missal,
  // Missal Companion
  [PlanId.MISSAL_COMPANION]: PlanCategory.Companion,
  [PlanId.PRORATED_MISSAL_COMPANION]: PlanCategory.Companion,
  // Basic
  [PlanId.BASIC]: PlanCategory.Basic,
  [PlanId.BASIC_MONTHLY]: PlanCategory.Basic,
  // Sunset
  [PlanId.YEARLY_STANDARD_archive]: PlanCategory.Standard,
  [PlanId.MISSAL_COMPANION_archive]: PlanCategory.Companion,
  [PlanId.YEARLY_STANDARD_ARCHIVE]: PlanCategory.Standard,
  [PlanId.YEARLY_STANDARD_ARCHIVE_archive]: PlanCategory.Standard,
  [PlanId.MONTH_TO_MONTH_STANDARD_ARCHIVE]: PlanCategory.Standard,
  [PlanId.YEARLY_ESSENTIALS_ARCHIVE]: PlanCategory.Essentials,
  [PlanId.YEARLY_ESSENTIALS_ARCHIVE_archive]: PlanCategory.Essentials,
  [PlanId.MONTH_TO_MONTH_ESSENTIALS_ARCHIVE]: PlanCategory.Essentials,
  [PlanId.ESSENTIALS_CHARTER_PARISH_NO_TRIAL]: PlanCategory.Essentials,
  [PlanId.ESSENTIALS_CHARTER_PARISH]: PlanCategory.Essentials,
  [PlanId.CHARTER_PARISH_NO_TRIAL]: PlanCategory.Standard,
  [PlanId.CHARTER_PARISH]: PlanCategory.Standard,
  [PlanId.OLD_MISSAL_SUBSCRIPTION]: PlanCategory.Missal,
  [PlanId.MISSAL_SUBSCRIPTION_2023]: PlanCategory.Missal,
  [PlanId.MISSAL_COMPANION_2023]: PlanCategory.Companion,
  // Add-ons
  [AddOnId.COMPLETE_DIGITAL_ADD_ON]: PlanCategory.Missal,
  [AddOnId.COMPLETE_DIGITAL_ADD_ON_2023]: PlanCategory.Missal,
  [AddOnId.ONBOARDING_COMPLETE_DIGITAL_ADD_ON]: PlanCategory.Missal,
  [AddOnId.TEMP_COMPLETE_DIGITAL_ADD_ON]: PlanCategory.Missal,
  [AddOnId.COMPLETE_DIGITAL_ADD_ON_DEPRECATED]: PlanCategory.Missal,
  [AddOnId.PRORATED_COMPLETE_DIGITAL_ADD_ON]: PlanCategory.Missal,
};

export const planNameForCategory: {
  [key in PlanCategory]: string;
} = {
  Basic: 'Basic Digital',
  Companion: '[missalYear] Missal, CE',
  Educational: 'Educational Digital',
  Essentials: 'Essentials Digital',
  Missal: '[missalYear] Missal, SE',
  Standard: 'Standard Digital',
};

export const addonNameForId = {
  [AddOnId.COMPLETE_DIGITAL_ADD_ON]: 'Complete Digital',
  [AddOnId.COMPLETE_DIGITAL_ADD_ON_2023]: 'Complete Digital',
  [AddOnId.ONBOARDING_COMPLETE_DIGITAL_ADD_ON]: 'Complete Digital',
  [AddOnId.TEMP_COMPLETE_DIGITAL_ADD_ON]: 'Complete Digital',
  [AddOnId.PRORATED_COMPLETE_DIGITAL_ADD_ON]: 'Complete Digital',
  [AddOnId.COMPLETE_DIGITAL_ADD_ON_DEPRECATED]: 'Complete Digital',
} as const satisfies {
  [key in AddOnId]: string;
};

export const isCompleteDigitalAddOnId = (
  addOnId: string,
): addOnId is AddOnId | keyof typeof AddOnId =>
  addOnId in AddOnId ||
  new Set(Object.values(AddOnId) as string[]).has(addOnId);

// should match equivalent API enum
export enum PlanTerm {
  MONTHLY = 'MONTHLY',
  DAY = 'DAY',
  ANNUAL = 'ANNUAL',
  OTHER = 'OTHER',
}

export const isMissalOrCompanion = (category?: PlanCategory) =>
  [PlanCategory.Missal, PlanCategory.Companion].includes(category!);

export const isNonDigital = (category?: PlanCategory) =>
  [PlanCategory.Missal, PlanCategory.Companion, PlanCategory.Basic].includes(category!);

export const isMonthlyPlan = (planId?: PlanId) =>
  !!planId &&
  new Set([
    PlanId.CHARTER_TRIAL,
    PlanId.MONTH_TO_MONTH_STANDARD,
    PlanId.CAMPUS_MINISTRY_MONTHLY,
    PlanId.MONTH_TO_MONTH_ESSENTIALS,
    PlanId.BASIC_MONTHLY,
    PlanId.MONTH_TO_MONTH_STANDARD_ARCHIVE,
    PlanId.MONTH_TO_MONTH_ESSENTIALS_ARCHIVE,
  ]).has(planId);
