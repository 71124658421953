import { MusicalKeys } from './MusicalKeys';
import { VEROVIO_NOTE_NAMES } from './VEROVIO_NOTE_NAMES';

export const getVerovioTranspose = (
  meiKey: MusicalKeys,
  transposeOffset: number
): string => {
  const keyName = VEROVIO_NOTE_NAMES[(meiKey + 12 + (transposeOffset % 12)) % 12];
  let octaves = '';
  if (transposeOffset > 0) {
    const octaveCount = Math.floor((transposeOffset + 6) / 12);
    octaves = '+'.repeat(octaveCount);
  } else if (transposeOffset < 0) {
    const octaveCount = Math.floor(-(transposeOffset - 6) / 12);
    octaves = '-'.repeat(octaveCount);
  } else {
    // transposeOffset 0
    return '';
  }
  return octaves + keyName;
};
