import { css } from '@emotion/css';
import QuillEditor, { QuillEditorProps } from 'components/QuillEditor';
import { Button } from 'components/shared/buttons/Button';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import quillExtensions from 'utils/quill/extensions';
import { Flexbox } from 'utils/styles/Flexbox';
import { TextAlignment } from './TextAlignment';

// We don't use this value, but this ensures the extension code gets run in utils/quill/extensions
// eslint-disable-next-line
quillExtensions;

export interface NotesCardEditorProps {
  save: (value: string) => void,
  initialContent?: string,
  alignment?: TextAlignment,
  autofocus?: boolean,
  autosave?: boolean;
};

const quillModules: QuillEditorProps['modules'] = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    // I don't know why this can't just be "left",
    // but it needs to be null to work correctly
    [{align: ''}, {align: 'center'}, {align: 'right'}],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],
    ['clean']
  ]
};

const quillFormats: QuillEditorProps['formats'] =  [
  'bold', 'italic', 'underline', 'align', 'list', 'link'
];

export const NotesCardEditor: React.FC<NotesCardEditorProps> = ({
  save,
  initialContent,
  autofocus = true,
  autosave = false,
}: NotesCardEditorProps) => {
  const [value, setValue] = useState(initialContent ?? '');

  const [focusSet, updateFocusSet] = useState<boolean>(false);

  const ref = useRef<Quill>(null);
  useEffect(() => {
    if (ref.current && !focusSet && autofocus) {
      ref.current.focus();
      updateFocusSet(true);
    }
  }, [autofocus, focusSet]);

  const saveDebounced = useDebouncedCallback(save, 400);
  const onChange: NonNullable<QuillEditorProps['onTextChange']> = useCallback((delta, oldContent, source) => {
    const value = ref?.current!.getSemanticHTML();
    if (autosave) {
      saveDebounced(value);
    }
    setValue(value);
  }, [autosave, saveDebounced]);

  return <>
    <QuillEditor className={style} theme='snow' defaultValue={initialContent} onTextChange={onChange} modules={quillModules} formats={quillFormats} ref={ref} />
    {!autosave && <Flexbox justifyContent='flex-end'>
      <Button
        variant='primary'
        onClick={() => save(value)}
      >Save</Button>
    </Flexbox>}
  </>;
};

const style = css({
  marginBottom: 10,
  
  minHeight: 0,
});
