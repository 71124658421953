import { css, cx, keyframes } from '@emotion/css';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type SpinnerProps = {
  size?: string|number;
}

export const Spinner : React.FC<SpinnerProps> = ({
  size,
}) => {
  return <FontAwesomeIcon icon={faSpinnerThird} className={cx(style, 'spinner')} style={{ width: size, height: size }} />;
};

const spin = keyframes`
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const style = css`
  animation: ${spin} 750ms linear infinite;
`;
