import { ErrorMessage } from 'components/gql/ErrorMessage';
import { LoadingMessage } from 'components/gql/LoadingMessage';
import { PlainTextTranslation } from 'components/ordo/shared/text/PlainTextTranslation';
import { MusicalErrorAlert } from 'components/shared/MusicalErrorAlert';
import { MidiVerovioOrPassthru } from 'components/verovio/MidiVerovioOrPassthru';
import { RenderMidiCallback } from 'components/verovio/Verovio';
import { CardContext } from 'context/CardContext';
import { OrdoContext } from 'context/OrdoContext';
import { GatedFeature, useHasFeature } from 'hooks/permission-set/useHasFeature';
import { useIdQuery } from 'hooks/queries/useQuery';
import { useMidiCallbacks } from 'hooks/useMidiCallbacks';
import { GetCollectionComposition } from 'queries/collection';
import React, { RefObject, useCallback, useContext, useEffect, useRef } from 'react';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { ReadingTypes, getReadingContent, getReadingSource } from 'utils/api-types/ordo/card/ReadingTypes';
import { getActiveSelections } from 'utils/functions/card';
import { findCompositionForText } from 'utils/functions/composition/findCompositionForText';
import { getOrdinaryReferenceCollection } from 'utils/ordo/card/getOrdinaryReferenceCollection';
import { OrdinaryMusic } from './partials/OrdinaryMusic';

interface ConfiguredStateProps {
  isFullScreen?: boolean;
  isActive?: boolean;
}

export const ConfiguredState: React.FC<ConfiguredStateProps> = ({
  isFullScreen,
  isActive = false,
}) => {

  const { card, config, textOptions, onLoaded } = useContext(CardContext);

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const {
    loading: loadingComposition,
    error: compositionError,
    data: { collectionComposition } = { collectionComposition: undefined }
  } = useIdQuery(GetCollectionComposition, config.compositionId);
  const { defaultLanguage } = useContext(OrdoContext).ordo;
  const composition = findCompositionForText(config.textId, collectionComposition);
  const language = composition?.translation?.language ?? config.language;
  let [translation, text, option] = getActiveSelections(textOptions, { ...config, language }, defaultLanguage);

  const formSelection = config.form ? config.form as ReadingTypes : ReadingTypes.LONG;
  let ordinaryTextContent = getReadingContent(translation, formSelection);
  const ordinaryScriptureReference = getReadingSource(text, formSelection);

  let referenceCollection: string|undefined;
  const referenceNumber = (
    useHasFeature(GatedFeature.MissalReferenceNumber)
    && config.displayReferenceNumber
    && collectionComposition?.referenceNumber
    && (referenceCollection = getOrdinaryReferenceCollection(collectionComposition))
    && `${referenceCollection} #${collectionComposition?.referenceNumber}`
  ) || undefined;

  const svgOrMei = card.svg ?? card.mei;
  const { setMidiData } = useMidiCallbacks(isActive, card.id, (svgOrMei)?.name, card.name, { ref, isMidiAvailable: !!(svgOrMei) });
  const onRenderMidi: RenderMidiCallback = useCallback((...args) => {
    setMidiData(...args, svgOrMei?.audioUrls, card.config.keyOffset);
  },[card.config.keyOffset, setMidiData, svgOrMei?.audioUrls]);

  const midiVerovio = (svgOrMei) && <MidiVerovioOrPassthru svg={card.svg ?? undefined} mei={card.mei ?? undefined} onRenderMidi={onRenderMidi} keyOffset={config.keyOffset} />;

  // We use paragraph mode if there are asterisks, versicle or response symbols, and Regular otherwise:
  const textDisplay = text?.name !== 'Agnus Dei' && /\(\*\)|[℣℟]/.test(ordinaryTextContent ?? '')
    ? 'Paragraph'
    : 'In Verse';

  const usePlainText = !config.compositionId || config.notation === MusicalNotation.TEXT;

  if (config.compositionId && config.notation === MusicalNotation.TEXT) {
    const compositionTextContent = composition?.translation?.content;
    // if there are any italicized rubrics, such as or for alternate amens or intonations
    // or ij in kyries, then we use the official translation instead of the composition's translation
    if (!/\(<i>/.test(compositionTextContent || '')) {
      ordinaryTextContent = compositionTextContent;
    }
  }

  useEffect(() => {
    if (usePlainText && ordinaryTextContent) {
      onLoaded();
    }
  }, [usePlainText, ordinaryTextContent, onLoaded]);

  if ((svgOrMei)?.error) {
    return <MusicalErrorAlert />;
  }

  return (
    <div ref={ref} className='card-configured ordinary'>

      {loadingComposition && <LoadingMessage />}

      {config.compositionId && compositionError && <ErrorMessage
          headline='Error loading composition data'
          error={compositionError}
      />}

      {!translation && textOptions?.length === 0 && <p>No text could be found for this ordinary.</p>}

      {translation && !usePlainText && <>
        {midiVerovio}
        <OrdinaryMusic
          config={config}
          textOption={option}
          cardMei={card.mei}
          cardSvg={card.svg}
          text={text}
          composition={composition}
          referenceNumber={referenceNumber}
          parentRef={ref}
          isFullScreen={isFullScreen}
          onLoaded={onLoaded}
        />
      </>}

      {ordinaryTextContent && usePlainText && <PlainTextTranslation
        text={ordinaryTextContent}
        scriptureReference={ordinaryScriptureReference ?? undefined}
        showAll={true}
        textDisplay={textDisplay}
      />}
    </div>
  );
}
