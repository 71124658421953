import { css, cx } from "@emotion/css";
import { colorDictionary } from "utils/styles";
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-light-svg-icons/faAngleLeft";
import { faTimes} from "@fortawesome/pro-light-svg-icons/faTimes";
import { ExclusiveUnion } from "utils/typescript/ExclusiveUnion";
import { Link } from "react-router-dom";
import { paths } from "utils/paths";

export type FixedModalExitButtonsProps = ExclusiveUnion<{
  onClick?: () => void;
} | {
  onClickBack: () => void;
  onClickExit: () => void;
}> & {
  showLogout?: boolean;
};

export const useFixedModalExitButtons = ({
  onClick,
  onClickBack,
  onClickExit,
  showLogout,
}: FixedModalExitButtonsProps) => [
  <div onClick={onClick ?? onClickBack} className={cx('fixed-back', style.shared, style.back)}><FontAwesomeIcon icon={faAngleLeft} className='back-icon' />&nbsp;Back</div>,
  showLogout
    ? <Link className={cx('fixed-logout', style.shared, style.logout)} to={paths.sign_out}>Log out</Link>
    : <div onClick={onClick ?? onClickExit} className={cx('fixed-close', style.shared, style.close)}><FontAwesomeIcon icon={faTimes} onClick={onClick} className='close-icon' /></div>
] as const;

export const FixedModalExitButtons : React.FC<FixedModalExitButtonsProps> = (props) => {
  const [backButton, closeButton] = useFixedModalExitButtons(props);
  return <>
    {backButton}
    {closeButton}
  </>;
}

const style = {
  shared: css`
    opacity: 1;
    transition: opacity 300ms ease;
    transition-delay: 300ms;
    color: ${colorDictionary['Primary Text']};
    padding: 3px 9.375px;
    position: fixed;
    top: 1rem;
    z-index: 11;
    font-size: 18px;
    cursor: pointer;
    border-radius: 8px;
    box-sizing: initial;
    user-select: none;
    background-color: ${colorDictionary['White']};
    &:hover {
      background-color: ${colorDictionary['Grey 3']};
    }
  `,
  back: css`
    left: 1.5rem;
    .close-icon {
      font-size: 1em;
    }
  `,
  close: css`
    right: 1.5rem;
    .back-icon {
      font-size: 1em;
    }
  `,
  logout: css`
    right: 1.5rem;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: ${colorDictionary['Primary Text']};
    }
  `,
};
