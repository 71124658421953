import { gql } from '@apollo/client';
import { WithNameAndId } from 'utils/api-types/WithNameAndId';

export type SourceNameShort = {
  nameShort: string;
} & WithNameAndId;

export type Source = {
  copyrightGeneral: string;
  copyrightSpecific: string;
} & SourceNameShort;


export const SOURCE_FRAGMENT = gql`
  fragment Source on Source {
    id
    name
    nameShort
    copyrightGeneral
    copyrightSpecific
  }
`;

export const SOURCE_NAME_SHORT_FRAGMENT = gql`
  fragment SourceNameShort on Source {
    id
    name
    nameShort
  }
`;
