import React from 'react';
import { DataGtagEvent, GtagName } from './DataGtagEvents';
import { SimpleGtagEventName } from './SimpleGtagEvents';

type Eventable<T, U extends React.SyntheticEvent> = T | ((uiEvent: U) => T | undefined);

export const getGACode = <U extends React.SyntheticEvent>(e: U) => {
  return (e.target as HTMLElement).getAttribute('data-ga-code');
}

export const createDataGtagHandler = <U extends React.SyntheticEvent, E extends DataGtagEvent = DataGtagEvent>(
  event: GtagName<E>,
  data: Eventable<E['Data'], U>
): React.EventHandler<U> => uiEvent => {
  const calculatedData = typeof data === 'function' ? data(uiEvent) : data;
  if (calculatedData) {
    if (!!window.gtag) {
      gtag('event', event.name, calculatedData);
    }
  }
}

export const createDataGtagCallback = <E extends DataGtagEvent = DataGtagEvent>(
  event: GtagName<E>,
  data: E['Data']
) => () => {
  if (!!window.gtag) {
    gtag('event', event.name, data);
  }
}

export const createSimpleGtagCallback = (name: SimpleGtagEventName) => () => {
  sendSimpleGtagEvent(name);
}

export const sendSimpleGtagEvent = (name: SimpleGtagEventName) => {
  // interaction identifier names have to be normalized to GA convention (lowercase with underscores)
  if (!!window.gtag) {
    gtag('event', name.toLowerCase());
  }
}
