import { unmute } from './unmute';

export let audioContext: Promise<AudioContext> = import(
  'standardized-audio-context'
).then(({ AudioContext }) => {
  try {
    const audioContext = new AudioContext() as any as globalThis.AudioContext;
    unmute(audioContext);
    return audioContext;
  } catch (e) {
    return new globalThis.AudioContext();
  }
});
