import { Dictionary } from "utils/typescript/Dictionary";
import { ensureStringArrayEndsWithPeriod } from "./functions/ensureStringEndsWithPeriod";

export const hierarchicalSections = {
  Text: [['Ant. Text', 'Verse Text']],
  'Ant. Music': [['Ant. Melody', 'Ant. Harm.']],
  'Verse Music': [['Verse Melody', 'Verse Harm.']],
  Melody: [['Ant. Melody', 'Verse Melody']],
  'Harm.': [['Ant. Harm.', 'Verse Harm.']],
  Music: [
    ['Ant. Music', 'Verse Music'],
    ['Melody', 'Harm.'],
  ],
  'Text and Melody': [['Text', 'Melody']],
  'Text and Harm.': [['Text', 'Harm.']],
  'Text and Music': [['Text', 'Music']],
}

type SectionMap = Dictionary<{ i: number, val: string, copyright?: string }>

const makeSectionMap = (sections: string[][]) => {
  const sectionMap: SectionMap = {};
  sections.forEach((section, i) => {
    // the last segment is considered the copyright:
    let copyright = section.length > 1 ? section.pop() : undefined;
    const split = section.join('; ').split(/^([^:]+):\s+/);

    // if there was a copyright, put it back into the section:
    if (copyright !== undefined) section.push(copyright);

    // split will be either length 1 (if no match was found) or 3 ([empty string, first group, rest of string]) if a match was found:
    if (split.length === 3) {
      let [, key, val] = split;
      // replace whitespace with non-breaking space
      if (copyright === undefined) {
        // if there was only one segment, it was a copyright, so clear out the value, and set it as the copyright:
        copyright = val;
        val = '';
      }
      sectionMap[key] = { i, val, copyright };
    }
  });
  return sectionMap;
}

/**
 * Check a list of sections within a sectionMap for equality
 * @param sectionMap section map in which to check for identical sections
 * @param sections list of sections to check for identical values
 * @returns null if the sections are not identical, and if they are identical, an object with
 *    ids = an array of the indices of the original sections checked
 *    val = the value that was shared by the sections
 */
const sectionsAreIdentical = (sectionMap: SectionMap, sections: string[], part: 'val'|'copyright' = 'val') => {
  let result: number[] = [];
  let lastSection = null as string | undefined | null;
  if (part === 'copyright' && sections.every(section => !sectionMap[section]?.copyright)) {
    return { ids: [], val: ''};
  }
  sections.forEach((key, i) => {
    if (result.length !== i) return;
    const section = sectionMap[key];
    const val = section?.[part];
    if (val) {
      if (lastSection === null || lastSection === val) {
        result.push(sectionMap[key].i)
      }
      lastSection = val;
    }
  });
  return result.length === sections.length ? { ids: result, val: lastSection! } : null;
}

/**
 * De-duplicate a list of attributions
 * @param sections list of "sections", an array of string arrays in the form [`label: value`, ..., `copyright section`]
 * @returns sections with the duplicates spliced out (the same array is returned)
 */
export const collapseDuplicateAttributions = (sections: string[][]) => {
  const sectionMap = makeSectionMap(sections);
  Object.entries(hierarchicalSections).forEach(([newSectionName, sectionLists]) => {
    if (newSectionName in sectionMap) return; // skip any that would collapse into something that already exists
    for(const sectionList of sectionLists) {
      const result = sectionsAreIdentical(sectionMap, sectionList);
      const copyrightResult = sectionsAreIdentical(sectionMap, sectionList, 'copyright');
      if (result && copyrightResult) {
        const ids = result.ids.sort((a,b) => b - a);
        // adjust first index to account for the fact that we are removing the other ids from the list
        ids[0] -= ids.length - 1;
        for (let i = ids.length - 1; i > 0; --i) {
          const index = ids[i];
          sections.splice(index, 1);
          Object.values(sectionMap).forEach((section) => {
            if (section.i > index) --section.i;
            else if (section.i === index) section.i = ids[0];
          });
        }
        sectionMap[newSectionName] = { i: ids[0], val: result.val, copyright: copyrightResult.val };
        const newSection = [result.val, copyrightResult.val].filter(val => !!val);
        newSection[0] = newSectionName + ': ' + newSection[0];
        sections.splice(ids[0], 1, newSection);
        break;
      } else if (copyrightResult && copyrightResult.ids.length) {
        // if copyrightResult.ids.length, it means that the copyrights were all missing, so they are the same, but we don't need to act since they wer all missing
        // Here, we add in the copyright section after the last section and remove the copyright from the original sections
        const ids = copyrightResult.ids.sort((a,b) => b - a);
        for (const index of ids) {
          sections[index].pop(); // pop off copyright value;
        }
        for (const section of sectionList) {
          delete sectionMap[section]?.copyright;
        }
        sections.splice(ids[0] + 1, 0, [newSectionName + ': ' + copyrightResult.val ]);
        Object.values(sectionMap).forEach(section => {
          if (section.i > ids[0]) ++section.i;
        });
        sectionMap[newSectionName] = { i: ids[0] + 1, val: '', copyright: copyrightResult.val };
        break;
      }
    }
  });
  return sections.filter(section => section.length).map(ensureStringArrayEndsWithPeriod);
}
