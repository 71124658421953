import React from 'react';
import { css, cx } from '@emotion/css';
import { colorDictionary } from 'utils/styles/colorDictionary';
import { fontSansCss } from 'utils/styles';

export type ButtonVariant =
| 'standard'
| 'primary'
| 'secondary'
| 'submit'
| 'light'
| 'icon'
| 'outline'
| 'white'
| 'gold'
| 'transparent';

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  variant?: ButtonVariant;
  children: React.ReactNode;
  className?: string;
  href?: string;
}

export const buttonStyle = css`
  font-weight: 600;
  line-height: 1.2;
  font-size: 18px;
  padding: 0 1.5ch;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: background-color 250ms;
  ${fontSansCss}
  &:disabled {
    cursor: default;
  }
  &:hover {
    text-decoration: none;
  }
  &.button-primary {
    background-color: ${colorDictionary['Primary']};
    color: ${colorDictionary['White']};
    &:hover:not(:disabled) {
      background-color: ${colorDictionary['Primary Dark']};
    }
    &:focus {
      outline: 1px solid ${colorDictionary['Primary Light']};
    }
  }
  &.button-secondary {
    background-color: ${colorDictionary['White']};
    color: ${colorDictionary['Secondary']};
  }
  &.button-submit {
    background-color: ${colorDictionary['Primary']};
    color: ${colorDictionary['White']};
    box-shadow: 0 0 0 0 ${colorDictionary['Primary Light']};
    transition: box-shadow 0.2s ease;
    &:hover:not(:disabled) {
      box-shadow: 0 0 0 0.2rem ${colorDictionary['Primary Light']};
      background: ${colorDictionary['Primary Dark']};
    }
  }
  &.button-light {
    background-color: ${colorDictionary['Primary Light']};
    color: ${colorDictionary['Primary']};
    &:hover:not(:disabled) {
      background-color: ${colorDictionary['Primary Medium']};
    }
  }
  &.button-icon {
    background-color: transparent;
    &:hover:not(:disabled) {
      background-color: ${colorDictionary['Input']};
    }
  }
  &.button-outline {
    border: 2px solid ${colorDictionary['Primary']};
    color: ${colorDictionary['Primary']};
    background-color: transparent;
    &:hover:not(:disabled) {
      background-color: ${colorDictionary['Primary Light']};
    }
  }
  &.button-gold {
    color: ${colorDictionary['Primary Text']};
    background-color: ${colorDictionary['Gold']};
    &:focus {
      background-color: ${colorDictionary['Gold']};
    }
  }
  &.button-white {
    color: ${colorDictionary['Primary Text']};
    background-color: ${colorDictionary['White']};
    &:focus {
      background-color: ${colorDictionary['White']};
    }
  }
  &.button-white, &.button-gold {
    height: 50px;
  }
  &.button-transparent {
    font-weight: 400;
    background-color: transparent;
  }
`;

export const Button = React.forwardRef(({
  variant = 'primary',
  children,
  className,
  href,
  style,
  ...props
}: React.PropsWithChildren<ButtonProps>, ref?: React.Ref<HTMLButtonElement>) =>
!href ? <button
  {...props}
  {...{ style, ref, children }}
  className={cx(`button button-${variant}`, buttonStyle, className)}
/>
: <a
  {...{ style, href }}
  className={cx(`button button-${variant}`, buttonStyle, className)}
>
  {children}
</a>);
