export const ensureStringEndsWithPeriod = (text?: string) =>
  text
    ? /^$|\.[)'"’”»]*$/.test((text = text.trim()))
      ? text
      : text + '.'
    : '';
export const ensureStringArrayEndsWithPeriod = (texts: string[]) => {
  if (texts && texts.length) {
    texts[texts.length - 1] = ensureStringEndsWithPeriod(
      texts[texts.length - 1],
    );
  }
  return texts;
};
