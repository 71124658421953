export const paths = {
  dashboard: '/',
  network_error: '/network-error',
  login_error: '/login-error',
  general_error: '/error',
  not_found: '/404',
  share: '/share',
  marketingShare: '/marketing',
  seek_22: '/seek22',
  sign_up: '/sign-up',
  clear_apollo: '/clear-apollo',
  clear_cache: '/clear-cache',
  about_us: '/about-us',
  ordo: '/ordo',
  ordo_outline: '/ordo-outline',
  welcome_back: '/welcome-back',
  select_plan: '/select-plan',
  pricing: '/pricing',
  checkout: '/checkout',
  post_purchase: '/post-purchase',
  purchase_confirm: '/purchase-confirm',
  account: [
    '/account',
    '/account/my-account',
    '/account/organization',
    '/account/referrals',
    '/account/billing',
  ],
  sign_out: '/sign-out',
  associate_places: '/associate-places',
  sent_links: '/sent-links',
  invitation: '/invitation',
  retry_login: '/retry-login',
  log_in: '/log-in',
  team: [
    '/team',
    '/team/editors',
    '/team/people',
    '/team/groups',
  ],
  templates: [
    '/templates',
    '/templates/all',
    '/templates/published',
    '/templates/draft',
  ],
  template: '/template',
  createTemplate: '/create-template',
  referral: '/referral',
  referrer: '/referrer',
  reset_password: '/reset-password',
  sample_missal: '/sample-missal',
  layout: [
    '/layout/:id',
    '/layout/:id/document',
    '/layout/:id/ordo',
    '/layout/:id/images',
    '/layout/:id/resources',
  ],
} as const;
