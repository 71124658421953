import { useDefaultVerovioOptions } from './useDefaultVerovioOptions';
import { useMemo } from 'react';
import type { VerovioOptions } from 'react-verovio';

const hymnConfig: VerovioOptions = {
  defaultBottomMargin: 5.5 * 0.3,
  pageWidth: 1595,
  rightMarginLeftBarLine: 2,
  lyricVerseCollapse: true,
};
export const useDefaultVerovioOptionsHymn = ({
  extraConfig,
  isFullScreen = false,
  showHarmonizations = false,
  addGrandStaff = false,
  isChantHymn = false,
  verseCount,
  spacingVertical,
}: {
  extraConfig?: VerovioOptions;
  isFullScreen?: boolean;
  showHarmonizations?: boolean | null;
  addGrandStaff?: boolean;
  isChantHymn?: boolean | null;
  verseCount?: number | null;
  spacingVertical?: number | null;
}): VerovioOptions => {
  const addonConfig: VerovioOptions = useMemo(() => ({
    ...hymnConfig,
    ...extraConfig,
    ...(isChantHymn ? {
      spacingLinear: 0.29,
      lyricTopMinMargin: 3.5,
      ...(showHarmonizations && verseCount === 1 ? { defaultBottomMargin: 5 } : {})
    } : {})
  }), [extraConfig, isChantHymn, showHarmonizations, verseCount]);
  return useDefaultVerovioOptions(addonConfig, isFullScreen, showHarmonizations, null, addGrandStaff, spacingVertical);
};
