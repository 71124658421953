import type { TimeMapEntry } from 'react-verovio';

type TimeEntryTicks = {
  tick: number;
  midiIdx: number;
  active?: string[];
};
export type TimeMapEntryWithTicks = TimeMapEntry & TimeEntryTicks;
export type TimeMapEntryWithPossibleTicks = TimeMapEntry &
  Partial<TimeEntryTicks>;

export const processTimeMapForTicks = (
  timeMap: TimeMapEntryWithPossibleTicks[],
  options: { tickOffset?: number, midiIdx?: number } = { },
) => {
  const { tickOffset = 0, midiIdx = 0 } = options;
  if (timeMap.length && !('tick' in timeMap[0])) {
    const activeNotes: { [id: string]: number } = {};
    let lastEntry: TimeMapEntryWithPossibleTicks | null = null;
    let lastTick = 0;
    if (!Object.isExtensible(timeMap[0])) {
      // if it's a GraphQL object that isn't extensible, we need to make copies of the entries:
      timeMap = timeMap.map(entry => ({ ...entry }));
    }
    for (let i = 0; i < timeMap.length; ++i) {
      const entry = timeMap[i];
      // TODO: I'm not sure why the entry qstamps need to be multiplied by 120, or whether something could causet that number to change,
      // but for now, it works:
      const tick = lastTick = tickOffset + Math.floor(entry.qstamp * 120);
      entry.tick = tick;
      entry.midiIdx = midiIdx;

      // remove notes that have turned off
      for (const id of entry.off ?? []) {
        delete activeNotes[id];
      }
      // add notes that have turned on
      for (const id of entry.on ?? []) {
        activeNotes[id] = tick;
      }

      // set note IDs that are currently active for this TimeMapEntry:
      entry.active = Object.keys(activeNotes);

      // sometimes two consecutive entries are for the same time stamp,
      // so we need to remove the earlier one and combine it with the later one:
      if (entry.qstamp === lastEntry?.qstamp) {
        timeMap.splice(--i, 1);
        entry.on = [...(lastEntry.on ?? []), ...(entry.on ?? [])];
        entry.off = [...(lastEntry.off ?? []), ...(entry.off ?? [])];
        if (lastEntry.tempo) {
          entry.tempo = entry.tempo ?? lastEntry.tempo;
        }
      }
      lastEntry = entry;
    }
    options.tickOffset = lastTick + 240;
    options.midiIdx = midiIdx + 1;
  }
  return timeMap as TimeMapEntryWithTicks[];
};
