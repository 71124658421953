import { css, cx } from '@emotion/css';
import React, { useEffect } from 'react';
import { RenderMidiCallback } from './Verovio';
import { MeiMidi } from 'queries/card-fragment';

export const VerovioSvgs: React.FC<{
  svgs: string[];
  midi?: MeiMidi | null;
  addFirstLastPageClassNames?: boolean;
  className?: string;
  outerClassName?: string;
  onRenderMidi?: RenderMidiCallback;
}> = ({ svgs, addFirstLastPageClassNames, className, outerClassName, midi, onRenderMidi }) => {
  useEffect(() => {
    if (midi && onRenderMidi) {
      // this is really only useful because it triggers the card loaded callback
      onRenderMidi([midi.midi], [midi.timeMap]);
    }
  }, [midi, onRenderMidi])
  const squareNotes = svgs?.[0]?.slice(0,4) === '<div';
  return squareNotes
    ? <div
        className={cx('verovio-container', exsurgeStyle, className)}
        dangerouslySetInnerHTML={{ __html: svgs.join('') }}
      />
    : <div className={outerClassName}>
    {svgs.map((svg, idx) => {
      const isFirstPage = idx === 0;
      const isLastPage = idx === svgs.length - 1;
      const classNames = {
        'first-page': addFirstLastPageClassNames && isFirstPage,
        'last-page': addFirstLastPageClassNames && isLastPage,
      };
      return (
        <div
          key={`${idx}`}
          className={cx('verovio-container', className, classNames)}
          dangerouslySetInnerHTML={{ __html: svg }}
        ></div>
      );
    })}
  </div>;
};

const exsurgeStyle = css({
  '.Exsurge > svg': {
    width: '100%',
  },
  '.Exsurge.width-auto > svg': {
    width: 'auto',
    zoom: 'var(--card-scale, 1)',
  },
  '.Exsurge + .Exsurge': {
    paddingTop: 'calc(4px + var(--card-vertical-spacing, 0px))',
  },
});
