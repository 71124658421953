import { css, cx } from '@emotion/css';
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons/faSparkles';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChargebeeButton } from 'components/registration/ChargebeeButton';
import { PricingCard } from 'components/registration/PricingCard';
import { Button } from 'components/shared/buttons/Button';
import { AuthContext } from 'context/AuthContext';
import { UserRoles, useHasRole } from 'hooks/permission-set/useHasRole';
import { getSelectedGroup } from 'hooks/useUser';
import React, { useCallback, useContext } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { AddOnId, PlanId } from 'utils/api-types/plans';
import { UpsellConversion, createDataGtagHandler, createSimpleGtagCallback, getGACode } from 'utils/gtag';
import { contactUrl } from 'utils/links';
import { PlanType } from 'utils/select-plan/PlanType';
import { sequenceHandlers } from 'utils/sequenceHandlers';
import { colorDictionary } from 'utils/styles';
import { completeDigitalUpsellModalCopy, essentialsUpsellModalCopy, standardUpsellModalCopy } from 'utils/upsellModalCopy';
import { FixedModalExitButtons } from './shared/FixedModalExitButtons';

interface UpsellModalProps extends ModalProps {
  expressedInterest: string;
  toEssentials?: boolean;
}

export const UpsellModal: React.FC<UpsellModalProps> = ({
  expressedInterest,
  toEssentials,
  ...modalProps
}) => {
  const { group, user } = useContext(AuthContext);
  const groupInfo = getSelectedGroup(user);
  const isYearly = !groupInfo?.roles?.includes(UserRoles.MonthlyUser);

  const contactParishSupport = useCallback(() => {
    window.open(contactUrl);
  }, []);

  const isMissalSupport = useHasRole({any: [UserRoles.MissalSupport, UserRoles.MissalCompanion]});
  const isBasic = useHasRole({ role: UserRoles.BasicUser });
  const upgradeToEssentials = toEssentials && isBasic;
  const copy = upgradeToEssentials
    ? essentialsUpsellModalCopy
    : isMissalSupport
      ? completeDigitalUpsellModalCopy
      : standardUpsellModalCopy;
  const upsellPlan = isMissalSupport ? 'Complete Digital' : 'Standard';
  const addonId = isMissalSupport ? AddOnId.COMPLETE_DIGITAL_ADD_ON : undefined;
  const selectPlanId = useCallback(
    (isAnnual = true) =>
      isMissalSupport
        ? undefined
        : upgradeToEssentials
          ? isAnnual ? PlanId.YEARLY_ESSENTIALS : PlanId.MONTH_TO_MONTH_ESSENTIALS
          : isAnnual ? PlanId.YEARLY_STANDARD : PlanId.MONTH_TO_MONTH_STANDARD,
    [isMissalSupport, upgradeToEssentials],
  );

  modalProps.onShow = sequenceHandlers(modalProps.onShow, createSimpleGtagCallback('view_upsell'));

  return <Modal className={cx(style, 'upsell-modal')} {...modalProps}>
    <FixedModalExitButtons onClick={modalProps.onHide}/>
    <div
      className='upsell-body'
      onClick={createDataGtagHandler(UpsellConversion, e => {
        const code = getGACode(e);
        return code ? {
          source: code,
          plan: upsellPlan,
        } : undefined;
      })}
    >
      <div className='super-text'>
        <p>{copy.superText}</p>
        <FontAwesomeIcon
          icon={faSparkles}
          className='upsell-icon'
        />
      </div>
      <h1 className='promo-title'>{copy.title}</h1>
      <p className='description'><copy.Description expressedInterest={expressedInterest} /></p>
      <PricingCard
        addonId={addonId}
        isUpgrade={true}
        isYearly={isYearly}
        Copy={{
          Loading: () => <>Loading</>,
          PerkItems: copy.perkItems.map(copy => ({
            icon: faCheck,
            copy,
            comingSoon: copy.includes('*'),
          })),
          RegisteredParishioners: () => null,
          ChargebeeButton,
          ContactSalesButton: () => <Button
              style={{width: '100%'}}
              variant='primary'
              onClick={sequenceHandlers(
                createDataGtagHandler(UpsellConversion, { source: 'upsell-button', plan: upsellPlan }),
                contactParishSupport
              )}
            >
              <span style={{width: '100%', textAlign: 'center'}}>{copy.buttonText}</span>
            </Button>,
          type: PlanType.YEARLY,
        }}
        selectPlanId={selectPlanId}
        group={group}
      />
      <p className='large'>{copy.secondaryTitle}</p>
      <p className='description'>{copy.secondaryDescription}</p>
    </div>
  </Modal>;
};

const style = css`
.modal-dialog {
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  max-width: unset;
  background: white;
  overflow: auto;

  .modal-content {
    box-shadow: none;

    .upsell-body {
      padding: 90px 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      max-width: 587px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .super-text {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 14px;
          color: ${colorDictionary['Secondary']};
          text-transform: uppercase;
          margin: 0;
        }

        .upsell-icon {
          color: ${colorDictionary['Green']};
          margin-left: 10px;
        }
      }

      p,
      h1 {
        line-height: 1.2;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
      }

      p {
        max-width: 486px;
        margin-top: 15px;
      }

      .promo-title {
        font-size: 50px;
        font-family: 'Ogg Medium', serif;
        margin-top: 15px;
      }

      .card {
        padding: 30px;
        border-radius: 8px;
        background: white;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
        border: none;
        width: 450px;

        .icon {
          color: ${colorDictionary['Green']};
        }

        .card-body {
          padding: 0 !important;
        }
      }

      .large {
        margin-top: 30px;
        font-size: 24px;
      }

      .description {
        font-size: 18px;
      }
    }
  }
}
`;
