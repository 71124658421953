import React, { ReactNode } from 'react';

interface DescriptionProps {
  expressedInterest: string;
}

interface UpsellModalCopy {
  superText: ReactNode;
  title: ReactNode;
  Description: React.FC<DescriptionProps>;
  perkItems: string[];
  buttonText: ReactNode;
  secondaryTitle: ReactNode;
  secondaryDescription: ReactNode;
}

const getQuestion = (expressedInterest: string): string =>
  /^Do you[\s\S]*\?$/.test(expressedInterest)
    ? expressedInterest
    : `Do you want to ${expressedInterest}?`;
const Description: React.FC<DescriptionProps> = ({ expressedInterest }) => (
  <>
    {getQuestion(expressedInterest)} Upgrade now to access this and a host of
    additional content and features to fully support the liturgical music in
    your parish including:
  </>
);

const upsellModalCopy: UpsellModalCopy = {
  superText: 'Plan Upgrade',
  title: 'Put all of the riches of the Liturgy at your fingertips.',
  Description,
  perkItems: [
    'More than 450 hymns (and growing)',
    '2000+ antiphon settings & 18 mass settings',
    'Readings for weekdays, Sundays and Solemnities',
    '5 users',
    'Votive & ritual masses',
    'Spanish and Latin support for Sundays and Solemnities',
    'Unlimited Templates',
    'User roles',
  ],
  buttonText: 'Contact Support',
  secondaryTitle: 'Do you still have questions?',
  secondaryDescription: <>If you still have questions or would like to learn more about the Standard Plan call us
  at <a data-ga-code='upsell-phone' href="tel:1-888-462-7780">(888) 462-7780</a> or email us
  at <a data-ga-code='upsell-email' href="mailto:support@sourceandsummit.com?subject=Upgrade Questions">support@sourceandsummit.com</a>.
  Our parish support staff is standing by and ready to assist you in any way they can.</>,
}

export const completeDigitalUpsellModalCopy: UpsellModalCopy = {
  ...upsellModalCopy,
  superText: 'Complete Digital Plan Upgrade',
};

export const standardUpsellModalCopy: UpsellModalCopy = {
  ...upsellModalCopy,
  superText: 'Standard Plan Upgrade',
};

export const essentialsUpsellModalCopy: UpsellModalCopy = {
  ...upsellModalCopy,
  superText: 'Essentials Plan Upgrade',
};
