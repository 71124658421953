import { Spinner } from 'components/shared/Spinner';
import { MeiMidi } from 'queries/card-fragment';
import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import type { TimeMapEntry, VerovioOptions, toolkit as VerovioToolkit, verovioInitCallback } from 'react-verovio';
import type { VerovioPostProcessor, VerovioSvgInfo, VerovioSvgPostProcessor } from 'react-verovio/dist/components/Verovio';
import { getContentWidthForExsurge } from 'utils/getContentWidth';
import { FONT_CHARACTER_SIZING_OVERRIDE, postProcessVerovioSvg } from 'utils/verovio';
import type { VerovioPostProcessConfig } from 'utils/verovio/postProcessVerovioSvg';

export const loadVerovio = () => import('react-verovio');


const minSpacingNonLinear = 0.51;
const maxSpacingNonLinear = 0.67;

const LazyVerovio = React.lazy(async () => ({
  default: (await loadVerovio()).Verovio,
}));

const spinner = <Spinner size="32px" />;
const onInit: verovioInitCallback = (verovioModule) => {
  try {
    verovioModule.FS_unlink('/data/text/Times.xml');
  } catch (exception) {
    console.error('failed to remove old xml file');
  }

  try {
    verovioModule.FS_createDataFile(
      '/data/text',
      'Times.xml',
      FONT_CHARACTER_SIZING_OVERRIDE,
      true,
      true,
      false
    );
  } catch (exception) {
    console.error('error writing XML file');
  }
};
const domParser = new DOMParser();
const sopLayerSelector = 'g.staff:not(g.staff ~ g.staff) > g.layer:not(g.layer ~ g.layer)';
const syllableSelector = 'g.syl.bounding-box';
const noteSelector = 'g.note:not(.bounding-box):not([id^="invisible-note-"])';
const countMeasuresInSvgs = (svgs: string[]) => {
  // reverse the array so that the last system is first, and we don't need to query for all systems.
  svgs.reverse();
  const svg = `<svg>${svgs.join('')}</svg>`;
  svgs.reverse();
  const doc = domParser.parseFromString(svg, 'image/svg+xml');
  const lastSystem = doc.querySelector('g.system:not(.bounding-box)') ?? doc;
  const possibleLineBreaks = doc.querySelectorAll('g.measure.bounding-box').length;
  const measures = lastSystem.querySelectorAll('g.measure g.barLine.bounding-box > rect[width]:not([width="0"])').length;
  const notes = lastSystem.querySelectorAll(sopLayerSelector + ' ' + noteSelector);
  const syllables = Array.from(notes).filter(note => note.querySelector(syllableSelector)).length;
  const allSyllables = doc.querySelectorAll(syllableSelector).length;
  let effectiveNotes = notes.length;
  let effectiveSyllables = syllables;
  const firstStemBbox = doc.querySelector('g.note:not([id^=invisible-]) > g.stem > g.stem.bounding-box > rect');
  const firstStemWidth = Number(firstStemBbox?.getAttribute('width') ?? 0);
  const firstStemHeight = Number(firstStemBbox?.getAttribute('height') ?? 0);
  const isChant = firstStemHeight <= 3 * firstStemWidth;
  if (isChant) {
    // we have to take into account the case where there is an amen on the last system, but more before it;
    // in this case, we want to ignore the Amen, and make sure the other content is enough for a system by itself
    const fullAndDoubleBars = Array.from(
      lastSystem.querySelectorAll<SVGGElement>('g.measure:not([id^="chantbarmeasure-"])')
    ).filter(measure => measure.querySelector(':scope > g.barLine > path'));
    if (fullAndDoubleBars.length > 1) {
      const lastMeasure = fullAndDoubleBars.pop()!;
      const lastMeasureNotes = lastMeasure.querySelectorAll(sopLayerSelector + ' ' + noteSelector);
      effectiveNotes -= lastMeasureNotes.length;
      effectiveSyllables -= Array.from(lastMeasureNotes).filter(note => note.querySelector(syllableSelector)).length;
    }
  }
  let lastSystemWidth = 0;
  lastSystem.querySelectorAll<SVGRectElement>('g.staff.bounding-box > rect[width]').forEach(staff => {
    lastSystemWidth += staff.width.baseVal.value;
  });
  return {
    measures,
    syllables,
    notes: notes.length,
    effectiveSyllables,
    effectiveNotes,
    isChant,
    allSyllables,
    possibleLineBreaks,
    lastSystemWidth,
  };
};
const relayoutWithNewSpacing = (vrvToolkit: VerovioToolkit, newSpacing: number) => {
  vrvToolkit.setOptions({ spacingNonLinear: newSpacing });
  vrvToolkit.redoLayout();
  return vrvToolkit.getPageCount();
};
const onRenderSvgs: VerovioPostProcessor = (svgs, vrvToolkit) => {
  if (svgs.length < 2) return;
  try {
    const { measures, notes, syllables, effectiveNotes, effectiveSyllables, isChant } = countMeasuresInSvgs(svgs);
    if (isChant ? (effectiveSyllables < 3 && effectiveNotes < 7) : (measures < 2)) {
      // TODO: remove these debug lines, or put them behind the debug flag
      console.info({ isChant, syllables, notes, measures, effectiveNotes, effectiveSyllables, systems: svgs.length })
      const { spacingNonLinear = 0.57 } = vrvToolkit.getOptions();
      // TODO: what is a good measure for this heuristic of how much to tighten the spacing?
      // a count of 6 systems is definitely arbitrary, and I have no idea if it is reasonable or not,
      // but sufficiently long music definitely requires less tightening of the spacing to produce a change.
      let newSpacing = (svgs.length < 6 || !isChant) ? minSpacingNonLinear : (minSpacingNonLinear + spacingNonLinear) / 2;
      let pageCount = relayoutWithNewSpacing(vrvToolkit, newSpacing);

      let needToHaveFewerSystems = true;
      if (isChant && effectiveNotes !== notes && pageCount === svgs.length) {
        // in cases when effective notes were different than notes (i.e., there was verse content on the last system before an amen)
        // we might not save a system, but still want to use the new spacing
        const { notes, effectiveNotes } = countMeasuresInSvgs([vrvToolkit.renderToSVG(pageCount)]);
        // if the new spacing settings produce a last system that still has content before the amen,
        // then we need to have saved a system in order to want to use the new spacing.
        // (contrariwise, if the new spacing settings produce a last system with only a single barline, its only content is the amen
        // and we do want to use it even though the number of systems was not reduced)
        needToHaveFewerSystems = notes !== effectiveNotes;
        console.info({ needToHaveFewerSystems, notes, effectiveNotes })
      }
      if (pageCount === svgs.length && needToHaveFewerSystems) {
        let unacceptableSpacing = false;
        console.info('didn’t save a system by compressing to', newSpacing);
        // we didn't save a system by compressing the spacing; we will have to expand the spacing untill we get more measures on the last system
        const addAmount = (maxSpacingNonLinear - spacingNonLinear) / (svgs.length - 1);
        let iterations = 0;
        for (newSpacing = spacingNonLinear + addAmount; iterations < 4 && newSpacing <= maxSpacingNonLinear; newSpacing += addAmount) {
          ++iterations;
          unacceptableSpacing = false;
          console.info(`trying `, {newSpacing, iterations});
          pageCount = relayoutWithNewSpacing(vrvToolkit, newSpacing);
          if (pageCount > svgs.length) {
            console.info('too many systems at', newSpacing, { pageCount });
            // we don't want to increase the number of systems, so if that has happened, we will cut our additional spacing in half,
            // and break out of the loop
            newSpacing -= addAmount / 2;
            pageCount = relayoutWithNewSpacing(vrvToolkit, newSpacing);
            unacceptableSpacing = pageCount > svgs.length;
            console.info({ pageCount, newSpacing, unacceptableSpacing });
            break;
          } else {
            const { notes: newNotes, syllables: newSyllables } = countMeasuresInSvgs([vrvToolkit.renderToSVG(pageCount)]);
            if (notes !== newNotes || syllables !== newSyllables) {
              console.info({notes, newNotes, syllables, newSyllables, newSpacing});
              break;
            } else {
              console.info(`spacing didn't change anything`);
              unacceptableSpacing = true;
            }
          }
        }
        if (unacceptableSpacing) {
          // we couldn't find a new spacing that was effective
          return;
        }
      }
      return Array.from(Array(pageCount), (_, idx) => vrvToolkit.renderToSVG(idx + 1));
    }
  } catch (exception) {
    return;
  }
};

export type RenderMidiCallback = (base64Midis: string[], timeMaps: TimeMapEntry[][]) => void;
export type LoadDataCallback = (vrvToolkit: VerovioToolkit) => void;

export const Verovio: React.FC<{
  mei: string;
  midiMei?: string;
  midiPassthru?: MeiMidi;
  config?: VerovioOptions;
  postProcessConfig?: VerovioPostProcessConfig;
  addFirstLastPageClassNames?: boolean;
  className?: string;
  style?: Partial<CSSStyleDeclaration>;
  spinnerClassName?: string;
  onLoadData?: LoadDataCallback;
  onRenderMidi?: RenderMidiCallback;
  midiOnly?: boolean;
}> = ({ mei, midiMei, midiPassthru, config = {}, postProcessConfig, className, style, addFirstLastPageClassNames, spinnerClassName, onLoadData, onRenderMidi, midiOnly }) => {
  const onRenderMidiCallback = useCallback((base64midi: string, timeMap: TimeMapEntry[]) => {
    onRenderMidi?.([base64midi], [timeMap]);
  }, [onRenderMidi]);
  // if midiPassthru is set, we have to make sure we don't pass onRenderMidi to <Verovio> or it will
  // process its own MIDI.  instead, we want to use the midiPassthru and set it as the MIDI data:
  const setRenderedMidi = (!midiPassthru && onRenderMidi) ? onRenderMidiCallback : undefined;
  useEffect(() => {
    if (midiPassthru && onRenderMidi) {
      onRenderMidi([midiPassthru.midi], [midiPassthru.timeMap]);
    }
  }, [midiPassthru, onRenderMidi])
  // memoize the postProcessConfig based on actual changes to the properties
  // to prevent re-rendering the verovio just because the object changed.
  const {
    addStanzaNumbersOnFirstSystem,
    addStanzaNumbersOnAllSystems,
    firstStanzaNumber,
    replaceWholeNoteWithBreve,
    stanzaCount,
  } = postProcessConfig || {};
  const memoizedPostProcessConfig: VerovioPostProcessConfig = useMemo(() => ({
    addStanzaNumbersOnFirstSystem,
    addStanzaNumbersOnAllSystems,
    firstStanzaNumber,
    replaceWholeNoteWithBreve,
    stanzaCount,
  }), [
    addStanzaNumbersOnFirstSystem,
    addStanzaNumbersOnAllSystems,
    firstStanzaNumber,
    replaceWholeNoteWithBreve,
    stanzaCount,
  ]);
  postProcessConfig = postProcessConfig && memoizedPostProcessConfig;
  const onPostProcessSvg: VerovioSvgPostProcessor = useCallback(
    (svg: string, svgInfo: VerovioSvgInfo) =>
      postProcessConfig ? postProcessVerovioSvg(svg, svgInfo, config, postProcessConfig) : svg,
    [config, postProcessConfig]
  );

  const originalPageWidth = config.pageWidth ?? 0;
  const pageWidth = getContentWidthForExsurge(originalPageWidth);
  const _config = useMemo(
    () => ({
      ...config,
      ...(config.adjustPageWidth ? { pageWidth: 60000 } : { pageWidth }),
    }),
    [config, pageWidth],
  );
  if (!config.adjustPageWidth && originalPageWidth !== pageWidth) {
    style = style || {};
    style.width = `${100 * pageWidth / originalPageWidth!}%`;
  }

  // never show a spinner if midiOnly is set
  // if spinnerClassName is set, add it to a parent div
  const _spinner = useMemo(
    () =>
      spinnerClassName && !midiOnly ? (
        <div className={spinnerClassName}>{spinner}</div>
      ) : midiOnly ? null : (
        spinner
      ),
    [spinnerClassName, midiOnly],
  );
  
  return useMemo(
    () => (
      <Suspense fallback={spinner}>
        <LazyVerovio
          mei={mei}
          midiMei={midiMei}
          config={_config}
          addFirstLastPageClassNames={addFirstLastPageClassNames}
          className={className}
          style={style}
          onPostProcessSvg={onPostProcessSvg}
          onInit={onInit}
          onLoadData={onLoadData}
          onRenderMidi={setRenderedMidi}
          midiOnly={midiOnly}
          onRenderSvgs={onRenderSvgs}
        >
          {_spinner}
        </LazyVerovio>
      </Suspense>
    ),
    [mei, midiMei, _config, addFirstLastPageClassNames, className, style, onPostProcessSvg, onLoadData, setRenderedMidi, midiOnly, _spinner]
  );
};
