import { getBooleanParam, getStringParam } from 'utils/params/getParam';
import { DebugLogger, emptyLogger } from './DebugLogger';

/**
 * creates an object that can be used to log messages used in debugging.
 * Output for a logger can be enabled or disabled according to the `debug`
 * URL parameter, which is passed like so: `sourceandsummit.com/some/url?debug=list,of,keys`
 * if the given key exists in the URL, the logger will output.
 */
export const createDebugLogger = (
  key: string,
  debug: string[] = getStringParam('debug')?.split(',') ?? [],
  trace: boolean = !!getBooleanParam('trace')
): DebugLogger => {
  // if debug parameter list contains the given key
  if (debug.includes(key)) {
    // if we matched, define a log and an error function.
    const log = (...logMessage: unknown[]) => {
      // main message is placed in a group with the stack trace
      // debug message is prefixed with where this message is coming from
      if (trace) {
        console.groupCollapsed(`${key}:`, ...logMessage);
        console.trace();
        console.groupEnd();
      } else {
        console.log(`${key}:`, ...logMessage);
      }
    };
    const error = (error: unknown, message?: string) => {
      console.groupCollapsed(`${key}: ${message ? message : 'error'}`);
      console.error(error);
      if (trace) {
        console.trace();
      }
      console.groupEnd();
    };
    const info = (infoItem: unknown, message?: string) => {
      console.groupCollapsed(`${key}: ${message ? message : 'info'}`);
      console.info(infoItem);
      if (trace) {
        console.trace();
      }
      console.groupEnd();
    };
    // return the actual DebugLogger object
    return { log, error, info };
  }

  // if there's no debug in the URL return empty logger with no-op log functions
  return emptyLogger;
}
