import { verovioTextUndertie } from 'components/shared/FormattedVerseSegment';
import { isImgElement, isSvgElement } from 'utils/typescript/ElementTypeGuards';
import {
  FontOption,
  PdfBlock,
  PdfColumnInfo,
  PdfImageBlock,
  PdfTextBlock,
  PdfTextColumnsBlock,
  PdfTextSpan,
} from './PdfBlockTypes';

// Page break classes are found in _print.scss
export enum PageBreakClass {
  PageBreakAfter = 'page-break-after',
  NoPageBreakAfter = 'no-page-break-after',
  NoPageBreakInside = 'no-page-break-inside',
  PageBreakBefore = 'page-break-before',
  NoPageBreakBefore = 'no-page-break-before',
}
export enum ColumnBreakClass {
  AllowColumnBreakAfter = 'allow-column-break-after',
  AllowColumnBreakBefore = 'allow-column-break-before',
}
const twoColumnsClass = 'columns-2';
const centerColumnsClass = 'columns-center';
const exportAsTableClass = '_export-as-table';

const pixelMultiplier = 396 / 590;

export const convertToPdfBlocks = (el: Element, options?: PdfBlock['options']): PdfBlock[] => {
  if (!(el instanceof HTMLElement || el instanceof SVGSVGElement)) {
    // I'm not sure if this would ever happen, but it simplifies the typescript typing.
    return [];
  }
  const pdfBlocks = convertToPdfSpans(el) as PdfBlock[];
  if (pdfBlocks.length > 1) {
    pdfBlocks.reduce((prev, curr) => {
      // add in links to the previous block for any matching multipart IDs
      // to handle left and right alignments on the same line
      if (prev.multipartId && prev.multipartId === curr.multipartId) {
        prev.pageBreakAfter = false;
        curr.continuedFrom = prev;
        curr.options = { ...curr.options, align: 'right' };
        curr.spans.forEach(
          (span) => (span.fontSize = span.fontSize || curr.fontSize),
        );
        curr.fontSize = prev.fontSize;
      }
      return curr;
    });
  }
  if (
    pdfBlocks.length > 0 &&
    (el.dataset.exportPadTop || el.dataset.exportPadBottom)
  ) {
    const top = Number(el.dataset.exportPadTop ?? '0') * pixelMultiplier;
    const bottom = Number(el.dataset.exportPadBottom ?? '0') * pixelMultiplier;
    if (top) {
      pdfBlocks[0].margins = {
        ...pdfBlocks[0].margins,
        top: (pdfBlocks[0].margins?.top ?? 0) + top,
      };
    }
    if (bottom) {
      const lastPdfBlock = pdfBlocks[pdfBlocks.length - 1];
      lastPdfBlock.margins = {
        ...lastPdfBlock.margins,
        bottom: (lastPdfBlock.margins?.bottom ?? 0) + bottom,
      };
    }
  }
  if (options) {
    for (const block of pdfBlocks) {
      block.options = { ...block.options, ...options };
    }
  }
  return pdfBlocks;
};

if (process.env.NODE_ENV !== 'production') {
  (window as any).toBlocks = convertToPdfBlocks;
}

interface MarginAndPadding {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  borderWidth?: number;
  borderRadius?: number;
  borderPadding?: number;
  backgroundColor?: PDFKit.Mixins.ColorValue;
}

const convertFromPixelString = (pixels: string) =>
  parseInt(pixels || '0', 10) * pixelMultiplier;

const fromMarginAndPadding = (marginAndPadding?: MarginAndPadding) => {
  return marginAndPadding
    ? {
        top: marginAndPadding.marginTop + marginAndPadding.paddingTop,
        left: marginAndPadding.marginLeft + marginAndPadding.paddingLeft,
        bottom: marginAndPadding.marginBottom + marginAndPadding.paddingBottom,
        right: marginAndPadding.marginRight + marginAndPadding.paddingRight,

        borderRadius: marginAndPadding.borderRadius ?? 0,
        borderWidth: marginAndPadding.borderWidth ?? 0,
        borderPadding: marginAndPadding.borderPadding ?? 0,
        backgroundColor: marginAndPadding.backgroundColor,
      }
    : {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        
        borderRadius: 0,
        borderWidth: 0,
        borderPadding: 0,
        backgroundColor: undefined,
      };
};

const fontFamilyForPdf = (fontFamily: string, text = '') => {
  if (fontFamily.match(/^["']?Versiculum['"]?(?:$|,)/) && text.match(/^[arv]/)) {
    return 'Versiculum';
  } else if (fontFamily.match(/^["']?VerovioText["']?\b/) && text === verovioTextUndertie) {
    return 'VerovioText';
  }
}

const regexUnderline = /\bunderline\b/u;

const spanForTextNode = (
  computedStyle: CSSStyleDeclaration,
  textContent?: string | null,
  { link, goTo, underline }: Pick<
  PDFKit.Mixins.TextOptions,
  | 'link'
  | 'goTo'
  | 'underline'
  > = {},
) => {
  const { fontWeight, fontStyle, textDecoration, fontVariant, whiteSpace } = computedStyle;
  const preserveWhitespace = /\bpre\b/.test(whiteSpace);
  let features = ({
    "small-caps": ["smcp"],
    "all-small-caps": ["c2sc", "smcp"],
    "petite-caps": ["pcap"],
    "all-petite-caps": ["c2pc", "pcap"],
    "unicase": ["unic"],
    "titling-caps": ["titl"],
  } satisfies {
    [key: string]: PDFKit.Mixins.OpenTypeFeatures[]
  })[fontVariant];
  const bold = Number(fontWeight) >= 600 || fontWeight.match(/bold/);
  const medium = Number(fontWeight) >= 500 || fontWeight.match(/medium/);
  const italic = fontStyle === 'italic';
  const fontOptions =
    (`${bold ? 'Bold' : medium ? 'Medium' : ''}${
      italic ? 'Italic' : ''
    }` as FontOption) || undefined;
  let text = (textContent || '');
  if (!preserveWhitespace) text = text.replace(/[ \r\n\t]+/g, ' ');
  if (computedStyle.textTransform === 'uppercase') {
    text = text.toUpperCase();
  }
  const span: PdfTextSpan = {
    text,
    fontOptions,
    fontSize: convertFromPixelString(computedStyle.fontSize),
    preserveWhitespace,
  };
  underline = underline || regexUnderline.test(textDecoration);
  if (underline || link || goTo || features) {
    span.options = { underline, link, goTo, features };
  }
  const fontFamily = fontFamilyForPdf(computedStyle.fontFamily, text);
  if (fontFamily) {
    span.fontFamily = fontFamily;
  }
  const { right } = fromMarginAndPadding(getMarginAndPadding(computedStyle));
  if (right) {
    span.marginRight = right;
  }
  if (textContent === '\n') {
    span.newline = true;
  }
  return span;
};

const parseContentFromStyle = (computedStyle: CSSStyleDeclaration) => {
  try {
    const content = computedStyle.content?.replace(/^'(.*)'$/, '"$1"');
    if (content === 'none') return undefined;
    return /^"/.test(content) ? JSON.parse(content) : content;
  } catch (exception) {
    throw new Error(`Error parsing "${computedStyle.content}" as JSON: ${exception}`);
  }
}

const getMarginAndPadding = (
  computedStyle: CSSStyleDeclaration,
  outerMarginAndPadding?: MarginAndPadding,
  useOuterTop = true,
  useOuterBottom = true,
) => {
  const sides = ['Top', 'Bottom', 'Left', 'Right'] as const;
  const result = Object.fromEntries(
    ['margin', 'padding']
      .flatMap((marPad) => sides.map((side) => marPad + side))
      .map((key) => [key, convertFromPixelString(computedStyle[key as any])]),
  ) as any as MarginAndPadding;
  const borderStyle = computedStyle.borderStyle;
  const borderWidth = convertFromPixelString(computedStyle.borderWidth);
  const backgroundColor = computedStyle.backgroundColor;
  const rgbaBackgroundColorMatch = /rgba\((\d*(?:\.\d+)?), (\d*(?:\.\d+)?), (\d*(?:\.\d+)?), (\d*(?:\.\d+)?)\)/.exec(backgroundColor);
  const rgbaBackgroundColor = rgbaBackgroundColorMatch && rgbaBackgroundColorMatch.slice(1).map(Number);
  if ((borderWidth || rgbaBackgroundColor) && borderStyle !== 'none') {
    result.borderWidth = borderWidth;
    result.borderRadius = convertFromPixelString(computedStyle.borderRadius);
    if ((rgbaBackgroundColor?.[3] ?? 0) > 0 && rgbaBackgroundColor!.slice(0, 3).every(val => val === 0)) {
      // (for now, we are only supporting variants of black, as CMYK)
      result.backgroundColor = [0, 0, 0, rgbaBackgroundColor![3] * 100];
    }
    const paddings = sides.map(side => result[`padding${side}`]);
    if (paddings[0] && paddings.every(val => val === paddings[0])) {
      result.borderPadding = paddings[0];
      sides.forEach(side => result[`padding${side}`] = 0);
    }
  }
  if (outerMarginAndPadding) {
    Object.entries(outerMarginAndPadding).forEach(([key, value]) => {
      if (key.match(/Top$/) && !useOuterTop) return;
      if (key.match(/Bottom$/) && !useOuterBottom) return;
      if (key.match(/^border/)) {
        result[key as keyof MarginAndPadding] ||= value;
      } else {
        result[key as keyof MarginAndPadding] += value;
      }
    });
  }
  return result;
};

interface State {
  list?: {
    type: 'number';
    number: number;
    text?: undefined;
  } | {
    type: 'disc';
    number?: undefined;
    text?: '\u2022';
  }
  multipartId: number;
}

const isOListElement = (el: Element): el is HTMLOListElement =>
  el.tagName === 'OL';
const isUListElement = (el: Element): el is HTMLUListElement =>
  el.tagName === 'UL';

const convertToPdfSpans = (
  el: Element,
  forceBlock = true,
  outerMarginAndPadding?: MarginAndPadding,
  outerComputedStyle?: CSSStyleDeclaration,
  useTopMargin = false,
  useBottomMargin = false,
  state: State = { multipartId: 0 },
  columnInfo?: PdfColumnInfo,
  pseudoEl?: string,
): (PdfTextSpan | PdfBlock)[] => {
  const computedStyle = getComputedStyle(el, pseudoEl);
  if (computedStyle.visibility === 'hidden' || computedStyle.opacity === '0') {
    return [];
  }
  const { display, flexDirection, width, minWidth, maxWidth, float } = computedStyle;
  const isFlex = display.endsWith('flex');
  const flexColumn = isFlex && flexDirection === 'column';
  const exportAsTable = el.classList.contains(exportAsTableClass);
  if (isFlex) {
    // we use the multipartId to indicate when two blocks are part of the same flex block,
    // so whenever we see a flex, we increment the multipartId
    ++state.multipartId;
  }
  const parentIsFlex = outerComputedStyle?.display?.endsWith('flex');
  const parentJustifyContent = parentIsFlex
    ? outerComputedStyle?.justifyContent
    : null;
  // this is a bit of a hack to handle the one place where flex is used, in the alleluia response:
  const forceNotBlock = parentJustifyContent === 'normal';
  const widthIsSet = minWidth === maxWidth && minWidth === width;
  let isBlock = forceBlock || (!forceNotBlock && (!display.match(/inline/) || widthIsSet));
  const pageBreakInfo: { [key in (keyof typeof PageBreakClass | keyof typeof ColumnBreakClass)]?: boolean; } =
    isBlock
      ? Object.fromEntries(
          Object.entries<PageBreakClass|ColumnBreakClass>(PageBreakClass).concat(Object.entries(ColumnBreakClass)).map(([key, value]) => [
            key,
            el.classList.contains(value),
          ]),
        )
      : {};
  const columns: PdfColumnInfo | undefined = el.classList.contains(twoColumnsClass) ? {
    count: 2,
    gap: 12,
    center: el.classList.contains(centerColumnsClass),
  } : columnInfo;
  const marginAndPadding = getMarginAndPadding(
    computedStyle,
    outerMarginAndPadding,
    useTopMargin,
    useBottomMargin,
  );
  const margins = fromMarginAndPadding(marginAndPadding);
  const spans: (PdfTextSpan | PdfBlock)[] = [];
  if (isImgElement(el) || isSvgElement(el)) {
    const block: PdfImageBlock = {
      element: el,
      margins,
      width: el.clientWidth * pixelMultiplier || undefined,
      height: el.clientHeight * pixelMultiplier || undefined,
    };
    if (isImgElement(el) && block.width && block.height) {
      block.src = el.src;
      const fetchSrc = (): Promise<ArrayBuffer> => fetch(el.src, { cache: 'force-cache' }).then(
        (response) => response.arrayBuffer(),
      ).then(buffer => {
         const textDecoder = new TextDecoder();
        const data = new Uint8Array(buffer.slice(0, 4));
        if (data[0] === 0xff && data[1] === 0xd8) {
          // jpeg, handled by pdfkit directly
          return buffer;
        } else if (data[0] === 0x89 && textDecoder.decode(data.slice(1,4)) === 'PNG') {
          // PNG, handled by pdfkit directly
          return buffer;
        } else if (textDecoder.decode(data.slice(0,3)) === 'GIF') {
          // GIF: must convert to png
        } else {
          // something else; we will have to try drawing it to a canvas
        }
        // It wasn't a PNG or a JPG, so we will draw it to the canvas and then convert to a PNG
        return new Promise((resolve, reject) => {
          const canvas = document.createElement('canvas');
          canvas.height = el.naturalHeight;
          canvas.width = el.naturalWidth;
          const canvasContext = canvas.getContext('2d');
          if (!canvasContext) {
            reject(new Error('Unable to create canvas context to convert image to png'));
            return;
          }

          canvasContext.drawImage(el, 0, 0);
          try {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(new Response(blob).arrayBuffer());
              } else {
                reject(new Error('Failed to retrieve canvas as blob'));
              }
            }, 'image/png');
          } catch (e) {
            reject(e);
          }
        });
      });
      block.getImageBuffer = async () =>
        block._imageBuffer || (block._imageBuffer = await fetchSrc());
    }
    return [block];
  } else if (display !== 'none') {
    if (pseudoEl) {
      // just like a text node below, but the text content comes from the CSS:
      const textContent = parseContentFromStyle(computedStyle);
      if (textContent) {
        spans.push(spanForTextNode(computedStyle, textContent));
      }
    } else {
      let elChildNodes = Array.from(el.childNodes);
      if (isBlock && elChildNodes.length) {
        // filter out whitespace-only text nodes from beginning and end on block elements
        if (
          elChildNodes[0].nodeType === Node.TEXT_NODE &&
          elChildNodes[0].textContent?.match(/^[ \r\n]*$/)
        ) {
          elChildNodes.shift();
        }
        const lastChild = elChildNodes[elChildNodes.length - 1];
        if (
          lastChild &&
          lastChild.nodeType === Node.TEXT_NODE &&
          lastChild.textContent?.match(/^[ \r\n]*$/)
        ) {
          elChildNodes.pop();
        }
      }
      let pseudoNodes: string[] = [];
      if (el.tagName === 'INPUT') {
        const inputEl = el as HTMLInputElement;
        const value = inputEl.value;
        if (value) {
          pseudoNodes = [value];
        }
      }
      let childNodes = [':before', ...pseudoNodes, ...elChildNodes, ':after'].filter(
        (childNode) =>
          typeof childNode === 'string'
            ? !pseudoEl && (getComputedStyle(el, childNode).content || 'none') !== 'none'
            : childNode.nodeType === Node.TEXT_NODE ||
              (childNode.nodeType === Node.ELEMENT_NODE &&
                getComputedStyle(childNode as HTMLElement).display !== 'none'),
      );
      let firstNodeI = 0;
      const lastNodeI = childNodes.length - 1;
      if (isOListElement(el)) {
        state.list = { number: el.start, type: 'number' };
      } else if (isUListElement(el) && computedStyle.listStyleType === 'disc') {
        state.list = { type: 'disc', text: '•' };
      } else if (el.tagName === 'LI' && (state.list || childNodes[0] === ':before')) {
        let text = state.list?.text || (state.list && computedStyle.listStyleType === 'decimal' && state.list.type === 'number' && `${state.list.number}.`);
        let style = computedStyle;
        let yOffset = 0;
        if (!text && childNodes[0] === ':before') {
          const computedStyleOfBefore = getComputedStyle(el, ':before');
          text = parseContentFromStyle(computedStyleOfBefore);
          if (text) {
            childNodes.shift();
            style = computedStyleOfBefore;
            yOffset = convertFromPixelString(computedStyle.fontSize) - convertFromPixelString(style.fontSize);
          }
        }
        if (text) {
          // write a list item number as an absolute block at the far left of this element:
          const { top } = margins;
          const { left } = fromMarginAndPadding(
            getMarginAndPadding(outerComputedStyle || computedStyle),
          );
          const right = 0.2 * convertFromPixelString(computedStyle.fontSize);
          const pdfBlock: PdfTextBlock = {
            height: 0,
            width: left,
            margins: { top: top, right },
            fontSize: convertFromPixelString(style.fontSize),
            spans: [{ text, yOffset }],
            options: { align: 'right' },
            ...(columns && { columns }),
          };
          const fontFamily = fontFamilyForPdf(style.fontFamily, text);
          if (fontFamily) {
            pdfBlock.spans[0].fontFamily = fontFamily;
          }
          spans.push(pdfBlock);
          // change firstNodeI so that the top margin/padding doesn't get handled again below,
          firstNodeI = -1;
        }
        if (state.list?.type === 'number') ++state.list.number;
      }
      if (isBlock && childNodes.length === 0) {
        // if this is a block element with no children, it still needs to process so that a break will be forced
        // if it is next to inline elements, so we add an empty text node:
        spans.push({ text: '' });
      }
      const link = el.tagName === 'A' ? (el as HTMLAnchorElement).href : undefined;
      const goTo = (el instanceof HTMLElement) ? el.dataset.goTo : undefined;
      childNodes.forEach((childNode, nodeI, nodes) => {
        const prevNode = nodes[nodeI - 1];
        const nextNode = nodes[nodeI + 1];
        const isFirstNode = nodeI === firstNodeI;
        const isLastNode = nodeI === lastNodeI;
        const {
          node,
          nodeType,
          pseudoEl = undefined,
        } = typeof childNode === 'string'
          ? {
              node: el,
              nodeType: /^:(before|after)$/.test(childNode)
                ? Node.ELEMENT_NODE
                : Node.TEXT_NODE,
              pseudoEl: childNode,
            }
          : { node: childNode, nodeType: childNode.nodeType };
        switch (nodeType) {
          case Node.TEXT_NODE:
            const preserveWhitespace = /\bpre\b/.test(computedStyle.whiteSpace);
            let textContent = (pseudoEl || node.textContent) ?? undefined;
            if (!preserveWhitespace) textContent = textContent?.replace(/[ \r\n\t]+/g, ' ');
            const isWhitespace = !textContent?.trim();
            if (textContent && isWhitespace && prevNode instanceof HTMLElement && nextNode instanceof HTMLElement && spans.every(span => span.spans || span.element || span.columnBlocks)) {
              // ignore whitespace between two div tags or two li tags
              if (prevNode.tagName === nextNode.tagName && /^(?:div|li)$/i.test(prevNode.tagName)) {
                textContent = '';
              }
            }
            if (textContent) {
              const underline = regexUnderline.test(outerComputedStyle?.textDecoration ?? '');
              spans.push(spanForTextNode(computedStyle, textContent, { link, goTo, underline }));
            }
            break;
          case Node.ELEMENT_NODE:
            const element = node as HTMLElement;
            if (element.tagName === 'BR') {
              const lastSpan = spans[spans.length - 1];
              if (lastSpan && 'text' in lastSpan && !lastSpan.newline) {
                lastSpan.newline = true;
              } else {
                spans.push(spanForTextNode(computedStyle, '\n'));
              }
            } else {
              spans.push(
                ...convertToPdfSpans(
                  element,
                  flexColumn || exportAsTable, // force block on flexColumn children
                  marginAndPadding,
                  computedStyle,
                  isFirstNode,
                  isLastNode,
                  state,
                  columns,
                  pseudoEl,
                ),
              );
            }
            break;
        }
      });
    }
  }
  // here we group together the span elements into blocks.
  // anything with an element property is already considered a block, so it doesn't get touched
  // anything with a spans property or a columnBlocks property has already been grouped into a block.
  if (isBlock && spans.some((span) => !(span.spans || span.element || span.columnBlocks))) {
    let firstSpanI = -1;
    const { borderRadius, borderWidth, borderPadding, backgroundColor, ...margins } = fromMarginAndPadding(marginAndPadding);
    for (let i = 0; i <= spans.length; i++) {
      const span = spans[i];
      if (i === spans.length || span.spans) {
        // this element is a block, or out of bounds, so if we have a firstSpanI, combine all intervening spans into a block:
        if (firstSpanI >= 0) {
          const count = i - firstSpanI;
          const spansForBlock = spans.splice(
            firstSpanI,
            count,
          ) as PdfTextSpan[];
          const [firstSpan] = spansForBlock;
          while (!firstSpan?.preserveWhitespace && firstSpan?.text.match(/^[ \r\n\t]+/)) {
            spansForBlock[0].text = spansForBlock[0].text.replace(
              /^[ \r\n\t]+/,
              '',
            );
            if (!spansForBlock[0].text) {
              spansForBlock.shift();
            }
          }
          const item: PdfTextBlock = {
            margins,
            options: {
              indent: convertFromPixelString(computedStyle.textIndent),
            },
            spans: spansForBlock,
            ...(columns && { columns }),
          };
          if (borderWidth || backgroundColor) {
            item.borderWidth = borderWidth;
            item.borderRadius = borderRadius;
            item.borderPadding = borderPadding;
            item.backgroundColor = backgroundColor;
          }
          if (parentJustifyContent === 'space-between') {
            // for flex children, so that they can all be on the same line
            item.multipartId = state.multipartId;
          }
          if (item.multipartId || item.spans.some((span) => span.fontFamily)) {
            item.fontSize = convertFromPixelString(computedStyle.fontSize);
          }
          if (computedStyle.position === 'absolute') {
            item.height = 0;
            // hack for now to just use the outer marginAndPadding and remove text indent:
            const offset = fromMarginAndPadding(outerMarginAndPadding);
            margins.left -= offset.left;
            item.options!.indent = 0;
            if (outerComputedStyle) {
              item.fontSize = convertFromPixelString(
                outerComputedStyle!.fontSize,
              );
            }
            if (computedStyle.textAlign === 'right') {
              // a further hack to handle right aligned hymn verse numers
              item.width = offset.left;
              margins.right = 0.2 * convertFromPixelString(computedStyle.fontSize);
            }
          }
          if (['center', 'right'].includes(computedStyle.textAlign)) {
            item.options = { align: computedStyle.textAlign as 'center'|'right' };
          }
          spans.splice(firstSpanI, 0, item);
          i -= count - 1;
          firstSpanI = -1;
        }
      } else if (!span.element && firstSpanI < 0) {
        firstSpanI = i;
      }
    }
  }

  // now we process page break classes:
  if (isBlock && spans.length) {
    const blocks = spans as PdfBlock[];
    const lastBlock = blocks[blocks.length - 1];
    if (pageBreakInfo.NoPageBreakInside) {
      // this will override any page break information of child elements
      blocks.slice(0, -1).forEach(block => block.columnBreakAfter = block.pageBreakAfter = false);
    }
    if (pageBreakInfo.PageBreakAfter) {
      lastBlock.pageBreakAfter = true;
    } else if (pageBreakInfo.NoPageBreakAfter) {
      lastBlock.pageBreakAfter = false;
      lastBlock.columnBreakAfter = pageBreakInfo.AllowColumnBreakAfter ? undefined : false;
    }
    if (pageBreakInfo.PageBreakBefore) {
      blocks[0].pageBreakBefore = true;
    } else if (pageBreakInfo.NoPageBreakBefore) {
      blocks[0].pageBreakBefore = false;
      blocks[0].columnBreakBefore = pageBreakInfo.AllowColumnBreakBefore ? undefined : false;
    }
    if (widthIsSet) {
      blocks[0].width = convertFromPixelString(width);
    }
    if (float === 'right') {
      blocks[0].float = 'right';
    }
  }
  if (exportAsTable && spans.length) {
    const columnBlock: PdfTextColumnsBlock = {
      columnBlocks: (spans as any[]),
      margins,
    };
    columnBlock.columnBlocks.forEach(block => {
      block.rowParent = columnBlock;
      block.fontSize = block.spans.find((span) => span.fontSize)?.fontSize;
    });
    return [columnBlock];
  }
  return spans;
};
