import { useBackendLoggedIdQuery } from 'hooks/queries/useQuery';
import { OrdoCard } from 'queries/card-fragment';
import { GetCardOptions, GetInvariableTextCategoryOptions } from 'queries/text-option';
import { CardTypes } from 'utils/ordo/card/CardTypes';

export const useCardOptionsQuery = (card: OrdoCard, skip = false) => {
  // we query differently if the card is an antiphon or an alleluia
  const isAlleluia = card.type === CardTypes.ALLELUIA || card.type === CardTypes.VERSE_BEFORE_GOSPEL;
  const isAntiphon = [
    CardTypes.ANTIPHON_VERSE,
    CardTypes.ANTIPHON_VERSE_GLORIA_PATRI,
    CardTypes.ENTRANCE_CHANT,
    CardTypes.COMMUNION_CHANT,
    CardTypes.RESPONSORIAL_PSALM
  ].includes(card.type);
  // if the card is not a type that ever needs text options, we'll always return null for it
  const isNonTextOptionsCard = [
    CardTypes.HYMN,
    CardTypes.CHORAL,
    CardTypes.INSTRUMENTAL,
    CardTypes.REFERENCE,
    CardTypes.SELECT,
    CardTypes.PRAYER,
  ].includes(card.type);
  const isInvariableOrdinaryCard = card.textCategory?.isInvariableOrdinary;
  const cardId = isAlleluia || isNonTextOptionsCard || isInvariableOrdinaryCard ? null : card.id;
  const textCategoryId = isInvariableOrdinaryCard ? card.textCategory?.id : null;
  const variableOptionsQuery = useBackendLoggedIdQuery(GetCardOptions, skip ? null : cardId);
  const invariableOptionsQuery = useBackendLoggedIdQuery(GetInvariableTextCategoryOptions, skip ? null : textCategoryId);
  const result = isInvariableOrdinaryCard ? invariableOptionsQuery : variableOptionsQuery;
  return {
    isAlleluia,
    isAntiphon,
    isNonTextOptionsCard,
    isInvariableOrdinaryCard,
    result,
  };
};
