import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getVerseLyricTickMap, seekMidiTick } from 'utils/midi/MidiPlayer';
import type { TimeMapEntry } from 'react-verovio';
import {
  ElementMap,
  makeElementMapFromTimeMap,
} from 'utils/midi/makeElementMapFromTimeMap';
import { MidiContext } from 'context/MidiContext';
import { MidiData } from 'components/verovio/MidiVerovio';

const activeMidiClassName = 'MIDI-active';

export interface MidiCallbacksOptions {
  ref?: React.RefObject<HTMLDivElement>;
  isMidiAvailable?: boolean;
}

export const useMidiCallbacks = (
  isActive: boolean,
  id: number,
  title?: string|null,
  supertitle?: string,
  { ref: defaultRef, isMidiAvailable = true }: MidiCallbacksOptions = {},
) => {
  const { setMidiActiveData } = useContext(MidiContext);
  const [base64midis, setMidiBase64s] = useState<string[]>([]);
  const [midiPsalmToneData, setMidiPsalmToneData] = useState<MidiData|null>(null);
  const [recordingUrls, setRecordingUrls] = useState<string[]|null>(null);
  const [transpose, setTranspose] = useState(0);
  const midiTimeMaps = useRef<TimeMapEntry[][]>([[]]);
  const midiElementMaps = useRef<ElementMap[]>([{}]);
  let ref = useRef<HTMLDivElement>(null);
  if (defaultRef) ref = defaultRef;

  const setMidiData = useCallback(
    (base64s: string[], timeMaps: TimeMapEntry[][], audioUrls?: string[] | null, transpose?: number | null) => {
      setMidiBase64s(base64s);
      setRecordingUrls(audioUrls ?? null);
      setTranspose(transpose ?? 0);
      midiTimeMaps.current = timeMaps;
      const options = {};
      midiElementMaps.current = timeMaps.map(tMap => makeElementMapFromTimeMap(tMap, options));
    },
    [setMidiBase64s],
  );

  const handleNoteClick = useCallback((event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    const closestNoteId = targetElement?.closest('.note[id]')?.id;
    const midiIdx = targetElement?.closest('.MIDI-verses') ? 1 : 0;
    if (closestNoteId) {
      const sylIndexString = Number(closestNoteId.match(/^syllable-(\d+)/)?.[1] ?? -1);
      const sylIndex = sylIndexString && Number(sylIndexString);
      seekMidiTick(() =>
        sylIndex >= 0
          ? getVerseLyricTickMap()[sylIndex]
          : midiElementMaps.current[midiIdx][closestNoteId],
        false
      );
    }
  }, []);

  useEffect(() => {
    if (isActive && base64midis?.length && isMidiAvailable) {
      setMidiActiveData({
        base64midis,
        recordingUrls,
        transpose,
        id,
        timeMaps: midiTimeMaps.current,
        ref,
        title: title ?? undefined,
        supertitle,
        midiPsalmToneData,
      });
      const currentRef = ref.current;
      currentRef?.classList.add(activeMidiClassName);
      currentRef?.addEventListener('click', handleNoteClick);
      return () => {
        currentRef?.classList.remove(activeMidiClassName);
        currentRef?.removeEventListener('click', handleNoteClick);
      };
    } else if (isActive && !isMidiAvailable) {
      setMidiActiveData({});
    }
  }, [base64midis, isActive, title, supertitle, handleNoteClick, setMidiActiveData, isMidiAvailable, id, midiPsalmToneData, recordingUrls, transpose]);

  return {
    ref,
    setMidiData,
    setMidiPsalmToneData,
  };
};
