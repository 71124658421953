import { GroupInfo } from 'utils/jwt/GroupInfo';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { useUser, JwtUser, getSelectedGroup } from '../useUser';

export enum UserRoles {
  Admin = 'ADMIN',
  MonthlyUser = 'MONTHLY_USER',
  YearlyUser = 'YEARLY_USER',
  EssentialsUser = 'ESSENTIALS_USER',
  CharterParish = 'CHARTER_PARISH',
  AlphaAccess = 'ALPHA_ACCESS',
  StandardUser = 'STANDARD_USER',
  SubscriptionActive = 'SUBSCRIPTION_ACTIVE',
  LiturgyPrep = 'LITURGY_PREP',
  TrialReactivated = 'TRIAL_REACTIVATED',
  SpecialOffer = 'SPECIAL_OFFER',
  OfflineAccess = 'offline_access',
  UMAAuthorization = 'uma_authorization',
  TrialUser = 'TRIAL_USER',
  CompleteDigital = 'COMPLETE_DIGITAL',
  CompleteDigitalCompanion = 'COMPLETE_DIGITAL_COMPANION',
  MissalSupport = 'MISSAL_SUPPORT',
  MissalCompanion = 'MISSAL_COMPANION',
  CampusMinistry = 'CAMPUS_MINISTRY',
  BasicUser = 'BASIC_USER',
}

export type RoleArgument = ExclusiveUnion<{
  all: UserRoles[];
} | {
  any: UserRoles[];
} | {
  role: UserRoles;
} | {
  none: UserRoles[];
}>;

/**
 * These are roles that can be set on the user as well as the group
 * so we have to check in both places for them
 */
const rolesToMatchOnUser: UserRoles[] = [
  UserRoles.Admin,
  UserRoles.AlphaAccess,
];

export const useHasRole = (roleArgument: RoleArgument) => {
  const user = useUser();
  const group = getSelectedGroup(user);
  return !!user && doesUserHaveRoles(user, roleArgument, group);
};

export const useHasAlphaAccess = () => useHasRole({ role: UserRoles.AlphaAccess });
export const useHasAlphaOrAdminAccess = () => useHasRole({ any: [UserRoles.AlphaAccess, UserRoles.Admin] });

export const doesUserHaveRoles = ({roles: userRoles}: Pick<JwtUser, 'roles'>, roleArgument: RoleArgument, {roles: groupRoles = []}: Partial<GroupInfo> = {}) =>
  (!!groupRoles && matchRoles(groupRoles, roleArgument, true)) || (!!userRoles && matchRoles(userRoles, roleArgument, false));

export const doesGroupHaveRoles = ({roles}: GroupInfo, roleArgument: RoleArgument) => 
  !!roles && matchRoles(roles, roleArgument, true);

export const matchRoles = (roles: UserRoles[], {all, any, role, none}: RoleArgument, areGroupRoles = false): boolean => {
  // This function returns true if the roles list we've been provided is valid for the role being checked
  // Most roles can only match on group roles, but admin and alpha, e.g., can match on user roles as well
  const canMatchRole = (r: UserRoles) => areGroupRoles || rolesToMatchOnUser.includes(r);
  if (role) {
    return canMatchRole(role) && roles.includes(role);
  } else if (all) {
    return all.every(r => canMatchRole(r) && roles.includes(r));
  } else if (any) {
    return any.some(r => canMatchRole(r) && roles.includes(r));
  } else if (none) {
    return areGroupRoles && !none.some(r => roles.includes(r));
  } else {
    // this case shouldn't happen (due to exclusive union)
    return true;
  }
};

export const useIsAdmin = () => {
  return useHasRole({ role: UserRoles.Admin })
};

export const useIsAlphaUser = () => {
  return useHasRole({ any: [UserRoles.Admin, UserRoles.AlphaAccess] })
};
