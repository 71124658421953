import React, { Fragment } from 'react';

const regexUnderscores = /(^|\W)_(?=\S)(.*?)_(?=$|\W)/;

interface UnderscoreItalicProps {
  text: string;
}

export const UnderscoreItalic: React.FC<UnderscoreItalicProps> = ({ text }) => {
  const split = text.split(regexUnderscores);
  // element index % 3 === 0 || index % 3 === 1 : not italic
  // element index % 3 === 2: italic
  return (
    <>
      {split.map((elem, i) =>
        i % 3 === 2 ? (
          <i key={i}>{elem}</i>
        ) : (
          <Fragment key={i}>{elem}</Fragment>
        )
      )}
    </>
  );
};
