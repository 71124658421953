import React from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faVolume } from '@fortawesome/pro-solid-svg-icons/faVolume';
import { SetMidiActiveData } from 'utils/midi/MidiPlayer';

export interface IMidiContext {
  onChangeVolume: (volume: number) => void;
  toggleMute: () => void;
  progress: number;
  progressTickMarks: number[];
  playMidi: (id?: number) => void;
  volumeIcon: IconDefinition;
  isActivated: boolean;
  supertitle: string;
  title: string;
  midiCardId?: number|null;
  pauseMidi: () => void;
  restartMidi: () => void;
  volume: number|null;
  channelCount: number|null;
  isPlaying: boolean;
  isAvailable: boolean;
  handleScrubStart: (tick: number) => void;
  handleScrubChange: (tick: number) => void;
  handleScrubEnd: (tick: number) => void;
  showMidiInViewMode: boolean;
  setMidiActiveData: SetMidiActiveData;
}

export const MidiContext = React.createContext<IMidiContext>({
  onChangeVolume: () => {},
  toggleMute: () => {},
  progress: 0,
  progressTickMarks: [],
  playMidi: () => {},
  volumeIcon: faVolume,
  isActivated: false,
  supertitle: '',
  title: '',
  midiCardId: null,
  pauseMidi: () => {},
  restartMidi: () => {},
  volume: null,
  channelCount: null,
  isPlaying: false,
  isAvailable: false,
  handleScrubStart: () => {},
  handleScrubChange: () => {},
  handleScrubEnd: () => {},
  showMidiInViewMode: true,
  setMidiActiveData: () => {},
} as IMidiContext);
