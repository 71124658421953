import React from 'react'

import { AlleluiaTranslation } from 'components/ordo/shared/text/AlleluiaTranslation';
import { Text, TranslationOnText } from 'queries/text';
import { useDefaultLanguageTranslation } from 'hooks/text';
import { TextOptionWithMeta } from 'queries/text-option';
import { splitVersesByType } from 'utils/markup/splitVerses';

export enum AlleluiaDisplayMode {
  DEFAULT,
  VERSE_ONLY,
}

interface OfficialAlleluiaTranslationProps {
  verse?: Text;
  textOption?: TextOptionWithMeta;
  displayMode: AlleluiaDisplayMode;
  antiphon?: Text;
  antiphonTranslation?: TranslationOnText | null;
  isLentenGospelAcclamation?: boolean;
}

export const OfficialAlleluiaTranslation: React.FC<OfficialAlleluiaTranslationProps> = ({
  verse,
  textOption,
  displayMode = AlleluiaDisplayMode.DEFAULT,
  antiphon,
  antiphonTranslation,
  isLentenGospelAcclamation = false,
}) => {
  // force "lenten gospel acclamation" style on tract cards:
  isLentenGospelAcclamation = isLentenGospelAcclamation || verse?.contentType?.name === 'Tract';
  const officialVerse = useDefaultLanguageTranslation(verse);
  const language = officialVerse?.language;
  const showAntiphon = (!!antiphon && displayMode === AlleluiaDisplayMode.DEFAULT);
  const officialAntiphon = useDefaultLanguageTranslation(antiphon, language);
  const effectiveAntiphon = showAntiphon ? (antiphonTranslation || officialAntiphon) : undefined;

  let antiphonRepeat = effectiveAntiphon?.content;
  let antiphonBefore = effectiveAntiphon?.altContent;
  let text = officialVerse?.content;
  let scriptureReference = verse?.originalSource;
  if ('Gradual' === antiphon?.contentType.name) {
    // Gradual needs to just show the antiphon once before,
    // and add a versicle symbol to the verse.
    // Also, the scripture reference needs to come from the antiphon.
    antiphonBefore = antiphonRepeat;
    antiphonRepeat = undefined;
    text = '℣. ' + text;
    scriptureReference = antiphon?.originalSource;
  } else if ('Tract' === verse?.contentType.name) {
    // For a Tract, we just add a versicle symbol to the beginning of each verse.
    text = splitVersesByType(text, 'DOUBLE_LINE_BREAK').map(v => '℣. ' + v).join('\n\n');
  } else if (displayMode === AlleluiaDisplayMode.DEFAULT) {
    // for ad libitum card display when selecting verse, we simplify things a bit:
    antiphonRepeat ??= 'Alleluia.';
    antiphonBefore ??= 'Alleluia, alleluia.';
  }
  return (
    <AlleluiaTranslation
      text={text}
      scriptureReference={scriptureReference}
      antiphonBefore={antiphonBefore}
      antiphon={antiphonRepeat}
      isLentenGospelAcclamation={isLentenGospelAcclamation}
      sourceNames={textOption?.sources.map(s => s.nameShort)}
      className=''
    />
  );
}
