/* eslint-disable @typescript-eslint/no-redeclare */
import { Source } from 'graphql';
import { gql } from '@apollo/client';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { GQLDocument, GQLQuery } from './gql-generics';
import { SOURCE_FRAGMENT } from './source';
import { Season } from 'utils/api-types/ordo/Season';
import { Language } from 'utils/api-types/Language';
import { ProperAntiphonContentType, ReadingContentType } from 'utils/api-types/ordo/contentTypes';

export type FindTranslation = WithId & {
  content: string;
  language: Language;
  synopsis: string;
  text: WithId & {
    originalSource: string;
    contentType: WithNameAndId;
    isGospel?: boolean;
  }
};

export type FindReadingTranslation = FindTranslation & {
  text: FindTranslation['text'] & {
    isGospel: boolean;
  }
};

export type AssociatedVerse = {
  textOption: WithId & {
    textCategory: WithNameAndId;
  };
  translation: FindTranslation;
};

export type FindAntiphonTranslation = FindTranslation & {
  associatedVerses: AssociatedVerse[];
};

export type Translation = {
  textId: number;
  language: Language;
  format: string;
  content: string;
  altContent: string;
  synopsis: string;
  translator: string;
  isOfficial: boolean;
  isRegularMeter: boolean;
  source: Source | null;
  readingIntroduction: string | null;
  readingConclusion: string | null;
} & WithNameAndId;

export const TRANSLATION_FRAGMENT = gql`
  fragment Translation on Translation {
    id
    name
    textId
    language
    format
    content
    altContent
    synopsis
    translator
    isOfficial
    isRegularMeter
    readingIntroduction
    readingConclusion
    source {
      ...Source
    }
  }
  ${SOURCE_FRAGMENT}
`;

export const FIND_TRANSLATION_FRAGMENT = gql`
  fragment FindTranslation on Translation {
    id
    content
    language
    synopsis
    text {
      id
      originalSource
      contentType {
        name
        id
      }
    }
  }
`

export interface GetTranslation extends GQLQuery {
  Variables: { id: number };
  Data: { translation: Translation };
}
export const GetTranslation: GQLDocument<GetTranslation> = {
  query: gql`
    query getTranslation($id: Float!) {
      translation(id: $id) {
        ...Translation
      }
    }
    ${TRANSLATION_FRAGMENT}
  `
}

export interface GetTranslations extends GQLQuery {
  Variables: { ids?: number[] };
  Data: { translations: Translation[] };
}
export const GetTranslations: GQLDocument<GetTranslations> = {
  query: gql`
    query getTranslations($ids: [Float!]) {
      translations(ids: $ids) {
        ...Translation
      }
    }
    ${TRANSLATION_FRAGMENT}
  `
}

export interface GetOfficialTranslation extends GQLQuery {
  Variables: {
    textId: number;
    language: string;
  }
  Data: { officialTranslation: Translation | null };
}
export const GetOfficialTranslation: GQLDocument<GetOfficialTranslation> = {
  query: gql`
    query officialTranslation($textId: Float!, $language: String!) {
      officialTranslation(textId: $textId, language: $language) {
        ...Translation
      }
    }
    ${TRANSLATION_FRAGMENT}
  `
}

export interface GetOfficialTranslations extends GQLQuery {
  Variables: {
    textIds: number[];
    language?: string;
  }
  Data: { officialTranslations: Translation[] };
}
export const GetOfficialTranslations: GQLDocument<GetOfficialTranslations> = {
  query: gql`
    query officialTranslations($textIds: [Float!]!, $language: String) {
      officialTranslations(textIds: $textIds, language: $language) {
        ...Translation
      }
    }
    ${TRANSLATION_FRAGMENT}
  `
}

export type AntiphonSearchTranslation = Translation & {
  text: WithId & {
    originalSource: string;
    contentType: WithId & {
      seedId: string;
    }
  }
}

export interface FindReading extends GQLQuery<FindReading> {
  Variables: {
    content?: string | null;
    contentTypes?: readonly ReadingContentType[] | null;
    isOfficial?: boolean | null;
    language?: Language | null;
    offset?: number;
    limit?: number;
  }
  Data: { readings: FindReadingTranslation[] }
}
export const FindReading: GQLDocument<FindReading> = {
  query: gql`
    query findReading(
      $content: String,
      $contentTypes: [String!],
      $isOfficial: Boolean,
      $language: String,
      $offset: Float,
      $limit: Float,
    ) {
      readings: findReading(
        content: $content,
        contentTypes: $contentTypes,
        isOfficial: $isOfficial,
        language: $language,
        offset: $offset,
        limit: $limit,
      ) {
        ...FindTranslation
        text {
          isGospel
        }
      }
    }
    ${FIND_TRANSLATION_FRAGMENT}
  `
}
export interface FindAntiphon extends GQLQuery<FindAntiphon> {
  Variables: {
    content?: string;
    contentTypes?: readonly ProperAntiphonContentType[];
    isOfficial?: boolean;
    language?: string;
    season?: Season;
    collectionIds?: number[];
    offset?: number;
    limit?: number;
  }
  Data: { antiphons: FindAntiphonTranslation[] }
}
export const FindAntiphon: GQLDocument<FindAntiphon> = {
  query: gql`
    query findAntiphon(
      $content: String,
      $contentTypes: [String!],
      $isOfficial: Boolean,
      $language: String,
      $season: Season,
      $collectionIds: [Float!],
      $offset: Float,
      $limit: Float,
    ) {
      antiphons: findAntiphon(
        content: $content,
        contentTypes: $contentTypes,
        isOfficial: $isOfficial,
        language: $language,
        season: $season,
        collectionIds: $collectionIds,
        offset: $offset,
        limit: $limit,
      ) {
        ...FindTranslation
        associatedVerses {
          textOption {
            id
            textCategory {
              id
              name
            }
          }
          translation {
            ...FindTranslation
          }
        }
      }
    }
    ${FIND_TRANSLATION_FRAGMENT}
  `
}
