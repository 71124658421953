import { useContext, useMemo } from 'react';
import { QueryHookOptions, QueryResult, useQuery as useApolloQuery } from '@apollo/client';
import { useApolloOnError, useLogError } from 'hooks/error/useLogError';
import { GQLDocument, GQLQuery } from 'queries/gql-generics';
import { AuthContext } from 'context/AuthContext';
import { WithRequired } from 'utils/typescript/WithRequired';
import { WithPartial } from 'utils/typescript/WithPartial';
import { getHasAuthorizationToken } from 'utils/authorization/authorization';

type RequireVariablesOrSkip<Q extends GQLQuery<Q>> =
  (WithRequired<QueryHookOptions<Q['Data'], Q['Variables']>, 'variables'> & { skip?: false }) |
  (QueryHookOptions<Q['Data'], Q['Variables']> & { skip: true });

export function useQuery<Q extends GQLQuery<Q>>(
  query: GQLDocument<Q>,
  ...[options]: Q['Variables'] extends never
  ? [QueryHookOptions<Q['Data'], Q['Variables']>?]
  : {} extends Q['Variables']
  ? [QueryHookOptions<Q['Data'], Q['Variables']>?]
  : [RequireVariablesOrSkip<Q>]
): QueryResult<Q['Data'], Q['Variables']> {
  const onError = useApolloOnError('Query', query.query, options);
  const optionsToUse = useMemo(() => ({
    ...options,
    onError,
  }), [onError, options]);

  return useApolloQuery<Q['Data'], Q['Variables']>(query.query, optionsToUse);
}

export const useBackendLoggedQuery = <Q extends GQLQuery<Q>>(query: GQLDocument<Q>, options?: QueryHookOptions<Q['Data'], Q['Variables']>) => {
  const logError = useLogError();
  const onError = useApolloOnError('Query', query.query, options);
  const { checkForError } = query;
  const onCompleted = useMemo(
    () =>
      checkForError
        ? (data: Q['Data']) => {
            checkForError?.(logError, data, options?.variables);
            options?.onCompleted?.(data);
          }
        : undefined,
    [logError, options, checkForError],
  );
  let optionsToUse = useMemo(() => ({ ...options, onError, onCompleted }), [onCompleted, onError, options]);

  return useApolloQuery<Q['Data'], Q['Variables']>(query.query, optionsToUse);
}

export const useBackendLoggedIdQuery = <IdType, Q extends GQLQuery<Q> & { Variables: { id: IdType } }>(
  query: GQLDocument<Q>,
  id?: IdType | null,
  options?: Omit<QueryHookOptions<Q['Data'], Q['Variables']>, 'variables' | 'skip'>
) => {
  return useBackendLoggedQuery(
    query,
    {
      ...options,
      variables: id ? { id } : undefined,
      skip: !id
    }
  );
}

export const useIdQuery = <IdType, Q extends GQLQuery<Q> & { Variables: { id: IdType } }>(
  query: GQLDocument<Q>,
  id: IdType | null,
  { authRequired, ...options }: Omit<QueryHookOptions<Q['Data'], Q['Variables']>, 'variables' | 'skip'> & {
    authRequired?: boolean;
  } = {}
) => {
  return useQuery(
    query,
    {
      ...options,
      // for negative numbers, skip the query:
      ...(id && (typeof id !== 'number' || id > 0) ? {
        variables: { id },
        skip: authRequired && !getHasAuthorizationToken(),
      } : {
        skip: true,
      })
    }
  );
}

export const useGroupIdQuery = <Q extends GQLQuery<Q> & { Variables: { groupId: string } }>(
  query: GQLDocument<Q>,
  options?: QueryHookOptions<Q['Data'], WithPartial<Q['Variables'], 'groupId'>>,
) => {
  const { group: { id: groupId } = { id: undefined }} = useContext(AuthContext);

  return useQuery(
    query,
    {
      ...options,
      ...(groupId && !options?.skip ? {
        variables: {
          ...options?.variables,
          groupId
        },
      } : {
        skip: true,
      })
    } as RequireVariablesOrSkip<Q>
  );
}
