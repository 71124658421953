import { DebugLogger } from './DebugLogger'
import { useCallback, useState, Dispatch, SetStateAction } from 'react';

// logging arguments for useLoggedState hook
interface UseLoggedStateArgs<S> {
  // the logger to use
  debugLogger: DebugLogger;
  // a description of the state variable to be included with log messages
  description: string;
  // optional function to convert the state to a more readable representation.
  showState?: ((state: S) => string);
}

// first overload of useLoggedState (normal case)
export function useLoggedState<S>(
  options: UseLoggedStateArgs<S>,
  initialState: S,
): [S, Dispatch<SetStateAction<S>>];

// first overload of useLoggedState, in order to match behavior of useState overloads
export function useLoggedState<S>(
  options: UseLoggedStateArgs<S>,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

// pass the argument if useState to this to log the state whenever it is changed.
// this is defined using the function keyword so they can be generic
export function useLoggedState<S>(
  {debugLogger, description, showState}: UseLoggedStateArgs<S>,
  // the initial state (what would normally be passed to useState)
  initialState?: unknown
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState as S);

  const loggedSetState: typeof setState = useCallback((newStateAction: SetStateAction<S>) => {
    const loggedStateAction = (prevState: S) => {
      // setState can be either the new state or a function to get the new state from the old one,
      // so we have to respect that here
      let newState: S;
      if ((typeof newStateAction) === 'function') {
        // if newStateAction is a function, evaluate it and that's our new state
        newState = (newStateAction as ((prevState: S) => S))(prevState);
      }
      else {
        // if it's not a function, then it is the new state
        newState = (newStateAction as S);
      }
      // check if the state has updated
      if (newState !== state) {
        // determine how to print the newState
        if (showState) {
          // use showState if it's available
          debugLogger.log(`${description}: ${showState(newState)}`);
        }
        else if (typeof newState !== 'object') {
          // if this is not an object, just naturally print out newState
          debugLogger.log(`${description}: ${newState}`);
        }
        else {
          // otherwise, print it out nicely
          debugLogger.info(newState, description);
        }
      }
      return newState;
    };
    // run the actual setState function to give us the original behavior
    setState(loggedStateAction);
  }, [state, setState, debugLogger, description, showState]);
  // return the reconstructed tuple with our new, wrapped setState
  return [state, loggedSetState];
};
