import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useTabs<Tab extends string, Path extends string = string>(basepath: string, tabMap: readonly (readonly [Path, Tab])[], defaultTab: null): [Tab|null, Dispatch<SetStateAction<Tab|null>>];
export function useTabs<Tab extends string, Path extends string = string>(basepath: string, tabMap: readonly (readonly [Path, Tab])[], defaultTab?: Tab): [Tab, Dispatch<SetStateAction<Tab>>];
export function useTabs<Tab extends string, Path extends string = string>(basepath: string, tabMap: readonly (readonly [Path, Tab])[], defaultTab: Tab|null = tabMap[0][1]): [Tab|null, Dispatch<SetStateAction<Tab|null>>] {
  const history = useHistory();
  const { pathname } = useLocation();
  const path = window.location.pathname.replace(basepath, '');
  const [activeTab, setTab] = useState<Tab|null>(
    (path &&
    (tabMap.find(([tabPath]) => tabPath && path.startsWith(tabPath))?.[1])) ||
    defaultTab
  );
  useEffect(() => {
    const path = pathname.replace(basepath, '');
    const entry = tabMap.find(([key]) => key === path);
    if (entry) {
      // if the path changed, update the tab to match
      setTab(entry[1]);
    }
  }, [basepath, pathname, tabMap])
  useEffect(() => {
    // if the tab changed, update the path to match
    const path = tabMap.find(([,tab]) => tab === activeTab)?.[0];
    if (path !== undefined && (path === '' || !window.location.pathname.replace(basepath, '').startsWith(path))) {
      history.replace(`${basepath}${path ? '/' + path : ''}`.replace('//', '/'));
    }
  }, [activeTab, basepath, history, tabMap]);

  return [activeTab, setTab];
}
