import React from 'react';
import { CanonicalSource } from 'components/shared/CanonicalSource';
import { cx, css } from '@emotion/css';
import { colorDictionary, fontSerifCss } from 'utils/styles';

interface AntiphonVerseReferenceProps {
  antiphonReference?: string;
  verseReference?: string;
  sourceNames?: string[];
}

export const AntiphonVerseReference: React.FC<AntiphonVerseReferenceProps> = ({
  antiphonReference = '',
  verseReference = '',
  sourceNames,
}) => {

  const antiphonParts = antiphonReference.split(':');
  const antiphonRefNormalized = antiphonParts[0].replace(/^Cf\. /, '');
  const verseParts = verseReference.split(':');
  let verseRef = verseReference;

  if (verseParts[0] === antiphonRefNormalized && verseParts.length > 1) {
    verseRef = verseParts[1].trim();
  }

  return (
    <div className={cx('text-reference no-page-break-after _EXPORT antiphon-verse-reference', style)}>
      {antiphonReference && <span>{antiphonReference}</span>}
      {antiphonReference && verseRef && <>; </>}
      {verseRef && <><span className="font-normal" style={{ fontFamily: 'Versiculum' }}>v</span> <span>{verseRef}</span></>}
      {!!sourceNames?.length && <>
        {(verseRef || antiphonReference) && <span> · </span>}
        <CanonicalSource sourceNames={sourceNames} />
      </>}
    </div>
  )
}

// TODO: at some point, if this CSS for cards gets restructured,
// this styling can be unified with the styling in src/components/ordo/shared/text/TextSource.tsx
const style = css`
  ${fontSerifCss}
  font-style: italic;
  font-size: 0.8em;
  color: ${colorDictionary['Secondary']};
  font-weight: 400;
  line-height: 1.2;
`;
