import { css, cx } from '@emotion/css';
import Exsurge from 'components/shared/LazyExsurge';
import { GabcPsalmTones, Language } from 'gabc-utils';
import { useLogException } from 'hooks/error/useLogError';
import { useDefaultExsurgeConfig } from 'hooks/exsurge/useDefaultExsurgeConfig';
import { useGabcsHaveRendered } from 'hooks/exsurge/useGabcsHaveRendered';
import React, { useEffect, useMemo } from 'react';
import { processFormulaicGabc } from 'utils/markup';
import { MusicalErrorAlert } from './MusicalErrorAlert';
import { Spacing } from './editor/settings/SpacingToggler';

interface NotatedVerseProps {
  verses: string[];
  language: Language;
  psalmTone?: GabcPsalmTones;
  width: number;
  isDoxology?: boolean;
  useVersicleSymbol?: boolean;
  removeSolesmesMarkings?: boolean;
  startingVerseNum?: number;
  spacingHorizontal?: Spacing | null;
  spacingVertical?: number | null;
  onLoad?: () => void;
}

export const NotatedVerse: React.FC<NotatedVerseProps> = ({
  verses,
  language,
  psalmTone,
  width,
  isDoxology,
  useVersicleSymbol,
  removeSolesmesMarkings,
  startingVerseNum = 1,
  spacingHorizontal,
  spacingVertical,
  onLoad,
}) => {
  const defaults = useDefaultExsurgeConfig({ spacingHorizontal, spacingVertical });
  const logException = useLogException();
  const notatedVerses = useMemo(() => {
    try {
      return processFormulaicGabc(verses, language, psalmTone, isDoxology, useVersicleSymbol, removeSolesmesMarkings, startingVerseNum);
    } catch (error) {
      logException({error, errorInfo: { function: 'processFormulaicGabc', verses }, message: 'Musical Exception'});
      return null;
    }
  }, [isDoxology, language, logException, psalmTone, removeSolesmesMarkings, startingVerseNum, useVersicleSymbol, verses]);


  const [verseGabcsHaveRendered, setVerseGabcsRendered] = useGabcsHaveRendered(notatedVerses?.length ?? 0);
  const missingNotatedVerses = (notatedVerses?.length ?? 0) === 0;
  const isLoaded = verseGabcsHaveRendered || missingNotatedVerses;

  useEffect(() => {
    if (isLoaded) {
      onLoad?.();
    }
  }, [isLoaded, onLoad]);

  if (notatedVerses === null) {
    return <MusicalErrorAlert />;
  }

  return (
    <>
      {notatedVerses.map((verse: string, idx: number) => {
        return (
          <div className={cx('notated-verse', style)} key={`notated-verse-${verse}-${idx}`} >
            <Exsurge
              gabc={verse}
              width={width}
              useDropCap={false}
              alignment='english'
              className='_EXPORT'
              onRender={setVerseGabcsRendered[idx]}
              {...defaults}
            />
          </div>
        )
      })}
    </>
  )
}

const style = css`
  & + & .Exsurge._EXPORT:first-child {
    padding-top: 24px;
  }
`;
