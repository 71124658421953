import { getSelectedGroup, useUser } from 'hooks/useUser';
import { OrdoCardWithMeiOrSvg } from 'queries/card-fragment';
import { useMemo } from 'react';
import { getHasPermission } from 'utils/ordo/card/getHasPermission';

export const useFilteredSortedOrdoCards = (
  ordoCards: OrdoCardWithMeiOrSvg[],
  ignoreUserRoles = false,
  cardId?: number,
) => {
  const user = useUser();
  const group = getSelectedGroup(user);
  const userRoles = user?.roles;
  const groupRoles = group?.roles;

  return useMemo(() => {
    let cards = ordoCards.filter(
      (card) => card.enabled && (cardId === undefined || card.id === cardId),
    );
    if (!ignoreUserRoles) {
      cards = cards.filter(getHasPermission(userRoles, groupRoles));
    }
    return cards.sort((a, b) => a.sortPosition - b.sortPosition) ?? [];
  }, [ordoCards, ignoreUserRoles, cardId, userRoles, groupRoles]);
};
