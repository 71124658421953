import { useBackendLoggedQuery } from 'hooks/queries/useQuery';
import { GetSeasonalCardOptions } from 'queries/text-option';
import { Season } from 'utils/api-types/ordo/Season';

// the type here just takes GetSeasonalCardOptions['Variables'] and makes `season` optional
export const useSeasonalOptionsQuery = (variables: Omit<GetSeasonalCardOptions['Variables'], 'season'> & { season?: Season }, skip?: boolean) => {
  return useBackendLoggedQuery(
    GetSeasonalCardOptions,
    !variables.season || skip ? { skip: true } : { variables: variables as GetSeasonalCardOptions['Variables'] },
  );
}
