import { css, cx } from '@emotion/css';
import React from 'react';
import { processNestedText, processText, getTextReaderNames, NestedArray } from 'utils/markup';
import { GloryBe } from '../GloryBe';

const renderAtNestedLevel = (array: NestedArray<string>, className?: string) => {
  if (array.length === 0) {
    return [];
  }

  let paragraphs: React.ReactElement[][] = [[]];
  let paragraphCounter = 0;
  let hasReaderLabel = false;

  array.forEach((item, idx) => {
    if (typeof item === 'string') {
      if (item) {
        if (item.includes('reader-label')) {
          hasReaderLabel = true;
        }
        paragraphs[paragraphCounter].push((<li key={idx} className={cx(className, { 'has-reader-label': hasReaderLabel })} dangerouslySetInnerHTML={{ __html: item }}></li>));
      } else {
        paragraphCounter += 1;
        paragraphs.push([]);
      }
    } else {
      paragraphs[paragraphCounter].push((<li key={idx}>{renderAtNestedLevel(item)}</li>));
    }
  });

  return paragraphs.map((paragraph, i) => <ul key={i}>{paragraph}</ul>);
};

interface RegularProps {
  withoutOuterDiv?: boolean;
  addExportClass?: boolean,
  text: string;
  textInterjected?: string;
  textInterjectedBefore?: string;
  className?: string;
  showAll?: boolean;
  nestedLevelClassName?: string;
  language?: string;
  numberOfReaders?: number;
  includeGloryBe?: boolean | null;
  columns?: number | null;
  paragraphClassName?: string;
}

export const Regular: React.FC<RegularProps> = ({
  withoutOuterDiv,
  addExportClass = true,
  text,
  textInterjected,
  textInterjectedBefore,
  className,
  nestedLevelClassName,
  language = '',
  numberOfReaders = 1,
  showAll,
  includeGloryBe = false,
  columns,
  paragraphClassName,
}) => {
  const gloryBe = includeGloryBe && <GloryBe withoutOuterDiv columns={columns}/>;
  const processedVerse = getTextReaderNames(processText(text), language, numberOfReaders);
  const parsedVerse = processNestedText(processedVerse, columns);
  const processedInterjected = processText(textInterjected);
  const parsedInterjected = processNestedText(processedInterjected);
  const processedInterjectedBefore = processText(textInterjectedBefore);
  const parsedInterjectedBefore = processNestedText(processedInterjectedBefore);
  const effectiveInterjectedBefore = textInterjectedBefore ? parsedInterjectedBefore : parsedInterjected;

  if (!parsedVerse) {
    return null;
  }

  const innerContent = <>
    {effectiveInterjectedBefore && <span className="liturgical-text-interjected no-page-break-inside">{renderAtNestedLevel(effectiveInterjectedBefore)}</span>}
    {renderAtNestedLevel(parsedVerse, nestedLevelClassName).map((paragraph, idx) => {
      return <React.Fragment key={idx}>
        <span className={cx(
          'liturgical-text-paragraph',
          paragraphClassName,
          { 'no-page-break-after': !!parsedInterjected }
        )}>{paragraph}</span>
        {parsedInterjected && <span className="liturgical-text-interjected no-page-break-inside">{renderAtNestedLevel(parsedInterjected)}</span>}
      </React.Fragment>;
    })}
    {gloryBe}
  </>;

  return withoutOuterDiv
    ? innerContent
    : <div className={cx(style, 'liturgical-text', className, { 'liturgical-text-response': !!parsedInterjected, _EXPORT: addExportClass })}>{innerContent}</div>;
}

const style = css`
  .liturgical-text-paragraph + .liturgical-text-paragraph > ul {
    padding-top: 15px;
  }
`;
