import { ApolloCache, MutationUpdaterFunction, OperationVariables } from '@apollo/client';
import { produce } from 'immer';
import { Draft } from 'immer';
import { GQLDocument, GQLQuery, UpdateQueryContext } from 'queries/gql-generics';

export type GetQueryVariablesFunction<TData, TVariables, Q extends GQLQuery> = (data: TData, options: {
  context?: UpdateQueryContext<Q>;
  variables?: TVariables;
},
  cache: ApolloCache<any>
) => Q['Variables'] | boolean;

/**
 * Applies the given update logic to create a cache updater function that Apollo can use
 * In either function, if you quit early for some failure state, return FALSE
 * to avoid writing the redundant data to the cache.
 * @param cacheQuery Query to use when updating the cache
 * @param getQueryVariables Callback to get query variables: return TRUE to continue without passing variables to the query, return False to quit early
 * @param updateLogic Callback to update cache: return FALSE to avoid updating the cache
 * @returns mutationUpdater an updater function to be passed as options to an Apollo mutation
 */
export const createMutationCacheUpdater = <TData, TVariables extends OperationVariables, Q extends GQLQuery>(
  cacheQuery: GQLDocument<Q>,
  getQueryVariables: GetQueryVariablesFunction<TData, TVariables, Q>,
  updateLogic: (draft: Draft<Q['Data']>, mutationData: TData, options: {
    context?: UpdateQueryContext<Q>;
    variables?: TVariables;
  }) => boolean,
): MutationUpdaterFunction<TData, TVariables, UpdateQueryContext<Q>, ApolloCache<any>> => {
  return (cache, result, options) => {
    let mutationData: TData;
    if (!result.data) {
      return;
    } else {
      mutationData = result.data;
    }

    const variables = getQueryVariables(result.data, options, cache);

    // false from getQueryVariables means we should quit early
    if (variables === false) {
      return;
    }

    const cacheData = cache.readQuery<Q['Data'], Q['Variables']>(
      {
        query: cacheQuery.query,
        // true from getQueryVariables means we do the query with no variables
        variables: variables === true ? undefined : variables,
      }
    );

    if (!cacheData) {
      return;
    }

    // mutate the ordo
    const { data, shouldWrite } = produce({ data: cacheData, shouldWrite: true }, (draft) => {
      // boolean from updateLogic indicates whether we should write data to cache
      draft.shouldWrite = updateLogic(draft.data, mutationData, options);
    });

    if (!shouldWrite) {
      return;
    }

    // write the changed ordo into the cache
    cache.writeQuery<Q['Data'], Q['Variables']>({
      query: cacheQuery.query,
      variables: variables === true ? undefined : variables,
      data,
    });
  }
}
