import dayjs from 'utils/dayjs';
import { useMutation } from 'hooks/queries/useMutation';
import { CreateReferral } from 'queries/referral';
import { useEffect, useMemo } from 'react';
import { toast } from 'utils/toast';

export const useStoredReferral = (): { referrerId: string; when: string; } | null => {
  const referralItem = window.localStorage.getItem('referral');
  return useMemo(() => referralItem ? JSON.parse(referralItem) : null, [referralItem]);
}

export const useProcessReferral = () => {
  const [createReferral] = useMutation(CreateReferral);

  const referral = useStoredReferral();
  useEffect(() => {
    if (referral) {
      // have to ensure this is a good referral
      if (referral.referrerId &&
        typeof referral.referrerId === 'string' &&
        validateDate(referral.when)
      ) {
        createReferral({
          variables: { referrerId: referral.referrerId },
          onError(e) {
            if (e.message === 'You cannot refer yourself.' ||
                e.message === 'Invalid referrer ID' ||
                e.message.startsWith?.('User does not qualify')
            ) {
              toast('error', 'Error while processing referral: ' + e.message);
              // these errors indicate an issue with the referrer ID or user's qualification, so it should be removed
              window.localStorage.removeItem('referral');
            } else {
              toast('error', 'Error while processing referral: ' + e + ' Please contact support@sourceandsummit.com for help with this issue.');
            }
          },
          onCompleted() {
            window.localStorage.removeItem('referral');
          },
        });
      } else {
        // something is wrong with the referral, just remove it
        window.localStorage.removeItem('referral');
        console.warn('removed faulty referral from localStorage');
      }
    }
  }, [referral, createReferral]);
}

const validateDate = (dateString: unknown) => {
  if (dateString && typeof dateString === 'string') {
    const date = new Date(dateString).getTime();
    return !isNaN(date) && dayjs(date).add(6, 'months') > dayjs();
  }
  return false;
}
