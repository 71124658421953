import type { MidiEvent } from './MidiEvent';

export const filterFirstNotesOnTrack = (
  events: MidiEvent[],
  trackNum: number,
) => {
  const noteOnEvents = events.filter(({ track, name, velocity }) => track === trackNum && name === 'Note on' && velocity);
  for (let i = noteOnEvents.length - 1; i > 0; --i) {
    const note = noteOnEvents[i];
    const prevNote = noteOnEvents[i - 1];
    if (note.tick === prevNote.tick) {
      // the notes occur at the same time.  We will only keep the note of higher pitch:
      const spliceIndex = note.noteNumber! < prevNote.noteNumber! ? i : i - 1;
      noteOnEvents.splice(spliceIndex, 1);
      if (i < noteOnEvents.length) {
        // if we were processing the last two events, we do not need to adjust i,
        // but otherwise we need to reprocess the event we kept, in case the event
        // before it is at a higher pitch
        ++i;
      }
    }
  }
  return noteOnEvents;
};
