// Note: this enum must be kept up to date with one in the API project
// But all the enum values have to be capitalized, because nest/graphql
// expects the names to match the values
export enum InteractionIdentifier {
  NUMBER_OF_ORDOS = 'NUMBER_OF_ORDOS',
  VIEWED_DASHBOARD = 'VIEWED_DASHBOARD',
  CLICKED_TO_CREATE_A_LITURGY = 'CLICKED_TO_CREATE_A_LITURGY',
  VIEWED_A_LITURGY = 'VIEWED_A_LITURGY',
  ADDED_A_HYMN = 'ADDED_A_HYMN',
  CLICKED_AN_OUTLINE_ITEM = 'CLICKED_AN_OUTLINE_ITEM',
  CLICKED_THE_PLUS_ICON = 'CLICKED_THE_PLUS_ICON',
  ADDED_A_CUSTOM_TEXT = 'ADDED_A_CUSTOM_TEXT',
  REMOVED_AN_OUTLINE_ITEM = 'REMOVED_AN_OUTLINE_ITEM',
  REMOVE_AN_ITEM = 'REMOVE_AN_ITEM',
  RENAMED_A_HYMN = 'RENAMED_A_HYMN',
  RENAMED_A_CUSTOM_TEXT = 'RENAMED_A_CUSTOM_TEXT',
  APPLY_MUSIC_TO_A_CHANT_SECTION = 'APPLY_MUSIC_TO_A_CHANT_SECTION',
  APPLY_MUSIC_TO_AN_ORDINARY_SECTION = 'APPLY_MUSIC_TO_AN_ORDINARY_SECTION',
  APPLY_MUSIC_TO_A_HYMN_SECTION = 'APPLY_MUSIC_TO_A_HYMN_SECTION',
  SELECT_ANTIPHON_TEXT = 'SELECT_ANTIPHON_TEXT',
  BROWSED_HYMNS = 'BROWSED_HYMNS',
  CLICKED_SETTINGS_TOGGLE = 'CLICKED_SETTINGS_TOGGLE',
  SET_NOTATION_TO_TEXT_HYMN = 'SET_NOTATION_TO_TEXT_HYMN',
  CHANGED_KEY = 'CHANGED_KEY',
  CLICKED_INTO_TUNES = 'CLICKED_INTO_TUNES',
  CHANGED_A_TUNE = 'CHANGED_A_TUNE',
  CLICKED_TO_REMOVE_A_VERSE_HYMN = 'CLICKED_TO_REMOVE_A_VERSE_HYMN',
  BROWSED_TONES = 'BROWSED_TONES',
  SEARCHED_HYMNS = 'SEARCHED_HYMNS',
  SET_NOTATION_TO_MODERN_CHANT = 'SET_NOTATION_TO_MODERN_CHANT',
  ENABLED_HARMONIZATION = 'ENABLED_HARMONIZATION',
  ENABLED_CHORD_SYMBOLS = 'ENABLED_CHORD_SYMBOLS',
  SET_VERSES_TO_NOTATE_CHANT = 'SET_VERSES_TO_NOTATE_CHANT',
  SET_VERSES_TO_TEXT_CHANT = 'SET_VERSES_TO_TEXT_CHANT',
  ADD_REMOVED_VERSES_CHANT = 'ADD_REMOVED_VERSES_CHANT',
  CLICKED_INTO_PSALM_TONES = 'CLICKED_INTO_PSALM_TONES',
  CHANGED_PSALM_TONE = 'CHANGED_PSALM_TONE',
  TOGGLED_GLORY_BE = 'TOGGLED_GLORY_BE',
  ENGAGE_MIDI_PLAYBACK = 'ENGAGE_MIDI_PLAYBACK',
  CLICK_SHARE_BUTTON = 'CLICK_SHARE_BUTTON',
  COPY_SHARE_LINK = 'COPY_SHARE_LINK',
  CLICK_EXPORT_BUTTON = 'CLICK_EXPORT_BUTTON',
  EXPORT_FILE_BUTTON_CLICK = 'EXPORT_FILE_BUTTON_CLICK',
  EXPORT_FILE_DOWNLOAD = 'EXPORT_FILE_DOWNLOAD',
  CLICKED_STATE_SWITCHER = 'CLICKED_STATE_SWITCHER',
  CLICKED_VIEW = 'CLICKED_VIEW',
  VIEWED_VIEW_STATE = 'VIEWED_VIEW_STATE',
  VISITED_WELCOME_BACK_PAGE = 'VISITED_WELCOME_BACK_PAGE',
  GET_STARTED_DISMISSAL = 'GET_STARTED_DISMISSAL',
  OPENED_GET_STARTED = 'OPENED_GET_STARTED',
  UPLOADED_MUSIC = 'UPLOADED_MUSIC',
  REMOVED_VERSE = 'REMOVED_VERSE',
  CHANGED_PARISH_SIZE = 'CHANGED_PARISH_SIZE',
  CHANGED_BILLING_BASIS = 'CHANGED_BILLING_BASIS',
  CLICKED_CONTACT_US = 'CLICKED_CONTACT_US',
  CLICKED_COPYRIGHT_INFO  = 'CLICKED_COPYRIGHT_INFO',
  SELECT_ORDO_ROLE  = 'SELECT_ORDO_ROLE',
  CLICKED_DASHBOARD_CALENDAR = 'CLICKED_DASHBOARD_CALENDAR',
}
