/**
 * This is (mostly) copied from the one in the `mei-conversion` project.
 */
import { QUILISMA } from "../QUILISMA";
import { BREVE } from "../BREVE";
import type { VerovioOptions } from "verovio";
import { getWindowOrShim } from "../utils/isBrowser";
export const xlinkNS = 'http://www.w3.org/1999/xlink';

type VerovioSvgInfo = {
  isFirstPage: boolean;
  isLastPage: boolean;
};
export interface VerovioPostProcessConfig {
  addStanzaNumbersOnFirstSystem?: boolean;
  addStanzaNumbersOnAllSystems?: boolean;
  firstStanzaNumber?: number | string;
  replaceWholeNoteWithBreve?: boolean;
  stanzaCount?: number;
  processBboxes?: boolean;
  processSylBboxes?: boolean;
}
export const defaultPostProcessConfig = {
  antiphon: {
    addStanzaNumbersOnFirstSystem: true,
    addStanzaNumbersOnAllSystems: false,
    replaceWholeNoteWithBreve: true,
  },
  ordinary: {
    addStanzaNumbersOnAllSystems: false,
    addStanzaNumbersOnFirstSystem: false,
    replaceWholeNoteWithBreve: false,
  },
  hymn: {
    addStanzaNumbersOnFirstSystem: true,
    addStanzaNumbersOnAllSystems: true,
  }
} as const satisfies { [key in 'antiphon' | 'ordinary' | 'hymn']: VerovioPostProcessConfig };

const versiculumMap = {
  '℣': 'v',
  '℟': 'r',
} as const;
export type VersicleResponseSymbol = keyof typeof versiculumMap;
const versiculumTspanForLetter = (letter: 'v' | 'r') =>
  `<tspan class="versiculum-text">${letter}</tspan>`;
const versiculumSpanForSymbol = (
  symbol: VersicleResponseSymbol,
  className?: string,
) =>
  `<span ${
    className ? `class="${className}"` : `style="font-family: Versiculum;"`
  }>${versiculumMap[symbol]}</span>`;
const versiculumTspanForSymbol = (symbol: VersicleResponseSymbol) =>
  versiculumTspanForLetter(versiculumMap[symbol]);
export const replaceVersicleResponseInHtml = (html?: string | null) =>
  html?.replace(/(℣|℟)\.?/g, (_, symbol) => versiculumSpanForSymbol(symbol));

const parseViewBox = (viewBox?: string | null) => {
  const [, x, y, width, height] = /([^,\s]+)[,\s]+([^,\s]+)[,\s]+([^,\s]+)[,\s]+([^,\s]+)/g.exec(viewBox ?? '') ?? [];
  return { x: Number(x), y: Number(y), width: Number(width), height: Number(height) };
}
  
const processClefBBoxes = (svg: SVGSVGElement, {
  heightOffset = 0,
  processSylBboxes = true,
} = {}) => {
  const defScaleSvg = svg.querySelector<SVGSVGElement>('svg.definition-scale');
  if(!defScaleSvg) return;
  const { y, height } = parseViewBox(defScaleSvg.getAttribute('viewBox'));
  let svgMinY = y;
  let svgMaxY = svgMinY + height;

  svg.querySelectorAll<SVGRectElement>('g.clef[id^="bbox-"] > rect[height][y]').forEach(rect => {
    const y = Number(rect.getAttribute('y'));
    const height = Number(rect.getAttribute('height'));
    svgMinY = Math.min(svgMinY, y);
    svgMaxY = Math.max(svgMaxY, y + height)
  });
  svg.querySelectorAll<SVGTextElement>('g.harm text[y], g.dir text[y], g.reh text[y]').forEach(text => {
    const y = Number(text.getAttribute('y'));
    const fontSizeString = text.querySelector<SVGTSpanElement>('tspan[font-size]')?.getAttribute('font-size');
    const fontSize = fontSizeString ? parseInt(fontSizeString, 10) : 0;
    svgMinY = Math.min(svgMinY, y - fontSize * 8 / 11);
    svgMaxY = Math.max(svgMaxY, y);
  });
  // TODO: this is a bit of a hack to get Verovio 3.9.0 working quickly
  // We should revisit this and figure out what exactly is going on.  Pre-verovio 3.9.0,
  // we were not doing anything with these .syl groups
  if (processSylBboxes) {
    svg.querySelectorAll<SVGTextElement>('g.syl text[y]').forEach(text => {
      let y = Number(text.getAttribute('y'));
      const fontSizeString = text.querySelector<SVGTSpanElement>('tspan[font-size]')?.getAttribute('font-size');
      const fontSize = fontSizeString ? parseInt(fontSizeString, 10) : 0;
      y += fontSize * 0.5;
      svgMinY = Math.min(svgMinY, y - fontSize * 8 / 11);
      svgMaxY = Math.max(svgMaxY, y);
    });
  }
  const svgHeight = svgMaxY - svgMinY + heightOffset;
  const viewBox = parseViewBox(svg.getAttribute('viewBox'));
  svg.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.width} ${svgHeight / 10}`);
  const scaleViewBox = parseViewBox(defScaleSvg.getAttribute('viewBox'));
  defScaleSvg.setAttribute('viewBox', `${scaleViewBox.x} ${svgMinY} ${scaleViewBox.width} ${svgHeight}`);
}

/**
 * Adds parentheses around chord symbols with an id starting with parenthetic-
 * @param svg 
 */
const parenthesizeParentheticHarms = (svg: SVGSVGElement) => {
  svg.querySelectorAll<SVGGElement>('g.harm[id^="parenthetic-"]').forEach(g => {
    const tspan = g.querySelector<SVGTSpanElement>('tspan[font-size][x][y]');
    if (tspan && tspan.childNodes.length === 1 && tspan.childNodes[0].nodeType === tspan.TEXT_NODE) {
      tspan.textContent = `(${tspan.textContent})`;
      const x = tspan.getAttribute('x');
      const fontSize = parseInt(tspan.getAttribute('font-size') ?? '0');
      tspan.setAttribute('x', `${Number(x) - fontSize / 3.7330594249096865}`);
    }
  });
}

const correctOverrunningDirs = (svg: SVGSVGElement) => {
  const defScaleSvg = svg.querySelector<SVGSVGElement>('svg.definition-scale');
  if (!defScaleSvg) return;
  const { width: systemWidth } = parseViewBox(defScaleSvg.getAttribute('viewBox'));
  const bboxes = svg.querySelectorAll<SVGRectElement>('g.dir > text[x] g.bounding-box.text > rect');
  bboxes.forEach(rect => {
    const right = Number(rect.getAttribute('x')) + (Number(rect.getAttribute('width')) * 0.95);
    if (right > systemWidth) {
      // shift this text over by 
      const shift = systemWidth - right;
      const text = rect.closest<SVGTextElement>('text[x]');
      if (text) {
        text.setAttribute('x', `${Number(text.getAttribute('x')) + shift}`);
      }
    }
  });
}

const correctLyricSizeOnTinyNotes = (svg: SVGSVGElement, lyricSize: number) => {
  const tinyNoteLyrics = svg.querySelectorAll<SVGTSpanElement>('g.note[id^="tiny-"] > g.verse > g.syl > text tspan[font-size]:not([font-size="0"])');
  tinyNoteLyrics?.forEach(tspan => tspan.setAttribute('font-size', `${lyricSize}px`));
}

/**
 * This function also moves Dirs down to keep their margin to 0.5 MEI units
 * @param svg 
 * @param verovioConfig 
 */
const placeDirsAboveHarms = (svg: SVGSVGElement, verovioConfig: VerovioOptions) => {
  const {
    bottomMarginHarm: bottomMarginHarmMei = 1,
    defaultBottomMargin: defaultBottomMarginMei = 0.5,
    unit = 9,
  } = verovioConfig;
  const bottomMarginHarm = unit * 10 * (bottomMarginHarmMei - 0.75);
  const yOffsetMei = Math.max(0, defaultBottomMarginMei - 1);
  const yOffset = unit * 10 * (yOffsetMei);

  // loop through each system and move dir tags above harm tags if both exist:
  svg.querySelectorAll('g.system').forEach(system => {
    const harmYset = new Set<number>();
    const dirYset = new Set<number>();
    const harmTexts = system.querySelectorAll<SVGTextElement>('g.harm text[y]');
    const dirTexts = system.querySelectorAll<SVGTextElement>('g.dir text[y]');
    if ((harmTexts.length && dirTexts.length) || (yOffset && dirTexts.length)) {
      harmTexts.forEach(text => harmYset.add(Number(text.getAttribute('y'))));
      dirTexts.forEach(text => dirYset.add(Number(text.getAttribute('y'))));
      const sortFunc = (a: number, b: number) => a - b;
      const harmYs = Array.from(harmYset.values());
      const dirYs = Array.from(dirYset.values());
      const minHarmY = Math.min(...harmYs);
      const minDirY = Math.min(...dirYs);
      const reorderHarmAndDir = harmYs.length === 1 && minHarmY < minDirY;
      const allYs = [...harmYs, ...dirYs].sort(sortFunc);
      const lastYindex = allYs.length - 1;
      const lyricSize = Math.floor(10 * (verovioConfig.unit ?? 9) * (verovioConfig.lyricSize ?? 4.5));
      const allowAdjustments = !(allYs.length === 2 && Math.abs(allYs[0] - allYs[1]) < lyricSize);
      const updateY = (text: SVGTextElement, key: 'harm' | 'dir') => {
        const yString = text.getAttribute('y');
        const y = Number(yString);
        const yIndex = allYs.indexOf(y);
        const newYindex = reorderHarmAndDir
          ? key === 'dir'
          ? yIndex - 1
          : lastYindex
          : yIndex;
        let newY = allYs[newYindex];
        if (allowAdjustments) {
          if (newYindex >= harmYs.length) {
            newY -= bottomMarginHarm;
          }
          newY += yOffset;
        }
        const newStringY = `${newY}`;
        text.setAttribute('y', newStringY);
        text.querySelectorAll<SVGTSpanElement>(`tspan[y='${yString}']`).forEach(tspan => tspan.setAttribute('y', newStringY));
      }
      harmTexts.forEach(text => updateY(text, 'harm'));
      dirTexts.forEach(text => updateY(text, 'dir'));
    }
  });
};

const window = getWindowOrShim();
export const domParser = new window.DOMParser();
const xmlSerializer = new window.XMLSerializer();
const Node = window.Node;
const quilismaDef = domParser?.parseFromString(QUILISMA, 'text/xml').firstElementChild;
export const postProcessVerovioSvg = (
  svg: string,
  {
    isFirstPage,
    isLastPage,
  }: VerovioSvgInfo,
  verovioConfig: VerovioOptions,
  config: VerovioPostProcessConfig
) => {
  if (!domParser) return svg;
  const lyricSize = Math.floor(10 * (verovioConfig.unit ?? 9) * (verovioConfig.lyricSize ?? 4.5));
  const svgDoc = domParser.parseFromString(svg, 'image/svg+xml');
  const svgDefs = svgDoc.querySelector('defs');
  const svgElem = svgDoc.firstElementChild as SVGSVGElement;
  // quilisma
  const quilismaUses = svgDoc.querySelectorAll('[id^=quilisma] .notehead > use[*|href]');
  quilismaUses.forEach(use => use.setAttributeNS(xlinkNS, 'href', '#quilisma'));
  if (quilismaUses.length && svgDefs && quilismaDef) {
    svgDefs.insertAdjacentElement('afterbegin', svgDoc.importNode(quilismaDef, true));
  }
  if (verovioConfig.pageWidth && verovioConfig.adjustPageWidth) {
    const { width } = parseViewBox(svgElem.getAttribute('viewBox'));
    if (width && width !== verovioConfig.pageWidth) {
      svgElem.style.maxWidth = '100%';
      svgElem.style.width = `${(width * 100) / verovioConfig.pageWidth}%`;
    }
  }

  svgDoc.querySelectorAll('.text tspan').forEach((tspan) => {
    if (tspan.childNodes.length === 1 && /℣|℟/.test(tspan.firstChild!.textContent || '')) {
      tspan.innerHTML = tspan.textContent!.replace(
        /(℣|℟)\.?/g,
        (whole, symbol) => versiculumTspanForSymbol(symbol),
      );
    }
  });

  placeDirsAboveHarms(svgElem, verovioConfig);
  parenthesizeParentheticHarms(svgElem);
  correctOverrunningDirs(svgElem);
  correctLyricSizeOnTinyNotes(svgElem, lyricSize);

  if (config.replaceWholeNoteWithBreve) {
    const wholeSymbol = svgDefs?.querySelector<SVGSymbolElement>('[id^=E0A2]');
    wholeSymbol?.querySelector('path')?.setAttribute('d', BREVE);
    const { y, width, height } = parseViewBox(wholeSymbol?.getAttribute('viewBox'));
    wholeSymbol?.setAttribute('viewBox', `90 ${y} ${width} ${height}`);
  }
  if (
    (isFirstPage && config.addStanzaNumbersOnFirstSystem) ||
    config.addStanzaNumbersOnAllSystems ||
    config.firstStanzaNumber
  ) {
    const firstStanzaNumber = config.firstStanzaNumber || 1;
    
    // add stanza number:
    const systems = svgDoc.querySelectorAll<SVGGElement>('g.system:not(.bounding-box)');
    const firstSystem = systems[0];
    const firstClef = firstSystem?.querySelector<SVGRectElement>('g.clef rect');
    const firstVerseNote = firstSystem?.querySelector<SVGGElement>('g.note > g.verse[id^=verse_]');
    const verseGroup = 'g.verse[id^=verse_] ';
    const firstNthVerseNote = (config.stanzaCount ?? 0) <= 1 ? null : firstSystem?.querySelector<SVGGElement>(`g.note > ${verseGroup}${('+ '+verseGroup).repeat(config.stanzaCount! - 1)}`);
    const firstRefrainAmen = firstSystem?.querySelector<SVGGElement>('g.verse[id^=refrain_], g.verse[id^=amen_]');

    const verseComesAfterRefrain = firstRefrainAmen && firstVerseNote &&
      !!(firstRefrainAmen.compareDocumentPosition(firstVerseNote) & Node.DOCUMENT_POSITION_FOLLOWING);

    if (firstSystem && firstVerseNote) {
      const clefX = Number(firstClef?.getAttribute('x') || 0);
      const clefWidth = Number(firstClef?.getAttribute('width') || 0);
      const lyricFontSize = `${lyricSize}px`;
      const minStanzaX = clefX + clefWidth;
      let minLyricX = Infinity;
      firstSystem?.querySelectorAll<SVGTextElement>('g.verse[id^=verse_] text[x], g.verse[id^=amen_] text[x]').forEach(text => minLyricX = Math.min(minLyricX, Number(text.getAttribute('x'))));
      const addStanzaNumber = (note: SVGGElement, x: number) => {
        const verses = note.querySelectorAll<SVGGElement>('g.verse[id^=verse_], g.verse[id^=amen_]');
        verses.forEach(verse => {
          let [, stanzaNumForVerse, italicStanzaLabel, stanzaLabel] = /^(?:verse|amen)_(?:(\d+)|(_?)([^_]+))_/.exec(verse.id) || [];
          let stanzaNum: number | string;
          if(!stanzaLabel) {
            if (typeof firstStanzaNumber === 'string') {
              stanzaNum = firstStanzaNumber;
            } else {
              stanzaNum = Number(stanzaNumForVerse) + firstStanzaNumber - 1;
            }
            if (Number(stanzaNum) === 0) return;
            if (stanzaNum in versiculumMap) {
              stanzaLabel = versiculumTspanForSymbol(stanzaNum as '℣'|'℟');
            } else {
              stanzaLabel = `${stanzaNum}.`;
            }
          } else if (['versicle', 'response'].includes(stanzaLabel)) {
            stanzaLabel = versiculumTspanForLetter(stanzaLabel[0] as 'v'|'r');
          } else if (/^[℣℟]\.?$/.test(stanzaLabel)) {
            stanzaLabel = versiculumTspanForSymbol(stanzaLabel[0] as '℣'|'℟');
          }
          let fontStyle = italicStanzaLabel ? "italic" : "normal";
          const firstText = verse.querySelector('text');
          if (firstText) {
            const newText = svgDoc.createElementNS('http://www.w3.org/2000/svg', 'text');
            newText.classList.add('stanza-number');
            newText.setAttribute('x', `${x}`);
            newText.setAttribute('y', firstText.getAttribute('y') || '');
            newText.setAttribute('font-size', lyricFontSize);
            newText.setAttribute('text-anchor', 'end');
            newText.setAttribute('font-style', fontStyle);
            newText.setAttribute('font-weight', 'normal');
            newText.innerHTML = stanzaLabel;
            firstText.insertAdjacentElement('beforebegin', newText)
          }
        });
      };
      
      const isFirstStanzaNumber = isFirstPage || verseComesAfterRefrain
      if (isFirstStanzaNumber) {
        const maxLyricGap = verseComesAfterRefrain ? 200 : 400;
        addStanzaNumber(firstVerseNote.parentElement as unknown as SVGGElement, Math.max(minStanzaX, minLyricX - maxLyricGap))
      } else if (config.addStanzaNumbersOnAllSystems) {
        addStanzaNumber((firstNthVerseNote || firstVerseNote).parentElement as unknown as SVGGElement, clefX + clefWidth);
      }
    }

    // move refrains to middle when they don't start a system:
    if (firstRefrainAmen && !verseComesAfterRefrain) {
      let system = firstRefrainAmen.parentElement as unknown as SVGGElement;
      while (system && !system.classList.contains('system')) {
        system = system.parentElement as unknown as SVGGElement;
      }
      // collect verse Y coordinates:
      let verseY: number[] = [],
        refrainY: number[] = [],
        amenY: number[] = [],
        yArrays = { verse: verseY, refrain: refrainY, amen: amenY };
      system.querySelectorAll<SVGGElement>('g.verse[id^=verse_], g.verse[id^=refrain], g.verse[id^=amen_]').forEach((verse) => {
        const text = verse.querySelector<SVGTextElement>('text[y]');
        const [, verseRefrain, verseNumString] = /^(verse|refrain|amen)_?(\d+)/.exec(verse.id) || [];
        if (!verseRefrain) return;
        const y = Number(text?.getAttribute('y') || 0);
        const verseNum = Number(verseNumString) - 1;
        // on refrains, ignore any self closing text tags, because these are used only to end previous underscore lyric extenders.
        if (verseRefrain === 'refrain' && !text?.children.length) return;
        yArrays[verseRefrain as 'verse'|'refrain'|'amen'][verseNum] = y;
      });
      const verseCount = verseY.length;
      const refrainCount = refrainY.length;
      if (verseCount && refrainCount) {
        const yOffset = (verseY[verseCount - refrainCount] - verseY[0]) / 2;
        if (yOffset) {
          system.querySelectorAll<SVGGElement>('g.verse[id^=refrain_]').forEach(refrain => {
            const rect = refrain.querySelector('rect');
            const text = refrain.querySelector('text');
            rect?.setAttribute('y', `${yOffset + Number(rect.getAttribute('y'))}`);
            text?.setAttribute('y', `${yOffset + Number(text.getAttribute('y'))}`);
          });
        }
      }
    }
  }
  if (config.processBboxes ?? true) processClefBBoxes(svgElem, { processSylBboxes: config.processSylBboxes ?? true });
  return xmlSerializer.serializeToString(svgElem);
};
