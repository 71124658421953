/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithNameAndId } from "utils/api-types/WithNameAndId";
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { GQLDocument, GQLQuery } from './gql-generics';

export type OrdoRole = {
  subtitle: string;
  defaultRoleConfig: OrdoRoleConfig;
} & WithNameAndId;

export type OrdoRoleConfig = Pick<OrdoCardConfig, 'notation'|'displayHarmonizations'|'displayChordSymbols'|'verseStyle'|'verseCount'|'includeGloryBe'> & {
  addGrandStaff?: true;
};

export const ORDO_ROLE_FRAGMENT = gql`
  fragment OrdoRole on OrdoRole {
    id
    name
    subtitle
    defaultRoleConfig {
      notation
      displayHarmonizations
      displayChordSymbols
      verseStyle
      verseCount
      includeGloryBe
    }
  }
`;

export interface GetOrdoRole extends GQLQuery {
  Variables: { id: number };
  Data: { ordoRole: OrdoRole };
}
export const GetOrdoRole: GQLDocument<GetOrdoRole> = {
  query: gql`
    query getOrdoRole($id: Float!) {
      ordoRole(id: $id) {
        ...OrdoRole
      }
    }
    ${ORDO_ROLE_FRAGMENT}
  `
}

export interface GetOrdoRoles extends GQLQuery {
  Variables: never;
  Data: { ordoRoles: OrdoRole[] };
}
export const GetOrdoRoles: GQLDocument<GetOrdoRoles> = {
  query: gql`
    query getOrdoRoles {
      ordoRoles {
        ...OrdoRole
      }
    }
    ${ORDO_ROLE_FRAGMENT}
  `
}
