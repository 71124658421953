import { OIDC_AUTHORITY, OIDC_CLIENT_ID } from 'utils/authorization/initAuthManager';

let ignoreAuthToken = false;
export const setIgnoreAuthorizationToken = (ignore = true) => {
  ignoreAuthToken = ignore;
}

export const getAuthorizationToken = () => {
  const token = !ignoreAuthToken && sessionStorage.getItem(
    `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`,
  );
  if (token) {
    return JSON.parse(token);
  } else {
    return;
  }
};

export const getHasAuthorizationToken = () => {
  const token = getAuthorizationToken();
  return !!token?.id_token;
};
