import { useBackendLoggedQuery } from 'hooks/queries/useQuery';
import { GetAlleluiaOptions } from 'queries/text-option';
import { Season } from 'utils/api-types/ordo/Season';

export const useAlleluiaOptionsQuery = (cardId: number | null, season?: Season) => {
  return useBackendLoggedQuery(
    GetAlleluiaOptions,
    {
      variables: {
        cardId: cardId || 0,
        season,
      },
      skip: cardId === null,
    },
  );
}
