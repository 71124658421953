import { Spacing } from "components/shared/editor/settings/SpacingToggler";
import { CardContext } from "context/CardContext";
import { ScreenSize, useScreenSize } from "hooks/useScreenSize";
import { useSizeMultiplier } from "hooks/useSizeMultiplier";
import { useContext, useMemo } from "react";
import type { SharedExsurgeProps } from 'react-exsurge';
import { getArabicNumeral } from 'utils/functions/getArabicNumeral';

export type ExsurgeConfig = Partial<SharedExsurgeProps>;

const mapAnnotationSpansToTextLeft: ExsurgeConfig['mapAnnotationSpansToTextLeft'] = (spans) => {
  if (spans.length === 1) {
    const spanText: string = spans[0]?.text;
    const spanNumeral = getArabicNumeral(spanText);
    if (spanText !== spanNumeral) {
      spans = [...spans];
      spans[0] = spans[0].clone();
      spans[0].properties['font-style'] = 'italic';
      spans[0].text = 'Mode ' + spanNumeral;
    }
  }
  return spans;
};
const contextCreated: ExsurgeConfig['contextCreated'] = (ctxt) => {
  ctxt.minNotesLastLine = 7;
  ctxt.minSyllablesLastLine = 3;
};
export const useDefaultExsurgeConfig = ({ spacingHorizontal, spacingVertical }: {
  spacingHorizontal?: Spacing | null;
  spacingVertical?: number | null;
}): ExsurgeConfig => {
  spacingVertical ??= 0;
  const screenSize = useScreenSize();
  const sizeMultiplier = useSizeMultiplier();
  const { textSize } = useContext(CardContext);
  const zoom = sizeMultiplier ?? textSize ?? 1;
  let spacing = spacingHorizontal ?? 0;
  spacing *= spacing < 0 ? 2 : 1;
  const interSyllabicSpacing = 6.5 + spacing;
  return useMemo(() => {
    const staffSize = zoom * (
      screenSize === ScreenSize.XS ? 39.06253906504 : 43.4028211834 //18px lyric on XS screens; 20px otherwise
    );
    const spaceBetweenSystems = 1 + (Math.min(0, spacingVertical!) * 0.5);
    const spaceAboveLyrics = 1.25 + (Math.min(0, spacingVertical!) * 0.4);
    return {
      height: 1, // force each system to render to its own SVG by setting page height lower than what any system will fit in
      defaultFont: 'EB Garamond Full',
      defaultColor: 'inherit',
      rubricColor: '#E22729',
      staffSize,
      interSyllabicSpacing,
      spaceBetweenSystems,
      spaceAboveLyrics,
      textStyles: {
        dropCap: {
          size: 0.8,
        },
        leftRight: {
          size: 0.8,
        },
        annotation: {
          size: 1,
        },
        subtitle: {
          color: '#E20300'
        },
        al: {
          size: 0.72,
        },
        lyric: {
          lineHeight: 1.1 + spacingVertical! * 0.075,
        },
      },
      mapAnnotationSpansToTextLeft,
      contextCreated,
    };
  }, [interSyllabicSpacing, screenSize, zoom, spacingVertical]);
}
