interface Labels {
  [key: number]: string;
  unknown: string;
};

const labels: Labels = {
  1: 'Single Reader',
  3: '3 Parts',
  4: '4 Parts',
  unknown: 'Select Parts',
};

export const getNumberOfReadersLabel = (num: number): string => {
  if (num in labels) {
    return labels[num];
  } else {
    return labels.unknown;
  }
};
