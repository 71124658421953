export const removeLocalStorageHostedPageId = () => {
  try {
    window.localStorage?.removeItem('hostedPageId');
  } catch {}
};

export const setLocalStorageHostedPageId = (hostedPageId: string) => {
  try {
    window.localStorage.setItem('hostedPageId', hostedPageId);
  } catch {}
};

export const getLocalStorageHostedPageId = () => {
  try {
    return window.localStorage.getItem('hostedPageId');
  } catch {}
};
