import { LayoutContext, defaultLayoutContext } from 'context/LayoutContext';
import { ScreenSize, useScreenSize } from 'hooks/useScreenSize';
import { useSizeMultiplier } from 'hooks/useSizeMultiplier';
import { useContext, useMemo } from 'react';
import type { VerovioOptions } from 'react-verovio';
import { getContentWidth } from 'utils/getContentWidth';

export const DEFAULT_PAGE_WIDTH = 1450;

const defaultOptions: VerovioOptions = {
  breaks: 'auto',
  transpose: '',
  pageHeight: 100,
  adjustPageHeight: true,
  adjustPageWidth: false,
  font: 'Bravura',
  header: 'none',
  footer: 'none',
  graceFactor: 0.7,
  spacingSystem: 3,
  spacingStaff: 0,
  spacingLinear: 0.33,
  spacingNonLinear: 0.57,
  lyricSize: 5.5,
  lyricWordSpace: 3,
  lyricTopMinMargin: 2,
  lyricNoStartHyphen: true,
  pageMarginLeft: 0,
  pageMarginRight: 0,
  pageMarginTop: 0,
  pageMarginBottom: 0,
  svgViewBox: true,
  xmlIdSeed: 1,
  svgBoundingBoxes: true,
  defaultBottomMargin: 0.5,
  defaultTopMargin: 0.5,
  rightMarginLeftBarLine: 1,
  bottomMarginHarm: 2,
  topMarginHarm: 0,
  leftMarginChord: 0,
  rightMarginChord: 0,
  smuflTextFont: 'none',
  svgFormatRaw: true,
  svgAdditionalAttribute: ["slur@layer", "tie@layer", "layer@n", "staff@n", "rend@rend"],
} as const;

export const useDefaultVerovioOptions = (
  extraConfig?: VerovioOptions,
  isFullScreen: boolean = false,
  showHarmonizations: boolean | null = false,
  cardHarmonizations: boolean | null = false,
  addGrandStaff: boolean = false,
  spacingVertical: number | null = 0,
) => {
  if (addGrandStaff && showHarmonizations) {
    addGrandStaff = false;
  }
  let screenSize = useScreenSize();
  const { contentWidth, harmonizedContentWidth, ordo } = useContext(LayoutContext);
  const isLayoutRendering = ordo !== defaultLayoutContext.ordo;

  let pageWidth =
    screenSize === ScreenSize.XS ? (DEFAULT_PAGE_WIDTH * 1.069 * window.innerWidth) / 600 : DEFAULT_PAGE_WIDTH;

  if (extraConfig?.pageWidth) {
    pageWidth =
      extraConfig.pageWidth < 3
        ? pageWidth * extraConfig.pageWidth
        : extraConfig.pageWidth;
  }

  let lyricSize = extraConfig?.lyricSize || defaultOptions.lyricSize;
  let defaultBottomMargin = extraConfig?.defaultBottomMargin || defaultOptions.defaultBottomMargin;
  let defaultTopMargin = extraConfig?.defaultTopMargin || defaultOptions.defaultTopMargin;
  let spacingStaff = extraConfig?.spacingStaff || defaultOptions.spacingStaff;

  if (isLayoutRendering) {
    const width = (showHarmonizations || cardHarmonizations) ? harmonizedContentWidth : contentWidth;
    pageWidth = DEFAULT_PAGE_WIDTH * width / 5.5;
  } else if (!getContentWidth() && (showHarmonizations || cardHarmonizations)) {
    // render with 7 inch content area instead of 5.5
    pageWidth *= Math.max(590, Math.min(window.innerWidth - 60, 750)) / 590;
  }

  const sizeMultiplier = useSizeMultiplier() ?? 1;
  pageWidth /= sizeMultiplier;

  if (addGrandStaff) {
    defaultBottomMargin = 5;
    defaultTopMargin = 5;
  }

  if (spacingVertical) {
    defaultBottomMargin! += spacingVertical * 0.5;
    defaultBottomMargin = Math.max(0, Math.min(5, defaultBottomMargin!));
    defaultTopMargin! += spacingVertical * 0.5;
    defaultTopMargin = Math.max(0, Math.min(5, defaultTopMargin!));
  }

  if (showHarmonizations || addGrandStaff) {
    spacingStaff = 3.5;
  }

  const options = useMemo<VerovioOptions>(
    () => ({
      ...defaultOptions,
      ...extraConfig,
      pageWidth,
      lyricSize,
      defaultBottomMargin,
      defaultTopMargin,
      spacingStaff,
    }),
    [extraConfig, pageWidth, lyricSize, defaultBottomMargin, defaultTopMargin, spacingStaff]
  );
  return options;
};
