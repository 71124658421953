/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithId } from 'utils/api-types/WithNameAndId';
import { GQLDocument, GQLMutation } from './gql-generics';
import { Dictionary } from "utils/typescript/Dictionary";

export interface LogError extends GQLMutation<LogError> {
  Variables: {
    type: string;
    miscData?: Dictionary<unknown>;
    notifySlack?: boolean;
  }
  Data: {
    logError: WithId;
  }
}
export const LogError: GQLDocument<LogError> = {
  mutation: gql`
    mutation logError($type: String!, $miscData: JSONObject, $notifySlack: Boolean) {
      logError(type: $type, miscData: $miscData, notifySlack: $notifySlack) {
        id
      }
    }
  `
}

export type LogErrorFunction = (type: LogError['Variables']['type'], miscData?: LogError['Variables']['miscData'], notifySlack?: LogError['Variables']['notifySlack']) => void;
