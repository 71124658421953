import { cx } from '@emotion/css';
import React, { CSSProperties } from 'react';

type FlexboxProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  flexDirection?: CSSProperties['flexDirection'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexFlow?: CSSProperties['flexFlow'];
}

// You could make a function to auto-generate components that give access to a given list of CSS properties with certain defaults
export const Flexbox : React.FC<FlexboxProps> = ({
  className,
  flexDirection,
  justifyContent,
  alignItems,
  flexFlow,
  style,
  children,
  ...otherProps
}) => {
  return <div {...otherProps} className={cx('flexbox', className)} style={{
    ...style,
    display: 'flex',
    flexDirection,
    justifyContent,
    alignItems,
    flexFlow,
  }}>
    {children}
  </div>;
};
