import { useQuery } from 'hooks/queries/useQuery';
import { OrdoCard, OrdoCardShare } from 'queries/card-fragment';
import { GetOrdoCardSvg } from 'queries/ordo';
import { useMemo } from 'react';
import { getScreenWidthForEngravings } from 'utils/ordo/getScreenWidthForEngravings';

export const useCardWithSvgFromCache = ({
  cardWithTextOptions,
  textSize = 1,
  dynamicHymnScaling = false,
  ordoRoleId,
  forceFullWidth,
}: {
  cardWithTextOptions: OrdoCardShare | OrdoCard;
  textSize: number;
  dynamicHymnScaling: boolean;
  ordoRoleId?: number;
  /**
   * if true, use the largest width allowed for engravings; i.e., for exports
   */
  forceFullWidth?: boolean;
}): OrdoCard | OrdoCardShare => {
  const screenWidth = getScreenWidthForEngravings(forceFullWidth ? Infinity : window.innerWidth, textSize);
  // just get the data from the cache.  The network activity is handled from <ShareMain />
  const { data } = useQuery(GetOrdoCardSvg, {
    fetchPolicy: 'cache-only',
    variables: {
      id: cardWithTextOptions.id,
      screenWidth,
      textScale: textSize,
      dynamicHymnScaling,
      ordoRoleId,
    },
  });
  const svg = data?.card?.svg;
  return useMemo(
    () =>
      svg
        ? {
            ...cardWithTextOptions,
            svg,
            mei: undefined,
          }
        : cardWithTextOptions,
    [cardWithTextOptions, svg],
  );
};
