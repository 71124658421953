import { ErrorMessage } from 'components/gql/ErrorMessage';
import { LoadingMessage } from 'components/gql/LoadingMessage';
import { CardContext } from 'context/CardContext';
import { useGetResourceGroupQuery } from 'hooks/resources/useGetResourceGroupQuery';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { isFile } from 'utils/api-types/resource';
import { ResourceAttributesDisplay } from './ResourceAttributesDisplay';
import { ResourceDisplay } from './ResourceDisplay';

export const ResourceGroupDisplay: React.FC<{ isActive: boolean; }> = ({ isActive }) => {
  const { config, onLoaded } = useContext(CardContext);

  const {
    loading: loadingResourceGroup,
    error: resourceGroupError,
    data: { resourceGroup } = { resourceGroup: undefined }
  } = useGetResourceGroupQuery(config.resourceGroupId || undefined);

  const resources = useMemo(() => resourceGroup?.resources.map(({resource}) => resource) ?? [], [resourceGroup]);
  const primaryResource = useMemo(() => resources.find(resource => resource.id === config.primaryResourceId), [config, resources]);
  const otherResources = resources.filter(
    (resource) =>
      resource.id !== config.primaryResourceId &&
      config.resourceIds?.includes(resource.id),
  );

  const displayReference = useMemo(() => {
    return !resources.length ||
      !primaryResource ||
      (isFile(primaryResource) &&
        !primaryResource.file
      );
  }, [resources, primaryResource]);

  useEffect(() => {
    if (!loadingResourceGroup && displayReference) {
      onLoaded();
    }
  });

  const ref = useRef<HTMLDivElement>(null);

  if (loadingResourceGroup) {
    return <LoadingMessage />;
  }

  if (resourceGroupError) {
    return <ErrorMessage
      headline='Unable to load your hymn'
      error={resourceGroupError}
    />;
  };

  if (displayReference && resourceGroup) {
    return <div className='_EXPORT'>
      <ResourceAttributesDisplay resourceGroup={resourceGroup} displayDataItems={['setting|title', 'composerSource', 'collection', 'pageNumber']} />
    </div>;
  }

  return <div className='resource-group-display mt-2' ref={ref}>
    {/* <ResourceAttributesDisplay {...{ resourceGroup }} displayDataItems={['title', 'composerSource', 'collection', 'pageNumber']} /> */}
    {primaryResource && <ResourceDisplay
      resource={primaryResource}
      parentRef={ref}
      isActive={isActive}
    />}
    <div className='secondary-resources'>
      {otherResources.map((resource: any) => <ResourceDisplay
        key={resource.id}
        resource={resource}
        parentRef={ref}
        isActive={isActive}
      />)}
    </div>
  </div>;
};
