import { Translation } from "queries/translation";
import { Text } from "queries/text";
import { ReadingDisplayType } from "./ReadingSettingsTypes";

export enum ReadingTypes {
  LONG = 'long',
  SHORT = 'short',
}
export type ReadingTypeOrBoth = ReadingTypes | 'both';

export type TranslationContentProperties = Pick<Translation, 'content'|'altContent'|'synopsis'>;

export function getReadingContent(
  translation: TranslationContentProperties,
  form?: ReadingTypeOrBoth | null,
  readingDisplay?: ReadingDisplayType | null,
): string;
export function getReadingContent(
  translation?: TranslationContentProperties | null,
  form?: ReadingTypeOrBoth | null,
  readingDisplay?: ReadingDisplayType | null,
): string | undefined;

export function getReadingContent (
  translation?: TranslationContentProperties | null,
  form?: ReadingTypeOrBoth | null,
  readingDisplay?: ReadingDisplayType | null,
) {
  if (readingDisplay === 'Synopsis') {
    return translation?.synopsis;
  }
  switch (form) {
    case ReadingTypes.SHORT:
      return translation?.altContent;
    case ReadingTypes.LONG:
    default:
      return translation?.content;
  }
}

type TextSourceProperties = Pick<Text, 'originalSource'|'altSource'>;

export const getReadingSource = (text?: TextSourceProperties, form?: ReadingTypeOrBoth | null) => {
  switch (form) {
    case ReadingTypes.SHORT:
      return text?.altSource;
    case ReadingTypes.LONG:
    default:
      return text?.originalSource;
  }
}

export const ReadingDisplay = {
  [ReadingTypes.LONG]: 'Long Form',
  [ReadingTypes.SHORT]: 'Short Form',
  'both': 'Both Forms',
};
