import { css, cx } from '@emotion/css';
import React, { useState } from 'react';
import { ReadingDisplayType, TextDisplayType } from 'utils/api-types/ordo/card/ReadingSettingsTypes';
import { colorDictionary } from 'utils/styles';
import { TextSource } from './TextSource';
import { Paragraphs } from './renderer/Paragraphs';
import { ReadersKey } from './renderer/ReadersKey';
import { Regular } from './renderer/Regular';

interface TranslationProps {
  text: string;
  englishTranslation?: string;
  scriptureReference?: string;
  sourceNames?: string[];
  showAll?: boolean;
  showSource?: boolean;
  showCanonicalSource?: boolean;
  textInterjected?: string;
  textInterjectedBefore?: string;
  className?: string;
  language?: string;
  numberOfReaders?: number;
  readingDisplay?: ReadingDisplayType;
  textDisplay?: TextDisplayType;
  readingIntroduction?: string | null | false;
  readingConclusion?: string | null | false;
  exportPadBottom?: number;
  exportPadTop?: number;
  allowFullText?: boolean;
  columns?: number | null;
  includeGloryBe?: boolean | null;
  addExportClass?: boolean;
  paragraphClassName?: string;
}

export const Translation: React.FC<TranslationProps> = ({
  text,
  englishTranslation,
  scriptureReference,
  sourceNames,
  showAll = true,
  showSource = false,
  showCanonicalSource,
  className = '',
  textInterjected,
  textInterjectedBefore,
  language = '',
  numberOfReaders = 1,
  allowFullText = true,
  readingDisplay = allowFullText ? 'Full Text' : 'Synopsis',
  textDisplay = 'In Verse',
  readingIntroduction,
  readingConclusion,
  exportPadBottom,
  exportPadTop,
  columns,
  includeGloryBe,
  addExportClass = true,
  paragraphClassName,
}) => {
  const [showAllState] = useState<boolean>(showAll);
  const effectiveReadingDisplay = readingDisplay === 'Full Text' && !allowFullText ? 'Synopsis' : readingDisplay;

  if (effectiveReadingDisplay !== 'Full Text') {
    // a greater number of readers than 1 should not be displayed on forms other than full text
    numberOfReaders = 1;
  }

  // Synopsis should be italicized
  text = effectiveReadingDisplay === 'Synopsis' ? `_${text}_` : text;
  readingIntroduction = readingIntroduction
    ? readingIntroduction.replace(/☩/, `<span style="color: ${colorDictionary['Rubric']};">☩</span>`)
    : readingIntroduction;

  let content =
    textDisplay === 'Paragraph' ? (
      <Paragraphs
        text={text}
        showAll={showAllState}
        className={className}
        language={language}
        numberOfReaders={numberOfReaders}
      />
    ) : (
      <Regular
        addExportClass={addExportClass}
        text={text}
        textInterjected={textInterjected}
        textInterjectedBefore={textInterjectedBefore}
        showAll={showAllState}
        className={cx(className, { 'columns-2': columns === 2 })}
        language={language}
        numberOfReaders={numberOfReaders}
        includeGloryBe={includeGloryBe}
        columns={columns}
        paragraphClassName={paragraphClassName}
      />
    );

    if (englishTranslation) {
      const englishTranslationContent =
        textDisplay === 'Paragraph' ? (
          <Paragraphs
            text={englishTranslation}
            showAll={showAllState}
            className={className}
            language={language}
            numberOfReaders={numberOfReaders}
          />
        ) : (
          <Regular
            text={englishTranslation}
            textInterjected={textInterjected}
            showAll={showAllState}
            className={className}
            language={language}
            numberOfReaders={numberOfReaders}
          />
        );
      content = <div className='multilingual-translation-content'>
        <span className='multilingual-original-language-translation'>{content}</span>
        <span className='multilingual-english-translation'>{englishTranslationContent}</span>
      </div>;
    }

  showSource = !!((sourceNames?.length && showCanonicalSource) || scriptureReference) && showSource;

  return (
    <div
      className={
        cx(
          style,
          'translation',
          {
            _EXPORT: addExportClass,
            'reference-only': effectiveReadingDisplay === 'Reference',
          }
        )
      }
      {...({
        ...(exportPadBottom ? {'data-export-pad-bottom': exportPadBottom} : {}),
        ...(exportPadTop ? {'data-export-pad-top': exportPadTop} : {}),
      })}
    >
      {numberOfReaders !== 1 && <ReadersKey
        language={language}
        numberOfReaders={numberOfReaders}
      />}
      {showSource && <TextSource
        scriptureReference={scriptureReference}
        sourceNames={sourceNames}
        showCanonicalSource={showCanonicalSource}
      />}
      {/* Don't show content if we're only supposed to display the reference */}
      {(effectiveReadingDisplay !== 'Reference') &&
        (effectiveReadingDisplay === 'Full Text'
          ? <>
            {readingIntroduction && <Translation className='pb-15 no-page-break-after' text={readingIntroduction} />}
            {content}
            {readingConclusion && <Translation className='pt-15 no-page-break-before' text={readingConclusion} />}
          </>
          : content
        )
      }
    </div>
  );
}

const style = css`
  &.reference-only .text-reference {
    font-size: 1em;
    color: ${colorDictionary['Primary Text']};
  }
  .text-reference {
    padding-bottom: 10px;
  }
  *, .content > p {
    margin-bottom: 0;
  }
  .multilingual-translation-content {
    font-size: 20px;
    display: flex;
    .multilingual-original-language-translation {
      width: 55%;
      flex-shrink: 0;
    }
    .multilingual-english-translation {
      width: 45%;
      font-size: 16px;
      font-style: italic;
      margin-left: 15px;
    }
  }
`;
