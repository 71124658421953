import { ConfiguredState as Alleluia } from 'components/ordo/cards/alleluia/ConfiguredState';
import { ConfiguredState as Antiphon } from 'components/ordo/cards/antiphon/ConfiguredState';
import { ConfiguredState as Ordinary } from 'components/ordo/cards/ordinary/ConfiguredState';
import { ConfiguredState as Reading } from 'components/ordo/cards/reading/ConfiguredState';
import { HymnCard } from 'components/ordo/cards/hymn/HymnCard';
import { NotesCard } from 'components/ordo/cards/notes/NotesCard';
import { ResourceGroupDisplay } from 'components/ordo/cards/resource/ResourceGroupDisplay';
import { ContextualRubricWrapper } from 'components/ordo/cards/rubric/ContextualRubricWrapper';
import { RubricCard } from 'components/ordo/cards/rubric/RubricCard';
import { SectionHeaderCard } from 'components/ordo/cards/section-header/SectionHeaderCard';
import { OrdoCard, OrdoCardShare } from 'queries/card-fragment';
import React from 'react';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { cardNotPreparedBody } from 'utils/ordo/card/cardNotPreparedBody';
import { PrayerCard } from 'components/ordo/cards/prayer/PrayerCard';

export const ShareCardBody: React.FC<{
  card: OrdoCardShare | OrdoCard;
  isActive: boolean;
}> = ({ card, isActive }) => {
  if (!card.isConfigured) {
    return cardNotPreparedBody;
  }
  if (card.config.resourceGroupId) {
    return <ResourceGroupDisplay isActive={isActive} />;
  }
  if (
    [
      CardTypes.ANTIPHON_VERSE,
      CardTypes.ANTIPHON_VERSE_GLORIA_PATRI,
      CardTypes.ENTRANCE_CHANT,
      CardTypes.COMMUNION_CHANT,
      CardTypes.RESPONSORIAL_PSALM,
    ].includes(card.type)
  ) {
    return <Antiphon isActive={isActive} />;
  } else if (card.type === CardTypes.SECTION_HEADER) {
    return <SectionHeaderCard />;
  } else if (card.type === CardTypes.READING) {
    return (
      <ContextualRubricWrapper>
        <Reading />
      </ContextualRubricWrapper>
    );
  } else if (card.type === CardTypes.RUBRIC) {
    return <RubricCard />;
  } else if (card.type === CardTypes.HYMN) {
    return <HymnCard isActive={isActive} />;
  } else if (
    card.type === CardTypes.ORDINARY ||
    card.type === CardTypes.SEQUENCE ||
    card.type === CardTypes.MYSTERY_OF_FAITH
  ) {
    return (
      <ContextualRubricWrapper>
        <Ordinary isActive={isActive} />
      </ContextualRubricWrapper>
    );
  } else if (
    card.type === CardTypes.ALLELUIA ||
    card.type === CardTypes.VERSE_BEFORE_GOSPEL
  ) {
    return <Alleluia isActive={isActive} />;
  } else if (card.type === CardTypes.NOTES) {
    return <NotesCard viewState='VIEW' />;
  } else if (card.type === CardTypes.PRAYER) {
    return <PrayerCard isActive={isActive} />;
  } else if (card.type === CardTypes.SELECT) {
    return null;
  }
  return null;
};
