import { GetResourceGroup } from 'queries/resource';
import { useDebugLogger, useLoggedQuery } from 'hooks/debug';

export const useGetResourceGroupQuery = (id?: number | null, skip: boolean = false) => {
  const debugLogger = useDebugLogger('upload');

  return useLoggedQuery(
    debugLogger,
    'GetResourceGroup',
    GetResourceGroup,
    {
      variables: id ? { id } : undefined,
      skip: !id || skip,
    }
  );
}
