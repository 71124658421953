import { JwtUser } from 'hooks/useUser';
import userflow from 'userflow.js';

export const loadUserFlow = (user: JwtUser) => {
  const userFlowToken = document.domain === 'app.sourceandsummit.com' ? 'ct_btchioqn3na5bogcqfhrji76u4' : 'ct_frf6rxdrzbdsjogsbxdi4ghyym';
  userflow.init(userFlowToken);
  userflow.identify(user.sub, {
    name: user.name,
    email: user.email,
    signed_up_at: {
      set_once: (new Date(user.created_at || 0)).toISOString()
    }
  });
}
