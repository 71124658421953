import { getReaderNames, Reader } from './getReaderNames';

export const getNumberOfReadersKey = (language: string, numberOfReaders: number): Reader[] => {
  const names = getReaderNames();  
  const langNames = names[language] || null;

  if (langNames === null) {
    return [];
  }

  const readerNames = langNames[4];

  if (numberOfReaders === 4) {
    return Object.values(readerNames);
  }

  if (numberOfReaders === 3) {
    const {C, ...smallerNamesList} = readerNames;
    return Object.values(smallerNamesList);
  }

  return [];
};
