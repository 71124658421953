import React, { useContext, useEffect } from 'react';
import { RubricText } from './RubricText';
import { CardContext } from 'context/CardContext';
import { OrdoContext } from 'context/OrdoContext';
import { cx } from '@emotion/css';

export const RubricCard: React.FC = () => {
  const { onLoaded, textOptions, card } = useContext(CardContext);
  const { ordo: { cards } } = useContext(OrdoContext);

  const isLast = cards[cards.length - 1] === card;

  const texts = textOptions?.flatMap((textOption) => textOption.texts);
  const translations = texts?.flatMap((text) => text.officialTranslations);

  useEffect(() => {
    onLoaded();
  }, [onLoaded]);

  return <div className='rubric-card m-0 mh-30 d-flex align-items-center'>
    {translations?.map((translation) => <RubricText
      className={cx('no-page-break-after', { 'no-page-break-before': isLast })}
      text={translation.content}
      key={`rubric-translation-${translation.id}`}
    />)}
  </div>;
};
