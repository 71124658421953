import { Dictionary } from "utils/typescript/Dictionary";

export interface Reader {
  label: string,
  displayAs: string,
}

export type Names = Dictionary<Reader>;

export interface NamesConfig {
  [key: number]: Names 
}

export type LanguagesConfig = Dictionary<NamesConfig>;

export const getReaderNames = (): LanguagesConfig => {
  const englishNames = {
    '+': {
      label: 'Christ',
      displayAs: '+'
    },
    'N': {
      label: 'Narrator',
      displayAs: 'N',
    },
    'V': {
      label: 'Voice',
      displayAs: 'V',
    },
    'C': {
      label: 'Crowd',
      displayAs: 'C',
    },
  };

  const spanishNames = {
    '+': {
      label: 'Cristo',
      displayAs: '+',
    },
    'N': {
      label: 'Cronista',
      displayAs: 'C',
    },
    'V': {
      label: 'Sinagoga',
      displayAs: 'S',
    },
    'C': {
      label: 'Pueblo',
      displayAs: 'P',
    },
  };

  const englishConfig = {
    3 : {
      ...englishNames,
      'C': englishNames.V,
    },
    4 : englishNames,
  };

  const names: LanguagesConfig = {
    'en': englishConfig,
    'la': englishConfig,
    'es': {
      3 : {
        ...spanishNames,
        'C': spanishNames.V,
      },
      4: spanishNames,
    }
  };

  return names;
};
