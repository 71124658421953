import {
  PDFArray,
  PDFDict,
  PDFName,
  PDFNull,
  PDFString,
} from '@cantoo/pdf-lib';
import { loadPdfLib } from 'utils/export/pdfTools';
import { ExportFile } from './ExportFile';

export const replacePdfAttachments = async ({
  pdfBlob,
  pdfAttachments,
  cardPages,
}: {
  pdfBlob: Blob;
  pdfAttachments: ExportFile[];
  cardPages: string[][];
}) => {
  const { PDFDocument } = await loadPdfLib();
  const doc = await PDFDocument.load(await pdfBlob.arrayBuffer());
  const pdfDestNames = doc.catalog
    .lookupMaybe(PDFName.of('Names'), PDFDict)
    ?.lookupMaybe(PDFName.of('Dests'), PDFDict)
    ?.lookupMaybe(PDFName.of('Names'), PDFArray);
  const destNamesArray = pdfDestNames?.asArray();

  for (const attachment of pdfAttachments) {
    // find page number based on attachment.key, which is the card ID string
    let pNum = cardPages.findIndex((keys) => keys.includes(attachment.key));
    if (pNum < 0) pNum = cardPages.length;

    const aBuffer = await attachment.content.arrayBuffer();
    const uploadedDoc = await PDFDocument.load(aBuffer);
    const uploadedPages = await doc.copyPages(
      uploadedDoc,
      uploadedDoc.getPageIndices(),
    );
    if (uploadedPages.length > 1) {
      cardPages.splice(
        pNum + 1,
        0,
        ...Array.from(Array(uploadedPages.length - 1), () => []),
      );
    }
    doc.removePage(pNum);
    uploadedPages.forEach((page, i) => doc.insertPage(i + pNum, page));

    const destIndex = destNamesArray?.findIndex(
      (obj) =>
        obj instanceof PDFString && obj.asString() === `card-${attachment.key}`,
    );
    if (pdfDestNames && typeof destIndex === 'number' && destIndex >= 0) {
      // update destination:
      const dest = pdfDestNames.lookup(destIndex + 1, PDFArray);
      dest.set(0, uploadedPages[0].ref);
      // remove X, Y coordinates so that it just counts as a link to the page:
      dest.set(2, PDFNull);
      dest.set(3, PDFNull);
    }
  }
  return new Blob([await doc.save()]);
};
