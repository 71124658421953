import React, { Suspense } from 'react';
import type { TimeMapEntry } from 'react-verovio';
import { loadVerovio } from './Verovio';
import { useMidiVerovioOrPassthru } from './useMidiVerovioOrPassthru';
import { MidiVerovioProps } from './MidiVerovioProps';

export type MidiData = [string, TimeMapEntry[]];

const LazyMidiVerovio = React.lazy(async () => {
  const { useVerovio } = await loadVerovio();
  return { default: makeMidiVerovio(useVerovio) }
});

const makeMidiVerovio: (useVerovio: typeof import('react-verovio').useVerovio) => React.FC<MidiVerovioProps> = (useVerovio) => (props) => {
  const verovio = useVerovio();
  useMidiVerovioOrPassthru({ ...props, verovio });
  return null;
};

export const MidiVerovio: React.FC<MidiVerovioProps> = (props) =>
  <Suspense fallback={null}>
    <LazyMidiVerovio {...props} />
  </Suspense>;
