import { css, cx } from '@emotion/css';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { Button } from './Button';

interface CallToActionProps {
  behavior: ExclusiveUnion<{
    onClick: React.MouseEventHandler<HTMLButtonElement>,
  } | {
    href: string,
  } | {
    type: 'submit',
  }>;
  disabled?: boolean,
  style?: CSSProperties,
  className?: string,
  'data-ga-code'?: string,
}

export const CallToAction : React.FC<PropsWithChildren<CallToActionProps>> = ({
  behavior,
  disabled = false,
  children,
  style,
  className,
  'data-ga-code': gaCode,
}) => {
  return <Button
    className={cx(styleCss, 'btn btn-primary d-flex w-100 h-50px rounded-8', className)}
    onClick={behavior.onClick}
    disabled={disabled}
    href={behavior.href}
    type={behavior.type ?? 'button'}
    style={style}
    data-ga-code={gaCode}
  >
    {children}
  </Button>;
};

const styleCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
