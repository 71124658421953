/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import type { Estimate } from 'chargebee-typescript/lib/resources';
import { AddOnId, PlanId } from 'utils/api-types/plans';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';

export type ChargebeeEstimates = {
  estimate?: Estimate | null;
  estimateWithoutCustomer?: Estimate | null;
}
export type HostedPageContent = {
  subscription: {
    id: string;
    planId: PlanId;
    addonId?: AddOnId;
    currentTermStart?: string;
    currentTermEnd?: string;
    nextBillingAt?: string;
  }
  customer?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  }
  invoice?: {
    id: string;
    downloadUrl?: string;
  }
};
export type HostedPage = {
  id: string | null;
  type: string | null;
  url: string | null;
  state: string | null;
  pass_thru_content: string | null;
  embed: boolean;
  created_at: number | null;
  expires_at: number | null;
  content: HostedPageContent | null;
  updated_at: number | null;
  resource_version: number | null;
  checkout_info: any | null;
};

export const HOSTED_PAGE_CONTENT_FRAGMENT = gql`
  fragment HostedPageContent on HostedPageContent {
    subscription {
      id
      planId
      addonId
      currentTermStart
      currentTermEnd
      nextBillingAt
    }
    customer {
      firstName
      lastName
      email
    }
    invoice {
      id
      downloadUrl
    }
  }
`;
export const HOSTED_PAGE_FRAGMENT = gql `
  fragment HostedPage on HostedPage {
    id
    type
    url
    state
    pass_thru_content
    embed
    created_at
    expires_at
    content {
      ...HostedPageContent
    }
    updated_at
    resource_version
    checkout_info
  }
  ${HOSTED_PAGE_CONTENT_FRAGMENT}
`
export const ESTIMATE_FRAGMENT = gql `
  fragment Estimate on Estimate {
    estimate
    estimateWithoutCustomer
  }
`;

export interface GetHostedPageForPaymentMethod extends GQLQuery {
  Variables: {
    groupId?: string;
  };
  Data: { hostedPage: HostedPage };
}
export const GetHostedPageForPaymentMethod: GQLDocument<GetHostedPageForPaymentMethod> = {
  query: gql`
    query getHostedPageForPaymentMethod(
      $groupId: String
    ) {
      hostedPage: getHostedPageForPaymentMethod(
        groupId: $groupId
      ) {
        ...HostedPage
      }
    }
    ${HOSTED_PAGE_FRAGMENT}
  `
}

interface HostedPageForPlanVariables {
  planId?: string;
  quantity?: number;
  addonId?: string;
  addonQuantity?: number;
  couponCode?: string;
  startDate?: Date;
  isRenewal?: boolean;
  referrerId?: string;
}
export interface GetHostedPageForPlan extends GQLQuery {
  Variables: HostedPageForPlanVariables;
  Data: { hostedPage: HostedPage };
}
export const GetHostedPageForPlan: GQLDocument<GetHostedPageForPlan> = {
  query: gql`
    query getHostedPageForPlan(
      $planId: String,
      $quantity: Float,
      $addonId: String,
      $addonQuantity: Float,
      $couponCode: String,
      $startDate: DateTime,
      $isRenewal: Boolean,
      $referrerId: String
    ) {
      hostedPage: getHostedPageForPlan(
        planId: $planId,
        quantity: $quantity,
        addonId: $addonId,
        addonQuantity: $addonQuantity,
        couponCode: $couponCode,
        startDate: $startDate,
        isRenewal: $isRenewal,
        referrerId: $referrerId
      ) {
        ...HostedPage
      }
    }
    ${HOSTED_PAGE_FRAGMENT}
  `
}
export interface GetEstimateForPlan extends GQLQuery {
  Variables: HostedPageForPlanVariables;
  Data: { estimate: ChargebeeEstimates };
}
export const GetEstimateForPlan: GQLDocument<GetEstimateForPlan> = {
  query: gql`
    query getEstimateForPlan(
      $planId: String,
      $quantity: Float,
      $addonId: String,
      $addonQuantity: Float,
      $couponCode: String,
      $startDate: DateTime,
      $isRenewal: Boolean,
      $referrerId: String
    ) {
      estimate: getEstimateForPlan(
        planId: $planId,
        quantity: $quantity,
        addonId: $addonId,
        addonQuantity: $addonQuantity,
        couponCode: $couponCode,
        startDate: $startDate,
        isRenewal: $isRenewal,
        referrerId: $referrerId
      ) {
        ...Estimate
      }
    }
    ${ESTIMATE_FRAGMENT}
  `
}

export interface GrantAccessForHostedPage extends GQLMutation<GrantAccessForHostedPage> {
  Variables: {
    hostedPageId: string;
    referrerId?: string;
  };
  Data: { hostedPage: HostedPage };
}
export const GrantAccessForHostedPage: GQLDocument<GrantAccessForHostedPage> = {
  mutation: gql`
    mutation grantAccessForHostedPage($hostedPageId: String!, $referrerId: String) {
      hostedPage: grantAccessForHostedPage(hostedPageId: $hostedPageId, referrerId: $referrerId) {
        ...HostedPage
      }
    }
    ${HOSTED_PAGE_FRAGMENT} `
}

export interface GetHostedPageContent extends GQLQuery {
  Variables: {
    id: string;
  };
  Data: { content: HostedPageContent };
}
export const GetHostedPageContent: GQLDocument<GetHostedPageContent> = {
  query: gql`
    query getHostedPageContent($id: String!) {
      content: getHostedPageContent(id: $id) {
        ...HostedPageContent
      }
    }
    ${HOSTED_PAGE_CONTENT_FRAGMENT} `,
}

export interface AddSubscriptionFromHostedPage extends GQLMutation<GrantAccessForHostedPage> {
  Variables: { hostedPageId: string };
  Data: { hostedPage: HostedPage };
}
export const AddSubscriptionFromHostedPage: GQLDocument<AddSubscriptionFromHostedPage> = {
  mutation: gql`
    mutation addSubscriptionFromHostedPage($hostedPageId: String!) {
      hostedPage: addSubscriptionFromHostedPage(hostedPageId: $hostedPageId) {
        ...HostedPage
      }
    }
    ${HOSTED_PAGE_FRAGMENT} `
}
