import React from 'react';
import { loadExsurge } from 'components/shared/LazyExsurge';
import { loadVerovio } from 'components/verovio/Verovio';
import { load as loadOrdoView } from 'components/ordo/LazyOrdoView';
import { loadPdfKit, loadPdfLib } from 'utils/export/pdfTools';
import { paths } from 'utils/paths';
import { isInIframe } from 'utils/isInIframe';
export const ReactLazyWithPreload: <P = unknown>(
  factory: () => Promise<{
    default: React.ComponentType<P>;
  }>,
) => {
  Component: React.LazyExoticComponent<React.ComponentType<P>>;
  preload: () => Promise<{ default: React.ComponentType<P> }>;
} = (factory) => ({ Component: React.lazy(factory), preload: factory });

const LazyFourOhFour = ReactLazyWithPreload(() => import('./FourOhFour'));
export const FourOhFour = LazyFourOhFour.Component;
const LazyNetworkError = ReactLazyWithPreload(() => import('./NetworkError'));
export const NetworkError = LazyNetworkError.Component;
const LazyError = ReactLazyWithPreload(() => import('./Error'));
export const Error = LazyError.Component;
const LazyDashboard = ReactLazyWithPreload(() => import('./Dashboard'));
export const Dashboard = LazyDashboard.Component;
const LazyWelcomeBack = ReactLazyWithPreload(() => import('./WelcomeBack'));
export const WelcomeBack = LazyWelcomeBack.Component;
const LazyPricing = ReactLazyWithPreload(() => import('./Pricing'));
export const Pricing = LazyPricing.Component;
const LazyCheckout = ReactLazyWithPreload(() => import('./Checkout'));
export const Checkout = LazyCheckout.Component;
const LazyPostPurchase = ReactLazyWithPreload(() => import('./PostPurchase'));
export const PostPurchase = LazyPostPurchase.Component;
const LazyPurchaseConfirmation = ReactLazyWithPreload(() => import('./PurchaseConfirmation'));
export const PurchaseConfirmation = LazyPurchaseConfirmation.Component;
const LazySignUp = ReactLazyWithPreload(() => import('./SignUp'));
export const SignUp = LazySignUp.Component;
const LazyLogIn = ReactLazyWithPreload(() => import('./LogIn'));
export const LogIn = LazyLogIn.Component;
const LazyAccountManagement = ReactLazyWithPreload(() => import('./AccountManagement'));
export const AccountManagement = LazyAccountManagement.Component;
const LazyAssociatePlaces = ReactLazyWithPreload(() => import('./AssociatePlaces'));
export const AssociatePlaces = LazyAssociatePlaces.Component;
const LazySentLinks = ReactLazyWithPreload(() => import('./SentLinks'));
export const SentLinks = LazySentLinks.Component;
const LazyAppWithoutCallback = ReactLazyWithPreload(() => import('../AppWithoutCallback'));
export const AppWithoutCallback = LazyAppWithoutCallback.Component;
const LazyTemplateListing = ReactLazyWithPreload(() => import('./TemplateListing'));
export const TemplateListing = LazyTemplateListing.Component;
const LazyReferrerDashboard = ReactLazyWithPreload(() => import('./ReferrerDashboard'));
export const ReferrerDashboard = LazyReferrerDashboard.Component;
const LazyResetPassword = ReactLazyWithPreload(() => import('./ResetPassword'));
export const ResetPassword = LazyResetPassword.Component;
const LazySampleMissal = ReactLazyWithPreload(() => import('./SampleMissal'));
export const SampleMissal = LazySampleMissal.Component;
const LazyShare = ReactLazyWithPreload(() => import('./Share'));
export const Share = LazyShare.Component;
const LazyLayout = ReactLazyWithPreload(() => import('./Layout'));
export const Layout = LazyLayout.Component;

// if the user is not on the new select plan page, preload specific additional pages:
if (window?.requestIdleCallback && !(window.location.pathname === paths.pricing && isInIframe)) {
  window.requestIdleCallback(() => {
    setTimeout(async () => {
      await LazyDashboard.preload();
      await loadOrdoView();
      await loadExsurge();
      await loadVerovio();
      await loadPdfKit();
      await loadPdfLib();
    }, 10000);
  });
}
