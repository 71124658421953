const hostname = window.location.hostname;

export const isLocalhost = Boolean(
  // necessary if you want to test using your
  // local network address
  process.env.REACT_APP_IS_LOCALHOST === 'true' || 

  hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(hostname)
);

const isStaging = (/staging/).test(hostname);

export const isProduction = !isStaging && !isLocalhost;

export const sentryDSN = isLocalhost
  ? undefined 
  : isStaging ? 'https://c054ddf18bd14c01bff0ca443aea5785@o468716.ingest.sentry.io/5580415'
  : 'https://bcef380174314c1c9ad05213a8d3ea20@o468716.ingest.sentry.io/5496985';

const isLocalRequest = hostname === 'app_local';

export const apiOrigin: string = isLocalRequest ? `http://api:3000`
  : isLocalhost && process.env.REACT_APP_API_DOMAIN
  ? process.env.REACT_APP_API_DOMAIN
  : `https://${hostname.replace(/^app\./, 'api.')}`;

export const webSocketGateway: string = apiOrigin;

export const iamOrigin: string = isLocalRequest ? `http://iam:8080`
  : isLocalhost && process.env.REACT_APP_IAM_DOMAIN
  ? process.env.REACT_APP_IAM_DOMAIN
  : `https://${hostname.replace(/^app\./, 'iam.')}`;

export const calendarOrigin: string = isLocalRequest ? `http://calendar`
  : isLocalhost && process.env.REACT_APP_LITURGICAL_CALENDAR_API_DOMAIN
  ? process.env.REACT_APP_LITURGICAL_CALENDAR_API_DOMAIN
  : `https://${hostname.replace(/^app\./, 'calendar.')}`;

export const chargebeeSite: string = window.location.hostname === 'app.sourceandsummit.com'
  ? 'sourceandsummit'
  : 'sourceandsummit-test';
