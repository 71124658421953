import { useCallback } from 'react';
import { saveAs } from 'utils/export/saveAs';
import JSZip from 'jszip';
import { ExportFile } from 'utils/export'

export const useExportOrdo = () => useCallback(<T extends Blob | Uint8Array>(files: ExportFile<T>[], archiveName: string) => {
  if (files.length === 1) {
    const file = files[0];

    if (file.content instanceof Uint8Array) {
      saveAs(new Blob([file.content]), file.name);
      return;
    }

    saveAs(file.content, file.name);

    return;
  }

  const zip = new JSZip();

  files.forEach((file) => {
    zip.file(file.name, file.content, {binary: true});
  });

  zip.generateAsync({type: 'blob'})
    .then((content: Blob) => {
      saveAs(content, `${archiveName}.zip`);
    });
}, []);
