import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { TranslationOnText } from 'queries/text';
import { TextOption } from 'queries/text-option';
import { Text } from 'queries/text';
import { Language } from 'utils/api-types/Language';

export const getActiveSelections = (textOptions: TextOption[] | null, config: OrdoCardConfig, defaultLanguage: Language):
[TranslationOnText | undefined, Text | undefined, TextOption | undefined] => {
  const onlyOption = textOptions?.length === 1 && textOptions[0];
  const option = onlyOption || textOptions?.find((o) => o.id === config?.optionId);
  const texts = option?.texts;
  const onlyText = (texts?.length === 1 && texts[0]) || texts?.find(({ name }) => name === 'Nicene Creed');
  const text = texts?.find((t) => t.id === config?.textId) ?? onlyText;
  const translation = text?.officialTranslations.find((t) => t.language === (config.language ?? defaultLanguage));
  return [translation, text, option];
};
