import { createTraverser } from 'utils/functions/card/createTraverser'
import { produce } from 'immer';
import { ReadingDisplay, ReadingTypeOrBoth, ReadingTypes } from 'utils/api-types/ordo/card/ReadingTypes';
import { SaveOrUpdateCardConfigCallback } from 'hooks/ordo/cards/UpdateCardConfigCallback';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { OrdinaryConfigInput, ReadingConfigInput } from '../../ordo/card/CardConfigInputTypes';
import { TextOption } from 'queries/text-option';
import { ReactNode } from 'react';
import { getNumberOfReadersLabel } from './getNumberOfReadersLabel';
import { determineReadingForm } from '../determineReadingForm';

// this can be added to a component's props to make them able to consume an option list
export type OptionListProps<V = string, L extends ReactNode = ReactNode> = {
  options: {
    label: L;
    value: V;
  }[];
  value: V;
  onChange: (value: V) => void;
};

// This interface can be used by various components across the UI that display a list of options to the user.
export type OptionList<V = string, L extends ReactNode = ReactNode> = OptionListProps<V, L>;

// function for creating an OptionList from a TextOption data object.
// if an ICardContext and a textOption or list of textOptions are in scope, this function can be called like so:
// createOptionListFromTextOption({ textOption, ...cardContext });
export const createOptionListFromTextOption = ({textOption, config, updateConfig, defaultLanguage}: {
  textOption: TextOption | TextOption[],
  config: OrdoCardConfig,
  updateConfig: SaveOrUpdateCardConfigCallback,
  defaultLanguage: string
}): OptionList => {
  // traverser provides quick access to the nested properties on a TextOption
  const configTraverser = createTraverser(config);
  
  // if we received an array of text options, we want to select the one designated by the config
  if (Array.isArray(textOption)) {
    textOption = configTraverser?.fromTextOptionsArray(textOption)?.selectTextOption() || textOption[0];
  }
  const texts = textOption?.texts ?? [];
  // convert the texts on this textOption (which are the actual options we want in this case) to our Option interface defined above
  const options: OptionList['options'] = texts.map(text => ({ label: text.name, value: text.id.toString() }));
  
  // currently selected textId, according to the config
  const selectedId = config.textId?.toString() || '';
  
  // handler to change textId and translationId when a new text is selected
  const handler = (value: string) => {
    if (value && (+value !== config.textId)) {
      updateConfig(produce(config, (draftConfig: OrdinaryConfigInput) => {
        // convert id to a number and set the new textId
        draftConfig.textId = +value;
        // if a resource group is selected, that needs to be reset
        if (config.resourceGroupId) {
          draftConfig.resourceGroupId = null;
        }
      }));
    }
  }

  return { options, value: selectedId, onChange: handler };
}

// function for creating an OptionList from ReadingTypes options (currently, LONG or SHORT).
// if an ICardContext (and a currently-selected reading form) is in scope, this function can be called like so:
// createOptionListFromTextOption({ selectedForm, ...cardContext });
export const createOptionListFromReadingForms = (
  updateReadingForm: (readingForm: ReadingTypes) => void,
  selectedForm?: ReadingTypeOrBoth | null,
): OptionList<string, string> => {

  // convert the enum values to our Option interface as defined above
  const options: OptionList<string, string>['options'] = Object.values(ReadingTypes).map(
    (value: ReadingTypes) => ({ label: ReadingDisplay[value], value })
  );
  
  // reading form defaults to long if the config isn't set, so this should display that as well
  const selectedId = determineReadingForm(selectedForm);
  
  // handler to update reading form in the config when a new one is selected
  const handler = (value: string) => {
    if (value) {
      updateReadingForm(value as ReadingTypes);
    }
  }
  return { options, value: selectedId, onChange: handler };
}

export const createUpdateReadingFormCallback = (
  config: OrdoCardConfig,
  updateConfig: SaveOrUpdateCardConfigCallback
) => (readingForm: ReadingTypes) => {
  updateConfig(produce(config, (draftConfig: ReadingConfigInput | OrdinaryConfigInput) => {
    draftConfig.form = readingForm;
  }));
}

// function for creating an OptionList from Reader count options.
export const createOptionListFromReaderCounts = (
  updateReaderCount: (readerCount: number) => void,
  readerCount?: number|null,
): OptionList<number, string> => {

  // convert the enum values to our Option interface as defined above
  const options: OptionList<number, string>['options'] = [1,3,4].map(
    (value: number) => ({ label: getNumberOfReadersLabel(value), value })
  );
  
  // reading form defaults to long if the config isn't set, so this should display that as well
  const selectedId = readerCount ?? 1;
  
  // handler to update reading form in the config when a new one is selected
  const handler = (value: number) => {
    if (value) {
      updateReaderCount(value);
    }
  }
  return { options, value: selectedId, onChange: handler };
}

export const createUpdateReaderCountCallback = (
  config: OrdoCardConfig,
  updateConfig: SaveOrUpdateCardConfigCallback
) => (readerCount: number) => {
  updateConfig(produce(config, (draftConfig: ReadingConfigInput) => {
    draftConfig.numberOfReaders = readerCount;
  }));
}
