import { VerseText, GabcPsalmTone } from 'gabc-utils';
import { Language } from 'gabc-utils';
import { MelodicComposition } from 'queries/composition';
import { Text, TranslationOnText } from 'queries/text';
import { removeSolesmesMarkings as removeSolesmes } from 'gabc-utils';
import replaceTildeWithUndertie from 'utils/markup/replaceTildeWithUndertie';
import { replaceLORDWithSmallCaps } from 'utils/markup/replaceLORDWithSmallCaps';
export interface CompositionProcessorArgs {
  text?: Text|null;
  composition?: MelodicComposition|null;
  language?: string;
  clef?: string;
  processGabc?: boolean;
  isEasterTime?: boolean;
  removeSolesmesMarkings?: boolean;
};

export const processComposition = ({
  text,
  composition,
  language,
  clef,
  processGabc = true,
  isEasterTime = false,
  removeSolesmesMarkings = false,
}: CompositionProcessorArgs) => {

  let music = composition?.melody.voice.data;
  let translation: TranslationOnText | null | undefined;
  let lang: Language | undefined = language as Language;
  let gabc;
  let psalmTone;
  let error: any | undefined = undefined;
  if (removeSolesmesMarkings) {
    music = removeSolesmes(music);    
  }

  if (composition?.isFormulaic && music) {
    psalmTone = GabcPsalmTone.getFromGabc(
      music,
      {
        treatAsOneAccentWithXPreparatory: true,
      },
      clef
    );

    if (text) {
      translation = composition.translation || text.officialTranslations.find((t) => t.language === language);
      if (translation && processGabc) {
        try {
          let text = translation.content;
          if (translation.language === 'es') {
            text = replaceTildeWithUndertie(text);
          }
          const vt = new VerseText({
            text,
            language: translation.language,
            isEaster: isEasterTime,
          });

          gabc = replaceLORDWithSmallCaps(
            vt.withGabc(psalmTone, {
              startVersesOnNewLine: false,
              addSequentialVerseNumbersStartingAt: 0,
              stripFlexMediantSymbols: true,
              removeSolesmesMarkings,
            })
          );
        } catch (exception) {
          console.warn(exception, translation.content);
          error = exception;
        }
      }
    }
  } else if (text && composition) {
    translation = composition.translation;
    lang = translation?.language;
  }

  return {
    translation,
    language: lang,
    music,
    gabc,
    psalmTone,
    error
  }
}
