import React, { Fragment, useMemo } from 'react';

import { FormattedVerseSegment } from './FormattedVerseSegment';
import {
  GabcPsalmTones,
  VerseText,
  GabcPsalmTone,
  VerseSegment,
} from 'gabc-utils';
import { MusicalErrorAlert } from './MusicalErrorAlert';
import { Language } from 'gabc-utils';
import { css, cx } from '@emotion/css';
import replaceTildeWithUndertie from 'utils/markup/replaceTildeWithUndertie';
import { useLogException } from 'hooks/error/useLogError';

interface PointedVerseProps {
  verses: string[];
  psalmTone: GabcPsalmTones | string;
  verseClassName?: string;
  verseColumns?: number | null;
  isGloryBe?: boolean;
  language: Language;
  aboveVerses?: React.ReactNode[];
  /**
   * should every syllable be rendered in its own clickable span
   */
  individualSyllables?: boolean;
  firstVerseNum?: number;
}

export const PointedVerse: React.FC<PointedVerseProps> = ({
  verses,
  psalmTone,
  verseClassName,
  verseColumns,
  isGloryBe,
  language,
  aboveVerses = [],
  individualSyllables = true,
  firstVerseNum = 1,
}) => {
  const logException = useLogException();
  const { gpt, hasMusicError } = useMemo(() => {
    let gpt: GabcPsalmTones | null = null;
    let hasMusicError = false;
    try {
      gpt =
        typeof psalmTone === 'string'
          ? GabcPsalmTone.getFromGabc(psalmTone, {
              treatAsOneAccentWithXPreparatory: true,
            })
          : psalmTone;
    } catch (error: unknown) {
      logException({error, message: 'Musical Exception', errorInfo: { psalmTone }});
      hasMusicError = true;
    }
    return { gpt, hasMusicError };
  }, [logException, psalmTone]);
  const lineBreakAtMediant = (verseColumns ?? 1) <= 1;
  const VerseContainer = lineBreakAtMediant ? gpt?.isMeinrad ? 'div' : 'ul' : Fragment;
  const SegmentContainer = lineBreakAtMediant ? gpt?.isMeinrad ? 'div' : 'li' : Fragment;

  const { verseTexts, hasTextError } = useMemo(() => {
    if (hasMusicError) return { verseTexts: [], hasTextError: false };
    let hasTextError = false;
    return {
      verseTexts:
        verses.map((text) => {
          if (gpt?.isMeinrad) {
            text = text.replace(/\s+[†*]/g, '');
          }
          if (language === 'es') {
            text = replaceTildeWithUndertie(text);
          }
          const vt = new VerseText({ text, language });
          vt.segments.forEach((segment) => {
            if (!hasTextError && (segment.accentedSyllables?.length ?? 0) === 0) {
              logException({ message: 'Musical Exception', errorInfo: { message: 'No accented syllables in verse segment', text } });
              hasTextError = true;
            }
          });
          return vt;
        }),
        hasTextError,
      };
  }, [gpt?.isMeinrad, language, verses, hasMusicError, logException]);
  const hasError = hasMusicError || hasTextError;

  const pointedVerse = (
    <>
      {verseTexts.map((vt, verseI) => {
        const stanzas: VerseSegment[][] = [];
        vt.segments.forEach((segment, index) => {
          if (
            index === 0 ||
            (
              !gpt?.isMeinrad
              && vt.segments[index - 1].segmentType === 'termination'
            )
          ) {
            stanzas.push([]);
          }
          stanzas[stanzas.length - 1].push(segment);
        });
        const psalmToneLines: GabcPsalmTone[] | null | undefined =
          gpt?.isMeinrad
            ? (gpt.lines?.[vt.segments.length] as GabcPsalmTone[])
            : null;

        const extraOption: { startingSyllableIndex?: number } = {};
        if (individualSyllables) {
          extraOption.startingSyllableIndex = 0;
        }
        // necessary for export
        return (
          <React.Fragment key={verseI}>
            {aboveVerses[verseI]}
            <ol
              className={cx('liturgical-text exsurge-verse', { "glory-be": isGloryBe }, verseClassName, style)}
              start={verseI + firstVerseNum}
            >
              <li
                className={cx('exsurge-text indent-hanging', { "glory-be": isGloryBe })}
              >
                {stanzas.map((segmentsOfStanza, i, stanzas) => {
                  const firstSegment = segmentsOfStanza[0];
                  if (verseI) {
                    delete extraOption.startingSyllableIndex;
                  }
                  return <div key={i} className={cx('pointed-verse-stanza no-page-break-inside',
                    { 'no-page-break-after allow-column-break-after': i < stanzas.length - 1 })}>
                    <FormattedVerseSegment
                      key={i}
                      segment={firstSegment}
                      psalmTone={gpt!}
                      psalmToneSegment={psalmToneLines?.[0]}
                      forceBreakWhenNotTermination={lineBreakAtMediant}
                      {...extraOption}
                    />
                    <VerseContainer>
                      {segmentsOfStanza.slice(1).map((segment, i) => {
                        if (i === 0 && typeof extraOption.startingSyllableIndex === 'number') {
                          extraOption.startingSyllableIndex += firstSegment.syllables.length;
                        }
                        const result = <Fragment key={i}>
                          {' '}
                          <SegmentContainer>
                            <FormattedVerseSegment
                              segment={segment}
                              psalmTone={gpt!}
                              psalmToneSegment={psalmToneLines?.[1 + i]}
                              forceBreakWhenNotTermination={lineBreakAtMediant}
                              {...extraOption}
                            ></FormattedVerseSegment>
                          </SegmentContainer>
                        </Fragment>;
                        if (typeof extraOption.startingSyllableIndex === "number") {
                          extraOption.startingSyllableIndex += segment.syllables.length;
                        }
                        return result;
                      })}
                    </VerseContainer>
                  </div>;
                })}
              </li>
            </ol>
          </React.Fragment>
        );
      })}
    </>
  );
  return hasError ? <MusicalErrorAlert /> : pointedVerse;
};

const style = css`
  & + & {
    margin-top: calc(15px + var(--card-vertical-spacing, 0px))
  }
  
  margin-bottom: 0;

  .pointed-verse-stanza + .pointed-verse-stanza {
    margin-top: calc(8px + var(--card-vertical-spacing, 0px))
  }
`;
