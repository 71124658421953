import { Error, Info, Success, Warn } from 'components/toasts';
import { toast as toastify, Zoom } from 'react-toastify';

const typeComponentMap = {
  'success': Success,
  'error': Error,
  'warn': Warn,
  'info': Info,
}

export const toast = (type: 'success'|'error'|'warn'|'info', message: string)  => {
  if (type !== 'success') {
    console[type](message);
  }
  return toastify[type](typeComponentMap[type]({ message }), { transition: Zoom });
}
