import { combinedAttributionsKey, outlineKey } from 'components/ordo/modals/export/ExportRenderer';
import { Ordo, OrdoShare } from 'queries/ordo';
import { Dictionary } from 'utils/typescript/Dictionary';
import { getExportFilename } from './getExportFilename';
import { OrdoCardWithMeiOrSvg } from 'queries/card-fragment';

export const getExportFilenames = ({
  ordo,
  crop,
  includeOutline,
  addGrandStaff,
}: {
  ordo: Ordo | OrdoShare;
  crop: boolean;
  includeOutline: boolean;
  addGrandStaff: boolean;
}): Dictionary<string> => {
  const cards = (ordo?.cards as OrdoCardWithMeiOrSvg[])
    .filter((card) => card.enabled)
    .sort((a, b) => a.sortPosition - b.sortPosition);
  const extraCardsAtBeginning = includeOutline ? 1 : 0;
  const extraCardsAtEnd = 1;
  const extraCardCount = extraCardsAtBeginning + extraCardsAtEnd;
  const padding = '0'.repeat(`${cards.length + extraCardCount}`.length);
  const fileNames = Object.fromEntries(
    cards.map((card, i) => [
      card.id,
      getExportFilename(
        ordo,
        crop,
        addGrandStaff,
        card.id,
        `${padding}${i + 1 + extraCardsAtBeginning}`.slice(-padding.length),
      ),
    ]),
  );
  fileNames[combinedAttributionsKey] = getExportFilename(
    ordo,
    crop,
    addGrandStaff,
    'copyright',
    `${padding}${cards.length + 1 + extraCardsAtBeginning}`.slice(-padding.length),
  );
  if (includeOutline) {
    fileNames[outlineKey] = getExportFilename(
      ordo,
      crop,
      addGrandStaff,
      'outline',
      `${padding}1`.slice(-padding.length),
    )
  }
  return fileNames;
};
