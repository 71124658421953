import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { RubricText } from './RubricText';
import { useContextualRubricTextOptions } from 'hooks/ordo/cards/useContextualRubricTextOptions';
import { TopLevelOrdoContext } from 'context/TopLevelOrdoContext';
import { doTextOptionsHaveRubrics } from 'utils/functions/card';
import { CardContext } from 'context/CardContext';

export const ContextualRubricWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  const { textOptions, card: { isConfigured, config: { optionId } } } = useContext(CardContext);
  
  const { setHasRubrics } = useContext(TopLevelOrdoContext);
  const hasRubrics = doTextOptionsHaveRubrics(textOptions);
  const tOptions = optionId ? textOptions?.filter(opt => opt.id === optionId) : textOptions;
  const {rubricHeader, rubricFooter} = useContextualRubricTextOptions(tOptions, isConfigured);

  useEffect(() => {
    if (hasRubrics) {
      // we don't want to say the ordo has
      // no rubrics simply because we don't
      // have rubrics
      setHasRubrics(true);
    }
  }, [hasRubrics, setHasRubrics]);

  return <>
    {rubricHeader !== '' && <RubricText
      className='mt-3 no-page-break-after'
      text={rubricHeader}
    />}
    {children}
    {rubricFooter !== '' && <RubricText
      className='mt-3 no-page-break-before'
      text={rubricFooter}
    />}
  </>;
};
