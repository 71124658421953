import { useToast } from "hooks/useToast"
import { useEffect } from "react";

export const useErrorToastEffect = (messageCondition?: string | false | null | undefined | 0) => {
  if (!messageCondition) { // coerce falsy to actual false value for effect check
    messageCondition = false;
  }
  const errorToast = useToast('error');

  useEffect(() => {
    if (messageCondition) {
      errorToast(messageCondition);
    }
  }, [messageCondition, errorToast])
}
