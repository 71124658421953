import { useApolloClient } from '@apollo/client';
import { useQuery } from 'hooks/queries/useQuery';
import { GetPermissionSet } from 'queries/permission-set';
import { useEffect } from 'react';
import { GatedFeature } from './useFeatureGate';

export const usePermissionSet = (seedId?: GatedFeature) => {
  const permissionSet = useQuery(
    GetPermissionSet,
    seedId ? {
      variables: { seedId },
      fetchPolicy: 'cache-first',
    } : {
      skip: true,
    }
  ).data?.permissionSet;

  const cached = permissionSet?.cached;
  const client = useApolloClient();
  useEffect(() => {
    if (cached && seedId) {
      client.query({ query: GetPermissionSet.query, fetchPolicy: 'network-only', variables: { seedId } });
    }
  }, [cached, client, seedId]);

  return permissionSet;
}
