import bravuraUrl from 'assets/fonts/Bravura.otf';
import ebGaramond from 'assets/fonts/EB Garamond-400.ttf';
import ebGaramondItalic from 'assets/fonts/EB Garamond-400italic.ttf';
import ebGaramondMedium from 'assets/fonts/EB Garamond-500.ttf';
import ebGaramondSemibold from 'assets/fonts/EB Garamond-600.ttf';
import ebGaramondSemiboldItalic from 'assets/fonts/EB Garamond-600italic.ttf';
import verovioTextUrl from 'assets/fonts/VerovioText.woff';
import versiculumUrl from 'assets/fonts/versiculum.ttf';
import { ExceptionLogger, LogExceptionArgument } from 'hooks/error/useLogError';
import type { } from 'pdfkit';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { getContentWidthPixels } from 'utils/getContentWidth';
import { sum } from 'utils/sum';
import { Dictionary } from 'utils/typescript/Dictionary';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { Override } from 'utils/typescript/Override';
import { PdfBlock } from './PdfBlockTypes';
import {
  PDFDocumentWithColumns,
  drawFormattedText,
  isBlockBreakableAtPage,
  isPdfTextStyleBlock,
} from './PdfFormattedText';
import { PDFKitDimensionSettings, defaultDimensionsForPDFKit, toPt } from './exportDimensionSettings';
import {
  PageNumberInfo,
  addPage,
  advanceColumn,
  endColumn,
  measureBlockHeight,
  measureColumnBlocks,
} from './pdfColumnTools';
import { paginateFromEnd } from './pdfPaginate';
import { uploadErrorFile } from './uploadErrorFile';

export type FontPromises = Dictionary<Promise<ArrayBuffer>>;

export const loadPdfKit = () => import('pdfkit/js/pdfkit.standalone').then(pdfkit => pdfkit.default);
export const loadPdfLib = () => import('@cantoo/pdf-lib');

interface PdfOptions {
  verticalAlign?: 'top' | 'middle' | 'bottom';
  singlePage?: boolean;
  fontPromises?: FontPromises;
  logException?: (arg: LogExceptionArgument) => ReturnType<ExceptionLogger>;
  contentWidth?: number;
  breakEveryCard?: boolean;
}
interface SvgToPdfOptions {
  /**
   * If true, assume that the svg is using points instead of pixels.
   */
  assumePt?: boolean;

  /**
   * viewport width in points, defaults to width of page
   */
  width?: number;

  /**
   * viewport height in points, defaults to height of page
   */
  height?: number;

  preserveAspectRatio?:
    | 'none'
    | 'xMinYMin'
    | 'xMinYMid'
    | 'xMinYMax'
    | 'xMidYMin'
    | 'xMidYMid'
    | 'xMidYMax'
    | 'xMaxYMin'
    | 'xMaxYMid'
    | 'xMaxYMax'
    | 'xMinYMin meet'
    | 'xMinYMid meet'
    | 'xMinYMax meet'
    | 'xMidYMin meet'
    | 'xMidYMid meet'
    | 'xMidYMax meet'
    | 'xMaxYMin meet'
    | 'xMaxYMid meet'
    | 'xMaxYMax meet'
    | 'xMinYMin slice'
    | 'xMinYMid slice'
    | 'xMinYMax slice'
    | 'xMidYMin slice'
    | 'xMidYMid slice'
    | 'xMidYMax slice'
    | 'xMaxYMin slice'
    | 'xMaxYMid slice'
    | 'xMaxYMax slice';

  useCSS?: boolean;

  precision?: number;
}

const pdfToBlob = (doc: PDFKit.PDFDocument): Promise<Blob> =>
  new Promise((resolve, reject) => {
    const buffers: Buffer[] = [];
    doc.on('data', buffers.push.bind(buffers));
    doc.on('end', () => resolve(new Blob(buffers, { type: 'application/pdf' })));
    doc.on('error', reject);
    doc.end();
  });

const defaultOptions = {
  layout: 'portrait',
  ...defaultDimensionsForPDFKit,
  autoFirstPage: false,
} as const satisfies Override<
  PDFKit.PDFDocumentOptions,
  {
    layout: PDFKit.PDFDocumentOptions['layout'];
  } & PDFKitDimensionSettings
> & {
  font?: string;
};

const defaultFont = 'EB Garamond';

const loadSvgToPdf = async () => (await import('utils/SVGtoPDF')).SVGtoPDF;
const SVGtoPDF = (
  doc: PDFKit.PDFDocument,
  svg: SVGSVGElement,
  x: number,
  y: number,
  options: SvgToPdfOptions,
) => loadSvgToPdf().then((svgToPdf) => svgToPdf(doc, svg, x, y, options));

export const makePdfDocument = async (
  { font, ...options }: PDFKit.PDFDocumentOptions = defaultOptions,
  { fontPromises = {} }: { fontPromises?: FontPromises } = {},
) => {
  const PDFDocument = await loadPdfKit();
  const pdf = new PDFDocument(options) as PDFDocumentWithColumns;
  // register fonts
  const fetchOptions: RequestInit = { cache: 'force-cache' };
  const fonts = {
    Versiculum: versiculumUrl,
    Bravura: bravuraUrl,
    // verovio text is only used for undertie character in FormattedVerseSegment.  Perhaps we can use the undertie from Bravura instead? since this is what the latest version of Verovio does
    VerovioText: verovioTextUrl,
    'EB Garamond': ebGaramond,
    'EB Garamond-Medium': ebGaramondMedium,
    'EB Garamond-Bold': ebGaramondSemibold,
    'EB Garamond-Italic': ebGaramondItalic,
    'EB Garamond-BoldItalic': ebGaramondSemiboldItalic,
  };
  Object.entries(fonts).forEach(([fontName, fontUrl]) => {
    if (!(fontName in fontPromises)) {
      fontPromises[fontName] = fetch(fontUrl, fetchOptions).then((response) =>
        response.arrayBuffer(),
      );
    }
  });
  const promises = Object.entries(fontPromises).map(([fontName, promise]) =>
    promise.then((buffer) => pdf.registerFont(fontName, buffer)),
  );

  await Promise.all(promises);
  pdf.options.font = font ?? defaultFont;
  pdf.font(defaultFont);
  return pdf;
}
export type PdfPage = {
  key?: string;
  blocks: PdfBlock[];
  options?: PdfOptions & {
    leftMargin?: number;
    rightMargin?: number;
  };
  pageBreakAfter?: boolean;
  cardConfig?: OrdoCardConfig;
};

/**
 * 
 * @param { pages, blocks, contentWidthPixels } 
 * @param options 
 * @returns { Blob, cardPages } cardPages is an array of which card IDs start on each page
 */
export const getPdfBlob = async (
  { pages, blocks, contentWidthPixels = getContentWidthPixels() }:
    ExclusiveUnion<{ blocks: PdfBlock[] } | { pages: PdfPage[] }> & {
      contentWidthPixels?: number;
    },
  options: PDFKit.PDFDocumentOptions & PdfOptions & { size?: [number, number], pageNumbers?: PageNumberInfo } = {
    info: {
      CreationDate: new Date(),
      Producer: 'Source & Summit (sourceandsummit.com)',
    },
  },
): Promise<{ blob: Blob; cardPages: string[][] }> => {
  const cardPages: string[][] = [];
  const { logException, verticalAlign, singlePage, breakEveryCard, fontPromises = {}, pageNumbers, ...pdfDocumentOptions } = options;
  if (pageNumbers) {
    // reset starting page number (if any)
    delete pageNumbers.pageNumber;
  }
  const loggedExceptions: ReturnType<ExceptionLogger>[] = [];
  let { contentWidth: defaultContentWidth } = pdfDocumentOptions;
  delete pdfDocumentOptions.contentWidth;
  pages = pages ?? [{ blocks: blocks! }];

  const destinations = new Set<string>(pages.flatMap(p => p.blocks).map(b => b.options?.destination).filter(dest => dest) as string[]);
  const pageWidth = (Array.isArray(pdfDocumentOptions.size) ? pdfDocumentOptions.size : defaultOptions.size)[0];
  const margins = pdfDocumentOptions.margins ?? defaultOptions.margins;
  const widthFromOptions = pageWidth - (margins.left ?? 0) - (margins.right ?? 0);
  let useContentWidth = widthFromOptions === toPt(5.5);
  defaultContentWidth = useContentWidth
    ? defaultContentWidth ?? pages[0].options?.contentWidth
    : undefined;

  if (useContentWidth && defaultContentWidth) {
    // we need to set the margins based on the content width:
    const margin = (pageWidth - defaultContentWidth) / 2;
    pdfDocumentOptions.margins = { ...margins, left: margin, right: margin };
  }
  const pdfOptions = {
    ...defaultOptions,
    ...pdfDocumentOptions,
  };
  const width = defaultContentWidth ?? (pageWidth - (pdfOptions.margins?.left ?? 0) - (pdfOptions.margins?.right ?? 0));
  const pdf = await makePdfDocument(pdfOptions, fontPromises);
  if (singlePage && blocks) {
    if (options.margin === 0) {
      delete pdf.options.margins;
      pdf.options.size = [width, pdfOptions.size[1]];
    }
    const { margins } = pdf.options;
    const height = sum(blocks
      ?.map((block, i) =>
        measureBlockHeight(block, pdf, i === 0 ? 0 : undefined),
      ));
    pdf.options.size = [width, height];
    if (margins) {
      pdf.options.size[0] += margins.left + margins.right;
      pdf.options.size[1] += margins.top + margins.bottom;
    }
  }
  addPage(pdf, pageNumbers, cardPages);

  let contentWidth =
    pdf.page.width - pdf.page.margins.left - pdf.page.margins.right;
  defaultContentWidth = contentWidth;

  const svgPdfOptions: SvgToPdfOptions = {
    useCSS: true,
    preserveAspectRatio: 'xMidYMid',
  };
  let pageBreak: boolean | undefined = undefined;
  for (const page of pages) {
    if (pageNumbers?.extraPageCount) {
      pageNumbers.pageNumber = (pageNumbers.pageNumber ?? 0) + (pageNumbers.extraPageCount[page.key ?? ''] ?? 0);
    }
    let pageContentStarted = false;
    if (useContentWidth) {
      // update the content width based on the page options
      ({ contentWidth = defaultContentWidth } = page.options ?? {});
      const { width } = pdf.page;
      const margin = (width - contentWidth) / 2;
      pdf.page.margins.left = margin;
      pdf.page.margins.right = margin;
    }
    // if the last page block array ended with a pageBreak, add it in now, provided that this page has been used (pdf.y > top margin)
    // or if the current page has verticalAlign set
    if (
      pageBreak &&
      pdf.y > pdf.page.margins.top
    ) {
      addPage(pdf, pageNumbers, cardPages);
    }
    // We use these sets to define properties without touching the original pdf block objects
    // This way we can store which blocks have column breaks, page breaks, etc. for just this render:
    const columnBreaks = new Set<PdfBlock>();
    const pageBreaks = new Set<PdfBlock>();
    const centerColumns = new Set<PdfBlock>();
    const contentHeight = pdf.page.height - pdf.page.margins.top - pdf.page.margins.bottom;
    if (page.options?.verticalAlign) {
      // paginate from end so that the first page of this section
      // starts as far down as possible, and any additional pages
      // are filled up with content.
      // measure content from end of section, filling up pages as we go
      const { pageHeights, blocks } = paginateFromEnd({ page, pdf, contentHeight });
      blocks.slice(1).forEach(([firstBlock]) => pageBreaks.add(firstBlock))
      const newY = pdf.page.margins.top + contentHeight - Math.ceil(pageHeights[0]);

      if (pdf.y > pdf.page.margins.top && pdf.y > newY) {
        // need a new page;
        addPage(pdf, pageNumbers, cardPages);
      }
      // set y of first page:
      pdf.y = newY;

      // unset pageBreak if it was set to avoid an extra page break:
      pageBreak = false;
    }
    // keep track of which blocks we've already measured:
    let measuredThroughBlock = (singlePage || page.options?.verticalAlign) ? page.blocks.length : -1;
    let lastBlock: PdfBlock | undefined;
    for (let i = 0; i < page.blocks.length; ++i) {
      const block = page.blocks[i];
      if (!block) continue;
      // don't add any top margin if we're starting at the top of the page:
      let top = pdf.y <= pdf.page.margins.top ? 0 : block.margins?.top ?? 0;
      if (
        pdf.column &&
        (centerColumns.has(block) || block.columns !== lastBlock?.columns || !block.columns)
      ) {
        endColumn(pdf);
      }
      if (pdf.column && columnBreaks.has(block)) {
        advanceColumn(pdf);
        top = 0;
      }
      if (pageBreaks.has(block)) {
        addPage(pdf, pageNumbers, cardPages);
        top = 0;
      }


      if (measuredThroughBlock < i) {
        // find any group of blocks that avoid page breaks:
        const nonBreakingBlocks = page.blocks.slice(i);
        const indexOfFirstAllowablePageBreak = nonBreakingBlocks.findIndex(isBlockBreakableAtPage);
        // keep track of blocks beyond the first breakable block, if any
        let nextBlocks: PdfBlock[] = [];
        if (indexOfFirstAllowablePageBreak >= 0) {
          nextBlocks = nonBreakingBlocks.splice(indexOfFirstAllowablePageBreak + 1);
        }
        // we have to check using the column measurer whether these blocks can fit on this page
        let measuredColumnBlocks = pageBreak ? -1 : measureColumnBlocks({ nonBreakingBlocks, nextBlocks, pdf, top, centerColumns, columnBreaks, pageBreaks });
        if (pdf.y > pdf.page.margins.top && measuredColumnBlocks <= 0) {
          // if something has already been put on the page (i.e., it's not a fresh new page with pdf.y === page.margins.top)
          // or none would fit
          // or there is a page break,
          // then we need to start a new page
          addPage(pdf, pageNumbers, cardPages);
          top = 0;
          // and re-measure with the new top set
          measuredColumnBlocks = measureColumnBlocks({ nonBreakingBlocks, nextBlocks, pdf, top, centerColumns, columnBreaks, pageBreaks });
        }
        // TODO: for small page heights, where not all non-breaking blocks can fit, we may eventually want to handle it in some way
        // but for now, we just force all non-breaking blocks to try to be together on the page, which means the page may
        // overrun its margins in these cases
        measuredThroughBlock = i - 1 + Math.max(nonBreakingBlocks.length, measuredColumnBlocks);
      }
      const bottom = block.margins?.bottom ?? 0;

      if (!pageContentStarted) {
        pageContentStarted = true;
        if (page.key) cardPages.at(-1)?.push(page.key);
      }
      if (isPdfTextStyleBlock(block)) {
        drawFormattedText(pdf, block, destinations, { top, bottom }, centerColumns.has(block));
      } else {
        const { clientWidth, clientHeight } = block.element;
        const pixelMultiplier = contentWidth / Math.max(contentWidthPixels, clientWidth); // for conversion from pixels to points
        const width = Math.round(clientWidth * pixelMultiplier);
        const height = Math.round(clientHeight * pixelMultiplier);
        pdf.y += top;
        if (block.getImageBuffer) {
          try {
            const buffer = await block.getImageBuffer();
            if (buffer) {
              const alignmentOptions = ['right', 'center'].includes(block.options?.align ?? 'left')
                ? {
                  fit: [pdf.page.width - pdf.page.margins.left - pdf.page.margins.right, height] as [number, number],
                  align: block.options!.align! as 'right' | 'center',
                } : {};
              pdf.image(buffer, {
                width,
                height,
                ...alignmentOptions,
              });
            }
          } catch (e) {
            const message = 'Exception caught when trying to insert image:';
            if (logException) {
              loggedExceptions.push(logException({ error: e, errorInfo: { message } }));
            }
            console.warn(message, e);
          }
        } else if (block.element.isConnected && height) {
          const left = pdf.page.margins.left + (contentWidth - width) / 2;
          // TODO: use something like this to avoid reflow:
          // may need to turn off `useCSS: true` above
          // const clone = await prepareSvg(svg as SVGSVGElement, { className: '_EXPORTED' });
          block.element.classList.add('_EXPORTED');
          await SVGtoPDF(pdf, block.element as SVGSVGElement, left, pdf.y, {
            ...svgPdfOptions,
            height,
            width,
          });
          block.element.classList.remove('_EXPORTED');
          pdf.font(defaultFont);
        } else {
          const message = `${block.element.isConnected ? 'SVG has zero height' : 'SVG no longer connected to parent document'}; it will not be included in the exported PDF`;
          if (logException) {
            loggedExceptions.push(logException({ message: `Export Exception: ${message}`, errorInfo: { message, pageFirstBlock: page.blocks[0].spans?.map(s => s.text).join(' ') }}));
          }
          console.warn(message);
        }
        pdf.y += height + bottom;
      }
      pageBreak = block.pageBreakAfter;
      lastBlock = block;
    }
    pageBreak = pageBreak || page.pageBreakAfter;
    if (page.pageBreakAfter !== false && 'pageBreakAfter' in page) {
      // if breakEveryCard is ON, set pageBreak to TRUE; otherwise set to the card settings:
      pageBreak = breakEveryCard || pageBreak;
      if (pageBreak === undefined) {
        // if using natural flow, add some extra vertical margin:
        pdf.y += 16;
      }
    }
  }
  const blob = pdfToBlob(pdf);
  if (loggedExceptions.length) {
    Promise.all(loggedExceptions).then(async (exceptions) => {
      const ids = exceptions.map(e => e?.data?.logError?.id).filter(id => !!id) as number[];
      try {
        uploadErrorFile(new File([await blob], `${ids.join(',')}.pdf`, { type: 'application/pdf' }), ids);
      } catch {
        // just ignore any errors uploading
      }
    });
  }
  return { blob: await blob, cardPages };
};
