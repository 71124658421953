export const FONT_CHARACTER_SIZING_OVERRIDE = `<?xml version="1.0" encoding="UTF-8"?>
<bounding-boxes font-family="Times" units-per-em="2048">
  <g c="21" x="265.0" y="-22.0" w="223.0" h="1406.0" h-a-x="682"/>
  <g c="22" x="188.0" y="0.0" w="459.0" h="1384.0" h-a-x="836"/>
  <g c="23" x="0.0" y="0.0" w="1021.0" h="1353.0" h-a-x="1024"/>
  <g c="24" x="90.0" y="-181.0" w="845.0" h="1669.0" h-a-x="1024"/>
  <g c="25" x="125.0" y="-29.0" w="1457.0" h="1413.0" h-a-x="1706"/>
  <g c="26" x="82.0" y="-30.0" w="1453.0" h="1414.0" h-a-x="1593"/>
  <g c="27" x="103.0" y="0.0" w="150.0" h="1384.0" h-a-x="369"/>
  <g c="28" x="94.0" y="-365.0" w="529.0" h="1749.0" h-a-x="682"/>
  <g c="29" x="54.0" y="-365.0" w="529.0" h="1749.0" h-a-x="682"/>
  <g c="2A" x="135.0" y="0.0" w="744.0" h="1384.0" h-a-x="1024"/>
  <g c="2B" x="61.0" y="0.0" w="1034.0" h="1035.0" h-a-x="1155"/>
  <g c="2C" x="215.0" y="-292.0" w="287.0" h="500.0" h-a-x="512"/>
  <g c="2D" x="80.0" y="0.0" w="503.0" h="526.0" h-a-x="682"/>
  <g c="2E" x="220.0" y="-24.0" w="227.0" h="227.0" h-a-x="512"/>
  <g c="2F" x="4.0" y="-34.0" w="555.0" h="1418.0" h-a-x="569"/>
  <g c="30" x="18.0" y="-19.0" w="927.0" h="1395.0" h-a-x="1024"/>
  <g c="31" x="227.0" y="0.0" w="578.0" h="1374.0" h-a-x="1024"/>
  <g c="32" x="61.0" y="0.0" w="910.0" h="1380.0" h-a-x="1024"/>
  <g c="33" x="89.0" y="-15.0" w="797.0" h="1401.0" h-a-x="1024"/>
  <g c="34" x="23.0" y="0.0" w="944.0" h="1374.0" h-a-x="1024"/>
  <g c="35" x="65.0" y="-22.0" w="830.0" h="1429.0" h-a-x="1024"/>
  <g c="36" x="70.0" y="-22.0" w="888.0" h="1422.0" h-a-x="1024"/>
  <g c="37" x="40.0" y="-19.0" w="879.0" h="1374.0" h-a-x="1024"/>
  <g c="38" x="109.0" y="-22.0" w="795.0" h="1401.0" h-a-x="1024"/>
  <g c="39" x="61.0" y="-48.0" w="882.0" h="1427.0" h-a-x="1024"/>
  <g c="3A" x="165.0" y="-17.0" w="228.0" h="958.0" h-a-x="569"/>
  <g c="3B" x="170.0" y="-292.0" w="287.0" h="1233.0" h-a-x="569"/>
  <g c="3C" x="61.0" y="-17.0" w="1034.0" h="1069.0" h-a-x="1155"/>
  <g c="3D" x="61.0" y="0.0" w="1034.0" h="794.0" h-a-x="1155"/>
  <g c="3E" x="18.0" y="-17.0" w="1115.0" h="1069.0" h-a-x="1155"/>
  <g c="3F" x="135.0" y="-19.0" w="714.0" h="1402.0" h-a-x="909"/>
  <g c="40" x="184.0" y="-29.0" w="1501.0" h="1413.0" h-a-x="1886"/>
  <g c="41" x="30.0" y="0.0" w="1415.0" h="1379.0" h-a-x="1479"/>
  <g c="42" x="35.0" y="0.0" w="1178.0" h="1355.0" h-a-x="1366"/>
  <g c="43" x="51.0" y="-34.0" w="1245.0" h="1413.0" h-a-x="1366"/>
  <g c="44" x="33.0" y="0.0" w="1372.0" h="1355.0" h-a-x="1479"/>
  <g c="45" x="23.0" y="0.0" w="1200.0" h="1355.0" h-a-x="1251"/>
  <g c="46" x="23.0" y="0.0" w="1098.0" h="1355.0" h-a-x="1139"/>
  <g c="47" x="49.0" y="-29.0" w="1392.0" h="1408.0" h-a-x="1479"/>
  <g c="48" x="33.0" y="0.0" w="1398.0" h="1355.0" h-a-x="1479"/>
  <g c="49" x="33.0" y="0.0" w="606.0" h="1355.0" h-a-x="682"/>
  <g c="4A" x="21.0" y="-24.0" w="737.0" h="1379.0" h-a-x="797"/>
  <g c="4B" x="37.0" y="0.0" w="1413.0" h="1355.0" h-a-x="1479"/>
  <g c="4C" x="23.0" y="0.0" w="1202.0" h="1355.0" h-a-x="1251"/>
  <g c="4D" x="23.0" y="0.0" w="1745.0" h="1355.0" h-a-x="1821"/>
  <g c="4E" x="23.0" y="-24.0" w="1425.0" h="1379.0" h-a-x="1479"/>
  <g c="4F" x="61.0" y="-29.0" w="1342.0" h="1413.0" h-a-x="1479"/>
  <g c="50" x="33.0" y="0.0" w="1076.0" h="1355.0" h-a-x="1139"/>
  <g c="51" x="61.0" y="-365.0" w="1368.0" h="1749.0" h-a-x="1479"/>
  <g c="52" x="23.0" y="0.0" w="1316.0" h="1355.0" h-a-x="1366"/>
  <g c="53" x="85.0" y="-27.0" w="920.0" h="1411.0" h-a-x="1139"/>
  <g c="54" x="28.0" y="0.0" w="1180.0" h="1355.0" h-a-x="1251"/>
  <g c="55" x="28.0" y="-29.0" w="1415.0" h="1384.0" h-a-x="1479"/>
  <g c="56" x="33.0" y="-24.0" w="1393.0" h="1379.0" h-a-x="1479"/>
  <g c="57" x="9.0" y="-24.0" w="1899.0" h="1379.0" h-a-x="1933"/>
  <g c="58" x="21.0" y="0.0" w="1420.0" h="1355.0" h-a-x="1479"/>
  <g c="59" x="45.0" y="0.0" w="1393.0" h="1355.0" h-a-x="1479"/>
  <g c="5A" x="14.0" y="0.0" w="1209.0" h="1355.0" h-a-x="1251"/>
  <g c="5B" x="180.0" y="-323.0" w="431.0" h="1678.0" h-a-x="682"/>
  <g c="5C" x="-64.0" y="0.0" w="696.0" h="1384.0" h-a-x="569"/>
  <g c="5D" x="68.0" y="-323.0" w="432.0" h="1678.0" h-a-x="682"/>
  <g c="5E" x="66.0" y="0.0" w="827.0" h="1384.0" h-a-x="961"/>
  <g c="5F" x="0.0" y="-256.0" w="1023.0" h="256.0" h-a-x="1024"/>
  <g c="60" x="-20.0" y="0.0" w="460.0" h="1390.0" h-a-x="682"/>
  <g c="61" x="75.0" y="-15.0" w="830.0" h="963.0" h-a-x="909"/>
  <g c="62" x="7.0" y="-19.0" w="950.0" h="1415.0" h-a-x="1024"/>
  <g c="63" x="47.0" y="-19.0" w="796.0" h="962.0" h-a-x="909"/>
  <g c="64" x="49.0" y="-19.0" w="956.0" h="1417.0" h-a-x="1024"/>
  <g c="65" x="40.0" y="-24.0" w="822.0" h="962.0" h-a-x="909"/>
  <g c="66" x="40.0" y="0.0" w="744.0" h="1398.0" h-a-x="682"/>
  <g c="67" x="54.0" y="-446.0" w="908.0" h="1389.0" h-a-x="1024"/>
  <g c="68" x="18.0" y="0.0" w="979.0" h="1396.0" h-a-x="1024"/>
  <g c="69" x="40.0" y="0.0" w="486.0" h="1396.0" h-a-x="569"/>
  <g c="6A" x="-118.0" y="-448.0" w="551.0" h="1844.0" h-a-x="569"/>
  <g c="6B" x="4.0" y="0.0" w="1019.0" h="1393.0" h-a-x="1024"/>
  <g c="6C" x="37.0" y="0.0" w="489.0" h="1404.0" h-a-x="569"/>
  <g c="6D" x="33.0" y="0.0" w="1555.0" h="936.0" h-a-x="1593"/>
  <g c="6E" x="33.0" y="0.0" w="962.0" h="942.0" h-a-x="1024"/>
  <g c="6F" x="52.0" y="-31.0" w="910.0" h="969.0" h-a-x="1024"/>
  <g c="70" x="9.0" y="-446.0" w="953.0" h="1391.0" h-a-x="1024"/>
  <g c="71" x="49.0" y="-446.0" w="951.0" h="1389.0" h-a-x="1024"/>
  <g c="72" x="9.0" y="0.0" w="680.0" h="943.0" h-a-x="682"/>
  <g c="73" x="104.0" y="-19.0" w="609.0" h="964.0" h-a-x="797"/>
  <g c="74" x="25.0" y="-19.0" w="546.0" h="1204.0" h-a-x="569"/>
  <g c="75" x="18.0" y="-19.0" w="963.0" h="945.0" h-a-x="1024"/>
  <g c="76" x="37.0" y="-19.0" w="939.0" h="948.0" h-a-x="1024"/>
  <g c="77" x="42.0" y="-20.0" w="1380.0" h="949.0" h-a-x="1479"/>
  <g c="78" x="35.0" y="0.0" w="946.0" h="919.0" h-a-x="1024"/>
  <g c="79" x="28.0" y="-448.0" w="943.0" h="1367.0" h-a-x="1024"/>
  <g c="7A" x="52.0" y="0.0" w="803.0" h="919.0" h-a-x="909"/>
  <g c="7B" x="127.0" y="-365.0" w="728.0" h="1749.0" h-a-x="983"/>
  <g c="7C" x="135.0" y="0.0" w="139.0" h="1398.0" h-a-x="410"/>
  <g c="7D" x="127.0" y="-365.0" w="728.0" h="1749.0" h-a-x="983"/>
  <g c="C384" x="30.0" y="0.0" w="1415.0" h="1716.0" h-a-x="1479"/>
  <g c="C385" x="30.0" y="0.0" w="1415.0" h="1834.0" h-a-x="1479"/>
  <g c="C387" x="51.0" y="-444.0" w="1245.0" h="1823.0" h-a-x="1366"/>
  <g c="C389" x="23.0" y="0.0" w="1200.0" h="1808.0" h-a-x="1251"/>
  <g c="C391" x="23.0" y="-24.0" w="1425.0" h="1747.0" h-a-x="1479"/>
  <g c="C396" x="61.0" y="-29.0" w="1342.0" h="1750.0" h-a-x="1479"/>
  <g c="C39C" x="28.0" y="-29.0" w="1415.0" h="1721.0" h-a-x="1479"/>
  <g c="C3A1" x="75.0" y="-15.0" w="830.0" h="1416.0" h-a-x="909"/>
  <g c="C3A0" x="75.0" y="-15.0" w="830.0" h="1415.0" h-a-x="909"/>
  <g c="C3A2" x="75.0" y="-15.0" w="830.0" h="1404.0" h-a-x="909"/>
  <g c="C3A4" x="75.0" y="-15.0" w="830.0" h="1300.0" h-a-x="909"/>
  <g c="C3A3" x="75.0" y="-15.0" w="830.0" h="1331.0" h-a-x="909"/>
  <g c="C3A5" x="75.0" y="-15.0" w="830.0" h="1489.0" h-a-x="909"/>
  <g c="C3A7" x="47.0" y="-444.0" w="796.0" h="1387.0" h-a-x="909"/>
  <g c="C3A9" x="40.0" y="-24.0" w="822.0" h="1415.0" h-a-x="909"/>
  <g c="C3A8" x="40.0" y="-24.0" w="822.0" h="1414.0" h-a-x="909"/>
  <g c="C3AA" x="40.0" y="-24.0" w="822.0" h="1403.0" h-a-x="909"/>
  <g c="C3AB" x="40.0" y="-24.0" w="822.0" h="1299.0" h-a-x="909"/>
  <g c="C3AD" x="40.0" y="0.0" w="562.0" h="1391.0" h-a-x="569"/>
  <g c="C3AC" x="-12.0" y="0.0" w="538.0" h="1390.0" h-a-x="569"/>
  <g c="C3AE" x="-34.0" y="0.0" w="640.0" h="1379.0" h-a-x="569"/>
  <g c="C3AF" x="-19.0" y="0.0" w="611.0" h="1275.0" h-a-x="569"/>
  <g c="C3B1" x="33.0" y="0.0" w="962.0" h="1310.0" h-a-x="1024"/>
  <g c="C3B3" x="52.0" y="-31.0" w="910.0" h="1422.0" h-a-x="1024"/>
  <g c="C3B2" x="52.0" y="-31.0" w="910.0" h="1421.0" h-a-x="1024"/>
  <g c="C3B4" x="52.0" y="-31.0" w="910.0" h="1410.0" h-a-x="1024"/>
  <g c="C3B6" x="52.0" y="-31.0" w="910.0" h="1306.0" h-a-x="1024"/>
  <g c="C3B5" x="52.0" y="-31.0" w="910.0" h="1337.0" h-a-x="1024"/>
  <g c="C3BA" x="18.0" y="-19.0" w="963.0" h="1410.0" h-a-x="1024"/>
  <g c="C3B9" x="18.0" y="-19.0" w="963.0" h="1409.0" h-a-x="1024"/>
  <g c="C3BB" x="18.0" y="-19.0" w="963.0" h="1398.0" h-a-x="1024"/>
  <g c="C3BC" x="18.0" y="-19.0" w="963.0" h="1294.0" h-a-x="1024"/>
  <g c="C2B0" x="87.0" y="0.0" w="583.0" h="1374.0" h-a-x="819"/>
  <g c="C2A2" x="109.0" y="-285.0" w="808.0" h="1470.0" h-a-x="1024"/>
  <g c="C2A3" x="22.0" y="-20.0" w="983.0" h="1404.0" h-a-x="1024"/>
  <g c="C2A7" x="146.0" y="-301.0" w="725.0" h="1690.0" h-a-x="1024"/>
  <g c="C2B6" x="-43.0" y="-299.0" w="927.0" h="1654.0" h-a-x="928"/>
  <g c="C39F" x="23.0" y="-22.0" w="936.0" h="1423.0" h-a-x="1024"/>
  <g c="C2AE" x="66.0" y="-24.0" w="1408.0" h="1408.0" h-a-x="1556"/>
  <g c="C2A9" x="66.0" y="-24.0" w="1408.0" h="1408.0" h-a-x="1556"/>
  <g c="C2B4" x="132.0" y="0.0" w="461.0" h="1391.0" h-a-x="682"/>
  <g c="C2A8" x="-19.0" y="0.0" w="611.0" h="1275.0" h-a-x="682"/>
  <g c="C386" x="9.0" y="0.0" w="1771.0" h="1355.0" h-a-x="1821"/>
  <g c="C398" x="61.0" y="-166.0" w="1342.0" h="1668.0" h-a-x="1479"/>
  <g c="C2B1" x="47.0" y="0.0" w="1033.0" h="1035.0" h-a-x="1124"/>
  <g c="C2A5" x="14.0" y="0.0" w="1017.0" h="1355.0" h-a-x="1024"/>
  <g c="C2B5" x="-157.0" y="-422.0" w="1128.0" h="1330.0" h-a-x="1180"/>
  <g c="C2AA" x="23.0" y="0.0" w="541.0" h="1384.0" h-a-x="565"/>
  <g c="C2BA" x="33.0" y="0.0" w="590.0" h="1385.0" h-a-x="635"/>
  <g c="C3A6" x="77.0" y="-16.0" w="1217.0" h="969.0" h-a-x="1366"/>
  <g c="C3B8" x="52.0" y="-228.0" w="910.0" h="1361.0" h-a-x="1024"/>
  <g c="C2BF" x="43.0" y="-457.0" w="715.0" h="1402.0" h-a-x="909"/>
  <g c="C2A1" x="187.0" y="-460.0" w="223.0" h="1405.0" h-a-x="682"/>
  <g c="C2AC" x="61.0" y="0.0" w="1034.0" h="794.0" h-a-x="1155"/>
  <g c="C2AB" x="87.0" y="0.0" w="846.0" h="858.0" h-a-x="1024"/>
  <g c="C2BB" x="90.0" y="0.0" w="846.0" h="858.0" h-a-x="1024"/>
  <g c="C380" x="30.0" y="0.0" w="1415.0" h="1831.0" h-a-x="1479"/>
  <g c="C383" x="30.0" y="0.0" w="1415.0" h="1747.0" h-a-x="1479"/>
  <g c="C395" x="61.0" y="-29.0" w="1342.0" h="1781.0" h-a-x="1479"/>
  <g c="C3B7" x="47.0" y="0.0" w="1033.0" h="997.0" h-a-x="1124"/>
  <g c="C2B7" x="106.0" y="0.0" w="237.0" h="796.0" h-a-x="512"/>
  <g c="C382" x="30.0" y="0.0" w="1415.0" h="1820.0" h-a-x="1479"/>
  <g c="C38A" x="23.0" y="0.0" w="1200.0" h="1796.0" h-a-x="1251"/>
  <g c="C381" x="30.0" y="0.0" w="1415.0" h="1832.0" h-a-x="1479"/>
  <g c="C38B" x="23.0" y="0.0" w="1200.0" h="1692.0" h-a-x="1251"/>
  <g c="C388" x="23.0" y="0.0" w="1200.0" h="1807.0" h-a-x="1251"/>
  <g c="C38D" x="33.0" y="0.0" w="619.0" h="1808.0" h-a-x="682"/>
  <g c="C38E" x="16.0" y="0.0" w="640.0" h="1796.0" h-a-x="682"/>
  <g c="C38F" x="31.0" y="0.0" w="611.0" h="1692.0" h-a-x="682"/>
  <g c="C38C" x="33.0" y="0.0" w="606.0" h="1807.0" h-a-x="682"/>
  <g c="C393" x="61.0" y="-29.0" w="1342.0" h="1866.0" h-a-x="1479"/>
  <g c="C394" x="61.0" y="-29.0" w="1342.0" h="1854.0" h-a-x="1479"/>
  <g c="C392" x="61.0" y="-29.0" w="1342.0" h="1865.0" h-a-x="1479"/>
  <g c="C39A" x="28.0" y="-29.0" w="1415.0" h="1837.0" h-a-x="1479"/>
  <g c="C39B" x="28.0" y="-29.0" w="1415.0" h="1825.0" h-a-x="1479"/>
  <g c="C399" x="28.0" y="-29.0" w="1415.0" h="1836.0" h-a-x="1479"/>
  <g c="C2AF" x="-34.0" y="0.0" w="640.0" h="1230.0" h-a-x="682"/>
  <g c="C2B8" x="52.0" y="-444.0" w="426.0" h="444.0" h-a-x="682"/>
  <g c="C2A6" x="135.0" y="0.0" w="139.0" h="1398.0" h-a-x="410"/>
  <g c="C390" x="33.0" y="0.0" w="1370.0" h="1355.0" h-a-x="1479"/>
  <g c="C3B0" x="54.0" y="-31.0" w="907.0" h="1422.0" h-a-x="1024"/>
  <g c="C39D" x="45.0" y="0.0" w="1393.0" h="1808.0" h-a-x="1479"/>
  <g c="C39E" x="33.0" y="0.0" w="1073.0" h="1355.0" h-a-x="1139"/>
  <g c="C397" x="85.0" y="0.0" w="984.0" h="1009.0" h-a-x="1155"/>
  <g c="C2B9" x="135.0" y="0.0" w="374.0" h="1375.0" h-a-x="614"/>
  <g c="C2B2" x="7.0" y="0.0" w="587.0" h="1374.0" h-a-x="614"/>
  <g c="C2B3" x="22.0" y="0.0" w="516.0" h="1374.0" h-a-x="614"/>
  <g c="C2BD" x="109.0" y="-15.0" w="1287.0" h="1394.0" h-a-x="1536"/>
  <g c="C2BC" x="109.0" y="-15.0" w="1256.0" h="1394.0" h-a-x="1536"/>
  <g c="C2BE" x="53.0" y="-15.0" w="1326.0" h="1394.0" h-a-x="1536"/>
  <g c="C2A4" x="66.0" y="0.0" w="832.0" h="1095.0" h-a-x="1024"/>
</bounding-boxes>`;
