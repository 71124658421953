import { AuthContext } from 'context/AuthContext';
import { useDebugLogger } from 'hooks/debug';
import { useLogInfoEffect } from 'hooks/debug/useDebugLogger';
import { useUser } from 'hooks/useUser';
import { useCallback, useContext, useMemo } from 'react';
import { PlanCategory, PlanId, isMissalOrCompanion } from 'utils/api-types/plans';
import dayjs from 'utils/dayjs';
import { contactUrl } from 'utils/links';
import { getBooleanParam } from 'utils/params/getParam';

export const useGetOverridePurchaseCta = (renewalParam = getBooleanParam('renewal')) => {
  const { blockMissal, blockNonMissal } = useBlockPurchaseConditions(renewalParam);
  return useCallback(
    /**
     * 
     * @param category category being purchased
     * @returns tuple with [label, url] to override the purchase CTA if purchase is unavailable
     *  or an empty array [] if purchase is available
     */
    (category?: PlanCategory): [string, string] | [] =>
      (blockMissal && isMissalOrCompanion(category)) ||
      (blockNonMissal && !isMissalOrCompanion(category))
        ? ['Talk to Sales', contactUrl]
        : [],
    [
      blockMissal,
      blockNonMissal
    ],
  );
};

export const useBlockPurchaseConditions = (renewalParam = getBooleanParam('renewal')) => {
  const user = useUser();
  const { group, groups } = useContext(AuthContext);
  const { subscription, keycloakGroup } = group ?? {};
  const { planId, status, isMissal, currentTermEnd } = subscription ?? {};
  const { verifiedGroupOwner } = keycloakGroup ?? {};
  const isNonRenewingSubscription =
    planId &&
    PlanId[planId] !== PlanId.CHARTER_TRIAL &&
    status === 'NON_RENEWING';

  // the current user is only able to purchase if there is not yet a verified group,
  // or else the current user is the owner of the verified group
  // or no user is logged in
  const isAbleToPurchase =
    !user ||
    (groups?.length === 1 &&
      ((verifiedGroupOwner ?? null) === null ||
        verifiedGroupOwner === user.sub));
  const isTrialSubscription =
    status === 'IN_TRIAL' ||
    (planId &&
      PlanId[planId] === PlanId.CHARTER_TRIAL &&
      status === 'NON_RENEWING');

  // missal subscriptions cannot be modified
  const isMissalSubscriptionThatCannotBeModified = isMissal
  const currentTermEndDay = useMemo(
    () => dayjs(currentTermEnd),
    [currentTermEnd],
  );
  const canModifyToMissalOnRenewal =
    renewalParam &&
    !isTrialSubscription &&
    currentTermEnd;
  const cannotBeModifiedToMissal =
    !canModifyToMissalOnRenewal &&
    !isMissal &&
    !isTrialSubscription &&
    !!subscription &&
    !!user &&
    false;
  
  const logger = useDebugLogger('purchase');
  useLogInfoEffect(logger, {
    renewalParam,
    status,
    isNonRenewingSubscription,
    isAbleToPurchase,
    isTrialSubscription,
    isMissalSubscriptionThatCannotBeModified,
    currentTermEndDay,
    canModifyToMissalOnRenewal,
    cannotBeModifiedToMissal
  });

  const blockAll = (status === 'FUTURE' && !renewalParam) ||
    !isAbleToPurchase ||
    !!isNonRenewingSubscription;
  const blockNonMissal = blockAll || !!isMissalSubscriptionThatCannotBeModified;
  const blockMissal = blockAll || cannotBeModifiedToMissal;

  return {
    blockNonMissal,
    blockMissal,
  }
}
