export type Tab =
| 'Document'
| 'Ordo'
| 'Images'
| 'Resources'

export const tabMap = [
  ['document', 'Document'],
  ['ordo', 'Ordo'],
  ['images', 'Images'],
  ['resources', 'Resources'],
] as const;
