import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BriefOrdoCardSummary } from "queries/card-fragment"
import { FC, Fragment, ReactNode } from "react";
import parseHtml from 'html-react-parser';
import { replaceVersicleResponseInHtml } from "utils/verovio/postProcessVerovioSvg";
import { cx } from "@emotion/css";

export type CardSummaryProps = {
  card: BriefOrdoCardSummary;
  includeSecondary?: boolean;
  showAudioButton?: boolean;
  groupReferenceAndSecondary?: boolean;
  className?: string;
  cardReferenceFallback?: string;
  makeLinkInPdf?: boolean;
}

export const CardSummary: FC<CardSummaryProps> = ({
  card: { name, summary, id },
  includeSecondary = true,
  showAudioButton = true,
  groupReferenceAndSecondary = false,
  className = 'card-summary',
  cardReferenceFallback,
  makeLinkInPdf = false,
}) => {
  let { reference, secondary, tags } = summary ?? { };
  if (cardReferenceFallback && !reference && (!includeSecondary || !secondary)) {
    reference = cardReferenceFallback;
  }
  const InfoGroup = groupReferenceAndSecondary
    ? ({ children }: { children: ReactNode }) => <div className='info-group'>{children}</div>
    : Fragment;
  const CardInfo = groupReferenceAndSecondary
    ? Fragment
    : ({ children }: { children: ReactNode }) => <div className='card-info'>{children}</div>
  return <div className={className}>
    {showAudioButton && <button className='card-audio-button'>
      <FontAwesomeIcon icon={faPlay} style={{ margin: 'auto' }} />
    </button>}
    <CardInfo>
      <div
        className="card-name"
        {...(makeLinkInPdf ? { 'data-go-to': `card-${id}` } : {})}
      >
        {name}
      </div>
      <InfoGroup>
        {reference ? <span className='card-reference'>{parseHtml(replaceVersicleResponseInHtml(reference) ?? '')}</span> : null}
        {includeSecondary && secondary ? <span className='card-secondary-info'>{secondary}</span> : null}
      </InfoGroup>
    </CardInfo>
    <div className={cx('card-tags', { 'grey-tags': summary.greyTags })}>
      {tags?.map((tag, i) => (
        <div key={i}>{tag}</div>
      ))}
    </div>
  </div>
}
