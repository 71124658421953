import { useDebugLogger } from "hooks/debug/useDebugLogger";
import { useLoggedState } from "hooks/debug/useLoggedState";
import { OrdoCard } from "queries/card-fragment";
import { useCallback } from "react";
import { OrdoCardConfigInput } from "utils/api-types/ordo/card/OrdoCardConfig";
import { CardState } from "utils/ordo/card/CardState";
import { UpdateCardConfigCallback } from "./UpdateCardConfigCallback";

export const useSharedCardContextProps = (card: OrdoCard | undefined, createConfigUpdater: (id: number) => UpdateCardConfigCallback) => {
  const debugLogger = useDebugLogger('card');

  const showState = useCallback(
    (cardState: CardState) => "[" + cardState.viewStack.join() + "]",
    []
  );
  const [cardState, setCardState] = useLoggedState<CardState>(
    {
      debugLogger,
      description: `card ${card?.id} (${card?.name}) viewStack`,
      showState,
    },
    { viewStack: [] } as CardState
  );

  const currentView = () => cardState.viewStack[0];

  const nextView = (name: string): string[] => {
    const stack = [...cardState.viewStack];
    stack.unshift(name);

    return stack;
  };

  const prevView = (): string[] => {
    const stack = [...cardState.viewStack];
    if (stack.length > 1) {
      stack.shift();
    }

    return stack;
  };

  const updateView = (viewStack: string[]) => {
    setCardState({
      viewStack,
    });
  };

  const saveConfig = useCallback(async (config: OrdoCardConfigInput, viewStack?: string[]) => {
    const result = await createConfigUpdater(card?.id ?? 0)(config);
    if (viewStack && result && result.data) {
      setCardState({ viewStack });
    }
  }, [card?.id, createConfigUpdater, setCardState]);

  return {
    cardState,
    currentView,
    nextView,
    prevView,
    updateView,
    saveConfig,
  }
};
