/* eslint-disable @typescript-eslint/no-redeclare */
import { gql, OperationVariables } from '@apollo/client';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { Dictionary } from 'utils/typescript/Dictionary';
import { CheckForErrorFunction, GQLDocument, GQLQuery } from './gql-generics';
import { SOURCE_FRAGMENT } from './source';
import { Translation } from './translation';

export type Text = {
  originalSource: string;
  altSource: string | null;
  contentType: WithNameAndId;
  officialTranslations: TranslationOnText[];
} & WithNameAndId;

export type TranslationOnText = Pick<
  Translation,
  | 'id'
  | 'content'
  | 'altContent'
  | 'synopsis'
  | 'language'
  | 'source'
  | 'readingIntroduction'
  | 'readingConclusion'
>;

export const TEXT_FRAGMENT = gql`
  fragment Text on Text {
    id
    name
    originalSource
    altSource
    contentType {
      id
      name
    }
    officialTranslations {
      id
      content
      altContent
      synopsis
      language
      readingIntroduction
      readingConclusion
      source {
        ...Source
      }
    }
  }
  ${SOURCE_FRAGMENT}
`;

export const createTextErrorCheck: <T extends Dictionary<Text>, Vars extends OperationVariables = OperationVariables>(queryName: string, propName: keyof T) => CheckForErrorFunction<{
  Data: T;
  Variables: Vars;
}> = (queryName, propName) => (logError, data, variables) => {
  if ((data?.[propName]?.officialTranslations?.length ?? 0) === 0) {
    logError(`No Translations on Text in query: ${queryName}`, {
      data,
      variables,
    });
  }
}

export interface GetText extends GQLQuery<GetText> {
  Variables: WithId;
  Data: { text: Text };
}
export const GetText: GQLDocument<GetText> = {
  query: gql`
    query getText($id: Float!) {
      text(id: $id) {
        ...Text
      }
    }
    ${TEXT_FRAGMENT}
  `,
  checkForError: createTextErrorCheck('GetText', 'text')
}

export interface GetGloryBe extends GQLQuery<GetGloryBe> {
  Variables: never;
  Data: { gloryBe: Text }
}
export const GetGloryBe: GQLDocument<GetGloryBe> = {
  query: gql`
    query getGloryBe {
      gloryBe {
        ...Text
      }
    }
    ${TEXT_FRAGMENT}
  `,
  checkForError: createTextErrorCheck('GetGloryBe', 'gloryBe')
}

export interface GetPrayers extends GQLQuery<GetPrayers> {
  Variables: never;
  Data: {
    prayers: (WithNameAndId & {
      officialTranslations: Pick<Translation, 'id'|'language'|'content'>[];
      attributes: (WithNameAndId & {
        value: string;
      })[];
    })[]
  }
}
export const GetPrayers: GQLDocument<GetPrayers> = {
  query: gql`
    query getPrayers {
      prayers {
        id
        name
        officialTranslations {
          id
          language
          content
        }
        attributes {
          id
          name
          value
        }
      }
    }
  `,
}
