import React from 'react';

interface InfoProps  {
  message: string;
}

export const Info: React.FC<InfoProps> = ({message}) => {
  return (
    <>{message}</>
  );
}
