import { css, cx } from '@emotion/css';
import { LazySyllabifiedExsurge, gabcVerovioTextUndertie } from 'components/shared/LazyExsurge';
import { MusicalErrorAlert } from 'components/shared/MusicalErrorAlert';
import { getVerovioOptionsForHorizontal } from 'components/shared/editor/settings/SpacingToggler';
import { MidiVerovioOrPassthru } from 'components/verovio/MidiVerovioOrPassthru';
import { RenderMidiCallback, Verovio } from 'components/verovio/Verovio';
import { VerovioSvgs } from 'components/verovio/VerovioSvgs';
import { CardContext } from 'context/CardContext';
import { OrdoContext } from 'context/OrdoContext';
import { useDefaultExsurgeConfig } from 'hooks/exsurge/useDefaultExsurgeConfig';
import { GatedFeature, useHasFeature } from 'hooks/permission-set/useHasFeature';
import { useIdQuery } from 'hooks/queries/useQuery';
import { useHymnText } from 'hooks/text/useHymnText';
import { useMidiCallbacks } from 'hooks/useMidiCallbacks';
import useResizeObserver from 'hooks/util/useResizeObserver';
import { GetComposition, GetMelody } from 'queries/composition';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { MusicalNotation } from 'utils/api-types/ordo/card/MusicalProperties';
import { getExsurgeAlignment } from 'utils/functions/exsurge/getExsurgeAlignment';
import { getModeNumeral } from 'utils/functions/exsurge/getModeNumeral';
import { ParsedVerse, VerseTypes, parseVerses } from 'utils/functions/hymn/parseVerses';
import { getContentWidthForExsurge } from 'utils/getContentWidth';
import { removeGabcHymnTuneAmen } from 'utils/hymnTuneAmen';
import { colorDictionary } from 'utils/styles';
import {
  DEFAULT_POST_PROCESS_HYMN,
  getVerovioTranspose,
  useDefaultVerovioOptionsHymn
} from 'utils/verovio';
import { VerovioPostProcessConfig } from 'utils/verovio/postProcessVerovioSvg';
import { CardReferenceDisplay } from '../reference/CardReferenceDisplay';
import { ResourceGroupDisplay } from '../resource/ResourceGroupDisplay';

export interface HymnCardProps {
  isActive?: boolean;
  isFullScreen?: boolean;
  addGrandStaff?: boolean;
}

export const HymnCard: React.FC<HymnCardProps> = ({
  isActive = false,
  isFullScreen,
  addGrandStaff
}) => {
  const { card, config, onLoaded } = useContext(CardContext);
  useEffect(() => {
    if (config.hymnId || config.referenceId) {
      onLoaded();
    }
  }, [config.hymnId, config.referenceId, onLoaded]);
  const { selectedRole, ordo } = useContext(OrdoContext);
  const { spacingLinear, spacingNonLinear, spacingHorizontal, spacingVertical } = config;
  addGrandStaff = selectedRole?.defaultRoleConfig?.addGrandStaff ?? addGrandStaff;
  const { accompanimentExport } = ordo.config ?? {};
  const meiOrSvg = card.mei ?? card.svg;
  const verseCount = meiOrSvg?.verseCount;
  const hasMultipleVerses = typeof verseCount !== "number" || verseCount > 1 || !!(config.notateFirstVerses && meiOrSvg?.textVerses?.length);
  const versesContainMultipleStanzas = meiOrSvg?.versesContainMultipleStanzas;
  const renderTextOnlyAfterNotated = !!config.notateFirstVerses && !versesContainMultipleStanzas && (meiOrSvg?.hymn?.length ?? 0) <= 2 && !!meiOrSvg?.textVerses?.length;
  const postProcessConfig: VerovioPostProcessConfig = useMemo(() => ({
    ...DEFAULT_POST_PROCESS_HYMN,
    addStanzaNumbersOnAllSystems: hasMultipleVerses && !versesContainMultipleStanzas,
    addStanzaNumbersOnFirstSystem: hasMultipleVerses || (versesContainMultipleStanzas ?? false),
    stanzaCount: verseCount || undefined,
  }), [hasMultipleVerses, versesContainMultipleStanzas, verseCount]);
  const firstVerseNum = renderTextOnlyAfterNotated ? 1 + (verseCount ?? 0) : 1;
  const parsedVerses = parseVerses(meiOrSvg, { firstVerseNum, removeRefrain: renderTextOnlyAfterNotated });

  const meiKey: number = meiOrSvg?.keyIndex ?? 0;
  const transposeOffset: number =
    (meiOrSvg?.defaultTranspose || 0) + (config.keyOffset || 0);
  const isChantHymn = meiOrSvg?.hymnHasSuppressedStems;
  const extraConfig = useMemo(() => ({
    transpose: getVerovioTranspose(meiKey, transposeOffset),
    ...(spacingHorizontal
      ? getVerovioOptionsForHorizontal(isChantHymn ? 0.29 : 0.33, spacingHorizontal)
      : {
          ...(spacingLinear ? { spacingLinear } : {}),
          ...(spacingNonLinear ? { spacingNonLinear } : {}),
        }),
  }), [meiKey, transposeOffset, spacingHorizontal, isChantHymn, spacingLinear, spacingNonLinear]);
  const verovioConfig = useDefaultVerovioOptionsHymn({
    extraConfig,
    isFullScreen,
    showHarmonizations: config.displayHarmonizations,
    addGrandStaff,
    isChantHymn,
    verseCount,
    spacingVertical,
  });
  let displayNotation = config.notation || MusicalNotation.MODERN;
  const needCompositionAndMelody = !card.svg?.hymn && displayNotation === MusicalNotation.SQUARE;
  const compositionId = needCompositionAndMelody ? config.hymnId : null;
  const melodyId = needCompositionAndMelody ? config.hymnTuneId : null;
  const { data: { composition } = { composition: null } } = useIdQuery(GetComposition, compositionId);
  const { data: { melody } = { melody: composition?.melody } } = useIdQuery(GetMelody, melodyId);
  if (melody?.voice.encoding === 'lilypond' && displayNotation === MusicalNotation.SQUARE) {
    displayNotation = MusicalNotation.MODERN;
  }
  const { ref, setMidiData } = useMidiCallbacks(
    isActive,
    card.id,
    meiOrSvg?.name,
    card.name,
    { isMidiAvailable: !!meiOrSvg },
  );
  const { width = 0 } = useResizeObserver({ref});

  const exsurgeDefaults = useDefaultExsurgeConfig({ spacingHorizontal, spacingVertical });
  const modeNumeral = getModeNumeral(melody?.mode ?? '');
  const setGabcRendered = useCallback(() => onLoaded(), [onLoaded]);

  const onRenderMidi: RenderMidiCallback = useCallback((...args) => {
    setMidiData(...args, meiOrSvg?.audioUrls, card.config.keyOffset);
    onLoaded();
  }, [setMidiData, meiOrSvg?.audioUrls, card.config.keyOffset, onLoaded]);

  useEffect(() => {
    if (displayNotation === MusicalNotation.TEXT) {
      onLoaded();
    }
  }, [displayNotation, onLoaded]);

  const showReferenceNumber = useHasFeature(GatedFeature.MissalReferenceNumber)
    && config.displayReferenceNumber
    && meiOrSvg?.referenceNumber;

  const longestVerseNumber = useMemo(
    () =>
      parsedVerses
        .filter(({ type }) => type === VerseTypes.VERSE)
        .map(({ label }) => label.replace(/\.$/, '').length)
        .sort((a, b) => b - a)[0],
    [parsedVerses],
  );

  const refrainText = composition?.translation?.altContent ?? '';
  const hymnText = useHymnText(
    composition?.translation?.content ?? '',
    meiOrSvg?.refrainAtBeginning ? '' : refrainText,
    config,
  );
  const refrainAtBeginningOfHymnText = meiOrSvg?.refrainAtBeginning ? refrainText : undefined;
  const melodyVoiceData = melody?.voice?.data ?? '';
  const gabcNotation = useMemo(
    () =>
      displayNotation === MusicalNotation.SQUARE && (config.showAmen ?? true)
        ? melodyVoiceData
        : removeGabcHymnTuneAmen(melodyVoiceData),
    [config.showAmen, displayNotation, melodyVoiceData],
  );

  if (config.resourceGroupId !== null) {
    return <ResourceGroupDisplay isActive={isActive} />
  }

  if (config.referenceId) {
    return <CardReferenceDisplay referenceId={config.referenceId} />;
  }

  if (!config.hymnId || !displayNotation) {
    return null;
  }

  if (meiOrSvg?.error) {
    return <MusicalErrorAlert />;
  }

  return (
    <>
      {meiOrSvg?.name && !accompanimentExport && (
        <div
          className={cx(
            'hymn-card-top-bar _EXPORT no-page-break-after',
            styles.topBar,
          )}
        >
          <span className="hymn-title">{meiOrSvg.name}</span>
          {showReferenceNumber && (
            <span className="hymn-reference-number">
              {meiOrSvg.referenceNumber}
            </span>
          )}
        </div>
      )}
      {meiOrSvg?.hymn && (
        <div
          ref={ref}
          className={cx({
            [cx('hymn-verovio-wrapper', styles.verovioWrapper, {
              'has-grand-staff': config.displayHarmonizations || addGrandStaff,
              'has-chord-symbols': config.displayChordSymbols,
              'chant-hymn': isChantHymn,
            })]: displayNotation === MusicalNotation.MODERN,
            [styles.exsurgeWrapper]: displayNotation === MusicalNotation.SQUARE
          })}
        >
          {meiOrSvg && (
            <MidiVerovioOrPassthru
              svg={card.svg ?? undefined}
              mei={card.mei ?? undefined}
              {...{ onRenderMidi }}
              keyOffset={config.keyOffset}
            />
          )}
          {card.svg?.hymn
            ? card.svg.hymn.map((svgWithTitles, i) => (
                <VerovioSvgs
                  key={i}
                  svgs={svgWithTitles.svgs}
                  className="_EXPORT"
                  outerClassName={cx({ 'with-harmonization': config.displayHarmonizations })}
                />
              ))
            : displayNotation === MusicalNotation.MODERN
            ? card.mei &&
              card.mei.hymn!.map((meiWithTitles, i) => (
                <Verovio
                  key={i}
                  mei={meiWithTitles.mei}
                  className="_EXPORT"
                  postProcessConfig={postProcessConfig}
                  config={verovioConfig}
                />
              ))
            : displayNotation === MusicalNotation.SQUARE && (
                <LazySyllabifiedExsurge
                  notation={gabcNotation}
                  text={hymnText}
                  refrainAtBeginning={refrainAtBeginningOfHymnText}
                  repeatVerses={config.stackChantVerses ? 'stack' : true}
                  useDropCap={true}
                  width={getContentWidthForExsurge(width)}
                  language={composition?.translation?.language}
                  alignment={getExsurgeAlignment(
                    composition?.translation?.language,
                  )}
                  annotation={modeNumeral}
                  className="_EXPORT exsurge-wrapper"
                  tildeReplacement={gabcVerovioTextUndertie}
                  onRender={setGabcRendered}
                  {...exsurgeDefaults}
                />
              )}
        </div>
      )}
      {(displayNotation === MusicalNotation.TEXT ||
        renderTextOnlyAfterNotated) &&
        meiOrSvg?.textVerses && (
          <ol
            className={cx('list-unstyled _EXPORT', styles.textOnly, {
              'columns-2 columns-center': config.verseColumns === 2,
              'mixed-lyrics': renderTextOnlyAfterNotated,
              'chant-hymn': isChantHymn,
              'has-grand-staff': config.displayHarmonizations,
              'two-digit-verses': longestVerseNumber > 1,
            })}
          >
            {parsedVerses.map((verse: ParsedVerse) => (
              <li
                key={verse.label}
                className={cx(
                  'text-only-verse pt-10 no-page-break-inside',
                  verse.type,
                  {
                    'single-verse':
                      !renderTextOnlyAfterNotated && parsedVerses.length === 1,
                  },
                )}
              >
                <p className={`label mb-0 mt-10 ${verse.type}`}>
                  {verse.label}
                </p>
                {verse.lines
                  // binding key={i} realy doesn't do anything
                  // to help React with updating the content but
                  // it stops the warning so there's that.
                  .map((line, i) => (
                    <p key={i} className={`line mt-0 mb-0 ${line.className}`}>
                      {line.content.map((span, j) => (
                        <span key={j} className={span.className}>
                          {span.content}
                        </span>
                      ))}
                    </p>
                  ))}
              </li>
            ))}
          </ol>
        )}
    </>
  );
};

const styles = {
  verovioWrapper: css`
    margin: 0;
    padding: 0;
    .verovio-container + .verovio-container {
      padding-top: calc(0px + var(--card-vertical-spacing, 0px))
    }
    &.has-chord-symbols .verovio-container + .verovio-container {
      padding-top: calc(8px + var(--card-vertical-spacing, 0px))
    }
    // this similar rule must come after the above rule because it has priority
    &.has-grand-staff .verovio-container + .verovio-container {
      padding-top: calc(15px + var(--card-vertical-spacing, 0px))
    }
  `,
  exsurgeWrapper: css`
    .Exsurge + .Exsurge {
      padding-top: calc(4px + var(--card-vertical-spacing, 0px));
    }
  `,
  topBar: css`
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-style: italic;
    line-height: 1.2;
    .hymn-title {
      font-size: 0.9em;
    }
    .hymn-reference-number {
      font-size: 15px;
      color: ${colorDictionary['Secondary']};
      font-weight: 400;
    }
  `,
  textOnly: css`
    &.mixed-lyrics {
      font-size: 18.2px;

      &.chant-hymn.has-grand-staff {
        margin-top: 10px;
      }
    }
  `
}
