import { MutationHookOptions, ApolloError } from '@apollo/client';
import { CreateUserInteraction, InteractionsForUser } from 'queries/interaction';
import { useCallback } from 'react';
import { InteractionIdentifier } from 'utils/ordo/InteractionIdentifier';
import { getHasAuthorizationToken } from 'utils/authorization/authorization';
import { useMutation } from 'hooks/queries/useMutation';
import { sendSimpleGtagEvent } from 'utils/gtag/createGtagHandler';

const onError = (error: ApolloError) => {
  if (/\bunauthorized\b/i.test(error.message)) {
    // ignore errors if they are simply not authorized,
    // as an unauthorized user cannot create a meaningful interaction
    return;
  }
  console.warn(error);
};
export const useCreateUserInteraction = (options: MutationHookOptions<CreateUserInteraction['Data'], CreateUserInteraction['Variables']> = {}) => {
  const [createUserInteractionMutation] = useMutation(CreateUserInteraction, {
    ...options,
    // this query isn't cached, or if it is, it's not obvious how to update it in-cache,
    // so just refetch it when a new interaction is created
    refetchQueries: [InteractionsForUser.query],
    onError,
  });

  const createUserInteraction = useCallback(
    (interactionIdentifier: InteractionIdentifier, jsonData?: CreateUserInteraction['Variables']['jsonData']) => {
      if (!getHasAuthorizationToken()) {
        return;
      }

      const variables = { interactionIdentifier, jsonData };
      createUserInteractionMutation({ variables });
      sendSimpleGtagEvent(interactionIdentifier);
    },
    [createUserInteractionMutation],
  );

  return createUserInteraction;
};

export const useInteractionCallback = (identifier: InteractionIdentifier, jsonData?: CreateUserInteraction['Variables']['jsonData']) => {
  const createUserInteraction = useCreateUserInteraction();
  const json = JSON.stringify(jsonData);
  return useCallback(() => {
    createUserInteraction(identifier, json && JSON.parse(json));
  }, [createUserInteraction, identifier, json]);
}
