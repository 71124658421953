import React, { CSSProperties } from 'react';
import {
  VerseSegment,
  GabcPsalmTones,
  GabcPsalmTone,
  VerseSegmentType,
} from 'gabc-utils';
import { cx } from '@emotion/css';

const getStyleClassName = (style?: string | null): string => {
  switch (style) {
    case 'bold':
      return 'fw-semibold';
    case 'italic':
      return 'fst-italic';
    default:
      return style || '';
  }
};

const undertieStyle: CSSProperties = {
  verticalAlign: 'baseline',
  fontFamily: 'VerovioText',
};
export const verovioTextUndertie = '';

interface FormattedVerseSegmentProps {
  segment: VerseSegment;
  psalmTone: GabcPsalmTones;
  psalmToneSegment?: GabcPsalmTone;
  forceBreakWhenNotTermination?: boolean;
  startingSyllableIndex?: number;
}

export const FormattedVerseSegment: React.FC<FormattedVerseSegmentProps> = ({
  segment,
  psalmTone: gpt,
  psalmToneSegment = gpt[
    gpt.isGregorianSolemn && segment.segmentType === VerseSegmentType.Flex
      ? VerseSegmentType.Mediant
      : segment.segmentType
  ] || ({} as GabcPsalmTone),
  forceBreakWhenNotTermination = true,
  startingSyllableIndex,
}) => {
  const useSyllableId = typeof startingSyllableIndex === 'number';
  let syllableId = startingSyllableIndex ?? 0;
  return <>
    {segment
      .getFormattedStrings({
        ...(psalmToneSegment.syllableCounts || {}),
        syllableSeparator: '\xAD',
      })
      .map(({ style, text }, i) => {
        const className = getStyleClassName(style);
        const syllables = useSyllableId ? text.split(/\xAD|(\s+)/) : [text];
        return <React.Fragment key={i}>
          {syllables.map((part, i) => {
            if (i % 2 === 1  || !/[a-záéíóúýàèìòùäëïöüÿæœǽœ́]/i.test(part)) {
              return part || null;
            }
            return (
              <span key={i} className={cx(className, 'note')} {...useSyllableId ? { id: `syllable-${syllableId++}` } : {}}>
                {part.split(/(‿)/).map((part, i) =>
                  // parts with odd index are underties, and we style them differently so they aren't weirdly super wide
                  i % 2 === 0
                    ? makeLordSmallCap(part)
                    : <span key={i} style={undertieStyle}>
                        {verovioTextUndertie}
                      </span>
                )}
              </span>
            );
          })}
        </React.Fragment>;
      })}
    {forceBreakWhenNotTermination && segment.segmentType !== 'termination'  && <br />}
  </>;
};

const makeLordSmallCap = (text: string) => {
  const split = text.split(/\b(LORD)\b/);
  if (split.length === 1) return text;
  return split.map((text, i) => (i % 2) === 0 ? text : <span key={i} className='all-small-caps'>{text}</span>);
}
