import { TextAlignment } from 'components/ordo/cards/notes/TextAlignment';
import { WithId, WithNameAndId } from '../WithNameAndId';

export type Note = {
  content: string | null;
  title: string | null;
  language: string | null;
  alignment: TextAlignment | null;
} & WithId;

export type Music = {
  name?: string | null;
  combinedGabc?: string | null;
  mode?: string | null;
  dropCap?: boolean | null;
  alignment?: 'en' | 'la' | null;
} & WithId;

export type File = {
  url: string;
  awsKey: string;
} & WithNameAndId;

type ResourceBase = WithId;

export type NoteResource = {
  note: Note;
  file?: undefined;
  music?: undefined;
  resourceType: null;
} & ResourceBase;

export type FileResource = {
  file: File;
  note?: undefined;
  music?: undefined;
  resourceType: WithNameAndId | null;
} & ResourceBase;

export type MusicResource = {
  file?: undefined;
  note?: undefined;
  music: Music;
  resourceType: null;
} & ResourceBase;

export type Resource = FileResource | NoteResource | MusicResource;

export const isNote = (resource?: Resource): resource is NoteResource => !!resource?.note;
export const isFile = (resource?: Resource): resource is FileResource => !!resource?.file;
export const isMusic = (resource?: Resource): resource is MusicResource => !!resource?.music;
