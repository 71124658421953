import React from 'react';
import { getNumberOfReadersKey, Reader } from 'utils/functions/card';

interface ReadersKeyProps {
  language?: string,
  numberOfReaders?: number
}

export const ReadersKey: React.FC<ReadersKeyProps> = ({language = '', numberOfReaders = 1}) => {
  const keyItems = getNumberOfReadersKey(language, numberOfReaders);

  if (numberOfReaders !== 1 && keyItems.length) {
    return <p className='_EXPORT no-page-break-after' style={{ paddingBottom: 5 }}>
      {
        keyItems.map((item: Reader) =>
          <span className='display-inline me-3' key={item.displayAs}>
            <span className={`display-inline color-red-on-screen`}>{item.displayAs}</span> = {item.label}
          </span>
        )
      }
    </p>;
  }

  return <></>;
}
