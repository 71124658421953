import { css, cx } from '@emotion/css';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { colorDictionary } from 'utils/styles';

type InfoIconProps = {
  className?: string;
  icon: IconDefinition;
  show?: number;
}

export const InfoIcon : React.FC<PropsWithChildren<InfoIconProps>> = ({
  className,
  icon,
  show = 1000,
  children,
}) => <OverlayTrigger
  overlay={<Tooltip id='info-icon-tooltip'>{children}</Tooltip>}
  delay={{show, hide: 0}}
>
  <FontAwesomeIcon
    icon={icon}
    className={cx('info-icon', className, style)}
    style={{cursor: 'pointer'}}
  />
</OverlayTrigger>;

const style = css`
  color: ${colorDictionary['Green']};
`;
