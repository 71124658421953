import { css, cx } from '@emotion/css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useEffect, useRef } from 'react';
import { colorDictionary, flatButton } from 'utils/styles';
import { fontSans, fontSerif } from 'utils/styles/font';

import { Delta, EmitterSource, Op, QuillOptions, Range } from 'quill/core';
import { ForwardedRef, forwardRef, useLayoutEffect } from 'react';

export type QuillEditorProps = {
  className?: string;
  readOnly?: boolean;
  defaultValue?: Delta | Op[] | string;
  onTextChange?: (delta: Delta, oldContent: Delta, source: EmitterSource) => void;
  onSelectionChange?: (range: Range, oldRange: Range, source: EmitterSource) => void;
  /**
   * quill theme; not used after initial render
   */
  theme?: string;
  /**
   * quill modules; not used after initial render
   */
  modules?: QuillOptions['modules'];
  /**
   * quill formats; not used after initial render
   */
  formats?: QuillOptions['formats'];
}

// QuillEditor is an uncontrolled React component
const QuillEditor = forwardRef<Quill | null, QuillEditorProps>(
  ({ theme, className, readOnly, defaultValue, onTextChange, onSelectionChange, modules, formats }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const quillRef = useRef<Quill>();
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const quillOptions = useRef<QuillOptions>({
      theme,
      modules,
      formats,
    })

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      quillRef.current?.enable(!readOnly);
    }, [readOnly]);

    useEffect(() => {
      const container = containerRef.current!;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = quillRef.current = new Quill(editorContainer, quillOptions.current!);

      const value = defaultValueRef.current;
      if (value) {
        if (typeof value === 'string') {
          quill.setContents(quill.clipboard.convert({ html: `<pre>${value}</pre>` }));
        } else {
          quill.setContents(value);
        }
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      updateForwardedRef(quill, ref);

      return () => {
        quillRef.current = undefined;
        updateForwardedRef(null, ref);
        container.innerHTML = '';
      };
    }, [ref]);

    return <div className={cx(className, style)} ref={containerRef}></div>;
  },
);

const updateForwardedRef = <T,>(newValue: T | null, ref?: ForwardedRef<T>) => {
  if (ref) {
    if (typeof ref === 'object') {
      ref.current = newValue;
    } else {
      ref(newValue);
    }
  }
}

QuillEditor.displayName = 'QuillEditor';

export default QuillEditor;
export { QuillEditor };

const style = css({
  backgroundColor: colorDictionary['Input'],
  border: `1px solid ${colorDictionary['Input']}`,
  borderRadius: 10,
  padding: 10,
  transition: `background-color 300ms, border-color 300ms`,
  display: 'flex',
  flexDirection: 'column',
  '.ql-toolbar.ql-snow': {
    backgroundColor: colorDictionary['White'],
    transition: `background-color 300ms`,
    borderRadius: 8,
    padding: 5,
    border: 'none',
    display: 'flex',
    '.ql-formats': {
      display: 'flex',
      marginRight: 0,
      '+ .ql-formats': {
        marginLeft: 15,
      },
    },
    button: [
      flatButton,
      {
        width: 24,
        height: 24,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        '+ button': {
          marginLeft: 5,
        },
        '.quill-icon': {
          width: 14,
          height: 14,
        },
        // rather oddly, the left-align button gets the "active" class
        // whenever the text is NOT left-aligned,
        // so the following reverses the styling specifically for that button
        '&.ql-align:not([value])': {
          '&.ql-active': {
            color: colorDictionary['Primary Text'],
            backgroundColor: 'transparent',
            transition: `background-color 350ms, color 350ms`,
          },
          '&:not(.ql-active)': {
            color: colorDictionary['Primary'],
            backgroundColor: colorDictionary['Primary Light'],
            transition: `background-color 50ms, color 50ms`,
          },
        },
      },
    ],
  },
  '.ql-container.ql-snow': {
    '.ql-editor': {
      margin: '10px 0',
      padding: '0 0 5px 0',
      minHeight: 224,
      overflowY: 'auto',
      fontFamily: fontSerif,
      fontSize: 18,
      ol: {
        paddingLeft: 0,
      },
    },
    border: 'none',
    fontFamily: fontSans,
  },
  '&:focus-within': {
    backgroundColor: colorDictionary['White'],
    borderColor: colorDictionary['Primary Blue'],
    '> .ql-toolbar': {
      backgroundColor: colorDictionary['Input'],
    },
  }
});
