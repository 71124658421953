import { Log, User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { useEffect } from 'react';
import { iamOrigin } from '../env';

Log.setLevel(Log.INFO);
Log.setLogger(console);

export const OIDC_REALM = 'source-and-summit';
export const OIDC_CLIENT_ID = 'liturgy-prep';
export const OIDC_PATHS = {
  loggedIn: '/callback',
  loggedInSilent: '/callback/silent',
  loggedOut: '/',
} as const;

export const OIDC_AUTHORITY = `${iamOrigin}/auth/realms/${OIDC_REALM}`;
export const getRedirectUri = (pathname = window.location.pathname + window.location.search) => {
  const redirectTo = /\/callback/.test( pathname ) ? '' : encodeURIComponent(pathname);
  return `${window.location.origin}${OIDC_PATHS.loggedIn}${redirectTo ? `?redirect-to=${redirectTo}` : ''}`
}

// keep track of whether the user has done a fresh login
// this gets set in App.tsx so that we know whether to check if the token has been revoked by means of a silent signin below
let hasLoggedIn = false;
export const setHasLoggedIn = () => hasLoggedIn = true;

export const initAuthManager = () => {
  const isLogin = window.location.pathname.startsWith(OIDC_PATHS.loggedIn);
  const { pathname, search } = window.location;

  const config: UserManagerSettings =  {
    authority: OIDC_AUTHORITY,
    client_id: OIDC_CLIENT_ID,
    redirect_uri: getRedirectUri(pathname + search),
    silent_redirect_uri: `${window.location.origin}${OIDC_PATHS.loggedInSilent}`,
    post_logout_redirect_uri: `${window.location.origin}${OIDC_PATHS.loggedOut}`,
    revokeTokensOnSignout: true,
    silentRequestTimeoutInSeconds: 5,
  };

  const manager = new UserManager(config);
  if (process.env.NODE_ENV !== 'production') {
    (window as any).userManager = manager;
  }
  manager.clearStaleState();
  // short circuit if we're at the login url, because we don't want to trigger the signinSilent or set up any events
  if (isLogin) { return manager; }

  const signOut = () => {
    manager.removeUser();
    manager.signinRedirect({ redirect_uri: getRedirectUri()});
  };

  if (!hasLoggedIn) {
    manager.getUser().then((user: User | null) => {
      if (user?.refresh_token) {
        // this sign in call is intended
        // to verify any existing jwt token
        // in the users local session storage.
        manager.signinSilent().catch((err) => {
          if (err.error === 'invalid_grant') {
            signOut();
          }
        });
      }
    });
  }

  // this event listener will log the user out
  // if their gant has been revoked
  manager.events.addSilentRenewError(err => {
    console.info({ sre: err })
    if (err.message === 'invalid_grant' || (err as any).error === 'invalid_grant') {
      signOut();
    }
  });

  // this event listener will log the user out
  // if their token expires.
  manager.events.addAccessTokenExpired(signOut);

  // this event listener will watch for logouts
  // that occur outside of this users session
  // while using the app, and log them out.
  manager.events.addUserSignedOut(signOut);

  return manager;
}

let _authManager: UserManager | undefined;
export const useUserManager = ({ skip, resetCache }: { skip?: boolean, resetCache?: () => void}) => {
  const manager =
    _authManager || (skip ? undefined : (_authManager = initAuthManager()));
  useEffect(() => {
    if (manager && resetCache) {
      const resetCacheOnNewUser = (user: User) => {
        const userId = user.profile.sub;
        const lastUserId = localStorage.getItem('userId');
        if (lastUserId !== userId) {
          localStorage.setItem('userId', userId);
          if (lastUserId && resetCache) {
            // only reset the cache if there was a user previously in local storage:
            console.info({lastUserId, userId, message: 'clearing cache...'})
            resetCache();
          }
        }
      }
      // Check if the cache needs to be cleared:
      manager.events.addUserLoaded(resetCacheOnNewUser);
      return () => manager.events.removeUserLoaded(resetCacheOnNewUser);
    }
  },  [resetCache, manager]);
  return manager;
}
