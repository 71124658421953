/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { AddOnId, PlanId, PlanTerm } from 'utils/api-types/plans';
import { WithName } from 'utils/api-types/WithNameAndId';
import { GQLDocument, GQLQuery } from './gql-generics';

// should match plan fragment
export type Plan = {
  id: keyof typeof PlanId;
  price: number | null;
  monthlyRate: number | null;
  annualRate: number | null;
  baseRate: number | null;
  period: number;
  periodTerm: PlanTerm;
  pricingModel: string;
  tiers: Tier[];
} & WithName;

export type Addon = Omit<Plan, 'id'> & {
  id: keyof typeof AddOnId;
}

export type Tier = {
  startingUnit: number;
  endingUnit: number | null;
  price: number;
}

export const PLAN_FRAGMENT = gql`
  fragment Plan on Plan {
    id
    name
    price
    monthlyRate
    annualRate
    baseRate
    period
    periodTerm
    pricingModel
    tiers {
      startingUnit
      endingUnit
      price
    }
  }
`;

export const ADDON_FRAGMENT = gql`
  fragment Addon on Addon {
    id
    name
    price
    monthlyRate
    annualRate
    baseRate
    period
    periodTerm
    pricingModel
    tiers {
      startingUnit
      endingUnit
      price
    }
  }
`;

export interface GetCharterParishSignups extends GQLQuery {
  Variables: never;
  Data: { count: number };
}
export const GetCharterParishSignups: GQLDocument<GetCharterParishSignups> = {
  query: gql`
    query charterParishSignups {
      count: charterParishSignups
    }
  `
}

export interface GetPlan extends GQLQuery {
  Variables: { id: PlanId };
  Data: { plan: Plan };
}
export const GetPlan: GQLDocument<GetPlan> = {
  query: gql`
    query getPlan($id: String!) {
      plan(id: $id) {
        ...Plan
      }
    }
    ${PLAN_FRAGMENT}
  `
}

export interface GetAddon extends GQLQuery {
  Variables: { id: AddOnId };
  Data: { addon: Addon };
}
export const GetAddon: GQLDocument<GetAddon> = {
  query: gql`
    query getAddon($id: String!) {
      addon(id: $id) {
        ...Addon
      }
    }
    ${ADDON_FRAGMENT}
  `
}
