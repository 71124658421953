import { css } from '@emotion/css';
import { colorDictionary as color } from './colorDictionary';

export const flatButton = css({
  color: color['Primary Text'],
  backgroundColor: 'transparent',
  border: 'none',
  transition: 'background-color 350ms, color 350ms',
  cursor: 'pointer',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: color['G1'],
  },
  '&:active,&.active,&.ql-active': {
    transition: 'background-color 50ms, color 50ms',
    color: color['Primary'],
    backgroundColor: color['Primary Light'],
  },
});

export const flatButtonHover = css({
  '&:hover': {
    backgroundColor: color['G1'],
  },
});

export const flatButtonFocus = css({
  '&:focus,&.focused': {
    color: color['Primary'],
    backgroundColor: color['Primary Light'],
  },
});
