// import dayjs and all the plugins we need to use it, then export it and use our augmented version everywhere
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';
import minMax from 'dayjs/plugin/minMax';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(dayOfYear);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);
dayjs.prototype.toLocalDate = function (this: dayjs.Dayjs) {
  const local = this.local();
  return local.subtract(local.utcOffset(), 'minutes').toDate();
};
declare module 'dayjs' {
  interface Dayjs {
    toLocalDate(): Date;
  }
}
export default dayjs;
