import { CollectionComposition } from 'queries/collection';

export const getOrdinaryReferenceCollection = (composition?: CollectionComposition) => {
  const collection = composition?.collectionCompositionDisplayData.find(({ key })=>key==='collection')?.value;
  if (!collection) {
    return undefined;
  }
  const match = /^Source (?:&|and) Summit Missal 20(\d\d)$/.exec(collection);
  return (match && `SSM${match[1]}`) ?? undefined;
}
