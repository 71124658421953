import { css, cx } from '@emotion/css';
import { ExportRenderer } from 'components/ordo/modals/export/ExportRenderer';
import { ShareCard } from 'components/share/ShareCard';
import { LayoutContext } from 'context/LayoutContext';
import { useLogException } from 'hooks/error/useLogError';
import { useExportOrdo } from 'hooks/export/useExportOrdo';
import { useFilteredSortedOrdoCards } from 'hooks/export/useFilteredSortedOrdoCards';
import { useSortedPdfCards } from 'hooks/export/useSortedPdfCards';
import { useSizeMultiplier } from 'hooks/useSizeMultiplier';
import { isImageDimensions, LayoutCard } from 'queries/layout-file';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import dayjs from 'utils/dayjs';
import { ExportFile } from 'utils/export';
import {
  blankPdfBlockPage,
  PdfBlock,
  PdfCardBlocks,
  PdfTextSpan
} from 'utils/export/PdfBlockTypes';
import { PDFDocumentWithColumns } from 'utils/export/PdfFormattedText';
import { convertToPdfBlocks } from 'utils/export/convertToPdfBlocks';
import { toPt } from 'utils/export/exportDimensionSettings';
import { getExportFilenames } from 'utils/export/getExportFilenames';
import { PageNumberInfo } from 'utils/export/pdfColumnTools';
import { paginate } from 'utils/export/pdfPaginate';
import { getPdfBlob, makePdfDocument } from 'utils/export/pdfTools';
import { replacePdfAttachments } from 'utils/export/replacePdfAttachments';
import { shufflePdf } from 'utils/export/shufflePdf';
import { fontSerifCss } from 'utils/styles';
import { Dictionary } from 'utils/typescript/Dictionary';

// this is based on 590px content display being exported on a 5.5 inch width
// effectively resulting in 107 ppi (slightly more than the standard 96ppi)
const getContentWidthForInches = (inches: number) => inches * 590 / 5.5;
const getContentWidthStringForInches = (inches: number) => `${getContentWidthForInches(inches)}px`;

export const useLayoutRenderer = ({ extraPageCount, pageSpreadFolds }: {
  extraPageCount?: { [cardId: string]: number };
  pageSpreadFolds?: false | 2 | 3
} = {}) => {
  const logException = useLogException();
  const cardsRef = useRef<HTMLDivElement>(null);
  const layoutCardsRef = useRef<HTMLDivElement>(null);
  const {
    ordo,
    enabledCards,
    disabledCardIds,
    contentWidth,
    harmonizedContentWidth,
    overrideConfigMap,
    effectivePageWidth: pageWidth,
    effectiveBottomMargin: marginBottom,
    activeFile: {
      config,
      pageHeight,
      marginTop,
      marginLeft,
      marginRight,
      cards: layoutCards,
      name: filename,
    },
    setPdfCardIds,
    pdfCardIds,
  } = useContext(LayoutContext);
  const isImages = isImageDimensions({ pageWidth, pageHeight });
  const includeOutline = config.includeOutline ?? true;
  const breakEveryCard = isImages ? true : config.breakEveryCard ?? true;
  const displayDate = config.displayDate ?? true;
  const ordoCards = useFilteredSortedOrdoCards(enabledCards);
  const [cardBlocks, setCardBlocks] = useState<Dictionary<PdfCardBlocks>>({});
  const [layoutCardBlocks, setLayoutCardBlocks] = useState<Dictionary<PdfCardBlocks>>({});
  const layoutCardMap = useMemo(() => new Map(layoutCards.map(card => [getLayoutCardId(card), card])), [layoutCards]);

  const updateLayoutCardBlocks = useCallback(() => {
    const layoutCardImgs = layoutCardsRef.current!.children as HTMLCollectionOf<HTMLImageElement>;
    setLayoutCardBlocks(blocks => {
      for (const img of layoutCardImgs) {
        const imgId = img.id;
        const src = img.src;
        const oldBlocks = blocks[imgId];
        const firstBlock = oldBlocks?.blocks[0];
        const cardConfig = layoutCardMap.get(imgId)?.config;
        const options: PdfBlock['options'] = { align: cardConfig?.alignment ?? 'center' };
        if (firstBlock?.src === src && firstBlock?.width) {
          // hasn't changed, so just keep what we've already got in the dictionary:
          continue;
        }
        const updateBlocks = (blocks: Dictionary<PdfCardBlocks>) => ({ ...blocks, ...{ [imgId]: { blocks: convertToPdfBlocks(img, options) } } });
        if (img.clientHeight) {
          blocks = updateBlocks(blocks);
        } else {
          // if it doesn't have a height yet, we need to wait until it's downloaded:
          img.onload = () => setLayoutCardBlocks(updateBlocks);
        }
      }
      return blocks;
    });
  }, [layoutCardMap]);

  const layoutCardImgAlignments = layoutCards.map(card => card.config.alignment).join(',');
  useEffect(() => {
    setLayoutCardBlocks(blocks => {
      for (const [imgId, imgCard] of layoutCardMap) {
        const imgBlock = blocks[imgId]?.blocks?.[0];
        if (imgBlock) {
          const { config } = imgCard;
          imgBlock.options = config?.alignment
            ? { align: config?.alignment }
            : undefined;
        }
      }
      return { ...blocks };
    })
  }, [layoutCardImgAlignments, layoutCardMap]);

  useEffect(() => {
    updateLayoutCardBlocks();
    const observer = new MutationObserver(() => updateLayoutCardBlocks());
    observer.observe(layoutCardsRef.current!, { subtree: true, childList: true, attributes: true });
    return () => {
      observer.disconnect();
    }
  }, [updateLayoutCardBlocks]);

  const [pdf, setPdf] = useState<PDFDocumentWithColumns>()
  
  useEffect(() => {
    (async () => {
      const localPdf = pdf || await makePdfDocument();
      if (pdf) {
        pdf.options.size = [toPt(pageWidth), toPt(pageHeight ?? 1000)];
        pdf.options.margins = {
          top: toPt(marginTop ?? 0),
          bottom: toPt(marginTop ?? 0),
          left: toPt(marginLeft ?? 0),
          right: toPt(marginRight ?? 0),
        }
        delete pdf.pageDimensions;
      }
      if (!pdf) setPdf(localPdf);
    })();
  }, [pdf, pageHeight, pageWidth, marginTop, marginBottom, marginLeft, marginRight]);

  const unsizedSortedCardBlocks = useSortedPdfCards(cardBlocks, ordo, {
    layoutCardBlocks,
    layoutCardMap,
    overrideConfigMap,
    crop: false,
    isPngExport: false,
    isFullOrdoExport: true,
    includeOutline,
    contentWidth,
    harmonizedContentWidth,
    disabledCardIds,
    pdfCardIds,
  });

  const sizeMultiplier = useSizeMultiplier() ?? 1;
  const sortedCardBlocks = useMemo(() => {
    const recursivelyMapBlock = makeRecursiveBlockMapper(sizeMultiplier);
    return sizeMultiplier === 1
      ? unsizedSortedCardBlocks
      : unsizedSortedCardBlocks?.map(page => ({
        ...page,
        blocks: page.blocks.map(recursivelyMapBlock)
      }));
  }, [unsizedSortedCardBlocks, sizeMultiplier]);
  const [attachments, setAttachments] = useState<ExportFile[]>([]);
  const fileNames = useMemo(
    () =>
      getExportFilenames({
        ordo,
        crop: false,
        includeOutline,
        addGrandStaff: false,
      }),
    [ordo, includeOutline],
  );
  const pdfAttachments = useMemo(
    () => attachments.filter(({ fileExt }) => fileExt === 'pdf'),
    [attachments],
  );

  useEffect(() => {
    setPdfCardIds(new Set(pdfAttachments.map(pdf => pdf.key)));
  }, [pdfAttachments, setPdfCardIds]);
  const pages = useMemo(() => {
    if (!pdf || !sortedCardBlocks) {
      return emptyPagesArray;
    }
    const contentWidthPixels = getContentWidthForInches(contentWidth)
    const pages = paginate({ pages: sortedCardBlocks, pdf, breakEveryCard, contentWidthPixels });
    const keyToPage = new Map(pages.filter(p => p.containers.length === 1).map(p => [p.containers[0].key, p]));
    for (const pdf of pdfAttachments) {
      // find page to replace
      const page = keyToPage.get(pdf.key);
      if (page) {
        page.content = pdf.content;
      }
    }
    return pages ?? emptyPagesArray;
  }, [pdf, sortedCardBlocks, contentWidth, breakEveryCard, pdfAttachments]);

  const pagesLength = pages.length;
  const sheetIndices = useMemo(() => {
    if (!pageSpreadFolds) return;
    const pagesPerSheet = pageSpreadFolds * 2;
    const sheetCount = Math.ceil(pagesLength / pagesPerSheet);
    const fullPagesLength = sheetCount * pagesPerSheet;
    const sheets = Array(sheetCount).fill(0);
    if (pageSpreadFolds === 2) {
      let j = fullPagesLength - 1;
      let i = 0;
      return sheets.map(() => [j--, i++, i++, j--]);
    } else if (pageSpreadFolds === 3) {
      return sheets.map((_, sheetI) => {
        const i = sheetI * 6;
        return config.triFoldAccordion
          ? [i, i + 1, i + 2, i + 3, i + 4, i + 5]
          : [i + 1, i + 2, i + 3, i + 4, i + 5, i];
      });
    }
  }, [config.triFoldAccordion, pageSpreadFolds, pagesLength]);
  const shuffledPages = useMemo(
    () =>
      sheetIndices
        ?.flat()
        .map((i) => ({
          ...(pages[i] ?? blankPdfBlockPage),
          originalPageNumber: i,
        })) ?? pages,
    [pages, sheetIndices],
  );
  const subtitle = (config.subtitle || ordo.name || '').replace(/^\s+$/, '');
  const saveFiles = useExportOrdo();
  const pageNumberInfo = useMemo<PageNumberInfo | undefined>(
    () => (config.pageNumbers ? { extraPageCount } : undefined),
    [config.pageNumbers, extraPageCount],
  );
  const downloadPdf = useCallback(() => {
    if (isImageDimensions({ pageWidth, pageHeight })) {
      // TODO: implement download PDF for images
      return;
    }
    const pages = sortedCardBlocks!;
    getPdfBlob({ pages, contentWidthPixels: getContentWidthForInches(contentWidth) }, {
      margins: {
        top: toPt(marginTop!),
        right: toPt(marginRight!),
        bottom: toPt(marginBottom!),
        left: toPt(marginLeft!),
      },
      size: [toPt(pageWidth), toPt(pageHeight!)],
      breakEveryCard,
      logException,
      pageNumbers: pageNumberInfo,
    }).then(async ({ blob: content, cardPages }) => {
      if (pdfAttachments.length) {
        try {
          content = await replacePdfAttachments({pdfBlob: content, pdfAttachments, cardPages });
        } catch (e) { }
      }
      if (sheetIndices) {
        content = await shufflePdf(content, sheetIndices);
      }
      saveFiles([{
        content,
        key: 'master',
        name: `${filename}.pdf`,
      }], filename);
    });
  }, [breakEveryCard, contentWidth, filename, logException, marginBottom, marginLeft, marginRight, marginTop, pageHeight, pageNumberInfo, pageWidth, pdfAttachments, saveFiles, sheetIndices, sortedCardBlocks]);

  return {
    downloadPdf,
    pages: shuffledPages,
    sortedCardBlocks,
    fileNames,
    element: (
      <div
        className={style}
        style={{
          height: 0,
          width: 0,
          overflow: 'hidden',
        }}
      >
        <ExportRenderer
          ordo={ordo}
          onRenderComplete={setCardBlocks}
          cards={ordoCards}
          displayName={config.liturgy || ordo.liturgyDisplayName}
          displaySubTitle={
            subtitle +
            (subtitle && displayDate ? ` · ` : '') +
            (displayDate ? dayjs.utc(ordo.eventDate).format('MMMM D, YYYY') : '')
          }
          displayThirdLine={config.parish ?? undefined}
          accountId={ordo.accountId}
          setAttachments={setAttachments}
          showRubrics={true}
          fileNames={fileNames}
        />
        <div
          className={cx('layout-cards', style)}
          ref={cardsRef}
          style={{
            ...({
              '--content-width': getContentWidthStringForInches(contentWidth),
              '--harmonized-content-width': getContentWidthStringForInches(
                harmonizedContentWidth,
              ),
            } as {}),
          }}
        >
          {ordoCards.map(card => (
            <ShareCard className="layout-card" card={card} key={card.id} />
          ))}
          <div ref={layoutCardsRef} style={{ width: getContentWidthForInches(contentWidth) }}>
            {layoutCards.map(card => (
              <img
                style={{
                  width: `${(card.config?.width ?? 1) * 100}%`
                }}
                id={getLayoutCardId(card)}
                key={card.id}
                crossOrigin='anonymous'
                className='_EXPORT'
                src={card.file.url}
                alt='User uploaded content'
              />
            ))}
          </div>
        </div>
      </div>
    )
  };
};

const style = css`
  .combined-ordo-card-attributions {
    width: var(--content-width);
  }

  .layout-cards {
    .layout-card {
      max-width: unset;
      width: var(--content-width);
    }
    .layout-card.extra-content-width {
      width: var(--harmonized-content-width);
    }
    .card-title {
      ${fontSerifCss}
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
      margin: 0;
    }
  }
`;

export const getLayoutCardId = (card: LayoutCard): string => `layout-image-card-${card.id}`;
export const getLayoutCardIdFromStringId = (id?: string) => Number(id?.match(/^layout-image-card-(\d+)$/)?.[1]);

const makeRecursiveBlockMapper = (sizeMultiplier = 1) => {
  const recursivelyMapBlock = <T extends (PdfBlock | PdfTextSpan) & { lines?: any; width?: number; spaceWidth?: number; height?: number; }>({ fontSize, spans, lines, width, spaceWidth, height, ...block }: T): T => ({
    ...block,
    ...(fontSize ? {
      fontSize: sizeMultiplier * fontSize,
    } : {}),
    ...(spans ? {
      spans: spans.map(recursivelyMapBlock),
      // we keep width and height if it was a block with spans, but clear them from spans, where they are related to font size
      ...( (width ?? null) === null ? {} : { width }),
      ...( (height ?? null) === null ? {} : { height }),
    } : {}),
  }) as T;
  return recursivelyMapBlock;
}

const emptyPagesArray = [] as const;
