import {
  PDFPage
} from '@cantoo/pdf-lib';
import { loadPdfLib } from 'utils/export/pdfTools';

/**
 * Make a new PDF 
 * @param pdfBlob source PDF blob
 * @param sheetIndices array of arrays of page number indices for each sheet
 * @returns 
 */
export const shufflePdf = async (
  pdfBlob: Blob,
  sheetIndices: number[][],
) => {
  const { PDFDocument } = await loadPdfLib();
  const sourceDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());
  const pages = sourceDoc.getPages();
  
  const doc = await PDFDocument.create();
  const embeddedPages = await doc.embedPages(pages);
  const { width, height } = pages[0].getSize();
  const pagesPerSheet = (sheetIndices[0]?.length ?? 0) >> 1;
  const sheetWidth = width * pagesPerSheet;
  for (const indices of sheetIndices) {
    let pageI = 0;
    let page: PDFPage | undefined = undefined;
    for (const index of indices) {
      pageI %= pagesPerSheet;
      // first half go on one page, second half are on the second / reverse side
      if (pageI === 0 || !page) {
        page = doc.addPage([sheetWidth, height]);
      }
      if (pages[index]) {
        page.drawPage(embeddedPages[index], { x: pageI * width });
      }
      ++pageI;
    }
  }
  return new Blob([await doc.save()]);
};
