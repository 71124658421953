import { shim as flatMapShim } from 'array.prototype.flatmap';
import { LoadingScreen } from 'components/shared/LoadingScreen';
import { pricingUrl } from 'hooks/usePricingUrl';
import { User } from 'oidc-client-ts';
import { AppWithoutCallback } from 'pages/Lazy';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import {
  matchPath
} from 'react-router-dom';
import asyncAppSync from 'utils/asyncAppSync';
import { setHasLoggedIn, useUserManager } from 'utils/authorization/initAuthManager';
import { isInIframe } from 'utils/isInIframe';
import { JwtUser } from 'utils/jwt/parseJwt';
import { paths } from 'utils/paths';
import { initApolloClient } from './utils/apollo/initApolloClient';
import { HelmetProvider } from 'react-helmet-async';

// shim Array.flatMap for any browsers that don't natively support it:
flatMapShim();

const { client, cachePromise } = initApolloClient();
const resetCache = () => client?.resetStore();

const App: React.FC = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const skipAuth = !!matchPath(currentPath, [
    paths.not_found,
    paths.share + '/:id',
    paths.marketingShare + '/:id',
    paths.network_error,
    paths.login_error,
  ]);
  const userManager = useUserManager({ skip: skipAuth, resetCache });

  useEffect(() => {
    const appSyncPromise = asyncAppSync();
    import('@aws-amplify/core').then(async ({ Amplify }) => Amplify.configure((await appSyncPromise).config));
  }, []);

  const handleLogin = useCallback((user: User | void) => {
    if (!user) return;
    setHasLoggedIn();
    if (isInIframe) {
      window.parent.postMessage(window.location.href, window.location.origin);
      return;
    }
    const jwt = user?.profile as JwtUser;
    const hasGroup = jwt.groups && jwt.groups.length > 0;
  
    if (hasGroup) {
      // User belongs to a group, and has active subscription
      // Redirect to the dashboard
  
      // see if we can find a redirect-to
      // param in the url
      const urlParams = new URLSearchParams(window.location.search);
      let redirectTo = urlParams.get('redirect-to');
      if (redirectTo?.startsWith(paths.sign_out)) {
        redirectTo = null;
      }
  
      const redirect = redirectTo || paths.dashboard;
      window.location.replace(redirect);
      setCurrentPath(redirect);
    } else {
      window.location.assign(pricingUrl);
    }
  }, [setCurrentPath]);
  
  const [cacheRestored, setCacheRestored] = useState(false);
  useEffect(() => {
    if (cachePromise) {
      cachePromise.then(() => setCacheRestored(true));
    } else {
      setCacheRestored(true);
    }
  }, [setCacheRestored]);

  return (
    <AuthProvider userManager={userManager} onSigninCallback={handleLogin}>
      <HelmetProvider>
        <Suspense fallback={<LoadingScreen />}>
          <AppWithoutCallback
            cacheRestored={cacheRestored}
            client={client!}
            userManager={userManager}
          />
        </Suspense>
      </HelmetProvider>
    </AuthProvider>
  );
};

export default App;
