import { UserManager } from 'oidc-client-ts';
import { useCallback } from 'react';
import { getRedirectUri } from 'utils/authorization/initAuthManager';

// Returns a callback function that can be used to refresh the JWT token
export const useRefreshJwt = (userManager?: UserManager) => {
  return useCallback(async (path: string = window.location.pathname + window.location.search) => {
    await userManager?.removeUser();
    return userManager?.signinRedirect({ redirect_uri: getRedirectUri(path)}); // Reloads the JWT and triggers the signin redirect
  }, [userManager])
};
