import React from "react";

interface CanonicalSourceProps {
  sourceNames?: string[];
}

export const CanonicalSource: React.FC<CanonicalSourceProps> = ({
  sourceNames,
}) => {

  const uniqueSources = Array.from(new Set(sourceNames));

  return (
    <span className="font-normal">
      {!!sourceNames?.length && <>
        {uniqueSources.join(', ')}
      </>}
    </span>
  );
};
