import { css, cx } from '@emotion/css';
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons/faSparkles';
import { InfoIcon } from 'components/shared/InfoIcon';
import { AuthContext } from 'context/AuthContext';
import { permissionSetToRoleArgument, useHasPermissionSet } from 'hooks/permission-set/useHasPermissionSet';
import { RoleArgument, UserRoles, doesGroupHaveRoles, matchRoles, useHasRole } from 'hooks/permission-set/useHasRole';
import { getSelectedGroup, useUser } from 'hooks/useUser';
import { PermissionSet } from 'queries/permission-set';
import { useCallback, useContext } from 'react';
import { GatedFeature } from 'utils/api-types/PermissionSetId';
import { GroupInfo } from 'utils/jwt/GroupInfo';
import { ExclusiveUnion } from 'utils/typescript/ExclusiveUnion';
import { usePermissionSet } from './usePermissionSet';

// re-export GatedFeature from here
export { GatedFeature } from 'utils/api-types/PermissionSetId';

export type FeatureGateArgs = {
  expressedInterest?: string;
} & ExclusiveUnion<{
  feature: GatedFeature;
} | {
  permissionSet?: PermissionSet | null | undefined;
} | {
  forceUpgradeModal: true;
}>;

export type FeatureGateResult = {
  verified: boolean;
  icon?: React.ReactNode;
  showUpgradeModal?: ((e?: React.MouseEvent) => void) | undefined;
  showUpgradeModalToEssentials?: ((e?: React.MouseEvent) => void) | undefined;
}

const iconStyle = css`
  height: 16px;
`;

export const featureUpgradeIcon = (group?: GroupInfo | null) => <InfoIcon
  icon={faSparkles}
  className={cx('feature-upgrade-icon', iconStyle)}
>
  This is part of the {groupPlanName(group)} for the digital platform.
</InfoIcon>;

export const useFeatureUpgradeIcon = () => featureUpgradeIcon(getSelectedGroup(useUser()));

const groupPlanName = (group?: GroupInfo | null) => {
  return !!group && doesGroupHaveRoles(group, { any: [UserRoles.MissalSupport, UserRoles.MissalCompanion] })
    ? 'Complete Digital Add-on'
    : 'Standard Plan';
}

export const isGroupInTrial = (group?: GroupInfo | null) => !!group && doesGroupHaveRoles(group, { role: UserRoles.TrialUser });
export const rolesAreWithinBasicTier = (roleArgument: RoleArgument) => matchRoles([UserRoles.EssentialsUser], roleArgument, true);

export const useFeatureGate = ({
  expressedInterest,
  feature,
  permissionSet,
  forceUpgradeModal,
}: FeatureGateArgs = {}): FeatureGateResult => {
  // query for feature
  const permissionSetForFeature = usePermissionSet(feature);

  // user data to pass to role check
  const user = useUser();
  const group = getSelectedGroup(user);

  const isInTrial = isGroupInTrial(group);

  // when something is actually meant to be filtered out (i.e., we have an active feature or permission set)
  const doFiltering = !!(forceUpgradeModal || feature || permissionSet);

  const effectivePermissionSet = permissionSet ?? permissionSetForFeature;
  const hasPermission = useHasPermissionSet(effectivePermissionSet, false);
  const isBasic = useHasRole({ role: UserRoles.BasicUser });

  const verified =
    // skipping filtering means this should just verify
    !doFiltering ||
    // trial users should get everything
    isInTrial ||
    // otherwise, validate against the user's roles
    hasPermission;

  const { openUpsellModal } = useContext(AuthContext);
  let showUpgradeModal: FeatureGateResult["showUpgradeModal"] = useCallback((e?: React.MouseEvent) => {
    e?.preventDefault();
    if (expressedInterest) {
      openUpsellModal?.(expressedInterest);
    }
  }, [expressedInterest, openUpsellModal]);
  let showUpgradeModalToEssentials: FeatureGateResult["showUpgradeModal"] = useCallback((e?: React.MouseEvent) => {
    e?.preventDefault();
    if (expressedInterest) {
      openUpsellModal?.(expressedInterest, true);
    }
  }, [expressedInterest, openUpsellModal]);
  if (!expressedInterest) {
    // if there is no expressed interest, the upgrade modal is unusable, and therefore not returned
    showUpgradeModal = undefined;
    showUpgradeModalToEssentials = undefined;
  }

  const roleArgument = effectivePermissionSet ? permissionSetToRoleArgument(effectivePermissionSet) : { any: [] };

  const showTrialIcon =
    // show it to trial users
    isInTrial &&
    // only show when we are actually doing filtering
    doFiltering &&
    // if the thing being gated would NOT show to someone with just missal support or essentials
    !rolesAreWithinBasicTier(roleArgument);

  return {
    verified,
    icon: !verified || showTrialIcon ? featureUpgradeIcon(group) : undefined,
    showUpgradeModal: !verified ? showUpgradeModal : undefined,
    showUpgradeModalToEssentials: (!verified && isBasic) ? showUpgradeModalToEssentials : undefined,
  }
};
