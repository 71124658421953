/* eslint-disable @typescript-eslint/no-redeclare */
import { ApolloCache, gql } from '@apollo/client';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { CARD_FRAGMENT, CARD_FRAGMENT_WITH_MEI, OrdoCard, OrdoCardWithMei } from './card-fragment';
import { GQLDocument, GQLMutation, GQLMutationUpdater, GQLQuery } from './gql-generics';
import { GetOrdo, GetOrdoCardSvg } from './ordo';
import { createMutationCacheUpdater } from './updaters/createMutationCacheUpdater';
import { OrdoCardConfigInput } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { FileResource } from 'utils/api-types/resource';
import { getScreenWidthForEngravings } from 'utils/ordo/getScreenWidthForEngravings';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { TextCategoriesWithCardTypeSeedId } from 'utils/api-types/TextCategoriesSeedIdWithCardType';
import { Language } from 'utils/api-types/Language';

////////////
/// QUERIES

export interface CardInspect extends GQLQuery {
  Variables: WithId;
  Data: { inspect: object[] };
}
export const CardInspect: GQLDocument<CardInspect> = {
  query: gql`
    query inspect($id: Float!) {
      inspect(id: $id)
    }
  `
}

type CardResourceMap = {
  resource: FileResource,
  cardId: number,
};

export interface GetResourcesForCards extends GQLQuery {
  Variables: { ids: number[] }
  Data: { getResourcesForCards: CardResourceMap[] };
}
export const GetResourcesForCards: GQLDocument<GetResourcesForCards> = {
  query: gql`
    query getResourcesForCards($ids: [Float!]!) {
      getResourcesForCards(ids: $ids) {
        resource {
          file {
            url
            awsKey
            id
          }
        }
        cardId
      }
    }
  `
}

// this is unused
export interface GetCard extends GQLQuery {
  Variables: WithId;
  Data: { card: OrdoCard };
}
export const GetCard: GQLDocument<GetCard> = {
  query: gql`
    query getCard($id: Float!) {
      card(id: $id) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `
}

////////////////////////
/// CACHE UPDATER UTILS

// this defines the minimum fields the query result must have to qualify for use in this hook
interface MutationDataWithCard {
  card: {
    id: number,
    ordoId: number,
  }
}

// function used to actually update the ordo with the new card
type CachedOrdoUpdateLogicFn<TData extends MutationDataWithCard> = (
  draft: GetOrdo['Data'],
  cardIndex: number,
  card: TData['card']
) => void;

/// ORDO UPDATE LOGIC

// This applies logic that applies to multiple mutations, and inserts a given bit of logic (ordoUpdateLogic) within that
const createSharedUpdateLogic = <TData extends MutationDataWithCard>(
  ordoUpdateLogic: CachedOrdoUpdateLogicFn<TData>
) => (
  draft: GetOrdo['Data'],
  mutationData: TData,
) => {
  const cardIdx = draft.ordo.cards.findIndex((card) => card.id === mutationData.card.id);

  if (cardIdx === undefined || cardIdx < 0) {
    return false;
  } else {
    ordoUpdateLogic(draft, cardIdx, mutationData.card);
  }

  draft.ordo.cards.sort((c1, c2) => c1.sortPosition - c2.sortPosition);
  return true;
}

// used for mutations which return a whole card that should be replaced in the cache (see CardMutationData in 'queries/card.ts')
const defaultOrdoUpdateLogic: CachedOrdoUpdateLogicFn<CardMutationData> = (draft, cardIndex, card) => {
  draft.ordo.cards[cardIndex] = card;
}

// used for mutations which enable or disable a card (see CardToggleMutationData in 'queries/card.ts')
const toggleOrdoUpdateLogic: CachedOrdoUpdateLogicFn<CardToggleMutationData> = (draft, cardIndex, card) => {
  draft.ordo.cards[cardIndex].enabled = card.enabled;
}

const getQueryVariables = (data: MutationDataWithCard) => {
  return { id: data.card.ordoId }
}

const removeCardSvgFromCache: GQLMutationUpdater<UpdateCardConfig|RenameCard|SelectCardResourceGroup|UpdateCardType, UpdateCardConfig|RenameCard|SelectCardResourceGroup|UpdateCardType> = (cache: ApolloCache<any>, result, options ) => {
  const { variables = {} } = options;
  const id = 'cardId' in variables ? variables.cardId as number : 'id' in variables ? variables.id as number : undefined;
  if (id) {
    cache.updateQuery<GetOrdoCardSvg['Data'], GetOrdoCardSvg['Variables']>({
      query: GetOrdoCardSvg.query,
      variables: { id, screenWidth: getScreenWidthForEngravings(Infinity), dynamicHymnScaling: false, textScale: 1 }
    }, (data) => {
      if (!data) return data;
      // pretend that the data is from local storage cache so that it will get ignored when downloading the card svgs
      return { card: { ...data.card, cached: true } };
    });
  }
}
const cardMutationCacheUpdater = createMutationCacheUpdater(GetOrdo, getQueryVariables, createSharedUpdateLogic(defaultOrdoUpdateLogic));
const cardToggleCacheUpdater = createMutationCacheUpdater(GetOrdo, getQueryVariables, createSharedUpdateLogic(toggleOrdoUpdateLogic));

const cardMutationCacheUpdaterWithSvgDeletion: typeof removeCardSvgFromCache = (...args) => {
  removeCardSvgFromCache(...args);
  cardMutationCacheUpdater(args[0], args[1], args[2] as any);
}

//////////////
/// MUTATIONS

// general return type for card mutations, since they mostly return full cards
// so they can be updated in the same way
export type CardMutationData = { card: OrdoCard };
export type CardMutationDataWithMei = { card: OrdoCardWithMei };

export interface RepositionCard extends GQLMutation<RepositionCard, GetOrdo> {
  Variables: {
    id: number;
    sortPosition: number;
  };
  Data: CardMutationData;
}
export const RepositionCard: GQLDocument<RepositionCard> = {
  mutation: gql`
    mutation repositionCard($id: Float!, $sortPosition: Float!) {
      card: repositionCard(id: $id, sortPosition: $sortPosition) {
        ...OrdoCardWithMei
      }
    }
    ${CARD_FRAGMENT_WITH_MEI}
  `,
  updater: cardMutationCacheUpdater,
}

export interface CreateCard extends GQLMutation<CreateCard, GetOrdo> {
  Variables: {
    ordoId: number;
    type?: CardTypes;
    name?: string;
    textCategoryName?: TextCategoriesWithCardTypeSeedId | undefined;
    afterTextCategory?: string|undefined;
    atEnd?: boolean;
  };
  Data: CardMutationData;
}
export const CreateCard: GQLDocument<CreateCard> = {
  mutation: gql`
    mutation createCard($ordoId: Float!, $type: String, $name: String, $textCategoryName: String, $afterTextCategory: String, $atEnd: Boolean) {
      card: createCard(ordoId: $ordoId, type: $type, name: $name, textCategoryName: $textCategoryName, afterTextCategory: $afterTextCategory, atEnd: $atEnd) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(GetOrdo, getQueryVariables, (draft, mutationData: CreateCard['Data']) => {
    const cardId = mutationData.card.id;
    if (!draft.ordo.cards.some(({ id }) => id === cardId)) {
      // card is not already in the ordo, so splice it in:
      const index = draft.ordo.cards.findIndex(card => card.sortPosition > mutationData.card.sortPosition);
      draft.ordo.cards.splice(index < 0 ? draft.ordo.cards.length : index, 0, mutationData.card);
      return true;
    }
    return false;
  }),
}

export interface RemoveCard extends GQLMutation<RemoveCard, GetOrdo> {
  Variables: WithId;
  Data: {
    removeCard: {
      deleted: boolean;
      card: {
        id: number;
        ordoId: number;
        enabled: boolean;
      }
    }
  };
}
export const RemoveCard: GQLDocument<RemoveCard> = {
  mutation: gql`
    mutation removeCard($id: Float!) {
      removeCard(id: $id) {
        deleted
        card {
          id
          ordoId
          enabled
        }
      }
    }
  `,
  updater: createMutationCacheUpdater(
    GetOrdo,
    data => ({ id: data.removeCard.card.ordoId }),
    (draft, { removeCard }) => {
      if (removeCard.deleted) {
        // card was deleted, remove it
        const cardIdx = draft.ordo.cards.findIndex((card) => card.id === removeCard.card.id);
      
        if (cardIdx === undefined || cardIdx < 0) {
          return false;
        }
    
        draft.ordo.cards.splice(cardIdx, 1);
      }
      // otherwise, the card was disabled, & it will be updated automatically in the cache
      return true;
  }),
}

export interface RenameCard extends GQLMutation<RenameCard, GetOrdo> {
  Variables: WithNameAndId;
  Data: CardMutationData;
}
export const RenameCard: GQLDocument<RenameCard> = {
  mutation: gql`
    mutation renameCard($id: Float!, $name: String!) {
      card: renameCard(id: $id, name: $name) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `,
  updater: cardMutationCacheUpdaterWithSvgDeletion,
}

export type CardToggleMutationData = {
  card: {
    enabled: boolean;
    ordoId: number;
  } & WithId;
}

export interface EnableCard extends GQLMutation<EnableCard, GetOrdo> {
  Variables: WithId;
  Data: CardToggleMutationData;
}
export const EnableCard: GQLDocument<EnableCard> = {
  mutation: gql`
    mutation enableCard($id: Float!) {
      card: enableCard(id: $id) {
        id
        ordoId
        enabled
      }
    }
  `,
  updater: cardToggleCacheUpdater,
}

export interface UpdateCardConfig extends GQLMutation<UpdateCardConfig, GetOrdo> {
  Variables: {
    config: OrdoCardConfigInput;
    ordoRoleId?: number;
  } & WithId;
  Data: CardMutationDataWithMei;
}
export const UpdateCardConfig: GQLDocument<UpdateCardConfig> = {
  mutation: gql`
    mutation updateConfig($id: Float!, $config: OrdoCardConfigInput!, $ordoRoleId: Float) {
      card: updateConfig(id: $id, config: $config, ordoRoleId: $ordoRoleId) {
        ...OrdoCardWithMei
      }
    }
    ${CARD_FRAGMENT_WITH_MEI}
  `,
  updater: cardMutationCacheUpdaterWithSvgDeletion,
}

export interface UpdateCardType extends GQLMutation<UpdateCardType, GetOrdo> {
  Variables: {
    type: string | null;
  } & WithId;
  Data: CardMutationDataWithMei;
}
export const UpdateCardType: GQLDocument<UpdateCardType> = {
  mutation: gql`
    mutation updateCardType($id: Float!, $type: String) {
      card: updateCardType(id: $id, type: $type) {
        ...OrdoCardWithMei
      }
    }
    ${CARD_FRAGMENT_WITH_MEI}
  `,
  updater: cardMutationCacheUpdaterWithSvgDeletion,
}

export interface SelectCardResourceGroup extends GQLMutation<SelectCardResourceGroup, GetOrdo> {
  Variables: {
    cardId: number;
    resourceGroupId: number;
  };
  Data: CardMutationData;
}
export const SelectCardResourceGroup: GQLDocument<SelectCardResourceGroup> = {
  mutation: gql`
    mutation selectCardResourceGroup($cardId: Float!, $resourceGroupId: Float!) {
      card: selectResourceGroupForCard(cardId: $cardId, resourceGroupId: $resourceGroupId) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `,
  updater: cardMutationCacheUpdaterWithSvgDeletion,
}

export interface SetCardTranslations extends GQLMutation<SetCardTranslations> {
  Variables: {
    cardId: number;
    noteIds: number[];
    resourceId: number;
  };
  Data: CardMutationData;
}
export const SetCardTranslations: GQLDocument<SetCardTranslations> = {
  mutation: gql`
    mutation setCardTranslations($cardId: Float!, $noteIds: [Float!]!, $resourceId: Float!) {
      card: setCardTranslations(cardId: $cardId, noteIds: $noteIds, resourceId: $resourceId) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `
  // NOTE: the corresponding mutation hook for this that I removed did not have a cache updater associated
  // but perhaps it should have
}

export interface SetPrimaryResource extends GQLMutation<SetPrimaryResource> {
  Variables: {
    cardId: number;
    resourceId?: number | null;
  };
  Data: CardMutationData;
}
export const SetPrimaryResource: GQLDocument<SetPrimaryResource> = {
  mutation: gql`
    mutation setPrimaryResource($cardId: Float!, $resourceId: Float) {
      card: setPrimaryResource(cardId: $cardId, resourceId: $resourceId) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `
  // NOTE: as above, the corresponding mutation hook for this that I removed did not have a
  // cache updater associated with it, but perhaps it should have
}

export interface ClearResourceGroupFromCard extends GQLMutation<ClearResourceGroupFromCard> {
  Variables: WithId;
  Data: CardMutationData;
}
export const ClearResourceGroupFromCard: GQLDocument<ClearResourceGroupFromCard> = {
  mutation: gql`
    mutation clearResourceGroupFromCard($id: Float!) {
      card: clearResourceGroupFromCard(id: $id) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `
  // NOTE: as above, the corresponding mutation hook for this that I removed did not have a
  // cache updater associated with it, but perhaps it should have
}

export interface DuplicateBaseCard extends GQLMutation<DuplicateBaseCard> {
  Variables: WithId;
  Data: CardMutationData;
}
export const DuplicateBaseCard: GQLDocument<DuplicateBaseCard> = {
  mutation: gql`
    mutation duplicateBaseCard($id: Float!) {
      card: duplicateBaseCard(id: $id) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(GetOrdo, getQueryVariables, (draft, mutationData: DuplicateBaseCard['Data']) => {
    const index = draft.ordo.cards.findIndex(card => card.sortPosition > mutationData.card.sortPosition);
    draft.ordo.cards.splice(index < 0 ? draft.ordo.cards.length : index, 0, mutationData.card);
    return true;
  })
}

export interface SelectAdLibitumText extends GQLMutation<SelectAdLibitumText> {
  Variables: {
    cardId: number,
    type: 'Antiphon'|'Reading',
    language: Language,
    mainTextId: number,
    verseTextId?: number|null,
    verseTextOptionId?: number|null,
  }
  Data: CardMutationData;
}
export const SelectAdLibitumText: GQLDocument<SelectAdLibitumText> = {
  mutation: gql`
    mutation selectAdLibitumText(
      $cardId: Float!,
      $type: String!,
      $language: String!,
      $mainTextId: Float!,
      $verseTextId: Float,
      $verseTextOptionId: Float,
    ) {
      card: selectAdLibitumText(
        cardId: $cardId,
        type: $type,
        language: $language,
        mainTextId: $mainTextId,
        verseTextId: $verseTextId,
        verseTextOptionId: $verseTextOptionId,
      ) {
        ...OrdoCard
      }
    }
    ${CARD_FRAGMENT}
  `,
}
