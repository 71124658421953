export const contactUrl = 'https://www.sourceandsummit.com/contact';
export const supportMailto = 'mailto:support@sourceandsummit.com';
export const reportAnIssueUrl =
  'https://share.hsforms.com/1JsXKJS8gTeirv74NBRHGhw3o1q1';
export const hymnCollectionRequestUrl =
  'https://share.hsforms.com/1dQnDzxRaSL6-qhatvEBF-A3o1q1';
export const requestFeatureUrl =
  'https://share.hsforms.com/1VlArd31-QNCxk-wm8GcZYg3o1q1';
export const contentRequestUrl =
  'https://share.hsforms.com/1m1yod2o3TkuOZDijGTIhlg3o1q1';
