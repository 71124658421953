import { permissionSetToRoleArgument } from 'hooks/permission-set/useHasPermissionSet';
import { matchRoles, UserRoles } from 'hooks/permission-set/useHasRole';
import { OrdoCard } from 'queries/card-fragment';

export const getHasPermission = (userRoles?: UserRoles[], groupRoles?: UserRoles[]) => (card: OrdoCard) => {
  const roleArg = card.permissionSet && permissionSetToRoleArgument(card.permissionSet);
  return userRoles?.length && roleArg
    ? ((groupRoles && matchRoles(groupRoles, roleArg, true)) || matchRoles(userRoles, roleArg, false))
    : true;
}
