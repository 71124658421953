import { JwtUser } from 'hooks/useUser';
import { GatedFeature } from 'utils/api-types/PermissionSetId';
import { useHasPermissionSet } from './useHasPermissionSet';
import { usePermissionSet } from './usePermissionSet';
export { GatedFeature } from 'utils/api-types/PermissionSetId';
export { usePermissionSet } from './usePermissionSet';

export const useHasFeature = <DefaultValue = false>(
  feature?: GatedFeature,
  defaultValue: boolean | DefaultValue = false,
  user?: JwtUser,
) => {
  const permissionSet = usePermissionSet(feature);
  return useHasPermissionSet(permissionSet, defaultValue, user);
};
