import { useIdQuery } from 'hooks/queries/useQuery';
import { GetResourceReference } from 'queries/resource-reference';
import React from 'react';

type CardReferenceDisplayProps = {
  referenceId: number;
}

export const CardReferenceDisplay : React.FC<CardReferenceDisplayProps> = ({
  referenceId,
}) => {
  const {
    data: { resourceReference } = { resourceReference: undefined }
  } = useIdQuery(
    GetResourceReference,
    referenceId, 
    { fetchPolicy: 'cache-first' }
  );

  const composerSource = resourceReference?.attributes.find(({name}) => name === 'composerSource')?.value;

  return !resourceReference ? null : <div className='card-reference-display _EXPORT'>
    {resourceReference.name}
    {composerSource && <>, <i>{composerSource}</i></>}
    , <i>{resourceReference.collection.name}</i>
    {' '}<i>#{resourceReference.reference}</i>
  </div>;
};
