import React from 'react';
import logo512 from 'assets/logo512.png';

export const LoadingLogo: React.FC = () => {
  return (
    <div className='loading-logo d-flex justify-content-center align-items-center'>
      <div className='loading-background' />
      <div className='logo-container d-flex justify-content-center align-items-center' />
      <img className='logo' src={logo512} alt='Source & Summit' />
    </div>
  );
};
