import { CollectionComposition } from 'queries/collection';
import { CompositionTypes } from 'utils/api-types/composition/CompositionTypes';

export const findCompositionForText = (
  textId?: number | null,
  collectionComposition?: CollectionComposition,
  compositionType?: CompositionTypes
) => {
  const melodyGroup = collectionComposition ? collectionComposition.melodyGroup : null;
  if (!melodyGroup) {
    return null;
  }

  if (!compositionType) {
    /* We only really care about composition type in relation to antiphon/verse and alleluia cards.
    These are the only cases where a melody group has compositions that apply to different texts, and
    the type distinguishes between them. In all other cases, the card only has one text, so whatever
    melody is in the melody group applies by default */
    return melodyGroup.compositions[0];
  }

  let composition = null;
  const compositions = melodyGroup ? melodyGroup.compositions : [];
  // First look for an explicitly matched composition
  composition = compositions.find((c) => c.translation && c.translation.textId === textId);


  if (!composition) {
    // No specific composition exists for the text. Look for a formulaic melody
    // (composition.translation is null and compositionType === composition.type)
    const compositions = collectionComposition?.melodyGroup?.compositions;
    composition = compositions?.find((c) => c.type === compositionType);

    if (!composition && compositionType === CompositionTypes.VERSE) {
      // Still no specific composition.  If the composition type is VERSE, we can allow an ANTIPHON type as long as it has a formulaic melody
      composition = compositions?.find((c) => !!c.isFormulaic);
    }
  }

  return composition;
}
