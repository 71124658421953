import { css, cx } from '@emotion/css';
import { LazySyllabifiedExsurge } from 'components/shared/LazyExsurge';
import { PointedVerse } from 'components/shared/PointedVerse';
import { GabcPsalmTone, GabcPsalmTones, Language } from 'gabc-utils';
import { useLogException } from 'hooks/error/useLogError';
import { ExsurgeConfig } from 'hooks/exsurge/useDefaultExsurgeConfig';
import { Text, TranslationOnText } from 'queries/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getGabcClefMatch } from 'utils/functions/exsurge/getGabcClefMatch';
import { MusicalErrorAlert } from './MusicalErrorAlert';
import { ScriptureReference } from './ScriptureReference';

interface PointedVersesProps {
  verseMusic?: string;
  versePsalmTone?: GabcPsalmTones;
  verseLanguage?: Language;
  defaults: ExsurgeConfig;
  additionalAntiphonText?: string|null;
  verseText?: Text|null;
  verseTranslation?: TranslationOnText|null;
  verses: string[];
  verseClassName?: string;
  verseColumns?: number|null;
  includeGloryBe: boolean;
  gbTranslation?: TranslationOnText|null;
  gbMusic?: string;
  onLoaded?: () => void;
}

export const PointedVerses: React.FC<PointedVersesProps> = ({
  verseMusic,
  versePsalmTone,
  verseLanguage,
  defaults,
  additionalAntiphonText,
  verseText,
  verseTranslation,
  verses,
  verseClassName,
  verseColumns,
  includeGloryBe,
  gbTranslation,
  gbMusic,
  onLoaded,
}) => {
  const logException = useLogException();
  const gabc = versePsalmTone?.originalGabc || verseMusic;
  const gpt = useMemo(
    () => {
      try {
        return versePsalmTone ??
          // TODO: this is asserting that verseMusic is non-null since it can be
          // caught by the try, but this whole call should just be restructured
          GabcPsalmTone.getFromGabc(verseMusic!, {
            treatAsOneAccentWithXPreparatory: true,
          });
      } catch (error) {
        console.warn("Psalm Tone Error: ", error, { versePsalmTone, verseMusic })
        logException({error, message: 'Musical Exception (Psalm Tone)', errorInfo: { versePsalmTone, verseMusic }});
      }
    },
    [versePsalmTone, verseMusic, logException],
  );

  const additionalAntiphonVerses = useMemo(
    () => (additionalAntiphonText ? [additionalAntiphonText] : []),
    [additionalAntiphonText],
  );

  const effectiveVerseClassName = cx({ 'two-digit-verses': (additionalAntiphonVerses.length + verses.length) >= 10 }, verseClassName);
  const gptIsMeinrad = gpt?.isMeinrad;
  const [hasAboveVersesRendered, setHasAboveVersesRendered] = useState(false);
  const [hasRenderedVerseMusic, setHasRenderedVerseMusic] = useState(false);
  const onRenderVerseMusic = useCallback(() => setHasRenderedVerseMusic(true), []);
  let [aboveAdditionalAntiphonVerses, aboveVerses, firstVerseGabc] = useMemo(
    () => {
      if (!gptIsMeinrad || !gabc) {
        setHasAboveVersesRendered(true);
        return [];
      }
      setHasAboveVersesRendered(false);
      const clef = getGabcClefMatch(gabc) || 'c4';
      const tonesGabc = gabc
        ?.split(/::\s+/)
        .map((s) => s.replace(/^([cf]b?[1-4])?/, clef).replace(/(?:::)?$/,'::'));
      const verseLengths = [...additionalAntiphonVerses, ...verses].map(verse => verse.split(/\n/).length);
      if (includeGloryBe && gbTranslation) {
        verseLengths.push(gbTranslation?.content.split(/\n/).length);
      }

      const versesInfo = verseLengths.map((verseLen, i) => {
        const sameAsLastVerse = verseLen === verseLengths[i - 1];
        return {
          gabc: sameAsLastVerse ? null : tonesGabc?.[verseLen - 2],
          hasRendered: sameAsLastVerse,
        };
      });
      const onRender = (i: number) => {
        versesInfo[i].hasRendered = true;
        if (versesInfo.every(verse => verse.hasRendered)) {
          setHasAboveVersesRendered(true);
        }
      }
      const aboveVerses = versesInfo.map((verse, i) => (verse.gabc && <div className={cx('text-center no-page-break-after verse-tone', { 'mt-2': i > 0 })}>
        <LazySyllabifiedExsurge
          text=""
          notation={verse.gabc}
          width={-1}
          onRender={() => onRender(i)}
          {...defaults}
          interSyllabicSpacing={2.5}
        />
        </div>) || null);
      const aboveAdditionalAntiphonVerses = aboveVerses.splice(0, additionalAntiphonVerses.length);
      return [aboveAdditionalAntiphonVerses, aboveVerses, versesInfo[0]?.gabc];
    },
    [defaults, gabc, gbTranslation, gptIsMeinrad, includeGloryBe, verses, additionalAntiphonVerses],
  );

  let verseGabc: string | null = null;

  if (aboveVerses && firstVerseGabc && aboveVerses.slice(1).every(aboveVerse => aboveVerse === null)) {
    // if there is only one aboveVerse, we display it as regular, instead of above the first verse:
    verseGabc = firstVerseGabc;
    aboveVerses = undefined;
  }

  const aboveGloryBe = useMemo(() => aboveVerses?.slice(verses.length), [
    aboveVerses,
    verses.length,
  ]);

  const hasAboveVerses = !!aboveVerses;
  const hasVerseMusic = verseMusic && !aboveVerses;
  const hasLoaded =
    (hasAboveVersesRendered || !hasAboveVerses) &&
    (hasRenderedVerseMusic || !hasVerseMusic);
  useEffect(() => {
    if(hasLoaded) {
      onLoaded?.();
    }
  }, [hasLoaded, onLoaded]);
  
  const gbVerses = useMemo(() => gbTranslation ? [gbTranslation.content] : undefined, [gbTranslation]);
  
  if (!gpt) {
    return <MusicalErrorAlert/>
  };

  return (
    <div className={cx('pointed-verses', style)}>
      {verseMusic && !aboveVerses && <div className='text-center no-page-break-after'>
        <LazySyllabifiedExsurge
          text=""
          notation={verseGabc || versePsalmTone?.originalGabc || verseMusic}
          width={-1}
          onRender={onRenderVerseMusic}
          className='_EXPORT psalm-tone'
          {...defaults}
          interSyllabicSpacing={2.5}
        />
      </div>}

      {additionalAntiphonText && <div className='_EXPORT'>
          <div style={{fontSize: '80%', paddingBottom: 8, fontStyle: 'italic'}}>Additional Antiphon Text</div>
          <PointedVerse
            aboveVerses={aboveAdditionalAntiphonVerses}
            verses={additionalAntiphonVerses}
            language={verseLanguage as Language}
            psalmTone={gpt}
            verseClassName={effectiveVerseClassName}
            individualSyllables={true}
          />
          <div style={{marginBottom: 24}} />
        </div>}

      {verseText && !!verses.length && <ScriptureReference text={verseText.originalSource}/>}

      <div className={cx("_EXPORT", { 'columns-2': verseColumns === 2 })}>
        {verseTranslation && verseMusic && !!verses.length && <PointedVerse
          aboveVerses={aboveVerses}
          verses={verses}
          language={verseLanguage as Language}
          psalmTone={gpt}
          verseClassName={effectiveVerseClassName}
          verseColumns={verseColumns}
          individualSyllables={!additionalAntiphonText}
          firstVerseNum={1 + additionalAntiphonVerses.length}
        />}

        {includeGloryBe && gbVerses && gbMusic && <PointedVerse
          aboveVerses={aboveGloryBe}
          verses={gbVerses}
          language={verseLanguage as Language}
          psalmTone={gpt}
          verseClassName={effectiveVerseClassName}
          verseColumns={verseColumns}
          isGloryBe={true}
          individualSyllables={!additionalAntiphonText && verses.length === 0}
        />}
      </div>
    </div>
  )
}

const style = css`
  .scripture-reference {
    padding-bottom: calc(8px + var(--card-vertical-spacing, 0px))
  }
  .psalm-tone {
    padding-bottom: calc(8px + var(--card-vertical-spacing, 0px))
  }
`;
