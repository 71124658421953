export const getContentWidth = () => {
  const params = new URLSearchParams(window.location.search);
  return Number(params.get('contentWidth'));
};

export const getContentWidthForExsurge = (width: number) => {
  const contentWidth = getContentWidth();
  if (!contentWidth) return width;
  return (width * contentWidth) / 5.5;
};

export const getContentWidthPixels = () => {
  const contentWidth = getContentWidth() || 5.5;
  return Math.round(contentWidth * 590 / 5.5);
}
