import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { TextOption } from "queries/text-option";
import { Text } from "queries/text";
import { Language } from 'utils/api-types/Language';

// createTraverser takes a config and provides an object that can be queried to get
// nested properties from a textOptions array or its members/children
// TODO: in the future, this sort of pattern could be done with some sort of templating/metaprogramming Typescript thing
//       that allows us to automatically generate the traverser with access to all possible fields available in the config.
// To use createTraverser, pass an OrdoCardConfig:
//     const traverser = createtraverser(ordoCardConfig);
// To query a TextOptions array, TextOption, or Text, use one of the following methods:
//     const textOptionsNav = traverser.fromTextOptionsArray(textOptions);
//     const textOptionNav = traverser.fromTextOption(textOption);
//     const textNav = traverser.fromText(text);
// These objects can further be queried in a variety of ways. In general, use .selectTextOption(), .selectText(), or .selectTranslation()
// at any level to select the property according to the OrdoCardConfig you gave. If you provide an appropriate ID to any of these functions,
// it will use the provided ID to search the list, rather than the config. In the case of translations, you can also provide a language,
// which will find the first translation in the list with the given langauge. These methods could be extended to match on other properties in this way.
export const createTraverser = (config?: OrdoCardConfig) => {
  // no config, empty object
  if (!config) {
    return undefined;
  }
  else {
    // the following three functions use partial application. The first argument is applied in here
    // to provide the correct context to the find call, and the second is added by the user
    // if they wish to override the ID in the config.
    const getTextOption = (textOptions?: TextOption[]) => (optionId = config.optionId) => {
      const textOption = textOptions?.find((option) => option.id === optionId);
      if (!textOption) {
        return undefined;
      }
      else {
        const selectText = getText(textOption);
        // return the textOption object along with accessor methods for texts and translations
        return { ...textOption, selectText, selectTranslation: getTranslation(selectText()) }
      }
    }
    const getText = (textOption?: TextOption) => (textId = config.textId) => {
      const text = textOption?.texts.find((text) => text.id === textId);
      if (!text) {
        return undefined;
      }
      else {
        // return the found text object along with an accessor method for translations
        return { ...text, selectTranslation: getTranslation(text) }
      }
    }
    const getTranslation = (text?: Text) => (language: Language) => {
      // translation to get is the one matching the language
      return text?.officialTranslations.find((translation) => translation.language === language);
    }
    return {
      // provide a TextOption array as context to the traverser
      fromTextOptionsArray: (textOptions?: TextOption[]) => {
        if (!textOptions) {
          return undefined;
        }
        else {
          const selectTextOption = getTextOption(textOptions);
          const selectText = getText(selectTextOption());
          const selectTranslation = getTranslation(selectText());
          return { selectTextOption, selectText, selectTranslation };
        }
      },
      // provide a single TextOption as context to the traverser
      fromTextOption: (textOption?: TextOption) => {
        if (!textOption) {
          return undefined;
        }
        else {
          const selectText = getText(textOption);
          const selectTranslation = getTranslation(selectText());
          return { selectText, selectTranslation };
        }
      },
      // provide a single Text as context to the traverser
      fromText: (text?: Text) => {
        if (!text) {
          return undefined;
        }
        else {
          const selectTranslation = getTranslation(text);
          return { selectTranslation };
        }
      }
    }
  }
}
