import { lazy } from 'react';

if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = <T>(): PromiseWithResolvers<T> => {
    let resolve: (value: T | PromiseLike<T>) => void;
    let reject: (reason?: any) => void;
    const promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve: resolve!, reject: reject! };
  }
}

export const loadReactPdf = () => import('react-pdf');
export const Page = lazy(async () => ({
  default: (await loadReactPdf()).Page,
}));
export const Document = lazy(async () => ({
  default: (await loadReactPdf()).Document,
}));
export const classReactPdfDocument = 'react-pdf__Document';
export const classReactPdfPage = 'react-pdf__Page';
