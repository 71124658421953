export type ParishSize =
  | '1-49'
  | '50-349'
  | '350-999'
  | '1000-2499'
  | '2500+'
  | 'unsure'
  | 'unknown'

// WARNING
// if you change this, you need to change it in
// the api in group.service.ts
export const parishSizes: { label: string, value: ParishSize }[] = [
  {
    label: '1-49 families',
    value: '1-49',
  },
  {
    label: '50-349 families',
    value: '50-349',
  },
  {
    label: '350-999 families',
    value: '350-999',
  },
  {
    label: '1000-2499 families',
    value: '1000-2499',
  },
  {
    label: '2500+ families',
    value: '2500+',
  },
  {
    label: 'I’m not sure',
    value: 'unsure',
  },
  {
    label: 'Not applicable',
    value: 'unsure',
  }
];

export const parishSizeQuantities: { [key in ParishSize]: number | undefined } = {
  '1-49': 1,
  '50-349': 50,
  '350-999': 350,
  '1000-2499': 1000,
  '2500+': 2500,
  'unsure': undefined,
  'unknown': undefined,
};

export const defaultMissalQuantities: { [key in ParishSize]: number | undefined } = {
  '1-49': 50,
  '50-349': 175,
  '350-999': 450,
  '1000-2499': 750,
  '2500+': 900,
  'unsure': undefined,
  'unknown': undefined,
};

export const numericParishSizes = [
  '1-49',
  '50-349',
  '350-999',
  '1000-2499',
  '2500+',
];

export const fallbackParishSize: ParishSize = '1-49';

export const parishSizeToNumber = (parishSize: ParishSize) => parishSizeQuantities[parishSize] ?? undefined;
export const parishSizeToMissalQuantity = (parishSize: ParishSize) => defaultMissalQuantities[parishSize] ?? NaN;
