export enum Season {
  ADVENT = 'ADVENT',
  CHRISTMAS = 'CHRISTMAS',
  LENT = 'LENT',
  LENT_OUTSIDE = 'LENT_OUTSIDE',
  HOLY_WEEK = 'HOLY_WEEK',
  EASTER = 'EASTER',
  EASTER_OUTSIDE = 'EASTER_OUTSIDE',
  ORDINARY = 'ORDINARY',
}

export const seasonNames: { [ key in Season ]: string } = {
  [Season.ADVENT]: 'Advent',
  [Season.CHRISTMAS]: 'Christmas Time',
  [Season.LENT]: 'Lent',
  [Season.LENT_OUTSIDE]: 'Outside Lent',
  [Season.HOLY_WEEK]: 'Holy Week',
  [Season.EASTER]: 'Easter Time',
  [Season.EASTER_OUTSIDE]: 'Outside Easter',
  [Season.ORDINARY]: 'Ordinary Time',
};

export const seasonEquals = (left: Season | undefined | null, right: Season | undefined | null) => {
  // if one of the sides is "Outside Easter" or "Outside Lent", then all we care
  // is that the other isn't the respective season or undefined.
  // otherwise, it's a normal equality check
  if (!left || !right) {
    // undefined/null seasons pass the check, since we don't care about them
    return true;
  } else if (left === Season.EASTER_OUTSIDE || right === Season.EASTER_OUTSIDE) {
    return !!(left !== Season.EASTER && right !== Season.EASTER && left && right);
  } else if (left === Season.LENT_OUTSIDE || right === Season.LENT_OUTSIDE) {
    return !!(left !== Season.LENT && right !== Season.LENT && left && right);
  } else {
    return left === right;
  }
}
