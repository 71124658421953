import { useMemo } from 'react';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { removeHymnTextAmen } from 'utils/hymnTuneAmen';
import { splitVersesByType } from 'utils/markup/splitVerses';

export const useHymnVerses = (
  translation: string | undefined,
  refrain: string | undefined,
  { notateFirstVerses, verseOptions, showAmen }: OrdoCardConfig,
) =>
  useMemo(() => {
    if (!translation) return [];
    let verses = splitVersesByType(translation, 'DOUBLE_LINE_BREAK');
    if (verseOptions) {
      const selectedVerses = verseOptions
        .filter((o) => o.isSelected)
        .map((o) => o.index);
      verses = verses.filter((_, i) => selectedVerses.includes(i));
    }
    if (notateFirstVerses) {
      verses.splice(notateFirstVerses);
    }
    if (refrain) {
      verses[0] += `\n${refrain}`;
    }
    if (showAmen === false) {
      verses[verses.length - 1] = removeHymnTextAmen(verses[verses.length - 1]);
    }
    return verses;
  }, [translation, verseOptions, notateFirstVerses, refrain, showAmen]);

export const useHymnText = (
  translation: string | undefined,
  refrain: string | undefined,
  config: OrdoCardConfig,
) => {
  const hymnVerses = useHymnVerses(translation, refrain, config);
  return useMemo(() => hymnVerses.join('\n\n'), [hymnVerses]);
};
