import { useApolloClient } from '@apollo/client';
import { useDebugLogger } from 'hooks/debug';
import { useLoggedIdQuery } from 'hooks/debug/useLoggedQuery';
import { useErrorToastEffect } from 'hooks/error/useErrorToastEffect';
import { useRefreshJwt } from 'hooks/registration';
import { isAutoExportQueryParam } from 'hooks/useQueryParam';
import { useSessionStorageWithLocalStorageBacking } from 'hooks/useSessionStorage';
import { useUpsellModal } from 'hooks/useUpsellModal';
import { User, UserManager } from 'oidc-client-ts';
import { ChoirEmailOnGroup, GetGroupChoirEmails } from 'queries/choir-email';
import { GetGroup, Group } from 'queries/group';
import { AddSubscriptionFromHostedPage } from 'queries/hosted-page';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import asyncAppSync from 'utils/asyncAppSync';
import { OIDC_PATHS } from 'utils/authorization/initAuthManager';
import { GroupInfo } from 'utils/jwt/GroupInfo';
import { JwtUser } from 'utils/jwt/parseJwt';
import { loadUserFlow } from 'utils/loadUserFlow';
import { getLocalStorageHostedPageId, removeLocalStorageHostedPageId } from 'utils/localStorageHostedPageId';

export interface IAuthContext {
  userManager: UserManager;
  group?: Group;
  choirEmails?: ChoirEmailOnGroup[];
  groups?: GroupInfo[];
  selectGroup: (groupId: string) => void;
  user?: JwtUser | null;
  openUpsellModal?: (expressedInterest: string, toEssentials?: boolean) => void;
  refreshUser: () => Promise<void>;
}

export const AuthContext = React.createContext<IAuthContext>({
  userManager: {} as UserManager,
  group: undefined,
  choirEmails: undefined,
  groups: undefined,
  selectGroup: () => {},
  user: undefined,
  openUpsellModal: undefined,
  refreshUser: async () => {},
});

type AuthContextProviderProps = {
  userManager?: UserManager;
  useSigninSilent?: boolean;
  ignoreAuthentication?: boolean;
}

export const AuthContextProvider: React.FC<PropsWithChildren<AuthContextProviderProps>> = ({
  userManager,
  useSigninSilent = false,
  ignoreAuthentication = false,
  children,
}) => {
  const debugLogger = useDebugLogger('auth-context');

  const { user, refreshUser } = useUserFromUserManager(useSigninSilent, userManager);
  const groups = user?.groups;

  const [groupId, setGroupId] = useSessionStorageWithLocalStorageBacking('groupId');

  useEffect(() => {
    if (groups) {
      const defaultGroupId = groups[0]?.id;
      const userGroupIds = groups.map(group => group.id);
      if (!groupId || !userGroupIds.includes(groupId)) {
        debugLogger.info({ currentGroupId: groupId, defaultGroupId, userGroupIds }, 'resetting to default group ID');
        setGroupId(defaultGroupId);
      }
    }
  }, [groupId, setGroupId, groups, debugLogger]);


  // subscribe to user ID on AppSync to pick up notification if user is removed from group
  useEffect(() => {
    if (user) {
      const subscription = asyncAppSync().then(sync => sync.subscribe(user.sub, ({ data }) => {
        const parsed = JSON.parse(data) as { action?: 'removed', groupId?: string };
        if (parsed?.action === 'removed') {
          debugLogger.log(`removed from group ${parsed.groupId}, refreshing session to remove access`);
          refreshUser();
        }
      }, error => debugLogger.error(error, 'Error receiving from AppSync')));
      return () => { subscription.then(sub => sub.unsubscribe()); }
    }
}, [user, debugLogger, refreshUser]);

  const {
    data: { group } = { group: undefined },
    error: groupError,
  } = useLoggedIdQuery(
    debugLogger,
    'GetGroup',
    GetGroup,
    ignoreAuthentication ? null : groupId,
    { nextFetchPolicy: isAutoExportQueryParam() ? 'cache-first' : 'cache-and-network', authRequired: true }
  );
  const {
    data: { group: { choirEmails } } = { group: { choirEmails: [] } },
  } = useLoggedIdQuery(
    debugLogger,
    'GetGroupChoirEmails',
    GetGroupChoirEmails,
    ignoreAuthentication ? null : groupId,
    { authRequired: true }
  )

  useErrorToastEffect(groupError && 'An error was encountered while loading the user’s group');

  const { openUpsellModal, upsellModalMarkup } = useUpsellModal(user);

  if (userManager) {
    const contextValue: IAuthContext = {
      user,
      group,
      choirEmails,
      groups,
      selectGroup: setGroupId,
      userManager,
      openUpsellModal,
      refreshUser,
    };

    debugLogger.info(contextValue, 'context value');

    return <AuthContext.Provider value={contextValue}>
      {children}
      {upsellModalMarkup}
    </AuthContext.Provider>;
  } else {
    return <>{children}</>;
  }
}

const useUserFromUserManager = (useSigninSilent: boolean, userManager?: UserManager) => {
  const isSilentLogin = window.location.pathname.startsWith(OIDC_PATHS.loggedInSilent);
  // user is set to undefined if still loading / checking
  // and to null if no user is authenticated
  const [user, setUser] = useState<JwtUser | null>();
  const apollo = useApolloClient();
  const refreshJwt = useRefreshJwt(userManager);
  const parseUser = useCallback(
    (user: User | null) => setUser(user?.profile as JwtUser | undefined),
    [setUser],
  );

  useEffect(() => {
    // if the user is in the middle of logging in, we don't want to trigger a signinSilent:
    if (isSilentLogin) return;
    userManager?.events.addUserLoaded(parseUser);
    let timeoutId: number | undefined;
    let continueProcessing = true;
    userManager?.getUser().then(user => {
      if (!continueProcessing) return;
      if (user) {
        parseUser(user);
      } else if (useSigninSilent) {
        timeoutId = window.setTimeout(async () => {
          try {
            const user = await userManager.signinSilent();
            parseUser(user);
           } catch (err) {
            console.info({signinSilentErr: err});
            let user = await userManager.getUser();
            // set to null here instead of undefined in parseUser
            // to indicate that no user was found
            setUser((user?.profile as JwtUser) || null);
          }
        });
      }
    });
    return () => {
      continueProcessing = false;
      window.clearTimeout(timeoutId);
      userManager?.events.removeUserLoaded(parseUser);
    };
  }, [userManager, parseUser, isSilentLogin, useSigninSilent]);

  useEffect(() => {
    if (user) {
      const hostedPageId = getLocalStorageHostedPageId();
      if (hostedPageId) {
        removeLocalStorageHostedPageId();
        // update things and refresh jwt if necessary
        apollo.mutate<AddSubscriptionFromHostedPage['Data'], AddSubscriptionFromHostedPage['Variables']>({
          mutation: AddSubscriptionFromHostedPage.mutation,
          variables: { hostedPageId },
        }).then((result) => {
          if (result?.data) {
            refreshJwt();
          }
        }).catch(() => {});
      }
      loadUserFlow(user);
    }
  }, [user, apollo, refreshJwt]);

  const refreshUser = useCallback(async () => {
    await userManager?.removeUser();
    await userManager?.signinSilent()
    .catch(err => {
      if (err.message !== 'Frame window timed out') {
        console.error(err);
      }
      return userManager?.getUser();
    })
    .then(user => setUser((user?.profile as JwtUser) || null));
  }, [setUser, userManager]);

  return { user, refreshUser };
}
