import { DebugLogger } from './DebugLogger'
import { OperationVariables, QueryHookOptions, useQuery as useApolloQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { GQLDocument, GQLQuery } from 'queries/gql-generics';
import { useApolloOnError } from 'hooks/error/useLogError';
import { getHasAuthorizationToken } from 'utils/authorization/authorization';

// pass the arguments of useQuery to this hook to log the loading, error, and success states of the query.
export const useLoggedApolloQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  // the logger to use
  logger: DebugLogger,
  // a description of the query to be included with log messages
  description: string,
  // useQuery arguments
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables> | undefined,
) => {
  const onError = useApolloOnError("Query", query, options);
  const optionsToUse = { ...options, onError };
  const queryResult = useApolloQuery<TData, TVariables>(query, optionsToUse);
  // skip logging when query is skipped
  if (!options?.skip) {
    // log the query with its description, according to its current state
    if (queryResult.loading) {
      logger.log(`query ${description} is loading`);
    } else if (queryResult.error) {
      logger.log(`encountered error in query ${description}: ${queryResult.error}`);
    } else {
      logger.log(`query ${description} loaded`);
    }
  }
  return queryResult;
}

// pass the arguments of useQuery to this hook to log the loading, error, and success states of the query.
export const useLoggedQuery = <Q extends GQLQuery>(
  // the logger to use
  logger: DebugLogger,
  // a description of the query to be included with log messages
  description: string,
  // useQuery arguments
  query: GQLDocument<Q>,
  options?: QueryHookOptions<Q['Data'], Q['Variables']>,
) => {
  return useLoggedApolloQuery(logger, description, query.query, options);
}

export const useLoggedIdQuery = <IdType, Q extends GQLQuery<Q> & { Variables: { id: IdType } }>(
  logger: DebugLogger,
  description: string,
  { query }: GQLDocument<Q>,
  id: IdType | null,
  { authRequired, ...options }: Omit<QueryHookOptions<Q['Data'], Q['Variables']>, 'variables' | 'skip'> & {
    authRequired?: boolean;
  } = {}
) => {
  return useLoggedApolloQuery(
    logger,
    description,
    query,
    {
      ...options,
      ...(id ? {
        variables: { id },
        skip: authRequired && !getHasAuthorizationToken(),
      } : {
        skip: true,
      })
    }
  );
}
