import { css, cx } from "@emotion/css";
import React from "react";

interface ScriptureReferenceProps {
  text?: string;
}

export const ScriptureReference: React.FC<ScriptureReferenceProps> = ({
  text = "",
}) => {
  // split on a regex to match the parts that should be bold (any books and chapter in the reference)
  const referenceParts = text.split(/((?:\d+\s*)?[A-Z](?:[^,:;\s\d+]+\s+)+\s*\d+(?:\s+\(\d+\))?|\d+(?:\s+\(\d+\))?(?=:))(?=:|$)/)
    .map((text, i) => ({ text, isBold: i % 2 === 1 }))
    .filter(({ text }) => !!text);
  return (
    <div className={cx('scripture-reference', style, 'text-center no-page-break-after _EXPORT')}>
      {referenceParts.map(({ text, isBold }, i) => <span key={`${i}`} className={cx({ bold: isBold })}>{text}</span>)}
    </div>
  );
};

const style = css`
  line-height: 1;
  span {
    font-size: 80%;
    &.bold {
      font-weight: 600;
    }
  }
`;
