import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faTextSlash } from '@fortawesome/pro-light-svg-icons/faTextSlash';
import { faUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Quill from 'quill';
import Link from 'quill/formats/link';
import Icons from 'quill/ui/icons';
import { renderToStaticMarkup } from 'react-dom/server';

// any module that uses Quill should import this to ensure the code in this module gets run
// eslint-disable-next-line import/no-anonymous-default-export
export default null;

// Extension to Link ensures that any schemeless URL gets https:// added
const makeHrefAbsolute = (node: HTMLElement): HTMLElement => {
  const href = node.getAttribute('href');
  if (href && !href.includes('://')) {
    node.setAttribute('href', 'https://' + href);
  }
  return node;
}

const FormatLink = Quill.import('formats/link') as typeof Link;

class AbsoluteLink extends FormatLink {
  static create(value: string) {
    const node = super.create(value) as HTMLElement;
    return makeHrefAbsolute(node);
  }
  format(name: string, value: unknown) {
    super.format(name, value);
    return makeHrefAbsolute(this.domNode);
  }
}

Quill.register(AbsoluteLink, true);

// set up icon definitions to use our (FontAwesome) icons in toolbar
let icons = Quill.import('ui/icons') as typeof Icons;

const toMarkup = (icon: IconDefinition) => renderToStaticMarkup(<FontAwesomeIcon className='quill-icon' icon={icon} />);

const iconMap: Partial<typeof icons> = {
  italic: toMarkup(faItalic),
  bold: toMarkup(faBold),
  underline: toMarkup(faUnderline),
  align: {
    '': toMarkup(faAlignLeft),
    center: toMarkup(faAlignCenter),
    right: toMarkup(faAlignRight),
    justify: toMarkup(faAlignJustify)
  },
  list: {
    ordered: toMarkup(faListOl),
    bullet: toMarkup(faListUl),
    check: toMarkup(faListCheck)
  },
  link: toMarkup(faLink),
  clean: toMarkup(faTextSlash),
}

Object.assign(icons, iconMap);
