import { LiturgicalRite } from 'queries/liturgicalRite';
import { OrdoConfig } from 'utils/api-types/ordo/OrdoConfig';

export const getLiturgyDisplayNameIfFuneral = (
  config?: Partial<OrdoConfig>,
  rite?: Pick<LiturgicalRite, 'name' | 'showDeceasedName'>,
) =>
  rite?.name === 'Funeral Mass for [Deceased Name]' && rite.showDeceasedName
    ? config?.deceasedFirstName && config.deceasedLastName
      ? `Funeral Mass for ${config.deceasedFirstName} ${config.deceasedLastName}`
      : // fallback if for some reason the lack of a deceased name wasn't caught by validation
        'Funeral Mass'
    : null;
