import { TextOptionWithMeta } from "queries/text-option";

export const doTextOptionsHaveRubrics = (textOptions: TextOptionWithMeta[] | null): boolean => {
  if (!textOptions) {
    return false;
  }
  const texts = textOptions.flatMap(textOption => textOption.texts);
  const translations = texts.filter(text => ['Rubric Header', 'Rubric Footer'].includes(text.contentType.name));

  return (translations.length > 1);
};
