import { useCallback, useMemo } from 'react';
import { ApolloClient, ApolloError, DocumentNode, FetchResult, MutationFunctionOptions, MutationHookOptions, useApolloClient } from '@apollo/client';
import { getHasAuthorizationToken } from 'utils/authorization/authorization';
import { LogError, LogErrorFunction } from 'queries/error';
import { OperationDefinitionNode } from 'graphql';
import { GQLOperation } from 'queries/gql-generics';
import { Dictionary } from 'utils/typescript/Dictionary';

export type LogErrorMutation = (options?: MutationFunctionOptions<LogError['Data'], LogError['Variables']>) => Promise<FetchResult<LogError['Data']>>;

export const useLogError = (_options?: MutationHookOptions<LogError['Data'], LogError['Variables']>) => {
  let options = useMemo(() => _options ?? {}, [_options]);
  const apollo = useApolloClient(options.client);
  return useCallback(
    (type: string, miscData?: Dictionary<unknown>, notifySlack: boolean = false) =>
      logError(apollo, type, miscData, notifySlack, options),
    [apollo, options],
  );
};

export const logError = (apollo: ApolloClient<object>, type: string, miscData?: Dictionary<unknown>, notifySlack?: boolean, options?: MutationHookOptions<LogError['Data'], LogError['Variables']>) => {
  if (!getHasAuthorizationToken()) {
    console.warn('logging without authorization token:', type, miscData);
  }
  // Add timestamp
  if (miscData && !miscData.when) {
    miscData.when = Date.now();
  }
  const variables: LogError['Variables'] = { type, miscData, notifySlack };
  return apollo.mutate({ ...options, mutation: LogError.mutation, variables }).catch(e => {
    options?.onError?.(e);
    console.warn(e);
  });
}

export const useApolloOnError = <Q extends GQLOperation>(
  type: 'Mutation' | 'Query' = 'Query',
  query: DocumentNode,
  options?: { 
    onError?: (error: ApolloError) => void;
    variables?: Q['Variables'];
  }
) => {
  const logApolloError = useLogApolloError(type, query, options);
  const onError = options?.onError;
  return useCallback(
    (error: ApolloError) => {
      let preventDefault = false;
      try {
        preventDefault = !!onError?.(error);
      } catch {}
      if (!preventDefault) {
        logApolloError(error);
      }
    },
    [logApolloError, onError],
  );
}

export interface LogExceptionArgument {
  error?: any;
  errorInfo?: any;
  message?: string;
};

export const makeLogErrorArguments = ({
  error,
  errorInfo,
  message = `Unhandled Exception`,
}: LogExceptionArgument): Parameters<LogErrorFunction> => {
  const { name, message: errorMessage, stack, ...altErrorInfo } = error || {};
  if (name === 'ChunkLoadError') {
    // the API will not notify slack if the message does not have 'exception' in it:
    message = `Chunk Load Error`;
  }
  if (errorMessage) message += ': ' + errorMessage;
  const url = window.location.href;
  const { userAgent } = window.navigator;
  return [
    message,
    {
      _: { url, userAgent },
      'Stack Trace': stack,
      errorInfo: errorInfo || altErrorInfo,
    },
    shouldNotifySlackOfError(message)
  ];
};

export const useLogException = (
  options?: MutationHookOptions<LogError['Data'], LogError['Variables']>,
) => {
  const logError = useLogError(options);
  const logException = useCallback(
    (arg: LogExceptionArgument) => logError(...makeLogErrorArguments(arg)),
    [logError],
  );

  return logException;
};
export const logException = (
  apollo: ApolloClient<object>,
  arg: LogExceptionArgument,
  options?: MutationHookOptions<LogError['Data'], LogError['Variables']>,
) => logError(apollo, ...makeLogErrorArguments(arg), options);
export type ExceptionLogger = typeof logException;

export const useLogApolloError = <Q extends GQLOperation>(
  type: 'Mutation' | 'Query' = 'Query',
  query: DocumentNode,
  options?: {
    onError?: (error: ApolloError) => void;
    variables?: Q['Variables'];
  },
) => {
  const logException = useLogException();
  return useCallback(
    (error: ApolloError) => {
      const { stack, message, ...errorInfo } = error;
      let numErrors =
        (errorInfo.clientErrors?.length ?? 0) +
        (errorInfo.graphQLErrors?.length ?? 0) +
        Object.keys(errorInfo.networkError ?? {}).length;
      if (numErrors > 0) {
        // Only log the error if there is useful information
        logException({
          error: { stack, message },
          message: `GraphQL ${type} Exception`,
          errorInfo: {
            ...errorInfo,
            [type.toLowerCase()]: {
              name: (
                query.definitions.find(
                  (def) => def.kind === 'OperationDefinition',
                ) as OperationDefinitionNode
              ).name?.value,
              variables: options?.variables,
            },
          },
        });
      }
    },
    [logException, options?.variables, query.definitions, type],
  );
};

const regexes = {
  exception: /\bException\b/,
  exclude: [
    /\bUnauthorized\b/,
    /\bKeycloak\b/,
    /\bClearing Local Storage\b/,
    /\binvitation link may be expired\b/,
    /\bit will not be included in the exported PDF\b/,
  ]
}

const shouldNotifySlackOfError = (message: string): boolean => {
  for (const exclude of regexes.exclude) {
    if (exclude.test(message)) {
      return false;
    }
  }
  return /\bException\b/.test(message);
}
