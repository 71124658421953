import { css, cx } from '@emotion/css';
import { AntiphonVerseReference } from 'components/ordo/shared/text/AntiphonVerseReference';
import { PlainTextTranslation } from 'components/ordo/shared/text/PlainTextTranslation';
import { CardContext } from 'context/CardContext';
import { useDefaultLanguageTranslation } from 'hooks/text';
import { Text } from 'queries/text';
import { TextOption } from 'queries/text-option';
import { Translation } from 'queries/translation';
import React, { useContext } from 'react';
import { Language } from 'utils/api-types/Language';
import { isTextVerse } from 'utils/isTextVerse';
import { splitVersesByType, VerseSplitType } from 'utils/markup/splitVerses';
import { triplifyAlleluia } from 'utils/triplifyAlleluia';

export enum AntiphonDisplayMode {
  DEFAULT,
  REPEATED_RESPONSE,
  ANTIPHON_WITH_VERSE_REFERENCE,
}

interface OfficialAntiphonTranslationProps {
  antiphonTranslation?: Translation | null;
  antiphon: Text;
  additionalAntiphonText?: string | null;
  textOption?: TextOption;
  verse?: Text;
  includeGloryBe?: boolean;
  displayMode?: AntiphonDisplayMode;
  verseCount?: number | null;
  verseColumns?: number | null;
  verseSplitType: VerseSplitType;
  languageOverride?: Language;
  isEasterVigilTripleAntiphon?: boolean;
}

export const OfficialAntiphonTranslation: React.FC<OfficialAntiphonTranslationProps> = ({
  antiphonTranslation,
  antiphon,
  additionalAntiphonText,
  textOption,
  verse,
  includeGloryBe = false,
  displayMode = AntiphonDisplayMode.DEFAULT,
  verseCount,
  verseColumns,
  verseSplitType,
  languageOverride,
  isEasterVigilTripleAntiphon = false,
}) => {
  const effectiveVerse = (antiphon && !verse && isTextVerse(antiphon))? antiphon : verse;
  const officialAntiphon = useDefaultLanguageTranslation(antiphon, languageOverride);
  let verseText = useDefaultLanguageTranslation(effectiveVerse)?.content ?? '';

  const verses = splitVersesByType(verseText, verseSplitType);

  let antiphonContent = antiphonTranslation?.content ?? officialAntiphon?.content;
  let antiphonAltContent = textOption?.textCategory.name === 'Responsorial Psalm'
    ? isEasterVigilTripleAntiphon
      ? triplifyAlleluia(antiphonContent)
      : antiphonContent
    : antiphonTranslation?.altContent ?? officialAntiphon?.altContent;

  if (effectiveVerse === antiphon) {
    // if it is a tract, with just verses, we don't want to show it twice:
    antiphonContent = antiphonAltContent = undefined;
  }

  if (displayMode === AntiphonDisplayMode.ANTIPHON_WITH_VERSE_REFERENCE) {
    verseText = '';
  } else if (typeof verseCount === 'number') {

    verses.splice(verseCount);

    verseText = verses.join('\n\n');
  }
  if (additionalAntiphonText) {
    verseText = `${additionalAntiphonText.trim()}\n\n${verseText}`.trim();
  }

  const cardTextDisplay = useContext(CardContext).config.textDisplay;
  const textDisplay =
      displayMode === AntiphonDisplayMode.DEFAULT
        ? cardTextDisplay || 'Paragraph'
        : 'In Verse';

  return (
    <div className={cx('official-antiphon-translation', style)}>
      <AntiphonVerseReference
        antiphonReference={antiphon?.originalSource}
        verseReference={verses.length ? verse?.originalSource : undefined}
        sourceNames={textOption?.sources.map(s => s.nameShort)}
      />
      {
        (displayMode === AntiphonDisplayMode.DEFAULT || !verseText) && <PlainTextTranslation
          text={antiphonContent}
          exportPadBottom={15}
          className={cx(
            { 'liturgical-text-response': displayMode !== AntiphonDisplayMode.DEFAULT && !verseText },
            'pb-15',
            'no-page-break-inside'
          )}
          textDisplay={textDisplay}
        />
      }
      {verseText && <PlainTextTranslation
        text={verseText}
        textInterjected={displayMode === AntiphonDisplayMode.REPEATED_RESPONSE ? antiphonContent : undefined}
        textInterjectedBefore={displayMode === AntiphonDisplayMode.REPEATED_RESPONSE ? antiphonAltContent : undefined}
        className={displayMode === AntiphonDisplayMode.REPEATED_RESPONSE ? 'liturgical-text-response' : 'liturgical-text-verse'}
        columns={verseColumns}
        includeGloryBe={includeGloryBe}
      />}
    </div>
  );
}

const style = css`
  // this rule gets the padding for the doxology
  .plain-text-translation + .liturgical-text {
    padding-top: 15px;
  }
  // this rule gets inter-verse spacing for responsorial psalms
  .liturgical-text-response > span + span > ul {
    padding-top: 15px;
  }
  .antiphon-verse-reference {
    padding-bottom: 10px;
  }
`;
