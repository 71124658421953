import { processTimeMapForTicks, TimeMapEntryWithPossibleTicks } from "./processTimeMapForTicks";

export interface ElementMap {
  [id: string]: number;
}
export const makeElementMapFromTimeMap = (
  timeMapWithPossibleTicks: TimeMapEntryWithPossibleTicks[],
  options = {},
) => {
  const timeMap = processTimeMapForTicks(timeMapWithPossibleTicks, options);
  const elementMap: ElementMap = {};
  for (const { tick, on } of timeMap) {
    // set start tick for each element that goes 'on' in this TimeMapEntry:
    for (const id of on ?? []) {
      elementMap[id] = tick;
    }
  }
  return elementMap;
};
