export interface DebugLogger {
  // basic logging functionality. Will include a stack trace with the log.
  log: (...logMessage: unknown[]) => void;
  // output an error. Errors will output even if the corresponding debug key is not present.
  error: (error: unknown, message?: string) => void;
  // output information about an object
  info: (infoItem: unknown, message?: string) => void;
}

// a no-op version of the logger so we don't have to re-create it each time we need it
export const emptyLogger: DebugLogger = {
  log: () => {},
  error: console.error,
  info: () => {}
};
