import { SegmentedControl } from 'components/shared/inputs/partials/SegmentedControl';
import { useOptionList } from 'hooks/useSimpleOptionList';
import React, { PropsWithChildren } from 'react';
import { VerovioOptions } from 'verovio';

export type Spacing = -2 | -1 | 0 | 1 | 2;
const validSpacings = new Set([-2, -1, 0, 1, 2]);
export const isValidSpacing = (val?: number | null): val is Spacing =>
  validSpacings.has(val!);
export const verovioNonLinearSpacing = [0.51, 0.54, 0.57, 0.6, 0.63];
export const getNonLinearForSpacing = (spacing: Spacing) =>
  verovioNonLinearSpacing[spacing + 2];
// not currently used, but if we need to change linear spacing as well:
export const getLinearForSpacing = (linear: number, spacing: Spacing) =>
  linear + spacing * 0.02;
export const getVerovioOptionsForHorizontal = (
  linear: number,
  spacing: Spacing,
): VerovioOptions => ({
  // spacingLinear: getLinearForSpacing(linear, spacing),
  spacingNonLinear: getNonLinearForSpacing(spacing),
});
const spacingOptionLabels = ['− −', '−', '0', '+', '+ +'] as const;
export const spacingOptionList: Parameters<
  typeof useOptionList<Spacing, string>
>[0] = spacingOptionLabels.map((label, i) => ({
  label,
  value: (i - 2) as Spacing,
}));

interface SpacingTogglerProps {
  value?: Spacing | number | null;
  defaultValue?: Spacing;
  onChange: (spacing: Spacing) => void;
}

export const SpacingToggler: React.FC<PropsWithChildren<SpacingTogglerProps>> = ({
  children: label,
  value,
  defaultValue = 0,
  onChange,
}) => {
  return (
    <label style={{ width: '100%' }}>
      {label}
      <SegmentedControl
        onChange={onChange}
        value={isValidSpacing(value) ? value : defaultValue}
        options={spacingOptionList}
      />
    </label>
  );
};
