export const processPlainText = (text?: string, isEasterTime?: boolean): string => {
  if (!text) {
    return '';
  }

  // This ensures the spaces around * and † characters are preserved
  let t = text.replace(/[~‿]| ([*†] ?|\([*†]\) ?)+/g, ' ');

  if (isEasterTime !== undefined) {
    if (isEasterTime) {
      t = t.replace(/([,;:.!?])?\s*\((?:E\.\s*T|T\.\s*P)\.\s*([^)]+)\)/g, (_,punctuation,alleluia) => {
        return `${(punctuation || ',')} ${alleluia}`;
      });
    } else {
      t = t.replace(/\s*\((?:E\.\s*T|T\.\s*P)\.[^)]+\)/g,'');
    }
  }

  // Take out anything else
  // NOTE: Intentionally checking for * and † characters here that don't have a leading space
  return t
    .replace(/(?:(^|\n)\s*){1,}((?:<rubric>[^<]*<\/rubric>\s*\n\s*)?)([\d℣℟]*\.)[  ]/g,'$1$1$2($3) ')
    .replace(/\\forceHyphen\s+(\S+)\s+--\s+/g, '$1-')
    .replace(/([^_\s])\s+_/gm,'$1')
    .replace(
      /\\set\s+\w+\s*=\s*("[^"]*")|\\\w+|\[[^[\]]*\]|(^|\s+)""|\s+--\s+(""\s+--\s+)?|^[\d℣℟]*\.|<(?!\/?(?:h2|rubric|poetic)>)[^>]+>/gm,
      '',
    )
    .replace(/\(([†*]|(?:[℣℟]|\d+)\.)\)|[†*]/g, '$1')
    .replace(/(?:\s*\n\s*)+([℣℟][^\n]*)$/g, '\n\n$1')
    .replace(/%([^\n]*(?:\n|$))/g, '$1')
    .replace(/"\((.*?)\)"/g, '$1');
}
