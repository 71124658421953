import { css, cx } from '@emotion/css';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { colorDictionary } from 'utils/styles';

interface AlternativeCardOptionProps {
  onClick: JSX.IntrinsicElements['div']['onClick'];
  suppressPlus?: boolean;
}

export const AlternativeCardOption : React.FC<PropsWithChildren<AlternativeCardOptionProps>> = ({
  onClick,
  suppressPlus = false,
  children,
}) => {
  return <div className={cx('alternative-card-option', style)} onClick={onClick}>
    <span>{!suppressPlus && <FontAwesomeIcon icon={faPlus} className='plus-icon' />}{children}</span>
  </div>;
};

const style = css`
  padding: 15px 10px;
  margin-top: 15px;
  border: 1px solid ${colorDictionary['Input']};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: ${colorDictionary['Grey 3']};
  }

  p:last-of-type { margin-bottom: 0; }

  .state-title + &, .or-block + & {
    margin-top: 10px;
  }

  .feature-upgrade-icon {
    margin-left: 11px;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    > .plus-icon {
      margin-right: 5px;
      height: 0.75em;
      position: relative;
      top: 1px;
    }
  }
`;
