import { gql } from '@apollo/client';
import { Spacing } from 'components/shared/editor/settings/SpacingToggler';
import { Language } from 'utils/api-types/Language';
import { Nullable } from 'utils/typescript/Nullable';
import { MusicalNotation, VerseStyle } from './MusicalProperties';
import { ReadingDisplayType, TextDisplayType } from './ReadingSettingsTypes';
import { ReadingTypeOrBoth } from './ReadingTypes';
import { VerseOption } from './VerseOption';

export type OrdoCardConfig = Partial<Nullable<OrdoCardConfigBase>>;

export type OrdoCardConfigInput = OrdoCardConfig;

export type OrdoCardConfigBase = {
  id: string;
  noteId: number;
  antiphonId: number;
  verseId: number;
  textId: number;
  optionId: number;
  collectionId: number;
  compositionId: number;
  verseCollectionId: number;
  verseCompositionId: number;
  hymnId: number;
  hymnTuneId: number;
  stackChantVerses: boolean;
  resourceGroupId: number;
  primaryResourceId: number;
  resourceIds: number[];
  includeGloryBe: boolean;
  notation: MusicalNotation;
  isVerseGrouped: boolean;
  verseStyle: VerseStyle;
  keyOffset: number;
  verseOptions: VerseOption[];
  verseCount: number;
  displayChordSymbols: boolean;
  displayHarmonizations: boolean;
  displayReferenceNumber: boolean;
  removeSolesmesMarkings: boolean;
  showAmen: boolean;
  isTextOnly: boolean;
  form: ReadingTypeOrBoth;
  readingDisplay: ReadingDisplayType;
  textDisplay: TextDisplayType;
  showAcclamations: boolean;
  numberOfReaders: number;
  translationIds: number[];
  language: Language;
  pageBreakBefore: boolean;
  centerTitle: boolean;
  number: string;
  notateFirstVerses: number;
  verseColumns: number;
  subtitle: string;
  useSSMMusic: boolean;
  additionalAntiphonText: boolean;
  spacingLinear: number;
  spacingNonLinear: number;
  spacingHorizontal: Spacing;
  spacingVertical: number;
  referenceId: number;
};

export const ORDO_CARD_CONFIG_FRAGMENT = gql`
  fragment OrdoCardConfig on OrdoCardConfig {
    id
    noteId
    antiphonId
    verseId
    textId
    optionId
    language
    collectionId
    compositionId
    verseCollectionId
    verseCompositionId
    hymnId
    hymnTuneId
    stackChantVerses
    resourceGroupId
    primaryResourceId
    resourceIds
    includeGloryBe
    notation
    isVerseGrouped
    verseStyle
    keyOffset
    verseOptions {
      id
      index
      isSelected
    }
    verseCount
    displayChordSymbols
    displayHarmonizations
    displayReferenceNumber
    removeSolesmesMarkings
    showAmen
    isTextOnly
    form
    readingDisplay
    textDisplay
    showAcclamations
    numberOfReaders
    translationIds
    pageBreakBefore
    centerTitle
    number
    notateFirstVerses
    verseColumns
    subtitle
    useSSMMusic
    additionalAntiphonText
    spacingLinear
    spacingNonLinear
    spacingHorizontal
    spacingVertical
    referenceId
  }
`;
