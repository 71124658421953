import { ApolloError, MutationHookOptions, MutationTuple } from '@apollo/client';
import { SaveState, SaveStateContext } from 'context/SaveStateContext';
import { useMutation } from 'hooks/queries/useMutation';
import { GQLDocument, GQLMutation, GQLQuery, UpdateQueryContext } from 'queries/gql-generics';
import { useCallback, useContext } from 'react';

/**
 * When a mutation called with this hook is saving, it will be indicated to the user in the navbar of the ordo edit page.
 */
export function useSavingChangesMutation<Q extends GQLMutation<Q, U>, U extends GQLQuery<U>>(
  mutation: GQLDocument<GQLMutation<Q, U>>,
  options?: MutationHookOptions<Q['Data'], Q['Variables'], UpdateQueryContext<U>>,
): MutationTuple<Q['Data'], Q['Variables']> {
  const { setSaveState } = useContext(SaveStateContext);

  const _onCompleted = options?.onCompleted;
  const _onError = options?.onError;
  // wrap completion callback
  const onCompleted = useCallback((data: Q['Data']) => {
    setSaveState(SaveState.SAVED);
    _onCompleted?.(data);
  }, [_onCompleted, setSaveState]);

  // wrap error callback
  const onError = useCallback((error: ApolloError) => {
    setSaveState(SaveState.SAVE_ERR);
    return _onError?.(error);
  }, [_onError, setSaveState]);

  const [mutationFunction, mutationData] = useMutation<Q, U>(
    mutation,
    {
      ...options,
      onCompleted,
      onError,
      update: options?.update ?? mutation.updater
    },
  );

  const mutate: typeof mutationFunction = useCallback(options => {
    setSaveState(SaveState.SAVING);
    return mutationFunction(options);
  }, [mutationFunction, setSaveState]);
  return [mutate, mutationData];
}
