/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';

export type ReferralStatus = 'In Trial'|'Pending'|'Payable'|'Paid'|'Waiting';
export type ReferrerDashboardData = {
  name: string|null;
  imageUrl: string|null;
  partnerSince: string|null;
  referrals: {
    refereeName: string;
    createdAt: string;
    status: ReferralStatus;
  }[];
}

// Gets the current user's own referrer ID
export interface GetReferrerId extends GQLQuery {
  Variables: never;
  Data: { referrerId: string };
}
export const GetReferrerId: GQLDocument<GetReferrerId> = {
  query: gql`
    query getReferrerId {
      referrerId
    }
  `
}

// Gets the name of the referrer (or email) of the user who referred the current user, if applicable
export interface GetReferrerName extends GQLQuery {
  Variables: never;
  Data: { referrerName: string };
}
export const GetReferrerName: GQLDocument<GetReferrerName> = {
  query: gql`
    query getReferrerName {
      referrerName
    }
  `
}

export interface IsReferrerIdValid extends GQLQuery {
  Variables: { referrerId: string };
  Data: {
    isReferrerIdValid: {
      valid: boolean;
      name: string|null;
    }
  }
}
export const IsReferrerIdValid: GQLDocument<IsReferrerIdValid> = {
  query: gql`
    query isReferrerIdValid($referrerId: String!) {
      isReferrerIdValid(referrerId: $referrerId) {
        valid
        name
      }
    }
  `
}

export interface GetReferrerDashboardData extends GQLQuery {
  Variables: {
    referrerId: string;
    referrerCode: string;
  }
  Data: {
    referrerDashboardData: ReferrerDashboardData;
  }
}
export const GetReferrerDashboardData: GQLDocument<GetReferrerDashboardData> = {
  query: gql`
    query referrerDashboardData($referrerId: String!, $referrerCode: String!) {
      referrerDashboardData(referrerId: $referrerId, referrerCode: $referrerCode) {
        name
        imageUrl
        partnerSince
        referrals {
          refereeName
          createdAt
          status
        }
      }
    }
  `
}

export interface CreateReferral extends GQLMutation<CreateReferral> {
  Variables: {
    referrerId: string;
  }
  Data: unknown;
}
export const CreateReferral: GQLDocument<CreateReferral> = {
  mutation: gql`
    mutation createReferral($referrerId: String!) {
      createReferral(referrerId: $referrerId)
    }
  `
}

export interface SendReferralEmail extends GQLMutation<SendReferralEmail> {
  Variables: {
    email: string;
  }
  Data: unknown;
}
export const SendReferralEmail: GQLDocument<SendReferralEmail> = {
  mutation: gql`
    mutation sendReferralEmail($email: String!) {
      sendReferralEmail(email: $email)
    }
  `
}
