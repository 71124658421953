import dayjs from 'utils/dayjs';
import { Season } from "utils/api-types/ordo/Season"

const easterInYear = (year: number): Date => {
  // this is a classic algorithm for getting easter for a given year
  const c = Math.floor(year/100);
  const n = year - 19*Math.floor(year/19);
  const k = Math.floor((c - 17)/25);
  let i = c - Math.floor(c/4) - Math.floor((c - k)/3) + 19*n + 15;
  i = i - 30*Math.floor((i/30));
  i = i - Math.floor(i/28)*(1 - Math.floor(i/28)*Math.floor(29/(i + 1))*Math.floor((21 - n)/11));
  let j = year + Math.floor(year/4) + i + 2 - c + Math.floor(c/4);
  j = j - 7*Math.floor(j/7);
  const l = i - j;
  const month = 3 + Math.floor((l + 40)/44);
  const day = l + 28 - 31*Math.floor(month/4);
  // construct a date with our calculations
  return dayjs.utc(`${year}-${month}-${day}`).toDate();
}

// According to Wikipedia:
// | It is possible to compute the date of Advent Sunday by adding three days
// | to the date of the last Thursday of November; it can also be computed as
// | the Sunday before the first Thursday of December.
const adventSundayInYear = (year: number): Date => {
  // start with last day in November
  let date = dayjs.utc(`${year}-11-30`);
  // three days after the last Thursday in November:
  // (date.day() + 3) % 7 goes back the number of days needed to reach a Thursday (weekday 4)
  // - 3 goes forward another 3 days
  return date.subtract((date.day() + 3) % 7 - 3, 'days').toDate();
}

// TODO: this disagrees with the calendar API in 2 out of 7 years, when Epiphany (where transferred to a Sunday)
// is celebrated on the 7th or 8th (and the Baptism of the Lord on the Monday following).
// The Calendar API in these instances gives Ordinary Time starting on the Tuesday instead of the Monday.
// This function always returns the Monday following the Sunday after January 6.
// If you're celebrating something other than the Baptism of the Lord on the Monday January 8th or 9th,
// you are going to be considering it Ordinary Time anyway.
const postEpiphanyInYear = (year: number): Date => {
  // start with Epiphany (Jan 6)
  const date = dayjs.utc(`${year}-01-06`);
  // find the day after the first Sunday after the 6th:
  // 7 - date.day() is the next Sunday (weekday 0)
  // + 1 for the day after
  return date.add(7 - date.day() + 1, 'days').toDate();
}

// a general liturgical date has a function representing the date that can be given a year
// to resolve to a specific liturgical date
interface GeneralLiturgicalDate {
  dateFunction: (year: number) => Date;
  prevSeason: Season;
}

interface SpecificLiturgicalDate {
  date: Date;
  prevSeason: Season;
}

const generalLiturgicalDates: GeneralLiturgicalDate[] = [
  {
    // Post Epiphany, i.e., the day after Baptism of the Lord, the start of Ordinary Time
    dateFunction: postEpiphanyInYear,
    prevSeason: Season.CHRISTMAS,
  },
  {
    // Ash Wednesday, the start of Lent
    dateFunction: year => dayjs(easterInYear(year)).subtract(46, 'days').toDate(),
    prevSeason: Season.ORDINARY,
  },
  {
    // Easter, the start of Eastertide
    dateFunction: easterInYear,
    prevSeason: Season.LENT,
  },
  {
    // Post Pentecost, i.e., the day after Pentecost, the resumption of Ordinary Time
    dateFunction: year => dayjs(easterInYear(year)).add(50, 'days').toDate(),
    prevSeason: Season.EASTER,
  },
  {
    // Advent Sunday, the start of Advent
    dateFunction: adventSundayInYear,
    prevSeason: Season.ORDINARY,
  },
  {
    // Christmas, the start of Christmastide
    dateFunction: year => dayjs.utc(`${year}-12-25`).toDate(),
    prevSeason: Season.ADVENT,
  },
];

// applies conversion function to the actual GeneralLiturgicalDate object, used for mapping
const convertGeneralLiturgicalDateToSpecific = (year: number) => ({dateFunction, prevSeason}: GeneralLiturgicalDate): SpecificLiturgicalDate => ({
  date: dateFunction(year),
  prevSeason
});

// returns one of ADVENT, CHRISTMAS, LENT, EASTER, ORDINARY
export const calculateLiturgicalSeason = (date: Date): Season => {
  const closestDate = generalLiturgicalDates
    // figure out the specific dates prior to the given date
    .map(convertGeneralLiturgicalDateToSpecific(date.getFullYear()))
    // find the first liturgical date 
    .find(ld => date < ld.date);

  // return the corresponding season, or christmas
  return closestDate?.prevSeason ?? Season.CHRISTMAS;
}

export const calculateLiturgicalYear = (date?: Date | dayjs.Dayjs | null): number | undefined => {
  if (!date || !(date instanceof Date ? date.getDate() : date.isValid())) {
    // if date is invalid return undefined
    return undefined;
  }
  const day = dayjs(date);
  let year = day.year();
  const advent1 = adventSundayInYear(year);
  if (!day.isBefore(advent1)) {
    // if advent 1 comes before or is equal to the date passed in, this date is part of the following liturgical year:
    ++year;
  }
  return year;
}
