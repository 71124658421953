import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';
import { CardTypes } from 'utils/ordo/card/CardTypes';

export type VerseSplitType =
  | 'DOUBLE_LINE_BREAK'
  | 'EVERY_PSALM_TONE_TERMINATION';

const emptyLineNormalizerRegex = /\n\s*\n/g;
const newLineNormalizerRegex = /\r\n/g;
// this regex will find lines that do not end with † or *, and all newlines after them
const verseBreakNormalizerRegex =
  /([?.!][^\S\n]*\n(?=\n|[^a-zA-Z]*[A-Z]))\n*(?=\S)/g;

export const splitVersesByType = (
  text?: string,
  type: VerseSplitType = 'EVERY_PSALM_TONE_TERMINATION',
): string[] => {
  if (!text) return [];
  text = text
    .replace(emptyLineNormalizerRegex, '\n\n')
    .replace(newLineNormalizerRegex, '\n\n');
  if (type === 'EVERY_PSALM_TONE_TERMINATION') {
    text = text.replace(verseBreakNormalizerRegex, '$1\n');
  }
  const result = text.replace(/(^|\n)\[\d+\]/g, '$1').split('\n\n');
  return result;
};

export const verseSplitTypeForCard = (type?: CardTypes, config: OrdoCardConfig = {}) =>
  type === CardTypes.RESPONSORIAL_PSALM ||
  type === CardTypes.ALLELUIA ||
  config.isVerseGrouped
    ? 'DOUBLE_LINE_BREAK'
    : 'EVERY_PSALM_TONE_TERMINATION';

export const splitVerses = (
  text?: string,
  cardType?: CardTypes,
  cardConfig?: OrdoCardConfig,
) => splitVersesByType(text, verseSplitTypeForCard(cardType, cardConfig));
