import { processText } from './processText';

/**
 * Match poetic text with possible whitespace before or after.
 * The "whitespace" after can be either two newline characters
 * or a newline character followed by a Reader mark (found in Passion Gospels)
 * If one of these is found following the poetic section, it does not need to end with a red slash
 */
const poeticRegex = /(\s*)?<poetic>([\S\s]+?)<\/poetic>(\n(?:\n|\{[A-Z]\}))?/g;
const redSlash = '<span class="color-red-on-screen"> / </span>';

export const processPoeticParagraph = (
  text?: string,
  paragraphBreak = '<br/><br/>',
): string => {
  const processed = processText(text || '').trim();

  if (!processed) return '';

  return processed.replace(
    poeticRegex,
    (full, preSpace, group, postSpace, matchOffset) => {
      // ignore any prespace that does not include two newline characters:
      if (!/\n.*\n/.test(preSpace)) preSpace = '';
      preSpace = preSpace || redSlash;
      postSpace = postSpace || redSlash;
      if (matchOffset === 0) preSpace = '';
      if (matchOffset + full.length === processed.length) postSpace = '';
      return (
        preSpace +
        group
          .replace(/(<\/div>)\s*\n+|\n+(<div)\b/g, '$1$2')
          .replace(/(<br\/>)*\n{2,}(<br\/>)*/g, paragraphBreak)
          .replace(/(\n)?<br\/>(\n)?/g, '<br/>')
          .replace(/\n<span\b/g, '<br/><span')
          .replace(/\n/g, redSlash) +
        postSpace
      );
    },
  );
};
