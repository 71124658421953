/* eslint-disable @typescript-eslint/no-redeclare */
import { PlanCategory, PlanId } from 'utils/api-types/plans';

interface GtagEventData {
  Data: { [key: string]: string|boolean|number|null|undefined };
}

export type DataGtagEvent = GtagEventData & {
  name: DataGtagEventName;
}

export type GtagName<D extends DataGtagEvent> = Omit<D, 'Data'>;

export type DataGtagEventName =
  | 'upsell_conversion'
  | 'end_of_trial_conversion'
  | 'open_signup_conversion'
  | 'checkout_conversion';

export interface UpsellConversion extends DataGtagEvent {
  Data: {
    source: string;
    plan: string;
  };
}
export const UpsellConversion: GtagName<UpsellConversion> = { name: 'upsell_conversion' }

export interface EndOfTrialConversion extends DataGtagEvent {
  Data: {
    page_definition: string;
    plan_id: PlanId;
    method: string;
  };
}
export const EndOfTrialConversion: GtagName<EndOfTrialConversion> = { name: 'end_of_trial_conversion' }

export interface OpenSignupConversion extends DataGtagEvent {
  Data: {
    plan_category: PlanCategory;
  };
}
export const OpenSignupConversion: GtagName<OpenSignupConversion> = { name: 'open_signup_conversion' }

export interface CheckoutConversion extends DataGtagEvent {
  Data: {
    plan_category: PlanCategory;
    isAnnual: boolean;
  };
}
export const CheckoutConversion: GtagName<CheckoutConversion> = { name: 'checkout_conversion' }
