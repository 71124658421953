import { css, cx } from '@emotion/css';
import { NotatedVerse } from 'components/shared/NotatedVerse';
import { GabcPsalmTones } from 'gabc-utils';
import { Text, TranslationOnText } from 'queries/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScriptureReference } from './ScriptureReference';
import { Spacing } from './editor/settings/SpacingToggler';

interface NotatedVersesProps {
  additionalAntiphonText?: string|null;
  versePsalmTone?: GabcPsalmTones;
  verseText?: Text|null;
  verseTranslation?: TranslationOnText|null;
  verses: string[];
  useVersicleSymbol?: boolean;
  includeGloryBe: boolean;
  gbTranslation?: TranslationOnText|null;
  gbPsalmTone?: GabcPsalmTones;
  width: number;
  verseCount: number;
  removeSolesmesMarkings?: boolean;
  spacingHorizontal?: Spacing | null;
  spacingVertical?: number | null;
  onLoad?: () => void;
}

export const NotatedVerses: React.FC<NotatedVersesProps> = ({
  additionalAntiphonText,
  versePsalmTone,
  verseText,
  verseTranslation,
  verses,
  useVersicleSymbol = false,
  includeGloryBe,
  gbTranslation,
  gbPsalmTone,
  width,
  verseCount,
  removeSolesmesMarkings,
  spacingHorizontal,
  spacingVertical,
  onLoad,
}) => {

  const [versesLoaded, setVersesLoaded] = useState(false);
  const onVersesLoaded = useCallback(() => setVersesLoaded(true), []);
  const [additionalAntiphonTextLoaded, setAdditionalAntiphonTextLoaded] = useState(false);
  const onAdditionalAntiphonTextLoaded = useCallback(() => setAdditionalAntiphonTextLoaded(true), []);
  const [gloryBeLoaded, setGloryBeLoaded] = useState(false);
  const onGloryBeLoaded = useCallback(() => setGloryBeLoaded(true), []);

  const isLoaded =
    (versesLoaded || !verses.length) &&
    (gloryBeLoaded || !includeGloryBe) &&
    (additionalAntiphonTextLoaded || !additionalAntiphonText);
  useEffect(() => {
    if (isLoaded) {
      onLoad?.();
    }
  }, [isLoaded, onLoad]);

  const additionalAntiphonVerses = useMemo(
    () => (additionalAntiphonText ? [additionalAntiphonText] : []),
    [additionalAntiphonText],
  );

  return (
    <div className={cx('notated-verses', style)}>

      {additionalAntiphonText && <div style={{marginBottom: 24}}>
        <div className='_EXPORT' style={{ fontSize: '80%', marginBottom: 4, fontStyle: 'italic' }}>Additional Antiphon Text</div>
        <NotatedVerse
          verses={additionalAntiphonVerses}
          language={verseTranslation?.language ?? 'en'}
          psalmTone={versePsalmTone}
          useVersicleSymbol={useVersicleSymbol}
          width={width}
          spacingHorizontal={spacingHorizontal}
          spacingVertical={spacingVertical}
          onLoad={onAdditionalAntiphonTextLoaded}
        />
      </div>}

      {verseText && !!verses.length && <ScriptureReference text={verseText.originalSource}/>}

      {verseTranslation && !!verses.length && <NotatedVerse
        verses={verses}
        language={verseTranslation.language}
        psalmTone={versePsalmTone}
        useVersicleSymbol={useVersicleSymbol}
        width={width}
        spacingHorizontal={spacingHorizontal}
        spacingVertical={spacingVertical}
        onLoad={onVersesLoaded}
        startingVerseNum={additionalAntiphonVerses.length + 1}
      />}

      {includeGloryBe && gbTranslation && <NotatedVerse
        verses={[gbTranslation.content]}
        language={gbTranslation.language}
        psalmTone={gbPsalmTone}
        width={width}
        isDoxology={true}
        removeSolesmesMarkings={removeSolesmesMarkings}
        spacingHorizontal={spacingHorizontal}
        spacingVertical={spacingVertical}
        onLoad={onGloryBeLoaded}
      />}
    </div>
  )
}

const style = css`
  .scripture-reference {
    padding-bottom: 15px;
  }
`;
