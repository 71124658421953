import { TimeMapEntryWithTicks } from './processTimeMapForTicks';

const ACTIVE_NOTE_CLASS = 'MIDI-active-note';
const ACTIVE_NOTE_CLASS_A = `${ACTIVE_NOTE_CLASS}-A`;
const ACTIVE_NOTE_CLASS_B = `${ACTIVE_NOTE_CLASS}-B`;
const ACTIVE_NOTE_CLASSES = [
  ACTIVE_NOTE_CLASS,
  ACTIVE_NOTE_CLASS_A,
  ACTIVE_NOTE_CLASS_B,
];
const ACTIVE_NOTE_SELECTOR = `.${ACTIVE_NOTE_CLASS}`;
const MIDI_MUSIC_PARENT_CLASS = 'MIDI-music';
const MIDI_MUSIC_PARENT_SELECTOR = `.${MIDI_MUSIC_PARENT_CLASS}`;
const MIDI_VERSES_PARENT_CLASS = 'MIDI-verses';
const MIDI_VERSES_PARENT_SELECTOR = `.${MIDI_VERSES_PARENT_CLASS}`;

export const deactivateAllNotesForRef = (
  svgParentRef?: React.RefObject<HTMLElement>,
) => {
  svgParentRef?.current
    ?.querySelectorAll(ACTIVE_NOTE_SELECTOR)
    ?.forEach((elem) => elem.classList.remove(...ACTIVE_NOTE_CLASSES));
};

const activateElements = (
  svgParentRef: React.RefObject<HTMLElement> | undefined,
  parentSelector: string,
  idSelector: string,
) => {
  const parentElem =
    svgParentRef?.current?.querySelector(parentSelector) ??
    svgParentRef?.current;
  const elements = parentElem?.querySelectorAll(idSelector);
  elements?.forEach((elem) => elem.classList.add(ACTIVE_NOTE_CLASS));
  return elements;
}

export const activateNotesForExactTick = (
  midiTimeMap: TimeMapEntryWithTicks[],
  lyricIdx: number,
  tick: number,
  svgParentRef?: React.RefObject<HTMLElement>,
) => {
  if (svgParentRef) {
    const entry = midiTimeMap.find((entry) => entry.tick === tick);
    let ids: string[] = entry?.active ?? [];
    const midiIdx = entry?.midiIdx;
    const parentSelector = midiIdx === 1 ? MIDI_VERSES_PARENT_SELECTOR : MIDI_MUSIC_PARENT_SELECTOR;
    deactivateAllNotesForRef(svgParentRef);
    if (ids.length) {
      const regexInvisibleNote = /^invisible-note-\d+\.\d+/;
      // Verovio gives the quilismas an id starting with quilisma so that we can use our own quilisma glyph,
      // but exsurge just calls them notes:
      const quilismasAsNotes = ids
        .filter((id) => /^quilisma-/.test(id))
        .map((id) => 'note-' + id.slice(9));
      const invisiblesAsNotes = ids
        .filter((id) => regexInvisibleNote.test(id))
        .map((id) => id.slice(10).replace(/\.\d+$/, ''));
      ids = [
        ...ids.filter((id) => !regexInvisibleNote.test(id)),
        ...quilismasAsNotes,
        ...invisiblesAsNotes,
      ];
      const idSelector = ids.map((id) => '#' + id).join(',');
      const elements = activateElements(svgParentRef, parentSelector, idSelector);
      if (
        elements?.length === 1 &&
        elements[0].classList.contains('porrectus')
      ) {
        const { classList } = elements[0];
        if (classList.contains('porrectus-start')) {
          classList.add(ACTIVE_NOTE_CLASS_A);
        } else if (classList.contains('porrectus-end')) {
          elements[0].previousElementSibling?.classList.add(
            ACTIVE_NOTE_CLASS,
            ACTIVE_NOTE_CLASS_B,
          );
        }
      }
    }
    if (lyricIdx >= 0) {
      const idSelector = `span.note#syllable-${lyricIdx}`;
      activateElements(svgParentRef, parentSelector, idSelector);
    }
  }
  return tick;
};

export const activateNotesForTick = (
  midiTimeMap: TimeMapEntryWithTicks[],
  lyricTickMap: number[],
  tick: number,
  lastTickProcessed: number | null,
  svgParentRef?: React.RefObject<HTMLElement>,
) => {
  const entryIdx = midiTimeMap.findIndex((entry) => entry.tick > tick) - 1;
  let lyricIdx = lyricTickMap.findIndex((lyricTick) => lyricTick > tick) - 1;
  if (lyricIdx === -2 && tick > lyricTickMap[lyricTickMap.length - 1]) {
    lyricIdx = lyricTickMap.length - 1;
  }
  if (entryIdx >= 0) {
    const entry = midiTimeMap[entryIdx];
    const exactTick = entry.tick;
    if (exactTick !== lastTickProcessed) {
      return activateNotesForExactTick([entry], lyricIdx, exactTick, svgParentRef);
    }
    return exactTick;
  }
  console.warn(`tick ${tick} not found in time map`);
  return null;
};
