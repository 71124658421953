import React, { useContext, useRef, RefObject, useEffect, useCallback } from 'react';
import { useAlleluiaOptionsQuery } from 'hooks/text';
import { LoadingMessage } from 'components/gql/LoadingMessage';
import { ErrorMessage } from 'components/gql/ErrorMessage';
import { CardTypes } from 'utils/ordo/card/CardTypes';
import { CardContext, ICardContext } from 'context/CardContext';
import { OfficialAlleluiaTranslation, AlleluiaDisplayMode } from './partials/OfficialAlleluiaTranslation';
import { AlleluiaMusic } from './partials/AlleluiaMusic';
import { MusicalErrorAlert } from 'components/shared/MusicalErrorAlert';
import { AlleluiaStates } from './AlleluiaStates';
import { isTextAlleluiaVerse } from 'utils/functions/card';
import { useMidiCallbacks } from 'hooks/useMidiCallbacks';
import { Season } from 'utils/api-types/ordo/Season';
import { useIdQuery } from 'hooks/queries/useQuery';
import { GetTextOption } from 'queries/text-option';
import { MidiVerovioOrPassthru } from 'components/verovio/MidiVerovioOrPassthru';
import { GetText } from 'queries/text';
import { RenderMidiCallback } from 'components/verovio/Verovio';

interface ConfiguredStateProps {
  season?: Season;
  isActive?: boolean;
}

export const ConfiguredState: React.FC<ConfiguredStateProps> = ({ season, isActive = false }) => {
  const { card, config, onLoaded, updateView, addGrandStaff, textOptions }: ICardContext = useContext(CardContext);

  const ref: RefObject<HTMLDivElement> = useRef(null);

  let option = textOptions?.find(({id}) => id === config.optionId);
  const { loading: loadingOption, error: optionError, data: optionData } = useIdQuery(GetTextOption, option ? null : config.optionId);
  const {
    loading: loadingAlleluiaOptions,
    error: alleluiaOptionsError,
    data: { textOptions: alleluiaOptionsForCard } = {},
  } = useAlleluiaOptionsQuery(card.id, season);
  if (!option) {
    option = optionData?.textOption;
  }
  const { data: verseTextData } = useIdQuery(GetText, option ? null : config.verseId);
  const verseText = verseTextData?.text;

  useEffect(() => {
    if (!loadingOption && !loadingAlleluiaOptions && !config.compositionId) {
      onLoaded();
    }
  }, [loadingOption, config, onLoaded, loadingAlleluiaOptions]);
  const svgOrMei = card.svg ?? card.mei;
  const { setMidiData, setMidiPsalmToneData } = useMidiCallbacks(isActive, card.id, (svgOrMei)?.name, card.name, { ref, isMidiAvailable: !!(svgOrMei) });
  const onRenderMidi: RenderMidiCallback = useCallback((...args) => {
    setMidiData(...args, svgOrMei?.audioUrls, card.config.keyOffset);
  },[card.config.keyOffset, setMidiData, svgOrMei?.audioUrls]);

  const midiVerovio = (svgOrMei) && <MidiVerovioOrPassthru svg={card.svg ?? undefined} mei={card.mei ?? undefined} verseStyle={config.verseStyle} onRenderMidi={onRenderMidi} {...{ setMidiPsalmToneData }} keyOffset={config.keyOffset} />;
  if (loadingOption || loadingAlleluiaOptions) {
    return <>{midiVerovio}<LoadingMessage /></>;
  }

  if (optionError || alleluiaOptionsError) {
    return <ErrorMessage
      error={optionError || alleluiaOptionsError}
      headline={'Error loading selected text option:'}
    />;
  }

  if (!config.verseId || !alleluiaOptionsForCard) {
    return null;
  }

  if ((svgOrMei)?.error) {
    return <MusicalErrorAlert />;
  }

  const verse = verseText ?? option?.texts.find((t) => t.id === config.verseId);
  const displayMode = (card.type === CardTypes.VERSE_BEFORE_GOSPEL && config.antiphonId === 0) ? AlleluiaDisplayMode.VERSE_ONLY : AlleluiaDisplayMode.DEFAULT;
  const antiphonId = CardTypes.VERSE_BEFORE_GOSPEL ? config.antiphonId : undefined;
  const alleluiaAntiphons = alleluiaOptionsForCard.flatMap((options) => options.texts).filter((text) => !isTextAlleluiaVerse(text));
  const antiphon =
    antiphonId === 0
      ? undefined
      : alleluiaAntiphons.length === 1
      ? alleluiaAntiphons[0]
      : alleluiaAntiphons.find((antiphon) => antiphon.id === antiphonId);
  if (displayMode === AlleluiaDisplayMode.DEFAULT && !antiphonId) {
    updateView([AlleluiaStates.LENTEN_GOSPEL_ACCLAMATION, AlleluiaStates.VERSE]);
    return null;
  }

  const isLentenGospelAcclamation = card.type === CardTypes.VERSE_BEFORE_GOSPEL;
  return (
    <div ref={ref} className='card-configured antiphon _EXPORT'>
      {config.compositionId
        ? <>
            {midiVerovio}<AlleluiaMusic
            config={config}
            verseText={verse}
            textOption={option}
            displayMode={displayMode}
            cardMei={card.mei}
            cardSvg={card.svg}
            parentRef={ref}
            addGrandStaff={addGrandStaff}
            isLentenGospelAcclamation={isLentenGospelAcclamation}
            onLoaded={onLoaded}
          /></>
        : <OfficialAlleluiaTranslation
            verse={verse}
            textOption={option}
            displayMode={displayMode}
            antiphon={antiphon}
            isLentenGospelAcclamation={isLentenGospelAcclamation}
          />}
    </div>
  );
};
