const subscriptionNumber = (number: number) => ('000000' + number.toFixed(0)).slice(-6)
const termsOfUse = (num: string) => `Produced using Source & Summit by subscriber #${num}.`;
const generalTermsOfUse = (num: string) => `This reproduced work was produced using Source & Summit (sourceandsummit.com) by subscriber #${num} and is subject to those terms and restrictions governing its use.`;

export const getTermsOfUseAttribution = (
  number: number,
  useGeneralAttribution = false
): string => {
  const num = subscriptionNumber(number);
  if (useGeneralAttribution) {
    return generalTermsOfUse(num);
  } else {
    return termsOfUse(num);
  }
};
