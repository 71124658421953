import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, WatchQueryFetchPolicy, defaultDataIdFromObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { isAutoExportQueryParam } from 'hooks/useQueryParam';
import type { ResourceReferenceCollection } from 'queries/resource-reference-collection';
import { PermissionSetId } from 'utils/api-types/PermissionSetId';
import { WithId } from 'utils/api-types/WithNameAndId';
import { MetaDisplayData } from 'utils/api-types/composition/MetaDisplayData';
import { getAuthorizationToken } from 'utils/authorization/authorization';
import dayjs from 'utils/dayjs';
import { calculateLiturgicalYear } from 'utils/ordo/calculateLiturgicalSeason';
import { paths } from 'utils/paths';
import { apiOrigin } from '../env';
import { offsetLimitPagination } from './offsetLimitPagination';

// This allows operations marked with the 'retry' property to be retried for thirty seconds before failing
// (when encountering network errors)
const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getAuthorizationToken();
  if (!token) {
    return forward(operation);
  }
  if ('id_token' in token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token.id_token}`,
      },
    });
  };
  return forward(operation);
});

// reset cache if main script hash changed
const mainScript = Array.from(document.scripts).map(s => s.src).find(src => /\/static\/js\/main\.\w+\.js$/.test(src)) ?? '';
const lastMainScript = localStorage.getItem('mainScript') ?? '';
const mainScriptChanged = lastMainScript !== mainScript;
if (mainScriptChanged) {
  localStorage.setItem('mainScript', mainScript ?? '');
}
// only reset the cache if there was a main script found in the script tags, and it was different from last time:
const needToClearCache = mainScript && mainScriptChanged;

/**
 * By creating the ApolloClient instance via this custom hook,
 * we can pass in a custom, context-aware callback function
 * for mutation requests which can be used to update the global
 * ordo context and display messages to the user accordingly
 */
export const initApolloClient = () => {
  const defaultFetchPolicy: WatchQueryFetchPolicy = isAutoExportQueryParam() ? 'cache-first' : 'cache-and-network';
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id ? `${object.__typename}-${object.id}` : defaultDataIdFromObject(object),
    resultCaching: true,
    typePolicies: {
      UserVariables: {
        keyFields: ['email'],
        fields: {
          daysToRenewal: {
            read: (_, { readField }) => {
              const renewalDate = readField<string>('renewalDate');
              if (renewalDate) {
                const renewOn = dayjs.utc(renewalDate);
                const duration = dayjs.duration(renewOn.diff(dayjs()));
                return Math.ceil(duration.asDays());
              }
              return null;
            }
          },
        }
      },
      ChoirEmail: {
        keyFields: ['email'],
      },
      PricingPlan: {
        keyFields: ['category'],
      },
      OrdoCardSummary: {
        keyFields: false,
      },
      AvailableDataByLanguage: {
        keyFields: false,
      },
      Ordo: {
        fields: {
          config: {
            merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming),
          },
          liturgicalYear: {
            read: (_, { readField }) => {
              const date = readField<string>('eventDate');
              return calculateLiturgicalYear(dayjs(date)) ?? null;
            }
          },
          canDisplayAntiphonVersesOtherThanCommunion: {
            read: (_, { readField }) => {
              const permissionSetSeedIds = readField<PermissionSetId[]>('permissionSetSeedIds');
              const set = new Set(permissionSetSeedIds);
              return set.has(PermissionSetId.AntiphonVersesOtherThanCommunion);
            }
          },
          treatAsBasic: {
            read: (_, { readField }) => {
              const permissionSetSeedIds = readField<PermissionSetId[]>('permissionSetSeedIds');
              const set = new Set(permissionSetSeedIds);
              return set.has(PermissionSetId.Basic);
            }
          },
        }
      },
      OrdoRole: {
        fields: {
          defaultRoleConfig: {
            merge: (existing, incoming, { mergeObjects }) => mergeObjects(existing, incoming),
          }
        }
      },
      ResourceReferenceCollection: {
        fields: {
          nameWithEdition: {
            read: (_, { readField }) => {
              const name = readField<ResourceReferenceCollection['name']>('name');
              const edition = readField<ResourceReferenceCollection['edition']>('edition');
              return `${name}${!edition || edition.match(/^\d{4}$/) ? '' : `, ${edition}`}`;
            }
          }
        }
      },
      TemplateCard: {
        keyFields: ['sharedId'],
        fields: {
          sharedId: {
            read: (_, { readField }) => {
              const additionId = readField<string>('additionId');
              const textCategory = readField<WithId>('textCategory');
              const textCategoryId = textCategory ? readField<number>('id', textCategory) : undefined;

              if (additionId) {
                return `addition-${additionId}`;
              } else if (textCategoryId) {
                return `text-category-${textCategoryId}`;
              } else {
                console.warn('Ill-formed template card: no additionId or textCategory');
                return '';
              }
            }
          },
        },
      },
      CollectionComposition: {
        fields: {
          collectionName: {
            read: (_, { readField }) => {
              const metaDisplayData = readField<MetaDisplayData[]>('collectionCompositionDisplayData');
              const collectionDisplayData = metaDisplayData?.find(
                (metadata) => readField<string>('key', metadata) === 'collection',
              );
              const val = collectionDisplayData && readField<string>('value', collectionDisplayData);
              return val ?? null;
            },
          }
        }
      },
      Query: {
        fields: {
          // paged queries should get a merge function (provided here through the offsetLimitPagination helper)
          // the argument tells the merge function that when the listed arguments are different, we are paging into a different set for the query
          pagedCompositionsForCollectionType: offsetLimitPagination(['textId', 'type', 'language', 'liturgicalYear']),
          searchCompositions: offsetLimitPagination(['name', 'type', 'attributeName', 'attributeValue', 'collection', 'language', 'showOnlySSMHymns']),
          ordos: offsetLimitPagination(['groupId', 'today']),
          findAntiphon: offsetLimitPagination(['content','contentTypes','isOfficial','language','season','collectionIds']),
          searchReferences: offsetLimitPagination(['query', 'type', 'collectionId']),
        }
      }
    }
  });

  let cachePromise: Promise<void> | null = null;
  if (window.location.pathname !== paths.clear_apollo) {
    const cachePersistor = new CachePersistor({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      persistenceMapper: async (json: string) => {
        const obj = JSON.parse(json) as { [key: string]: { __typename?: string } };
        const addCache = (obj: object): object =>
          Object.fromEntries(
            Object.entries(obj)
              .filter(
                ([key, value]) =>
                  !/^(Error|HostedPage)$/.test(value?.__typename ?? ''),
              )
              .map(([key, value]) => [
                key,
                !(value && typeof value === 'object' && '__typename' in value)
                  ? value
                  : {
                      ...addCache(value),
                      cached: true,
                    },
              ]),
          );
        return JSON.stringify(addCache(obj));
      }
    });
    if (needToClearCache ) {
      console.info({lastMainScript, mainScript, message: 'clearing cache...'});
      cachePromise = cachePersistor.purge();
    } else {
      // otherwise, restore cache
      cachePromise = cachePersistor.restore();
    }
  }

  const client = new ApolloClient({
    link: ApolloLink.from([
      authLink,
      retryLink,
      onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors)
          graphQLErrors.forEach((error) => {
            console.error(`[GraphQL error]:`, JSON.stringify(error, null, 2));
            console.trace(operation.operationName)
          });
        if (networkError) console.error(`[Network error]:`, networkError);
      }),
      new HttpLink({
        uri: `${apiOrigin}/graphql`,
        credentials: 'include',
      }),
    ]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: defaultFetchPolicy,
        nextFetchPolicy: 'cache-first',
      }
    }
  });

  return { client, cachePromise };
}
