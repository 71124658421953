import { useMediaQueries } from './useMediaQueries';

export enum ScreenSize {
  XS = 0,
  SM = 1,
  MD = 2,
  ONEK = 3,
  LG = 4,
  XL = 5,
  XXL = 6,
  PRINT = 7
}

export const useScreenSize = () => {
  const { isExtraSmall, isSmall, isMedium, isOneK, isLarge, isExtraLarge, isPrint } = useMediaQueries();

  if (isPrint) return ScreenSize.PRINT;
  if (isExtraSmall) return ScreenSize.XS;
  if (isSmall) return ScreenSize.SM;
  if (isMedium) return ScreenSize.MD;
  if (isOneK) return ScreenSize.ONEK;
  if (isLarge) return ScreenSize.LG;
  if (isExtraLarge) return ScreenSize.XL;

  return ScreenSize.XXL;
}
