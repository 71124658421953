import deepEqual from 'fast-deep-equal';
import { useSessionStorage } from 'hooks/useSessionStorage';
import { parse } from 'utils/query-string';
import { useMemo, useState } from 'react';
import { getBooleanParam, getStringParam } from 'utils/params/getParam';
import { createDebugLogger } from './createDebugLogger';
import { DebugLogger } from './DebugLogger';

export const useDebugLogger = (key: string): DebugLogger => {
  const params = parse(window.location.search);

  const [debug] = useSessionStorage(
    'debug',
    getStringParam('debug', params)?.split(',') ?? [],
    (initialState, storedState) => !storedState.length || !!initialState?.length
  );
  const [trace] = useSessionStorage(
    'trace',
    !!getBooleanParam('trace', params),
    true
  );
  return useMemo(() => createDebugLogger(key, debug, trace), [key, debug, trace]);
}

export const useLogInfoEffect = (logger: DebugLogger, infoItems: { [key: string]: unknown }, message?: string) => {
  const [latestItems, setLatestItems] = useState<{ [key: string]: unknown }>({});

  let shouldReplaceItems = false;
  Object.entries(infoItems).forEach(([key, value]) => {
    if (!deepEqual(value, latestItems[key])) {
      logger.info(value, message ? `${message}: ${key}` : key);
      shouldReplaceItems = true;
    }
  });
  if (shouldReplaceItems) {
    logger.log('----------');
    setLatestItems(infoItems);
  }
}
