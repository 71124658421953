import { LayoutContext, defaultLayoutContext } from 'context/LayoutContext';
import { useContext } from 'react';

export const useSizeMultiplier = () => {
  const { activeFile } = useContext(LayoutContext);
  if (activeFile === defaultLayoutContext.activeFile) {
    return null;
  }
  const sizeMultiplier = 1 + (activeFile?.config?.contentSize ?? 0) * 0.1;
  return sizeMultiplier;
};
