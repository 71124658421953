import MidiPlayer from 'midi-player-js';
import { Howl, Howler } from 'howler';
import { Soundfont, CacheStorage } from 'smplr';
import { parse } from 'utils/query-string';
import deepEqual from 'fast-deep-equal';
import { processTimeMapForTicks, TimeMapEntryWithPossibleTicks, TimeMapEntryWithTicks } from './processTimeMapForTicks';
import { deactivateAllNotesForRef, activateNotesForTick } from './activateNotes';
import { createDebugLogger } from 'hooks/debug';
import { getStringParam } from 'utils/params/getParam';
import { audioContext } from 'utils/audioContext';
import { MidiData } from 'components/verovio/MidiVerovio';
import { filterFirstNotesOnTrack } from './filterFirstNotesOnTrack';
import { MidiEvent } from './MidiEvent';
import { PartSalience, PlaybackPart } from 'components/share/SharePlaybackOptions';

interface MidiChannel {
  [noteNumber: number]: Promise<ReturnType<Soundfont['start']>>;
}
export interface MidiProgress {
  tick?: number;
  tempo?: number;
  isPlaying?: boolean;
  totalTicks?: number;
  isAvailable?: boolean;
  title?: string;
  supertitle?: string;
  id?: number|null;
  channelCount?: number;
  /**
   * tick number for start of each track (the first entry should be 0)
   */
  trackStarts?: number[];
}
export type MidiProgressCallback = (progress: MidiProgress) => void;
export type SetMidiPsalmToneData = (midiData: MidiData | null) => void;
export interface MidiSong {
  totalTicks: number;
  recordedAudioPercent?: number;
  events: MidiEvent[];
  channelCount: number;
  tracks: {
    startTick: number;
    totalTicks: number;
    events: MidiEvent[];
    tickMultiplier: number;
    lyricTickMap: number[];
  }[]
}
export interface MidiControls {
  isPlaying: () => boolean;
  play: () => void;
  pause: () => void;
  skipToTick: (tickNumber: number, isSeeking?: boolean) => void;
  getCurrentTick: () => number;
  stop: () => void;
  setRelativeTempo: (tempo: number) => void;
  song?: MidiSong;
}
export type SeekMidiTick = (tick: number | (() => number), isSeeking: boolean) => void;

// just for testing, we allow reading the instrument name from the instrument query string parameter:
const { instrument, accompanimentInstrument, soundfont } = parse(window.location.search);
const instrumentName =typeof instrument === 'string' ? instrument : 'acoustic_grand_piano';
const instrumentUrl = `https://gleitz.github.io/midi-js-soundfonts/${soundfont || 'MusyngKite'}/${instrumentName}-mp3.js`;
const accompanimentInstrumentName =typeof accompanimentInstrument === 'string' ? accompanimentInstrument : 'acoustic_grand_piano';
const accompanimentInstrumentUrl = `https://gleitz.github.io/midi-js-soundfonts/${soundfont || 'MusyngKite'}/${accompanimentInstrumentName}-mp3.js`;

const debugLogger = createDebugLogger('midi', getStringParam('debug')?.split(',') ?? []);

const midiCtxt: MidiPlayer.Player & {
  // these are simply missing from the type definition:
  defaultTempo?: number;
  totalTicks?: number;
} = new MidiPlayer.Player();
let progressCallbacks: MidiProgressCallback[] = [];
let relativeTempo = 1;
let volume = 50;
const partToChannel = {
  S: 2,
  A: 3,
  T: 4,
  B: 5,
} as const;
const stereoPan = [-0.25, 0.25, -0.5, 0.5] as const;
const mutedVolume = 0.225 as const;
const channelVolume = [1, 1, 1, 1, 1, 1];
let tickMultiplier = 0;

const storage = new CacheStorage();
const initPlayer = () => {
  const channels: MidiChannel[] = [];
  const stopAllChannels = () => {
    channels.forEach((channel) => {
      Object.keys(channel).forEach((key) => {
        channel[(key as unknown) as number].then(p => p());
      });
    });
    channels.splice(0);
  };
  let ac: AudioContext;
  const pannedInstruments: Soundfont[] = [];
  audioContext.then(async (ctxt) => {
    for (let i = 0; i < 4; ++i) {
      const node = ctxt.createStereoPanner();
      node.pan.setValueAtTime(stereoPan[i], ctxt.currentTime);
      node.connect(ctxt.destination);
      pannedInstruments.push(await new Soundfont(ac = ctxt, { instrumentUrl: i === 0 ? instrumentUrl : accompanimentInstrumentUrl, storage, destination: node }).load);
    }
  });
  const playInstrument = async (sample: Parameters<Soundfont['start']>[0], channel = -1) => {
    const instrument = pannedInstruments[channel] ?? pannedInstruments[0];
    return instrument?.start(sample);
  }
  const stepDuration = 100 / 1000;
  let songStart = 0;
  let isPlaying = false;
  let isHowling = false;
  let isSeeking = false;

  let currentSongTotalTicks = 0;
  let currentSongTime = 0;
  let currentSongTick = 0;
  let nextAnimationFrame = 0;
  let nextProgressTick = 0;
  
  let lastTickProcessed: number | null = null;
  const player: MidiControls = {
    isPlaying: () => isPlaying,
    stop: () => {
      isPlaying = false;
      recordedAudio?.stop();
      window.cancelAnimationFrame(nextAnimationFrame);
      currentSongTime = currentSongTick = nextProgressTick = 0;
      stopAllChannels();
      deactivateAllNotesForRef(svgParentRef);
      updateProgress({ isPlaying: false, tick: 0 });
    },
    pause: () => {
      isPlaying = false;
      recordedAudio?.pause();
      window.cancelAnimationFrame(nextAnimationFrame);
      stopAllChannels();
      lastTickProcessed = null;
    },
    skipToTick: (tickNumber, _isSeeking = false) => {
      window.cancelAnimationFrame(nextAnimationFrame);
      isSeeking = _isSeeking;
      // const recordedAudioPercent = player.song?.recordedAudioPercent ?? 0;
      const recordedAudioPosition = tickNumber / (recordedAudio?.tickMultiplier ?? 1);
      const useRecordedAudio = recordedAudioPosition < (recordedAudio?.howls?.[0]?.duration() ?? 0);
      if (useRecordedAudio) {
        if (player.isPlaying()) {
          if (isSeeking) {
            recordedAudio!.pause();
          } else {
            recordedAudio!.play();
          }
          isHowling = true;
        }
        // we use a slightly positive number to indicate below (in the tick function) that we are waiting
        // for the audio to start up again.
        recordedAudio!.seek(recordedAudioPosition || 0.001);
        lastTickProcessed = null;
        deactivateAllNotesForRef(svgParentRef);
      } else {
        isHowling = false;
        recordedAudio?.stop();
        lastTickProcessed = activateNotesForTick(midiTimeMap, verseLyricTickMap, tickNumber, lastTickProcessed, svgParentRef);
      }
      currentSongTick = lastTickProcessed || tickNumber;
      const trueTickMultiplier = tickMultiplier / relativeTempo;
      currentSongTime = trueTickMultiplier * currentSongTick;
      songStart = 0;
      nextProgressTick = 0;
      if (isPlaying) {
        tick(player, stepDuration, recordedAudio?.howls);
      } else {
        updateProgress({ tick: tickNumber });
      }
    },
    getCurrentTick: () => currentSongTick,
    play: () => {
      songStart = nextProgressTick = 0;
      currentSongTotalTicks = player.song!.totalTicks;
      isPlaying = true;
      recordedAudio?.play();
      isHowling = !!recordedAudio;
      tick(player, stepDuration, recordedAudio?.howls);
    },
    setRelativeTempo: (tempo: number) => {
      recordedAudio?.rate(tempo);
      relativeTempo = tempo || 1;
      if ((songStart || currentSongTime) && !recordedAudio?.howls?.some(howl => howl.playing())) {
        // if it's in the middle of a song, we need to update currentSongTime and songStart
        // so that it coincides with when the song would have started if it had all been played at this tempo
        // and they agree with currentSongTick based on the new tempo
        const trueTickMultiplier = tickMultiplier / relativeTempo;
        const oldCurrentSongTime = currentSongTime;
        currentSongTime = currentSongTick * trueTickMultiplier;
        songStart += oldCurrentSongTime - currentSongTime;
      }
    }
  };

  midiCtxt.on('fileLoaded', () => {
    player.stop();
  });

  const sendEvents = (player: MidiControls, songStart: number, currentSongTick: number, nextTick: number, tickMultiplier: number, ac: AudioContext) => {
    const { events, channelCount, tracks } = player.song!;
    const useRecordedAudio = !!recordedAudio && (tracks.length === 1 || nextTick <= (tracks[1]?.startTick ?? 0));
    const chVolume = channelCount >= 4 ? channelVolume : [1, 1, 1, 1];
    const filteredEvents = useRecordedAudio ? [] : events.filter(e => e.tick >= currentSongTick && e.tick < nextTick);
    if (filteredEvents.length) {
      filteredEvents.forEach(event => {
        if (event.name === 'Note on' && event.noteNumber) {
          const track = (channels[event.track] = channels[event.track] || {});
          track[event.noteNumber]?.then(p => p());
          if ((event.velocity || 0) > 0) {
            const channel = channelCount >= 4 ? (event.channel ?? 0) - 2 : 0;
            const eventVolume = volume * (chVolume[event.channel ?? 0] ?? 1);
            if (eventVolume > 0) {
              track[event.noteNumber] = playInstrument({
                  note: event.noteNumber,
                  time: songStart + (tickMultiplier * event.tick),
                  gainOffset: (eventVolume * (event.velocity || 0)) / 3200,
                },
                channel
              );
            }
          } else {
            delete track[event.noteNumber];
          }
        } else if (event.name === 'Set Tempo') {
          // set tempo
          updateProgress({ tempo: midiCtxt.tempo });
        }
      });
      lastTickProcessed = activateNotesForTick(midiTimeMap, verseLyricTickMap, nextTick, lastTickProcessed, svgParentRef);
    } else if (useRecordedAudio) {
      deactivateAllNotesForRef(svgParentRef);
    }

    if (currentSongTick >= nextProgressTick) {
      updateProgress({ tick: currentSongTick });
      const totalSongSeconds = useRecordedAudio ? recordedAudio!.howls[0].duration() : (tickMultiplier / relativeTempo) * player.song!.totalTicks!;
      const ticksPerSecond = player.song!.totalTicks / totalSongSeconds;
      // update the progress no more than 10 times a second:
      nextProgressTick += ticksPerSecond / 10;
    }
  }
  const tick = (player: MidiControls, stepDuration: number, howls?: Howl[]) => {
    if (!isPlaying) return;
    // in case this function gets called more than once, we will cancel the animation frame if there is one already requested:
    window.cancelAnimationFrame(nextAnimationFrame);

    let trueTickMultiplier = tickMultiplier / relativeTempo;
    let nextStepTime: number;
    let nextStepTick: number;
    let currentTick: number;
    if (isSeeking) {
      currentTick = currentSongTick;
      nextStepTick = currentSongTick + stepDuration / trueTickMultiplier;
    } else {
      const howlPosition = howls?.[0]?.seek() || 0;
      if (howls && (howls.some(howl => howl.playing()) || howls.some(howl => howl.state() === 'loading') || (howlPosition && isHowling))) {
        isHowling = true;
        currentTick = howlPosition * (recordedAudio?.tickMultiplier ?? 1);
        nextStepTick = currentTick;
        nextStepTime = nextStepTick * trueTickMultiplier;
      } else {
        if (isHowling) {
          // just switched from recorded audio to MIDI:
          isHowling = false;
          currentSongTime = trueTickMultiplier * (player.song?.tracks[1]?.startTick ?? 0);
          songStart = ac.currentTime - currentSongTime;
        } else if (songStart === 0) {
          songStart = ac.currentTime - currentSongTime;
        }
        nextStepTime = ac.currentTime + stepDuration - songStart;
        nextStepTick = nextStepTime / trueTickMultiplier;
        currentTick = (ac.currentTime - songStart) / trueTickMultiplier;
      }
    }
    if (!isSeeking && currentTick >= currentSongTotalTicks) {
      // stop
      player.stop();
      return;
    }
    sendEvents(player, songStart, currentSongTick, nextStepTick, trueTickMultiplier, ac);
    if (!isSeeking) {
      currentSongTime = nextStepTime!;
      currentSongTick = nextStepTick;
    }

    if (!isSeeking) {
      nextAnimationFrame = window.requestAnimationFrame(() => tick(player, stepDuration, howls));
    }
  }
  return player;
}

const player: MidiControls = initPlayer();

/**
 * this keeps track of the audio currently playing
 * it can be an array of base64 midis or a recording URL
 */
let audioPlaying: string[] | undefined;
let recordedAudio: {
  urls: string[];
  howls: Howl[];
  duration?: number;
  isLoaded?: true;
  promise: Promise<any>;
  tickMultiplier?: number;
  play: () => void;
  stop: () => void;
  pause: () => void;
  rate: (rate: number) => void;
  seek: (position: number) => void;
} | undefined = undefined;
// let pitchShifter: Tone.PitchShift;
/**
 * this is the array of base 64 midis to load
 * OR the URL of a recorded audio file
 */
let audioToLoad: string[] | null = null;
let transpose = 0;
let midiTimeMaps: TimeMapEntryWithTicks[][] = [];
let midiTimeMap: TimeMapEntryWithTicks[] = [];
let psalmToneMap: TimeMapEntryWithTicks[] | null = null;
let psalmToneMidi: string | null = null;
let verseLyricTickMap: number[] = [];
let svgParentRef: React.RefObject<HTMLElement> | undefined;

export const getVerseLyricTickMap = () => verseLyricTickMap;

export type SetAudioActiveDataOptions = {
  id?: number | null;
  timeMaps?: TimeMapEntryWithPossibleTicks[][];
  ref?: React.RefObject<HTMLElement>;
  title?: string;
  supertitle?: string;
  midiPsalmToneData?: MidiData | null;
  base64midis?: string[] | null;
  recordingUrls?: string[] | null;
  transpose?: number;
};
export type SetMidiActiveData = (options: SetAudioActiveDataOptions) => void;

const howlCallbacks = (recordingUrl: string, onload?: () => void) => ({
  onload: () => {
    if (recordedAudio && deepEqual(recordingUrl, recordedAudio.urls)) {
      recordedAudio.isLoaded = true;
      recordedAudio.duration = recordedAudio.howls?.[0]?.duration();
    }
    onload?.();
  }
});
const initializeAudio = (urls: string[], onload?: () => void) => {
  const promises: Promise<void>[] = [];
  Howler.volume(volume / 100);
  const howls = urls.map((url, i) => {
    let resolve: (() => void) | undefined;
    promises.push(new Promise<void>(_resolve => {
      resolve = () => {
        _resolve();
        onload?.();
      }
    }));
    const src = /\.webm$/.test(url)
      ? [url, url.replace(/\.webm$/, '.mp4')]
      : url;
    const result = new Howl({ src, html5: true, rate: relativeTempo || 1, ...howlCallbacks(url, resolve) });
    if (urls.length === stereoPan.length) {
      result.stereo(stereoPan[i]);
    }
    return result;
  });
  const promise = Promise.all(promises);
  const play = () => { for (const howl of howls) !howl.playing() && howl.play() };
  const pause = () => { for (const howl of howls) howl.pause() };
  const stop = () => { for (const howl of howls) howl.stop() };
  const rate = (rate: number) => howls.forEach(howl => howl.rate(rate));
  const seek = (position: number) => howls.forEach(howl => howl.seek(position));
  return { urls, howls, promise, play, pause, stop, rate, seek };
}

export const setMidiActiveData: SetMidiActiveData = ({
  base64midis = null,
  recordingUrls = null,
  transpose: newTranspose = 0,
  id = null,
  timeMaps,
  ref,
  title = '',
  supertitle = '',
  midiPsalmToneData,
}) => {
  let isPlaying = player?.isPlaying() ?? false;
  let tick: number | undefined = undefined;
  const data = recordingUrls || base64midis;
  const isAvailable = !!data;
  
  // For now, we are just cancelling use of recorded audio if transpose is set
  transpose = newTranspose;
  if (transpose) {
    recordingUrls = null;
  }
  if (!deepEqual(audioPlaying, base64midis)) {
    player?.stop();
    audioToLoad = base64midis;
    isPlaying = false;
    tick = 0;
    deactivateAllNotesForRef(svgParentRef);
  }
  if (!deepEqual(recordingUrls ?? null, recordedAudio?.urls ?? null)) {
    recordedAudio?.stop();
    if (recordedAudio) {
      recordedAudio = undefined;
    }
    if (recordingUrls) {
      recordedAudio = initializeAudio(recordingUrls);
    }
  }
  const options = { tickOffset: 0 };
  midiTimeMaps = (timeMaps ?? []).map(tMap => processTimeMapForTicks(tMap, options));
  svgParentRef = ref;
  updateProgress({ tick, isPlaying, isAvailable, title, supertitle, id });
  setMidiPsalmToneData(midiPsalmToneData);
  // use the midiTimeMaps if present in place of the final midiTimeMap
  midiTimeMap = (
    psalmToneMap ? [...midiTimeMaps.slice(0, -1), psalmToneMap] : midiTimeMaps
  ).flat();
  loadAudioIfNecessary();
};

export const setMidiPsalmToneData = (midiData?: MidiData | null) => {
  if (midiData) {
    const [base64midi, timeMap] = midiData;
    if (psalmToneMidi !== base64midi) {
      psalmToneMidi = base64midi;
      midiCtxt.loadDataUri('data:audio/midi;base64,' + base64midi);
      tickMultiplier = 60 / midiCtxt.division / midiCtxt.tempo;
      let psalmToneMidiEvents = midiCtxt.getEvents().flat();
      const firstNoteEvent = psalmToneMidiEvents.find(({ name }) => name === 'Note on');
      if (firstNoteEvent) {
        psalmToneMidiEvents = filterFirstNotesOnTrack(psalmToneMidiEvents, firstNoteEvent.track);
      }
      psalmToneMap = prepareMidiPsalmtoneMap(psalmToneMidiEvents, timeMap);
    }
  } else if (!midiData) {
    psalmToneMidi = psalmToneMap = null;
  }
}

const prepareMidiPsalmtoneMap = (psalmToneMidiEvents: MidiEvent[], timeMap: TimeMapEntryWithPossibleTicks[]) => {
  if (psalmToneMidiEvents) {
    loadAudioIfNecessary();
    const verseTrack = player.song?.tracks[1];
    if (verseTrack) {
      
      const startTick = verseTrack.startTick;
      let psalmToneTimeMap = processTimeMapForTicks(timeMap.map(e => ({ ...e })), { tickOffset: startTick, midiIdx: 1 });
      const melodyTrack = psalmToneMidiEvents[0].track;
      const melodyEvents = filterFirstNotesOnTrack(verseTrack.events!, melodyTrack);

      // make a map of associated events between the audio MIDI and the visualized psalm tone
      const associatedEvents: MidiEvent[][] = [[]];
      let playingEventIndex = 0,
          viewingEventIndex = 0,
          viewingEvent = psalmToneMidiEvents[0],
          nextViewingEvent = psalmToneMidiEvents[1],
          // if we need to know that the viewing event is a reciting tone, we could do this:
          // viewingEventIsRecitingTone = nextViewingEvent?.tick - viewingEvent.tick >= 240,
          nextViewingEventIsSamePitch = nextViewingEvent?.noteNumber === viewingEvent.noteNumber;
      const getNextViewingEvent = () => {
        associatedEvents.push([]);
        viewingEvent = psalmToneMidiEvents[++viewingEventIndex];
        if (!viewingEvent) {
          viewingEvent = psalmToneMidiEvents[viewingEventIndex = 0];
        }
        nextViewingEvent = psalmToneMidiEvents[viewingEventIndex + 1];
        // viewingEventIsRecitingTone = nextViewingEvent?.tick - viewingEvent.tick >= 240;
        nextViewingEventIsSamePitch = nextViewingEvent?.noteNumber === viewingEvent.noteNumber;
      }
      const noteOffset = (viewingEvent.noteNumber ?? 0) - (melodyEvents[0]?.noteNumber ?? 0);
      while (playingEventIndex < melodyEvents.length) {
        const playingEvent = melodyEvents[playingEventIndex];
        const nextPlayingEvent = melodyEvents[playingEventIndex + 1];
        const playingEventLength = nextPlayingEvent?.tick - playingEvent.tick;
        const endsPhrase = playingEventLength >= 240;
        const midiNote = noteOffset + playingEvent.noteNumber!;
        if (midiNote !== viewingEvent.noteNumber || (nextViewingEventIsSamePitch && associatedEvents[associatedEvents.length - 1].length)) {
          getNextViewingEvent();
          if (midiNote !== viewingEvent.noteNumber) {
            console.warn('problems exist; things will not be good.', { viewingEventIndex })
          }
        }
        associatedEvents[associatedEvents.length - 1].push(playingEvent)
        ++playingEventIndex;

        if (endsPhrase && (!nextViewingEvent || nextViewingEvent.noteNumber === noteOffset + (nextPlayingEvent.noteNumber ?? 0))) {
          // force the next viewing event, as long as it is the correct pitch
          getNextViewingEvent();
        }
      }

      // take the last time entry, to use for its final note off event:
      const lastTimeEntry = psalmToneTimeMap.pop()!;
      // then keep only the entries with a note on event:
      psalmToneTimeMap = psalmToneTimeMap.filter(({ on }) => on);
      const originalPsalmToneTimeMap = psalmToneTimeMap.slice();
      while (associatedEvents.length > psalmToneTimeMap.length) {
        psalmToneTimeMap.splice(0, 0, ...originalPsalmToneTimeMap.map(entry => ({ ...entry })));
      }
      lastTimeEntry.tick = verseTrack.startTick + verseTrack.totalTicks;
      psalmToneTimeMap.push(lastTimeEntry);
      for (let i = associatedEvents.length - 1; i >= 0; --i) {
        const [event, ...events] = associatedEvents[i];
        if (event) {
          psalmToneTimeMap[i].tick = event.tick;
          if (events.length) {
            psalmToneTimeMap.splice(i + 1, 0, ...events.map(({ tick }) => ({
              ...psalmToneTimeMap[i],
              tick
            })));
          }
        } else {
          psalmToneTimeMap.splice(i + 1, 1);
        }
      }
      return psalmToneTimeMap;
    }
  }
  return null;
}

const loadAudioIfNecessary = async () => {
  if (audioToLoad && audioToLoad !== audioPlaying) {
    const type = typeof audioToLoad === 'string' ? audioToLoad : 'midi';
    debugLogger.log(`loading ${type}`);
    player.stop();
    audioPlaying = audioToLoad;

    const song: MidiSong = player.song = {
      events: [],
      totalTicks: 0,
      channelCount: 0,
      tracks: [],
    }

    let startTick = 0;
    verseLyricTickMap = [];
    for (const base64midi of audioToLoad) {
      const uri = 'data:audio/midi;base64,' + base64midi;
      midiCtxt.loadDataUri(uri);
      tickMultiplier = 60 / midiCtxt.division / midiCtxt.tempo;
      const events: MidiEvent[] = midiCtxt.getEvents().flat();
      if (startTick) {
        // shift all ticks so that this track starts at `startTick` instead of 0:
        const offset = startTick;
        const index = song.tracks.length;
        events.forEach(event => {
          event.tick += offset;
          event.midiIdx = index;
        });
      }
      // also set up a lyric map, of when each lyric starts:
      const lyricTickMap = events.filter(event => event.name === 'Lyric').map(event => event.tick);
      if (startTick) {
        // use the last track as the verse lyric tick map, provided it is not the first track (startTick === 0)
        verseLyricTickMap = lyricTickMap;
      }
      
      const totalTicks = midiCtxt.totalTicks!;
      const track: typeof song.tracks[number] = {
        events,
        tickMultiplier,
        startTick,
        totalTicks,
        lyricTickMap,
      };
      song.tracks.push(track);
      song.events.push(...events);
      song.totalTicks = startTick += totalTicks;
      song.channelCount = Math.max(...song.events.map(event => event.channel ?? 0));
      startTick += 240;
    }
    updateProgress({
      totalTicks: song.totalTicks,
      trackStarts: song.tracks.map((t) => t.startTick),
      channelCount: song.channelCount,
    });
  }
  audioToLoad = null;
  if (recordedAudio && player.song) {
    if (recordedAudio.howls.length <= 1 && (player.song?.channelCount ?? 0) > 1) {
      // if there are harmonies in the MIDI, but not in the recording, we disallow the audio recording:
      recordedAudio.stop();
      recordedAudio = undefined;
    } else {
      await recordedAudio?.promise;
      const midiTicks = player.song.tracks[1]?.startTick ?? player.song.totalTicks ?? 0;
      player.song.recordedAudioPercent = midiTicks / player.song.totalTicks;
      recordedAudio.tickMultiplier = midiTicks / recordedAudio.howls?.[0]?.duration();
    }
  }
  return player;
}

export const playMidi = async (
) => {
  debugLogger.log('playing');
  const player = await loadAudioIfNecessary();
  player.play();
  updateProgress({ isPlaying: true, isAvailable: true });
  return player;
};

export const pauseMidi = async (sendUpdate = true) => {
  debugLogger.log('pausing');
  player?.pause();
  if (sendUpdate) updateProgress({ isPlaying: false });
};

export const restartMidi = async () => {
  if (!player) {
    return;
  }
  
  debugLogger.log('restarting');
  pauseMidi();
  player.skipToTick(0);
  updateProgress({
    isPlaying: false,
    tick: 0,
    isAvailable: true,
  });
};

export const seekMidiPercent = async (percent: number, { isSeeking = false }: { isSeeking?: boolean } = {}) => {
  const player = await loadAudioIfNecessary();
  let tick = percent * player.song!.totalTicks!;
  if (!isSeeking && tick === 0) {
    tick = 1;
  }
  seekMidiTick(tick, isSeeking, player);
};

export const seekMidiTick = async (tick: number | (() => number | undefined), isSeeking: boolean, player?: MidiControls) => {
  player = player ?? await loadAudioIfNecessary();
  if (typeof tick === 'function') {
    tick = tick() ?? -1;
    if (tick < 0) return;
  }
  player.skipToTick(tick, isSeeking);
}
export const getMidiCurrentTick = () => player?.getCurrentTick() ?? 0;

const updateProgress = (progress: MidiProgress) => {
  for (const callback of progressCallbacks) {
    try {
      callback?.(progress);
    } catch (e) {
      console.warn(e);
    }
  }
};

export const addMidiProgressListener = (callback: MidiProgressCallback) => {
  progressCallbacks.push(callback);
};

export const removeMidiProgressListener = (callback: MidiProgressCallback) => {
  let i: number;
  while ((i = progressCallbacks.indexOf(callback)) >= 0) {
    progressCallbacks.splice(i, 1);
  }
};

export const setMidiVolume = (_volume: number) => {
  volume = _volume;
  Howler.volume(volume / 100);
};

export type VoiceSalience = {
  voice: PlaybackPart;
  salience: PartSalience;
};
const allParts = ['S','A','T','B'] as const;
const partCssProperties = [
  '--MIDI-soprano-opacity',
  '--MIDI-alto-opacity',
  '--MIDI-tenor-opacity',
  '--MIDI-bass-opacity',
] as const;
/**
 * Channel number of first part (soprano)
 */
const partChannelOffset = 2;
const salienceToVolumeOfOtherVoices: { readonly [key in PartSalience]: number } = {
  Muted: 1,
  Prominent: mutedVolume,
  Solo: 0,
};
const nonSalientVolumeToSalience = new Map(Object.entries(salienceToVolumeOfOtherVoices).map(([k, v]) => [v, k as PartSalience]));
export const setVoiceSalience = ({ voice, salience }: VoiceSalience) => {
  if (voice === 'SATB') {
    allParts.forEach((part, i) => {
      const channel = partToChannel[part];
      channelVolume[channel] = 1;
      recordedAudio?.howls?.[i]?.volume(1);
      recordedAudio?.howls?.[i]?.stereo(stereoPan[i]);
      document.body.style.setProperty(partCssProperties[i], '1');
    });
  } else {
    partCssProperties.forEach((key, i) => {
      const isSelected = allParts[i] === voice;
      document.body.style.setProperty(key, isSelected ? "1" : "0.4");
    });
    const allOtherParts = allParts.filter(part => part !== voice);
    const channel = partToChannel[voice];
    const volume = salience === 'Muted' ? mutedVolume : 1;
    const stereo = salience === 'Muted' ? stereoPan[channel - 2] : 0;
    channelVolume[channel] = volume;
    recordedAudio?.howls?.[channel - 2]?.volume(volume);
    recordedAudio?.howls?.[channel - 2]?.stereo(stereo);
    allOtherParts.forEach(part => {
      const channel = partToChannel[part];
      const volume = salienceToVolumeOfOtherVoices[salience] ?? 1;
      channelVolume[channel] = volume;
      recordedAudio?.howls?.[channel - 2]?.volume(volume);
      recordedAudio?.howls?.[channel - 2]?.stereo(stereoPan[channel - 2]);
    });
  }
}
export const setRelativeTempo = (tempo: number) => {
  player.setRelativeTempo(tempo);
}
export const getRelativeTempo = () => relativeTempo;
export const getVoiceSalience = (): VoiceSalience => {
  const chVolume = channelVolume.slice(partChannelOffset);
  const numLoudVoices = chVolume.filter(chVol => chVol === 1).length;
  const firstLoudVoiceIndex = chVolume.findIndex(chVol => chVol === 1);
  const firstQuietVoiceIndex = chVolume.findIndex(chVol => chVol <= mutedVolume);
  const salientVoiceIndex = numLoudVoices === 1 ? firstLoudVoiceIndex : firstQuietVoiceIndex;
  const nonSalientVoiceIndex = salientVoiceIndex === 0 ? 1 : 0;
  const voice: PlaybackPart = numLoudVoices === 4 ? 'SATB' : allParts[salientVoiceIndex];
  const salience: PartSalience = numLoudVoices === 4 ? 'Solo' : nonSalientVolumeToSalience.get(chVolume[nonSalientVoiceIndex]) ?? 'Solo';
  return { voice, salience };
};
