export const getScreenWidthForEngravings = (innerWidth: number, scale = 1) => {
  const maxWidth = 750 * scale + 60;
  if (innerWidth + 20 > maxWidth) {
    return maxWidth;
  }
  const mod40 = innerWidth % 40;
  // round to nearest number divisible by 40
  return innerWidth - mod40 + (mod40 >= 20 ? 40 : 0);
};

/**
 * 
 * @returns true if the screen is wide enough to use the SVGs we display in the webapp for the exported PDF
 */
export const getScreenWidthIsGoodForExport = () => {
  return (
    getScreenWidthForEngravings(window.innerWidth) ===
    getScreenWidthForEngravings(Infinity)
  );
};
