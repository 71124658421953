import { AuthContext } from 'context/AuthContext';
import { useContext } from 'react';
import { ParishSize, fallbackParishSize } from 'utils/forms/options/parishSizes';

// first return value is a numeric parish size to use,
// second return is "true" if this is the fallback,
// and "false" this is the known parish size
export const useDefaultParishSize = (): [ParishSize, boolean] => {
  const { group } = useContext(AuthContext);

  let defaultParishSize = group?.keycloakGroup.parishSize;
  const parishSizeUnknown = !defaultParishSize || !/^\d/.test(defaultParishSize);
  if (parishSizeUnknown) {
    return [fallbackParishSize, parishSizeUnknown];
  } else {
    return [defaultParishSize!, false];
  }
}
