import React, { useContext, useEffect } from 'react';
import { Translation } from 'components/ordo/shared/text/Translation';
import { getReadingContent, getReadingSource, ReadingTypes } from 'utils/api-types/ordo/card/ReadingTypes';
import { getActiveSelections } from 'utils/functions/card';
import { OrdoContext } from 'context/OrdoContext';
import { RubricText } from '../rubric/RubricText';
import { CardContext } from 'context/CardContext';
import { useIdQuery } from 'hooks/queries/useQuery';
import { GetText } from 'queries/text';
import { useAllowFullTextReadings } from 'hooks/ordo/cards/useAllowFullTextReadings';

export const ConfiguredState: React.FC = () => {
  const { defaultLanguage, name: ordoName } = useContext(OrdoContext).ordo;
  const { config, onLoaded, textOptions } = useContext(CardContext);
  const { data: textData } = useIdQuery(GetText, config.optionId ? null : config.textId);

  useEffect(() => onLoaded(), [onLoaded]);

  const [translationFromTextOptions, textFromTextOptions] = getActiveSelections(textOptions, config, defaultLanguage);
  const text = textFromTextOptions ?? textData?.text;
  const translation = translationFromTextOptions ?? text?.officialTranslations.find((t) => t.language === (config.language ?? defaultLanguage));

  const {
    readingDisplay,
    textDisplay,
    showAcclamations,
    numberOfReaders,
    form,
  } = config;
  // for lectionary content review ordos, we show both forms of the reading:
  const showBothForms = form === 'both' || (!form && ordoName === 'Lectionary Content Approval');

  const allowFullText = useAllowFullTextReadings();

  if (!translation) {
    if (textOptions?.length === 0) {
      return <p>No text could be found for this reading.</p>;
    } else {
      return null;
    }
  }

  const translationText = getReadingContent(translation, form, readingDisplay ?? (allowFullText ? 'Full Text' : 'Synopsis'));
  const scriptureReference = getReadingSource(text, form);
  const shortFormTranslationText = showBothForms && getReadingContent(translation, ReadingTypes.SHORT, readingDisplay);
  const shortFormScriptureReference = getReadingSource(text, ReadingTypes.SHORT);

  const getTranslationForText = (translationText: string, scriptureReference?: string | null) =>
    <Translation
      text={translationText ?? ''}
      language={translation.language}
      numberOfReaders={numberOfReaders ?? 1}
      scriptureReference={scriptureReference ?? undefined}
      showAll={true}
      readingDisplay={readingDisplay ?? 'Full Text'}
      allowFullText={allowFullText}
      textDisplay={textDisplay ?? 'Paragraph'}
      readingIntroduction={showAcclamations && translation.readingIntroduction}
      readingConclusion={showAcclamations && translation.readingConclusion}
      showSource={true}
      showCanonicalSource={false}
    />;
  const result = getTranslationForText(translationText, scriptureReference);

  if (!showBothForms || !shortFormTranslationText) return result;

  // show both forms for lectionary content review:
  const shortResult = getTranslationForText(shortFormTranslationText, shortFormScriptureReference);
  return <>
    {result}
    <RubricText text='Or, the short form:' className='mt-15' />
    {shortResult}
  </>
}
