import { getReaderNames, Names, NamesConfig } from 'utils/functions/card';

const readerPartRegex = /(\s*)\{([A-Z+])\}/g;
const readerLongNameRegex = /(\s*)\{(\d[^}]*?|[^}]{2,}?)\}/g;

const whitespaceOrNewline = (whitespace: string, index: number) => {
  // take only newline characters from whitespace, and at most two;
  whitespace = whitespace.replace(/[^\n]*/g, '').slice(0, 2);
  // if there were not at least 2 newline characters, we put only a small break before the reader-label:
  return whitespace.length <= 1 && index > 0
    ? '\n<div class="my-1"></div>'
    : whitespace;
};

export const getTextReaderNames = (text: string, language = 'en', numberOfReaders = 1) => {
  const names = getReaderNames();
  const langNames: NamesConfig|null = names[language] ? names[language] : null;

  if (langNames === null || numberOfReaders === 1) {
    return text
      .replace(readerPartRegex, '$1')
      .replace(
        readerLongNameRegex,
        (full: string, whitespace: string, captured: string, index: number) =>
          `${whitespaceOrNewline(whitespace, index)}<span class='display-inline color-red-on-screen reader-label me-3'>${captured}</span>`,
      );
  }

  const textNames: Names = langNames[numberOfReaders];
  if (!textNames) {
    return text.replace(readerPartRegex, '$1');
  }

  return text.replace(readerPartRegex, (full: string, whitespace: string, captured: string, index: number) => {
    const name = textNames[captured];
    if (!name) {
      console.warn(`No name for ${full} in ${language}`);
      return '';
    }
    return `${whitespaceOrNewline(whitespace, index)}<span class='display-inline color-red-on-screen reader-label single-character me-3'>${name.displayAs}</span>`;
  });
};
