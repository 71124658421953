import { initSpan } from './initSpan';
import { PdfTextBlock, PdfTextSpan, PdfTextSpanWithDimensions, PdfTextLine } from './PdfBlockTypes';

function widthOfSpan(
  pdf: PDFKit.PDFDocument,
  span: PdfTextSpan,
  block: PdfTextBlock,
) {
  const { cleanup } = initSpan(pdf, span, block);
  const result = pdf.widthOfString(span.text, {
    ...block?.options,
    ...span.options,
  });
  cleanup();
  return result;
}

/**
 * Split a block's spans into words and measure the widths of each span.
 * @param pdf document
 * @param block of spans
 * @returns the new spans, but also sets them as the spans of the block
 */
function separateSpansIntoBreakableElements(
  pdf: PDFKit.PDFDocument,
  block: PdfTextBlock,
): PdfTextSpanWithDimensions[] {
  const spans: PdfTextSpan[] | PdfTextSpanWithDimensions[] = block.spans;
  const firstSpan = spans[0];
  if (firstSpan && 'width' in firstSpan) {
    // if it alread has dimensions
    return spans as PdfTextSpanWithDimensions[];
  } else if (spans.length === 0 || (spans.length === 1 && !firstSpan.text)) {
    spans.push({
      text: '\n',
    });
  }
  const result: PdfTextSpanWithDimensions[] = [];
  let lastTextEndedInSpace = true;
  for (const span of spans) {
    const { cleanup } = initSpan(pdf, span, block);
    let { text = '' } = span;
    const height = pdf.heightOfString(text, {
      ...span.options,
      lineBreak: false,
      continued: false,
      width: Infinity,
    });
    if (span.preserveWhitespace) {
      text = text.replace(/\t/g, '    ')
    } else {
      // split on whitespace, but keep the space at the end of any word
      text = text.replace(/[ \r\n\t]+/, ' ');
      if (lastTextEndedInSpace) {
        // if the last text ended in a space, then we want to get rid of any leading spaces
        text = text.replace(/^ /, '');
      }
    }
    lastTextEndedInSpace = !span.preserveWhitespace && (span.newline || text.endsWith(' '));
    const words = text.match(/(?:^[ \r\n\t]*)?(?:[^ \r\n\t]*[ \r\n\t]*|[^ \r\n\t]+$)/g) ?? [text];
    const pdfOptions = { ...block?.options, ...span.options };
    const spaceWidth = pdf.widthOfString(' ', pdfOptions);
    const spans = words.map((word, i, words) => ({
      ...span,
      width: pdf.widthOfString(word, pdfOptions),
      height,
      spaceWidth: word.endsWith(' ') ? spaceWidth : 0,
      text: word,
      newline: span.newline && words.length - 1 === i,
    }));
    result.push(...spans);
    cleanup();
  }
  block.spans = result;
  return result;
}

export function addLinesForPdfBlock(
  pdf: PDFKit.PDFDocument,
  block: PdfTextBlock,
  blockWidth: number,
) {
  if (!(block.lines && block.contentWidth === blockWidth)) {
    block.contentWidth = blockWidth;
    if (block.height === 0) {
      block.lines = [
        {
          height: 0,
          width: block.spans.reduce(
            (acc, span) => acc + widthOfSpan(pdf, span, block),
            0,
          ),
          dx: block.options?.indent ?? 0,
          spans: block.spans,
        },
      ];
      return;
    }
    const lines: PdfTextLine[] = [];
    block.lines = lines;
    const spansWithDimensions = separateSpansIntoBreakableElements(pdf, block);
    let currentLineSpans: PdfTextSpanWithDimensions[] = [];
    let indent = block.options?.indent ?? 0;
    let currentLineWidth = indent;

    const endLine = () => {
      if (currentLineSpans.length) {
        lines.push({
          height: Math.max(...currentLineSpans.map((span) => span.height)),
          spans: currentLineSpans,
          width: currentLineWidth,
          dx: indent,
        });
      }
      indent = 0;
      currentLineSpans = [];
      currentLineWidth = 0;
    };

    const addSpansToCurrentLine = ([
      span,
      ...spans
    ]: PdfTextSpanWithDimensions[]) => {
      // combine spans if the first span has the same options as the last current span
      const lastCurrentSpan = currentLineSpans.pop();
      if (lastCurrentSpan) {
        if (
          lastCurrentSpan.options === span.options &&
          lastCurrentSpan.fontFamily === span.fontFamily &&
          lastCurrentSpan.fontOptions === span.fontOptions &&
          lastCurrentSpan.fontSize === span.fontSize
        ) {
          span = { ...span, text: lastCurrentSpan.text + span.text };
        } else {
          currentLineSpans.push(lastCurrentSpan);
        }
      }
      currentLineSpans.push(span, ...spans);
    };

    for (let i = 0; i < spansWithDimensions.length; ++i) {
      let spans = spansWithDimensions.slice(i);
      let nextBreakableSpan = spans.findIndex((span) => span.newline || span.spaceWidth > 0);
      if (nextBreakableSpan < 0) nextBreakableSpan = spans.length;
      i += nextBreakableSpan;
      spans = spans.slice(0, nextBreakableSpan + 1);
      const { spaceWidth = 0, newline } = spans[nextBreakableSpan] || {};
      const width = spans.reduce((a, b) => a + b.width, 0);

      const widthWithoutSpace = width - spaceWidth;
      if (currentLineWidth + width <= blockWidth) {
        // add it in:
        addSpansToCurrentLine(spans);
        currentLineWidth += widthWithoutSpace;
        if (currentLineWidth + spaceWidth <= blockWidth) {
          // fits...add the space width as well:
          currentLineWidth += spaceWidth;
          if (newline) {
            endLine();
          }
        } else {
          // space wouldn't fit...end the line
          endLine();
        }
      } else {
        // word wouldn't fit...end the line, and add the word to the next line:
        endLine();
        addSpansToCurrentLine(spans);
        currentLineWidth += width;
        if (currentLineWidth > blockWidth) {
          // get rid of space width and end the line
          currentLineWidth -= spaceWidth;
          endLine();
        }
      }
    }
    endLine();
    block.height = block.lines.reduce(
      (acc, line) => acc + line.height,
      0,
    );
  }
}
