import dayjs from 'dayjs';
import { parse } from 'utils/query-string';

export const getBooleanParam = (key: string, params = parse(window.location.search)) => {
  const value = params[key];
  return value === undefined
  // if the param is not defined, default to the default value
  ? undefined
  // if the param is truthy (i.e., not literally the string 'false'), return true, otherwise false
  : typeof value !== 'string' || !['false', 'null'].includes((value as string).toLowerCase());
};

export function getNumberParam(key: string, params = parse(window.location.search)) {
  const value = params[key];
  const parsed = value && typeof value === 'string'
  ? Number.parseInt(decodeURIComponent(value) as string)
  : NaN;
  return isNaN(parsed) ? undefined : parsed;
};

export const dateParamFormat = 'MM-DD-YYYY';

export function getDateParam(key: string, params = parse(window.location.search)) {
  const value = params[key];
  const parsed = value && typeof value === 'string'
  ? dayjs(decodeURIComponent(value), dateParamFormat, true)
  : undefined;
  return parsed?.isValid() ? parsed : undefined;
};

export function getStringParam(key: string, params = parse(window.location.search), validate?: (value: string) => boolean) {
  const value = params[key];
  if (typeof value === 'string') {
    const decodedValue = decodeURIComponent(value);
    if (!validate || validate(decodedValue)) {
      return decodedValue;
    }
  }
  return undefined;
};
