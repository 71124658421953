import { VerovioPostProcessConfig } from "./postProcessVerovioSvg";

export const DEFAULT_POST_PROCESS_ANTIPHON: VerovioPostProcessConfig = {
  addStanzaNumbersOnAllSystems: false,
  addStanzaNumbersOnFirstSystem: true,
  replaceWholeNoteWithBreve: true
}

export const DEFAULT_POST_PROCESS_ORDINARY: VerovioPostProcessConfig = {
  addStanzaNumbersOnAllSystems: false,
  addStanzaNumbersOnFirstSystem: false,
  replaceWholeNoteWithBreve: false
}
