import { CSSProperties } from 'react';
import { OrdoCardConfig } from 'utils/api-types/ordo/card/OrdoCardConfig';

export const getStyleForCardConfig = ({
  spacingVertical,
}: OrdoCardConfig = {}): CSSProperties => ({
  ...(spacingVertical
    ? ({
        '--card-vertical-spacing': `${spacingVertical * 2}px`,
      } as React.CSSProperties)
    : {}),
});
