export type NestedArray<T> = (T|NestedArray<T>)[];

const tabNormalizationRegex = / {2,8}|\t/g;
const leadingWhitespaceRegex = /^[\t ]*/;

const createNestedElement = <T>(depth: number, item: T) => {
  let element: T|NestedArray<T> = item;
  while (depth-- > 0) {
    element = [element];
  }
  return element;
}

export const processNestedText = (text?: string, columns?: number | null): NestedArray<string> | undefined => {
  if (!text) {
    return;
  }

  const lines = text.split(/\n/g);

  return columns === 2
    ? lines.reduce((result: string[], line, i) => {
        const leadingWhitespace = leadingWhitespaceRegex.exec(line)![0];
        const depth = leadingWhitespace.match(tabNormalizationRegex)?.length || 0;
        const trimmed = line.trim();
        if (depth && i) {
          // only consider depth if it's not the first line, which should never be indented anyway.
          const str = result.pop();
          result.push(`${str} / ${trimmed}`)
        } else {
          result.push(trimmed)
        }
        return result;
      }, [])
    : lines.map((line: string) => {
      const leadingWhitespace = leadingWhitespaceRegex.exec(line)![0];
      const depth = leadingWhitespace.match(tabNormalizationRegex)?.length || 0;
      return createNestedElement(depth, line.slice(leadingWhitespace.length));
    });
};
