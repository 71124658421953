/* eslint-disable @typescript-eslint/no-redeclare */
import { ApolloCache, FetchResult, gql } from '@apollo/client';
import { UserRoles } from 'hooks/permission-set/useHasRole';
import { Language } from 'utils/api-types/Language';
import { WithId, WithNameAndId } from 'utils/api-types/WithNameAndId';
import { LiturgicalColor } from 'utils/api-types/ordo/LiturgicalColor';
import { OrdoConfig } from 'utils/api-types/ordo/OrdoConfig';
import { Season } from 'utils/api-types/ordo/Season';
import { addChoirEmailsToGroupCache } from 'utils/apollo/addChoirEmailsToGroupCache';
import dayjs from 'utils/dayjs';
import { BriefOrdoCardSummary, CARD_FRAGMENT_SVG, CARD_FRAGMENT_WITH_MEI, CARD_SHARE_FRAGMENT, CARD_WITH_SUMMARY_FRAGMENT, OrdoCardShare, OrdoCardSvg, OrdoCardWithMei } from './card-fragment';
import { CHOIR_EMAIL_FRAGMENT, ChoirEmail } from './choir-email';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';
import { LAYOUT_FILE_SUMMARY_FRAGMENT, LayoutFileSummary } from './layout-file';
import { LITURGICAL_RITE_FRAGMENT, LiturgicalRite } from './liturgicalRite';
import { GetQueryVariablesFunction, createMutationCacheUpdater } from './updaters/createMutationCacheUpdater';
export type { ChoirEmail } from './choir-email';

export type OrdoBase = {
  userId: string|null;
  updatedAt: Date;
  groupId: string;
  eventId: number | null;
  eventDate: string;
  riteId: number;
  season: Season | null;
  liturgicalYear: number;
  defaultLanguage: Language;
  liturgyDisplayName: string;
  celebrations: ({ time: string, location: string } & WithId)[];
  config: Partial<OrdoConfig>;
  sharedWithEmails?: ChoirEmail[];
  sharedWithGroups?: WithNameAndId[];
} & WithNameAndId;

export type OrdoBaseWithCardsUpdated = {
  cards: {
    id: number;
    updatedAt: Date;
  }[];
} & OrdoBase;

// this type should match ORDO_FRAGMENT
export type Ordo = {
  permissionSetSeedIds: string[];
  canDisplayAntiphonVersesOtherThanCommunion: boolean;
  treatAsBasic: boolean;
  suggestedCompositions: {
    isOfficeHymn: boolean;
    composition: {
      id?: number;
    }
  }[];
  liturgicalCategory: WithNameAndId | null;
  liturgicalRite: {
    shortName: string;
    showDeceasedName: boolean;
  } & WithNameAndId;
  liturgicalEvent: ({ externalId: string | null, season: Season | null, hasMultipleRites: boolean } & WithNameAndId) | null;
  cards: OrdoCardWithMei[];
  shareId: string;
  marketingShareId?: string | null;
  accountId: number;
  layoutFiles: LayoutFileSummary[];
} & OrdoBase;

export type OrdoSummaryWithoutCards = Pick<Ordo,
  | 'id'
  | 'updatedAt'
  | 'name'
  | 'eventDate'
  | 'season'
  | 'liturgicalYear'
  | 'liturgyDisplayName'
  | 'defaultLanguage'
  | 'config'
  | 'accountId'
  | 'liturgicalRite'
  | 'liturgicalEvent'
>;
export type OrdoSummary = OrdoSummaryWithoutCards & {
  cards: BriefOrdoCardSummary[];
};
export type OrdoShare = OrdoSummaryWithoutCards & {
  cards: OrdoCardShare[];
};

export const ORDO_CONFIG_FRAGMENT = gql`
fragment OrdoConfig on OrdoConfig {
  showRubrics
  liturgyDisplayName
  gender
  deceasedFirstName
  deceasedLastName
  religiousName
  religiousAnniversary
  yearsMarried
  hideDate
  hideTitle
  superTitle
  accompanimentExport
  accompanimentLectionary
}
`;

export const ORDO_BASE_FRAGMENT = gql`
  fragment OrdoBase on Ordo {
    id
    updatedAt
    name
    userId
    groupId
    eventId
    eventDate
    riteId
    season
    liturgicalYear @client
    defaultLanguage
    liturgyDisplayName
    celebrations {
      id
      time
      location
    }
    config {
      ...OrdoConfig
    }
    sharedWithEmails {
      name
      email
      id
    }
    sharedWithGroups {
      name
      id
    }
  }
  ${ORDO_CONFIG_FRAGMENT}
`
export const ORDO_BASE_WITH_CARDS_UPDATED_FRAGMENT = gql`
  fragment OrdoBaseWithCardsUpdated on Ordo {
    ...OrdoBase
    cards: ordoCards {
      id
      updatedAt
    }
  }
  ${ORDO_BASE_FRAGMENT}
`

export const ORDO_FRAGMENT = gql`
  fragment Ordo on Ordo {
    ...OrdoBase
    permissionSetSeedIds
    canDisplayAntiphonVersesOtherThanCommunion @client
    treatAsBasic @client
    suggestedCompositions {
      isOfficeHymn
      id
      composition {
        ...on MelodicComposition {
          id
        }
      }
    }
    liturgicalCategory {
      id
      name
    }
    liturgicalRite {
      id
      name
      shortName
      showDeceasedName
    }
    liturgicalEvent {
      id
      name
      externalId
      season
      hasMultipleRites
    }
    cards: ordoCards {
      ...OrdoCardWithMei
    }
    layoutFiles {
      ...LayoutFileSummary
    }
    shareId
    marketingShareId
    accountId
  }
  ${ORDO_BASE_FRAGMENT}
  ${CARD_FRAGMENT_WITH_MEI}
  ${LAYOUT_FILE_SUMMARY_FRAGMENT}
`;

export const ORDO_SUMMARY_WO_CARDS_FRAGMENT = gql`
  fragment OrdoSummaryWithoutCards on Ordo {
    id
    updatedAt
    name
    eventDate
    season
    liturgicalYear @client
    liturgyDisplayName
    defaultLanguage
    config {
      ...OrdoConfig
    }
    liturgicalRite {
      id
      name
      shortName
      showDeceasedName
    }
    liturgicalEvent {
      id
      name
      externalId
      season
      hasMultipleRites
    }
    accountId
  }
  ${ORDO_CONFIG_FRAGMENT}
`;
export const ORDO_SUMMARY_FRAGMENT = gql`
  fragment  OrdoSummary on Ordo {
    ...OrdoSummaryWithoutCards
    cards: enabledCards {
      ...OrdoCardWithSummary
    }
  }
  ${ORDO_SUMMARY_WO_CARDS_FRAGMENT}
  ${CARD_WITH_SUMMARY_FRAGMENT}
`;

export const ORDO_SHARE_FRAGMENT = gql`
  fragment OrdoShare on Ordo {
    ...OrdoSummaryWithoutCards
    cards: enabledCards {
      ...OrdoCardShare
    }
  }
  ${ORDO_SUMMARY_WO_CARDS_FRAGMENT}
  ${CARD_SHARE_FRAGMENT}
`;

export interface GetOrdo extends GQLQuery {
  Variables: { id: number, ordoRoleId?: number, layoutFileId?: number };
  Data: { ordo: Ordo };
}
export const GetOrdo: GQLDocument<GetOrdo> = {
  query: gql`
    query getOrdo($id: Float!, $ordoRoleId: Float, $layoutFileId: Float) {
      ordo(id: $id, ordoRoleId: $ordoRoleId, layoutFileId: $layoutFileId) {
        ...Ordo
      }
    }
    ${ORDO_FRAGMENT}
  `
}

export interface GetOrdoShareSummary extends GQLQuery {
  Variables: { id: string, ordoRoleId?: number };
  Data: {
    share: {
      ownerRoles: UserRoles[];
      ordo: OrdoSummary
    }
  };
}
export const GetOrdoShareSummary: GQLDocument<GetOrdoShareSummary> = {
  query: gql`
    query getOrdoShareSummary($id: String!, $ordoRoleId: Float) {
      share: ordoShare(id: $id, ordoRoleId: $ordoRoleId) {
        id
        ownerRoles
        ordo {
          ...OrdoSummary
        }
      }
    }
    ${ORDO_SUMMARY_FRAGMENT}
  `
}

export interface GetOrdoShare extends GQLQuery {
  Variables: { id: string, email?: string | null, ordoRoleId?: number, screenWidth?: number, textScale?: number, dynamicHymnScaling?: boolean, isMarketing?: boolean; };
  Data: {
    share: {
      ownerRoles: UserRoles[];
      ordo: OrdoShare;
      choirEmail?: ChoirEmail;
      isMarketing: boolean;
    }
  };
}
export const GetOrdoShare: GQLDocument<GetOrdoShare> = {
  query: gql`
    query getOrdoShare($id: String!, $email: String, $ordoRoleId: Float, $screenWidth: Float, $textScale: Float, $dynamicHymnScaling: Boolean, $isMarketing: Boolean) {
      share: ordoShare(id: $id, email: $email, ordoRoleId: $ordoRoleId, screenWidth: $screenWidth, textScale: $textScale, dynamicHymnScaling: $dynamicHymnScaling, isMarketing: $isMarketing) {
        id
        ownerRoles
        isMarketing
        ordo {
          ...OrdoShare
        }
        choirEmail {
          ...ChoirEmail
        }
      }
    }
    ${ORDO_SHARE_FRAGMENT}
    ${CHOIR_EMAIL_FRAGMENT}
  `
}
export interface GetOrdoCardSvg extends GQLQuery {
  Variables: { id: number, ordoRoleId?: number, screenWidth?: number, textScale?: number, dynamicHymnScaling?: boolean };
  Data: {
    card: OrdoCardSvg & {
      cached?: true;
    };
  }
}
export const GetOrdoCardSvg: GQLDocument<GetOrdoCardSvg> = {
  query: gql`
    query getOrdoCardSvg($id: Float!, $ordoRoleId: Float, $screenWidth: Float, $textScale: Float, $dynamicHymnScaling: Boolean) {
      card(id: $id, ordoRoleId: $ordoRoleId, screenWidth: $screenWidth, textScale: $textScale, dynamicHymnScaling: $dynamicHymnScaling) {
        ...OrdoCardSvg
        cached
      }
    }
    ${CARD_FRAGMENT_SVG}
  `
}

// Ordo information used for editing and cloning
// should match the query GET_ORDO_TO_EDIT_DETAILS
export type OrdoEditDetails = {
  eventDate: string;
  officialDate: string | null;
  season: Season | null;
  liturgicalYear: number;
  commonId: number | null;
  lectionaryCommonId: number | null;
  liturgicalRite: LiturgicalRite;
  celebrations: ({ time: string, location: string } & WithId)[];
  config: OrdoConfig;
} & WithNameAndId;

export const ORDO_EDIT_DETAILS_FRAGMENT = gql`
  fragment OrdoEditDetails on Ordo {
    id
    name
    eventDate
    officialDate
    season
    liturgicalYear @client
    commonId
    lectionaryCommonId
    liturgicalRite {
      ...LiturgicalRite
    }
    celebrations {
      id
      time
      location
    }
    config {
      ...OrdoConfig
    }
  }
  ${LITURGICAL_RITE_FRAGMENT}
  ${ORDO_CONFIG_FRAGMENT}
`;

export interface GetOrdoToEdit extends GQLQuery {
  Variables: { id: number };
  Data: { ordo: OrdoEditDetails };
}
export const GetOrdoToEdit: GQLDocument<GetOrdoToEdit> = {
  query: gql`
    query getOrdoToEdit($id: Float!) {
      ordo: ordo(id: $id) {
        ...OrdoEditDetails
      }
    }
    ${ORDO_EDIT_DETAILS_FRAGMENT}
  `
}

// should match the query GetOrdos
export type ListOrdo = {
  cached?: true;
  groupId: string;
  userId: string|null;
  eventDate: string;
  liturgyDisplayName: string;
  liturgicalEvent: ({
    rank: string | null,
    color: LiturgicalColor,
  } & WithId) | null;
  liturgicalRite: {
    liturgicalCategory: WithNameAndId;
  } & WithId;
  celebrations: ({ location: string, time: string } & WithId)[];
  config: Partial<Pick<OrdoConfig, 'accompanimentExport'|'accompanimentLectionary'>>
} & WithNameAndId;

export const LIST_ORDO_FRAGMENT = gql`
  fragment ListOrdo on Ordo {
    id
    groupId
    userId
    name
    cached
    eventDate
    liturgyDisplayName
    liturgicalEvent {
      id
      rank
      color
    }
    liturgicalRite {
      id
      liturgicalCategory {
        id
        name
      }
    }
    celebrations {
      id
      time
      location
    }
    config {
      accompanimentExport
      accompanimentLectionary
    }
  }
`;

export interface GetOrdos extends GQLQuery {
  Variables: {
    groupId: string;
    today?: Date;
    offset?: number;
    limit?: number;
  };
  Data: { ordos: ListOrdo[] };
}
export const GetOrdos: GQLDocument<GetOrdos> = {
  query: gql`
    query getOrdos($groupId: String, $today: DateTime, $offset: Float, $limit: Float) {
      ordos(groupId: $groupId, today: $today, offset: $offset, limit: $limit) @connection(key: "getOrdos", filter: ["today"]) {
        ...ListOrdo
      }
    }
    ${LIST_ORDO_FRAGMENT}
  `,
}
const GetOrdosCached: GQLDocument<GetOrdos> = {
  query: gql`
    query getOrdos($today: DateTime) {
      ordos(today: $today) @connection(key: "getOrdos", filter: ["today"]) {
        ...ListOrdo
      }
    }
    ${LIST_ORDO_FRAGMENT}
  `
}

export interface GetOrdoCount extends GQLQuery {
  Variables: never;
  Data: {
    getOrdoCount: {
      count: number;
    }
  };
}
export const GetOrdoCount: GQLDocument<GetOrdoCount> = {
  query: gql`
    query getOrdoCount {
      getOrdoCount {
        count
      }
    }
  `
}

////////////////////////
/// CACHE UPDATER UTILS

// The following two functions are used in the createMutationCacheUpdater function
const getOrdoVariables = (data: { ordo: { id: number } }) => {
  return { id: data.ordo.id };
}
// this will cause the mutation cache updater to continue with the query, with no variables
const getOrdosVariablesFromContext: GetQueryVariablesFunction<unknown, unknown, GetOrdos> = (
  _: unknown,
  { context }
) => context?.updateQueryVariables?.groupId
  ? ({
    today: dayjs.utc().startOf('day').toDate(),
    groupId: context.updateQueryVariables.groupId,
  })
  : false;

const getOrdosVariablesFromMutationVariables: GetQueryVariablesFunction<unknown, { groupId: string }, GetOrdos> = (
  _: unknown,
  { variables }
) => variables?.groupId
  ? ({
    today: dayjs.utc().startOf('day').toDate(),
    groupId: variables.groupId,
  })
  : false;

//////////////
/// MUTATIONS

export interface DeleteOrdo extends GQLMutation<DeleteOrdo, GetOrdos> {
  Variables: {
    ordoId: number;
  }
  Data: { id: number };
}
const deleteOrdoMutationCacheUpdater = createMutationCacheUpdater(
  GetOrdosCached,
  getOrdosVariablesFromContext,
  (draft, mutationData: DeleteOrdo['Data']) => {
    const deletedIdx = draft.ordos.findIndex((ordo) => ordo.id === mutationData.id)

    if (deletedIdx < 0) {
      return false;
    }

    draft.ordos.splice(deletedIdx, 1);
    return true;
  },
);
export const DeleteOrdo: GQLDocument<DeleteOrdo> = {
  mutation: gql`
    mutation deleteOrdo($ordoId: Float!) {
      id: deleteOrdo(ordoId: $ordoId)
    }
  `,
  updater: (cache: ApolloCache<{ id: number }>, result: FetchResult<DeleteOrdo['Data']>, options) => {
    deleteOrdoMutationCacheUpdater(cache, result, options);
    cache.evict({
      id: cache.identify({ id: result.data?.id, __typename: 'Ordo' }),
    });
  }
}

// These are used as the args for both the edit and clone ordo endpoints,
// but since in both cases they specify what properties on the existing
// ordo should be changed, it's just called EditOrdoArgs
type EditOrdoArgs = {
  id: number;
  name?: string;
  date?: Date;
  celebrations?: Date[];
  config?: OrdoConfig;
  commonId?: number | null;
  lectionaryCommonId?: number | null;
};

export interface CloneOrdo extends GQLMutation<CloneOrdo, GetOrdos> {
  Variables: EditOrdoArgs & { groupId: string };
  Data: { 
    cloneOrdo: {
      ordo: ListOrdo;
      parentId: number;
    }
  }
}
export const CloneOrdo: GQLDocument<CloneOrdo> = {
  mutation: gql`
    mutation cloneOrdo(
      $id: Float!,
      $groupId: String!,
      $name: String,
      $date: DateTime,
      $celebrations: [DateTime!],
      $config: OrdoConfigInput
      $commonId: Float,
      $lectionaryCommonId: Float,
    ) {
      cloneOrdo(
        id: $id,
        groupId: $groupId,
        name: $name,
        date: $date,
        celebrations: $celebrations,
        config: $config,
        commonId: $commonId,
        lectionaryCommonId: $lectionaryCommonId
      ) {
        ordo {
          ...ListOrdo
        }
        parentId
      }
    }
    ${LIST_ORDO_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetOrdosCached,
    getOrdosVariablesFromMutationVariables,
    (draft, mutationData: CloneOrdo['Data']) => {
      const clonedIdx = draft.ordos.findIndex((ordo) => ordo.id === mutationData.cloneOrdo.parentId)
  
      if (clonedIdx < 0) {
        return false;
      }
  
      draft.ordos.splice(clonedIdx, 0, mutationData.cloneOrdo.ordo);
      return true;
    },
  ),
}

export interface EditOrdo extends GQLMutation<EditOrdo, GetOrdos & GetOrdoToEdit> {
  Variables: EditOrdoArgs;
  Data: { ordo: ListOrdo & OrdoEditDetails };
}
// EditOrdo has two different places it's called, and the cache updater is different in each
export const EditOrdoHomeScreen: GQLDocument<EditOrdo> = {
  mutation: gql`
    mutation editOrdo(
      $id: Float!,
      $name: String,
      $date: DateTime,
      $celebrations: [DateTime!],
      $config: OrdoConfigInput
      $commonId: Float,
      $lectionaryCommonId: Float,
    ) {
      ordo: editOrdo(
        id: $id,
        name: $name,
        date: $date,
        celebrations: $celebrations,
        config: $config,
        commonId: $commonId,
        lectionaryCommonId: $lectionaryCommonId
      ) {
        ...ListOrdo
        ...OrdoEditDetails
      }
    }
    ${LIST_ORDO_FRAGMENT}
    ${ORDO_EDIT_DETAILS_FRAGMENT}
  `,
  // The edit ordo needs to update the cache in two places, so we create two updater functions and run them both
  updater: createMutationCacheUpdater(
    GetOrdosCached,
    getOrdosVariablesFromContext,
    (draft, mutationData: EditOrdo['Data']) => {
      const editedIdx = draft.ordos.findIndex((ordo) => ordo.id === mutationData.ordo.id)
  
      if (editedIdx < 0) {
        return false;
      }
  
      draft.ordos[editedIdx] = mutationData.ordo;
      return true;
    }
  ),
}
export const EditOrdoEditorScreen: GQLDocument<EditOrdo> = {
  ...EditOrdoHomeScreen,
  updater: createMutationCacheUpdater(
    GetOrdoToEdit,
    getOrdoVariables,
    (draft, mutationData: EditOrdo['Data']) => {
      draft.ordo = mutationData.ordo;
      return true;
    }
  ),
}

export interface CreateOrdo extends GQLMutation<CreateOrdo, GetOrdos> {
  Variables: {
    name?: string;
    groupId: string;
    councilId: string;
    riteId?: number | null;
    eventExternalId?: string;
    eventRank?: string;
    date: Date;
    officialDate?: Date;
    celebrations: Date[];
    season?: Season | null;
    cycleStandard?: string | null;
    cycleFerial?: string | null;
    commonId?: number | null;
    lectionaryCommonId?: number | null;
    orderId?: number | null;
    eventFerialExternalId?: string;
    ordoConfig?: OrdoConfig;
    templateId?: number | null;
    blankOrdo?: boolean;
  }
  Data: { createOrdo: {
      ordo: ListOrdo;
      showDefaultTemplatePrompt: boolean;
    }
  };
}
export const CreateOrdo: GQLDocument<CreateOrdo> = {
  mutation: gql`
    mutation createOrdo(
      $name: String,
      $groupId: String!,
      $councilId: String!,
      $riteId: Float,
      $eventExternalId: String,
      $eventRank: String,
      $date: DateTime!,
      $officialDate: DateTime,
      $celebrations: [DateTime!]!,
      $season: Season,
      $cycleStandard: String,
      $cycleFerial: String,
      $commonId: Float,
      $lectionaryCommonId: Float,
      $orderId: Float,
      $eventFerialExternalId: String,
      $ordoConfig: OrdoConfigInput,
      $templateId: Float,
      $blankOrdo: Boolean,
    ) {
      createOrdo(
        name: $name,
        groupId: $groupId,
        councilId: $councilId,
        eventExternalId: $eventExternalId,
        eventRank: $eventRank,
        riteId: $riteId,
        date: $date,
        officialDate: $officialDate,
        celebrations: $celebrations,
        season: $season,
        cycleStandard: $cycleStandard,
        cycleFerial: $cycleFerial,
        commonId: $commonId,
        lectionaryCommonId: $lectionaryCommonId,
        orderId: $orderId,
        eventFerialExternalId: $eventFerialExternalId,
        ordoConfig: $ordoConfig,
        templateId: $templateId,
        blankOrdo: $blankOrdo,
      ) {
        ordo {
          ...ListOrdo
        }
        showDefaultTemplatePrompt
      }
    }
    ${LIST_ORDO_FRAGMENT}
  `,
  updater: createMutationCacheUpdater(
    GetOrdosCached,
    getOrdosVariablesFromMutationVariables,
    (draft, mutationData: CreateOrdo['Data']) => {
      draft.ordos.push(mutationData.createOrdo.ordo);
      return true;
    }
  ),
}

export interface SetRubricStateForOrdo extends GQLMutation<SetRubricStateForOrdo, GetOrdo> {
  Variables: {
    ordoId: number;
    showRubrics: boolean;
  }
  Data: {
    ordo: {
      config: {
        showRubrics: OrdoConfig['showRubrics']
      }
    } & WithId;
  };
}
export const SetRubricStateForOrdo: GQLDocument<SetRubricStateForOrdo> = {
  mutation: gql`
    mutation toggleRubrics($ordoId: Float!, $showRubrics: Boolean!) {
      ordo: setRubricStateForCardOrdo(ordoId: $ordoId, showRubrics: $showRubrics) {
        id
        config {
          showRubrics
        }
      }
    }
  `,
  updater: createMutationCacheUpdater(
    GetOrdo,
    getOrdoVariables,
    (draft, mutationData: SetRubricStateForOrdo['Data']) => {
      draft.ordo.config.showRubrics = mutationData.ordo.config.showRubrics;
      return true;
    }
  ),
}

export interface SetOrdoShared extends GQLMutation<SetOrdoShared, GetOrdo> {
  Variables: {
    ordoId: number;
  },
  Data: {
    setOrdoShared: boolean;
  }
};
export const SetOrdoShared: GQLDocument<SetOrdoShared> = {
  mutation: gql`
    mutation setOrdoShared($ordoId: Float!) {
      setOrdoShared(ordoId: $ordoId)
    }
  `
};

export interface CreateOrdoMarketingShareId extends GQLMutation<CreateOrdoMarketingShareId, GetOrdo> {
  Variables: {
    ordoId: number;
  },
  Data: {
    createOrdoMarketingShareId: {
      id: number;
      marketingShareId: string;
    }
  }
};
export const CreateOrdoMarketingShareId: GQLDocument<CreateOrdoMarketingShareId> = {
  mutation: gql`
    mutation createOrdoMarketingShareId($ordoId: Float!) {
      createOrdoMarketingShareId(ordoId: $ordoId) {
        id
        marketingShareId
      }
    }
  `
};

export interface ShareOrdo extends GQLMutation<ShareOrdo, GetOrdo> {
  Variables: {
    ordoId: number;
    emails: string[];
    choirGroupIds: number[];
    note?: string | null;
  },
  Data: {
    ordo: {
      groupId: string;
      sharedWithEmails: NonNullable<Ordo['sharedWithEmails']>;
      sharedWithGroups: NonNullable<Ordo['sharedWithGroups']>;
    } & WithId
  }
};
export const ShareOrdo: GQLDocument<ShareOrdo> = {
  mutation: gql`
    mutation shareOrdo($ordoId: Float!, $emails: [String!]!, $choirGroupIds: [Float!]!, $note: String) {
      ordo: shareOrdo(ordoId: $ordoId, emails: $emails, choirGroupIds: $choirGroupIds, note: $note) {
        id
        groupId
        sharedWithEmails {
          ...ChoirEmail
        }
        sharedWithGroups {
          name
          id
        }
      }
    }
    ${CHOIR_EMAIL_FRAGMENT}
  `,
  updater: (cache, { data }) => {
    const ordo = data?.ordo;
    if (!ordo) return;
    const { groupId, sharedWithEmails } = ordo;
    addChoirEmailsToGroupCache(cache, sharedWithEmails, groupId);
  }
};

export interface UnshareOrdoFromChoirEmail extends GQLMutation<UnshareOrdoFromChoirEmail, GetOrdo> {
  Variables: {
    ordoId: number;
    email: string;
  },
  Data: {
    ordo: ({
      sharedWithEmails: NonNullable<Ordo['sharedWithEmails']>;
    } & WithId) | null
  }
};
export const UnshareOrdoFromChoirEmail: GQLDocument<UnshareOrdoFromChoirEmail> = {
  mutation: gql`
    mutation UnshareOrdoFromChoirEmail($ordoId: Float!, $email: String!) {
      ordo: unshareOrdoFromEmail(ordoId: $ordoId, email: $email) {
        id
        sharedWithEmails {
          name
          email
          id
        }
      }
    }
  `,
};

export interface UnshareOrdoFromChoirGroup extends GQLMutation<UnshareOrdoFromChoirGroup, GetOrdo> {
  Variables: {
    ordoId: number;
    choirGroupId: number;
  },
  Data: {
    ordo: ({
      sharedWithGroups: NonNullable<Ordo['sharedWithGroups']>;
    } & WithId) | null
  }
};
export const UnshareOrdoFromChoirGroup: GQLDocument<UnshareOrdoFromChoirGroup> = {
  mutation: gql`
    mutation UnshareOrdoFromChoirGroup($ordoId: Float!, $choirGroupId: Float!) {
      ordo: unshareOrdoFromChoirGroup(ordoId: $ordoId, choirGroupId: $choirGroupId) {
        id
        sharedWithGroups {
          name
          id
        }
      }
    }
  `,
};
