export const GospelCross = {
  x: 0,
  y: 0,
  width: 560,
  height: 560,
  path: `M180,540V490H250V310H70V380H20V180H70V250H250V70H180V20H380V70H310V250H490V180H540V380H490V310H310V490H380V540Z`,
  pathFor: ({ x = 0, y = 0, h = 12 }: { x?: number; y?: number; h?: number }) =>
    GospelCross.path
      .replace(/([MLH])(\d+)/gi, (_, cmd, n) => `${cmd}${(Number(n) * h / GospelCross.height) + x}`)
      .replace(/([V,])(\d+)/gi, (_, cmd, n) => `${cmd}${(Number(n) * h / GospelCross.height) + y}`),
};
