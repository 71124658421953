import { HostedPage } from 'queries/hosted-page';
import { chargebeeSite } from 'utils/env';

// chargebee types
declare namespace Chargebee {
  function getInstance(): Chargebee | undefined;
  function init(opts: ChargebeeInit): Chargebee;
  function getPortalSections(): PortalSelections;

  interface CheckoutOptions {
    layout?: 'in_app' | 'full_page';
    hostedPage: () => Promise<HostedPage>;
    loaded?: () => void;
    error?: (err: any) => void;
    success?: (hostedPageId: string) => void;
    close?: () => void;
    step?: (currentStep: string) => void;
  }

  type PortalSession = any;
  type PortalSetterFunction = () => Promise<PortalSession>;
  interface Chargebee {
    openCheckout: (options: CheckoutOptions) => void;
    createChargebeePortal: () => ChargebeePortal;
    setPortalSession: (setter: PortalSetterFunction) => void;
    logout: () => void;
    closeAll: () => void;

    getCart: () => Cart;
    initializeProduct: (productId: string, quantity?: number | string) => Product;
  }

  interface Cart {
    setCustomer: (customer: any) => void;
    setShippingAddress: (address: any) => void;
    replaceProduct: (product: Product) => void;
    proceedToCheckout: () => void;
  }
  interface Product {
    addAddon: (addon: Addon) => void;
    setPlanQuantity: (quantity: number) => void;
    setAddons: (addons: Addon[]) => void;
  }
  interface Addon {
    id: string;
    quantity?: number | string;
    quantity_in_decimal?: string;
  }
  interface ChargebeeInit {
    site: string;
    domain?: string;
    enableFriendbuyTracking?: boolean;
    enableRedirectMode?: boolean;
    enableGATracking?: boolean;
    enableFBQTracking?: boolean;
    enableGTMTracking?: boolean;
    enableRefersionTracking?: boolean;
    isItemsModel?: boolean;
    iframeOnly?: boolean;
    recaptchaKey?: string;
    referrerModule?: string;
  }

  interface ChargebeePortal {
    open: (options: any, forwardOptions: any) => void;
    openSection: (options: any, callbacks: any) => void;
  }

  type PortalSelections = { [key: string]: string };
}

let hasInitialized = false;

export const getChargebee = () => {
  try {
    if (!hasInitialized) {
      hasInitialized = true;
      return Chargebee.init({
        site: chargebeeSite,
        enableGATracking: true,
        enableFBQTracking: true,
      });
    } else {
      return Chargebee.getInstance();
    }
  }
  catch {
    return undefined;
  }
};
