import React from 'react';
import { Regular } from 'components/ordo/shared/text/renderer/Regular';
import { processPlainText } from 'utils/markup';
import { useGloryBeTranslation } from 'hooks/text/useGloryBe';

export const GloryBe: React.FC<{
  withoutOuterDiv?: boolean;
  addExportClass?: boolean;
  columns?: number | null;
}> = ({ withoutOuterDiv, addExportClass = true, columns }) => {
  const gloryBeTranslation = useGloryBeTranslation();

  const translationContent = processPlainText(gloryBeTranslation?.content);

  return (
    <>
      {gloryBeTranslation && (
        <Regular
          columns={columns}
          addExportClass={addExportClass}
          withoutOuterDiv={withoutOuterDiv}
          text={translationContent}
          className="liturgical-text-glory-be"
        />
      )}
    </>
  );
};
