/* eslint-disable @typescript-eslint/no-redeclare */
import { gql } from '@apollo/client';
import { WithId } from 'utils/api-types/WithNameAndId';
import { InteractionIdentifier } from 'utils/ordo/InteractionIdentifier';
import { Dictionary } from 'utils/typescript/Dictionary';
import { GQLDocument, GQLMutation, GQLQuery } from './gql-generics';

export interface CreateUserInteraction extends GQLMutation<CreateUserInteraction> {
  Variables: {
    interactionIdentifier: InteractionIdentifier;
    jsonData?: Dictionary<unknown>;
  }
  Data: {
    createUserInteraction: WithId;
  }
}
export const CreateUserInteraction: GQLDocument<CreateUserInteraction> = {
  mutation: gql`
    mutation createUserInteraction($interactionIdentifier: InteractionIdentifier!, $jsonData: JSONObject) {
      createUserInteraction(interactionIdentifier: $interactionIdentifier, jsonData: $jsonData) {
        id
      }
    }
  `
}

export interface ShowGetStarted extends GQLQuery {
  Variables: never;
  Data: {
    showGetStarted: boolean;
  }
}
export const ShowGetStarted: GQLDocument<ShowGetStarted> = {
  query: gql`
    query showGetStarted {
      showGetStarted
    }
  `
}

export interface InteractionsForUser extends GQLQuery {
  Variables: {
    identifiers: InteractionIdentifier[];
  };
  Data: {
    userInteractions: InteractionIdentifier[];
  }
}
export const InteractionsForUser: GQLDocument<InteractionsForUser> = {
  query: gql`
    query interactionsForUser($identifiers: [InteractionIdentifier!]!) {
      userInteractions: interactionsForUser(identifiers: $identifiers)
    }
  `
}
